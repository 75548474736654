import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `/admin/faq/list`,
	lookupFaqCategories: () => `/admin/faq/category/list`,
	lookupFaq: () => `/admin/faq`,
	createFaq: () => `/admin/faq`,
	updateFaq: () => `/admin/faq`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	lookupFaqCategories: () => axiosInstance.get(endPoint.lookupFaqCategories()),
	lookupFaq: params => axiosInstance.get(endPoint.lookupFaq(), { params }),
	createFaq: body => axiosInstance.post(endPoint.createFaq(), body),
	updateFaq: body => axiosInstance.patch(endPoint.updateFaq(), body),
}

export default api
