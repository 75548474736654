import cookies from 'vue-cookies'
import store from '@/store/index'

const _baseAccountURL = process.env.VUE_APP_BASE_ACCOUNT_URL
const _baseURLExceptHttp = process.env.VUE_APP_BASE_URL_EXCEPT_HTTP
const _baseMallURL = process.env.VUE_APP_MALL_URL

const COOKIES_DOMAIN = location.hostname.includes('lab-manager.com') ? _baseURLExceptHttp : location.hostname

const TOKEN_KEY = process.env.VUE_APP_TOKEN_KEY
const LAB_KEY = process.env.VUE_APP_LAB_KEY
const SERVICE_URL = process.env.VUE_APP_SERVICE_URL
const LEGACY_SERVICE_URL = process.env.VUE_APP_LEGACY_SERVICE_URL

cookies.config({
	domain: COOKIES_DOMAIN,
})

const isLogin = () => {
	return !cookies.isKey(TOKEN_KEY) ? false : true
}

const getAccountUrl = () => {
	return _baseAccountURL
}

const getCurrentLabId = () => {
	return null
}

const getServiceUrl = () => {
	return SERVICE_URL
}

const getLegacyServiceUrl = () => {
	return LEGACY_SERVICE_URL
}

const getMallURL = () => {
	return _baseMallURL
}

const setToken = token => {
	cookies.set(TOKEN_KEY, token, null, null, COOKIES_DOMAIN)
}

const INSTITUTE_ID_KEY = 'instituteId'
const setCookieInstituteId = instituteId => {
	cookies.set(INSTITUTE_ID_KEY, instituteId, null, null, COOKIES_DOMAIN)
}

const getToken = () => {
	return cookies.get(TOKEN_KEY)
}

const logout = () => {
	deleteLocalStorage()
	cookies.remove(TOKEN_KEY, null, COOKIES_DOMAIN)
	// 전체 쿠키 삭제 -> 주석처리
	// deleteAllCookies()
}
const deleteLocalStorage = () => {
	localStorage.removeItem('isRemember')
}

const deleteAllCookies = () => {
	cookies.keys().forEach(key => {
		cookies.remove(key)
	})
	cookies.remove(TOKEN_KEY, null, COOKIES_DOMAIN)
	cookies.remove(LAB_KEY, null, COOKIES_DOMAIN)
}

const goToSSOMain = url => {
	location.replace(`${_baseAccountURL}/SignIn?redirect=${url ? encodeURIComponent(url) : window.location.origin}&pro=true`)
}

const goToSSOEmail = () => {
	location.replace(`${_baseAccountURL}/pro/EmailAuth?redirect=${window.location.origin}&pro=true&token=${getToken()}`)
}

const hasInstitutionId = () => {
	// return store.getters['user/hasInstitutionId']
}

const loginCheck = (from, to, next) => {
	if (isLogin()) {
		next()
	} else {
		location.replace(`${_baseAccountURL}/SignIn?redirect=${window.location.origin}&pro=true`)
	}
}

export {
	isLogin,
	getAccountUrl,
	getCurrentLabId,
	setToken,
	getToken,
	logout,
	deleteLocalStorage,
	deleteAllCookies,
	getServiceUrl,
	getLegacyServiceUrl,
	goToSSOMain,
	goToSSOEmail,
	hasInstitutionId,
	COOKIES_DOMAIN,
	loginCheck,
	INSTITUTE_ID_KEY,
	setCookieInstituteId,
	getMallURL,
}
