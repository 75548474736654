<template>
	<portal to="lm-modal">
		<div class="modal-order-cancel-request-reject-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">주문취소 요청 거절</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="explanation">
						주문취소 요청을 거절하고 <span style="font-weight: 700">승인 완료</span> 상태로 되돌립니다.<br />선택한 상품의 취소를
						거절하시겠습니까?
					</div>
					<div class="cancel-request-reject-reason-detail-wrapper">
						<div class="title-wrapper">
							<div class="title">거절 상세사유 입력</div>
							<div class="delete" @click="clearCancelResponse">모두 지우기</div>
						</div>
						<div class="textarea-wrapper">
							<textarea v-model="cancelResponse" placeholder="거절사유를 입력해주세요"></textarea>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__delete btn-warning" @click="complete">거절</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'
export default {
	name: 'ModalOrderCancelRequestReject',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		orderItemList: {
			type: Array,
			default: [],
		},
	},
	watch: {
		isShow() {
			Object.assign(this.$data, this.$options.data.call(this))
		},
	},
	data() {
		return {
			cancelResponse: '',
		}
	},
	methods: {
		...mapActions('order', ['cancelOrderRequestReject']),
		clearCancelResponse() {
			this.cancelResponse = ''
		},
		hide() {
			this.$emit('hide')
		},
		async complete() {
			try {
				const response = await this.cancelOrderRequestReject({
					orderItemIds: this.orderItemList.map(({ orderItemId }) => orderItemId),
					cancelResponse: this.cancelResponse,
				})
				if (response?.status === 205) {
					this.$root.toast('주문취소 요청 거절 완료', '승인 완료으로 유지됩니다.', ToastType.SUCCESS)
					this.$emit('complete')
					this.hide()
				}
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-cancel-request-reject-wrapper {
	.Popup__container {
		.Popup__content {
			width: 500px;
			.explanation {
				font-size: 0.875rem;
			}

			.cancel-request-reject-reason-detail-wrapper {
				margin-top: 20px;
				.title-wrapper {
					font-size: 0.75rem;
					display: flex;
					justify-content: space-between;
					.delete {
						color: $FONT_PLACEHOLDER;
						font-weight: 700;
						cursor: pointer;
					}
				}
				.textarea-wrapper {
					margin-top: 8px;
					textarea {
						width: 100%;
						height: 150px;
						border: 1px solid #cccccc;
						border-radius: 5px;
						padding: 10px;
						font-size: 0.875rem;
					}
				}
			}
		}
	}
}
</style>
