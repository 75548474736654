<template>
	<button class="button-wrapper btn" :disabled="disabled" :class="[{ active: isActive, disabled: disabled }, size, color]" @click="handleClick">
		<slot></slot>
	</button>
</template>

<script>
export default {
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: '', // sm , md , lg
		},
		color: {
			type: String,
			default: '', // primary - 노랑 , warning
		},
	},
	methods: {
		handleClick() {
			this.$emit('click')
		},
	},
}
</script>

<style lang="scss" scoped>
.button-wrapper {
	display: flex;
	background-color: #f2f3f4;
	border-radius: 4px;
	height: 30px;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 700;
	gap: 5px;
	align-items: center;
	text-align: center;
	justify-content: center;
	&.active {
		border: 1px solid #ffb000;
		background-color: $PRIMARY_YELLOW_200;
		.btn-text {
			color: $FONT_YELLOW !important;
		}
		.btn-subtext {
			color: $FONT_YELLOW !important;
		}
	}
	&.disabled {
		opacity: 0.2;
	}
	// TODO: css 설정
	// &.size-sm {
	// }
	// &.color-warning {
	// }

	// size
	&.sm {
		font-size: 12px;
		height: 20px;
	}
	&.md {
		font-size: 14px;
		height: 36px;
		min-width: 100px;
	}
	&.lg {
		font-size: 16px;
		height: 40px;
		min-width: 160px;
	}
	// color
	&.warning {
		background: #ff5746;
		color: white;
		&.only {
			background-color: white;
			color: #ff5746;
		}
	}
	&.primary {
		background: #fec741;
		color: black;
		&.only {
			background: white;
			color: #fec741;
			border: 1px solid #fec741;
		}
	}
	&.accept {
		background: #0dc577;
		color: #ffffff;
	}
	&:hover,
	&:focus,
	&:active {
		opacity: 0.7 !important;
	}
}
</style>
