const StringUtils = {
	isEmpty: str => {
		return str === undefined || str === null || str === ''
	},
	setSearchMarkTag: (originStr, markText) => {
		const strLower = originStr.toLowerCase()
		const markTextLower = markText.toLowerCase()
		const startIndex = strLower.indexOf(markTextLower)

		if (startIndex < 0) {
			return originStr
		} else {
			const replaceText = `<mark>${originStr.substr(startIndex, markText.length)}</mark>`
			return originStr.substr(0, startIndex) + replaceText + originStr.substr(startIndex + markText.length)
		}
	},
	replaceHighlight: (originStr, markText, tagName, className) => {
		const strLower = originStr ? originStr.toLowerCase() : ''
		const markTextLower = markText ? markText.toLowerCase() : ''
		const startIndex = strLower.indexOf(markTextLower)
		if (startIndex < 0) {
			return originStr
		} else {
			const replaceText = `<${tagName} class="${className}" >${originStr.substr(startIndex, markText.length)}</${tagName}>`
			return originStr.substr(0, startIndex) + replaceText + originStr.substr(startIndex + markText.length)
		}
	},
	jsonToMultiform: json => {
		let body = {}

		for (let key in json) {
			if (!json[key]) continue

			switch (typeof json[key]) {
				case 'string':
				case 'number':
					body[key] = json[key] + ''
					break
				case 'object':
					if (Array.isArray(json[key])) {
						json[key].forEach((cur, index) => {
							if (typeof cur == 'object') {
								for (let key2 in cur) {
									if (cur[key2]) {
										body[`${key}[${index}][${key2}]`] = cur[key2] + ''
									}
								}
							} else {
								body[`${key}[${index}]`] = cur
							}
						})
					}
					break
			}
		}

		return body
	},

	autoHypenTel(str) {
		// ref: https://hgko1207.github.io/2019/01/18/javascript-autoHypenTel/
		str = str.replace(/[^0-9]/g, '')
		let tmp = ''

		if (str.substring(0, 2) == '02') {
			// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
			if (str.length < 3) {
				return str
			} else if (str.length < 6) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2)
				return tmp
			} else if (str.length < 10) {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 3)
				tmp += '-'
				tmp += str.substr(5)
				return tmp
			} else {
				tmp += str.substr(0, 2)
				tmp += '-'
				tmp += str.substr(2, 4)
				tmp += '-'
				tmp += str.substr(6, 4)
				return tmp
			}
		} else {
			// 핸드폰 및 다른 지역 전화번호 일 경우
			if (str.length < 4) {
				return str
			} else if (str.length < 7) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3)
				return tmp
			} else if (str.length < 11) {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 3)
				tmp += '-'
				tmp += str.substr(6)
				return tmp
			} else {
				tmp += str.substr(0, 3)
				tmp += '-'
				tmp += str.substr(3, 4)
				tmp += '-'
				tmp += str.substr(7)
				return tmp
			}
		}

		return str
	},
}

export default StringUtils
