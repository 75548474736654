<template>
	<table class="lm-table">
		<table-header />
		<table-item v-for="item in settlementList.list" :key="item.settleNo" :item="item" />
		<div class="empty-table" v-if="settlementList.count === 0">
			<img class="empty-img" src="@/assets/svg/common/inventory-empty-data.svg" />
			<p class="bold">정산중인 항목이 없습니다.</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './TableHeader.vue'
import TableItem from './TableItem.vue'
import { mapState } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	computed: {
		...mapState('settlement/list', ['settlementList']),
	},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding: 80px 0;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: #666;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: #999;
	}
}
</style>
