<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>이벤트 페이지 등록</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>배너정보</h5>
				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					:inputValue="formData.title"
					@changeValue="setTitle"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<upload-banner-images @changeValue="setBannerImages" />
				<upload-detail-images ref="upload-detail-images" />
			</div>
			<div>
				<h5>배너 설정</h5>
				<input-radio title="타겟 지정" @changeValue="data => (formData.target = data.id)" :list="targetStatus" :tab="formData.target" />
				<input-date-picker title="노출 시작일" :inputValue="formData.startDatetime" @changeValue="data => (formData.startDatetime = data)" />
				<input-date-picker title="노출 종료일" :inputValue="formData.endDatetime" @changeValue="data => (formData.endDatetime = data)" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="regist">등록</base-button>
		</div>
		<modal-submit
			title="노출 종료일 설정 불가"
			content="노출 종료일은 노출 시작일 이전으로 설정할 수 없습니다."
			id="date-caution-modal"
			:isOkOnly="true"
		/>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import UploadBannerImages from '../components/UploadBannerImages.vue'
import UploadDetailImages from '../components/UploadDetailImage.vue'
import TargetSelect from '../../components/target/TargetSelect.vue'
import PageName from '@/utils/define/PageName'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import { mapActions } from 'vuex'

export default {
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputRadio,
		UploadBannerImages,
		UploadDetailImages,
		TargetSelect,
		ModalSubmit,
	},
	data() {
		return {
			formData: {
				title: null,
				webMainImage: null,
				webSearchImage: null,
				mobileMainImage: null,
				mobileSearchImage: null,
				target: null,
				startDatetime: this.$DateTime.now().toJSDate(),
				endDatetime: this.$DateTime.now().plus({ day: 7 }).toJSDate(),
			},
			isValid: {
				title: true,
			},
			inValidText: {
				title: '',
			},
		}
	},
	computed: {
		targetStatus() {
			return [
				{
					id: null,
					title: '전체',
				},
				{
					id: 'UNIVERSITY',
					title: '학교',
				},
				{
					id: 'ENTERPRISE',
					title: '기업',
				},
				{
					id: 'SELLER',
					title: '재판매업체',
				},
				{
					id: 'PERSON',
					title: '개인',
				},
			]
		},
	},
	methods: {
		...mapActions('lms/banner', ['createEvent']),
		async regist() {
			if (!this.validate()) return

			const formData = new FormData()
			Object.keys(this.formData).forEach(key => {
				if (key === 'startDatetime' || key === 'endDatetime')
					formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
				else formData.append(key, this.formData[key])
			})

			const details = this.$refs['upload-detail-images'].getImages()
			details['detailImageList'].forEach(detail => {
				formData.append('detailImages', detail.file)
				formData.append('links[]', detail.link || "x")
			})

			try {
				await this.createEvent(formData)
				this.$root.toast('등록 완료', '이벤트 페이지가 등록되었습니다.', 'success')
				this.$router.replace({
					name: PageName.Lms.Banner,
				})
			} catch (e) {
				this.$root.toast('등록 실패', e.response.data.msg, 'error')
			}
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			let isValid = true
			if (!this.formData.title) {
				this.isValid.title = false
				this.inValidText.title = '제목을 입력해주세요.'
				isValid = false
			}

			if (this.formData.startDatetime > this.formData.endDatetime) {
				this.$bvModal.show('date-caution-modal')
				isValid = false
			}

			return isValid
		},
		setTitle(value) {
			this.isValid.title = true
			this.formData.title = value
		},
		setBannerImages(values) {
			values.forEach(value => {
				switch (value.type) {
					case 'webHomeBannerForm':
						this.formData.webMainImage = value.image
						break
					case 'webListBannerForm':
						this.formData.webSearchImage = value.image
						break
					case 'mobileHomeBannerForm':
						this.formData.mobileMainImage = value.image
						break
					case 'mobileListBannerForm':
						this.formData.mobileSearchImage = value.image
						break
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
