import axiosInstance from '@/services/axios'

const endPoint = {
	lookupProductPriceBulkupdateList: () => `admin/product/price/bulkupdate/list`,
	uploadProductPriceBulkupdate: () => `admin/product/price/bulkupdate`,
}

const api = {
	lookupProductPriceBulkupdateList: params => axiosInstance.get(endPoint.lookupProductPriceBulkupdateList(), { params }),
	uploadProductPriceBulkupdate: formData => axiosInstance.post(endPoint.uploadProductPriceBulkupdate(), formData, {
		headers: {
			'Content-Type': 'multipart/form-data',
		}
	}),
}

export default api
