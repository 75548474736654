<template>
	<portal to="lm-modal">
		<div class="modal-order-separation-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">주문 수정</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="product-info-table-wrapper">
						<order-separation-option-table
							ref="order-separation-option-table"
							:orderItem="orderItem"
							@changeStatus="changeStatus"
						></order-separation-option-table>
					</div>
					<div v-if="hasCancel">
						<div v-if="!cancelOrderList.length" class="text-wrapper">
							<p>주문을 분리하면, 기존 주문 아이템 번호인</p>
							<p>
								<span>[{{ orderItemNo }}]</span>는 <span>주문 취소 처리</span>됩니다.
							</p>
						</div>
						<div v-else class="text-wrapper">
							<p>주문을 분리하면, 기존 주문 아이템 번호인</p>
							<p>
								<span>[{{ orderItemNo }}]</span>과
							</p>
							<p>분리해서 신규로 생성한 주문 아이템 번호인</p>
							<p>
								<span v-for="(order, index) in cancelOrderList" :key="`order_${index}`">{{ index > 0 ? ', ' : '' }}[{{ order }}]</span
								>는 <span>주문 취소 처리</span> 됩니다.
							</p>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
			<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="completeCommonModal"></modal-common>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import OrderInfo from '@/views/order/orderProduct/components/separation/OrderInfo.vue'
import OrderSeparationOptionTable from '@/views/order/orderProduct/components/separation/OrderSeparationOptionTable.vue'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalOrderModify',
	components: {
		OrderInfo,
		OrderSeparationOptionTable,
		ModalCommon,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		orderItem: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		orderItemNo() {
			const { orderItemNo } = this.orderItem
			return orderItemNo ?? '-'
		},
	},
	data() {
		return {
			cancelOrderList: [],
			hasCancel: false,
			isShowConfirmModal: false,
		}
	},
	methods: {
		...mapActions('order', ['separationOrder', 'cancelOrder']),
		hide() {
			this.cancelOrderList = []
			this.hasCancel = false
			this.$emit('hide')
		},
		async complete() {
			const tableData = this.$refs['order-separation-option-table'].getTableData()
			let count = 0
			let isValid = true
			tableData.forEach(row => {
				count += Number(row.quantity)
				if (Number(row.quantity) === 0) isValid = false
			})

			if (!isValid) return this.$root.toast('에러', '주문 수량은 0 이상이어야 합니다.', ToastType.ERROR)
			if (count - tableData[0].quantity !== tableData[0].quantity)
				return this.$root.toast('에러', '주문 수량의 총합은 출고수량과 같아야 합니다.', ToastType.ERROR)

			if (this.cancelOrderList.length) this.showConfirmModal()
			else {
				try {
					if (tableData.length === 1 && tableData[0].status == 'CANCEL') {
						await this.cancelOrder({
							orderItemIds: [this.orderItem.orderItemId],
						})
					} else {
						await this.separationOrder({ body: this.makePayload() })
					}
					this.$root.toast('주문수정 완료', '주문이 수정되었습니다.', ToastType.SUCCESS)
					this.$emit('complete')
					this.hide()
				} catch (error) {
					this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
				}
			}
		},
		makePayload() {
			const tableData = this.$refs['order-separation-option-table'].getTableData()
			const { orderItemId } = this.orderItem
			let result = {
				orderItemId,
				separatedOrderItems: [
					...tableData.map(({ quantity, status }, index) => {
						const data = { quantity, status }
						const payload = {}

						Object.keys(data).forEach(key => {
							if (data[key] !== null && (data[key].length || data[key] > 0)) payload[key] = data[key]
						})

						return payload
					}),
				],
			}
			result.separatedOrderItems.splice(0, 1)

			return result
		},
		showConfirmModal() {
			this.$refs['modal-common'].show(
				'주문취소 확인',
				'주문취소 처리한 아이템이 있습니다.<br>주문취소는 주문자에게 확인 후 진행해야 하며, 되돌릴 수 없습니다.<br>정말로 주문취소 처리 및 주문수정을 하시겠습니까?',
				'complete',
				'확인'
			)
		},
		async completeCommonModal() {
			try {
				await this.separationOrder({ body: this.makePayload() })
				this.$root.toast('주문수정 완료', '주문이 수정되었습니다.', ToastType.SUCCESS)
				this.$emit('complete')
				this.hide()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		changeStatus() {
			this.hasCancel = false
			this.cancelOrderList = []
			const tableData = this.$refs['order-separation-option-table'].getTableData()
			tableData.forEach((data, index) => {
				if (data.status === 'CANCEL') this.hasCancel = true
				if (data.status === 'CANCEL' && index !== 0) this.cancelOrderList.push(data.orderNo)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-separation-wrapper {
	.Popup__container {
		.Popup__content {
			width: 500px;
			overflow: visible;
			.text-wrapper {
				text-align: center;
				background: #f5f5f5;
				border-radius: 5px;
				padding: 10px;
				margin-top: 10px;
				font-weight: 400;
				font-size: 14px;
				span {
					font-weight: 600;
				}
			}
		}
	}
}
</style>
