<template>
	<div class="wrapper">
		<div class="table-wrapper">
			<table>
				<colgroup>
					<col v-for="(column, index) of columns" :key="`col_${index}`" :width="column.width ?? 'auto'" />
				</colgroup>
				<thead>
					<tr>
						<th v-for="(column, index) of columns" :key="`th_${index}`">
							<span>
								{{ column.value }}
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, rowIndex) of tableData" :key="`tr_${rowIndex}`">
						<td>
							<p :style="{ 'font-weight': isFirstRow(rowIndex) ? '600' : '400' }">{{ data[KEY.ITEM_NO] }}</p>
							<p v-if="isFirstRow(rowIndex)" class="original-order">기존주문</p>
						</td>
						<td><input type="number" v-model="data[KEY.QUANTITY]" :disabled="rowIndex === 0" /></td>
						<td>
							<div
								class="select-wrapper"
								:style="{
									'background-color': isFirstRow(rowIndex) ? '#e6e6e64d' : '',
									cursor: isFirstRow(rowIndex) ? 'default' : '',
								}"
								@click="toggleSelect(tableData[rowIndex], 'isShowSelect', rowIndex)"
							>
								<div class="text-box">
									<p>{{ getStatusName(data.status) }}</p>
								</div>
								<div class="image-box">
									<img src="@/assets/svg/app/select-triangle.svg" alt="" />
								</div>
								<ul v-show="tableData[rowIndex]['isShowSelect']">
									<li
										v-for="(status, index) in statusList"
										:key="`status${index}`"
										:value="status.value"
										@click="onSelect(index, tableData[rowIndex], status.value)"
									>
										{{ getStatusName(status.name) }}
									</li>
								</ul>
							</div>
						</td>
						<td>
							<img v-if="rowIndex > 0" style="cursor: pointer" src="@/assets/svg/icon-trash.svg" @click="onClickDeleteBtn(rowIndex)" />
							<img v-else src="@/assets/svg/order/icon-trash-disabled.svg" />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="add-option-container">
			<div @click="onClickAddBtn" style="cursor: pointer; display: flex">
				<img src="@/assets/svg/order/icon-divide.svg" />
				<span>주문분리</span>
			</div>
		</div>
	</div>
</template>

<script>
import ModalCommon from '@/views/common/modals/ModalCommon.vue'

const KEY = {
	ITEM_NO: 'orderNo',
	QUANTITY: 'quantity',
	STATUS: 'status',
	DELETE: 'delete',
}

export default {
	name: 'OrderSeparationOptionTable',
	components: {
		ModalCommon,
	},
	props: {
		orderItem: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			KEY,
			tableData: [],
			defaultItem: {},
			deleteItemIndex: 0,
			columns: [
				{
					key: KEY.ITEM_NO,
					value: '주문 아이템 번호',
					width: '150',
				},
				{
					key: KEY.QUANTITY,
					value: '수량',
					width: '70',
				},
				{
					key: KEY.STATUS,
					value: '상태',
					width: '150',
				},
				{
					key: KEY.DELETE,
					value: '삭제',
					width: '50',
				},
			],
			statusList: [],
		}
	},
	computed: {},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.tableData = []
			this.defaultItem = {
				isShowSelect: false,
			}
			this.columns.forEach(column => {
				this.defaultItem[column.key] = column.key === KEY.QUANTITY ? 1 : ''
			})

			const defaultItem = this.$_.cloneDeep(this.defaultItem)
			Object.keys(defaultItem).map(key => {
				defaultItem[key] = this.orderItem[key] ? this.orderItem[key] : ''
			})
			this.tableData.push(defaultItem)
			this.statusList = this.getStatusList()
		},
		getTableData() {
			return this.tableData
		},
		isFirstRow(index) {
			return index === 0
		},
		onClickAddBtn() {
			if (this.tableData.length > 4) return
			if (this.tableData[0].quantity === 1)
				return this.$root.toast('에러', '수량이 2개 이상인 주문에 대해서만 주문 분리가 가능합니다.', 'error')

			const newRow = {
				orderNo: `새주문 ${this.tableData.length}`,
				status: this.tableData[0].status,
				quantity: this.setQuantity(),
				isShowSelect: false,
			}
			this.tableData.push(newRow)
			if (this.tableData.length === 2) {
				this.onClickAddBtn()
				this.tableData[0].status = 'CANCEL'
			}
			this.$emit('changeStatus')
		},
		setQuantity() {
			if (this.tableData.length === 1) return this.tableData[0].quantity - 1
			else if (this.tableData.length === 2) return 1
			else return 0
		},
		onClickDeleteBtn(index) {
			this.tableData.splice(index, 1)
			if (this.tableData.length == 2) {
				this.onClickDeleteBtn(1)
				this.init()
			}
			this.$emit('changeStatus')
		},
		toggleSelect(data, key, index) {
			if (this.isFirstRow(index)) return
			this.tableData[index][key] = !this.tableData[index][key]
		},
		onSelect(index, data, value) {
			data.status = value
			this.$emit('changeStatus')
		},
		getStatusName(code) {
			const STATUS_NAME = {
				WAIT: '승인 대기',
				APPROVE: '승인 완료',
				// WAIT_CANCEL_REQUEST_APPROVE: '주문취소 요청승인',
				// WAIT_CANCEL_REQUEST_REJECT: '주문취소 요청거절',
				// SHIPPING_ING: '배송중',
				// SHIPPING_COMPLETE: '배송완료',
				CANCEL: '주문취소',
			}
			return STATUS_NAME[code]
		},
		getStatusList() {
			const STATUS = {
				WAIT: [
					{ value: 'WAIT', name: 'WAIT' },
					// { value: 'APPROVE', name: 'APPROVE' },
					// { value: 'SHIPPING_ING', name: 'SHIPPING_ING' },
					// { value: 'SHIPPING_COMPLETE', name: 'SHIPPING_COMPLETE' },
					{ value: 'CANCEL', name: 'CANCEL' },
				],
				APPROVE: [
					{ value: 'APPROVE', name: 'APPROVE' },
					// { value: 'SHIPPING_ING', name: 'SHIPPING_ING' },
					// { value: 'SHIPPING_COMPLETE', name: 'SHIPPING_COMPLETE' },
					{ value: 'CANCEL', name: 'CANCEL' },
				],
				// SHIPPING_ING: [
				// 	{ value: 'SHIPPING_ING', name: 'SHIPPING_ING' },
				// 	{ value: 'SHIPPING_COMPLETE', name: 'SHIPPING_COMPLETE' },
				// 	{ value: 'CANCEL', name: 'CANCEL' },
				// ],
				// SHIPPING_COMPLETE: [
				// 	{ value: 'SHIPPING_COMPLETE', name: 'SHIPPING_COMPLETE' },
				// 	{ value: 'SHIPPING_ING', name: 'SHIPPING_ING' },
				// 	{ value: 'CANCEL', name: 'CANCEL' },
				// ],
				// WAIT_CANCEL: [
				// 	{ value: 'WAIT_CANCEL', name: 'WAIT_CANCEL' },
				// 	{ value: 'WAIT_CANCEL_REQUEST_APPROVE', name: 'WAIT_CANCEL_REQUEST_APPROVE' },
				// 	{ value: 'WAIT_CANCEL_REQUEST_REJECT', name: 'WAIT_CANCEL_REQUEST_REJECT' },
				// ],
				// CANCEL: [{ value: 'CANCEL', name: 'CANCEL' }],
			}
			return STATUS[this.tableData[0].status]
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.table-wrapper {
		overflow: visible;
		max-height: 400px;
		border: 1px solid $LINE_DIVIDER;
		border-bottom: 0;
		border-radius: 5px 5px 0 0;
		table {
			width: 100%;
			table-layout: fixed;
			thead {
				background: $COLOR_F5;
				border-bottom: 1px solid $LINE_DIVIDER;
				th {
					padding: 14px;
					font-size: 14px;
					text-align: left;
					color: $COLOR_666;
					span {
						white-space: nowrap;
						em {
							color: $PRIMARY_RED;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					td {
						position: relative;
						padding: 14px;
						font-size: 14px;
						input {
							width: 100%;
							height: 36px;
							border: 1px solid $FILE_BORDER;
							border-radius: 5px;
						}
						.original-order {
							font-weight: 400;
							font-size: 12px;
						}
						.number-input-minus {
							position: absolute;
							padding: 7px;
							right: 45px;
							cursor: pointer;
						}
						.number-input-plus {
							position: absolute;
							padding: 7px;
							right: 20px;
							cursor: pointer;
						}
						.date-picker {
							width: 100%;
							height: 100%;
						}
						.select-wrapper {
							width: 100%;
							height: 36px;
							border: 1px solid $FILE_BORDER;
							border-radius: 5px;
							position: relative;
							display: inline-flex;
							justify-content: space-between;
							align-items: center;
							cursor: pointer;
							padding: 0 10px;

							.text-box {
								display: flex;
								align-items: center;
								.placeholder {
									font-size: 0.875rem;
									color: grey;
								}
							}
							.image-box {
								padding-left: 10px;
								display: flex;
								align-items: center;
							}
							ul {
								width: 135px;
								max-height: 200px;
								position: absolute;
								top: 35px;
								right: 0;
								background-color: $PRIMARY_WHITE;
								border: 1px solid $INPUT_BORDER;
								border-radius: 4px;
								z-index: 1;
								overflow-y: auto;
								li {
									font-size: 0.875rem;
									height: 35px;
									display: flex;
									align-items: center;
									padding: 10px 15px;
									&:hover {
										background-color: $HOVER_BACKGROUND;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.add-option-container {
		border-left: 1px solid $LINE_DIVIDER;
		border-bottom: 1px solid $LINE_DIVIDER;
		border-right: 1px solid $LINE_DIVIDER;
		border-radius: 0 0 5px 5px;
		padding: 10px 15px;
		text-align: left;
		display: flex;
		align-items: center;
		span {
			margin-left: 5px;
			font-weight: 700;
			font-size: 14px;
			cursor: pointer;
		}
	}
}
</style>
