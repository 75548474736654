<template>
	<div class="page-wrapper">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<cancel-refund-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>
<script>
import Pagination from '@/views/common/components/CommonPagenation.vue'
import ListHeader from '../components/ListHeader.vue'
import CancelRefundTable from '../components/Table.vue'
import { mapState, mapActions } from 'vuex'
export default {
	components: {
		ListHeader,
		Pagination,
		CancelRefundTable,
	},
	computed: {
		...mapState('cancelRefund/list', ['list', 'count']),
	},
	created() {
		this.getList()
	},
	methods: {
		...mapActions('cancelRefund/list', ['getList', 'setLoadParams', 'setPagination']),
		pagination(page) {
			this.setPagination(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 215px);
	}
}
</style>
