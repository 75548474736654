<template>
	<div class="seller-entry-registration-info">
		<div class="entry-registration-title">
			<p>판매자 입점 신청</p>
		</div>
		<div class="input-wrapper">
			<div>
				<input ref="email" type="text" v-model="user.email" :class="{ error: !isValid.email }" placeholder="아이디(이메일 주소)" />
				<div v-if="!isValid.email" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.email }}</span>
				</div>
			</div>
			<div>
				<input
					ref="password"
					type="password"
					v-model="user.password"
					:class="{ error: !isValid.password }"
					placeholder="비밀번호(6자리 이상)"
				/>
				<div v-if="!isValid.password" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.password }}</span>
				</div>
			</div>
			<div>
				<input
					ref="confirm-password"
					type="password"
					v-model="user.passwordConfirm"
					:class="{ error: !isValid.passwordConfirm }"
					placeholder="비밀번호 확인"
				/>
				<div v-if="!isValid.passwordConfirm" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.passwordConfirm }}</span>
				</div>
			</div>
		</div>
		<div class="next-button-wrapper"><button class="btn Btn__complete" @click="onClickNextButton">다음</button></div>
		<div class="footer-wrapper">
			<p>이미 회원이신가요? <span @click="routeSellerLogin">로그인하기</span></p>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PageName from '@/utils/define/PageName'
import { isEmail } from '@/utils/modules/Regex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'SellerEntryRegistrationInfo',
	data() {
		return {
			user: {
				email: '',
				password: '',
				passwordConfirm: '',
			},
			isValid: {
				email: true,
				password: true,
				passwordConfirm: true,
			},
			inValidText: {
				email: '',
				password: '',
				passwordConfirm: '',
			},
		}
	},
	computed: {
		...mapGetters('user', {
			registUserInfo: 'getRegistUserInfo',
		}),
	},
	methods: {
		...mapMutations('user', ['setRegistUserInfo']),
		...mapActions('user', ['lookupEmailValidation']),
		onClickNextButton() {
			if (!this.validate()) return
			if (!this.fetchEmailValidation()) return

			const registUserInfo = this.$_.cloneDeep(this.registUserInfo)
			registUserInfo.email = this.user.email
			registUserInfo.password = this.user.password
			this.setRegistUserInfo(registUserInfo)

			this.$emit('complete')
		},
		async fetchEmailValidation() {
			try {
				await this.lookupEmailValidation({
					email: this.user.email,
				})

				return true
			} catch (error) {
				this.isValid.email = false
				this.inValidText.email = error.response.data.msg
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
				return false
			}
		},
		routeSellerLogin() {
			this.$router.push({
				name: PageName.Seller.SellerLogin,
			})
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			// 이메일
			let isValid = true
			if (!this.user.email.length) {
				this.isValid.email = false
				this.inValidText.email = '이메일을 입력해주세요.'
				isValid = false
			} else if (!isEmail(this.user.email)) {
				this.isValid.email = false
				this.inValidText.email = '이메일 형식이 올바르지 않습니다.'
				isValid = false
			}
			// 비밀번호
			if (!this.user.password.length) {
				this.isValid.password = false
				this.inValidText.password = '비밀번호를 입력해주세요.'
				isValid = false
			} else if (this.user.password.length < 6) {
				this.isValid.password = false
				this.inValidText.password = '6자리 이상 입력해주세요.'
				isValid = false
			}
			// 비밀번호 확인
			if (!this.user.passwordConfirm.length) {
				this.isValid.passwordConfirm = false
				this.inValidText.passwordConfirm = '비밀번호 확인을 입력해주세요.'
				isValid = false
			} else if (this.user.passwordConfirm.length < 6) {
				this.isValid.passwordConfirm = false
				this.inValidText.passwordConfirm = '6자리 이상 입력해주세요.'
				isValid = false
			} else if (this.user.passwordConfirm !== this.user.password) {
				this.isValid.passwordConfirm = false
				this.inValidText.passwordConfirm = '비밀번호가 일치하지 않습니다.'
				isValid = false
			}

			return isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-entry-registration-info {
	.entry-registration-title {
		display: flex;
		justify-content: center;
		p {
			font-size: 1.5rem;
			font-weight: 700;
		}
	}

	.input-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 30px;

		& > div {
			margin-bottom: 10px;
			input[type='text'],
			input[type='password'] {
				width: 100%;
				height: 48px;
				border: 1px solid $FILE_BORDER;
				border-radius: 5px;
				padding: 12px;
				&.error {
					border-color: $PRIMARY_RED;
				}
			}

			.error {
				display: flex;
				span {
					font-size: 0.75rem;
					color: $PRIMARY_RED;
					margin-left: 6px;
				}
			}
		}
	}

	.next-button-wrapper {
		margin-top: 35px;
		button {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: center;
		}
	}

	.footer-wrapper {
		margin-top: 35px;
		span {
			color: $PRIMARY_BLUE;
			font-weight: 500;
			text-decoration-line: underline;
			cursor: pointer;
		}
	}
}
</style>
