<template>
	<div class="estimate-export-wrapper">
		<div class="pdf-viewer">
			<embed ref="" :src="pdfViewerUrl" type="application/pdf" frameborder="0" width="100%" height="100%" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'EstimateExport',
	computed: {
		pdfViewerUrl() {
			return `https://drive.google.com/viewerng/viewer?embedded=true&toolbar=1&url=${this.$route.query.url}`
		},
	},
}
</script>

<style lang="scss" scoped>
.estimate-export-wrapper {
	display: flex;
	flex-direction: column;
	gap: 48px;
	padding: 0 57px;
	.pdf-viewer {
		height: 1200px;
	}
}
</style>
