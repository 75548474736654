import Vue from 'vue'
import VueRouter from 'vue-router'
import product from '@/router/product'
import order from '@/router/order'
import seller from '@/router/seller'
import lms from '@/router/lms'
import cs from '@/router/cs'
import estimate from '@/router/estimate'
import buyer from '@/router/buyer'
import institute from '@/router/institute'
import chemicalAPI from '@/router/chemicalAPI'
import lmp from '@/router/lmp'
Vue.use(VueRouter)

var mainRoutes = [
	{
		path: '/',
		redirect: to => {
			return '/orderProductManagement'
		},
	},
]

mainRoutes = mainRoutes.concat(product)
mainRoutes = mainRoutes.concat(order)
mainRoutes = mainRoutes.concat(seller)
mainRoutes = mainRoutes.concat(cs)
mainRoutes = mainRoutes.concat(lms)
mainRoutes = mainRoutes.concat(estimate)
mainRoutes = mainRoutes.concat(buyer)
mainRoutes = mainRoutes.concat(institute)
mainRoutes = mainRoutes.concat(chemicalAPI)
mainRoutes = mainRoutes.concat(lmp)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err
	})
}

export default new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: mainRoutes,
})
