import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Lms.Banner,
		name: PageName.Lms.Banner,
		props: true,
		component: () => import('@/views/lms/banner/pages/list.vue'),
	},
	{
		path: RoutePath.Lms.BannerCreate,
		name: PageName.Lms.BannerCreate,
		props: true,
		component: () => import('@/views/lms/banner/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.BannerDetail,
		name: PageName.Lms.BannerDetail,
		props: true,
		component: () => import('@/views/lms/banner/pages/detail.vue'),
	},
	{
		path: RoutePath.Lms.BannerUpdate,
		name: PageName.Lms.BannerUpdate,
		props: true,
		component: () => import('@/views/lms/banner/pages/update.vue'),
	},
	{
		path: RoutePath.Lms.Event,
		name: PageName.Lms.Event,
		props: true,
		component: () => import('@/views/lms/event/pages/list.vue'),
	},
	{
		path: RoutePath.Lms.EventCreate,
		name: PageName.Lms.EventCreate,
		props: true,
		component: () => import('@/views/lms/event/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.EventDetail,
		name: PageName.Lms.EventDetail,
		props: true,
		component: () => import('@/views/lms/event/pages/detail.vue'),
	},
	{
		path: RoutePath.Lms.EventEdit,
		name: PageName.Lms.EventEdit,
		props: true,
		component: () => import('@/views/lms/event/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.Notice,
		name: PageName.Lms.Notice,
		props: true,
		component: () => import('@/views/lms/notice/pages/list.vue'),
	},
	{
		path: RoutePath.Lms.NoticeCreate,
		name: PageName.Lms.NoticeCreate,
		props: true,
		component: () => import('@/views/lms/notice/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.Faq,
		name: PageName.Lms.FAQ,
		props: true,
		component: () => import('@/views/lms/faq/pages/list.vue'),
	},

	{
		path: RoutePath.Lms.FaqCreate,
		name: PageName.Lms.FAQCreate,
		props: true,
		component: () => import('@/views/lms/faq/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.Brand,
		name: PageName.Lms.Brand,
		props: true,
		component: () => import('@/views/lms/brand/pages/list.vue'),
	},
	{
		path: RoutePath.Lms.BrandCreate,
		name: PageName.Lms.BrandCreate,
		props: true,
		component: () => import('@/views/lms/brand/pages/create.vue'),
	},
	{
		path: RoutePath.Lms.BrandDetail,
		name: PageName.Lms.BrandDetail,
		props: true,
		component: () => import('@/views/lms/brand/pages/detail.vue'),
	},
	{
		path: RoutePath.Lms.BrandEdit,
		name: PageName.Lms.BrandEdit,
		props: true,
		component: () => import('@/views/lms/brand/pages/edit.vue'),
	},
]
