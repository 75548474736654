<template>
	<search-row class="search-row" :title="title">
		<div v-for="(date, index) in dateList" :key="`date_${index}`">
			<jw-date-picker
				class="date-picker"
				:ref="`datePicker_${index}`"
				:name="date.text"
				:datePlaceholder="datePlaceholder"
				:datePickerStyle="{ width: '230px' }"
				@changeDate="changeDate($event, date.text)"
			/>
		</div>
	</search-row>
</template>

<script>
import SearchRow from '@/views/common/components/search/SearchRow.vue'
import JwDatePicker from '@/views/common/components/search/JwDatePicker.vue'

export default {
	name: 'SearchDate',
	components: {
		SearchRow,
		JwDatePicker,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		dateList: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		reset() {
			this.dateList.forEach((date, index) => {
				this.$refs[`datePicker_${index}`][0].reset()
			})
		},
		deleteFilter(item) {
			this.dateList.forEach((date, index) => {
				if (item.name === date.text) this.$refs[`datePicker_${index}`][0].reset()
			})
		},
		changeDate(event, name) {
			if (event.length > 0) {
				this.$emit('change', event, name)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.search-row {
	div {
		padding-right: 10px;
	}
}
</style>
