<template>
	<base-input
		type="number"
		:value="value"
		:placeholder="placeholder"
		:min="min"
		:max="max"
		:disabled="disabled"
		:readonly="readonly"
		:autofocus="autofocus"
		:error="!isValid || error"
		:errorMessage="errorMessage"
		:inputStyle="inputStyle"
		@input="onInput"
		@keypress="$emit('keypress', $event)"
		@keydown="$emit('keydown', $event)"
		@keyup="$emit('keyup', $event)"
		@click="$emit('click', $event)"
	/>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'

export default {
	name: 'NumberInput',
	components: {
		BaseInput,
	},
	extends: BaseInput,
	methods: {
		onInput(val) {
			if (val.length > this.maxlength) val = val.slice(0, this.maxlength)

			this.$emit('input', val)
		},
	},
}
</script>

<style lang="scss" scoped></style>
