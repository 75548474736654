<template>
	<base-item :title="title" :subContentText="subContentText">
		<additional-text-list
			ref="text-list"
			:title="addButtonTitle"
			:defaultTextList="defaultTextList"
			:maxCount="maxCount"
			:background="background"
			:border="border"
			@change="$emit('change', $event)"
			@changeDeleteTagIds="$emit('changeDeleteTagIds', $event)"
			@overCount="$emit('overCount')"
		></additional-text-list>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'
import AdditionalTextList from '@/views/common/components/AdditionalTextList.vue'

export default {
	name: 'InputCustomTextList',
	components: {
		BaseItem,
		AdditionalTextList,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		defaultTextList: {
			type: Array,
			default: () => [],
		},
		addButtonTitle: {
			type: String,
			default: '',
		},
		maxCount: {
			type: Number,
			default: 999,
		},
		background: {
			type: String,
			default: 'white',
		},
		border: {
			type: String,
			default: '1px solid #333',
		},
	},
	methods: {
		setItems(items) {
			this.$refs['text-list'].setItems(items)
		},
	},
}
</script>

<style lang="scss" scoped>
.text-editor {
	width: 810px;
	height: 154px;
}
</style>
