<template>
	<div class="recent">
		<div class="keyword-header">
			<span> 검색 결과 </span>
		</div>
		<ul class="recent-keyword-list">
			<li class="recent-keyword" v-for="(keyword, index) in suggestionKeywordList" :key="`keyword_${index}`">
				<img class="recent" src="@/assets/svg/common/suggestion-keyword.svg" />
				<span class="keyword-text" @click.stop="clickItem($event, keyword)">{{ keyword }}</span>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'SuggestionKeywords',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		suggestionKeywordList: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		clickItem(event, keyword) {
			this.$emit('clickItem', keyword)
		},
	},
}
</script>

<style lang="scss" scoped></style>
