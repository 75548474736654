import axiosInstance from '@/services/axiosLMP'

const endPoint = {
	license: () => `/admin/license`,
	chargeLicense: () => `/admin/license/charge`,
	instituteList: () => `/admin/institute/list`,
}

const productAPI = {
	// 판매자 정보
	getLicense: params => axiosInstance.get(endPoint.license(), { params }),
	createLicense: body => axiosInstance.post(endPoint.license(), body),
	editLicense: body => axiosInstance.put(endPoint.license(), body),
	chargeLicense: params => axiosInstance.get(endPoint.chargeLicense(), { params }),
	instituteList: params => axiosInstance.get(endPoint.instituteList(), { params }),
}

export default productAPI
