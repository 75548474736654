<template>
	<div class="EstimateForm">
		<button-include-header :title="title" :prevBtn="headerBtn" @prevClickEv="$bvModal.show('modal-common')" />
		<input-form-group
			title="견적 담당자 정보"
			content="*해당 견적을 확인할 담당자 정보입니다"
			formCategory="assignee"
			:btn="assigneeBtn"
			:list="managerAndRecipientForm"
			:info="info.manager"
			:disabled="isDisabledManagerForm"
			@clickEv="$bvModal.show('modal-search-user')"
		/>
		<input-form-group
			title="수신자 정보"
			content="*해당 견적에서 출력될 수신자 정보입니다"
			formCategory="recipient"
			:list="managerAndRecipientForm"
			:info="info.recipient"
		/>
		<textarea-edit-form
			title="숨김 메모"
			content="*해당 메모는 상품요청자에게 노출되지 않는 정보입니다"
			:defaultValue="info.adminMemo"
			:maxlength="500"
			@save="value => saveTextForm(value, 'adminMemo')"
		/>
		<product-info-table
			title="상품정보"
			:btnGroup="productTableBtnGroup"
			:headers="productInfoTableHeader"
			:products="info.products"
			isRemove
			@clickEv="productTableEv"
			@delete="removeProductItem"
		/>
		<textarea-edit-form
			title="메모"
			content="*해당 메모는 상품요청자에게 노출되는 정보입니다"
			:defaultValue="info.memo"
			:maxlength="500"
			@save="value => saveTextForm(value, 'memo')"
		/>
		<div class="btn-wrap bottom">
			<button class="btn btn-lg" @click="$router.go(-1)">취소</button>
			<button class="btn btn-complete btn-lg" @click="$bvModal.show('modal-common')">작성 완료</button>
		</div>

		<!--modals-->
		<modal-submit
			title="견적 작성 완료"
			content="견적서 작성 시 담당자에게도 동일한 견적이 생성됩니다.<br>가격 및 수량 정보를 다시 한 번 확인해주세요."
			btnText="작성 완료"
			isHeaderClose
			id="modal-common"
			@ok="onSubmit"
		/>
		<modal-edit-price title="할인적용" btnText="적용" :list="info.products" @ok="applyDiscount" />
		<modal-search-user title="유저 검색하기" @select="onSelectManager" />
		<modal-search-product title="상품 검색" @add="onAddProduct" />
		<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="onClickExitBtn"></modal-common>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import ButtonIncludeHeader from '@/views/common/components/header/ButtonIncludeHeader'
import InputFormGroup from '@/views/common/components/formItem/InputFormGroup'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm'
import ProductInfoTable from '@/components/product/ProductInfoTable'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ModalEditPrice from '@/views/estimate/estimate/modals/ModalEditPrice'
import ModalSearchProduct from '@/views/common/modals/ModalSearchProduct'
import ModalSearchUser from '@/views/common/modals/ModalSearchUser'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import PageName from '@/utils/define/PageName'

export default {
	name: 'EstimateForm',
	components: {
		ButtonIncludeHeader,
		InputFormGroup,
		TextareaEditForm,
		ProductInfoTable,
		ModalSubmit,
		ModalEditPrice,
		ModalSearchProduct,
		ModalSearchUser,
		ModalCommon,
	},
	data() {
		return {
			info: {
				instituteId: null,
				requestId: null,
				manager: {
					id: null,
					name: '',
					phone: '',
					email: '',
					instituteName: '',
				},
				recipient: {
					name: '',
					phone: '',
					email: '',
					instituteName: '',
				},
				products: [],
				memo: '',
				adminMemo: '',
			},
			headerBtn: {
				category: 'btn-complete',
				name: '작성완료',
			},
			assigneeBtn: {
				image: require('@/assets/svg/common/member-assignee.svg'),
				text: '담당자 지정',
			},
			productTableBtnGroup: [
				{
					key: 'search',
					image: require('@/assets/svg/common/group-search.svg'),
					text: '상품 검색하기',
				},
			],
			inputForms: ['소속', '이름', '연락처', '이메일'],
			productInfoTableHeader: [
				{ name: '상품 정보', key: 'info', width: '68%' },
				{ name: '수량', key: 'quantity', width: '70px' },
				{ name: '소비자가', key: 'price', width: '13%' },
				{ name: '판매가', key: 'sellingPrice', width: '13%' },
			],
			isDisabledManagerForm: false,
		}
	},
	computed: {
		...mapState('estimate/detail', ['item']),
		title() {
			if (this.$route.params.mode === 'add') {
				return '신규 견적서 작성하기'
			} else if (this.$route.params.mode === 'copy') {
				return '견적서 복제하기'
			}
		},
		managerAndRecipientForm() {
			let keys = ['instituteName', 'name', 'phone', 'email']
			let list = []
			this.inputForms.forEach((form, index) => {
				list.push({ name: form, key: keys[index] })
			})
			return list
		},
	},
	watch: {
		'info.products'(val) {
			if (val.length) {
				this.productTableBtnGroup[1] = {
					key: 'change-count',
					image: require('@/assets/svg/common/edit-count.svg'),
					text: '가격/수량 수정',
				}
			} else {
				this.productTableBtnGroup.splice(1, 1)
			}
		},
	},
	mounted() {
		const menuEls = document.querySelectorAll('.sub-item')
		menuEls.forEach(el => el.addEventListener('click', this.onClickSideMenu))

		if (this.$route.params.mode === 'copy') this.fetchDetail()
		if (this.$route.query.ids) this.fetchProducts(JSON.parse(this.$route.query.ids))
		if (this.$route.query.requestId) {
			this.info.requestId = this.$route.query.requestId
			this.fetchRequestProducts()
		}
		if (this.$route.query.instituteId) {
			this.info.instituteId = this.$route.query.instituteId
		}
		if (this.$route.query.manager) {
			this.info.manager = JSON.parse(this.$route.query.manager)
			this.isDisabledManagerForm = true
			this.assigneeBtn.text = '담당자 변경'
		}
	},
	beforeDestroy() {
		const menuEls = document.querySelectorAll('.sub-item')
		menuEls.forEach(el => el.removeEventListener('click', this.onClickSideMenu))
	},
	methods: {
		...mapMutations('common', ['setIsCompleteAnotherEvent']),
		...mapActions('estimate/detail', ['getDetail', 'addEstimate', 'lookupProductsByOrderItemId', 'lookupProductsByRequestId']),
		async fetchDetail() {
			await this.getDetail(this.$route.query.id)
			this.info = this.$_.cloneDeep({
				manager: {
					...this.item.manager,
					id: null,
				},
				recipient: this.item.recipient ?? {},
				products: this.item.products,
				memo: this.item.memo,
				adminMemo: this.item.adminMemo,
			})
			this.isDisabledManagerForm = true
		},
		async fetchProducts(ids) {
			const response = await this.lookupProductsByOrderItemId({ orderItemIds: ids })
			this.info.products = response.products
		},
		async fetchRequestProducts() {
			const response = await this.lookupProductsByRequestId({ requestId: this.info.requestId })
			this.info.products = response
		},
		onClickSideMenu() {
			this.$refs['modal-common'].show(
				'페이지 이동',
				'페이지를 이동하면 지금까지 작성한 내용이 사라집니다.<br/>페이지를 이동하시겠습니까?',
				'complete',
				'나가기'
			)
		},
		onClickExitBtn() {
			this.setIsCompleteAnotherEvent(true)
			this.$refs['modal-common'].hide()
		},
		onSelectManager(manager) {
			this.info.instituteId = manager.institute.id
			this.info.manager = {
				id: manager.id,
				name: manager.name,
				phone: manager.phone,
				email: manager.email,
				instituteName: manager.institute.name,
			}
			this.isDisabledManagerForm = true
			this.assigneeBtn.text = '담당자 변경'
		},
		onAddProduct(product) {
			product.quantity = 1
			product.id = product.productId
			product.price = product.unitPrice
			product.sellingPrice = product.sellingUnitPrice
			this.info.products.push(product)
		},
		saveTextForm(memo, key) {
			this.info[key] = memo
		},
		productTableEv(key) {
			if (key == 'search') {
				this.$bvModal.show('modal-search-product')
			} else if (key == 'change-count') {
				this.$bvModal.show('modal-edit-price')
			}
		},
		removeProductItem(index) {
			this.info.products.splice(index, 1)
		},
		applyDiscount(products) {
			this.info.products = products
		},
		async onSubmit() {
			if (!this.info.products.length) {
				return this.$root.toast('작성 실패', '선택된 상품이 존재하지 않습니다.', ToastType.ERROR)
			}

			try {
				await this.addEstimate(this.info)

				this.$root.toast('견적서 작성 완료', '견적서가 정상적으로 작성 되었습니다', ToastType.SUCCESS)
				this.$router.push({
					name: PageName.Estimate.EstimateList,
				})
			} catch (e) {
				this.$root.toast('작성 실패', `${e.response.data.msg}`, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss">
.EstimateForm {
	.button-include-header {
		.btn-complete {
			width: 100px;
			height: 36px;
			background-color: $PRIMARY_YELLOW;
		}
	}
	.input-form-group {
		margin-top: 32px;
	}
	.textarea-edit-form {
		width: calc(100% - 60px);
		margin: 40px auto 0;
	}
	.product-info-table {
		width: calc(100% - 60px);
		margin-top: 40px;
	}
	.bottom {
		padding: 64px 0;
	}
}
</style>
