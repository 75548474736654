<template>
	<div class="content">
		<info-list title="판매 요약" :list="sellSumInfo" />
		<div class="filter-wrapper">
			<p>판매내역</p>
			<status-counter-list :list="statusList" @active="onClickStatus" />
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="상품명, 브랜드, 판매자 검색"
				:datePlaceholder="datePlaceholder"
				:datePickerList="datePickerList"
				@searchTextList="searchTextList"
			/>
		</div>

		<div class="list-wrapper">
			<div class="title-btn-wrapper">
				<search-title
					:title="`주문 목록(${totalCount})`"
					subTitle="상품을 선택 한후 아래 주문 상태를 클릭하여 주문상태를 변경할 수 있습니다."
				/>
				<div class="right-btn-group">
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						보기옵션
					</button>
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
					<button class="btn">
						<img src="@/assets/svg/common/icon-estimate.svg" />
						견적서 작성
					</button>
				</div>
			</div>
			<order-status-changer :selectedList="checkedList" @change="sellHistoryMounted" />
			<div class="table-wrapper">
				<jw-table
					ref="jw-table"
					:width="table.width"
					:options="table.options"
					@getSelectedItem="getSelectedItem"
					@change="changeDate"
					:noData="isNoData"
					:noSearchResult="isNoSearchResult"
					:noDataText="noDataText"
					:noDataImg="noDataImg"
					:noDataSubtext="noDataSubtext"
					:noSearchResultText="noSearchResultText"
					:noSearchResultSubtext="noSearchResultSubtext"
					noDataHeight="500px"
				></jw-table>
				<common-pagenation @page="getCurrentPage" :total="totalCount"></common-pagenation>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import JwTable from '@/views/common/components/JwTable.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'
import { orderManagementTable } from '@/utils/define/table/orderManagementTable'

export default {
	name: 'SellHistory',
	components: {
		InfoList,
		OrderStatusChanger,
		SearchTitle,
		CommonPagenation,
		StatusCounterList,
		DetailSearchBox,
		JwTable,
	},
	mixins: [mixin],
	data() {
		return {
			statusList: [],
			checkedList: [],
			totalCount: 0,
			datePickerList: [{ text: '주문일' }],
			listParams: {
				offset: 0,
				length: 0,
				keyword: '',
				status: 'ALL',
			},
			table: this.$_.cloneDeep(orderManagementTable),
		}
	},
	computed: {
		...mapGetters('seller/detail', ['getSellingListSummary']),
		sellSumInfo() {
			return [{ title: '누적 판매금액', value: '12회 / 1,123,123', row: true }]
		},
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		isNoData() {
			return this.listParams.keyword.length === 0 && this.totalCount === 0
		},
		isNoSearchResult() {
			return this.listParams.keyword.length > 0 && this.totalCount === 0
		},
		noDataText() {
			return '판매자가 판매한 상품이 없습니다.'
		},
		noDataSubtext() {
			return '판매자가 상품을 판매하면, 판매한 상품을 보여드립니다.'
		},
		noSearchResultText() {
			return '검색 결과가 없습니다'
		},
		noSearchResultSubtext() {
			return '상품명, 상품번호, 자체 관리번호, 브랜드, 제품번호, CAS No로 검색할 수 있습니다.'
		},
		noDataImg() {
			return require('@/assets/svg/common/jw-table/img-nodata.svg')
		},
	},
	mounted() {
		this.sellHistoryMounted()
	},
	methods: {
		...mapActions('seller/detail', ['']),
		sellHistoryMounted() {
			this.loadListData()
			this.loadSummaryData()
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())

			// await this.lookupOrderList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			// await this.lookupOrderListSummary({ orderId: this.listParams.orderId })
			this.setSummaryData()
		},
		setData() {},
		setSummaryData() {
			this.statusList = Object.keys(this.getSellingListSummary).map(summary => {
				return {
					id: summary,
					title: summary,
					count: this.getSellingListSummary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			this.listParams.orderStartDatetime = filters['기간 조회/주문일']?.length ? filters['기간 조회/주문일'][0].value[0] : undefined
			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''
		},
		getCurrentPage(page) {
			this.listParams.offset = page.offset
			this.listParams.length = page.length
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		onClickDownloadOrderList() {},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	padding-top: 30px;
}
.filter-wrapper {
	p {
		display: flex;
		font-weight: 700;
		font-size: 20px;
		padding-bottom: 10px;
	}
}
.list-wrapper {
	margin-top: 30px;
	.title-btn-wrapper {
		display: flex;
		justify-content: space-between;
	}
}
</style>
