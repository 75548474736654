import labAPI from '@/services/api/lab'
import cookies from 'vue-cookies'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: '',
			isBlock: JSON.parse(cookies.get('filter_lab_status')) ?? [],
			instituteType: JSON.parse(cookies.get('filter_lab_type')) ?? [],
			syncWithPro: JSON.parse(cookies.get('filter_lab_sync_with_pro')) ?? [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: {},
		filterCookieName: {
			isBlock: 'filter_lab_status',
			instituteType: 'filter_lab_type',
			syncWithPro: 'filter_lab_sync_with_pro',
		},
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => state.loadParams.isBlock.length + state.loadParams.instituteType.length + state.loadParams.syncWithPro.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, _) => {
			const filter = {
				isBlock: [
					{ id: false, name: '구매 가능' },
					{ id: true, name: '구매 불가' },
				],
				instituteType: [
					{ id: 'UNIVERSITY', name: '학교' },
					{ id: 'ENTERPRISE', name: '기업' },
					{ id: 'SELLER', name: '재판매업체' },
					{ id: 'PERSON', name: '개인' },
				],
				syncWithPro: [
					{ id: true, name: '완료' },
					{ id: false, name: '미완료' },
				],
			}

			state.filter = filter
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				isBlock: JSON.parse(cookies.get('filter_lab_status')) ?? [],
				instituteType: JSON.parse(cookies.get('filter_lab_type')) ?? [],
				syncWithPro: JSON.parse(cookies.get('filter_lab_sync_with_pro')) ?? [],
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const loadParams = _.cloneDeep(state.loadParams)
			if (loadParams.isBlock.length > 0) {
				if (loadParams.isBlock.length === 2) {
					loadParams.isBlock = []
				} else if (loadParams.isBlock.length === 1) {
					loadParams.isBlock = loadParams.isBlock[0]
				}
			}
			if (loadParams.syncWithPro.length > 0) {
				if (loadParams.syncWithPro.length === 2) {
					loadParams.syncWithPro = []
				} else if (loadParams.syncWithPro.length === 1) {
					loadParams.syncWithPro = loadParams.syncWithPro[0]
				}
			}
			const response = await labAPI.lookupLabList(loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
