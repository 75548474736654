<template>
	<div class="product-total-price">
		<b class="title">{{ title }}: </b>
		<div class="sale-price">
			<span>판매가 합계</span>
			<p>{{ `${commaNum(Math.floor(sellingPrice))}원` }}</p>
		</div>
		<div class="shipping-price">
			<span>배송비</span>
			<p>{{ `${commaNum(shippingPrice)}원` }}</p>
		</div>
		<div class="vat-price">
			<span>VAT(10%)</span>
			<p>{{ `${commaNum(Math.floor(vatPrice))}원` }}</p>
		</div>
		<div class="total-price">
			<h6>{{ `${commaNum(Math.floor(totalPrice))}원` }}</h6>
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'ProductTotalPrice',
	props: {
		title: {
			type: String,
			default: '',
		},
		shippingPrice: {
			type: [Number, String],
			default: 0,
		},
		sellingPrice: {
			type: [Number, String],
			default: 0,
		},
		vatPrice: {
			type: [Number, String],
			default: 0,
		},
	},
	mixins: [mixin],
	computed: {
		totalPrice() {
			return this.sellingPrice + this.shippingPrice + this.vatPrice
		},
	},
}
</script>

<style lang="scss" scoped>
.product-total-price {
	width: 100%;
	height: 80px;
	border-top: 1px solid $COLOR_DDD;
	background-color: $HOVER_BACKGROUND;
	display: flex;
	justify-content: center;
	align-items: center;
	.title {
		font-weight: 700;
		margin-right: 20px;
		margin-bottom: 0;
	}
	> div {
		display: inline-flex;
		align-items: center;
		font-weight: 400;
		padding-left: 36px;
		position: relative;
		height: 24px;
		&:after {
			position: absolute;
			top: 2px;
			left: 10px;
			content: url(~@/assets/svg/common/plus.svg);
			width: 16px;
			height: 16px;
		}
		&.sale-price {
			padding-left: 0;
			&:after {
				display: none;
			}
		}
		&.total-price {
			padding-left: 56px;
			&:after {
				content: url(~@/assets/svg/common/equal.svg);
				left: 20px;
			}
		}
		span {
			font-size: 1rem;
			font-weight: 400;
			color: $COLOR_666;
			margin-right: 8px;
		}
		p {
			color: #111;
		}
		h6 {
			font-size: 1.25rem;
			font-weight: 700;
		}
	}
}
</style>
