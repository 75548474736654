import axiosInstance from '@/services/axios'

const endPoint = {
	lookupBrandList: () => `/admin/brand/list`,
}

const brandAPI = {
	// 제조사 목록
	lookupBrandList: params => axiosInstance.get(endPoint.lookupBrandList(), { params }),
}

export default brandAPI
