<template>
	<portal to="lm-modal">
		<div v-if="isShow" class="Modal ModalSearchRegisteringItem" @click="isSearchBoxFocused = false">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-lg">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ title }}</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="search-wrap">
						<div class="search-main-wrap">
							<small-search-box
								:placeholder="placeholderByCategoryType"
								:defaultKeyword="keyword"
								:useQr="true"
								@showCode="showSearchUsingScan"
								@change="changeKeyword"
								@search="search"
								@focus="isSearchBoxFocused = true"
							>
							</small-search-box>
							<div class="btn search-in-result" @click="useSearchInResult = !useSearchInResult">
								<span>결과 내 검색</span>
								<img class="img-arrow" :src="imgArrow" />
							</div>
							<button v-if="hasSearched && noData" class="btn direct-input" @click="inputRedirect">직접 입력하기</button>
						</div>
						<div class="search-sub-wrap" v-if="useSearchInResult">
							<small-search-box :placeholder="`결과 내 검색`" @search="search" @change="changeSecondKeyword"></small-search-box>
						</div>
					</div>
					<div class="Table__wrap" @scroll="throttleScroll" ref="table-wrap">
						<product-external-list
							:list="searchResult"
							:hasSearched="hasSearched"
							@clickItem="onClickItem"
							@clickInputRedirect="inputRedirect"
						></product-external-list>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__white" @click="hide">닫기</button>
					</div>
				</div>
			</div>
			<modal-search-item-with-code ref="modal-search-item-with-code" @scan="hideModalSearchItemUsingScan"></modal-search-item-with-code>
		</div>
	</portal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import MixinModal from '@/mixins/modal'
import SmallSearchBox from '@/views/common/components/SmallSearchBox'
import ArrowUp from '@/assets/svg/app/arrow-up.svg'
import ArrowDown from '@/assets/svg/app/arrow-down.svg'
import { getSearchProductColumns } from '@/utils/define/ModalSearchProductExternalColumn'
import { MiddleCategory } from '@/utils/define/ItemCode'
import ProductExternalList from '@/views/product/registration/components/productInfo/ProductExternalList.vue'
import ModalSearchItemWithCode from '@/views/common/modals/ModalSearchItemWithCode.vue'
import StringUtils from '@/utils/modules/StringUtils'

export default {
	name: 'ModalSearchProductExternal',
	mixins: [MixinModal],
	components: {
		SmallSearchBox,
		ProductExternalList,
		ModalSearchItemWithCode,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		categoryType: {
			type: String,
			default: MiddleCategory.REAGENT,
		},
	},
	data() {
		return {
			keyword: '',
			secondKeyword: '',
			length: 50,
			useSearchInResult: false,
			isSearchBoxFocused: false,
			throttleScroll: () => {},
			hasSearched: false,
			searchResult: [],
		}
	},
	computed: {
		...mapState('catalog/list', ['list']),
		imgArrow() {
			return this.useSearchInResult ? ArrowUp : ArrowDown
		},
		noData() {
			return this.searchResult.length === 0
		},
		tableWidthsByCategory() {
			return getSearchProductColumns(this.categoryType).widths ?? []
		},
		tableColumnsByCategory() {
			return getSearchProductColumns(this.categoryType).columns ?? []
		},
		tableWidth() {
			return this.tableWidthsByCategory.reduce((curr, prev) => curr + prev, 0) + 40 + 'px'
		},
		placeholderByCategoryType() {
			switch (this.categoryType) {
				case MiddleCategory.REAGENT:
				case MiddleCategory.GAS:
				case MiddleCategory.BIO_REAGENT:
					return '물품명, CAS No, 제품번호, 브랜드로 검색'
				case MiddleCategory.BIO_ANTIBODY:
				case MiddleCategory.BIO_CELL:
				case MiddleCategory.CHROMATOGRAPHY:
				case MiddleCategory.ETC:
					return '물품명, 제품번호, 브랜드로 검색'
				case MiddleCategory.EQUIPMENT:
				case MiddleCategory.TOOL:
				case MiddleCategory.PROTECTION:
					return '물품명, 유형, 제품번호, 브랜드로 검색해주세요'
			}
		},
	},
	mounted() {
		this.throttleScroll = this.$_.throttle(this.scroll, 200)
	},
	methods: {
		...mapActions('catalog/list', ['setLoadParams', 'getList']),
		show() {
			this.isShow = true
			this.searchResult = []
		},
		close() {
			this.useSearchInResult = false
			this.keyword = ''
			this.secondKeyword = ''
			this.hide()
		},
		async search() {
			this.setLoadParams({
				keyword: this.keyword,
				keywordType: 'TEXT', // TEMP: QR 미구현
				subKeyword: this.secondKeyword,
				categoryCode: this.categoryType,
				offset: 0,
				length: 50,
			})
			await this.getList()
			this.setList()
		},
		setList() {
			this.searchResult = this.$_.cloneDeep(
				this.list.map(item => {
					return {
						name: item.name,
						subname: item.subname,
						info: this.getCategoryInfo(item),
						src: item?.images[0],
						item: item,
					}
				})
			)
		},
		getCategoryInfo(item) {
			switch (item.category.code) {
				case MiddleCategory.REAGENT:
				case MiddleCategory.GAS:
				case MiddleCategory.BIO_REAGENT:
					return `카테고리 : ${this.getCategoryText(item.category)} | 
							브랜드 : ${item.brand.name} | 
							제품번호 : ${item.code} | 
							CAS No : ${item.casno ?? ' - '} |
							${this.getProductOptions(item.categoryDisplayOption)}`
				case MiddleCategory.BIO_ANTIBODY:
				case MiddleCategory.BIO_CELL:
				case MiddleCategory.CHROMATOGRAPHY:
				case MiddleCategory.TOOL:
					return `카테고리 : ${this.getCategoryText(item.category)} | 
							브랜드 : ${item.brand.name} | 
							제품번호 : ${item.code} | 
							CAS No : ${item.casno ?? ' - '} |
							${this.getProductOptions(item.categoryDisplayOption)} |
							규격 : ${item.spec}`
				case MiddleCategory.EQUIPMENT:
				case MiddleCategory.PROTECTION:
				case MiddleCategory.ETC:
					return `카테고리 : ${this.getCategoryText(item.category)} | 
							브랜드 : ${item.brand.name} | 
							제품번호 : ${item.code} | 
							${this.getProductOptions(item.categoryDisplayOption)} |
							규격 : ${item.spec}`
			}
		},
		getCategoryText(category) {
			return category.parents.length ? category.parents[0].korName + ' / ' + category.korName : category.korName
		},
		getProductOptions(item) {
			let result = item ? item.name + ' : ' : '사이즈(용량) : '
			if (item?.values.length) {
				item.values.forEach((value, index) => {
					result += value + (index < item.values.length - 1 ? ', ' : ' ')
				})
			} else result = result + ' - '
			return result
		},
		onClickItem(item) {
			this.selectProduct(item)
			this.hide()
		},
		inputRedirect() {
			this.$emit('clickInputRedirect')
		},
		changeKeyword(keyword) {
			this.keyword = keyword
		},
		changeSecondKeyword(keyword) {
			this.secondKeyword = keyword
		},
		async scroll() {
			const tableWrap = this.$refs['table-wrap']
			const isBottom = tableWrap.scrollTop + tableWrap.clientHeight + 300 >= tableWrap.scrollHeight
			if (isBottom) {
				this.setLoadParams({
					keyword: this.keyword,
					keywordType: 'TEXT', // TEMP: QR 미구현
					subKeyword: this.secondKeyword,
					categoryCode: this.categoryType,
					offset: 0,
					length: this.searchResult.length + 50,
				})
				await this.getList()
				this.setList()
			}
		},
		selectProduct(data) {
			this.$emit('selectProduct', data.item)
			this.close()
		},
		showSearchUsingScan() {
			this.$refs['modal-search-item-with-code'].show()
		},
		hideModalSearchItemUsingScan() {
			this.$refs['modal-search-item-with-code'].hide()
		},
		replaceHighlight(text) {
			return StringUtils.replaceHighlight(text, this.inputValue, 'span', 'jw-highlight')
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__background {
	z-index: 1000 !important;
}
.btn.search-in-result {
	margin-left: 10px !important;
	padding-right: 3px;
	.img-arrow {
		width: 28px;
		height: 24px;
	}
}
.btn.direct-input {
	margin-left: auto;
}
.ModalSearchRegisteringItem {
	.Popup__container {
		.Popup__top {
			border-bottom: 1px solid $COLOR_EEE;
		}
		.Popup__content {
			max-height: unset;
			overflow-y: initial;
			padding: 0;
			.search-wrap {
				width: 100%;
				padding: 15px 0;
				.Btn__select {
					margin-left: 10px;
				}
			}
			.search-main-wrap {
				align-items: center;
				height: auto;
				display: flex;
			}
			.search-sub-wrap {
				margin-top: 10px;
			}
			.search-box {
				width: 400px;
				border: 1px solid $INPUT_BORDER;
				border-radius: 4px;
				align-items: center;
				height: auto;
				display: flex;
				height: 30px;
				input {
					border: 0;
					width: calc(100% - 40px);
				}
				button {
					width: 24px;
					height: 24px;
					padding: 0;
					margin: 0 2px;
					&:nth-child(1) {
						border-radius: 4px;
						transition: 0.3s;
						&:hover {
							background-color: $COLOR_EEE;
						}
					}
				}
				&:focus-within {
					border: 1px solid $PRIMARY_BLACK;
					&:read-only {
						border: 1px solid $INPUT_BORDER;
					}
				}
			}
			.Table__wrap::-webkit-scrollbar {
				height: 10px;
			}
			.Table__wrap {
				display: block;
				height: 430px;
				overflow-y: auto;
				position: relative;
				width: 100%;
				overflow-x: auto;
			}
		}
		.Popup__bottom {
			border-top: 1px solid $COLOR_EEE;
		}
	}
}

.Btn__hover-balloon-w {
	.balloon {
		left: 50px;
	}
}
</style>
