import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	//라이선스 부여
	{
		path: RoutePath.Institute.LicenseCreate,
		name: PageName.Institute.LicenseCreate,
		props: true,
		component: () => import('@/views/institute/license/Create.vue'),
	},
]
