import sellerAPI from '@/services/api/seller'
import { refineAddressListTableData, refineTemplateListTableData } from '@/store/modules/dataRefiner'

const seller = {
	namespaced: true,
	state: {
		sellerInfo: {},
		sellers: {
			count: 0,
			list: [],
		},
		loadParams: {
			keyword: null,
			offset: 0,
			length: 50,
			isBlock: null,
		},
		addressList: [],
		templateList: [],
		scrollTop: 0,
	},
	getters: {
		getSellerInfo: state => state.sellerInfo,

		sellerList: state => state.sellers.list,

		getAddressList: state => state.addressList,
		getAddressListTableData: state => refineAddressListTableData(state, 'addressList'),

		getTemplateList: state => state.templateList,
		getTemplateListTableData: state => refineTemplateListTableData(state, 'templateList'),
	},
	mutations: {
		setSellerList: (state, payload) => {
			state.sellers.count = payload.count
			state.sellers.list = payload.list
		},
		setSellerInfo: (state, payload) => {
			state.sellerInfo = payload
		},
		setAddressList: (state, payload) => {
			state.addressList = payload
		},
		setTemplateList: (state, payload) => {
			state.templateList = payload
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		initParams: state => {
			state.loadParams.keyword = null
			state.loadParams.offset = 0
			state.loadParams.length = 50
			state.loadParams.isBlock = null
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		lookupSellerInfo: async ({ commit }, params) => {
			const response = await sellerAPI.lookupSellerInfo(params)
			commit('setSellerInfo', response.data)
		},
		editSellerInfo: async ({ commit }, body) => {
			return await sellerAPI.editSellerInfo(body)
		},
		getList: async ({ state, commit }) => {
			const response = await sellerAPI.getList(state.loadParams)
			commit('setSellerList', response.data)
		},
		lookupAddressList: async ({ commit }, params) => {
			const response = await sellerAPI.lookupAddressList(params)
			commit('setAddressList', response.data)
		},
		registAddress: async ({ commit }, body) => {
			return await sellerAPI.registAddress(body)
		},
		updateAddress: async ({ commit }, body) => {
			return await sellerAPI.updateAddress(body)
		},
		deleteAddress: async ({ commit }, params) => {
			return await sellerAPI.deleteAddress(params)
		},
		lookupTemplateList: async ({ commit }, params) => {
			const response = await sellerAPI.lookupTemplateList(params)
			commit('setTemplateList', response.data)
		},
		registTemplate: async ({ commit }, body) => {
			return await sellerAPI.registTemplate(body)
		},
		updateTemplate: async ({ commit }, body) => {
			return await sellerAPI.updateTemplate(body)
		},
		deleteTemplate: async ({ commit }, params) => {
			return await sellerAPI.deleteTemplate(params)
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
	},
}

export default seller
