<template>
	<b-modal ref="modal" centered size="lg">
		<template #modal-title>
			<div class="title">필터</div>
		</template>
		<slot name="modal-content"> </slot>
		<template #modal-footer>
			<div class="footer-left">
				<button class="button-gray" @click="$emit('reset')">전체 초기화</button>
			</div>
			<div class="footer-right">
				<button class="button-primary" @click="$emit('confirm')">적용</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	methods: {
		show() {
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
::v-deep {
	.modal-dialog {
		max-width: 700px;
	}
	.modal-content {
		min-height: 580px;
		.modal-body {
			max-height: 700px;
			overflow-y: auto;
		}
	}
}
</style>
