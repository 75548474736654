import axiosInstance from '@/services/axios'

const endPoint = {
	lookupEstimateList: () => `admin/estimate/list`,
	lookupEstimateFilter: () => `admin/estimate/list/filter`,
	lookupEstimate: id => `admin/estimate?id=${id}`,
	expiryEstimate: () => `admin/estimate/expiry`,
	addEstimate: () => `admin/estimate`,
	updateEstimate: () => `admin/estimate`,
	printEstimate: () => `admin/estimate/print`,
	lookupProductsByOrderItemId: () => `admin/product/orderItem`,
	lookupProductsByRequestId: () => `admin/product/request`,
}

const api = {
	lookupEstimateList: params => axiosInstance.get(endPoint.lookupEstimateList(), { params }),
	lookupEstimateFilter: () => axiosInstance.get(endPoint.lookupEstimateFilter()),
	lookupEstimate: id => axiosInstance.get(endPoint.lookupEstimate(id)),
	expiryEstimate: body => axiosInstance.patch(endPoint.expiryEstimate(), body),
	addEstimate: body => axiosInstance.post(endPoint.addEstimate(), body),
	updateEstimate: body => axiosInstance.patch(endPoint.updateEstimate(), body),
	printEstimate: body => axiosInstance.post(endPoint.printEstimate(), body),
	lookupProductsByOrderItemId: params => axiosInstance.get(endPoint.lookupProductsByOrderItemId(), { params }),
	lookupProductsByRequestId: params => axiosInstance.get(endPoint.lookupProductsByRequestId(), { params }),
}

export default api
