import { render, staticRenderFns } from "./ModalViewMsds.vue?vue&type=template&id=26c74a48&scoped=true&"
import script from "./ModalViewMsds.vue?vue&type=script&lang=js&"
export * from "./ModalViewMsds.vue?vue&type=script&lang=js&"
import style0 from "./ModalViewMsds.vue?vue&type=style&index=0&id=26c74a48&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26c74a48",
  null
  
)

export default component.exports