import buyerAPI from '@/services/api/buyer'
import cookies from 'vue-cookies'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: '',
			isVerified: JSON.parse(cookies.get('filter_buyer_verified')) ?? [],
			isNotiSMS: JSON.parse(cookies.get('filter_buyer_sms')) ?? [],
			isNotiEmail: JSON.parse(cookies.get('filter_buyer_email')) ?? [],
			isNotiPush: JSON.parse(cookies.get('filter_buyer_push')) ?? [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: {},
		filterCookieName: {
			verified: 'filter_buyer_verified',
			sms: 'filter_buyer_sms',
			email: 'filter_buyer_email',
			push: 'filter_buyer_push',
		},
		list: [],
		count: 0,
		info: {},
		filterList: ['isVerified', 'isNotiSMS', 'isNotiEmail', 'isNotiPush'],
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state =>
			state.loadParams.isVerified.length +
			state.loadParams.isNotiSMS.length +
			state.loadParams.isNotiEmail.length +
			state.loadParams.isNotiPush.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, _) => {
			const filter = {
				verified: [
					{ id: true, name: '완료' },
					{ id: false, name: '미완료' },
				],
				sms: [
					{ id: true, name: '동의' },
					{ id: false, name: '미동의' },
				],
				email: [
					{ id: true, name: '동의' },
					{ id: false, name: '미동의' },
				],
				push: [
					{ id: true, name: '동의' },
					{ id: false, name: '미동의' },
				],
			}

			state.filter = filter
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				isVerified: JSON.parse(cookies.get('filter_buyer_verified')) ?? [],
				isNotiSMS: JSON.parse(cookies.get('filter_buyer_sms')) ?? [],
				isNotiEmail: JSON.parse(cookies.get('filter_buyer_email')) ?? [],
				isNotiPush: JSON.parse(cookies.get('filter_buyer_push')) ?? [],
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const loadParams = _.cloneDeep(state.loadParams)

			state.filterList.forEach(filter => {
				if (loadParams[filter].length > 0) {
					if (loadParams[filter].length === 2) {
						loadParams[filter] = []
					} else if (loadParams[filter].length === 1) {
						loadParams[filter] = loadParams[filter][0]
					}
				} else loadParams[filter] = null
			})

			const response = await buyerAPI.lookupBuyerList(loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			await dispatch('getList')
		},
		exportExcel: async ({ state }) => {
			const loadParams = _.cloneDeep(state.loadParams)

			state.filterList.forEach(filter => {
				if (loadParams[filter].length > 0) {
					if (loadParams[filter].length === 2) {
						loadParams[filter] = []
					} else if (loadParams[filter].length === 1) {
						loadParams[filter] = loadParams[filter][0]
					}
				} else loadParams[filter] = null
			})

			const response = await buyerAPI.exportExcel(loadParams)
			return response
		},
	},
}
