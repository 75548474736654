<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="Modal ModalSearchItemWithCode Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">QR code/Barcode로 물품 정보찾기</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p>시약병에 부착된 라벨의 QR코드 혹은 바코드를 스캔해주세요.</p>
					<p>물품에 할당된 QR 스티커를 스캔해주세요.</p>
					<input ref="scan" type="text" v-model="result" @keyup.enter="search" />
					<div class="imgbox">
						<img src="@/assets/svg/app/qrcode-example1-2.png" />
						<img src="@/assets/svg/app/qrcode-example2.png" />
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__white" @click="cancel">닫기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import { ToastType } from '@/utils/define/ToastType'
import { checkKor } from '@/utils/modules/Regex'

export default {
	name: 'ModalSearchItemWithCode',
	mixins: [MixinModal],
	props: {
		loadFunction: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			result: '',
			instId: '',
		}
	},
	created() {
		this.instId = this.$route.params.instituteId
	},
	methods: {
		show() {
			this.isShow = true
			this.result = ''
			this.$nextTick(() => this.$refs['scan'].focus())
		},
		cancel() {
			this.hide()
		},
		async search() {
			const params = {
				offset: 0,
				length: 50,
				qrBarcode: this.result,
			}

			if (checkKor(this.result)) {
				this.$root.toast('QR 인식 불가', '한/영 키를 눌러 영문 입력으로 전환해주세요.', ToastType.WARNING)
				this.cancel()
				return
			}
			// try {
			// 	const response = await this.loadFunction(this.instId, params)
			// 	const count = response.data.count
			// 	if (count > 1) {
			// 		this.$emit('scan', this.result)
			// 	} else {
			// 		this.toastWrongData()
			// 	}
			// } catch (error) {
			// 	this.toastWrongData()
			// } finally {
			// 	this.cancel()
			// }
		},
		toastWrongData() {
			this.$root.toast('일치하는 데이터가 없습니다', '일치하는 데이터가 없습니다.<br/>텍스트로 검색해주세요', ToastType.WARNING)
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalSearchItemWithCode {
	.Popup__content {
		p {
			font-size: 1rem;
			font-weight: 400;
			position: relative;
			padding-left: 20px;
			&:before {
				width: 3px;
				height: 3px;
				background-color: #000;
				border-radius: 3px;
				content: '';
				position: absolute;
				top: 50%;
				left: 8px;
				transform: translate(0, -50%);
			}
		}

		input[type='text'] {
			opacity: 0;
			position: absolute;
			left: 0px;
			top: 0px;
			-webkit-ime-mode: disabled;
			-moz-ime-mode: disabled;
			-ms-ime-mode: disabled;
			ime-mode: disabled;
		}
		.imgbox {
			width: 100%;
			margin-top: 20px;
			img {
				width: calc(50% - 5px);
				overflow: hidden;
				border-radius: 10px;
				&:first-child {
					margin-right: 5px;
				}
				&:last-child {
					margin-left: 5px;
				}
			}
		}
	}
}
</style>
