import orderAPI from '@/services/api/order'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			orderStartDatetime: '',
			orderEndDatetime: '',
		},
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,

		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: null,
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
				orderStartDatetime: '',
				orderEndDatetime: '',
			}
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await orderAPI.lookupOrderBulkList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
