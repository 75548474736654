<template>
	<tr class="table-item" style="cursor: default">
		<td>
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status', status]"> {{ item.status }} </span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.type.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span style="text-decoration: underline; cursor: pointer" @click="goToDetail">
					{{ item.id }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; max-width: 250px">
				<span>
					{{ item.orderItemNo ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 250px; max-width: 300px">
				<span>content</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.user.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.user.phone ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.institute.name ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.lastCommentDatetime ? $DateTime.fromISO(item.lastCommentDatetime).toFormat('yyyy.MM.dd T') : '-' }}
				</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import MixinItem from '@/mixins/table/item'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		status() {
			return this.item.status === '답변완료' ? 'COMPLETE' : ''
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'InquiryDetail',
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
