<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ item?.orderItem.orderNo }}-{{ item?.orderItem.orderItemId }}</h2>
				<h6>
					<span :class="['status', item?.csStatus?.code]"> {{ item?.csStatus?.name }} </span>
					<p>접수일시: {{ item?.cancelRequestDatetime ? $DateTime.fromISO(item.cancelRequestDatetime).toFormat('yyyy.MM.dd T') : '-' }}</p>
				</h6>
			</div>
			<div class="button-groups" v-if="item?.csStatus.code == 'ING'">
				<base-button color="warning" size="md" @click="onClickReject">거절</base-button>
				<base-button color="accept" size="md" @click="onClickComplete">처리 완료</base-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="접수 정보" :list="contentInfo" style="margin-bottom: 30px" />
			<info-list v-if="item?.csStatus.code !== 'ING'" title="처리 정보" :list="processInfo" style="margin-bottom: 30px" />
			<div>
				<textarea-edit-form
					title="숨김 메모"
					content="*해당 메모는 상품요청자에게 노출되지 않는 정보입니다"
					:defaultValue="item?.adminMemo"
					@save="saveMemo"
				/>
			</div>
			<info-list title="판매자 정보" :list="sellerInfo" />
			<div>
				<h5>연결된 주문 아이템</h5>
				<table class="order-table sm-table">
					<order-header />
					<order-item v-if="item" :item="item.orderItem" />
				</table>
			</div>
		</div>

		<modal-cancel-refund-complete
			:info="modalInfo"
			:isShow="isShowCompleteModal"
			@hide="isShowCompleteModal = false"
		></modal-cancel-refund-complete>
		<modal-cancel-refund-reject
			:info="modalInfoReject"
			:isShow="isShowRejectModal"
			@hide="isShowRejectModal = false"
		></modal-cancel-refund-reject>
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { mapActions, mapState } from 'vuex'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import OrderHeader from '../../components/order/header.vue'
import OrderItem from '../../components/order/item.vue'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
import ModalCancelRefundComplete from '@/views/cs/cancelRefund/modal/ModalCancelRefundComplete.vue'
import ModalCancelRefundReject from '@/views/cs/cancelRefund/modal/ModalCancelRefundReject.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	components: {
		BaseButton,
		InfoList,
		OrderHeader,
		OrderItem,
		TextareaEditForm,
		ModalCancelRefundComplete,
		ModalCancelRefundReject,
	},
	computed: {
		...mapState('cancelRefund/detail', ['item']),
		contentInfo() {
			let result = [
				{
					title: '접수일시',
					value: this.item?.cancelRequestDatetime ? this.$DateTime.fromISO(this.item.cancelRequestDatetime).toFormat('yyyy.MM.dd T') : '-',
				},
				{ title: '접수 유형', value: this.item?.csType.name, type: 'bold' },
			]

			if (this.item?.csType.code === 'CANCEL') {
				if (this.item.cancelSource === 'USER') {
					result.push({ title: '접수자', value: this.item?.cancelRequestUser ?? '-' })
					result.push({ title: '접수자 연락처', value: this.item?.cancelRequestUser.phone ?? '-' })
					result.push({ title: '취소 사유', value: this.item?.cancelReason ?? '-', isSingleRow: true })
					result.push({ title: '취소 상세사유', value: this.item?.cancelReasonDetail ?? '-', isSingleRow: true })
				} else if (this.item.cancelSource === 'ADMIN') {
					result.push({ title: '취소 상세사유', value: this.item?.cancelReasonDetail ?? '-', isSingleRow: true })
				}
			} else if (this.item?.csType.code === 'RETURN') {
				if (this.item.returnRequestUser) {
					result.push({ title: '접수자', value: this.item?.returnRequestUser ?? '-' })
					result.push({ title: '접수자 연락처', value: this.item?.returnRequestUser.phone ?? '-' })
				}
				result.push({ title: '접수내용', value: this.item?.returnRequestReason ?? '-', isSingleRow: true })
			}

			return result
		},
		processInfo() {
			if (this.item?.csStatus.code === 'REJECT') {
				return [
					{
						title: '처리일시',
						value:
							this.item?.csType.code === 'CANCEL'
								? this.$DateTime.fromISO(this.item?.cancelRequestRejectDatetime).toFormat('yyyy.MM.dd T')
								: this.$DateTime.fromISO(this.item?.returnRejectDatetime).toFormat('yyyy.MM.dd T'),
					},
					{ title: '처리상태', value: this.item?.csStatus.name },
					{ title: '취소 상세사유', value: this.item?.csType.code === 'CANCEL' ? this.item?.cancelResponse : this.item?.returnResponse },
				]
			} else {
				return [
					{
						title: '처리일시',
						value:
							this.item?.csType.code === 'CANCEL'
								? this.$DateTime.fromISO(this.item?.cancelDatetime).toFormat('yyyy.MM.dd T')
								: this.$DateTime.fromISO(this.item?.returnDatetime).toFormat('yyyy.MM.dd T'),
					},
					{ title: '처리상태', value: this.item?.csStatus.name },
					{ title: '취소 상세사유', value: this.item?.csType.code === 'CANCEL' ? this.item?.cancelResponse : this.item?.returnResponse },
				]
			}
		},
		sellerInfo() {
			return [
				{ title: '판매자 이름', value: this.item?.seller.name },
				{ title: '담당자', value: this.item?.seller.contactName },
				{ title: '연락처', value: this.item?.seller.contactPhone },
				{ title: '이메일', value: this.item?.seller.email },
			]
		},
		modalInfo() {
			return {
				title: this.item?.csType.code === 'CANCEL' ? '주문취소' : '교환/반품 처리',
				text:
					this.item?.csType.code === 'CANCEL'
						? '정말로 주문을 취소하시겠습니까?<br>한번 취소한 주문은 되돌릴 수 없습니다.'
						: '정말로 교환반품 처리 하시겠습니까?<br>한번 교환/반품 처리한 주문은 되돌릴 수 없습니다.',
				textAreaTitle: this.item?.csType.code === 'CANCEL' ? '취소 상세사유' : '교환/반품 상세사유',
				placeholder: this.item?.csType.code === 'CANCEL' ? '취소 상세사유를 입력해주세요' : '교환/반품 상세사유를 입력해주세요',
				btnText: this.item?.csType.code === 'CANCEL' ? '주문취소' : '처리 완료',
				type: this.item?.csType.code,
				id: this.$route.params.id,
			}
		},
		modalInfoReject() {
			return {
				title: '요청 거절',
				text:
					this.item?.csType.code === 'CANCEL'
						? '주문취소 요청을 거절하고 <b>승인 완료</b> 상태로 되돌립니다.<br>선택한 상품의 취소를 거절하시겠습니까?'
						: '교환/반품 요청을 거절하고 <b>이전</b> 상태로 되돌립니다.<br>선택한 상품의 취소를 거절하시겠습니까?',
				textAreaTitle: '거절 상세사유 입력',
				placeholder: '거절사유를 입력해주세요',
				btnText: '거절',
				type: this.item?.csType.code,
				id: this.$route.params.id,
			}
		},
	},
	data() {
		return {
			isShowCompleteModal: false,
			isShowRejectModal: false,
		}
	},
	async created() {
		await this.getDetail(this.$route.params.id)
	},
	methods: {
		...mapActions('cancelRefund/detail', ['getDetail', 'setMemo']),
		onClickComplete() {
			this.isShowCompleteModal = true
		},
		onClickReject() {
			this.isShowRejectModal = true
		},
		async saveMemo(value) {
			try {
				await this.setMemo({
					id: this.$route.params.id,
					adminMemo: value,
				})
				this.$root.toast('메모 작성 완료', '숨김 메모를 작성 했습니다.', ToastType.SUCCESS)
				await this.getDetail(this.$route.params.id)
			} catch (e) {
				this.$root.toast('메모 작성 실패', e.response.data.msg, ToastType.SUCCESS)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.sm-table {
	min-height: 0 !important;
}
</style>
