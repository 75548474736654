import faqAPI from '@/services/api/lms/faq'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			offset: 0,
			length: 50,
			categoryIds: [0],
		},
		list: [],
		detail: {},
		faqCategories: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => state.loadParams.categoryIds[0],
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setDetail: (state, data) => {
			state.detail = data
		},
		setFaqCategories: (state, data) => {
			state.faqCategories = []
			state.faqCategories.push({ id: 0, code: 'ALL', name: '모든 유형' })
			data.list.forEach(item => {
				state.faqCategories.push(item)
			})
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				if (key === 'status') {
					state.loadParams.categoryIds = []
					state.loadParams.categoryIds.push(loadParams[key])
				} else state.loadParams[key] = loadParams[key]
			})
		},
		initParams: state => {
			state.loadParams = {
				keyword: null,
				offset: 0,
				length: 50,
				categoryIds: [0],
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const params = {
				...state.loadParams,
			}
			if (state.loadParams.categoryIds[0] === 0) params.categoryIds = []
			const response = await faqAPI.list(params)
			commit('setList', response.data)
		},
		getFaqCategories: async ({ state, commit }) => {
			const response = await faqAPI.lookupFaqCategories()
			commit('setFaqCategories', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		lookupFaq: async ({ state, commit }, params) => {
			const response = await faqAPI.lookupFaq(params)
			commit('setDetail', response.data)
		},
		createFaq: async ({ commit }, body) => {
			return await faqAPI.createFaq(body)
		},
		updateFaq: async ({ commit }, body) => {
			return await faqAPI.updateFaq(body)
		},
	},
}
