import approvalAPI from '@/services/api/product/approval'
import cookies from 'vue-cookies'
import _ from 'lodash'
import { approvalProductTableAllColumns } from '@/utils/define/table/approvalProductTable'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: '',
			status: [],
			hasStock: JSON.parse(cookies.get('filter_approval_stock')) ?? [],
			categoryCodes: JSON.parse(cookies.get('filter_approval_category')) ?? [],
			shippingAddressIds: JSON.parse(cookies.get('filter_approval_shippingaddress')) ?? [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: null,
		filterCookieName: {
			stock: 'filter_approval_stock',
			category: 'filter_approval_category',
			shippingaddress: 'filter_approval_shippingaddress',
		},
		visibleColumns: JSON.parse(cookies.get('visible_approval_columns')) ?? approvalProductTableAllColumns,
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (state.loadParams.status.length == 1) return state.loadParams.status[0]
			return 'ALL'
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => state.loadParams.hasStock.length + state.loadParams.categoryCodes.length + state.loadParams.shippingAddressIds.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		setFilter: (state, data) => {
			const result = {
				stock: [
					{ id: true, name: '재고있음' },
					{ id: false, name: '재고없음' },
				],
				quick: [
					{ id: true, name: '퀵서비스 있음' },
					{ id: false, name: '퀵서비스 없음' },
				],
				contact: [
					{ id: true, name: '개별연락 있음' },
					{ id: false, name: '개별연락 없음' },
				],
			}
			for (let key in data) {
				const filters = []
				for (let obj of data[key]) {
					if (key === 'category') {
						filters.push({ id: obj.code, name: obj.korName })
					} else if (key === 'shippingaddress') {
						filters.push({ id: obj.id, name: obj.name })
					}
				}
				result[key] = filters
			}
			state.filter = result
		},
		setVisibleColumn: (state, data) => {
			state.visibleColumns = data
			cookies.set('visible_approval_columns', JSON.stringify(data))
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				status: [],
				hasStock: JSON.parse(cookies.get('filter_approval_stock')) ?? [],
				categoryCodes: JSON.parse(cookies.get('filter_approval_category')) ?? [],
				shippingAddressIds: JSON.parse(cookies.get('filter_approval_shippingaddress')) ?? [],
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const loadParams = _.cloneDeep(state.loadParams)
			if (loadParams.hasStock.length > 0) {
				if (loadParams.hasStock.length === 2) {
					loadParams.hasStock = []
				} else if (loadParams.hasStock.length === 1) {
					loadParams.hasStock = loadParams.hasStock[0]
				}
			}

			const response = await approvalAPI.lookupApprovalProductList(loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		getFilter: async ({ commit }) => {
			const response = await approvalAPI.lookupApprovalFilter()
			commit('setFilter', response.data)
		},
		approveApprovalProduct: async ({ commit }, body) => {
			return await approvalAPI.approveApprovalProduct(body)
		},
		rejectApprovalProduct: async ({ commit }, body) => {
			return await approvalAPI.rejectApprovalProduct(body)
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
