<template>
	<portal to="lm-modal">
		<div class="TemplateEditPopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">고객상담 템플릿 {{ type }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="{ error: !isValidName }">
						<p>
							고객상담 템플릿 이름
							<span class="required">*</span>
						</p>
						<input type="text" v-model="info.name" placeholder="템플릿 이름을 입력해주세요" @input="changeName" />
						<span>템플릿 이름을 입력해주세요</span>
					</div>
					<div class="Popup__input-field" :class="{ error: !isValidPhone }">
						<p>
							고객상담 연락처
							<span class="required">*</span>
						</p>
						<input type="text" v-model="info.tel" placeholder="연락처를 입력해주세요" @input="autoHypenTel" />
						<span>{{ phoneValidText }}</span>
					</div>
					<div class="Popup__input-field">
						<p>상담 안내</p>
						<textarea v-model="info.content" placeholder="상담 안내를 입력해주세요" />
					</div>
					<label v-if="!isDefaultTemplate"><input type="checkbox" v-model="info.isSetDefaultTemplate" />기본 템플릿으로 설정</label>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="clickDone">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import Validation from '@/utils/Validation'
import { mapActions } from 'vuex'

export default {
	name: 'ModalTemplateManagement',
	data() {
		return {
			isShow: false,
			isValidName: true,
			isValidPhone: true,
			phoneValidText: '',
			info: {
				id: 0,
				name: '',
				tel: '',
				content: '',
				isSetDefaultTemplate: false,
			},
			type: '',
			isDefault: false,
		}
	},
	computed: {
		isDefaultTemplate() {
			return this.type === '수정' && this.isDefault
		},
	},
	methods: {
		...mapActions('seller/list', ['registTemplate', 'updateTemplate']),
		async show(type, data) {
			this.isValidName = true
			this.isValidPhone = true
			this.isShow = true
			this.type = type

			if (this.type === '수정') {
				const templateData = this.$_.cloneDeep(data)
				this.isDefault = (templateData?.isSetDefaultTemplate || templateData.isDefault) ?? false
				this.info = templateData
			}
		},
		hide() {
			this.isShow = false
		},
		changeName(e) {
			const name = e.target.value
			this.isValidName = true
			this.setName(name)
		},
		validPhone() {
			if (this.info.tel?.length > 0) {
				if (!/^[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/.test(this.info.tel)) {
					this.isValidPhone = false
					this.phoneValidText = '연락처를 형식에 맞게 입력해주세요'
				}
			} else {
				this.isValidPhone = false
				this.phoneValidText = '연락처를 입력해주세요'
			}
		},
		async clickDone() {
			const formData = new FormData()

			this.validPhone()
			if (!(this.info.name?.length > 0)) {
				this.isValidName = false
			}

			if (!this.isValidName || !this.isValidPhone) {
				return false
			}

			if (this.type === '등록') {
				const body = {
					name: this.info.name,
					tel: this.info.tel,
					content: this.info.content,
					isDefault: this.info.isSetDefaultTemplate,
				}
				await this.registTemplate(body)
			} else if (this.type === '수정') {
				const body = {
					id: this.info.id,
					name: this.info.name,
					tel: this.info.tel,
					content: this.info.content,
					isDefault: this.info.isSetDefaultTemplate,
				}
				await this.updateTemplate(body)
			}

			this.showEditSuccessToast()
			this.$emit('refresh')
			this.clearInput()
			this.hide()
		},
		showEditSuccessToast() {
			const toastTitle = `템플릿 ${this.type} 완료`
			const toastContent = `템플릿이 ${this.type}되었습니다`
			this.$root.toast(toastTitle, toastContent, 'success')
		},
		clearInput() {
			this.info = {
				id: 0,
				name: '',
				phone: '',
				content: '',
				isSetDefaultTemplate: false,
			}
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.isValidPhone = true
			this.setPhone(phoneNumber)
		},
		setPhone(phone) {
			this.info.tel = phone
		},
		setName(name) {
			this.info.name = name
		},
	},
}
</script>

<style lang="scss" scoped>
.TemplateEditPopup {
	label {
		font-weight: 400;
		font-size: 14px;
		input {
			margin-right: 10px;
		}
	}
	.Popup__input-field {
		margin-top: 20px;
		.flex {
			button {
				background: #f2f3f4;
			}
		}
		&.error {
			input {
				border-color: #ff5746;
			}
			> span {
				display: block;
			}
		}
		> span {
			font-size: 0.75rem;
			color: #ff5746;
			font-weight: 400;
			position: relative;
			height: 17px;
			display: none;
			padding-left: 16px;
			padding-top: 2px;
			&:before {
				position: absolute;
				width: 12px;
				height: 12px;
				content: url(~@/assets/svg/common/noti-error.svg);
				top: 4px;
				left: 0;
			}
		}
	}
}
</style>
