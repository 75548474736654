<template>
	<portal to="lm-modal">
		<div class="modal-confirm-delete-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">브랜드 삭제</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">선택한 브랜드를 삭제하시겠습니까?<br />한 번 삭제한 브랜드는 되돌릴 수 없습니다.</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__delete" @click="complete">삭제</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'

export default {
	name: 'ModalConfirmDelete',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		...mapActions('cancelRefund/detail', ['setReturn', 'setCancel', 'getDetail']),
		hide() {
			this.$emit('hide')
		},
		async complete() {
			// if (this.reason === '') return this.$root.toast('에러', '상세사유를 입력해주세요.', ToastType.ERROR)
			// try {
			// 	if (this.info.type === 'CANCEL') {
			// 		await this.setCancel({
			// 			id: this.info.id,
			// 			cancelResponse: this.reason,
			// 		})
			// 		this.$root.toast('주문취소 완료', '주문을 취소 처리 했습니다.', ToastType.SUCCESS)
			// 	} else {
			// 		await this.setReturn({
			// 			id: this.info.id,
			// 			returnResponse: this.reason,
			// 		})
			// 		this.$root.toast('교환/반품 처리 완료', '교환/반품 처리 했습니다.', ToastType.SUCCESS)
			// 	}
			// 	await this.getDetail(this.info.id)
			// 	this.hide()
			// } catch (error) {
			// 	this.$root.toast('에러', error.response.data.msg, ToastType.ERROR)
			// }
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-confirm-delete-wrapper {
	.Popup__container {
		.Popup__content {
			width: 500px;
			font-size: 14px;
		}
	}
}
</style>
