<template>
	<div class="item-wrapper">
		<div class="item-title" v-if="title">{{ title }}<span v-if="required" class="required">*</span></div>
		<div class="item-content">
			<slot></slot>
			<div v-if="subContentText" class="item-sub-text" v-html="subContentText"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseItem',
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.item-wrapper {
	display: flex;
	width: 100%;
	height: auto;
	min-height: 50px;
	padding: 15px 0;
	border-bottom: 1px solid $INPUT_BORDER;
	.item-title {
		width: 250px;
		padding: 5px 0;
		text-align: left;
		font-size: 14px;
	}
	.item-content {
		width: 100%;
		text-align: left;
		font-size: 14px;
		.item-sub-text {
			padding-top: 8px;
			font-size: 12px;
			color: $COLOR_999;
		}
	}
}
</style>
