<template>
	<li class="menu-item">
		<div class="flex" @click="goPage(item.routeName)">
			<img :src="require(`@/assets/svg/app/` + item.icon)" />
			<b @click="$emit('toggleMenu')">{{ item.name }}</b>
			<img class="arrow" :class="{ open: opened }" src="@/assets/svg/app/sidebar-arrow.svg" />
		</div>
		<app-side-menu-sub :list="item.list" :isOpen="opened" @goPage="goPage" @showSetting="showSetting" />
	</li>
</template>

<script>
import AppSideMenuSub from '@/views/app/components/AppSideMenu/components/AppSideMenuSub.vue'

export default {
	name: 'AppSideMenuItem',
	components: {
		AppSideMenuSub,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		index: {
			type: Number,
			default: 0,
		},
		opened: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		goPage(routeName) {
			if (routeName) {
				this.$router
					.push({
						name: routeName,
						// params: { instituteId: this.institute.id },
					})
					.catch(() => {})
			} else {
				this.$emit('toggleMenu')
			}
		},
		showSetting() {
			this.$emit('showSetting')
		},
	},
}
</script>

<style lang="scss" scoped>
.menu-item {
	width: 100%;
	.flex {
		width: 100%;
		height: 55px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		padding-right: 16px;
		cursor: pointer;
		background-color: $SIDEBAR_DEFAULT;
		transition: all 0.3s;
		&:hover {
			background-color: $SIDEBAR_SUB_HOVER;
		}
		img {
			width: 24px;
			height: 24px;
			margin-left: 28px;
		}
		b {
			font-size: 0.875rem;
			font-weight: 500;
			margin-left: 7px;
		}
		.arrow {
			margin-left: auto;
			&.open {
				transform: rotate(180deg);
			}
		}
	}
}
</style>
