<template>
	<div class="product-info-wrapper">
		<div>
			<h5 class="form-header-title">판매자 정보</h5>
			<base-item title="판매자" :required="true">
				<button class="btn seller-search-button" @click="$bvModal.show('modal-search-seller')">
					<img src="@/assets/svg/common/member-assignee.svg" alt="" />
					<span>판매자 검색</span>
				</button>
				<div v-if="seller.id" class="search-info">
					<div class="search-info-title">판매자 이름</div>
					<div class="search-info-data">
						{{ seller.name }}
					</div>
				</div>
				<div v-else class="search-info">
					<div class="search-info-void">상품을 판매하는 판매자를 지정해주세요.</div>
				</div>
			</base-item>
		</div>
		<div>
			<h5 class="form-header-title">카테고리</h5>
			<base-item title="카테고리 선택" :required="true">
				<select-category
					ref="category-selector"
					:large="categoryList"
					:showErrorMessage="true"
					:showSelectedCategoryMessage="true"
					@selectCategory="onSelectCategory"
				></select-category>
			</base-item>
		</div>
		<div>
			<h5 class="form-header-title">물품 검색</h5>
			<base-item title="물품 검색" :subContentText="productSearchSubContentText">
				<button class="btn product-search-button" @click="onClickSearchProductBtn" :disabled="!selectedCategory">
					<img src="@/assets/svg/registration/search-icon.svg" alt="" />
					<span>물품 검색하기</span>
				</button>
			</base-item>
		</div>
		<div>
			<h5 class="form-header-title">상품 유형</h5>
			<InputRadio
				title="상품 유형"
				required
				:list="typeList"
				:tab="productInfo?.type ?? 'NORMAL'"
				@changeValue="value => setInfo('type', value.id)"
			/>
		</div>
		<div>
			<h5 class="form-header-title">물품 정보</h5>
			<base-item title="브랜드" :required="true">
				<button class="btn brand-search-button" @click="$bvModal.show('modal-search-brand')">
					<img src="@/assets/svg/common/member-assignee.svg" alt="" />
					<span>브랜드 검색</span>
				</button>
				<div v-if="brand.id" class="search-info">
					<span class="search-info-title"> 브랜드 이름 </span>
					<span class="search-info-data">
						{{ brand.name }}
					</span>
				</div>
				<div v-else class="search-info">
					<div class="search-info-void">상품의 브랜드를 지정해주세요.</div>
				</div>
			</base-item>
			<product-info-form
				v-if="productInfoFields.length"
				ref="product-info-fields"
				:fields="productInfoFields"
				@changeValue="onChangeValue"
			></product-info-form>
			<base-item v-else title="물품 정보" :required="true">
				<div class="no-selected-category">
					<span>카테고리를 선택해주세요</span>
				</div>
			</base-item>
		</div>
		<div v-if="productInfoDetailFields.length">
			<h5 class="form-header-title">물품 상세정보</h5>
			<product-info-form
				ref="product-detail-info-fields"
				:fields="productInfoDetailFields"
				@changeValue="onChangeValue"
				@changeOption="onChangeOption"
			></product-info-form>
		</div>
		<div v-if="isShowMsds">
			<h5 class="form-header-title">MSDS</h5>
			<input-msds :msdsInfo="msdsInfo" :msds="productInfo?.msds ?? []" @changeInfo="onChangeMsds"></input-msds>
		</div>

		<modal-search-product-external
			ref="modal-search-product-external"
			title="등록할 물품 검색"
			:categoryType="selectedCategory?.option.code"
			@selectProduct="onSelectProduct"
		></modal-search-product-external>
		<modal-common ref="modal-common" @isHide="$refs['modal-common'].hide()" @complete="onClickCompleteBtn"></modal-common>
		<modal-search-seller title="판매자 검색하기" @select="onSelectSeller" />
		<modal-search-brand title="브랜드 검색하기" @select="onSelectBrand" />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import VarType from '@/utils/define/VarType'
import SelectCategory from '@/views/product/registration/components/productInfo/SelectCategory.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import ModalSearchProductExternal from '@/views/product/registration/modals/ModalSearchProductExternal.vue'
import ProductInfoForm from '@/views/product/registration/components/productInfo/ProductInfoForm.vue'
import InputMsds from '@/views/common/components/formItem/InputMsds.vue'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import ModalSearchSeller from '@/views/common/modals/ModalSearchSeller'
import ModalSearchBrand from '@/views/common/modals/ModalSearchBrand'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'

export default {
	name: 'ProductInfo',
	components: {
		SelectCategory,
		BaseItem,
		ModalSearchProductExternal,
		ProductInfoForm,
		InputMsds,
		ModalCommon,
		ModalSearchSeller,
		ModalSearchBrand,
		InputRadio,
	},
	data() {
		return {
			selectedCategory: null,
			showModalSearchProductExternal: false,
		}
	},
	computed: {
		...mapState('productRegist/regist', ['seller', 'brand', 'categoryList', 'productInfo', 'sellInfo']),
		productSearchSubContentText() {
			let text = '카테고리를 먼저 선택해주세요'
			if (this.selectedCategory) text = '판매할 물품을 검색하고 물품 정보를 불러올 수 있습니다'
			return text
		},
		productInfoFields() {
			if (!this.selectedCategory || !this.selectedCategory.option.fields) return []
			const { INVENTORY } = this.selectedCategory.option.fields
			return this.refineProductInfoFormFields(INVENTORY)
		},
		productInfoDetailFields() {
			if (!this.selectedCategory || !this.selectedCategory.option.fields) return []
			const { MANAGEMENT } = this.selectedCategory.option.fields
			return this.refineProductInfoFormFields(MANAGEMENT)
		},
		isShowMsds() {
			return this.productInfoDetailFields.some(({ key }) => key === 'msds')
		},
		msdsInfo() {
			return this.productInfo?.msdsInfo ?? {}
		},
		typeList() {
			return [
				{
					id: 'NORMAL',
					title: '일반상품',
					name: 'NORMAL',
				},
				{
					id: 'PURCHASE_AGENT',
					title: '구매대행',
					name: 'PURCHASE_AGENT',
				},
			]
		},
	},
	created() {
		this.fetchCategoryList()
	},
	mounted() {
		if (!this.$_.isEmpty(this.productInfo)) this.bindProductInfo()
	},
	methods: {
		...mapMutations('productRegist/regist', ['setSeller', 'setBrand', 'setProductInfo', 'setSellInfo']),
		...mapActions('productRegist/regist', ['lookupCategoryList']),
		bindProductInfo() {
			const productInfo = this.$_.cloneDeep(this.productInfo)
			const category = this.categoryList.find(category => category.children.find(child => child.code === productInfo.category.option.code))
			this.$refs['category-selector'].setData(productInfo, category)
			this.selectedCategory = productInfo.category

			this.$nextTick(() => {
				productInfo.code = productInfo.productCode
				this.$refs['product-info-fields'].setProductInfo(productInfo)
				this.$refs['product-detail-info-fields'].setProductInfo(productInfo)
			})
		},
		async fetchCategoryList() {
			try {
				await this.lookupCategoryList()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		async onSelectCategory(select) {
			if (select.classification === 'large') {
				this.selectedCategory = null
			} else if (select.classification === 'middle') {
				this.selectedCategory = select
			}

			this.setProductInfo({})
			this.setInfo('category', this.selectedCategory)

			const sellInfo = this.$_.cloneDeep(this.sellInfo)
			sellInfo.optionNames = this.selectedCategory?.option?.requiredOptionNames
				? this.selectedCategory.option.requiredOptionNames.map(name => {
						return { value: name, complete: true, disabled: true }
				  })
				: []
			this.setSellInfo(sellInfo)
		},
		onClickSearchProductBtn() {
			this.$refs['modal-common'].show('물품 검색', '물품 검색 시 이미 입력한 물품정보가 사라집니다.', 'complete', '진행하기')
		},
		onClickCompleteBtn() {
			this.$refs['modal-search-product-external'].show()
		},
		onSelectProduct(product) {
			if (!this.productInfoFields.length) return

			this.$refs['product-info-fields'].setProductInfo(product)
			if (this.productInfoDetailFields.length) this.$refs['product-detail-info-fields'].setProductInfo(product)

			const fields = this.selectedCategory.option.fields
			const productInfo = this.$_.cloneDeep(this.productInfo)
			Object.entries(fields).forEach(([_, value]) => {
				value.forEach(item => {
					if (item.key === 'productCode') {
						productInfo.productCode = product.code
					} else if (item.key === 'msds') {
						if (product[item.key]?.brand) productInfo.msdsInfo = product[item.key]
					} else {
						productInfo[item.key] = product[item.key]
					}
				})
			})
			this.setProductInfo(productInfo)
			this.setBrand(productInfo.brand)
			if (product.images && product.images[0]) {
				const sellInfo = this.$_.cloneDeep(this.sellInfo)
				sellInfo.images = product.images
				this.setSellInfo(sellInfo)
			}
			this.$root.toast('물품 선택 완료', '선택한 물품 정보를 불러왔습니다.', ToastType.SUCCESS)
		},
		onSelectSeller(seller) {
			this.setSeller(seller)
		},
		onSelectBrand(brand) {
			this.setBrand(brand)
		},
		refineProductInfoFormFields(fields) {
			const productInfoFields = []
			for (const { isRequire, key, name, varType, unit } of fields) {
				if (key == 'brand') {
					continue
				}

				const productInfoField = {
					required: isRequire,
					key: key,
					name: name,
					formType: '',
					inputType: '',
					value: '',
				}

				// 분석용 칼럼 - 분석용 칼럼
				if (key === 'phRange') {
					productInfoField.formType = 'inputRange'
					productInfoFields.push(productInfoField)
					continue
				}
				// 화학 물질 - 시약
				if (key === 'msds') {
					productInfoFields.push(productInfoField)
					continue
				}

				switch (varType) {
					case VarType.TEXT_SINGLE_LINE:
						// 일반 텍스트 입력
						productInfoField.formType = 'inputText'
						productInfoField.inputType = 'text'
						break
					case VarType.TEXT_SINGLE_LINE_WITH_UNIT:
						// 일반 텍스트 + 단위
						productInfoField.formType = 'inputSelect'
						productInfoField.inputType = 'text'
						productInfoField.unitList = unit.map(item => {
							return { name: item, value: item }
						})
						break
					case VarType.NUMBER:
						// 숫자
						productInfoField.formType = 'inputText'
						productInfoField.inputType = 'number'
						break
					case VarType.NUMBER_WITH_UNIT:
						// 숫자 + 단위
						productInfoField.formType = 'inputSelect'
						productInfoField.inputType = 'number'
						productInfoField.unitList = unit.map(item => {
							return { name: item, value: item }
						})
						break
					default:
						productInfoField.formType = 'inputText'
						productInfoField.inputType = 'text'
				}
				productInfoFields.push(productInfoField)
			}
			return productInfoFields
		},
		onChangeValue(value, { key }) {
			if (key === 'phRange') {
				const phRange = value.split('-')
				this.setInfo('phRangeFrom', phRange[0])
				this.setInfo('phRangeTo', phRange[1])
			} else {
				this.setInfo(key, value)
			}
		},
		onChangeOption(value, { key }) {
			this.setInfo(`${key}Unit`, value)
		},
		onChangeMsds(value) {
			this.setInfo('msds', value)
		},
		setInfo(key, value) {
			const productInfo = this.$_.cloneDeep(this.productInfo)
			productInfo[key] = value
			this.setProductInfo(productInfo)
		},
		valid() {
			return !!this.productInfo.category && this.isValidProductInfoForm() && !!this.seller.id && !!this.brand.id
		},
		isValidProductInfoForm() {
			let isValid = true
			const fields = this.productInfo?.category?.option?.fields
			if (!fields) return false

			Object.entries(fields).forEach(([_, value]) => {
				const inValid = value.some(item => {
					if (item.key == 'brand') {
						return false
					}
					if (item.isRequire === true && !this.productInfo[item.key]) {
						return true
					}
				})
				if (inValid === true) isValid = !inValid
			})
			return isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.product-info-wrapper {
	width: 100%;
	position: relative;
	padding-bottom: 20px;
	.form-header-title {
		text-align: left;
		font-weight: 700;
		padding: 25px 0;
	}
	.product-search-button {
		width: 125px;
		height: 36px;
		background: $PRIMARY_YELLOW;
		border-radius: 5px;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		display: flex;
		justify-content: center;
		img {
			width: 15px;
			height: 15px;
		}
		span {
			color: $COLOR_111;
			font-size: 0.875rem;
			margin-left: 3px;
		}

		&:disabled {
			background: $COMMON_BACKGROUND !important;
			border: 0 !important;
			span {
				color: $COLOR_BEBEBE;
			}
			img {
				content: url(~@/assets/svg/registration/search-icon-disabled.svg);
			}
		}
	}
	.seller-search-button .brand-search-button {
		color: $COLOR_666;
	}
	.search-info {
		font-size: 14px;
		margin-top: 15px;
		.search-info-title {
			display: inline-block;
			width: 200px;
			height: 40px;
			line-height: 40px;
		}
		.search-info-data {
			display: inline-block;
			width: 400px;
			height: 40px;
			line-height: 40px;
			background-color: #f9f9f9;
			border: 1px solid $COLOR_EEE;
			border-radius: 4px;
			padding-left: 20px;
		}
		.search-info-void {
			display: inline-block;
			width: 400px;
			height: 40px;
			line-height: 40px;
		}
	}
	.no-selected-category {
		max-width: 810px;
		height: 36px;
		background: $LIST_BACKGROUND;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $FILE_BORDER;
		span {
			color: $FONT_PLACEHOLDER;
			font-size: 0.875rem;
			font-weight: 400;
		}
	}
}
</style>
