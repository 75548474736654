<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status', statusClass]"> {{ statusText }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ typeText }} </span>
			</div>
		</td>
		<td>
			<div class="flex" style="min-width: 180px; max-width: 250px">
				<span style="text-decoration: underline">
					{{ item.no }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.brandName) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.productCode) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.casno) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.volume) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.quantity) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.comment) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.institute?.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.user.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.user.phone) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.user.email) }} </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.takeDatetime ? $DateTime.fromISO(item.takeDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.completeDatetime ? $DateTime.fromISO(item.completeDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.estimate?.createDatetime ? $DateTime.fromISO(item.estimate?.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import { RequestProduct } from '@/utils/define/ItemCode'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		statusText() {
			return RequestProduct.statusToString(this.item.status.code)
		},
		statusClass() {
			return RequestProduct.statusToClass(this.item.status.code)
		},
		typeText() {
			if (this.item.type == 'PURCHASE_AGENT') {
				return '구매 대행'
			} else if (this.item.type == 'NORMAL') {
				return '일반 상품'
			} else return '-'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Estimate.RequestProductDetail,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
