export default {
	computed: {
		isASC() {
			return this.orderDirection == 'ASC'
		},
	},
	methods: {
		changeOrder(order) {
			let orderDirection = 'ASC'
			if (this.order == order && this.isASC) orderDirection = 'DESC'
			this.setLoadParams({
				order: [order],
				orderDirection: [orderDirection],
			})
		},
		orderClass(order) {
			return {
				order: true,
				active: order == this.order,
				asc: this.isASC,
			}
		},
		isView(type) {
			// return this.checkedViewOption.length == 0 || this.checkedViewOption.includes(type)
			return true
		},
	},
}
