import PageName from '@/utils/define/PageName'

export default {
	namespaced: true,
	state: {
		pages: [
			{ title: '물품정보', status: '', pageName: PageName.Product.ProductRegistration.ProductInfo },
			{ title: '판매정보', status: '', pageName: PageName.Product.ProductRegistration.SellInfo },
			{ title: '배송정보', status: '', pageName: PageName.Product.ProductRegistration.ShippingInfo },
			{ title: '반품/환불 정보', status: '', pageName: PageName.Product.ProductRegistration.ReturnInfo },
			{ title: '고객상담 정보', status: '', pageName: PageName.Product.ProductRegistration.ConsultingInfo },
		],
		currentPageNumber: 1,
	},
	getters: {
		pageTotalCount: state => {
			return state.pages.length
		},
	},
	mutations: {
		setPages: (state, pages) => {
			state.pages = pages
		},
		setCurrentPageNumber: (state, pageNumber) => {
			state.currentPageNumber = pageNumber
		},
		setPageStatus: (state, { page, status }) => {
			const findPage = state.pages.find(item => item.title === page.title)
			if (!findPage) return

			findPage.status = status
		},
	},
	actions: {
		initPage: ({ commit }) => {
			commit('setPages', [
				{ title: '물품정보', status: '', pageName: PageName.Product.ProductRegistration.ProductInfo },
				{ title: '판매정보', status: '', pageName: PageName.Product.ProductRegistration.SellInfo },
				{ title: '배송정보', status: '', pageName: PageName.Product.ProductRegistration.ShippingInfo },
				{ title: '반품/환불 정보', status: '', pageName: PageName.Product.ProductRegistration.ReturnInfo },
				{ title: '고객상담 정보', status: '', pageName: PageName.Product.ProductRegistration.ConsultingInfo },
			])
			commit('setCurrentPageNumber', 1)
		},
		movePage: ({ commit }, pageNumber) => {
			commit('setCurrentPageNumber', pageNumber)
		},
		movePrevPage: ({ state, commit }) => {
			if (state.currentPageNumber > 1) commit('setCurrentPageNumber', state.currentPageNumber - 1)
		},
		moveNextPage: ({ state, getters, commit }) => {
			if (state.currentPageNumber < getters.pageTotalCount) commit('setCurrentPageNumber', state.currentPageNumber + 1)
		},
	},
}
