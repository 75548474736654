<template>
	<b-modal
		id="modal-update-stop"
		centered
		title="판매중지로 변경"
		ok-title="판매중지"
		ok-variant="complete"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-update-stop"
		size="md"
		@ok="submit"
	>
		<div class="content-box">
			<div class="text-wrapper">
				<p>판매중지로 상태 변경 시 랩매니저 스토어에서 상품이 노출되지 않습니다.<br />정말로 상품을 판매 중지하시겠습니까?</p>
			</div>
			<p style="font-size: 12px">판매중지 사유 선택<span>*</span></p>
			<div class="radio-wrapper">
				<div v-for="(radio, index) in radioList" :key="`radio_${radio}`">
					<input type="radio" :id="`radio_${index}`" name="radioList" @change="changeMethod(radio)" />
					<label :for="`radio_${index}`" style="cursor: pointer"> {{ radio }} </label>
				</div>
			</div>
			<div class="flex">
				<p class="title">판매중지 상세사유 입력</p>
				<button @click="content = ''">모두 지우기</button>
			</div>
			<textarea v-model="content" placeholder="판매중지 상세사유를 입력해주세요"></textarea>
		</div>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalUpdateStop',
	props: {
		title: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '확인',
		},
	},
	data() {
		return {
			content: '',
			select: null,
			radioList: ['운영기준 위반', '판매금지 품목', '배송지연', '구매자 신고', '기타'],
		}
	},
	methods: {
		changeMethod(value) {
			this.select = value
		},
		submit(e) {
			if (!this.select) {
				e.preventDefault()
				this.$root.toast('판매중지 사유 선택', '판매중지 사유를 선택해주세요.', 'error')
			} else this.$emit('ok', { stopReason: this.select, stopReasonDetail: this.content })
		},
	},
}
</script>

<style lang="scss">
#modal-update-stop {
	.modal-content {
		.modal-body {
			padding: 0 20px;
		}
		.title {
			font-size: 0.75rem;
			font-weight: 400;
		}
		.radio-option-box {
			.title {
				margin-bottom: 8px;
			}
			.option {
				margin-bottom: 8px;
			}
			label {
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 700;
				margin-left: 10px;
			}
		}
		.content-box {
			margin-top: 12px;
			span {
				color: red;
				margin-left: 3px;
			}
			.text-wrapper {
				p {
					font-size: 14px;
					margin-bottom: 20px;
				}
			}
			.radio-wrapper {
				margin-top: 8px;
				div {
					align-items: center;
					display: flex;
					margin: 12px 0;
					input {
						appearance: none;
						width: 18px;
						height: 18px;
						border: 1px solid $PRIMARY_YELLOW;
						border-radius: 50%;
						background-clip: content-box;
						padding: 2px;
					}

					input:checked {
						background-color: $PRIMARY_YELLOW;
					}

					label {
						font-weight: 700;
						padding-left: 10px;
						font-size: 14px;
					}
				}
			}
			.flex {
				width: 100%;
				display: flex;
				align-items: center;
				margin-top: 20px;
				button {
					margin-left: auto;
					font-size: 0.75rem;
					color: $COLOR_999;
					font-weight: 700;
				}
			}
			textarea {
				width: 100%;
				height: 150px;
				border: 1px solid $COLOR_CCC;
				border-radius: 5px;
				padding: 10px;
				font-size: 0.875rem;
				margin-top: 8px;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			justify-content: end;
			border-top: 0;
			button {
				justify-content: center;
				min-width: 100px;
				width: 100px;
				height: 30px;
				font-size: 0.875rem;
				border-radius: 5px;
				border-width: 1px;
			}
		}
	}
}
</style>
