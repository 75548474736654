<template>
	<div class="payment-status-changer">
		<div v-if="selectedList.length > 0">
			<h6>{{selectedList.length}}개의 항목이 선택되었습니다.</h6>
			<h6 class="uncheck-all" @click="$emit('uncheckAll')"> (선택항목 모두 해제하기)</h6>
		</div>
		<div v-else>
			<h6>항목을 선택해주세요</h6>
		</div>
		<p>선택한 상품을</p>
		<select-option ref="select-option" :list="options" :placeholder="placeholder" :isEmptyOption="true" @select="onSelect" />
		<button @click="onChange">변경</button>

		<modal-wait-to-paid
			:isShow="isShowModalWaitToPaid"
			:selectedList="selectedList"
			@hide="isShowModalWaitToPaid = false"
			@complete="$emit('refresh')"
		/>

		<modal-nopaid-to-wait
			:isShow="isShowModalNopaidToWait"
			:selectedList="selectedList"
			@hide="isShowModalNopaidToWait = false"
			@complete="$emit('refresh')"
		/>

		<modal-nopaid-to-paid
			:isShow="isShowModalNopaidToPaid"
			:selectedList="selectedList"
			@hide="isShowModalNopaidToPaid = false"
			@complete="$emit('refresh')"
		/>
	</div>
</template>

<script>
import SelectOption from '@/views/common/components/formItem/SelectOption'
import ModalWaitToPaid from '@/views/buyer/payment/modal/ModalWaitToPaid.vue'
import ModalNopaidToWait from '@/views/buyer/payment/modal/ModalNopaidToWait.vue'
import ModalNopaidToPaid from '@/views/buyer/payment/modal/ModalNopaidToPaid.vue'

export default {
	name: 'PaymentStatusChanger',
	components: {
		SelectOption,
		ModalWaitToPaid,
		ModalNopaidToWait,
		ModalNopaidToPaid,
	},
	props: {
		options: {
			type: Array,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: '결제상태 선택',
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedItem: {},
			isShowModalWaitToPaid: false,
			isShowModalNopaidToWait: false,
			isShowModalNopaidToPaid: false,
		}
	},
	watch: {
		options: {
			handler() {
				if (this.$refs['select-option']) this.$refs['select-option'].selectItem({})
			},
			deep: true,
		},
	},
	methods: {
		onSelect(item) {
			this.selectedItem = item
		},
		async onChange() {
			if (this.selectedList.length === 0) {
				return
			}
			// 결제 완료
			if (this.selectedItem.value === 'WAIT_TO_PAID') {
				this.isShowModalWaitToPaid = true
			} else if (this.selectedItem.value === 'NOPAID_TO_WAIT') {
				this.isShowModalNopaidToWait = true
			} else if (this.selectedItem.value === 'NOPAID_TO_PAID') {
				this.isShowModalNopaidToPaid = true

			}
		},
	},
}
</script>

<style lang="scss" scoped>
.payment-status-changer {
	width: 100%;
	height: 76px;
	border-radius: 4px;
	background-color: $PRIMARY_YELLOW_200;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	h6 {
		color: $FONT_YELLOW;
		font-weight: 700;
		display: inline-block;
		margin-left: 5px;
		&.uncheck-all {
			color: $FONT_DARK_YELLOW;
			cursor: pointer;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	p {
		font-weight: 400;
		padding-left: 20px;
		position: relative;
		&:after {
			position: absolute;
			top: calc(50% - 10px);
			left: 10px;
			width: 1px;
			height: 20px;
			background-color: $COLOR_DDD;
			content: '';
		}
	}
	.select-option {
		width: 180px;
		margin: 0 10px;
	}
	button {
		font-size: 1rem;
		font-weight: 700;
		color: white;
		background-color: $FONT_YELLOW;
		border-radius: 4px;
		width: 80px;
		height: 36px;
		align-items: center;
		justify-content: center;
	}
}
</style>
