<template>
	<jw-slot-table
		ref="jw-slot-table"
		:wrapperStyle="wrapperStyle"
		:options="options"
		:isCheckbox="options.isCheckbox"
		:keyword="keyword"
		:noData="noData"
		@getSelectedItem="getSelectedItem"
		@sortColumn="getSortColumn"
		@onClickNoDataBtn="onClickNoDataBtn"
		:noDataText="noDataText"
		:noDataSubtext="noDataSubtext"
		:noDataBtn="noDataBtn"
		:noDataImg="noDataImg"
		:noDataHeight="noDataHeight"
		:isComplete="isComplete"
		:isCompleteText="isCompleteText"
	>
		<!-- 테이블 헤더 -->
		<template #header="{ column }">
			<div class="separator"></div>
			{{ column.name }}
			<img
				class="header-arrow"
				width="18"
				height="18"
				v-if="column.useSort"
				:class="{ isAsc: column.sortAsc, active: isSortActiveColumnKey(column.key) }"
				:src="`${iconArrowUp}`"
			/>
		</template>

		<!-- 일반 데이터 값 -->
		<template #default="{ column, data, keyword }">
			<div class="jw-item-data">
				<div
					class="item-common jw-normal-data"
					:class="[...column.classes]"
					:style="({ maxWidth: column.style.width }, column.bold ? 'font-weight: bold;' : '')"
					v-if="column.dataHandler || data"
					v-html="setDefaultValue(column, data.value, keyword)"
				></div>
				<div v-else class="field-nodata">-</div>
				<div class="balloon-wrapper-info" v-if="column.balloon">
					<div class="balloon">
						<p class="balloon-text">{{ data.value }}</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 텍스트 클릭 -->
		<template #textWithClickEvent="{ column, data }">
			<div class="jw-item-data" v-if="!data.isDisabled" @click.stop="column.clickEvent(data)" style="cursor: pointer">
				<div
					class="item-hover-underline jw-normal-data"
					:class="[...column.classes]"
					:style="{ maxWidth: column.style.width }"
					v-if="data.value"
					v-html="setDefaultValue(column, data.value)"
				></div>
				<div v-else class="item-common">
					<p class="underline">{{ column.textHandler(data.value) }}</p>
				</div>
			</div>
			<div class="jw-item-data" v-else>
				<div
					class="item-common jw-normal-data"
					:class="[...column.classes]"
					:style="{ maxWidth: column.style.width }"
					v-if="data.value"
					v-html="setDefaultValue(column, data.value)"
				></div>
				<div v-else class="field-nodata">{{ column.textHandler(data.value) }}</div>
			</div>
		</template>

		<template #textWithDatePicker="{ column, data }">
			<div class="jw-item-data" v-if="!data.isDisabled" @click.stop="column.clickEvent(data)">
				<label style="display: flex; align-items: center; cursor: pointer">
					<div
						class="item-hover-underline jw-normal-data"
						:class="[...column.classes]"
						:style="{ maxWidth: column.style.width }"
						v-if="data.value"
						v-html="setDefaultValue(column, data.value)"
					></div>
					<div v-else class="item-common">
						<p class="underline">{{ column.textHandler(data.value) }}</p>
					</div>
					<jw-date-picker
						class="date-picker"
						ref="datePicker"
						:initDate="data.value"
						:row="data.row"
						:isRange="false"
						:datePickerStyle="{ width: '0px', height: '0px', border: 'none', top: '20px', right: '-80px' }"
						@changeDate="$emit('change', $event, data.row)"
					/>
				</label>
			</div>
			<div class="jw-item-data" v-else>
				<div
					class="item-common jw-normal-data"
					:class="[...column.classes]"
					:style="{ maxWidth: column.style.width }"
					v-if="data.value"
					v-html="setDefaultValue(column, data.value)"
				></div>
				<div v-else class="field-nodata">{{ column.textHandler(data.value) }}</div>
			</div>
		</template>

		<!-- 날짜 :  오늘 체크 가능한 값 -->
		<template #dayChecker="{ column, data, keyword }">
			<div class="item-day-checker item-common" :style="{ maxWidth: column.style.width }">
				<img v-if="isToday(data.value)" :src="iconTagToday" />
				<div
					class="item-day-checker-text"
					:style="{ maxWidth: `calc(${column.style.width} - 50px)` }"
					v-if="column.dataHandler || data"
					v-html="setDefaultValue(column, data.value, keyword)"
				></div>
				<div v-else class="field-nodata">-</div>
			</div>
		</template>

		<!-- 용량 + 유닛값 -->
		<template #valueUnit="{ column, data, keyword }">
			<div class="item-common jw-normal-data" :class="column.classes" v-if="data" v-html="setValueUnit(column, data, keyword)"></div>
			<div v-else class="field-nodata">정보없음</div>
		</template>

		<!-- 아이템 -->
		<template #item="{ column, data, keyword }">
			<div class="jw-item-data">
				<div class="jw-item-indicator-wrapper">
					<div
						v-for="(status, i) in data.indicatorStatus"
						class="jw-item-indicator"
						:class="column.classHandler(status)"
						:key="`${column.key}-${i}`"
					>
						{{ column.indicatorHandler(status, i) }}
					</div>
				</div>
				<div class="item-common item-text bold" :style="{ maxWidth: column.style.width }">
					<p class="item-main-text" v-html="setText(column, data.text, keyword)"></p>
				</div>
				<div class="item-subtext">
					<div
						class="item-common item-subtext"
						v-html="
							setText(column, data.brand, keyword) +
							(data.brand && data.subtext ? '&nbsp;|&nbsp;' : '') +
							setText(column, data.subtext, keyword)
						"
					></div>
				</div>
				<div class="balloon-wrapper-info" v-if="column.balloon">
					<div class="balloon">
						<p class="balloon-text">{{ data.text }}</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 프로필 -->
		<template #profile="{ column, data, keyword }">
			<!-- :class="column"
					:style="col" -->
			<div class="item-common jw-profile">
				<img class="jw-profile-img" v-if="data.img" :src="data.img" />
				<img class="jw-profile-img" v-else :src="`${defaultMemberImg}`" />
				<div class="jw-profile-text" v-html="setText(column, data.text, keyword)"></div>
			</div>
		</template>

		<!-- 상태 -->
		<template #status="{ column, data }">
			<div class="item-common jw-status">
				<img :src="column.statusHandler(data).img" />
				<div class="jw-status-text" :style="{ color: column.statusHandler(data).color }">
					{{ column.statusHandler(data).text }}
				</div>
			</div>
		</template>

		<!-- 결재 상태 -->
		<template #statusIndicator="{ column, data }">
			<div class="item-common jw-indicator-status" :class="[...column.statusHandler(data).class]">
				<div class="jw-status-text">
					{{ data.text }}
				</div>
			</div>
		</template>

		<!-- 상태 + 느낌표 -->
		<template #statusIndicatorWithMark="{ column, data }">
			<div class="status-indicator-with-mark">
				<div class="item-common jw-indicator-status" :class="[...column.statusHandler(data).class]">
					<div class="jw-status-text">
						{{ data.text }}
					</div>
				</div>
				<div
					v-if="data.isShowCaution"
					style="margin-right: 5px"
					class="item-common jw-indicator-status"
					:class="[...column.statusHandler(data).class]"
				>
					<div class="jw-status-text">!</div>
				</div>
				<div class="balloon-wrapper-caution" v-if="column.balloon && data.isShowCaution">
					<div class="balloon" :style="{ ...column.balloon.style }">
						<p class="balloon-text">JW Mall 관리자에 의해 판매가 중지된 상태입니다.</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 상태 + 상세 -->
		<template #statusWithDetail="{ column, data }">
			<div class="jw-item-data status">
				<div class="jw-item-indicator-wrapper">
					<div
						v-for="(status, i) in data.indicatorStatus"
						class="jw-item-indicator"
						:class="column.classHandler(status)"
						:key="`${column.key}-${i}`"
					>
						{{ column.indicatorHandler(status, i) }}
					</div>
				</div>
				<div class="item-common item-text bold" :style="{ maxWidth: column.style.width, display: data.text === '인증 대기' ? 'flex' : '' }">
					<p
						class="item-main-text"
						@click="column.clickEvent(data)"
						:style="{
							'text-decoration': data.text === '인증 대기' ? 'underline' : '',
							cursor: data.text === '인증 대기' ? 'pointer' : '',
						}"
						v-html="setText(column, data.text)"
					></p>
					<img v-if="data.text === '인증 대기'" src="@/assets/svg/order/icon-question.svg" />
				</div>
				<div v-if="data.subtext">
					<p class="status-subtext" @click.stop="column.clickEvent(data)">{{ data.subtext }}</p>
				</div>
				<div class="balloon-wrapper-info" v-if="data.text === '인증 대기'">
					<div class="balloon">
						<p class="balloon-text">
							사업자등록증 인증 대기상태입니다. <br />
							사업자등록증 인증을 완료해주세요.
						</p>
					</div>
				</div>
			</div>
		</template>

		<!-- 체크박스 -->
		<template #checkbox="{ data }">
			<div class="item-common jw-normal-data" v-if="data">
				{{ data }}
			</div>
			<div v-else class="field-nodata">정보없음</div>
		</template>

		<!-- 이미지 버튼  -->
		<template #imgButton="{ column, data }">
			<div
				class="jw-img-btn-wrapper"
				@click="column.clickEvent"
				:style="{
					...column.itemStyle,
					cursor: data ? '' : 'pointer',
				}"
			>
				<img class="jw-img-btn" :src="column.imgHandler(data)" />
			</div>
		</template>

		<!-- 이미지 + 텍스트 버튼  -->
		<template #imgTextButton="{ column, data }">
			<div class="jw-button-wrapper">
				<button class="jw-button" @click.stop="column.clickEvent(data)">
					<img class="jw-button-img" v-if="column.imgHandler" :src="column.imgHandler(data)" />
					<div
						class="jw-button-img-txt"
						:class="[column.classHandler && column.classHandler(data)]"
						v-if="!column.textHandler && data.text"
					>
						{{ data.text }}
					</div>
					<div class="jw-button-img-txt" :class="[column.classHandler && column.classHandler(data)]" v-if="column.textHandler && data.text">
						{{ column.textHandler(data.text) }}
					</div>
				</button>
			</div>
		</template>

		<!-- 텍스트 버튼 + 텍스트  -->
		<template #textButtonWithText="{ column, data }">
			<div v-if="data.isReverse" class="jw-button-wrapper" style="justify-content: space-between; padding: 0 20px 0 15px">
				<p style="padding-right: 20px">{{ data.subtext }}</p>
				<button class="jw-button-yellow" @click.stop="column.clickEvent(data)" :disabled="data.isButtonDisabled">
					<div class="jw-button-img-txt" :class="[column.classHandler && column.classHandler(data)]">
						{{ data.buttonText }}
					</div>
				</button>
			</div>
			<div v-else class="jw-button-wrapper" style="justify-content: space-between">
				<button class="jw-button-yellow" @click.stop="column.clickEvent(data)" :disabled="data.isButtonDisabled">
					<div class="jw-button-img-txt" :class="[column.classHandler && column.classHandler(data)]">
						{{ data.buttonText }}
					</div>
				</button>
				<p style="padding-right: 20px">{{ data.subtext }}</p>
			</div>
		</template>

		<!-- 이미지 + 텍스트 버튼 두개  -->
		<template #doubleImgTextButton="{ column, data }">
			<div class="jw-button-wrapper" style="justify-content: space-between">
				<button class="jw-button" @click.stop="column.firstClickFn(data)">
					<img class="jw-button-img" v-if="column.firstImgHandler" :src="column.firstImgHandler(data)" />
					<div class="jw-button-img-txt" :class="[column.classHandler && column.classHandler(data)]">
						{{ data.firstText }}
					</div>
				</button>
				<button class="jw-button btn-balloon" :disabled="data.isDefault" @click.stop="column.secondClickFn(data)">
					<img class="jw-button-img" v-if="column.secondImgHandler" :src="column.secondImgHandler(data)" />
					<div class="jw-button-img-txt" :class="[column.classHandler && column.classHandler(data)]">
						{{ data.secondText }}
					</div>
					<div class="balloon-wrapper-button" v-if="data.isDefault">
						<div class="balloon">
							<p class="balloon-text">{{ data.balloonText }}</p>
						</div>
					</div>
				</button>
			</div>
		</template>

		<!-- 멤버 -->
		<template #member="{ column, data, keyword }">
			<div class="jw-member">
				<div class="jw-member-img-wrapper">
					<img class="jw-member-img" v-if="data.img" />
					<img class="jw-member-img" v-else :src="defaultMemberImg" />
				</div>
				<div class="item-member-info">
					<p class="item-common member-text" v-html="setText(column, data.text, keyword)"></p>
					<p class="item-common member-subtext" v-html="setText(column, data.subtext, keyword)"></p>
				</div>
			</div>
		</template>

		<!-- 퍼센트 -->
		<template #percent="{ data }">
			<div class="item-common progressbar">
				<donut-chart :allCount="100" :itemCount="data.value" />
			</div>
		</template>

		<!-- 더블 버튼 -->
		<template #doubleButtons="{ column, data }">
			<div class="item-common double-btn">
				<button class="double-btn" @click.stop="() => column.firstClickFn(data)">{{ data.firstText }}</button>
				<button class="double-btn" @click.stop="() => column.secondClickFn(data)">{{ data.secondText }}</button>
			</div>
		</template>

		<!--게시판-제목-->
		<template #title="{ data }">
			<div class="item-common title">
				<span class="notice" v-if="data.isNotice.value">{{ data.isNotice.text }}</span>
				<b>{{ data.text }}</b>
				<img class="new" v-if="data.isNew.value" :src="data.isNew.image" />
			</div>
		</template>
	</jw-slot-table>
</template>

<script>
import DefaultMemberImg from '@/assets/svg/common/jw-table/member-photo-default.svg'
import JwSlotTable from '@/views/common/components/JwSlotTable.vue'
import StringUtils from '@/utils/modules/StringUtils'
import IconArrowUp from '@/assets/svg/common/jw-table/icon-arrow-up.svg'
import IconArrowDown from '@/assets/svg/common/jw-table/icon-arrow-down.svg'
import IconTagToday from '@/assets/svg/common/jw-table/icon-tag-today.svg'
import DonutChart from '@/views/common/components/DonutChart.vue'
import JwDatePicker from '@/views/common/components/search/JwDatePicker.vue'

export default {
	components: { JwSlotTable, DonutChart, JwDatePicker },
	props: {
		width: {
			type: String,
			default: '1000px',
		},
		height: {
			type: String,
			default: '500px',
		},
		options: {
			type: Object,
			default: () => {},
		},
		keyword: {
			type: String,
			default: '',
		},
		// noData 관련 props
		noData: {
			type: Boolean,
			default: false,
		},
		noDataText: {
			type: String,
			default: '',
		},
		noDataSubtext: {
			type: String,
			default: '',
		},
		noDataImg: {
			type: String,
			default: '',
		},
		noDataHeight: {
			type: String,
			default: '300px',
		},
		// 입고 완료 message
		isComplete: {
			type: Boolean,
			default: false,
		},
		isCompleteText: {
			type: String,
			default: '',
		},
		noDataBtn: {
			type: Object,
			default: () => {
				return { text: '' }
			},
		},
	},
	data() {
		return {
			defaultMemberImg: DefaultMemberImg,
			highlightClass: 'jw-table-highlight',
			highlightTag: 'span',
			iconArrowUp: IconArrowUp,
			iconArrowDown: IconArrowDown,
			iconTagToday: IconTagToday,
		}
	},
	computed: {
		wrapperStyle() {
			return {
				width: this.width,
				height: this.height,
				backgroundColor: 'white', // 테이블 wrapper 기본 배경색
				borderRadius: '4px',
			}
		},
	},
	methods: {
		setDefaultValue(column, value, keyword) {
			if (!value) {
				return '-'
			}
			const defaultData = column.dataHandler ? column.dataHandler(value) : value

			return keyword ? StringUtils.replaceHighlight(defaultData, keyword, this.highlightTag, this.highlightClass) : defaultData
		},
		setValueUnit(column, data, keyword) {
			let value = data.value
			if (typeof value === 'string') {
				value = Number(value)
			}
			const valueUnitData = value.toLocaleString() + data.unit
			return keyword ? StringUtils.replaceHighlight(valueUnitData, keyword, this.highlightTag, this.highlightClass) : valueUnitData
		},
		setText(column, text, keyword) {
			if (!text) return ''
			return keyword ? StringUtils.replaceHighlight(text, keyword, this.highlightTag, this.highlightClass) : text
		},
		setPercircle(percent) {
			document.querySelector('#custom-color').percircle({
				percent,
			})
		},
		getSelectedItem(selectedList) {
			this.$emit('getSelectedItem', selectedList)
		},
		resetSelectedItem() {
			this.$refs['jw-slot-table'].resetSelectItem()
		},
		getSortColumn(sortColumn) {
			this.$emit('sortColumn', sortColumn)
		},
		isToday(datetime) {
			const paramDatetime = new Date(datetime).toDateString()
			const today = new Date().toDateString()
			return paramDatetime === today
		},
		isSortActiveColumnKey(columnKey) {
			return this.options.sortActiveColumnKey === columnKey
		},
		onClickNoDataBtn() {
			this.$emit('onClickNoDataBtn')
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/scss/global/_colors.scss';

.disable * {
	color: gray !important;
}

.header-th {
	position: sticky;
	top: 0px;
	transition: all ease-in-out 0.3s;
	padding: 10px 0px;
	&:hover {
		transition: all ease-in-out 0.3s;
		background-color: $LIST_BACKGROUND;
		.header-arrow {
			opacity: 1;
		}
	}
	&.checkbox {
		position: relative;
		left: -5px;
		min-width: 30px;
		background-color: inherit;
		input[type='checkbox'] {
			background-color: inherit;
			&:before {
				background-color: inherit;
			}
		}
	}
	.header-arrow {
		position: absolute;
		top: 11px;
		right: 6px;
		transform: rotate(180deg);
		transition: all ease-in-out 0.3s;
		opacity: 0;
		&.isAsc {
			transform: rotate(360deg);
			opacity: 1;
		}
		&.active {
			opacity: 1;
		}
	}
}

.jw-page-wrapper {
	position: relative;
	background: rgba(15, 19, 1, 0.5);
	overflow: hidden;

	.header {
		padding: 10px 30px;
		color: $COLOR_666;
		font-weight: 700;
	}

	.header-gray {
		background-color: $TABLE_HEADER;
	}
	.header-white {
		background-color: $PRIMARY_WHITE;
	}
}

.header-wrapper {
	display: flex;

	.separator {
		display: inline;
		height: 100%;
		border-left: 1px solid $COLOR_DDD !important;
		padding-right: 8px;
	}
}

.jw-item-data {
	&:hover {
		.balloon-wrapper-info {
			position: relative;
			visibility: visible;
			width: 100%;
			.balloon {
				position: absolute;
				left: 50%;
				transform: translate(-50%);
				display: block;
				padding: 12px 15px;
				border-radius: 5px;
				color: white !important;
				background: #333333;
				font-weight: normal;
				font-size: 0.8125rem;
				z-index: 11;
				border: 1px solid rgba(0, 0, 0, 0.6);
				box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
				box-sizing: border-box;
				white-space: nowrap;
				text-align: center;
			}
			.balloon:after {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid #333333;
				content: '';
				position: absolute;
				top: -7px;
				left: 50%;
				transform: translate(-50%);
				z-index: 1004;
			}
			.balloon:before {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				top: -8px;
				left: 50%;
				transform: translate(-50%);
				z-index: 1003;
			}
		}
	}
	.balloon-wrapper-info {
		position: absolute;
		visibility: hidden;
		width: 100%;
		.balloon {
			position: absolute;
			padding: 12px 15px;
			border-radius: 5px;
		}
	}
}

.item-day-checker {
	display: flex;
	padding-left: 10px;
	text-align: left;
	overflow: hidden;
	img + .item-day-checker-text {
		padding-left: 8px;
	}
}
.status-subtext {
	text-decoration-line: underline;
	padding-left: 10px;
	display: flex;
	text-align: left;
	color: $PRIMARY_RED;
	font-weight: 400;
	font-size: 12px;
	&:hover {
		font-weight: bold;
	}
}
.item-hover-underline {
	padding-left: 10px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: $COLOR_111;
	text-decoration: underline;
	&:hover {
		font-weight: bold;
		letter-spacing: -0.03rem;
	}
}
.item-common {
	padding-left: 10px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: $COLOR_111;
	.underline {
		text-decoration: underline;
		color: $FONT_YELLOW;
		&:hover {
			font-weight: bold;
		}
	}
	&.double-btn {
		display: flex;
		gap: 8px;
		.double-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			border: 0;
			padding: 5px 10px;
			border-radius: 4px;
			gap: 2px;
			background: $COMMON_BACKGROUND;
			color: $COLOR_000000DE;
			font-weight: 700;
			font-size: 14px;
		}
	}

	&.ellipsis-disable {
		overflow: auto;
		text-overflow: unset;
		white-space: unset;
	}
	.item-main-text {
		width: 100%;
		text-align: left;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	&.item-text {
		width: calc(100% - 8px);
		color: $COLOR_111;
	}
	&.item-subtext {
		width: calc(100% - 8px);
		color: $COLOR_666;
		font-weight: 400;
		font-size: 12px;
	}
	&.jw-profile {
		display: flex;
		align-items: center;
		> .jw-profile-img {
			width: 24px;
			height: 24px;
			border-radius: 100px;
			margin-right: 5px;
			border: 1px solid $COLOR_DDD;
		}
		> .jw-profile-text {
			color: $COLOR_111;
		}
	}
	&.jw-status {
		display: flex;
		align-items: center;
		font-weight: 700;
	}
	&.jw-indicator-status {
		display: table;
		font-weight: 700;
		padding: 1px 5px;
		border-radius: 5px !important;
		position: relative;
		font-size: 12px;
		left: 10px;
		.jw-status-text {
			padding: 1px 5px;
		}
		&.gray {
			background: $COLOR_EEE;
			.jw-status-text {
				color: $COLOR_666;
			}
		}

		&.red {
			background: $COLOR_BACKGROUND_RED;
			.jw-status-text {
				color: $PRIMARY_RED;
			}
		}

		&.green {
			background: $COLOR_BACKGROUND_GREEN;
			.jw-status-text {
				color: $PRIMARY_GREEN;
			}
		}

		&.blue {
			background: $JW_200;
			.jw-status-text {
				color: $PRIMARY_YELLOW;
			}
		}
	}
	&.caution-mark {
		justify-content: center;
		display: flex;
		font-weight: 900;
		width: 15px;
		margin-right: 5px;
	}
}

.jw-member {
	display: flex;
	align-items: center;
	.jw-member-img-wrapper {
		padding-left: 10px;
		.jw-member-img {
			width: 40px;
			height: 40px;
		}
	}

	.item-member-info {
		display: flex;
		flex-direction: column;
		.member-text {
			font-weight: 700;
			font-size: 16px;
		}
		.member-subtext {
			font-weight: 400;
			font-size: 12px;
			color: $COLOR_999;
		}
	}
}

.jw-item-indicator-wrapper {
	display: flex;
	padding-left: 10px;
	padding-bottom: 1px;
	.jw-item-indicator {
		display: flex;
		align-items: center;
		height: 16px;
		padding: 1px 5px;
		gap: 10px;
		font-size: 10px;
		font-weight: 700;
		border-radius: 5px;
		background-color: $LINE_DIVIDER;
		color: $COLOR_999;
		&.green {
			background: $SUB_GREEN;
			color: $PRIMARY_GREEN;
		}
	}
	.jw-item-indicator + .jw-item-indicator {
		margin-left: 5px;
	}
}

.field-nodata {
	color: $COLOR_999;
	font-weight: 400;
	text-align: left;
	padding-left: 8px;
}

.jw-button-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;

	.btn-balloon {
		.balloon-wrapper-button {
			position: absolute;
			visibility: hidden;
			width: 100%;
			.balloon {
				position: absolute;
				padding: 12px 15px;
				border-radius: 5px;
				.balloon-text {
					font-weight: 400;
					font-size: 12px;
					color: $PRIMARY_WHITE;
					text-align: center;
				}
			}
		}
		&:hover {
			.balloon-wrapper-button {
				position: absolute;
				visibility: visible;
				width: 100%;
				.balloon {
					width: 170px;
					height: 30px;
					position: absolute;
					display: block;
					padding: 5px 15px;
					border-radius: 5px;
					top: 25px;
					left: 550px;
					color: $PRIMARY_BLACK;
					background: $COLOR_333;
					font-weight: normal;
					font-size: 0.8125rem;
					z-index: 11;
					border: 1px solid rgba(0, 0, 0, 0.6);
					box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
					max-width: 306px;
					box-sizing: border-box;
					white-space: initial;
					text-align: left;
				}

				.balloon:after {
					border-top: 0px solid transparent;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid #333333;
					content: '';
					position: absolute;
					top: -8px;
					left: 79px;
					z-index: 1004;
				}
				.balloon:before {
					border-top: 0px solid transparent;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid rgba(0, 0, 0, 0.6);
					content: '';
					position: absolute;
					top: -8px;
					left: 79px;
					z-index: 1003;
				}
			}
		}
	}
	.jw-button {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 30px;
		min-height: 30px;
		background-color: $COMMON_BACKGROUND;
		border-radius: 4px;
		padding: 5px, 10px, 5px, 10px;
		color: $COLOR_000000DE;
		font-weight: 700;
		transition: all ease-in-out 0.3s;
		&:hover {
			background-color: #dadbdc !important;
		}
		.jw-button-img + .jw-button-img-txt {
			margin-left: -2px;
		}

		.jw-button-img + .jw-button-txt {
			margin-left: 4px;
		}

		.jw-button-img-txt {
			&.red {
				color: $PRIMARY_RED !important;
			}
			&.blue {
				color: $PRIMARY_BLUE;
			}
			&.white {
				color: white;
			}
		}
		&:disabled {
			border: 1px solid $COMMON_BACKGROUND;
			background: #fcfdfd !important;
			img {
				opacity: 0.2;
			}
			.jw-button-img-txt {
				color: #bebebe !important;
			}
		}
	}
	.jw-button-yellow {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 30px;
		min-height: 30px;
		background-color: $PRIMARY_YELLOW_200;
		border-radius: 4px;
		padding: 5px, 10px, 5px, 10px;
		color: $COLOR_000000DE;
		font-weight: 700;
		transition: all ease-in-out 0.3s;
		.jw-button-img-txt {
			color: $FONT_YELLOW;
		}
		&:disabled {
			background-color: #f9f9f9 !important;
			border: 1px solid #f2f3f4 !important;
			.jw-button-img-txt {
				color: #cacbcb !important;
			}
			&:hover {
				background-color: #f9f9f9 !important;
				border: 1px solid #f2f3f4 !important;
			}
		}
		&:hover {
			background-color: $PRIMARY_YELLOW !important;
			.jw-button-img-txt {
				color: white;
			}
		}
	}
}

.bold {
	font-weight: 700;
}
.color-666 {
	color: $COLOR_666 !important;
}

.red {
	color: $PRIMARY_RED;
}
.green {
	color: $PRIMARY_GREEN;
}

::v-deep .jw-table-highlight {
	background-color: $COLOR_HIGHLIGHT_RED !important;
}

.small {
	font-size: 40px !important;
	height: auto;
	& > span {
		color: '#00A9E5';
	}
}

.item-common.progressbar {
	padding-left: 8px;
}

.notice {
	height: 20px;
	display: inline-block;
	padding: 0 5px;
	border-radius: 5px;
	background-color: $JW_200;
	font-size: 0.75rem;
	font-weight: 700;
	color: $PRIMARY_YELLOW;
	margin-right: 10px;
}

img.new {
	width: 14px;
	height: 14px;
	margin-left: 5px;
	margin-top: -2px;
}

.status-indicator-with-mark {
	display: flex;
	justify-content: space-between;
	position: relative;
	&:hover {
		.balloon-wrapper-caution {
			top: 5px;
			visibility: visible;
			width: 100%;
			.balloon {
				width: 310px;
				position: absolute;
				display: block;
				padding: 12px 15px;
				border-radius: 5px;
				top: 23px;
				left: -60px;
				color: white !important;
				background: #333333;
				font-weight: normal;
				font-size: 0.8125rem;
				z-index: 11;
				border: 1px solid rgba(0, 0, 0, 0.6);
				box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
				box-sizing: border-box;
				white-space: initial;
				text-align: left;
			}

			.balloon:after {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid #333333;
				content: '';
				position: absolute;
				top: -8px;
				left: 129px;
				z-index: 1004;
			}
			.balloon:before {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				top: -8px;
				left: 129px;
				z-index: 1003;
			}
		}
	}
	.balloon-wrapper-caution {
		position: absolute;
		visibility: hidden;
		width: 100%;
		.balloon {
			position: absolute;
			padding: 12px 15px;
			border-radius: 5px;
		}
	}
}
</style>
