<template>
	<div class="seller-registration-wrapper">
		<div class="seller-registration">
			<component :is="component" @complete="complete"></component>
		</div>
	</div>
</template>

<script>
import SellerTermsAgree from '@/views/seller/entry/registration/components/SellerTermsAgree.vue'
import SellerEntryRegistrationInfo from '@/views/seller/entry/registration/components/SellerEntryRegistrationInfo.vue'
import SellerInfo from '@/views/seller/entry/registration/components/SellerInfo.vue'
import RegistrationSuccess from '@/views/seller/entry/registration/components/RegistrationSuccess.vue'
import PageName from '@/utils/define/PageName'

export default {
	name: 'SellerEntryRegistration',
	components: {
		SellerTermsAgree,
		SellerEntryRegistrationInfo,
		SellerInfo,
		RegistrationSuccess,
	},
	data() {
		return {
			componentIndex: 0,
			componentList: [SellerTermsAgree, SellerEntryRegistrationInfo, SellerInfo, RegistrationSuccess],
		}
	},
	computed: {
		component() {
			return this.componentList[this.componentIndex]
		},
	},
	methods: {
		complete() {
			if (this.componentIndex < this.componentList.length - 1) this.componentIndex++
			else this.routeSellerLogin()
		},
		routeSellerLogin() {
			this.$router.push({
				name: PageName.Seller.SellerLogin,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-registration-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $COMMON_BACKGROUND;

	.seller-registration {
		width: 500px;
		background: #ffffff;
		padding: 50px;
		border: 1px solid #eeeeee;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		border-radius: 16px;
	}
}
</style>
