import requestAPI from '@/services/api/estimate/request'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: '',
			status: ['ALL'],
			estimateStatus: JSON.parse(cookies.get('filter_request_estimate_status')) ?? '',
			brandNames: JSON.parse(cookies.get('filter_request_brand_names')) ?? [],
			instituteIds: JSON.parse(cookies.get('filter_request_institute_ids')) ?? [],
			types: JSON.parse(cookies.get('filter_request_types')) ?? [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: null,
		filterCookieName: {
			estimateStatus: 'filter_request_estimate_status',
			brandNames: 'filter_request_brand_names',
			instituteIds: 'filter_request_institute_ids',
			types: 'filter_request_types',
		},
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (state.loadParams.status.length == 1) return state.loadParams.status[0]
			return 'ALL'
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state =>
			state.loadParams.estimateStatus.length +
			state.loadParams.brandNames.length +
			state.loadParams.instituteIds.length +
			state.loadParams.types.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			const filter = {
				estimateStatus: [
					{ id: 'NONE', name: '미작성' },
					{ id: 'COMPLETE', name: '작성완료' },
				],
				brandNames: data.brandNames,
				instituteIds: data.institutes,
				types: data.types,
			}
			state.filter = filter
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				status: ['ALL'],
				estimateStatus: JSON.parse(cookies.get('filter_request_estimate_status')) ?? '',
				brandNames: JSON.parse(cookies.get('filter_request_brand_names')) ?? [],
				instituteIds: JSON.parse(cookies.get('filter_request_institute_ids')) ?? [],
				types: JSON.parse(cookies.get('filter_request_types')) ?? [],
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
			state.scrollTop = 0
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const params = {
				...state.loadParams,
				status: state.loadParams.status[0],
				estimateStatus: state.loadParams?.estimateStatus[0],
			}
			const response = await requestAPI.list(params)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		getFilter: async ({ commit }) => {
			const response = await requestAPI.filter()
			commit('setFilter', response.data)
		},
		requestProduct: async ({ commit }, body) => {
			return await requestAPI.requestProduct(body)
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
