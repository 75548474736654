<template>
	<tr class="table-header">
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>요청유형</span>
		</th>
		<th>
			<span>상품 요청번호</span>
		</th>
		<th>
			<span>요청 상품</span>
		</th>
		<th>
			<span>제조사</span>
		</th>
		<th>
			<span>제품번호</span>
		</th>
		<th>
			<span>CAS No.</span>
		</th>
		<th>
			<span>용량</span>
		</th>
		<th>
			<span>수량</span>
		</th>
		<th>
			<span>추가 요청사항</span>
		</th>
		<th>
			<span>요청자 소속</span>
		</th>
		<th>
			<span>요청자</span>
		</th>
		<th>
			<span>요청자 연락처</span>
		</th>
		<th>
			<span>요청자 이메일</span>
		</th>
		<th>
			<span>요청일시</span>
		</th>
		<th>
			<span>접수일시</span>
		</th>
		<th>
			<span>소싱 처리일시</span>
		</th>
		<th>
			<span>견적 생성일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapGetters('request/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('request/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
