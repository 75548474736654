<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>{{ this.mode === 'create' ? '공지사항 등록하기' : '공지사항 수정하기' }}</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>공지사항 내용</h5>
				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					:inputValue="formData.title"
					@changeValue="data => (formData.title = data)"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<input-text-editor :editorStyle="{ height: '668px', width: '800px' }" title="공지사항 상세설명" v-model="formData.html" />
			</div>
			<div>
				<h5>노출 설정</h5>
				<input-date-picker
					title="노출 시작일"
					:isValid="isValid.startDatetime"
					:inputValue="formData.startDatetime"
					@changeValue="data => (formData.startDatetime = data)"
					inValidText="노출 시작일을 입력해주세요"
				/>
				<input-date-picker
					title="노출 종료일"
					:isValid="isValid.endDatetime"
					:inputValue="formData.endDatetime"
					@changeValue="data => (formData.endDatetime = data)"
					inValidText="노출 종료일을 입력해주세요"
				/>
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="regist">{{ this.mode === 'create' ? '등록' : '수정' }}</base-button>
		</div>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import TargetSelect from '../../components/target/TargetSelect.vue'
import InputTextEditor from '@/views/common/components/formItem/InputTextEditor.vue'
import PageName from '@/utils/define/PageName'
import { mapActions, mapState } from 'vuex'

export default {
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputTextEditor,
		InputRadio,
		TargetSelect,
	},
	data() {
		return {
			mode: 'create',
			formData: {
				title: null,
				html: null,
				startDatetime: this.$DateTime.now().toJSDate(),
				endDatetime: this.$DateTime.now().plus({ day: 7 }).toJSDate(),
			},
			isValid: {
				title: true,
				startDatetime: true,
				endDatetime: true,
			},
		}
	},
	computed: {
		...mapState('lms/notice', ['detail']),
	},
	async mounted() {
		if (this.$route.query.id) {
			this.mode = 'edit'
			await this.lookupNotice({ noticeId: this.$route.query.id })
			this.formData = this.$_.cloneDeep(this.detail)
		}
	},
	methods: {
		...mapActions('lms/notice', ['createNotice', 'lookupNotice', 'updateNotice']),
		async regist() {
			if (!this.formData.title) return (this.isValid.title = false)
			if (!this.formData.startDatetime) return (this.isValid.startDatetime = false)
			if (!this.formData.endDatetime) return (this.isValid.endDatetime = false)
			if (this.formData.startDatetime > this.formData.endDatetime)
				return this.$root.toast('등록 실패', '설정할수 없는 날짜입니다. <br> 노출시작일은 노출 종료일보다 빠를수 없습니다', 'error')
			if (!this.formData.html || this.formData.html === '<p></p>')
				return this.$root.toast('등록 실패', '공지사항 상세설명을 입력해주세요', 'error')

			try {
				if (this.mode === 'create') {
					await this.createNotice(this.formData)
					this.$root.toast('등록 완료', '공지사항이 등록되었습니다.', 'success')
					this.$router.replace({
						name: PageName.Lms.Notice,
					})
				} else {
					this.formData.noticeId = this.$route.query.id
					await this.updateNotice(this.formData)
					this.$root.toast('수정 완료', '공지사항이 수정되었습니다.', 'success')
					this.$router.replace({
						name: PageName.Lms.Notice,
					})
				}
			} catch (e) {
				this.$root.toast('등록 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
