<template>
	<div class="BusinessRegistrationDetail">
		<div class="content-header">
			<div class="title">
				<h4>{{ detail.name }}</h4>
				<div class="subtitle">
					<span class="status-text" :class="statusClass">{{ statusText }}</span>
					<p>등록일시: {{ $DateTime.fromISO(detail.createDatetime).toFormat('yyyy-MM-dd T') }}</p>
					<p v-if="detail.status !== 'WAIT' && detail.statusChangeDatetime">
						인증 처리일시: {{ $DateTime.fromISO(detail.statusChangeDatetime).toFormat('yyyy-MM-dd T') }}
					</p>
				</div>
			</div>
			<div class="right-btn-group" v-if="detail.status === 'WAIT'">
				<button class="Btn__export" @click="$bvModal.show('modal-submit')">거절</button>
				<button class="btn-confirm" @click="$refs['modal-business-registration'].show()">인증 완료</button>
			</div>
			<div class="right-btn-group" v-if="detail.status === 'APPROVE'">
				<button class="btn-confirm" @click="$refs['modal-business-registration'].show(detail)">수정</button>
			</div>
		</div>
		<div class="content-wrapper">
			<info-list title="사업자등록증(결제처) 정보" :list="registrationInfo" />
			<info-list title="사업자등록증 입력 정보" :list="registrationInputInfo" />
			<info-list title="연구실 정보" :list="labInfo" />
		</div>

		<modal-submit
			title="사업자등록증 인증 거절"
			content="사업자등록증을 거절 처리하시겠습니까?<br>거절한 사업자등록증은 되돌릴 수 없습니다.<br>해당 사업자등록증으로 주문한 상품은<br>자동으로 주문취소 처리됩니다."
			@ok="reject"
		/>
		<modal-business-registration ref="modal-business-registration" @complete="getDetailInfo" :id="id" />
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { BusinessRegistration } from '@/utils/define/ItemCode'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ModalBusinessRegistration from '@/views/common/modals/ModalBusinessRegistration'
import { mapActions, mapGetters } from 'vuex'

const INSTITUTE_TYPE = {
	PERSON: 'PERSON',
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
}

export default {
	name: 'BusinessRegistrationDetail',
	components: {
		InfoList,
		ModalSubmit,
		ModalBusinessRegistration,
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters('businessregistration/list', ['detail']),
		statusClass() {
			return BusinessRegistration.statusToClass(this.detail.status)
		},
		statusText() {
			return BusinessRegistration.statusToString(this.detail.status)
		},
		registrationInfo() {
			let list = []
			list.push({ title: '별칭', value: this.detail?.name })
			list.push({ title: '인증 상태', value: this.statusText, type: 'bold' })
			list.push({ title: '등록일시', value: this.$DateTime.fromISO(this.detail.createDatetime).toFormat('yyyy-MM-dd T') })
			list.push({
				title: '인증 처리일시',
				value: this.detail.statusChangeDatetime ? this.$DateTime.fromISO(this.detail.statusChangeDatetime).toFormat('yyyy-MM-dd T') : '-',
			})
			list.push({ title: '파일', value: this.detail.files, type: 'file', row: true })

			return list
		},
		registrationInputInfo() {
			let list = []
			list.push({ title: '연구실 전화번호', value: this.detail.tel, row: true })
			list.push({ title: '사업자등록증 번호', value: this.detail.corpNo, row: true })
			list.push({ title: '법인명 (단체명)', value: this.detail.corpName })
			list.push({ title: '대표자', value: this.detail.ceoName })
			list.push({ title: '사업장 소재지', value: this.detail.address, row: true })
			list.push({ title: '사업의 종류 : 업태', value: this.detail.bizType, row: true })
			list.push({ title: '사업의 종류 : 종목', value: this.detail.bizClass, row: true })

			return list
		},
		labInfo() {
			let list = []
			list.push({ title: '신청자 이름', value: this.detail.user?.name, row: true })
			list.push({ title: '신청자 이메일', value: this.detail.user?.email })
			list.push({ title: '신청자 연락처', value: this.detail.user?.phone })
			list.push({ title: '소속 유형', value: this.typeToString(this.detail.institute?.type) })
			list.push({ title: '교수님 / 구매담당자', value: this.detail.institute?.managerName })
			list.push({ title: '소속', value: this.detail.institute?.name, row: true })
			list.push({ title: '연구실 ID', value: this.detail.institute?.id })
			list.push({
				title: '연구실 생성일',
				value: this.detail.createDatetime ? this.$DateTime.fromISO(this.detail.createDatetime).toFormat('yyyy-MM-dd T') : '-',
			})

			return list
		},
		id() {
			return this.$route.params.id
		},
	},
	async created() {
		this.getDetailInfo()
	},
	methods: {
		...mapActions('businessregistration/list', ['getDetail', 'rejectBusinessRegistration']),
		async reject() {
			try {
				await this.rejectBusinessRegistration({
					body: {
						id: this.id,
					},
				})
				this.getDetailInfo()
			} catch (e) {
				this.$root.toast('요청 실패', e.response.data.msg, 'error')
			}
		},
		approval() {
			this.$root.toast('인증완료', '사업자등록증 인증을 완료했습니다.', 'success')
		},
		async getDetailInfo() {
			await this.getDetail({ id: this.id })
		},
		typeToString(status) {
			if (status == INSTITUTE_TYPE.PERSON) {
				return '개인'
			} else if (status == INSTITUTE_TYPE.UNIVERSITY) {
				return '학교 소속 연구실'
			} else if (status == INSTITUTE_TYPE.ENTERPRISE) {
				return '기업 소속 연구실'
			} else if (status == INSTITUTE_TYPE.SELLER) {
				return '재판매업체(법인)'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.BusinessRegistrationDetail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
					&.error {
						color: $PRIMARY_RED;
					}
					&:after {
						width: 1px;
						height: 12px;
						content: '';
						position: absolute;
						top: calc(50% - 6px);
						left: 10px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px;
	}
}
</style>
