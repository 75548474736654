<template>
	<div id="app" @click.stop="appClick">
		<main class="App__inner-container">
			<app-header
				v-show="!isOnlyRouterView"
				:class="{
					'right-menu-on': $root.rightMenuOn,
					'notice-on': $root.isNoticeOn,
				}"
			></app-header>
			<app-side-menu v-show="!isOnlyRouterView" ref="menu"></app-side-menu>
			<section :class="{ 'App__router-container': isLogin, 'App__router-container-no-sidebar': !isLogin, scroll: !isLogin }">
				<router-view></router-view>
			</section>
		</main>
		<lm-toast-list></lm-toast-list>
		<portal-target name="lm-modal" multiple></portal-target>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import AppMixin from '@/mixins/index'
import PageName from '@/utils/define/PageName'
import AppHeader from '@/views/app/components/AppHeader.vue'
import AppSideMenu from '@/views/app/components/AppSideMenu'
import LmToastList from '@/views/common/components/Toast/ToastList.vue'
import SellerLogin from '@/views/seller/login/pages/SellerLogin.vue'
import { getToken } from '@/services/api/common'
import axiosLMSInstance from '@/services/axios'
import axiosLMPInstance from '@/services/axiosLMP'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'App',
	components: {
		AppHeader,
		AppSideMenu,
		LmToastList,
		SellerLogin,
	},
	mixins: [AppMixin],
	data() {
		return {
			hideHeaderPaths: ['/estimate/sheet/export'],
		}
	},
	computed: {
		...mapState('user', ['isLogin']),
		hasInstitute() {
			return this.$route.params.instituteId != null
		},
		isOnlyRouterView() {
			if (this.hideHeaderPaths.indexOf(this.$route.path) >= 0) {
				return true
			} else {
				return false
			}
		},
	},
	created() {
		this.createdInit()
	},
	methods: {
		...mapMutations('user', ['setIsLogin']),
		...mapActions('toast', ['showToast']),
		...mapActions('user', ['lookupUserInfo']),
		appClick() {
			this.$root.initStateOnMenu()
		},
		async createdInit() {
			this.setToast()
			const token = getToken()
			if (token) {
				axiosLMSInstance.setAuth(token)
				axiosLMPInstance.setAuth(token)
				this.fetchUserInfo()
				this.setIsLogin(true)
			} else {
				this.routeLogin()
			}
		},
		async fetchUserInfo() {
			try {
				await this.lookupUserInfo()
			} catch (error) {
				console.log(error)
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		routeLogin() {
			this.$router.replace({
				name: PageName.Seller.SellerLogin,
			})
		},
		setToast() {
			this.$root.toast = (title, content, type) => {
				const payload = {
					type: type,
					message: {
						title: title,
						content: content,
					},
				}
				this.showToast(payload)
			}
		},
	},
}
</script>

<style lang="scss">
@import '@/styles/scss/global/_font.scss';
html,
body {
	max-width: 4096px;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: hidden;
}

body {
	margin: 0;
}

#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	width: auto;
	height: 100%;
	overflow-y: hidden;
}

.App__header-temparary {
	display: block;
	position: absolute;
	left: 50%;
	z-index: 1000;
}

.App__router-container {
	display: block;
	position: fixed;
	z-index: 0;
	top: 60px;
	left: 250px;
	width: calc(100% - 250px);
	min-width: 650px;
	height: calc(100% - 60px) !important;
	/* overflow-y: hidden; */
	overflow-y: auto;
	&.experience {
		top: 140px !important;
		height: calc(100% - 140px) !important;
	}
}

.App__router-container-no-sidebar {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 0;
	min-width: 650px;
	height: 100%;
	width: 100%;
	max-width: unset;
	overflow-y: auto;
	background-color: $SUB_BACKGROUND;
}

.App__inner-container {
	display: block;
	position: relative;
	z-index: 0;
	min-width: 650px;
	height: 100%;
	min-height: 900px;
}

* {
	font-family: Noto Sans KR;
	letter-spacing: normal;
	font-stretch: normal;
	font-style: normal;
}

input:focus,
button:focus,
textarea:focus {
	outline: none;
}
div,
input {
	box-sizing: border-box;
}
</style>
