import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Order.OrderItemList,
		name: PageName.Order.OrderItemList,
		component: () => import('@/views/order/orderItem/pages/OrderItemList.vue'), // 주문 건별 목록
	},
	{
		path: RoutePath.Order.OrderItemDetail,
		name: PageName.Order.OrderItemDetail,
		component: () => import('@/views/order/orderItem/pages/OrderItemDetail.vue'), // 주문 건별 상세
	},
	{
		path: RoutePath.Order.OrderProductManagement,
		name: PageName.Order.OrderProductManagement,
		component: () => import('@/views/order/orderProduct/pages/OrderProductManagement.vue'), // 주문 상품별 목록
	},
	{
		path: RoutePath.Order.OrderProductManagementDetail,
		name: PageName.Order.OrderProductManagementDetail,
		component: () => import('@/views/order/orderProduct/pages/OrderProductManagementDetail.vue'), // 주문 상품별 상세
	},
	{
		path: RoutePath.Order.TransactionStatement,
		name: PageName.Order.TransactionStatement,
		component: () => import('@/views/order/orderProduct/pages/TransactionStatement.vue'), // 거래명세서
	},
]
