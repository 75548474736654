<template>
	<div class="buyer-detail">
		<div class="content-header">
			<div class="title">
				<h4>{{ item.name }}</h4>
			</div>
		</div>
		<div class="content-wrapper">
			<line-tab :list="buyerTabs" :value="selectedTab" @change="changeTab" />
			<div class="content info" v-if="selectedTab === 'info'">
				<info-list title="유저 정보" :titleBtn="estimateBtn" @clickEv="goEstimateForm" :list="buyerInfo" />
				<info-list title="마케팅 동의" :list="marketingInfo" />
				<detail-table title="소속 정보" :headers="headers.buyerHeaders">
					<template>
						<tbody>
							<tr v-for="(item, index) in item.institutes" :key="`institute_${index}`">
								<td>
									<span>{{ isData(item.type.name) }}</span>
								</td>
								<td>
									<b>{{ isData(item.name) }}</b>
								</td>
								<td>
									<span> {{ isData(item.id) }}</span>
								</td>
								<td>
									<span> {{ item.memberCount ? `${item.memberCount}명` : '-' }}</span>
								</td>
								<td>
									<span> {{ isData(item.managerName) }}</span>
								</td>
								<td>
									<span>{{ isData(Validation.autoHypenTel(item.phone)) }}</span>
								</td>
								<td>
									<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd') : '-' }}</span>
								</td>
							</tr>
						</tbody>
					</template>
				</detail-table>
			</div>
			<div class="content order" v-else-if="selectedTab === 'order'">
				<div class="order-wrapper">
					<div class="order-filter-wrapper">
						<status-counter-list :list="statusList" @active="onClickStatus" />
						<detail-search-box
							class="search-box"
							title="상세검색"
							subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
							searchPlaceholder="주문자, 주문상세 ID,  상품명, 브랜드로 검색"
							:datePlaceholder="datePlaceholder"
							:datePickerList="datePickerList"
							:useFold="true"
							@searchTextList="searchTextList"
						/>
					</div>
					<div class="order-list-wrapper">
						<div class="title-btn-wrapper">
							<search-title
								:title="`주문 목록(${totalCount})`"
								subTitle="상품을 선택 한후 아래 주문 상태를 클릭하여 주문상태를 변경할 수 있습니다."
							/>
							<div class="btn-wrapper">
								<button class="btn" @click="isShowModalVisibleOption = true">
									<img src="@/assets/svg/common/view-option.svg" />
									보기옵션
								</button>
								<button class="btn" @click="onClickDownloadOrderList">
									<img src="@/assets/svg/common/excel-download.svg" />
									목록 다운로드
								</button>
							</div>
						</div>
						<order-status-changer :selectedList="checkedList" @change="orderManagementCreated" />
						<div class="table-wrapper">
							<jw-table
								ref="jw-table"
								class="jwTable"
								:width="table.width"
								:height="table.height"
								:options="table.options"
								@getSelectedItem="getSelectedItem"
								@change="changeDate"
								:noData="isNoData"
								:noSearchResult="isNoSearchResult"
								:noDataText="noDataText"
								:noDataImg="noDataImg"
								:noDataSubtext="noDataSubtext"
								:noSearchResultText="noSearchResultText"
								:noSearchResultSubtext="noSearchResultSubtext"
								noDataHeight="600px"
							></jw-table>
							<div class="pagenation-wrapper">
								<common-pagenation @page="getCurrentPage" :total="totalCount" :defaultOffset="50"></common-pagenation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<modal-institute-select :isShow="isShowInstituteSelectModal" :item="item" @hide="isShowInstituteSelectModal = false" />
		<modal-order-modify
			:isShow="isShowOrderModifyModal"
			:orderItem="selectedItem"
			@hide="isShowOrderModifyModal = false"
			@complete="orderManagementCreated"
		/>
		<modal-shipping-info
			:isShow="isShowShippingInfoModal"
			:orderItemList="selectedItemList"
			@hide="isShowShippingInfoModal = false"
			@complete="orderManagementCreated"
		/>
		<modal-visible-option
			:isShow="isShowModalVisibleOption"
			:allColumns="allColumns"
			:visibleColumns="visibleColumns"
			@close="isShowModalVisibleOption = false"
			@save="setVisibleOption"
		/>
		<modal-update-price :isShow="isShowPriceModal" :item="changePriceRow" @hide="hidePriceModal" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import mixin from '@/mixins'
import DetailTable from '@/components/table/DetailTable'
import LineTab from '@/components/tab/LineTab'
import InfoList from '@/views/common/components/InfoList.vue'
import headers from '@/utils/define/table/header'
import JwTable from '@/views/common/components/JwTable.vue'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import { orderManagementTable, orderManagementTableAllColumns } from '@/utils/define/table/orderManagementTable'
import { TableField } from '@/utils/define/table/JwTable'
import { ToastType } from '@/utils/define/ToastType'
import Validation from '@/utils/Validation'
import PageName from '@/utils/define/PageName'
import ModalInstituteSelect from '@/views/buyer/buyer/modals/ModalInstituteSelect.vue'
import ModalOrderModify from '@/views/order/orderProduct/modals/ModalOrderModify.vue'
import ModalShippingInfo from '@/views/order/orderProduct/modals/ModalShippingInfo.vue'
import ModalVisibleOption from '@/views/common/modals/ModalVisibleOption.vue'
import ModalUpdatePrice from '@/views/order/orderProduct/modals/ModalUpdatePrice.vue'

export default {
	name: 'BuyerDetail',
	mixins: [mixin],
	components: {
		DetailTable,
		InfoList,
		LineTab,
		OrderStatusChanger,
		SearchTitle,
		CommonPagenation,
		StatusCounterList,
		DetailSearchBox,
		JwTable,
		ModalInstituteSelect,
		ModalOrderModify,
		ModalShippingInfo,
		ModalVisibleOption,
		ModalUpdatePrice,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	data() {
		return {
			Validation,
			buyerTabs: [
				{ name: '유저 정보', value: 'info' },
				{ name: '유저 주문내역', value: 'order' },
			],
			selectedTab: 'info',
			headers: headers,
			estimateBtn: {
				image: require('@/assets/svg/common/icon-estimate.svg'),
				text: '견적서 작성',
			},
			isShowInstituteSelectModal: false,
			isShowModalVisibleOption: false,
			isShowOrderModifyModal: false,
			isShowShippingInfoModal: false,
			isShowPriceModal: false,
			selectedItem: {},
			selectedItemList: [],
			selectedStatus: 'ALL',
			checkedList: [],
			table: this.$_.cloneDeep(orderManagementTable),
			sourceTable: orderManagementTable,
			allColumns: orderManagementTableAllColumns,
			cookieVisibleColumnsKey: 'BuyerDetailTableAllColumns',
			visibleColumns: [],
			totalCount: 0,
			listParams: {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				brandIds: [],
				shippingMethods: [],
				orderStartDatetime: undefined,
				orderEndDatetime: undefined,
				estimatedReleaseStartDatetime: undefined,
				estimatedReleaseEndDatetime: undefined,
				releaseStartDatetime: undefined,
				releaseEndDatetime: undefined,
				userId: this.id,
			},
			statusList: [],
			datePickerList: [{ text: '주문일' }],
			changePriceRow: {},
		}
	},
	computed: {
		...mapState('buyer/detail', ['item']),
		...mapGetters('order', ['getOrderList', 'getOrderListTableData', 'getOrderListSummary', 'getOrderListFilter']),
		buyerInfo() {
			// 유저 정보
			return [
				{ title: '이름', value: this.item.name },
				{ title: '연락처', value: Validation.autoHypenTel(this.item.phone) },
				{ title: '이메일(계정)', value: this.item.email },
				{ title: '본인인증', value: this.item.isVerified ? '완료' : '미완료' },
			]
		},
		marketingInfo() {
			// 마케팅 동의
			return [
				{ title: 'SMS 동의', value: this.item.isNotiSms ? '동의' : '미동의' },
				{ title: '이메일 동의', value: this.item.isNotiEmail ? '동의' : '미동의' },
				{ title: 'PUSH 동의', value: this.item.isNotiPush ? '동의' : '미동의', isSingleRow: true },
			]
		},
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		isNoData() {
			return this.listParams.keyword.length === 0 && this.totalCount === 0
		},
		isNoSearchResult() {
			return this.listParams.keyword.length > 0 && this.totalCount === 0
		},
		noDataText() {
			return '유저가 주문한 상품이 없습니다.'
		},
		noDataSubtext() {
			return '유저가 주문을 시작하면, 주문한 상품을 보여드립니다.'
		},
		noSearchResultText() {
			return '검색 결과가 없습니다'
		},
		noSearchResultSubtext() {
			return '상품명, 상품번호, 자체 관리번호, 브랜드, 제품번호, CAS No로 검색할 수 있습니다.'
		},
		noDataImg() {
			return require('@/assets/svg/common/jw-table/img-nodata.svg')
		},
	},
	created() {
		this.changeTab(this.buyerTabs[0].value)
	},
	methods: {
		...mapActions('buyer/detail', ['getDetail']),
		...mapActions('order', ['lookupOrderList', 'lookupOrderListSummary', 'changeEstimatedDate', 'downloadOrderList']),
		async changeTab(val) {
			this.selectedTab = val
			if (this.selectedTab === 'info') {
				await this.getDetail(this.id)
			} else if (this.selectedTab === 'order') {
				this.orderManagementCreated()
			}
		},
		goEstimateForm() {
			if (this.item.institutes.length > 1) {
				this.isShowInstituteSelectModal = true
			} else {
				this.$router.push({
					name: PageName.Estimate.EstimateForm,
					params: {
						mode: 'add',
					},
					query: {
						instituteId: this.item.institutes[0].id,
						manager: JSON.stringify({
							id: this.item.id,
							name: this.item.name,
							phone: this.item.phone,
							email: this.item.email,
							instituteName: this.item.institutes[0].name,
						}),
					},
				})
			}
		},
		orderManagementCreated() {
			this.loadListData()
			this.loadSummaryData()
			this.setColumnClickEvent()
		},
		routeOrderDetailPage(orderId) {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: orderId,
				},
			})
		},
		routeOrderItemDetailPage(orderItemId) {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: orderItemId,
				},
			})
		},
		routeLabDetail(row) {
			if (row.isBlock === 1) {
				this.$router.push({
					name: PageName.Buyer.LabDetail,
					params: {
						id: row.institute.id,
					},
				})
			}
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())
			await this.lookupOrderList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			await this.lookupOrderListSummary({ userId: this.id })
			this.setSummaryData()
		},
		setData() {
			const response = this.getOrderListTableData
			this.totalCount = response.count
			this.table.options.tableData = response.tableData
			if (this.$cookies.get(this.cookieVisibleColumnsKey)) this.setVisibleOption(JSON.parse(this.$cookies.get(this.cookieVisibleColumnsKey)))
		},
		scrollTop() {
			document.querySelector('.jwTable').scrollTo(0, 0)
		},
		setSummaryData() {
			this.statusList = Object.keys(this.getOrderListSummary.summary).map(summary => {
				return {
					id: summary,
					title: this.getStatusTitle(summary),
					count: this.getOrderListSummary.summary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		getStatusTitle(key) {
			const result = {
				ALL: '전체',
				APPROVE: '승인 완료',
				CANCEL: '주문취소',
				SHIPPING_COMPLETE: '배송완료',
				SHIPPING_ING: '배송중',
				WAIT: '승인 대기',
			}[key]

			return result
		},
		setColumnClickEvent() {
			this.table.options.columns.forEach((data, index) => {
				if (data.key === TableField.ReleaseVolume) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItem = row
						this.isShowOrderModifyModal = true
					}
				}
				if (data.key === TableField.DeliveryMethod) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItemList = [row]
						this.isShowShippingInfoModal = true
					}
				}
				if (data.key === TableField.OrderPrice) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.changePriceRow = row
						this.isShowPriceModal = true
					}
				}
				if (data.key === TableField.OrderNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderDetailPage(data.id)
					}
				}
				if (data.key === TableField.OrderItemNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderItemDetailPage(data.id)
					}
				}
				if (data.key === TableField.Status) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeLabDetail(data.id)
					}
				}
			})
		},
		setVisibleOption(visibleNameList) {
			this.table.options.columns = [
				...this.sourceTable.options.columns.filter(item => {
					return visibleNameList.includes(item.name)
				}),
			]
			this.visibleColumns = visibleNameList
			this.$cookies.set(this.cookieVisibleColumnsKey, JSON.stringify(visibleNameList))
			this.setColumnClickEvent()
		},
		hidePriceModal() {
			this.isShowPriceModal = false
			this.loadListData()
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			const periodType = {
				ORDER_DATE: '기간 조회/주문일',
			}
			this.listParams.orderStartDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[0] : undefined
			this.listParams.orderEndDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[1] : undefined
			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''
			this.loadListData()
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		getCurrentPage(page) {
			const { offset, length } = page
			this.listParams.offset = offset
			this.listParams.length = length
			this.scrollTop()
			this.loadListData()
		},
		async onClickDownloadOrderList() {
			try {
				const response = await this.downloadOrderList(this.listParams)
				window.fileDownload(response, '전체 주문관리 목록')
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.buyer-detail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 54px;
		padding: 0 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
		}
	}
	.content-wrapper {
		padding: 0 30px 30px 30px;
		width: 100%;
		.order-filter-wrapper {
			padding: 0 0 20px 0;
			.search-box {
				margin-top: 33px;
			}
		}
		.order-list-wrapper {
			.title-btn-wrapper {
				display: flex;
				justify-content: space-between;
				padding-top: 20px;
			}
			.btn-wrapper {
				display: flex;
				gap: 10px;
			}
			.table-wrapper {
				padding-top: 10px;
				.pagenation-wrapper {
					position: relative;
					z-index: 100;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.buyer-detail {
	.line-tab {
		padding: 0 0;
		height: 50px;
		margin-bottom: 40px;
		.tab {
			&.active {
				border-color: $PRIMARY_YELLOW;
				font-weight: 700;
			}
		}
	}
	.search-wrap {
		.search-box {
			width: 300px;
		}
	}
}
</style>
