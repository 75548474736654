import noticeAPI from '@/services/api/lms/notice'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			offset: 0,
			length: 50,
		},
		list: [],
		count: 0,
		detail: {},
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setDetail: (state, data) => {
			state.detail = data
			state.detail.startDatetime = new Date(data.startDatetime)
			state.detail.endDatetime = new Date(data.endDatetime)
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		initParams: state => {
			state.loadParams = {
				keyword: null,
				offset: 0,
				length: 50,
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await noticeAPI.list(state.loadParams)
			commit('setList', response.data)
		},
		lookupNotice: async ({ state, commit }, params) => {
			const response = await noticeAPI.lookupNotice(params)
			commit('setDetail', response.data)
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		createNotice: async ({ commit }, body) => {
			return await noticeAPI.createNotice(body)
		},
		updateNotice: async ({ commit }, body) => {
			return await noticeAPI.updateNotice(body)
		},
	},
}
