import { APP_MODE_PRODUCTION, APP_MODE_EXPERIENCE, APP_MODE_DEV_EXPERIENCE, APP_MODE_DEVELOP } from '@/utils/define/Constant'
import { mapState } from 'vuex'
import PageName from '@/utils/define/PageName'
import Cookies from 'js-cookie'

export default {
	data() {
		return {
			rightMenuOn: false,
			groupSelectorOn: false,
			dropdownOn: false,
			orgTreeDropdownOn: false,
			currentLabId: null,
			isNoticeOn: false,
			cardInput: false,
			isSearchRecentOn: false,
			isCalendarOn: false,
			toastTitle: '',
			toastContent: '',
			toastType: '',
			// NEW
			// DONE
			// AVAILABLE
			// NOPERM
			// ING
			// SELECT
			// REJECT
		}
	},
	created() {
		this.instituteId = Cookies.get('instituteId')
	},
	computed: {
		// ...mapState('user', ['institute']),
		serviceUrl() {
			const debug = process.env.NODE_ENV !== 'production'
			return debug
				? location.hostname.includes('lab-manager-enterprise.com')
					? 'https://dev-app.lab-manager-enterprise.com'
					: 'http://localhost:8080'
				: 'https://app.lab-manager-enterprise.com'
		},
		appMode() {
			// 앱 모드 체크용
			return {
				APP_MODE_PRODUCTION: APP_MODE_PRODUCTION, // 실서버
				APP_MODE_DEVELOP: APP_MODE_DEVELOP, // 개발용
				APP_MODE_EXPERIENCE: APP_MODE_EXPERIENCE, // 둘러보기 실서버용
				APP_MODE_DEV_EXPERIENCE: APP_MODE_DEV_EXPERIENCE, // 둘러보기 개발용
			}
		},
		isAppModeExperience() {
			const appMode = process.env.VUE_APP_MODE
			// const appMode = this.appMode.APP_MODE_DEV_EXPERIENCE
			// const appMode = this.appMode.APP_MODE_DEV_EXPERIENCE
			return [this.appMode.APP_MODE_EXPERIENCE, this.appMode.APP_MODE_DEV_EXPERIENCE].includes(appMode)
		},
		PageName() {
			return PageName
		},
	},

	methods: {
		initStateOnMenu() {
			this.rightMenuOn = false
			this.groupSelectorOn = false
			this.dropdownOn = false
			this.orgTreeDropdownOn = false
			this.isNotice = false
			this.isSearchRecentOn = false
			this.isCalendarOn = false
			this.cardInput = false
		},
		// routeDashboard() {
		// 	this.$router.push({
		// 		name: PageName.Dashboard,
		// 		params: {
		// 			instituteId: this.institute.id,
		// 		},
		// 	})
		// },
		formatNumberLocale(num) {
			if (typeof num === 'number') return num.toLocaleString()
			else if (typeof num === 'string') return Number(num).toLocaleString()
			else return num
		},
		formatDatetime(isoDatetime, format = 'yyyy-MM-dd HH:mm:ss') {
			if (!isoDatetime) return isoDatetime
			return this.$DateTime.fromISO(isoDatetime).toFormat(format)
		},
		formatDate(isoDatetime, format = 'yyyy-MM-dd') {
			if (!isoDatetime) return isoDatetime
			return this.$DateTime.fromISO(isoDatetime).toFormat(format)
		},
		formatStartDatetime(startDate) {
			return `${startDate} 00:00:00`
		},
		formatEndDatetime(endDate) {
			return `${endDate} 23:59:59`
		},
		printToast(title, content, type) {
			this.$root.toast(title, content, type)
		},
		formatData(data, column) {
			const value = data[column]
			const unitData = data[`${column}Unit`]
			if (typeof value === 'string') {
				const trimStr = value.trim()
				const trimStrLength = trimStr.length
				let resultData = value
				if (unitData) {
					resultData += unitData
				}
				return trimStrLength > 0 ? resultData : '-'
			} else if (typeof value === 'number') {
				let resultData = value
				if (unitData) {
					resultData += unitData
				}
				return resultData ? resultData : '-'
			} else {
				return '-'
			}
		},
		//숫자에 comma 추가
		commaNum(num) {
			if (num != null) {
				return num.toLocaleString('ko-KR')
			}
		},
		isData(data) {
			if (data) {
				return data
			} else {
				return '-'
			}
		},
		checkEnding(word) {
			if (typeof word !== 'string') return null

			let lastLetter = word[word.length - 1]
			let uni = lastLetter.charCodeAt(0)

			if (uni < 44032 || uni > 55203) return null

			return (uni - 44032) % 28 != 0 ? '을' : '를'
		},
	},
}
