<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ViewOptionPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">보기 옵션</h5>
					<button class="Popup__close-btn" @click="close">
						<img :src="`${iconModalCloseBtn}`" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="flex">
						<h5>조회 항목</h5>
						<button class="select-all-btn" @click="selectAll">{{ isAll ? '전체해제' : '전체선택' }}</button>
					</div>
					<ul class="Filter__list">
						<li class="Filter__item" v-for="item in columns" :key="item">
							<input
								class="item-input"
								type="checkbox"
								:id="item"
								:value="item"
								v-model="visible"
								:disabled="requiredColumns.includes(item)"
							/>
							<label class="item-label" :for="item">
								{{ item }}
							</label>
						</li>
					</ul>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="close">취소</button>
						<button class="Btn__complete" @click="save">저장</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import IconModalCloseBtn from '@/assets/svg/common/popup-close.svg'
import { ToastType } from '@/utils/define/ToastType'
export default {
	name: 'ModalVisibleOption',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		// 존재하는 모든 Columns
		allColumns: {
			type: Array,
			default: () => [],
		},
		// 보여줄 Columns
		visibleColumns: {
			type: Array,
			default: () => [],
		},
		// 필수 값인 Columns
		requiredColumns: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		isAll() {
			return this.allColumns.length === this.visible.length
		},
	},
	watch: {
		isShow(value) {
			if (value) {
				this.columns = [...this.allColumns]
				const isEmptyVisibleColumn = this.visibleColumns.length === 0
				this.visible = isEmptyVisibleColumn ? [...this.allColumns] : [...this.visibleColumns]
			}
		},
	},
	data() {
		return {
			list: [],
			viewOptionList: [],
			fixedOption: '',
			iconModalCloseBtn: IconModalCloseBtn,
			visible: [],
			columns: [],
		}
	},
	methods: {
		selectAll() {
			if (this.isAll) this.visible = []
			else this.visible = [...this.allColumns]
		},
		close() {
			this.$emit('close')
		},
		save() {
			if (!this.visible.length) return this.$root.toast('보기옵션', '적어도 하나 이상의 필드를 선택해야합니다.', 'error')
			const title = '보기옵션'
			const content = '보기옵션이 저장되었습니다.'
			const type = ToastType.SUCCESS
			this.$root.toast(title, content, type)
			this.$emit('save', this.visible)
			this.close()
		},
	},
}
</script>

<style lang="scss" scoped>
.ViewOptionPopup {
	.Popup__container {
		.Popup__content {
			.flex {
				h5 {
					font-size: 0.875rem;
					color: $COLOR_666;
					font-weight: 700;
				}
				.select-all-btn {
					color: $PRIMARY_BLUE;
					font-weight: 700;
					font-size: 14px;
					margin-left: auto;
				}
			}
			.Filter__list {
				margin-top: 12px;
				.Filter__item {
					width: 50%;
					.item-input {
						width: 18px;
						height: 17px;
						&:disabled::after {
							background-image: url(/img/select-check-on-disabled.65774fa0.svg);
							background-color: #c1c1c1;
							border-color: $COLOR_CCC;
							background-size: 26px;
						}
					}
				}
			}
		}
	}
}
</style>
