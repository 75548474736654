import axiosInstance from '@/services/axios'

const endPoint = {
	lookupProductInfoList: () => 'admin/productInfo/list',
	lookupProductInfoFilter: () => 'admin/productInfo/list/filter',
	lookupProductInfo: id => `admin/productInfo?productInfoId=${id}`,
	stopSaleProductInfo: () => 'admin/productInfo/stop',
}

const api = {
	lookupProductInfoList: params => axiosInstance.get(endPoint.lookupProductInfoList(), { params }),
	lookupProductInfoFilter: () => axiosInstance.get(endPoint.lookupProductInfoFilter()),
	lookupProductInfo: id => axiosInstance.get(endPoint.lookupProductInfo(id)),
	stopSaleProductInfo: body => axiosInstance.patch(endPoint.stopSaleProductInfo(), body),
}

export default api
