<template>
	<b-modal ref="modal" title="물품 검색" centered>
		<div class="search-wrap">
			<input-select-option ref="input-select" v-model="params.type" placeholder="검색 조건 선택" :list="type" />
			<search-box
				class="search-box"
				name="modal-search-product-search-box"
				placeholder="물품명, CAS NO, Catalog No를 입력해주세요"
				@change="getList"
				v-model="params.keyword"
			/>
		</div>
		<br />
		<div class="search-wrap">
			<search-box
				class="search-box"
				name="modal-search-product-search-box"
				placeholder="제조사 이름을 입력해주세요"
				@change="getList"
				v-model="params.makerName"
			/>
		</div>
		<b-overlay :show="isLoading" spinner-variant="yellow" rounded="sm">
			<div class="list-wrap" ref="scroll-container" @scroll="onScroll">
				<div
					class="institute-wrap"
					:class="{ selected: item.id == selected }"
					v-for="item in list"
					:key="item.id"
					@click="e => ((selected = item.id), (selectedItem = item))"
				>
					<div class="content">
						<div class="chemical-name">물품명: {{ item.name }}</div>
						<div class="chemical-name">Catalog#: {{ item.catalogNo }}</div>
						<div class="chemical-name">제조사: {{ item.maker?.name }}</div>
						<div class="chemical-id">물품ID: {{ item.id }}</div>
						<div class="chemical-id">제조사ID: {{ item.maker?.id }}</div>
						<div class="chemical-id">Cas#: {{ item.casNo ?? '-' }}</div>
						<div class="chemical-id">용량: {{ item.volume?.value + item.volume?.unit ?? '-' }}</div>
					</div>
				</div>
			</div>
		</b-overlay>
		<template #modal-footer>
			<div class="footer-right">
				<button class="button-gray" @click="hide()">취소</button>
				<button class="button-primary" @click="onClickConfirm" :disabled="!selected">선택</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import MixinScrolling from '@/mixins/scrolling'
import SearchBox from '@/views/common/components/searchBox/index'
import { mapActions } from 'vuex'
export default {
	components: {
		SearchBox,
		InputText,
		InputSelectOption,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			selected: null,
			selectedItem: null,
			params: {
				type: null,
				keyword: '',
				offset: 0,
				length: 20,
				id: null,
				makerName: '',
			},
			list: [],
			count: 0,
			isLoading: false,
		}
	},
	watch: {
		async isBottom() {
			if (this.list.length < this.count) await this.getList(true)
		},
		'params.type'(val) {
			if (val != 'detail') this.params.id = null
		},
	},
	computed: {
		type() {
			return [
				{ value: 'name', name: '이름' },
				{ value: 'catalog', name: 'Catalog#' },
				{ value: 'casno', name: 'CAS#' },
				{ value: 'detail', name: '상세' },
			]
		},
	},
	methods: {
		...mapActions('chemicalApi/search', ['searchProduct', 'getProductDetail']),
		show(keyword = '', type = '') {
			this.params = {
				type: null,
				keyword: '',
				makerName: '',
				offset: 0,
				length: 20,
				id: null,
			}
			this.list = []
			this.params.keyword = keyword
			this.selected = keyword
			if (keyword) this.params.type = 'detail'
			if (type) this.params.type = type

			this.$refs['modal'].show()
			this.getList()
		},
		async getList(isMore = false) {
			if (this.isLoading) return
			this.isLoading = true
			if (!isMore) {
				this.list = []
				this.params.offset = 0
			}
			if (this.params.type != null) {
				if (this.params.type == 'detail' && this.params.keyword) {
					const response = await this.getProductDetail(this.params.keyword)
					this.count = 1
					this.list = [response]
				} else if (this.params.keyword) {
					const response = await this.searchProduct(this.params)
					if (response.code == 0) {
						this.count = response.count
						this.list = this.list.concat(response.list)
						this.params.offset = this.list.length
					} else {
						this.$root.toast('오류', response.msg, 'error')
					}
				}
			}
			this.isLoading = false
		},
		hide() {
			this.$refs['modal'].hide()
		},
		onClickConfirm() {
			console.log(this.selectedItem)
			this.$emit('confirm', this.selected, this.selectedItem)
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
.search-wrap {
	display: flex;
	gap: 10px;
	::v-deep {
		.item-wrapper {
			border-bottom: none;
			padding: 0px;
			min-height: 30px;
			width: unset;
			.item-content {
				width: unset;
			}
			.select-wrapper {
				height: 30px !important;
				width: 150px !important;
			}
		}
		.search-box {
			width: 100%;
		}
	}
}
.list-wrap {
	margin-top: 16px;
	border: 1px solid $FILE_BORDER;
	border-radius: 5px;
	height: 500px;
	overflow-y: auto;
	.institute-wrap {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 16px;
		gap: 16px;
		min-height: 80px;
		border-bottom: 1px solid $FILE_BORDER;
		&:hover {
			background-color: $PRIMARY_YELLOW_100;
		}
		&.selected,
		&:active {
			background-color: $PRIMARY_YELLOW_200;
		}
		.logo {
			border-radius: 5px;
			width: 56px;
			height: 56px;
			object-fit: cover;
		}
		.content {
			display: flex;
			flex-direction: column;
			width: 100%;
			.chemical-name {
				font-size: 14px;
				color: #111;
			}
			.chemical-id {
				font-size: 12px;
				color: #333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>
