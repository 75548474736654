import cancelRefundAPI from '@/services/api/cs/cancelRefund'

export default {
	namespaced: true,
	state: {
		item: null,
	},
	getters: {},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await cancelRefundAPI.lookupCancelRefundDetail(id)
			commit('setItem', response.data)
		},
		setReturn: async ({ commit }, body) => {
			return await cancelRefundAPI.setReturn(body)
		},
		setCancel: async ({ commit }, body) => {
			return await cancelRefundAPI.setCancel(body)
		},
		setReturnReject: async ({ commit }, body) => {
			return await cancelRefundAPI.setReturnReject(body)
		},
		setCancelReject: async ({ commit }, body) => {
			return await cancelRefundAPI.setCancelReject(body)
		},
		setMemo: async ({ commit }, body) => {
			return await cancelRefundAPI.setMemo(body)
		},
	},
}
