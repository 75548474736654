import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `admin/request/list`,
	filter: () => `admin/request/list/filter`,
	requestProduct: () => `admin/request`,
	lookupRequestProduct: id => `admin/request?id=${id}`,
	updateRequestProductMemo: () => `admin/request/memo`,
	failSourcing: () => `admin/request/fail`,
	completeSourcing: () => `admin/request/complete`,
	confirmReceipt: () => `admin/request/take`,
	addProduct: () => `/admin/request/product`,
	deleteProduct: () => `/admin/request/product`,
	setRequestType: () => `/admin/request/type`,
	printEstimate: () => `/admin/request/estimate/print`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	filter: () => axiosInstance.get(endPoint.filter()),
	requestProduct: body => axiosInstance.post(endPoint.requestProduct(), body),
	lookupRequestProduct: id => axiosInstance.get(endPoint.lookupRequestProduct(id)),
	updateRequestProductMemo: body => axiosInstance.patch(endPoint.updateRequestProductMemo(), body),
	failSourcing: body => axiosInstance.patch(endPoint.failSourcing(), body),
	completeSourcing: body => axiosInstance.patch(endPoint.completeSourcing(), body),
	confirmReceipt: body => axiosInstance.patch(endPoint.confirmReceipt(), body),
	addProduct: body => axiosInstance.post(endPoint.addProduct(), body),
	deleteProduct: params => axiosInstance.delete(endPoint.deleteProduct(), { params }),
	setRequestType: body => axiosInstance.patch(endPoint.setRequestType(), body),
	printEstimate: body => axiosInstance.post(endPoint.printEstimate(), body),
}

export default api
