<template>
	<div class="detail-wrapper" v-if="item && item.id == id">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ item.title }}</h2>
				<h6>
					<span :class="['status', PopupStatus.statusToClass(item.status)]"> {{ PopupStatus.statusToString(item.status) }} </span>
					<p>번호: {{ item.id }}&nbsp;&nbsp; 등록일시: {{ createDatetime }} &nbsp;&nbsp; 등록자: {{ item.register.name }}</p>
				</h6>
			</div>
			<div class="button-groups">
				<base-button size="md" @click="goToEdit">수정</base-button>
				<base-button size="md" @click="onDelete">삭제</base-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="배너 설정" :list="bannerInfo" />
			<div class="banner-images">
				<banner-image title="팝업 이미지" :width="480" :height="680" :image="item.image" :link="item.link" />
			</div>
		</div>
		<modal-confirm ref="modal-delete" variant="warning" title="팝업 삭제" content="팝업을 정말로 삭제하시겠습니까?" confirmText="삭제" />
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { mapActions, mapState } from 'vuex'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import BannerImage from '../../components/BannerImage.vue'
import { PopupStatus, PopupTargetType, InstituteType } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'

export default {
	components: {
		BaseButton,
		InfoList,
		BannerImage,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	data() {
		return {
			PopupStatus,
		}
	},
	computed: {
		...mapState('lms/event', ['item']),
		createDatetime() {
			return this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd HH:mm') : '-'
		},
		openTarget() {
			if (this.item.targetType === PopupTargetType.INSTITUTE_TYPE) {
				return InstituteType.typeToString(this.item.targetInstituteType)
			} else {
				return PopupTargetType.typeToString(this.item.targetType)
			}
		},
		bannerInfo() {
			return [
				{ title: '노출타겟', value: this.openTarget },
				{
					title: '노출 시작일시',
					value: this.item.startDatetime ? this.$DateTime.fromISO(this.item.startDatetime).toFormat('yyyy-MM-dd HH:mm') : '-',
				},
				{
					title: '노출 종료일시',
					value: this.item.endDatetime ? this.$DateTime.fromISO(this.item.endDatetime).toFormat('yyyy-MM-dd HH:mm') : '-',
					isSingleRow: true,
				},
			]
		},
	},
	created() {
		this.getDetail(this.id)
	},
	methods: {
		...mapActions('lms/event', ['getDetail', 'deletePopup']),
		goToEdit() {
			this.$router.push({ name: PageName.Lms.EventEdit, params: { id: this.id } })
		},
		onDelete() {
			this.$refs['modal-delete'].show(async () => {
				try {
					const response = await this.deletePopup([this.id])
					if (response.status == 205) {
						this.$root.toast('팝업 삭제', `팝업이 삭제되었습니다.`, 'success')
						this.$router.go(-1)
					}
				} catch (e) {
					this.$root.toast('팝업 삭제', `팝업을 삭제하지 못했습니다.`, 'error')
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.banner-images {
	margin-bottom: 20px;
	& > *:first-child {
		margin-right: 20px;
	}
	& > * {
		vertical-align: text-top;
	}
}
</style>
