import productInfoAPI from '@/services/api/product-info'

export default {
	namespaced: true,
	state: {
		loadParams: {
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			keyword: '',
			keywordType: 'TEXT',
			isStops: [],
			hasStocks: [],
			brandIds: [],
			categoryCodes: [],
			shippingAddressIds: [],
		},
		filter: null,
		list: [],
		count: 0,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			data.expireStatus.forEach(status => {
				status.id = status.code
			})
			state.filter = data
		},
	},
	actions: {
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		getList: async ({ state, commit }) => {
			const response = await productInfoAPI.lookupProductInfoList(state.loadParams)
			commit('setList', response.data)
		},
		getFilter: async ({ commit }) => {
			const response = await productInfoAPI.lookupProductInfoFilter()
			commit('setFilter', response.data)
		},
	},
}
