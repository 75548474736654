<template>
	<div class="order-detail-page-wrapper">
		<div class="header">
			<div class="title-wrapper">
				<h2 class="title">{{ title }}</h2>
			</div>
		</div>
		<div class="input-wrapper">
			<div class="sub-title">
				<h5>공급받는자</h5>
				<span>*해당 거래명세서를 공급받은 업체/사람입니다.</span>
			</div>
			<div>
				<p>소속 <span>*</span></p>
				<input placeholder="소속을 입력해주세요" v-model="info.recipientInstituteName" />
			</div>
			<div>
				<p>이름 <span>*</span></p>
				<input placeholder="이름을 입력해주세요" v-model="info.recipientName" />
			</div>
			<div>
				<p>연락처 <span>*</span></p>
				<input placeholder="연락처를 입력해주세요" v-model="info.recipientPhone" @input="autoHypenTel" />
			</div>
			<div>
				<p>이메일 <span>*</span></p>
				<input placeholder="이메일을 입력해주세요" v-model="info.recipientEmail" />
			</div>
			<div>
				<p>거래일 <span>*</span></p>
				<image-date-button
					type="calendar"
					:isRange="false"
					:clearable="true"
					:datePlaceholder="datePlaceholder"
					:datePickerStyle="{ width: '150px' }"
					@changeDate="changeDate"
				></image-date-button>
			</div>
		</div>
		<div class="product-wrapper">
			<product-info-table
				title="상품정보"
				:headers="productInfoTableHeader"
				:orders="getOrderItemGroup.orders"
				:totalPrice="getOrderItemGroup.totalOrderItemPriceWithVat"
				:vatPrice="getOrderItemGroup.totalOrderItemVat"
				:sellingPrice="getOrderItemGroup.totalOrderItemPrice"
				isRemove
			/>
		</div>
		<div class="bottom-wrapper">
			<button class="btn" @click.stop="onClickCancel">취소</button>
			<button class="btn btn-complete" @click.stop="onClickPrint">거래명세서 출력</button>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import ProductInfoTable from '@/components/product/ProductInfoTable'
import ImageDateButton from '@/views/common/components/button/ImageDateButton.vue'
import Validation from '@/utils/Validation.js'

export default {
	name: 'TransactionStatement',
	components: { ProductInfoTable, ImageDateButton },
	data() {
		return {
			title: '거래명세서',
			productInfoTableHeader: [
				{ name: '상품 정보', key: 'info', width: '70%' },
				{ name: '수량', key: 'quantity', width: '8%' },
				{ name: '주문금액', key: 'price', width: '14%' },
				{ name: '주문자', key: 'orderer', width: '8%' },
			],
			info: {
				recipientInstituteName: '',
				recipientName: '',
				recipientPhone: '',
				recipientEmail: '',
				tradeDatetime: '',
				orderItemIds: '',
			},
		}
	},
	computed: {
		...mapGetters('order', ['getOrderItemGroup']),
		datePlaceholder() {
			return this.$DateTime.now().toFormat('yyyy-MM-dd')
		},
	},
	created() {
		this.setStorageDetailPaths([this.title])
		this.transactionStatementCreated()
	},
	methods: {
		...mapMutations('breadcrumb', ['setStorageDetailPaths']),
		...mapActions('order', ['lookupOrderItemGroup', 'printTradeStatement']),
		async transactionStatementCreated() {
			this.info.orderItemIds = this.$route.query.ids
			await this.lookupOrderItemGroup({ orderItemIds: this.$route.query.ids })
		},
		changeDate(date) {
			this.info.tradeDatetime = date
		},
		onClickCancel() {
			this.$router.go(-1)
		},
		autoHypenTel(e) {
			const phoneNumber = Validation.autoHypenTel(e.target.value)
			this.setPhone(phoneNumber)
		},
		setPhone(phone) {
			this.info.recipientPhone = phone
		},
		async onClickPrint() {
			if (!this.validate()) return
			try {
				const response = await this.printTradeStatement(this.info)
				window.fileDownload(response, `거래명세서_${this.info.recipientInstituteName}_${this.info.tradeDatetime}.pdf`)
				this.$router.go(-1)
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, 'error')
			}
		},
		validate() {
			let isValid = true
			let content = ''
			if (!this.info.recipientInstituteName) {
				content = '소속을 입력해주세요'
				isValid = false
			} else if (!this.info.recipientName) {
				content = '이름을 입력해주세요'
				isValid = false
			} else if (!this.info.recipientPhone) {
				content = '연락처를 입력해주세요'
				isValid = false
			} else if (!this.info.recipientEmail) {
				content = '이메일을 입력해주세요'
				isValid = false
			} else if (!this.info.tradeDatetime) {
				content = '거래일을 선택해주세요'
				isValid = false
			}

			if (!isValid) this.$root.toast('에러', content, 'error')

			return isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.order-detail-page-wrapper {
	overflow-y: auto;
	.header {
		width: 100%;
		padding: 20px 30px;
		min-height: 54px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		.title {
			font-size: 1.5rem;
			font-weight: bold;
			color: $PRIMARY_BLACK;
			text-align: left;
			align-items: center;
			height: auto;
		}
	}
	.input-wrapper {
		padding: 30px;
		border-bottom: solid 1px $LINE_DIVIDER;
		.sub-title {
			h5 {
				font-weight: 700;
				font-size: 20px;
			}
			span {
				font-weight: 500;
				font-size: 14px;
				color: #666666;
				margin-left: 15px;
				margin-top: 3px;
			}
		}
		div {
			display: flex;
			padding: 10px 0;
			p {
				padding-left: 10px;
				text-align: start;
				font-weight: 600;
				font-size: 14px;
				width: 200px;
				align-self: center;
				span {
					color: $PRIMARY_RED;
				}
			}
			input {
				padding: 10px;
				font-size: 14px;
				width: 400px;
				height: 40px;
				border: 1px solid #eeeeee;
				border-radius: 4px;
			}
		}
	}
	.product-wrapper {
		padding: 30px;
	}
	.memo-wrapper {
		padding: 30px;
		.sub-title {
			h5 {
				font-weight: 700;
				font-size: 20px;
			}
			span {
				font-weight: 500;
				font-size: 14px;
				color: #666666;
				margin-left: 15px;
				margin-top: 3px;
			}
		}
		div {
			display: flex;
			padding: 12px 0;
			p {
				text-align: start;
				font-weight: 600;
				font-size: 14px;
				width: 200px;
				align-self: center;
			}
			input {
				width: 400px;
				height: 40px;
				border: 1px solid #eeeeee;
				border-radius: 4px;
			}
		}
		.memo-contents-wrapper {
			width: 100%;
			position: relative;
			padding: 5px;
			height: 120px;
			.memo-contents {
				width: 100%;
				height: 70px;
				font-weight: 400;
				font-size: 14px;
				border: solid 1px $LINE_DIVIDER;
				padding: 10px;
			}
			.memo-button {
				width: 100px;
				height: 36px;
				position: absolute;
				left: 5px;
				bottom: 5px;
				background-color: $JW_200;
				font-weight: 700;
				font-size: 14px;
				color: $FONT_YELLOW;
				justify-content: center;
			}
			span {
				font-weight: 400;
				font-size: 12px;
				color: $FONT_PLACEHOLDER;
				position: absolute;
				right: 10px;
				bottom: 5px;
			}
		}
	}
	.bottom-wrapper {
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 15px;
		button {
			width: 200px;
			height: 48px;
			text-align: center;
			justify-content: center;
		}
	}
}
</style>
