<template>
	<tr class="table-header">
		<th>
			<span>유형</span>
		</th>
		<th>
			<span>제목</span>
		</th>
		<th>
			<span>작성자</span>
		</th>
		<th>
			<span>작성일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	mixins: [MixinHeader],
	methods: {
		...mapActions('lms/faq', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
