<template>
	<div class="page-status-list">
		<page-status-item
			v-for="(item, index) in list"
			:key="`status_${index}`"
			:item="item"
			:pageNumber="index + 1"
			:currentPageNumber="currentPageNumber"
			:isLastIndex="index === list.length - 1"
		></page-status-item>
	</div>
</template>

<script>
import PageStatusItem from '@/views/product/registration/components/PageStatusItem.vue'

export default {
	name: 'PageStatusList',
	components: {
		PageStatusItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		currentPageNumber: {
			type: Number,
			default: 1,
		},
	},
}
</script>

<style lang="scss" scoped>
.page-status-list {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	padding: 0 30px;
}
</style>
