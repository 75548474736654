<template>
	<div class="RequestProductList">
		<list-header @changeLoadParams="changeLoadParams" @refresh="getList" />
		<div class="table-wrapper">
			<request-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import RequestTable from '../components/Table.vue'
import PageName from '@/utils/define/PageName'

export default {
	components: {
		Pagination,
		RequestTable,
		ListHeader,
	},
	computed: {
		...mapState('request/list', ['list', 'count', 'loadParams']),
	},
	data() {
		return {
			beforePage: '',
		}
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.beforePage = from.name
		})
	},
	mounted() {
		if (this.beforePage !== PageName.Estimate.RequestProductDetail) {
			this.initLoadParams()
		}
		this.getList()
	},
	methods: {
		...mapMutations('request/list', ['initLoadParams']),
		...mapActions('request/list', ['getList', 'setLoadParams']),
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
		changeLoadParams(params) {
			this.setLoadParams(params)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-wrapper {
	height: calc(100% - 215px);
}
</style>
