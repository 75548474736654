import license from './license'
import instituteAPI from '@/services/api/institute/index'

export default {
	namespaced: true,
	modules: { license },
	state: {
		list: [],
	},
	mutations: {},
	actions: {
		getList: async ({ state, commit }, params) => {
			const response = await instituteAPI.instituteList(params)
			return response
		},
	},
}
