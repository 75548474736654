import instituteAPI from '@/services/api/institute/index'

import { sendMessage } from '@/services/api/slack/index'

export default {
	namespaced: true,
	state: {},
	mutations: {},
	actions: {
		createLicense: async ({ state, commit }, body) => {
			const newBody = body
			newBody.memo = `[CMS] ${body.memo}`
			const response = await instituteAPI.createLicense(newBody)
			return response
		},
		editLicense: async ({ state, commit }, body) => {
			const newBody = body
			newBody.memo = `[CMS] ${body.memo}`
			const response = await instituteAPI.editLicense(newBody)
			return response
		},
		sendSlack: async ({ state, commit }, body) => {
			const response = await sendMessage(body, '라이선스 요청')
			return response
		},
		getLicense: async ({ state, commit }, params) => {
			const response = await instituteAPI.getLicense(params)
			return response
		},
		chargeLicense: async ({ state, commit }, params) => {
			const newParams = params
			newParams.instituteId = 1
			const response = await instituteAPI.chargeLicense(newParams)
			return response
		},
	},
}
