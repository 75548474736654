<template>
	<div class="product-info-wrapper">
		<div class="product-info">
			<div class="name">
				<p>{{ productName }}</p>
			</div>
			<div class="info-wrapper">
				<div class="info">
					<span v-for="(productInfo, index) in productInfos" :key="`productInfos_${index}`">
						{{ `${productInfo.name}: ${productInfo.value}` }}
					</span>
				</div>
				<div class="price">
					<span>{{ `${price}원` }}</span>
				</div>
				<div class="shipping-info">
					<span v-for="(shippingInfo, index) in shippingInfos" :key="`shippingInfos${index}`">
						{{ shippingInfo }}
					</span>
				</div>
			</div>
		</div>
		<div class="product-order">
			<div>
				<p class="order">{{ `${quantity}개 주문` }}</p>
				<p class="stock">{{ `보유 재고: ${productStock}개` }}</p>
			</div>
		</div>
		<div class="product-total-price">
			<div class="total-price">{{ `${totalPrice}원` }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'OrderInfo',
	props: {
		orderItem: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		productName() {
			const { product } = this.orderItem
			return product?.name ?? '-'
		},
		price() {
			const { price } = this.orderItem
			return this.convertNumberToCurrency(price)
		},
		quantity() {
			const { quantity } = this.orderItem
			return quantity ?? '-'
		},
		productStock() {
			const { product } = this.orderItem
			return product?.stock ?? '-'
		},
		totalPrice() {
			const { totalPrice } = this.orderItem
			return this.convertNumberToCurrency(totalPrice)
		},
		productInfos() {
			const { product } = this.orderItem
			const { subname, brand, code, casno, options } = product
			return [
				{ name: '서브네임', value: subname ?? '-' },
				{ name: '브랜드', value: brand?.name ?? '-' },
				{ name: '제품번호', value: code ?? '-' },
				{ name: 'CAS No', value: casno ?? '-' },
				...options.map(option => {
					return { name: option?.name ?? '-', value: option?.value ?? '-' }
				}),
			]
		},
		shippingInfos() {
			const shippingInfos = []
			const { product } = this.orderItem
			const { needReleaseDatetime, shippingDirectContact, shippingCanQuick } = product
			const needReleaseDatetimeToString = needReleaseDatetime
				? this.$DateTime.fromISO(needReleaseDatetime).toFormat('yyyy년 MM월 dd일 까지 출고필요')
				: '예상 출고일 제공안함'
			shippingInfos.push(needReleaseDatetimeToString)
			if (shippingDirectContact) shippingInfos.push('개별연락 필요 상품')
			if (shippingCanQuick) shippingInfos.push('퀵 가능 상품')
			return shippingInfos
		},
	},
	methods: {
		convertNumberToCurrency(value) {
			if (value === 0) return value
			if (!value) return '-'

			return this.convertCurrencyToNumber(value)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		convertCurrencyToNumber(value) {
			if (!value) return '-'
			if (value == 0) return value

			return parseInt(value.toString().replace(/,/g, ''))
		},
	},
}
</script>

<style lang="scss" scoped>
.product-info-wrapper {
	height: 100%;
	display: flex;
	background-color: $LIST_BACKGROUND;
	padding: 12px 20px;
	border-radius: 5px;

	.product-info {
		width: calc(100% - 340px);
		height: 100%;
		.name {
			font-size: 1rem;
			font-weight: 700;
		}
		.info-wrapper {
			height: 100%;
			.info {
				span {
					color: #666666;
					font-size: 0.75rem;
					height: 18px;
					word-wrap: break-word;
					word-break: keep-all;
					&:not(:first-child)::before {
						content: '';
						margin: 0 5px;
						border-left: 1px solid $COLOR_DDD;
					}
				}
			}
			.price {
				span {
					color: #666666;
					font-size: 0.75rem;
				}
			}
			.shipping-info {
				span {
					font-size: 0.75rem;
					height: 18px;
					word-wrap: break-word;
					word-break: keep-all;
					&:not(:first-child)::before {
						content: '';
						margin: 0 5px;
						border-left: 1px solid #666666;
					}
				}
			}
		}
	}

	.product-order {
		width: 170px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: 1px solid $COLOR_DDD;
		.order {
			font-size: 1rem;
			font-weight: 700;
			text-align: center;
		}
		.stock {
			font-size: 0.75rem;
		}
	}

	.product-total-price {
		width: 170px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-left: 1px solid $COLOR_DDD;
		.total-price {
			font-size: 1rem;
			font-weight: 700;
		}
	}
}
</style>
