<template>
	<div ref="wrapper" class="image-wrapper">
		<div class="image-box" style="width: 100%; height: 200px">
			<img :src="imageSrc" />
			<button class="close-btn" v-if="imageSrc" @click="deleteImage">
				<img src="@/assets/svg/icon-trash.svg" />
			</button>
		</div>

		<template>
			<input v-model="defaultForm.link" class="link" ref="link" type="text" placeholder="링크 입력" />
			<div class="link-sub">유저가 이미지를 클릭하였을 떄 이 링크 주소로 유저를 이동시킵니다.</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
			default: null,
		},
		index: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		imageSrc() {
			if (this.defaultForm.image) return URL.createObjectURL(this.defaultForm.image)
			return null
		},
	},
	data() {
		return {
			defaultForm: {
				image: null,
				link: '',
			},
		}
	},
	mounted() {
		if (this.form) this.defaultForm = this.form
	},
	methods: {
		// uploadImage() {
		// 	const image = Object.values(this.$refs['image-file'].files)[0]
		// 	// const form = this.$_.cloneDeep(this.defaultForm)
		// 	// form.image = image
		// 	this.defaultForm.image = image
		// 	this.$refs['image-file'].value = ''
		// 	this.$emit('changeImage', this.defaultForm)
		// },
		// dropFile(event) {
		// 	event.preventDefault()
		// 	if (event.dataTransfer.files) {
		// 		this.$refs['image-file'].files = event.dataTransfer.files
		// 		this.uploadImage()
		// 	}
		// },
		deleteImage() {
			// const form = this.$_.cloneDeep(this.defaultForm)
			this.defaultForm.image = null
			this.$emit('deleteImage', this.index)
		},
		getForm() {
			return this.defaultForm
		},
		setOrder(data) {
			this.defaultForm = data
		},
	},
}
</script>
<style lang="scss" scoped>
.image-wrapper {
	width: 100%;
	display: inline-block;
	padding: 0 20px;
	.image-box {
		position: relative;
		border: 1px solid #ddd;
		border-radius: 5px;
		background-color: #f5f5f5;
		max-height: 1000px;
		text-align: center;
		img {
			border-radius: 5px;
			max-width: 100%;
			max-height: 100%;
		}
		.close-btn {
			left: 8px;
			top: 8px;
			position: absolute;
			width: 30px;
			height: 30px;
			background-color: #f5f5f5;
			border-radius: 4px;
			border: 1px solid #ddd;
		}
	}
	.drag-area {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background: #f5f5f5;
		border: 1px solid #ddd;
		border-radius: 5px;
		& > * {
			color: #999;
		}
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
	}
	margin-bottom: 30px;
}
.link {
	width: 100%;
	display: block;
	margin-top: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 14px;
	height: 36px;
	color: #111;
	word-break: break-all;
	font-weight: 400;
	overflow: hidden;
	padding: 8px 12px;
}
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
</style>
