import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `/admin/event/list`,
	detail: () => `admin/event`,
	create: () => `/admin/event`,
	updateEvent: () => `/admin/event`,
	deleteEvent: () => `/admin/event`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	detail: params => axiosInstance.get(endPoint.detail(), { params }),
	create: body => axiosInstance.post(endPoint.create(), body),
	updateEvent: body => axiosInstance.patch(endPoint.updateEvent(), body),
	deleteEvent: params => axiosInstance.delete(endPoint.deleteEvent(), { params }),
}

export default api
