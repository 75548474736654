<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div style="min-width: 100px; max-width: 100px; display: flex; gap: 5px">
				<span :class="['status', status]"> {{ statusText }} </span>
			</div>
		</td>
		<td>
			<div class="flex" style="min-width: 180px; max-width: 250px">
				<span style="text-decoration: underline"> {{ item.productInfoId }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.seller.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.name }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.productCount }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.totalStock }}개 </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ productCategory }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.brand.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.code }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.casno) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> 태그(-) </span>
			</div>
		</td>
		<td>
			<div>
				<span> 예상출고일(-) </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingDirectContact ? '가능' : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingCanQuick ? '가능' : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingAddress?.address + item.shippingAddress?.addressDetail }} </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.updateDatetime ? $DateTime.fromISO(item.updateDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.stopDatetime ? $DateTime.fromISO(item.stopDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import { RequestProduct } from '@/utils/define/ItemCode'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		status() {
			if (this.item.isStop) {
				return 'REJECT'
			} else if (this.item.productCount == 0) {
				return 'REJECT'
			} else 
				return 'COMPLETE'
		},
		statusText() {
			if (this.item.isStop) {
				return '판매중지'
			} else if (this.item.productCount == 0) {
				return 'SKU 없음'
			} else 
				return '판매중'
		},
		productCategory() {
			if (this.item.category.parents.length) {
				return this.item.category.parents[0].korName + '/' + this.item.category.korName
			} else return this.item.category.korName
		},
		statusClass() {
			return RequestProduct.statusToClass(this.item.status.code)
		},
		isMakeEstimate() {
			if (this.item.status === 'complete' && this.item.status === 'fail') {
				return this.estimate ? '작성완료' : '미작성'
			} else return '-'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Product.ProductListDetail,
				params: {
					id: this.item.productInfoId,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
