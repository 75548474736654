import Vue from 'vue'
import App from '@/views/App.vue'
import router from '@/router'
import store from '@/store'
import 'babel-polyfill'
import 'es6-promise/auto'
import { BootstrapVue } from 'bootstrap-vue'
import _ from 'lodash'
import es6Promise from 'es6-promise'
import PortalVue from 'portal-vue'
import vueDebounce from 'vue-debounce'
import { DateTime } from 'luxon'
import MixinMain from '@/mixins'
import VueCookies from 'vue-cookies'
import { COOKIES_DOMAIN } from '@/services/api/common'
import ModalConfirm from '@/views/common/modals/ModalConfirm.vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/styles/scss/global/bootstrap-tooltip.scss'

import '@/styles/scss/global/_common.scss'
import '@/styles/scss/global/_style.scss'
import '@/styles/scss/global/custom-datepicker.scss'

VueCookies.config('365d')

es6Promise.polyfill()
Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.prototype.$cookies = VueCookies

Vue.use(BootstrapVue)
Vue.use(PortalVue)
Vue.use(vueDebounce)

Vue.component('ModalConfirm', ModalConfirm)

Vue.mixin(MixinMain)

Vue.prototype.$_ = _
Vue.prototype.$DateTime = DateTime

new Vue({
	render: h => h(App),
	router,
	store,
}).$mount('#app')

window.fileDownload = function (res, filename) {
	try {
		var blob = new Blob([res.data], { type: res.data.type })
		var fileName = filename
		fileName = decodeURI(fileName)

		if (window.navigator.msSaveOrOpenBlob) {
			// IE 10+
			window.navigator.msSaveOrOpenBlob(blob, fileName)
		} else {
			// not IE
			var link = document.createElement('a')
			var url = window.URL.createObjectURL(blob)
			link.href = url
			link.target = '_self'
			if (fileName) link.download = fileName
			document.body.append(link)
			link.click()
			link.remove()
			window.URL.revokeObjectURL(url)
		}
	} catch (e) {
		console.error(e)
	}
}

Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event)
			}
		}
		document.body.addEventListener('mouseup', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('mouseup', el.clickOutsideEvent)
	},
})

Array.prototype.remove = function (data, key) {
	const idx = this.findIndex(function (item) {
		if (key == null || key == undefined) {
			return item == data
		} else {
			return item[key] === data || item[key] === data[key]
		}
	}) // findIndex = find + indexOf
	if (idx > -1) this.splice(idx, 1)
}
