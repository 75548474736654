<template>
	<div class="page-status-item">
		<div class="page-number" :class="[item.status, { selected: pageNumber === currentPageNumber }]">
			<p>{{ pageNumber }}</p>
		</div>
		<h4 :class="[item.status, { selected: pageNumber === currentPageNumber }]">{{ item.title }}</h4>
		<div v-if="!isLastIndex" class="arrow" :class="[item.status, { selected: pageNumber === currentPageNumber }]"></div>
	</div>
</template>

<script>
export default {
	name: 'PageStatusItem',
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		pageNumber: {
			type: Number,
			default: 1,
		},
		currentPageNumber: {
			type: Number,
			default: 1,
		},
		isLastIndex: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.page-status-item {
	height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	.page-number {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		background: $LINE_DIVIDER;
		text-align: center;
		color: $FONT_PLACEHOLDER;
		&.done {
			background: $PRIMARY_YELLOW_200;
			&:not(.selected) {
				color: $FONT_YELLOW;
			}
		}
		&.selected {
			background: $PRIMARY_YELLOW;
			color: $PRIMARY_BLACK;
		}
		p {
			font-size: 0.75rem;
			font-weight: 700;
			line-height: 24px;
		}
	}
	h4 {
		color: $COLOR_666;
		font-size: 0.875rem;
		font-weight: 400;
		&.selected {
			color: $COLOR_111;
			font-weight: 500;
		}
		&.done {
			color: $COLOR_111;
			font-weight: 500;
		}
	}
	.arrow {
		width: 24px;
		height: 24px;
		background-image: url(~@/assets/svg/registration/step-arrow.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 13px;
		margin-left: 10px;
		margin-right: 15px;
		&.selected {
			background-image: url(~@/assets/svg/registration/step-arrow-on.svg);
		}
		&.done {
			background-image: url(~@/assets/svg/registration/step-arrow-on.svg);
		}
	}
}
</style>
