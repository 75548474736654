<template>
	<div class="line-tab" :style="customStyle">
		<div class="tab" v-for="(tab, index) in list" :key="`tab_${index}`" :class="[{ error: error }, { active: tab.value == selectTab }]">
			<input type="radio" :value="tab.value" :id="`tab_${index}`" class="tab-radio" v-model="selectTab" @change="$emit('change', selectTab)" />
			<label :for="`tab_${index}`">{{ tab.name }}</label>
		</div>
		<p class="error-msg" v-if="error" v-text="errorMessage"></p>
	</div>
</template>

<script>
export default {
	name: 'LineTab',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		value: {
			type: String,
			default: '',
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		customStyle: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selectTab: '',
		}
	},
	watch: {
		value: {
			handler(val) {
				this.selectTab = val
			},
			immediate: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.line-tab {
	width: 100%;
	height: 45px;
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 7px;
	padding: 0 30px;
	border-bottom: 1px solid $LINE_DIVIDER;
	.tab {
		display: inline-flex;
		align-items: center;
		padding-bottom: 12px;
		border-bottom: 2px solid transparent;
		margin-right: 24px;
		font-size: 1rem;
		font-weight: 700;
		color: $FONT_DISABLED;
		&.active {
			color: black;
			border-color: black;
		}
	}
}
</style>
