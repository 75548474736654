<template>
	<base-input
		:value="value"
		:placeholder="placeholder"
		:maxlength="13"
		:disabled="disabled"
		:readonly="readonly"
		:autofocus="autofocus"
		:error="!isValid || error"
		:errorMessage="errorMessage"
		:inputStyle="inputStyle"
		@input="onInput"
		@keypress="$emit('keypress', $event)"
		@keydown="$emit('keydown', $event)"
		@keyup="$emit('keyup', $event)"
		@click="$emit('click', $event)"
	/>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'

export default {
	name: 'TelInput',
	components: {
		BaseInput,
	},
	extends: BaseInput,
	mounted() {
		this.onInput(this.value)
	},
	methods: {
		onInput(value) {
			const val = this.convertTel(value)
			this.$emit('input', val)
		},
		convertTel(value) {
			return value.replace(/\D+/g, '').replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3')
		},
		validate() {
			this.isValid = !!this.value.match(/^[0-9]{3}[-]+[0-9]{3,4}[-]+[0-9]{4}$/)
			return this.isValid
		},
	},
}
</script>

<style lang="scss" scoped></style>
