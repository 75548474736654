<template>
	<div class="search-sub-wrap">
		<div class="search-box">
			<input type="text" :placeholder="'결과 내 검색'" @keyup.enter="searchMain()" :value="keywordSub" @input="inputKeywordSub" />
			<div class="right-btn-group">
				<img class="clearBtn" v-if="keywordSub.length > 0" src="@/assets/svg/app/Close-1.svg" @click="onClickClear" />
				<button class="searchBtn" @click="searchMain()">
					<img src="@/assets/svg/app/group-search.svg" alt="icon" />
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SubSearchBox',
	components: {},
	props: {},
	data() {
		return {
			keywordSub: '',
		}
	},
	methods: {
		inputKeywordSub(e) {
			this.keywordSub = e.target.value
			// console.log('keywordSub', e.target.value)
		},
		onClickClear() {
			this.keywordSub = ''
		},
		searchMain() {
			this.$emit('subSearch', this.keywordSub)
		},
	},
}
</script>

<style lang="scss" scoped>
.search-sub-wrap {
	.search-box {
		width: 100%;
		height: 30px;
		display: flex;
		align-items: center;
		border: 1px solid $INPUT_BORDER;
		border-radius: 4px;
		transition: all ease-in-out 0.4s;
		overflow: hidden;
		&:hover {
			border: 1px solid black;
		}
		input[type='text'] {
			border: 0;
			font-size: 0.875rem;
			font-weight: 400;
			padding-left: 10px;
			width: 100%;
		}
		.right-btn-group {
			margin-left: auto;
			.searchBtn {
				width: 30px;
				height: 30px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				background-color: $COMMON_BACKGROUND;
				padding: 5px;
				margin-left: 0;
				img {
					width: 24px;
					height: 24px;
				}
			}
			.clearBtn {
				width: 24px;
				height: 24px;
				margin-right: 3px;
				cursor: pointer;
			}
		}
	}
}
</style>
