<template>
	<div class="SellerDetail">
		<div class="content-header">
			<div>
				<h4>스마트잭</h4>
				<div class="subtext">
					<div class="status" :class="sellingStatus">{{ sellingStatus == 'stop' ? '판매중지' : '판매중' }}</div>
					<p>번호: 1</p>
					<img src="@/assets/svg/order/line_separator.svg" />
					<p>등록일: 2022-01-01</p>
				</div>
			</div>
			<div class="header-button">
				<button :class="sellingStatus" @click="changeStatus">{{ sellingStatus == 'stop' ? '활성화하기' : '판매중지' }}</button>
			</div>
		</div>
		<div class="content-wrapper">
			<line-tab :list="sellerTabs" :value="selectedTab" @change="changeTab" />
			<div class="content info" v-if="selectedTab == 'info'">
				<info-list title="기본 정보" :titleBtn="modifyBtn" @clickEv="updateInfo" :list="info.seller" />
			</div>
			<account-list v-else-if="selectedTab == 'account'" />
			<sell-history v-else-if="selectedTab == 'history'" />
			<selling-products v-else-if="selectedTab == 'selling'"></selling-products>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import LineTab from '@/components/tab/LineTab'
import mixin from '@/mixins'
import AccountList from '@/views/seller/sellers/components/account/AccountList.vue'
import SellHistory from '@/views/seller/sellers/components/account/SellHistory.vue'
import SellingProducts from '@/views/seller/sellers/components/account/SellingProducts.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'SellerDetail',
	components: {
		InfoList,
		LineTab,
		AccountList,
		SellHistory,
		SellingProducts,
	},
	mixins: [mixin],
	mounted() {
		this.selectTab = this.sellerTabs[0].value
	},
	data() {
		return {
			sellingStatus: 'stop',
			selectedTab: 'info',
			modifyBtn: {
				text: '수정',
			},
		}
	},
	computed: {
		...mapState('seller/detail', ['info', 'sellerTabs']),
	},
	methods: {
		changeTab(val) {
			this.selectedTab = val
		},
		updateInfo() {
			console.log('견적서 작성')
		},
		changeStatus() {},
	},
}
</script>

<style lang="scss" scoped>
.SellerDetail {
	.content-header {
		width: 100%;
		border-bottom: 1px solid $LINE_DIVIDER;
		padding: 10px 30px;
		display: flex;
		justify-content: space-between;
		text-align: start;
		h4 {
			font-weight: 700;
			font-size: 24px;
		}
		.status {
			font-weight: 700;
			font-size: 12px;
			margin: 3px 0;
			border-radius: 5px;
			padding: 1px 5px;
			text-align: center;
			margin: 5px 0;
		}
		.subtext {
			align-items: center;
			display: flex;
			gap: 5px;
			p {
				font-weight: 400;
				font-size: 12px;
				color: #666666;
			}
			img {
				height: 11px;
				width: 1px;
			}
			.stop {
				width: 60px;
				color: $PRIMARY_RED;
				background: linear-gradient(0deg, #ffeeed, #ffeeed), #ffffff;
			}
			.selling {
				width: 50px;
				color: $PRIMARY_GREEN;
				background: rgba(13, 197, 119, 0.15);
			}
		}
		.header-button {
			button {
				font-weight: 700;
				font-size: 14px;
				border-radius: 8px;
				color: #ffffff;
				width: 100px;
				height: 36px;
				justify-content: center;
			}
			.stop {
				background: $PRIMARY_GREEN;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0dc577;
				}
			}
			.selling {
				background: $PRIMARY_RED;
				&:hover {
					background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #ff5746;
				}
			}
		}
	}
	.content-wrapper {
		padding: 0 30px;
		width: 100%;
		.order-list-wrapper {
			.title-btn-wrapper {
				display: flex;
				justify-content: space-between;
				padding-top: 20px;
			}
		}
		.table-wrapper {
			margin-top: 10px;
		}
		.jw-table-wrapper {
			min-height: 300px;
		}
	}
}
</style>

<style lang="scss">
.SellerDetail {
	.line-tab {
		padding: 0 0;
		height: 50px;
		margin-bottom: 40px;
		.tab {
			&.active {
				border-color: $PRIMARY_YELLOW;
				font-weight: 700;
			}
		}
	}
	.search-wrap {
		.search-box {
			width: 300px;
		}
	}
}
</style>
