<template>
	<div class="update-product-price-list">
		<list-header @changeLoadParams="changeLoadParams" />
		<div class="table-wrapper">
			<update-product-price-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination" />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import UpdateProductPriceTable from '../components/Table.vue'
import FloatingMenu from '@/components/table/FloatingMenu'
import PageName from '@/utils/define/PageName'

export default {
	name: 'UpdateProductPriceList',
	components: {
		Pagination,
		UpdateProductPriceTable,
		ListHeader,
		FloatingMenu,
	},
	data() {
		return {
			beforePage: '',
		}
	},
	computed: {
		...mapState('updateProductPrice/list', ['list', 'count']),
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.beforePage = from.name
		})
	},
	mounted() {
		if (this.beforePage !== PageName.Product.ApprovalProductDetail) {
			this.initLoadParams()
		}
		this.getList()
	},
	methods: {
		...mapMutations('updateProductPrice/list', ['initLoadParams']),
		...mapActions('updateProductPrice/list', ['getList', 'setLoadParams']),
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
		changeLoadParams(params) {
			this.setLoadParams(params)
		},
	},
}
</script>

<style lang="scss" scoped>
.update-product-price-list {
	.table-wrapper {
		height: calc(100% - 155px);
	}
}
</style>
