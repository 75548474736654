<template>
	<div class="product-external-list-wrapper">
		<div v-if="!noData">
			<div v-for="(item, index) of list" :key="index">
				<product-external-item :item="item" @clickItem="onClickItem"></product-external-item>
			</div>
		</div>
		<!-- 데이터 없는 경우 : 검색 X-->
		<div v-else-if="!hasSearched && noData" class="no-data">
			<h6>물품 검색</h6>
			<p v-html="searchMessageByCategoryType"></p>
		</div>
		<!-- 데이터 없는 경우 : 검색 O-->
		<div v-else-if="hasSearched && noData" class="search-no-data">
			<h6>결과 없음</h6>
			<p>
				검색결과가 없습니다. <br />
				물품 정보를 직접 입력해주세요
			</p>
			<div class="btn-wrap">
				<button @click="$emit('clickInputRedirect')">직접 입력하기</button>
			</div>
		</div>
	</div>
</template>

<script>
import ProductExternalItem from '@/views/product/registration/components/productInfo/ProductExternalItem.vue'
import { MiddleCategory } from '@/utils/define/ItemCode'

export default {
	name: 'ProductExternalList',
	components: {
		ProductExternalItem,
	},
	props: {
		categoryType: {
			type: String,
			default: MiddleCategory.REAGENT,
		},
		list: {
			type: Array,
			default: () => [],
		},
		hasSearched: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		searchMessageByCategoryType() {
			switch (this.categoryType) {
				case MiddleCategory.REAGENT:
				case MiddleCategory.GAS:
				case MiddleCategory.BIO_REAGENT:
					return '물품명, CAS No, 제품번호,<br>브랜드로 검색해주세요'
				case MiddleCategory.BIO_ANTIBODY:
				case MiddleCategory.BIO_CELL:
				case MiddleCategory.CHROMATOGRAPHY:
				case MiddleCategory.ETC:
					return '물품명, 제품번호,<br>브랜드로 검색해주세요'
				case MiddleCategory.EQUIPMENT:
				case MiddleCategory.TOOL:
				case MiddleCategory.PROTECTION:
					return '물품명, 유형, 제품번호,<br>브랜드로 검색해주세요'
			}
		},
		noData() {
			return this.list.length === 0
		},
	},
	methods: {
		onClickItem(item) {
			this.$emit('clickItem', item)
		},
	},
}
</script>

<style lang="scss" scoped>
.product-external-list-wrapper {
	width: 100%;
	height: 100%;

	.no-data {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: $HOVER_BACKGROUND;
		text-align: center;
		h6 {
			font-size: 1.125rem;
			color: $COLOR_999;
			font-weight: 700;
		}
		p {
			margin-top: 10px;
			font-size: 0.875rem;
			color: $COLOR_999;
			font-weight: 400;
		}
	}
	.search-no-data {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		background-color: $HOVER_BACKGROUND;
		text-align: center;
		h6 {
			font-size: 1.125rem;
			color: $COLOR_999;
			font-weight: 700;
		}
		p {
			margin-top: 10px;
			font-size: 0.875rem;
			color: $COLOR_999;
			font-weight: 400;
		}
		.btn-wrap {
			text-align: center;
			margin-top: 1rem;
		}
		button {
			border: 1px solid $PRIMARY_BLACK;
			background-color: $COMMON_BACKGROUND;
			border-radius: 4px;
			font-size: 0.875rem;
			color: $PRIMARY_BLACK;
			font-weight: bold;
			padding: 0 10px;
			box-sizing: border-box;
			height: 30px;
			margin: 0 auto;
			text-align: center;
		}
	}
}
</style>
