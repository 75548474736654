<template>
	<tr class="table-header">
		<template v-for="(column, index) of productListTableAllColumns">
			<template v-if="visibleColumns.includes(column)">
				<th :key="index">
					<span>{{ column }}</span>
				</th>
			</template>
		</template>
	</tr>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { productListTableAllColumns } from '@/utils/define/table/productListTable'
import MixinHeader from '@/mixins/table/header'
export default {
	mixins: [MixinHeader],
	data() {
		return {
			productListTableAllColumns,
		}
	},
	computed: {
		...mapState('product/list', ['visibleColumns']),
		...mapGetters('product/list', ['order', 'orderDirection']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
