<template>
	<div class="header-wrapper">
		<div class="header-title-wrapper">
			<h2 class="header-title">판매자 목록</h2>
			<div>
				<text-switch-slider text="판매중지된 판매자만 보기" :defaultValue="false" @change="changeSlider"></text-switch-slider>
			</div>
		</div>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<!-- search box -->
				<search-box class="search-box" placeholder="판매자 이름, 담장자, 연락처로 검색" @change="changeKeyword"></search-box>
			</div>
			<div class="filter-button-wrapper-right">
				<!-- <image-button text="목록 다운로드" :image="require('@/assets/svg/common/excel-download.svg')" @click="excelBtnClick"></image-button> -->
			</div>
		</div>

		<!--modals-->
	</div>
</template>
<script>
import SearchBox from '@/views/common/components/searchBox/index.vue'
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import TextSwitchSlider from '@/views/common/components/TextSwitchSlider'
import { mapGetters } from 'vuex'
export default {
	components: {
		SearchBox,
		ImageButton,
		TextSwitchSlider,
	},
	computed: {
		...mapGetters('buyer/list', ['filteredCount']),
	},
	methods: {
		excelBtnClick() {},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		changeSlider(isBlock) {
			this.$emit('changeLoadParams', { isBlock: isBlock ? isBlock : null })
		},
	},
}
</script>
<style lang="scss" scoped>
.header-wrapper {
	position: sticky;
	background-color: white;
	top: 0;
	display: flex;
	flex-direction: column;
	z-index: 100;
	border-bottom: 1px solid #eee;
	.header-title-wrapper {
		display: flex;
		justify-content: space-between;
		div {
			margin: 7px;
		}
	}
	.header-title {
		text-align: left;
		font-weight: 700;
		font-size: 24px;
		padding: 10px 30px 5px 30px;
		height: 50px;
	}
	.filter-button-wrapper {
		display: flex;
		width: 100%;
		min-width: 1200px;
		height: 54px;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		.filter-button-wrapper-left {
			display: inherit;
			gap: 10px;
			.search-box {
				width: 300px;
			}
		}
		.filter-button-wrapper-right {
			display: inherit;
			gap: 10px;
		}
	}
}
</style>
