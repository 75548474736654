<template>
	<div class="order-detail-page-wrapper">
		<div class="header">
			<div class="title-area">
				<h2 class="title">{{ orderNo }}</h2>
				<p>주문일시: {{ orderDatetime }}</p>
			</div>
			<div class="btn-wrapper">
				<button class="btn" @click="onClickTransactionStatement('all')">거래명세서 다운로드</button>
				<button class="btn" @click="makeEstimate('all')">견적서 작성</button>
			</div>
		</div>
		<div style="padding: 30px">
			<info-list v-for="(item, index) in detailInfo" :key="index" :title="item.title" :list="item.data" />
			<textarea-edit-form
				title="숨김 메모"
				content="*해당 메모는 판매자와 주문자에게 노출되지 않는 정보입니다"
				:defaultValue="memo"
				@save="value => saveTextForm(value, 'adminMemo')"
			/>
		</div>
		<div class="order-filter-wrapper">
			<div class="sub-title" style="margin-bottom: 30px">주문 상품</div>
			<status-counter-list :list="statusList" @active="onClickStatus" />
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="주문자, 주문상세 ID,  상품명, 브랜드로 검색"
				:datePlaceholder="datePlaceholder"
				:filters="filters"
				:datePickerList="datePickerList"
				@searchTextList="searchTextList"
				@searchCheckList="searchCheckList"
			/>
		</div>
		<div class="order-list-wrapper">
			<div class="title-btn-wrapper">
				<search-title
					:title="`주문 목록(${totalCount})`"
					subTitle="상품을 선택 한후 아래 주문 상태를 클릭하여 주문상태를 변경할 수 있습니다."
				/>
				<div class="btn-wrapper">
					<button class="btn" @click="isShowModalVisibleOption = true">
						<img src="@/assets/svg/common/view-option.svg" />
						보기옵션
					</button>
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
					<button class="btn" @click="makeEstimate">
						<img src="@/assets/svg/common/icon-estimate.svg" />
						견적서 작성
					</button>
					<button class="btn" @click="onClickTransactionStatement">
						<img src="@/assets/svg/common/icon-transaction-statement.svg" />
						거래명세서
					</button>
				</div>
			</div>
			<order-status-changer :selectedList="checkedList" @change="orderDetailCreated" />
			<div class="table-wrapper">
				<jw-table
					ref="jw-table"
					:width="table.width"
					height="300"
					:options="table.options"
					@getSelectedItem="getSelectedItem"
					@sortColumn="getSortColumn"
					@change="changeDate"
				></jw-table>
				<div class="pagenation-wrapper">
					<common-pagenation @page="getCurrentPage" :total="totalCount" :defaultOffset="50"></common-pagenation>
				</div>
			</div>
		</div>
		<modal-order-modify
			:isShow="isShowOrderModifyModal"
			:orderItem="selectedItem"
			@hide="isShowOrderModifyModal = false"
			@complete="orderDetailCreated"
		></modal-order-modify>
		<modal-shipping-info
			:isShow="isShowShippingInfoModal"
			:orderItemList="selectedItemList"
			@hide="isShowShippingInfoModal = false"
			@complete="orderDetailCreated"
		></modal-shipping-info>
		<modal-visible-option
			:isShow="isShowModalVisibleOption"
			:allColumns="allColumns"
			:visibleColumns="visibleColumns"
			@close="isShowModalVisibleOption = false"
			@save="setVisibleOption"
		></modal-visible-option>

		<modal-update-price :item="changePriceRow" :isShow="isShowPriceModal" @hide="hidePriceModal" />
		<modal-caution ref="modal-caution" :subtext="modalSubtext" :isShow="isShowCautionModal" @hide="isShowCautionModal = false" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import ModalOrderModify from '@/views/order/orderProduct/modals/ModalOrderModify.vue'
import ModalVisibleOption from '@/views/common/modals/ModalVisibleOption.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import ModalUpdatePrice from '@/views/order/orderProduct/modals/ModalUpdatePrice.vue'
import ModalCaution from '@/views/order/orderProduct/modals/ModalCaution.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import JwTable from '@/views/common/components/JwTable.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import { formatDatetime, _2H2MFormat } from '@/utils/modules/DateUtils'
import ModalShippingInfo from '@/views/order/orderProduct/modals/ModalShippingInfo.vue'
import { TableField } from '@/utils/define/table/JwTable'
import { orderManagementTable, orderManagementTableAllColumns } from '@/utils/define/table/orderManagementTable'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'OrderManagementDetail',
	components: {
		InfoList,
		StatusCounterList,
		DetailSearchBox,
		CommonPagenation,
		SearchTitle,
		JwTable,
		OrderStatusChanger,
		ModalShippingInfo,
		ModalVisibleOption,
		ModalUpdatePrice,
		ModalCaution,
		ModalOrderModify,
		TextareaEditForm,
	},
	data() {
		return {
			isShowModalVisibleOption: false,
			isShowOrderModifyModal: false,
			isShowShippingInfoModal: false,
			isShowPriceModal: false,
			isShowCautionModal: false,
			selectedItem: {},
			selectedItemList: [],
			isCancel: false,
			statusDetailData: {},
			selectedStatus: 'ALL',
			checkedList: [],
			table: this.$_.cloneDeep(orderManagementTable),
			sourceTable: orderManagementTable,
			allColumns: orderManagementTableAllColumns,
			cookieVisibleColumnsKey: 'OrderManagementDetailTableAllColumns',
			visibleColumns: [],
			listParams: {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				brandIds: [],
				shippingMethods: [],
				orderStartDatetime: undefined,
				orderEndDatetime: undefined,
				orderId: 0,
			},
			totalCount: 0,
			itemId: '',
			statusList: [],
			filters: {
				배송방식: [],
				브랜드: [],
			},
			datePickerList: [
				{
					text: '주문일',
				},
			],
			detailInfo: [],
			memo: '',
			orderDatetime: null,
			orderNo: '',
			changePriceRow: {},
			modalSubtext: '',
		}
	},
	computed: {
		...mapGetters('order', ['getOrderList', 'getOrderDetail', 'getOrderListTableData', 'getOrderListSummary', 'getOrderListFilter']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		buttonText() {
			if (this.isInputMemo) return '저장'
			else if (this.memo === '' && !this.isInputMemo) return '메모하기'
			else if (this.memo !== '' && !this.isInputMemo) return '수정하기'
		},
		inputLength() {
			return this.memo?.length < 1000 ? this.memo.length : this.memo?.length.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
		},
		isError() {
			return this.memo?.length > 1000
		},
	},
	created() {
		this.orderDetailCreated()
	},
	methods: {
		...mapActions('order', [
			'lookupOrderDetail',
			'lookupOrderList',
			'lookupOrderListSummary',
			'lookupOrderListFilter',
			'changeEstimatedDate',
			'setAdminMemo',
			'downloadOrderList',
		]),
		orderDetailCreated() {
			this.listParams.orderId = this.$route.params.id
			this.setDetailInfo()
			this.loadListData()
			this.loadSummaryData()
			this.setFilters()
			this.setColumnClickEvent()
		},
		async setDetailInfo() {
			await this.lookupOrderDetail({ orderId: this.listParams.orderId })

			const response = this.getOrderDetail
			this.orderDatetime = formatDatetime(response.createDatetime, _2H2MFormat)
			this.orderNo = response.orderNo
			this.memo = response.adminMemo ?? ''
			this.detailInfo.push(
				{
					title: '주문 정보',
					data: [
						{ title: '주문번호', value: response.orderNo },
						{ title: '주문일시', value: formatDatetime(response.createDatetime, _2H2MFormat) },
						{ title: '주문금액', value: this.convertPrice(response.totalOrderItemPrice), isSingleRow: true },
						{ title: '총 할인금액', value: `- ${this.convertPrice(response.totalDiscountPrice)}`, isSingleRow: true },
						{ title: 'VAT(10%)', value: `+ ${this.convertPrice(response.totalOrderItemVat)}`, isSingleRow: true },
						{ title: '주문 금액 합계', value: this.convertPrice(response.totalOrderItemPriceWithVat), isSingleRow: true, type: 'bold' },
					],
				},
				{
					title: '주문자 정보',
					data: [
						{ title: '주문자 소속', value: response.institute.name },
						{ title: '주문 연구실 ID', value: response.institute.id },
						{ title: '주문자', value: response.orderer.name },
						{ title: '주문자 연락처', value: response.orderer.phone },
						{ title: '주문자 이메일', value: response.orderer.email, isSingleRow: true },
					],
				},
				{
					title: '수령인 정보',
					data: [
						{ title: '수령인', value: response.recipient.name },
						{ title: '수령인 연락처', value: response.recipient.phone },
						{ title: '주소', value: response.address, isSingleRow: true },
						{ title: '배송 요청사항', value: response.deliveryRequest, isSingleRow: true },
					],
				}
			)
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())

			await this.lookupOrderList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			await this.lookupOrderListSummary({ orderId: this.listParams.orderId })
			this.setSummaryData()
		},
		setData() {
			const response = this.getOrderListTableData
			this.totalCount = response.count
			this.table.options.tableData = response.tableData
			if (this.$cookies.get(this.cookieVisibleColumnsKey)) this.setVisibleOption(JSON.parse(this.$cookies.get(this.cookieVisibleColumnsKey)))
		},
		setSummaryData() {
			this.statusList = Object.keys(this.getOrderListSummary.summary).map(summary => {
				return {
					id: summary,
					title: this.getStatusTitle(summary),
					count: this.getOrderListSummary.summary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, 'error')
			}
		},
		getStatusTitle(key) {
			const result = {
				ALL: '전체',
				APPROVE: '승인 완료',
				CANCEL: '주문취소',
				SHIPPING_COMPLETE: '배송완료',
				SHIPPING_ING: '배송중',
				WAIT: '승인 대기',
			}[key]

			return result
		},
		setColumnClickEvent() {
			this.table.options.columns.forEach((data, index) => {
				if (data.key === TableField.ReleaseVolume) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItem = row
						this.isShowOrderModifyModal = true
					}
				}
				if (data.key === TableField.DeliveryMethod) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItemList = [row]
						this.isShowShippingInfoModal = true
					}
				}
				if (data.key === TableField.OrderPrice) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.changePriceRow = row
						this.isShowPriceModal = true
					}
				}
				if (data.key === TableField.OrderItemNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderItemDetailPage(data.id)
					}
				}
				if (data.key === TableField.Status) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeLabDetail(data.id)
					}
				}
			})
		},
		routeOrderItemDetailPage(orderItemId) {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: orderItemId,
				},
			})
		},
		routeLabDetail(row) {
			if (row.isBlock === 1) {
				this.$router.push({
					name: PageName.Buyer.LabDetail,
					params: {
						id: row.institute.id,
					},
				})
			}
		},
		async setFilters() {
			const params = { orderId: this.listParams.orderId }
			await this.lookupOrderListFilter(params)

			this.filters['브랜드'] = []
			this.filters['배송방식'] = []
			this.getOrderListFilter.filter.brands.forEach(item => {
				this.filters['브랜드'].push({ name: item.name, value: item.id })
			})
			this.getOrderListFilter.filter.shippingMethods.forEach(item => {
				this.filters['배송방식'].push({ name: item, value: item })
			})
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			const periodType = {
				ORDER_DATE: '기간 조회/주문일',
			}

			this.listParams.orderStartDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[0] : undefined
			this.listParams.orderEndDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[1] : undefined
			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''

			this.loadListData()
		},
		searchCheckList(filters) {
			let brandIds = []
			let shippingMethods = []

			if (filters['브랜드']?.length) {
				filters['브랜드'].forEach(brand => {
					brandIds.push(brand.value)
				})
			}

			if (filters['배송방식']?.length) {
				filters['배송방식'].forEach(el => {
					shippingMethods.push(el.value)
				})
			}

			this.listParams.brandIds = brandIds
			this.listParams.shippingMethods = shippingMethods
			this.loadListData()
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		getSortColumn() {},
		getCurrentPage(page) {
			const { offset, length } = page
			this.listParams.offset = offset
			this.listParams.length = length
			this.loadListData()
		},
		convertPrice(price) {
			return (price < 1000 ? price : price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')) + (price > 0 ? '원' : null)
		},
		async saveTextForm(value, field) {
			try {
				await this.setAdminMemo({
					body: {
						orderId: this.$route.params.id,
						[field]: value,
					},
				})

				this.$root.toast('숨김 메모 입력 완료', '숨김 메모 입력이 완료되었습니다.', ToastType.SUCCESS)
				this.loadListData()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async onClickDownloadOrderList() {
			const response = await this.downloadOrderList(this.listParams)
			window.fileDownload(response, '전체 주문관리 목록')
		},
		makeEstimate(type) {
			if (!this.checkedList.length && type !== 'all') {
				this.modalSubtext = '견적서 작성할 상품을 선택해주세요.'
				return (this.isShowCautionModal = true)
			}
			let ids = []
			if (type === 'all') {
				this.getOrderListTableData.tableData.OrderItemNo.forEach(row => {
					ids.push(row.data.id)
				})
			} else {
				this.checkedList.forEach(item => {
					ids.push(item.orderItemId)
				})
			}

			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'add',
				},
				query: {
					ids: JSON.stringify(ids),
				},
			})
		},
		onClickTransactionStatement(type) {
			if (!this.checkedList.length && type !== 'all') {
				this.modalSubtext = '거래명세서 출력할 상품을 선택해주세요.'
				return (this.isShowCautionModal = true)
			}
			let ids = []
			if (type === 'all') {
				this.getOrderListTableData.tableData.OrderItemNo.forEach(row => {
					ids.push(row.data.id)
				})
			} else {
				this.checkedList.forEach(item => {
					ids.push(item.orderItemId)
				})
			}
			this.$router.push({
				name: PageName.Order.TransactionStatement,
				query: {
					ids: ids,
				},
			})
		},
		setVisibleOption(visibleNameList) {
			this.table.options.columns = [
				...this.sourceTable.options.columns.filter(item => {
					return visibleNameList.includes(item.name)
				}),
			]
			this.visibleColumns = visibleNameList
			this.$cookies.set(this.cookieVisibleColumnsKey, JSON.stringify(visibleNameList))
			this.setColumnClickEvent()
		},
		hidePriceModal() {
			this.isShowPriceModal = false
			this.loadListData()
		},
	},
}
</script>

<style lang="scss" scoped>
.order-detail-page-wrapper {
	overflow-y: auto;
	.header {
		width: 100%;
		padding: 20px 30px;
		min-height: 54px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		.title {
			font-size: 1.5rem;
			font-weight: bold;
			color: $PRIMARY_BLACK;
			text-align: left;
			align-items: center;
			height: auto;
		}
		p {
			font-size: 12px;
			color: #666;
			display: flex;
			margin-top: 10px;
		}
		.btn-wrapper {
			display: flex;
			gap: 10px;
		}
	}
	.sub-title {
		display: flex;
		font-weight: 700;
		font-size: 20px;
		min-height: 40px;
		border-bottom: 1px solid $LINE_DIVIDER;
		span {
			font-size: 14px;
			font-weight: 500;
			color: #666;
			margin-left: 10px;
			margin-top: 5px;
		}
	}
	.order-filter-wrapper {
		padding: 0 30px 20px 30px;
		.search-box {
			margin-top: 33px;
		}
	}
	.order-list-wrapper {
		padding: 0 30px;
		.title-btn-wrapper {
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
			.btn-wrapper {
				display: flex;
				gap: 10px;
			}
		}
		.table-wrapper {
			padding-top: 10px;
			.pagenation-wrapper {
				position: relative;
				z-index: 100;
			}
		}
	}
}
</style>
