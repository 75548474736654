<template>
	<div class="input-range-wrap">
		<base-item :title="title" :subContentText="subContentText" :required="isRequired">
			<span class="input-wrap">
				<input
					type="text"
					v-model="from"
					:placeholder="placeholder"
					:disabled="isReadOnly"
					:class="{ error: !isValid }"
					:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
					@input="changeValue"
				/>
				<input
					type="text"
					v-model="to"
					:placeholder="placeholder"
					:disabled="isReadOnly"
					:class="{ error: !isValid }"
					:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
					@input="changeValue"
				/>
			</span>
			<div v-if="!isValid" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidText }}</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputRange',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		inputValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			from: '',
			to: '',
		}
	},
	mounted() {
		if (this.valueFrom) {
			this.from = this.valueFrom
		}
		if (this.valueTo) {
			this.to = this.valueTo
		}
	},
	methods: {
		changeValue() {
			const values = `${this.from}-${this.to}`
			this.$emit('changeValue', values)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-range-wrap {
	.input-wrap {
		position: relative;
		input[type='text'] {
			padding: 12px;
			border: 1px solid $FILE_BORDER;
			border-radius: 5px;
			&:not(:first-child) {
				margin-left: 10px;
			}
			&:disabled {
				background-color: $LIST_HOVER;
			}
			&.error {
				border-color: $PRIMARY_RED;
			}
		}
	}

	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
}
</style>
