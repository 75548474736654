import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Lmp.LmpEvent,
		name: PageName.Lmp.LmpEvent,
		props: true,
		component: () => import('@/views/lmp/event/pages/list.vue'),
	},
	{
		path: RoutePath.Lmp.LmpEventCreate,
		name: PageName.Lmp.LmpEventCreate,
		props: true,
		component: () => import('@/views/lmp/event/pages/create.vue'),
	},
	{
		path: RoutePath.Lmp.LmpEventDetail,
		name: PageName.Lmp.LmpEventDetail,
		props: true,
		component: () => import('@/views/lmp/event/pages/detail.vue'),
	},
	{
		path: RoutePath.Lmp.LmpEventEdit,
		name: PageName.Lmp.LmpEventEdit,
		props: true,
		component: () => import('@/views/lmp/event/pages/create.vue'),
	},
]
