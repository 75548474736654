<template>
	<portal to="lm-modal">
		<div class="MemoPopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">선택된 상품 없음</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field sub-title">
						<p>{{ subtext }}</p>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">확인</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	name: 'ModalCaution',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		subtext: {
			type: String,
			default: '',
		},
	},
	methods: {
		hide() {
			this.$emit('hide')
		},
	},
}
</script>

<style lang="scss" scoped>
.MemoPopup {
	span {
		font-weight: 400;
		font-size: 12px;
		color: #666666;
	}
	textarea {
		width: 460px;
		height: 160px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		font-weight: 400;
		font-size: 14px;
		padding: 10px;
	}
	.sub-title {
		display: flex;
		justify-content: space-between;
		p {
			font-weight: 400;
			font-size: 14px;
			color: #000;
		}
	}
	.error {
		border: 1px solid #ff5746;
		&:focus {
			border: 1px solid #ff5746 !important;
		}
	}
	.error-text {
		padding-left: 3px;
		font-weight: 400;
		font-size: 12px;
		color: #ff5746;
	}
}
</style>
