<template>
	<div class="item-msds-wrapper">
		<div class="image-wrapper">
			<img :src="imgSrc" alt="" />
		</div>
		<div class="text-wrapper">
			<div>
				<div class="title">{{ title }}</div>
				<div class="subtext">{{ subtext }}</div>
			</div>
			<button class="btn" @click="clickItem">보러가기</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ItemMsds',
	props: {
		imgSrc: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		subtext: {
			type: String,
			default: '',
		},
		msds: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
		clickItem() {
			this.$emit('showMsds', this.msds)
		},
	},
}
</script>

<style lang="scss" scoped>
.item-msds-wrapper {
	max-width: 400px;
	height: 70px;
	padding: 10px;
	display: inline-flex;
	align-items: center;
	background-color: $SUB_BACKGROUND;
	border: 1px solid $LINE_DIVIDER;
	border-radius: 8px;
	.image-wrapper {
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 36px;
			height: 36px;
		}
	}
	.text-wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		margin-left: 10px;
		.title {
			font-size: 0.875rem;
			font-weight: 700;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.subtext {
			color: rgba(0, 0, 0, 0.6);
			font-size: 0.75rem;
			font-weight: 400;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		button {
			font-size: 0.875rem;
			font-weight: 700;
			background: $LINE_DIVIDER;
			border-radius: 15px;
			margin-left: auto;
		}
	}
}
</style>
