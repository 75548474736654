import Vue from 'vue'
import Vuex from 'vuex'
import breadcrumb from '@/store/modules/BreadCrumb'
import user from '@/store/modules/user'
import toast from '@/store/modules/Toast'
import order from '@/store/modules/order'
import common from '@/store/modules/common'
import cancelRefund from './modules/cancelRefund'
import inquiry from './modules/inquiry'
import lms from './modules/lms'
import estimate from './modules/estimate'
import request from './modules/request'
import businessregistration from './modules/businessregistration'
import approval from './modules/approval'
import updateProductPrice from './modules/updateProductPrice'
import buyer from './modules/buyer'
import seller from './modules/seller'
import product from './modules/product'
import settlement from './modules/settlement'
import lab from './modules/lab'
import productInfo from './modules/product-info'
import orderItem from './modules/order-item'
import catalog from './modules/catalog'
import productRegist from './modules/product-regist'
import brand from './modules/brand'
import payment from './modules/payment'
import pagination from './modules/pagination'
import menu from './modules/menu'
import institute from './modules/institute'
import chemicalApi from './modules/chemicalApi'
import createPersistedState from 'vuex-persistedstate'
import lmp from './modules/lmp'

const debug = process.env.NODE_ENV !== 'production'

Vue.use(Vuex)

export default new Vuex.Store({
	namespaced: true,
	modules: {
		chemicalApi,
		breadcrumb,
		user,
		toast,
		order,
		product,
		common,
		cancelRefund,
		inquiry,
		lms,
		estimate,
		request,
		businessregistration,
		approval,
		updateProductPrice,
		buyer,
		seller,
		settlement,
		lab,
		productInfo,
		orderItem,
		catalog,
		productRegist,
		brand,
		payment,
		pagination,
		menu,
		institute,
		lmp,
	},
	strict: debug,
	plugins: [
		createPersistedState({
			paths: ['menu'],
		}),
	],
})
