<template>
	<div class="wrapper">
		<!-- <div v-if="!valid" class="invalid-wrapper">
			<div>입력되지 않은 배송정보가 있습니다.</div>
		</div> -->
		<div class="table-wrapper">
			<table>
				<colgroup>
					<col v-for="(column, index) of columns" :key="`col_${index}`" :width="column.width ?? 'auto'" />
				</colgroup>
				<thead>
					<tr>
						<th v-for="(column, index) of columns" :key="`th_${index}`">
							<span>
								{{ column.value }}
								<em v-if="column.required">*</em>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, rowIndex) of tableData" :key="`tr_${rowIndex}`">
						<td>
							<div>
								{{ tableData[rowIndex][KEY.ORDER_DETAIL_ID] }}
							</div>
						</td>
						<td>
							<div class="product-name">
								{{ tableData[rowIndex][KEY.PRODUCT_NAME] }}
							</div>
							<div class="product-subname">
								{{ tableData[rowIndex][KEY.PRODUCT_SUBNAME] }}
							</div>
						</td>
						<td>
							<div>
								{{ `${tableData[rowIndex][KEY.QUANTITY]}개` }}
							</div>
						</td>
						<td>
							<div>
								{{ tableData[rowIndex][KEY.ESTIMATED_RELEASE_DATETIME] }}
							</div>
						</td>
						<td>
							<div class="select-wrapper" @click="toggleSelect(tableData[rowIndex], 'isShowSelect')">
								<div class="text-box">
									<p v-if="tableData[rowIndex][KEY.SHIPPING_METHOD]">
										{{ shippingMethodToString(tableData[rowIndex][KEY.SHIPPING_METHOD]) }}
									</p>
									<p v-else class="placeholder">배송방식 선택</p>
								</div>
								<div class="image-box">
									<img src="@/assets/svg/app/select-triangle.svg" alt="" />
								</div>
								<ul v-show="tableData[rowIndex]['isShowSelect']">
									<li
										v-for="(shippingMethod, index) in ShippingMethodSelectList"
										:key="`shippingMethod_${index}`"
										:value="shippingMethod.value"
										@click="onSelect(index, tableData[rowIndex], KEY.SHIPPING_METHOD)"
									>
										{{ shippingMethod.name }}
									</li>
								</ul>
							</div>
						</td>
						<td>
							<input
								type="text"
								placeholder="택배사 입력"
								:value="data[KEY.SHIPPING_COMPANY]"
								@keyup="onKeyupText($event, tableData[rowIndex], KEY.SHIPPING_COMPANY)"
								:disabled="tableData[rowIndex][KEY.SHIPPING_METHOD] !== ShippingMethod.PARCEL"
							/>
						</td>
						<td>
							<input
								type="text"
								placeholder="송장번호 입력"
								:value="data[KEY.INVOICE_NUMBER]"
								@keyup="onKeyupText($event, tableData[rowIndex], KEY.INVOICE_NUMBER)"
								:disabled="tableData[rowIndex][KEY.SHIPPING_METHOD] !== ShippingMethod.PARCEL"
							/>
						</td>
						<!-- <td>
							<div class="memo-wrapper">
								<div class="memo" v-if="tableData[rowIndex][KEY.MEMO]">
									{{ tableData[rowIndex][KEY.MEMO] }}
								</div>
								<div v-else class="empty-memo">작성된 메모가 없습니다.</div>
								<div class="button-wrapper">
									<button class="btn" @click="onClickMemoButton(rowIndex)">
										{{ tableData[rowIndex][KEY.MEMO] ? '수정' : '입력' }}
									</button>
								</div>
							</div>
						</td> -->
					</tr>
				</tbody>
			</table>
		</div>

		<!-- <modal-memo ref="modal-memo" :isShow="isShowMemoModal" @hide="isShowMemoModal = false" @complete="completeMemo"></modal-memo> -->
	</div>
</template>

<script>
import { ShippingMethod, shippingMethodToString, ShippingMethodSelectList } from '@/utils/define/ItemCode'
// import ModalMemo from '@/views/order/modals/ModalMemo.vue'

const KEY = {
	ORDER_DETAIL_ID: 'orderDetailId',
	PRODUCT_NAME: 'productName',
	PRODUCT_SUBNAME: 'productSubname',
	PRODUCT_INFO: 'productInfo',
	QUANTITY: 'quantity',
	ESTIMATED_RELEASE_DATETIME: 'estimatedReleaseDatetime',
	SHIPPING_METHOD: 'shippingMethod',
	SHIPPING_COMPANY: 'shippingCompany',
	INVOICE_NUMBER: 'invoiceNumber',
	// MEMO: 'memo',
}

export default {
	name: 'ShippingInfoOptionTable',
	// components: {
	// ModalMemo,
	// },
	props: {
		orderItemList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			KEY,
			tableData: [],
			selectedRowIndex: null,
			defaultItem: {},
			ShippingMethod,
			shippingMethodToString,
			ShippingMethodSelectList,
			// isShowMemoModal: false,
			valid: false,
			columns: [
				{
					key: KEY.ORDER_DETAIL_ID,
					value: '주문상세 ID',
					width: '120',
				},
				{
					key: KEY.PRODUCT_INFO,
					value: '상품정보',
					width: '200',
				},
				{
					key: KEY.QUANTITY,
					value: '출고수량',
					width: '100',
				},
				{
					key: KEY.ESTIMATED_RELEASE_DATETIME,
					value: '예상 출고일',
					width: '160',
				},
				{
					key: KEY.SHIPPING_METHOD,
					value: '배송방식',
					width: '180',
					required: true,
				},
				{
					key: KEY.SHIPPING_COMPANY,
					value: '택배사',
					width: '180',
					required: true,
				},
				{
					key: KEY.INVOICE_NUMBER,
					value: '송장번호',
					width: '180',
					required: true,
				},
				// {
				// 	key: KEY.MEMO,
				// 	value: '메모',
				// 	width: '300',
				// },
			],
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.tableData = []
			this.defaultItem = {
				orderItemId: '',
				isShowSelect: false,
			}
			this.columns.forEach(column => {
				this.defaultItem[column.key] = ''
			})

			this.orderItemList.forEach(orderItem => {
				const defaultItem = this.$_.cloneDeep(this.defaultItem)
				const { orderItemNo, product } = orderItem
				const { name, subname } = product
				Object.keys(defaultItem).map(key => {
					if (key === KEY.ORDER_DETAIL_ID) {
						defaultItem[KEY.ORDER_DETAIL_ID] = orderItemNo ?? '-'
					} else if (key === KEY.PRODUCT_INFO) {
						defaultItem[KEY.PRODUCT_NAME] = name ?? '-'
						defaultItem[KEY.PRODUCT_SUBNAME] = subname ?? '-'
					} else if (key === KEY.ESTIMATED_RELEASE_DATETIME) {
						defaultItem[key] = orderItem[key] ? this.$DateTime.fromISO(orderItem[key]).toFormat('yyyy-MM-dd') : ''
					} else {
						defaultItem[key] = orderItem[key]
					}
				})

				this.tableData.push(defaultItem)
			})
		},
		getTableData() {
			return this.tableData
		},
		onKeyupText(e, data, key) {
			data[key] = e.target.value
		},
		// onClickMemoButton(index) {
		// 	this.selectedRowIndex = index
		// 	const { orderItemId, memo } = this.tableData[index]
		// 	this.$refs['modal-memo'].initText({
		// 		id: orderItemId,
		// 		value: memo,
		// 	})

		// 	this.isShowMemoModal = true
		// },
		// completeMemo(memo) {
		// 	this.tableData[this.selectedRowIndex][KEY.MEMO] = memo
		// },
		toggleSelect(data, key) {
			data[key] = !data[key]
		},
		onSelect(index, data, key) {
			data[key] = this.ShippingMethodSelectList[index].value
			if (data[key] !== this.ShippingMethod.PARCEL) {
				data[KEY.SHIPPING_COMPANY] = ''
				data[KEY.INVOICE_NUMBER] = ''
			}
		},
		validation() {
			let valid = true
			const tableData = this.getTableData()
			tableData.forEach(({ shippingMethod, shippingCompany, invoiceNumber }) => {
				if (!shippingMethod) valid = false
				else if (shippingMethod === '택배 배송' && (!shippingCompany || !invoiceNumber)) {
					valid = false
				}
			})

			return valid
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.invalid-wrapper {
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: linear-gradient(0deg, rgba(255, 87, 70, 0.1), rgba(255, 87, 70, 0.1)), #ffffff;
		border-radius: 5px;
		margin-bottom: 10px;
		font-size: 0.875rem;
	}
	.table-wrapper {
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		overflow: auto;
		min-height: 280px;
		max-height: 400px;
		table {
			width: 100%;
			table-layout: fixed;
			thead {
				background: $COLOR_F5;
				border-bottom: 1px solid $LINE_DIVIDER;
				th {
					padding: 14px;
					font-size: 14px;
					text-align: left;
					color: $COLOR_666;
					span {
						white-space: nowrap;
						em {
							color: $PRIMARY_RED;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					td {
						position: relative;
						padding: 14px;
						font-size: 14px;
						input {
							width: 100%;
							height: 36px;
							border: 1px solid $FILE_BORDER;
							border-radius: 5px;
						}
						.product-name {
							font-weight: 700;
						}
						.product-subname {
							color: #666666;
							font-size: 0.875rem;
						}
						// .memo-wrapper {
						// 	display: flex;
						// 	justify-content: space-between;
						// 	align-items: center;
						// 	.memo,
						// 	.empty-memo {
						// 		display: flex;
						// 		justify-content: space-between;
						// 		align-items: center;
						// 	}
						// 	.memo {
						// 		display: -webkit-box;
						// 		overflow: hidden;
						// 		text-overflow: ellipsis;
						// 		word-break: break-word;
						// 		-webkit-line-clamp: 2; // 원하는 라인수
						// 		-webkit-box-orient: vertical;
						// 		padding-right: 8px;
						// 	}
						// 	.empty-memo {
						// 		color: grey;
						// 	}
						// 	.button-wrapper {
						// 		button.btn {
						// 			width: 45px;
						// 			display: flex;
						// 			justify-content: center;
						// 		}
						// 	}
						// }

						.select-wrapper {
							width: 100%;
							height: 36px;
							border: 1px solid $FILE_BORDER;
							border-radius: 5px;
							position: relative;
							display: inline-flex;
							justify-content: space-between;
							align-items: center;
							cursor: pointer;
							padding: 0 10px;

							.text-box {
								display: flex;
								align-items: center;
								.placeholder {
									font-size: 0.875rem;
									color: grey;
								}
							}
							.image-box {
								padding-left: 10px;
								display: flex;
								align-items: center;
							}
							ul {
								width: 100%;
								max-height: 200px;
								position: absolute;
								top: 35px;
								right: 0;
								background-color: $PRIMARY_WHITE;
								border: 1px solid $INPUT_BORDER;
								border-radius: 4px;
								z-index: 1;
								overflow-y: auto;
								li {
									font-size: 0.875rem;
									height: 35px;
									display: flex;
									align-items: center;
									padding: 10px 15px;
									&:hover {
										background-color: $HOVER_BACKGROUND;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
