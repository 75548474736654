<template>
	<div class="return-wrapper">
		<div>
			<h5>반품지 정보</h5>
			<base-item title="반품지 선택">
				<button class="btn address-btn" @click="$refs['modal-address-management'].show()">반품지 등록</button>
				<choice-list
					ref="choice-list"
					title="반품지"
					emptyMessage="반품지가 없습니다. 반품지를 등록해주세요"
					:list="addressList"
					:tab.sync="selectReturnTab"
					type="address"
					@changeValue="onChangeAddressValue"
					@regist="$refs['modal-address-management'].show('regist')"
					@edit="value => $refs['modal-address-management'].show('edit', value)"
				></choice-list>
			</base-item>
		</div>
		<div>
			<h5>반품/환불 정보</h5>
			<input-text-area
				ref="info"
				:title="'반품/환불 안내'"
				:inputWidthSize="810"
				:inputHeightSize="120"
				:placeholder="'반품/환불 안내 입력'"
				@changeValue="onChangeReturnInfoText"
			></input-text-area>
		</div>

		<modal-address-management ref="modal-address-management" @complete="fetchAddressList"></modal-address-management>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ChoiceList from '@/views/common/components/formItem/ChoiceList.vue'
import InputAddress from '@/views/common/components/formItem/InputAddress.vue'
import InputCheckBox from '@/views/common/components/formItem/InputCheckBox.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputTextArea from '@/views/common/components/formItem/InputTextArea.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import ModalAddressManagement from '@/views/common/modals/ModalAddressManagement.vue'

export default {
	name: 'ReturnInfo',
	components: {
		ChoiceList,
		InputAddress,
		BaseItem,
		InputCheckBox,
		InputText,
		InputTextArea,
		ModalAddressManagement,
	},
	data() {
		return {
			addressList: [],
			selectReturnTab: null,
		}
	},
	computed: {
		...mapState('productRegist/regist', ['seller', 'returnInfo']),
		...mapGetters('seller/list', ['getAddressList']),
	},
	created() {
		this.fetchAddressList()
	},
	mounted() {
		if (!this.$_.isEmpty(this.returnInfo)) this.bindReturnInfo()
	},
	methods: {
		...mapActions('seller/list', ['lookupAddressList']),
		...mapMutations('productRegist/regist', ['setReturnInfo']),
		async fetchAddressList() {
			await this.lookupAddressList({
				sellerId: this.seller.id,
			})
			const response = this.getAddressList
			this.addressList = this.$_.cloneDeep(response.list)
			if (!this.returnInfo?.returnAddressId) {
				this.addressList.some(item => {
					if (item.isDefault) {
						this.selectReturnTab = this.$_.cloneDeep(item)
						this.setInfo('returnAddressId', item.id)
						return true
					} else {
						return false
					}
				})
			} else {
				let address = this.addressList.find(item => {
					return item.id === this.returnInfo.returnAddressId
				})
				this.selectReturnTab = address
			}
		},
		bindReturnInfo() {
			this.returnInfo?.returnInfo ? this.$refs['info'].setValue(this.returnInfo.returnInfo) : null
		},
		onChangeAddressValue(value) {
			this.selectReturnTab = value
			this.setInfo('returnAddressId', value.id)
		},
		onChangeReturnInfoText(value) {
			this.setInfo('returnInfo', value)
		},
		setInfo(key, value) {
			const returnInfo = this.$_.cloneDeep(this.returnInfo)
			returnInfo[key] = value
			this.setReturnInfo(returnInfo)
		},
		valid() {
			return true
		},
	},
}
</script>

<style lang="scss" scoped>
.return-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	h5 {
		text-align: left;
		font-weight: 700;
		padding: 25px 0;
	}

	.address-btn {
		width: 100px;
		height: 36px;
		display: flex;
		justify-content: center;
	}

	.address-btn + div {
		margin-top: 15px;
	}
}
</style>
