<template>
	<b-overlay :show="isUpdating" spinner-variant="yellow" rounded="sm">
		<div class="create-wrapper">
			<div class="create-header">
				<h2>라이선스 부여 및 수정</h2>
			</div>
			<div class="create-content">
				<div>
					<h5>기관 정보</h5>
					<input-text
						ref="institute"
						v-model="inputData.instituteId"
						title="기관"
						placeholder="기관 ID 입력"
						inValidText="ID를 입력해주세요"
						isRequired
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchInstitute'].show(keyword)"
					/>
					<input-select-option
						ref="input-select"
						v-model="inputData.plan"
						title="플랜"
						placeholder="플랜 선택"
						:list="planList"
						isRequired
					/>
					<input-text
						ref="phone"
						title="휴대폰번호"
						v-model="inputData.phone"
						placeholder="휴대폰 번호 입력"
						inValidText="ID를 입력해주세요"
					/>
					<input-text
						ref="quantity"
						title="계정 수"
						:disabled="inputData.plan != 'STANDARD'"
						v-model="inputData.quantity"
						placeholder="계정 수 입력"
						type="number"
						isRequired
					/>
					<input-text ref="month" title="개월 수" v-model="inputData.month" placeholder="개월 수 입력" type="number" isRequired />
					<input-select-option
						ref="input-select"
						v-model="inputData.paymentMethod"
						title="결제 방법"
						placeholder="결제 방법 선택"
						:list="paymentList"
						isRequired
					/>
					<input-text title="라이선스 시작일" type="date" v-model="inputData.activateDate" />
					<input-text title="라이선스 만료일" type="date" v-model="inputData.expireDate" />

					<base-button style="margin-top: 20px; margin-left: 200px" size="lg" color="accept" @click="calcPrice">가격 계산</base-button>
					<input-text title="라이선스 가격" v-model="inputData.price" placeholder="라이선스 가격 입력" type="number" isRequired />
					<input-text title="vat" v-model="inputData.vat" placeholder="vat 입력" type="number" isRequired />
					<input-text title="결제 금액" v-model="inputData.totalPrice" placeholder="결제 금액 입력" type="number" isRequired />
					<input-text-area title="메모" placeholder="OOO님 OO월 OO일 신규 부여" v-model="inputData.memo" />
				</div>

				<h5>*만료된 기관만 라이선스 부여가 가능합니다. 라이선스 수정 및 취소는 슬랙 lmpro-voc-chanel을 이용해주세요.</h5>
			</div>
			<div class="create-footer">
				<base-button :disabled="createDisabled" size="lg" color="primary" @click="onAdd">등록</base-button>
				<!-- <base-button :disabled="editDisabled" size="lg" color="accept" @click="onEdit">수정</base-button> -->
				<base-button size="lg" color="accept" @click="onSendSlack">슬랙으로 Data전송</base-button>
			</div>
		</div>
		<ModalSearchInstitute ref="ModalSearchInstitute" @confirm="id => (inputData.instituteId = id)" />
	</b-overlay>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputTextArea from '@/views/common/components/formItem/InputTextArea.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import ModalSearchInstitute from './modals/ModalSearchInstitute.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import debounce from 'lodash/debounce'
export default {
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputRadio,
		InputSelectOption,
		InputTextArea,
		ModalSearchInstitute,
	},
	data() {
		return {
			isUpdating: false,

			inputData: {
				instituteId: '',
				plan: 'STANDARD',
				phone: '',
				quantity: 0,
				month: 0,
				price: 0,
				vat: 0,
				totalPrice: 0,
				activateDate: this.$DateTime.now().toISODate(),
				expireDate: null,
				paymentMethod: 'ELECTRONIC_BILL',
				memo: '',
			},
			createDisabled: false,
			editDisabled: true,
		}
	},
	watch: {
		'inputData.month'() {
			this.inputData.expireDate = this.$DateTime.fromISO(this.inputData.activateDate).plus({ month: this.inputData.month }).toISODate()
		},
		'inputData.plan'(val) {
			console.log(val)
			if (!this.inputData.licenseId) {
				if (val == 'EDUCATION' || val == 'FREE_TIER' || val == 'TRIAL') this.inputData.quantity = 99999
				else this.inputData.quantity = 1
			}
		},
		async 'inputData.instituteId'(val) {
			this.createDisabled = false
			// this.editDisabled = true
			if (val) {
				const { data } = await this.getLicense({ instituteId: val })
				const { type: plan, quantity, month, paymentMethod, id, status, activateDate, expireDate } = data
				if (plan == 'FREE_TIER' || plan == 'TRIAL') return
				this.inputData.licenseId = id
				this.inputData.plan = plan
				this.inputData.quantity = quantity
				this.inputData.month = month
				this.inputData.paymentMethod = paymentMethod
				if (status == 'ACTIVATE') {
					this.createDisabled = true
					// this.inputData.expireDate = expireDate
					// this.inputData.activateDate = activateDate
					// this.editDisabled = false
				}
			}
		},
		// inputData: {
		// 	deep: true,
		// 	handler: debounce(async function () {
		// 		const { instituteId, plan, month, quantity } = this.inputData
		// 		if (instituteId && plan && month && quantity) {
		// 			try {
		// 				const { data } = await this.chargeLicense({
		// 					instituteId,
		// 					plan,
		// 					month,
		// 					quantity,
		// 				})
		// 				const { price, vat, totalPrice } = data
		// 				this.inputData.price = price
		// 				this.inputData.vat = vat
		// 				this.inputData.totalPrice = totalPrice
		// 			} catch (e) {
		// 				// alert(e?.response?.data?.msg ?? '오류가 발생했습니다. 개발팀에게 문의해주세요')
		// 			}
		// 		}
		// 	}, 300),
		// },
	},
	mounted() {
		this.inputData = { ...this.inititalData }
		if (this.user.name) this.inputData.memo = `${this.user.name}님이 ${this.$DateTime.now().toISODate()} 부여 하였습니다.`
		this.inputData.quantity = 1
		this.inputData.month = 12
	},
	computed: {
		...mapState('user', ['user']),
		inititalData() {
			return {
				instituteId: '',
				plan: 'EDUCATION',
				phone: '',
				quantity: 99999,
				month: 12,
				price: 0,
				vat: 0,
				totalPrice: 0,
				activateDate: this.$DateTime.now().toISODate(),
				expireDate: null,
				paymentMethod: 'ELECTRONIC_BILL',
				memo: '',
			}
		},
		planList() {
			return [
				{ value: 'STANDARD', name: '스탠다드' },
				{ value: 'EDUCATION', name: '대학 무제한' },
			]
		},
		paymentList() {
			return [
				{ value: 'ELECTRONIC_BILL', name: '전자세금계산서' },
				{ value: 'CARD', name: '카드' },
				{ value: 'FREE', name: '무료' },
			]
		},
		priceList() {
			return [
				{ title: '라이선스 가격', value: this.inputData.price.toLocaleString() + '원' },
				{ title: 'VAT', value: this.inputData.vat.toLocaleString() + '원' },
				{ title: '결제 금액', value: this.inputData.totalPrice.toLocaleString() + '원', row: true },
			]
		},
	},
	methods: {
		...mapActions('institute/license', ['createLicense', 'editLicense', 'sendSlack', 'getLicense', 'chargeLicense']),
		async onAdd() {
			this.isUpdating = true
			try {
				const response = await this.createLicense(this.inputData)
				this.$root.toast('라이선스 부여 완료', '라이선스 부여를 완료하였습니다.', ToastType.SUCCESS)
				this.inputData = { ...this.inititalData }
			} catch (e) {
				this.$root.toast('라이선스 부여 에러', e?.response?.data?.msg ?? '개발팀에게 문의하세용', ToastType.ERROR)
			}
			this.isUpdating = false
		},
		// async onEdit() {
		// 	this.isUpdating = true
		// 	try {
		// 		const response = await this.editLicense(this.inputData)
		// 		this.$root.toast('라이선스 수정 완료', '라이선스 수정를 완료하였습니다.', ToastType.SUCCESS)
		// 		this.inputData = { ...this.inititalData }
		// 	} catch (e) {
		// 		this.$root.toast('라이선스 수정 에러', e?.response?.data?.msg ?? '개발팀에게 문의하세용', ToastType.ERROR)
		// 	}
		// 	this.isUpdating = false
		// },
		async onSendSlack() {
			this.isUpdating = true
			try {
				Object.values(this.$refs).forEach(ref => {
					if (ref.isRequired && !ref.value) {
						throw '필수 정보를 모두 입력해주세요'
					}
				})
				this.inputData.title = 'License 등록 요청'
				const response = await this.sendSlack(this.inputData)
				this.$root.toast('슬랙 채널', '슬랙 채널에 전송하였습니다.', ToastType.SUCCESS)
				this.inputData = { ...this.inititalData }
				if (this.user.name) this.inputData.memo = `${this.user.name}님이 ${this.$DateTime.now().toISODate()} 부여 하였습니다.`
			} catch (e) {
				console.log(e)
				this.$root.toast('슬랙 채널', e ?? '슬랙 채널에 전송에 실패하였습니다.', ToastType.ERROR)
				// alert(JSON.stringify(this.inputData))
			}
			this.isUpdating = false
		},
		async calcPrice() {
			const { instituteId, plan, month, quantity } = this.inputData
			if (instituteId && plan && month && quantity) {
				try {
					const { data } = await this.chargeLicense({
						instituteId,
						plan,
						month,
						quantity,
					})
					const { price, vat, totalPrice } = data
					this.inputData.price = price
					this.inputData.vat = vat
					this.inputData.totalPrice = totalPrice
				} catch (e) {
					// alert(e?.response?.data?.msg ?? '오류가 발생했습니다. 개발팀에게 문의해주세요')
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
</style>
