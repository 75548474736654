const VarType = {
	TEXT_SINGLE_LINE: 'text_single_line',
	TEXT_SINGLE_LINE_WITH_UNIT: 'text_single_line_with_unit',
	TEXT_MULTIPLE_LINE: 'text_multiple_line',
	SELECT_OPTION: 'select_option',
	SELECT_DATETIME: 'select_datetime',
	SELECT_MEMBER: 'select_member',
	NUMBER_WITH_UNIT: 'number_with_unit',
	NUMBER: 'number',
}

export default VarType
