import { MiddleCategory } from '@/utils/define/ItemCode'

const tableColumn = {
	Info: 'info',
	ProductCode: 'productCode',
	CasNo: 'casno',
	Brand: 'brand',
	Volume: 'volume', // 물품 사이즈(용량)
	CurrentVolume: 'currentVolume', // 패키지 수량(임으로 Value 지정)
	Spec: 'spec', //	규격
	Shape: 'shape', // 형태
	Origin: 'origin', // 소스
	Dna: 'dna', // DNA 정보
	InnerDiameter: 'innerDiameter', // 내경
	ParticleSize: 'particleSize', // Particle Size
	Length: 'length', // 길이
	PoreSize: 'poreSize', // Pore Size
}

const tableColumn2String = columnName => {
	return {
		info: '정보',
		productCode: '제품번호',
		casno: 'CAS No',
		brand: '브랜드',
		volume: '물품 사이즈(용량)',
		currentVolume: '패키지 수량',
		spec: '규격',
		shape: '형태',
		origin: '소스',
		dna: 'DNA 정보',
		innerDiameter: '내경',
		particleSize: 'Particle Size',
		length: '길이',
		poreSize: 'Pore Size',
	}[columnName]
}

// 정보, 제품번호. 브랜드, 물품 사이즈, 패키지 수량, 규격,  소스, 형태, DNA정보, 내경, Particle Size, 길이, Pore Size,

const allTableColumns = {}
allTableColumns[MiddleCategory.REAGENT] = {
	columns: [tableColumn.Info, tableColumn.CasNo, tableColumn.ProductCode, tableColumn.Brand, tableColumn.Volume],
	widths: [342, 130, 110, 250, 128],
}
allTableColumns[MiddleCategory.GAS] = {
	columns: [tableColumn.Info, tableColumn.CasNo, tableColumn.ProductCode, tableColumn.Brand, tableColumn.Volume],
	widths: [342, 130, 110, 250, 128],
}
allTableColumns[MiddleCategory.BIO_REAGENT] = {
	columns: [
		tableColumn.Info,
		tableColumn.CasNo,
		tableColumn.ProductCode,
		tableColumn.Brand,
		tableColumn.Volume,
		tableColumn.Spec,
		tableColumn.Shape,
	],
	widths: [322, 130, 110, 250, 162, 113, 113],
}
allTableColumns[MiddleCategory.BIO_ANTIBODY] = {
	columns: [
		tableColumn.Info,
		tableColumn.ProductCode,
		tableColumn.Brand,
		tableColumn.Volume,
		tableColumn.Spec,
		tableColumn.Origin,
		tableColumn.Shape,
		tableColumn.Dna,
	],
	widths: [303, 110, 250, 162, 113, 113, 113, 146],
}
allTableColumns[MiddleCategory.BIO_CELL] = {
	columns: [
		tableColumn.Info,
		tableColumn.ProductCode,
		tableColumn.Brand,
		tableColumn.Volume,
		tableColumn.Spec,
		tableColumn.Origin,
		tableColumn.Shape,
		tableColumn.Dna,
	],
	widths: [233, 110, 250, 162, 113, 113, 113, 146],
}
allTableColumns[MiddleCategory.CHROMATOGRAPHY] = {
	columns: [
		tableColumn.Info,
		tableColumn.ProductCode,
		tableColumn.Brand,
		tableColumn.InnerDiameter,
		tableColumn.ParticleSize,
		tableColumn.Length,
		tableColumn.PoreSize,
	],
	widths: [333, 100, 215, 70, 100, 60, 80],
}
allTableColumns[MiddleCategory.EQUIPMENT] = {
	columns: [tableColumn.Info, tableColumn.ProductCode, tableColumn.Brand, tableColumn.Spec],
	widths: [293, 110, 355, 202],
}
allTableColumns[MiddleCategory.TOOL] = {
	columns: [tableColumn.Info, tableColumn.ProductCode, tableColumn.Brand, tableColumn.CurrentVolume, tableColumn.Spec],
	widths: [293, 110, 250, 105, 202],
}
allTableColumns[MiddleCategory.PROTECTION] = {
	columns: [tableColumn.Info, tableColumn.ProductCode, tableColumn.Brand, tableColumn.CurrentVolume, tableColumn.Spec],
	widths: [293, 110, 250, 105, 202],
}
allTableColumns[MiddleCategory.ETC] = {
	columns: [tableColumn.Info, tableColumn.ProductCode, tableColumn.Brand, tableColumn.CurrentVolume],
	widths: [473, 110, 250, 127],
}

// 카테고리 타입 별 컬럼 가져오는 함수
const getSearchProductColumns = categoryType => {
	try {
		return allTableColumns[categoryType]
	} catch (e) {
		console.warn(e)
		return {
			columns: [],
			widths: [],
		}
	}
}

export { getSearchProductColumns, tableColumn2String }
