<template>
	<b-modal ref="modal" title="제조사 검색" centered>
		<div class="search-wrap">
			<search-box
				class="search-box"
				name="modal-search-product-search-box"
				placeholder="제조사 이름을 검색해주세요"
				@change="getList"
				v-model="params.makerName"
			/>
		</div>
		<b-overlay :show="isLoading" spinner-variant="yellow" rounded="sm">
			<div class="list-wrap" ref="scroll-container">
				<div class="institute-wrap" @click="$refs['ModalMakerAdd'].show(params.makerName)">+ 제조사 추가하기</div>
				<div
					class="institute-wrap"
					:class="{ selected: item.makerId == selected.makerId }"
					v-for="item in list"
					:key="item.makerId"
					@click="selected = item"
				>
					<div class="content">
						<div class="chemical-name">제조사: {{ item.makerName }}</div>
						<div class="chemical-id">제조사ID: {{ item.makerId }}</div>
					</div>
				</div>
			</div>
		</b-overlay>
		<template #modal-footer>
			<div class="footer-right">
				<button class="button-gray" @click="hide()">취소</button>
				<button class="button-primary" @click="onClickConfirm" :disabled="!selected">선택</button>
			</div>
		</template>
		<ModalMakerAdd ref="ModalMakerAdd" @confirm="addedMaker" />
	</b-modal>
</template>
<script>
import ModalMakerAdd from './ModalMakerAdd.vue'
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import MixinScrolling from '@/mixins/scrolling'
import SearchBox from '@/views/common/components/searchBox/index'
import { mapActions } from 'vuex'
export default {
	components: {
		ModalMakerAdd,
		SearchBox,
		InputText,
		InputSelectOption,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			selected: null,
			params: {
				makerName: '',
			},
			list: [],
			count: 0,
			isLoading: false,
		}
	},
	watch: {
		async isBottom() {
			if (this.list.length < this.count) await this.getList()
		},
	},
	methods: {
		...mapActions('chemicalApi/search', ['searchMaker']),
		show(makerName = '', makerId = null) {
			this.params = {
				makerName: '',
			}
			this.list = []
			this.params.makerName = makerName
			this.selected = {
				makerId: makerId,
				makerName: makerName,
			}
			this.$refs['modal'].show()
			this.getList()
		},
		async getList() {
			if (this.isLoading) return
			this.isLoading = true
			const response = await this.searchMaker(this.params)
			console.log(response)
			this.count = response.count
			this.list = response.list
			this.isLoading = false
		},
		hide() {
			this.$refs['modal'].hide()
		},
		onClickConfirm() {
			this.$emit('confirm', this.selected)
			this.hide()
		},
		addedMaker(maker) {
			this.params.makerName = maker.makerName
			this.selected = maker
			this.list = [maker]
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
.search-wrap {
	display: flex;
	gap: 10px;
	::v-deep {
		.item-wrapper {
			border-bottom: none;
			padding: 0px;
			min-height: 30px;
			width: unset;
			.item-content {
				width: unset;
			}
			.select-wrapper {
				height: 30px !important;
				width: 150px !important;
			}
		}
		.search-box {
			width: 100%;
		}
	}
}
.list-wrap {
	margin-top: 16px;
	border: 1px solid $FILE_BORDER;
	border-radius: 5px;
	height: 500px;
	overflow-y: auto;
	.institute-wrap {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 16px;
		gap: 16px;
		min-height: 80px;
		border-bottom: 1px solid $FILE_BORDER;
		&:hover {
			background-color: $PRIMARY_YELLOW_100;
		}
		&.selected,
		&:active {
			background-color: $PRIMARY_YELLOW_200;
		}
		.logo {
			border-radius: 5px;
			width: 56px;
			height: 56px;
			object-fit: cover;
		}
		.content {
			display: flex;
			flex-direction: column;
			width: 100%;
			.chemical-name {
				font-size: 14px;
				color: #111;
			}
			.chemical-id {
				font-size: 12px;
				color: #333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>
