<template>
	<div class="upload-wrapper">
		<base-item title="상세 이미지" class="image-upload-wrapper" required>
			<base-button size="md" @click="onClickAddImage">이미지 업로드</base-button>
			<div class="subtext">
				<b>이미지 업로드 Tip</b>
				<input
					v-show="false"
					ref="image-file-Invisible"
					multiple
					type="file"
					accept=".jpg, .jpeg, .png"
					@drop="dropFile"
					@change="uploadImage"
				/>
				<ul style="padding: 10px 0 0 20px">
					<li v-for="(tip, index) in tipList" :key="`detail_${index}`" style="list-style-type: disc">{{ tip }}</li>
				</ul>
			</div>
			<template v-if="!hasFile">
				<div class="upload-drag-wrapper">
					<div class="drag-area">
						<input ref="image-file" multiple type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
						<h2>+</h2>
						<h5>이미지 업로드</h5>
						<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
					</div>
					<div class="size-guide-table">
						<table>
							<tr>
								<th>종류</th>
								<th>사이즈</th>
							</tr>
							<tr>
								<td>[WEB/MOBILE] 상세 이미지</td>
								<td>너비:1000이하 높이:12000이하</td>
							</tr>
						</table>
					</div>
				</div>
			</template>
			<div class="upload-files-wrapper" v-else>
				<div v-for="(el, index) in detailImageList" :key="`detailImage_${index}`" class="detail-file-wrapper">
					<div class="file-info">
						<p>상세 이미지 {{ index + 1 }}</p>
						<div>
							<image-button
								:image="require('@/assets/svg/lms/icon_left.svg')"
								:disabled="index === 0"
								@click="changeOrder('up', index)"
							/>
							<image-button
								style="transform: rotate(180deg)"
								:image="require('@/assets/svg/lms/icon_left.svg')"
								:disabled="index === detailImageList.length - 1"
								@click="changeOrder('down', index)"
							/>
							<button @click="deleteSection(index)">삭제</button>
						</div>
					</div>
					<div v-if="el.imageUrl" class="image-box" style="width: 100%; height: 200px">
						<img :src="el.imageUrl" />
						<button class="close-btn" @click="deleteImage(index)">
							<img src="@/assets/svg/icon-trash.svg" />
						</button>
					</div>
					<div v-else class="image-box" style="width: 100%; height: 200px">
						<input
							ref="image-file"
							type="file"
							accept=".jpg, .jpeg, .png"
							@drop="dropSingleFile($event, index)"
							@change="uploadSingleImage($event, index)"
						/>
						<h2 style="padding-top: 60px">+</h2>
						<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
					</div>
					<template>
						<input v-model="el.link" class="link" ref="link" type="text" placeholder="링크 입력" />
						<div class="link-sub">유저가 이미지를 클릭하였을 떄 이 링크 주소로 유저를 이동시킵니다.</div>
					</template>
				</div>
			</div>
		</base-item>
		<modal-submit
			title="이미지 파일 확인 필요"
			content="이미지 사이즈에 맞지 않은 이미지가 포함되어 있거나,<br>업로드 가능한 이미지 수량보다 많습니다.<br>이미지 사이즈 당 1개의 이미지를 업로드해주세요."
			:isOkOnly="true"
		/>
	</div>
</template>

<script>
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import InputDetailImage from '@/views/common/components/formItem/InputDetailImage.vue'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ImageButton from '@/views/common/components/button/ImageButton.vue'

export default {
	components: {
		BaseItem,
		InputDetailImage,
		BaseButton,
		ModalSubmit,
		ImageButton,
	},
	props: {
		isValid: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			uploadImageList: [],
			detailImageList: [],
			deleteDetailIds: [],
		}
	},
	computed: {
		hasFile() {
			return this.detailImageList.length > 0
		},
		tipList() {
			return [
				'너비 1000px, 높이 12000px 이하의 상세 이미지를 업로드해주세요.',
				'이벤트 페이지를 등록하려면 최소 1장의 상세 이미지를 모두 업로드 해야 합니다.',
				'상세 이미지 업로드는 최대 20장까지 가능하며, 파일명 순으로 정렬됩니다. ',
				'이미지 업로드 버튼을 클릭하거나, 드래그 앤 드랍으로 여러 이미지를 한번에 업로드 할 수 있습니다.',
			]
		},
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.uploadImageList = event.dataTransfer.files
				this.uploadImage()
			}
		},
		uploadImage(event) {
			let files = this.uploadImageList.length ? this.$_.cloneDeep(this.uploadImageList) : event.target.files
			Array.from(files).forEach(file => {
				this.detailImageList.push({ file: file, id: "", imageUrl: URL.createObjectURL(file), isImageUpdated: true, link: '' })
			})

			this.uploadImageList = []
		},
		onClickAddImage() {
			if (this.detailImageList.length) this.$refs['image-file-Invisible'].click()
			else this.$refs['image-file'].click()
		},
		deleteImage(index) {
			this.detailImageList[index].imageUrl = null
			this.detailImageList[index].isImageUpdated = true
		},
		deleteSection(index) {
			if (this.detailImageList[index].id) {
				this.deleteDetailIds.push(this.detailImageList[index].id)
			}
			this.detailImageList.splice(index, 1)
		},
		changeOrder(direction, clickedIndex) {
			let arr = []
			let countDirection = direction === 'up' ? -1 : 1
			this.detailImageList.forEach((image, index) => {
				if (index === clickedIndex) arr[clickedIndex + countDirection] = this.$_.cloneDeep(this.detailImageList[clickedIndex])
				else if (index === clickedIndex + countDirection)
					arr[clickedIndex] = this.$_.cloneDeep(this.detailImageList[clickedIndex + countDirection])
				else arr[index] = this.$_.cloneDeep(this.detailImageList[index])
			})
			this.detailImageList = arr
		},
		getImages() {
			return { detailImageList: this.detailImageList, deleteDetailIds: this.deleteDetailIds }
		},
		setDetails(details) {
			this.detailImageList = this.$_.cloneDeep(details)
			this.detailImageList.forEach(image => (image.isImageUpdated = false))
		},
		dropSingleFile(event, index) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.detailImageList[index].file = event.dataTransfer.files[0]
				this.detailImageList[index].imageUrl = URL.createObjectURL(event.dataTransfer.files[0])
			}
		},
		uploadSingleImage(event, index) {
			if (event.target.files) {
				this.detailImageList[index].file = event.target.files[0]
				this.detailImageList[index].imageUrl = URL.createObjectURL(event.target.files[0])
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.image-upload-wrapper {
	.subtext {
		margin-top: 10px;
		font-size: 14px;
	}
	.upload-drag-wrapper {
		display: flex;
		gap: 10px;
		margin-top: 10px;
		.drag-area {
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width: calc(50% - 5px);
			max-width: 500px;
			height: 240px;
			background: #f5f5f5;
			border: 1px solid #ddd;
			border-radius: 5px;
			& > * {
				color: #999;
			}
			input {
				width: 100%;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				padding: 0 0;
				cursor: pointer;
				&.isFile {
					visibility: hidden;
				}
			}
		}
		.size-guide-table {
			width: calc(50% - 5px);
			max-width: 500px;
			height: 240px;
			border: 1px solid #ddd;
			border-radius: 5px;
			table {
				width: 100%;
				height: 100%;
				th,
				td {
					width: 50%;
					padding-left: 16px;
					font-size: 14px;
					&:first-child {
						border-right: 1px solid #ddd;
					}
				}
				tr {
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: none;
					}
					&:first-child {
						background-color: #f5f5f5;
						height: 47px;
					}
				}
			}
		}
	}
	.upload-files-wrapper {
		& > div {
			gap: 25px;
			align-items: baseline;
		}
		.detail-file-wrapper {
			border: 1px solid #eeeeee;
			border-radius: 5px;
			height: 360px;
			margin: 20px 0;
			padding: 0 20px;
			.file-info {
				font-weight: 700;
				font-size: 14px;
				padding: 15px;
				display: flex;
				width: 100%;
				justify-content: space-between;
				div {
					display: flex;
					button {
						background: #f2f3f4;
						border-radius: 5px;
						font-weight: 700;
						font-size: 14px;
						width: 40px;
						height: 30px;
						display: flex;
						justify-content: center;
						margin-left: 5px;
						&:last-child {
							width: 80px;
							height: 30px;
							margin-left: 10px;
						}
					}
				}
			}
			.image-box {
				position: relative;
				border: 1px solid #ddd;
				border-radius: 5px;
				background-color: #f5f5f5;
				max-height: 1000px;
				text-align: center;
				align-items: center;
				justify-content: center;
				img {
					border-radius: 5px;
					max-width: 100%;
					max-height: 100%;
				}
				.close-btn {
					left: 8px;
					top: 8px;
					position: absolute;
					width: 30px;
					height: 30px;
					background-color: #f5f5f5;
					border-radius: 4px;
					border: 1px solid #ddd;
				}
				input {
					width: 100%;
					height: 100%;
					opacity: 0;
					position: absolute;
					top: 0;
					left: 0;
					padding: 0 0;
					cursor: pointer;
					&.isFile {
						visibility: hidden;
					}
				}
			}
			.link {
				width: 100%;
				display: block;
				margin-top: 20px;
				border: 1px solid #ddd;
				border-radius: 5px;
				font-size: 14px;
				height: 36px;
				color: #111;
				word-break: break-all;
				font-weight: 400;
				overflow: hidden;
				padding: 8px 12px;
			}
			.link-sub {
				font-size: 12px;
				color: #999;
				margin-top: 10px;
			}
		}
	}
}
</style>
