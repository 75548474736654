<template>
	<tr class="table-header">
		<template v-for="(column, index) of estimateTableAllColumns">
			<template v-if="visibleColumns.includes(column)">
				<th v-if="column === '견적일'" :key="index" :class="orderClass('ESTIMATE_DATETIME')" @click.stop="changeOrder('ESTIMATE_DATETIME')">
					<span>{{ column }}</span>
				</th>
				<th v-else :key="index">
					<span>{{ column }}</span>
				</th>
			</template>
		</template>
	</tr>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import MixinHeader from '@/mixins/table/header'
import { estimateTableAllColumns } from '@/utils/define/table/estimateTable'

export default {
	mixins: [MixinHeader],
	data() {
		return {
			estimateTableAllColumns,
		}
	},
	computed: {
		...mapState('estimate/list', ['visibleColumns']),
		...mapGetters('estimate/list', ['order', 'orderDirection']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
