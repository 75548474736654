<template>
	<div class="Search-box">
		<input
			type="text"
			:placeholder="placeholder"
			:value="inputValue"
			@input="changeInput"
			@keyup.enter="search"
			ref="search-input"
			:class="[{ searchInput: isScan }, { useQr: useQr && inputValue.length > 0 }]"
			:readonly="isScan"
			@click.stop="showRecent"
		/>
		<div class="keyword-wrapper" v-if="$root.isSearchRecentOn && (useRecentKeywords || useSuggestionKeywords)">
			<!--태그검색 영역-->
			<tag-keywords v-if="useTagKeywords && tagSearchList.length > 0" :tagSearchList="tagSearchList" @clickTag="clickTag"></tag-keywords>
			<!--최근검색 영역-->
			<recent-keywords
				v-if="isShowRecentKeywords"
				:recentKeywords="newKeywords"
				:classification="classification"
				:isShow="$root.isSearchRecentOn"
				@clickItem="clickItem"
				@removeItem="removeItem"
				@removeAllItem="removeAllItem"
			></recent-keywords>
			<!--검색결과 영역-->
			<suggestion-keywords
				v-if="isShowSuggestionKeywords"
				:suggestionKeywordList="suggestionKeywordList"
				:isShow="$root.isSearchRecentOn"
				@clickItem="clickItem"
			></suggestion-keywords>
		</div>
		<button class="resetBtn" v-if="resetOption">
			<img v-if="isScan" src="@/assets/svg/common/close-image2.svg" @click="resetCode" />
			<img v-else-if="inputValue.length > 0" @click="resetSearch" src="@/assets/svg/common/close-image1.svg" />
			<img v-if="useQr && !isScan" @click="showCode" src="@/assets/svg/common/qr-scan.svg" />
		</button>
		<button class="searchBtn" type="button" @click="search" style="margin-left: auto">
			<img src="@/assets/svg/common/group-search.svg" />
		</button>
	</div>
</template>
<script>
import RecentKeywords from '@/views/common/components/keyword/RecentKeywords.vue'
import TagKeywords from '@/views/common/components/keyword/TagKeywords.vue'
import SuggestionKeywords from '@/views/common/components/keyword/SuggestionKeywords.vue'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
	components: {
		RecentKeywords,
		TagKeywords,
		SuggestionKeywords,
	},
	props: {
		name: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
		},
		useQr: {
			type: Boolean,
			default: false,
		},
		isScan: {
			type: Boolean,
			default: false,
		},
		keyword: {
			type: String,
			default: '',
		},
		useRecentKeywords: {
			type: Boolean,
			default: false,
		},
		useTagKeywords: {
			type: Boolean,
			default: true,
		},
		useSuggestionKeywords: {
			type: Boolean,
			default: false,
		},
		classification: {
			type: String,
			default: '',
		},
		loadList: {
			type: Function,
			default: () => {},
		},
		categoryId: {
			type: Array,
			default: () => [],
		},
		keywordMaxCount: {
			type: Number,
			default: 20,
		},
		suggestionKeywordList: {
			type: Array,
			default: () => [],
		},
		initInputValue: {
			type: String,
			default: '',
		},
	},
	mounted() {
		this.$root.isSearchRecentOn = false
		// if (this.listKeyword && this.listKeyword.length > 0 && this.useRecentKeywords) {
		// 	this.inputValue = this.listKeyword
		// }
		this.recentKeywordCookieKey = this.name
	},
	data() {
		return {
			inputValue: '',
			recentKeywords: [],
			newKeywords: [],
			tagSearchList: [],
			recentKeywordCookieKey: null,
		}
	},
	watch: {
		initInputValue: {
			handler() {
				this.inputValue = this.initInputValue
			},
			immediate: true,
		},
	},
	computed: {
		...mapState('common', ['listKeyword']),
		resetOption() {
			return this.isScan || this.inputValue.length > 0 || (this.useQr && !this.isScan)
		},
		isShowRecentKeywords() {
			if (this.useRecentKeywords && this.useSuggestionKeywords) {
				return this.inputValue.length > 0 ? false : true
			} else {
				return this.useRecentKeywords ? true : false
			}
		},
		isShowSuggestionKeywords() {
			if (this.useRecentKeywords && this.useSuggestionKeywords) {
				return this.inputValue.length > 0 ? true : false
			} else {
				return this.useSuggestionKeywords ? true : false
			}
		},
	},
	methods: {
		...mapMutations('common', ['setKeyword']),
		saveRecentKeyword(keyword) {
			let emptyPattern = /\s/g
			if (this.recentKeywords.indexOf(keyword) < 0 && keyword.replace(emptyPattern, '')) {
				this.recentKeywords.push(keyword)
				this.newKeywords.push(keyword)
				if (this.recentKeywords.length > this.keywordMaxCount) this.recentKeywords.shift()
				this.$cookies.set(this.recentKeywordCookieKey, JSON.stringify(this.recentKeywords))
			}
		},
		loadRecentKeywords() {
			let recentKeywords = this.$cookies.get(this.recentKeywordCookieKey)
			this.recentKeywords = recentKeywords ? [...JSON.parse(recentKeywords)] : []
			this.newKeywords = this.recentKeywords
		},
		changeInput(event) {
			if (!this.$root.isSearchRecentOn) {
				this.$root.isSearchRecentOn = true
			}
			this.inputValue = event.target.value
			this.setKeyword(this.inputValue)
			this.newKeywords = this.recentKeywords.filter(v => v.includes(this.inputValue))
			if (this.useTagKeywords) this.tagSearchList = this.searchTagList.list.filter(item => item.name.includes(this.inputValue))
			if (this.inputValue == '') {
				this.loadList(this.inputValue)
			} else {
				this.$emit('input', this.inputValue)
			}
		},
		search() {
			let emptyPattern = /\s/g
			if (!this.listKeyword.replace(emptyPattern, '')) {
				this.setKeyword('')
			}
			this.loadList(this.inputValue)
			this.saveRecentKeyword(this.inputValue)
			this.$emit('search', this.inputValue)
		},
		resetSearch() {
			this.$refs['search-input'].value = ''
			this.inputValue = this.$refs['search-input'].value
			this.setKeyword('')
			this.$emit('reset')
			this.loadList(this.inputValue)
		},
		showCode() {
			this.$emit('showCode')
		},
		resetCode() {
			this.$emit('resetCode')
		},
		async showRecent() {
			this.loadRecentKeywords()

			if (this.useTagKeywords) {
				const params = {
					instId: this.$route.params.instituteId,
					keyword: this.listKeyword,
					offset: 0,
					length: 10,
					categoriesId: this.categoryId,
				}
				await this.lookupSearchTagList(params)
				this.tagSearchList = this.searchTagList.list
			}
			this.$root.isSearchRecentOn = true
		},
		hideRecent() {
			this.$root.initStateOnMenu()
		},
		async showTag() {},
		clickItem(keyword) {
			this.$emit('clickItem', keyword)
			this.inputValue = keyword
			this.hideRecent()
		},
		removeItem(keyword) {
			this.recentKeywords = [...this.recentKeywords.filter((item, itemIndex) => item !== keyword)]
			this.newKeywords = [...this.newKeywords.filter((item, itemIndex) => item !== keyword)]
			this.$cookies.set(this.recentKeywordCookieKey, JSON.stringify(this.recentKeywords))
		},
		removeAllItem() {
			this.newKeywords = []
			this.$cookies.set(this.recentKeywordCookieKey, '')
		},
		clickTag(id) {
			this.$emit('clickTag', id)
			let searchValue = this.tagSearchList.find(x => x.id == id).name
			if (searchValue) {
				this.inputValue = '#' + searchValue
			}
			this.hideRecent()
		},
	},
}
</script>
<style lang="scss" scoped>
.Search-box {
	position: relative;
	z-index: 30;
	.search-input::-webkit-input-placeholder {
		/* background-image: url(~@/assets/svg/QR Scan.svg) */
		background-image: url(~@/assets/svg/common/qr-scan.svg);
		background-repeat: no-repeat;
		background-position: -5px center;
		padding-left: 18px;
	}
	.useQr {
		width: calc(100% - 78px);
	}
	.resetBtn {
		background-color: rgba(0, 0, 0, 0);
		border-radius: 0;
		margin-right: 5px;
		img {
			width: 24px;
			height: 24px;
		}
	}
	.searchBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			width: 24px;
			height: 24px;
			padding-left: 0;
		}
	}
	.keyword-wrapper {
		position: absolute;
		background: $PRIMARY_WHITE;
		border-radius: 4px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
		width: 300px;
		top: 30px;
		max-height: 420px;
		text-align: left;
		padding: 5px;
		overflow-y: auto;
		box-sizing: border-box;
		border: 1px solid $COLOR_CCC;
		padding: 5px;
		z-index: 1;
		.keyword-header {
			width: 100%;
			height: 30px;
			display: flex;
			align-items: flex-end;
			span {
				display: inline-block;
				font-size: 0.75rem;
				font-weight: 400;
				padding-left: 5px;
			}
			button {
				margin-left: auto;
				color: $PRIMARY_BLUE;
				font-weight: 700;
				font-size: 0.75rem;
			}
		}
		.tag-keyword-list {
			width: calc(100%);
			display: block;
			margin-top: 10px;
			li {
				display: inline-block;
				font-size: 0.75rem;
				font-weight: 400;
				padding: 3px 6px;
				margin: 0 2.5px 5px;
				max-width: 95%;
				border-radius: 5px;
				background: $COLOR_EEE;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				cursor: pointer;
			}
		}
		.recent-keyword-list {
			margin-top: 5px;
			li {
				display: flex;
				align-items: center;
				width: 100%;
				.keyword-image {
					margin-left: auto;
					cursor: pointer;
				}
				span {
					font-size: 0.875rem;
					display: inline-block;
					font-weight: 400;
					margin-left: 5px;
					width: 260px;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
