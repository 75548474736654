<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>이벤트 페이지 수정하기</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>배너정보</h5>
				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					:value="formData.title"
					@changeValue="setTitle"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<upload-banner-images ref="upload-banner-images" @changeValue="setBannerImages" />
				<upload-detail-images ref="upload-detail-images" />
			</div>
			<div>
				<h5>배너 설정</h5>
				<input-radio title="타겟 지정" @changeValue="data => (formData.target = data.id)" :list="targetStatus" :tab="formData.target" />
				<input-date-picker title="노출 시작일" :inputValue="formData.startDatetime" @changeValue="data => (formData.startDatetime = data)" />
				<input-date-picker title="노출 종료일" :inputValue="formData.endDatetime" @changeValue="data => (formData.endDatetime = data)" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="update">수정</base-button>
		</div>
		<modal-submit
			title="노출 종료일 설정 불가"
			content="노출 종료일은 노출 시작일 이전으로 설정할 수 없습니다."
			id="date-caution-modal"
			:isOkOnly="true"
		/>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import UploadBannerImages from '../components/UploadBannerImages.vue'
import UploadDetailImages from '../components/UploadDetailImage.vue'
import TargetSelect from '../../components/target/TargetSelect.vue'
import PageName from '@/utils/define/PageName'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import { mapState, mapActions } from 'vuex'

export default {
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputRadio,
		UploadBannerImages,
		UploadDetailImages,
		TargetSelect,
		ModalSubmit,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	data() {
		return {
			formData: {
				eventId: null,
				title: null,
				webMainImage: null,
				webSearchImage: null,
				mobileMainImage: null,
				mobileSearchImage: null,
				target: null,
				startDatetime: this.$DateTime.now().toJSDate(),
				endDatetime: this.$DateTime.now().plus({ day: 7 }).toJSDate(),
			},
			isValid: {
				title: true,
			},
			inValidText: {
				title: '',
			},
			isUpdated: {
				webMainImage: false,
				webSearchImage: false,
				mobileMainImage: false,
				mobileSearchImage: false,
			},
		}
	},
	computed: {
		...mapState('lms/banner', ['item']),
		targetStatus() {
			return [
				{
					id: null,
					title: '전체',
				},
				{
					id: 'UNIVERSITY',
					title: '학교',
				},
				{
					id: 'ENTERPRISE',
					title: '기업',
				},
				{
					id: 'SELLER',
					title: '재판매업체',
				},
				{
					id: 'PERSON',
					title: '개인',
				},
			]
		},
	},
	async mounted() {
		await this.getDetail({ eventId: this.id })

		const item = this.item
		this.formData.title = this.item.title
		this.formData.eventId = this.id
		this.$refs['upload-banner-images'].setImages({
			webHomeBanner: item.banner.webMainUrl,
			webListBanner: item.imageUrl,
			mobileHomeBanner: item.banner.mobileMainUrl,
			mobileListBanner: item.banner.mobileSearchUrl,
		})
		this.$refs['upload-detail-images'].setDetails(item.details)
		this.formData.target = item.target.code
		this.formData.startDatetime = new Date(item.startDatetime)
		this.formData.endDatetime = new Date(item.endDatetime)
	},
	methods: {
		...mapActions('lms/banner', ['getDetail', 'updateEvent']),
		async update() {
			if (!this.validate()) return

			const formData = new FormData()
			Object.keys(this.formData).forEach(key => {
				if (key === 'startDatetime' || key === 'endDatetime')
					formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
				else formData.append(key, this.formData[key])
			})

			const details = this.$refs['upload-detail-images'].getImages()
			Object.keys(details).forEach(key => {
				if (key === 'detailImageList') {
					let detailImages = []
					details[key].forEach((detail, index) => {
						if (detail.isImageUpdated) {
							if (!detail.imageUrl) return this.$root.toast('수정 실패', '상세 이미지는 필수입니다.', 'error')
							detailImages.push(detail.file)
						}
						formData.append(`details[${[index]}][id]`, detail.id || "")
						formData.append(`details[${[index]}][isImageUpdated]`, detail.isImageUpdated)
						formData.append(`details[${[index]}][link]`, detail.link || "x")
					})
					if (detailImages.length) detailImages.forEach(image => formData.append('detailImages', image))
				} else {
					details['deleteDetailIds'].forEach(id => formData.append('deleteDetailIds[]', id))
				}
			})

			try {
				await this.updateEvent(formData)
				this.$root.toast('수정 완료', '이벤트 페이지가 수정되었습니다.', 'success')
				this.$router.replace({
					name: PageName.Lms.BannerDetail,
					params: {
						id: this.id,
					},
				})
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, 'error')
			}
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			let isValid = true
			if (!this.formData.title) {
				this.isValid.title = false
				this.inValidText.title = '제목을 입력해주세요.'
				isValid = false
			}

			if (this.formData.startDatetime > this.formData.endDatetime) {
				this.$bvModal.show('date-caution-modal')
				isValid = false
			}

			if (!this.$refs['upload-banner-images'].validate()) {
				this.$root.toast('수정 실패', '배너 이미지는 필수입니다.', 'error')
				isValid = false
			}

			return isValid
		},
		setTitle(value) {
			this.isValid.title = true
			this.formData.title = value
		},
		setBannerImages(values) {
			values.forEach(value => {
				switch (value.type) {
					case 'webHomeBannerForm':
						this.formData.webMainImage = value.image
						this.isUpdated.webMainImage = true
						break
					case 'webListBannerForm':
						this.formData.webSearchImage = value.image
						this.isUpdated.webSearchImage = true
						break
					case 'mobileHomeBannerForm':
						this.formData.mobileMainImage = value.image
						this.isUpdated.mobileMainImage = true
						break
					case 'mobileListBannerForm':
						this.formData.mobileSearchImage = value.image
						this.isUpdated.mobileSearchImage = true
						break
				}
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
