<template>
	<tr class="table-header">
		<th>
			<span>정산번호</span>
		</th>
		<th>
			<span>정산상태</span>
		</th>
		<th>
			<span>정산금액 합계</span>
		</th>
		<th>
			<span>청구금액 합계</span>
		</th>
		<th>
			<span>이익</span>
		</th>
		<th>
			<span>이익률</span>
		</th>
		<th>
			<span>업체 정보</span>
		</th>
		<th>
			<span>청구일시</span>
		</th>
		<th>
			<span>확인일시</span>
		</th>
		<th>
			<span>송금일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	props: {
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('buyer/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	data() {
		return {
			checked: false,
		}
	},
	watch: {},
	methods: {
		...mapActions('buyer/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
