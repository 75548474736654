<template>
	<div class="switch-wrapper" :style="`--backgroundColor: ${isChecked ? backgroundColor : offBackgroundColor}`">
		<span class="switch-text">{{ text }}</span>
		<switch-slider ref="switch" :defaultValue="defaultValue" :sliderColor="sliderColor" @change="change"></switch-slider>
	</div>
</template>

<script>
import SwitchSlider from '@/views/common/components/button/SwitchSlider.vue'
export default {
	components: {
		SwitchSlider,
	},
	props: {
		// Wrapper on 배경컬러
		backgroundColor: {
			type: String,
			default: '#FFF3d6',
		},
		// Wrapper off 배경컬러
		offBackgroundColor: {
			type: String,
			default: '#F9F9F9',
		},
		// 슬라이더 백그라운드 컬러
		sliderColor: {
			type: String,
			default: '#FEC741',
		},
		// 보여줄 텍스트
		text: {
			type: String,
			default: '',
		},
		// 버튼 초기값
		defaultValue: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isChecked: this.defaultValue,
		}
	},
	methods: {
		change(value) {
			this.$emit('change', value)
			this.isChecked = value
		},
		syncWithFilter(isOn) {
			this.isChecked = isOn
			this.$refs['switch'].isChecked = isOn
		},
	},
}
</script>

<style lang="scss" scoped>
.switch-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px 15px;
	background-color: var(--backgroundColor);
	border-radius: 10px;
	max-height: 38px;
	gap: 10px;
	.switch-text {
		font-size: 14px;
		font-weight: 700;
		color: $COLOR_000000DE;
	}
}
</style>
