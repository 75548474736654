<template>
	<div>
		<div class="keyword-header">
			<span>
				최근 검색어
			</span>
			<button @click.stop="removeAllRecent($event)">
				모두 삭제
			</button>
		</div>
		<ul class="recent-keyword-list">
			<li class="recent-keyword" v-for="(keyword, index) in recentList" :key="`keyword_${index}`">
				<img class="recent" src="@/assets/svg/common/recent-keyword.svg" />
				<span class="keyword-text" @click.stop="$emit('clickRecent', keyword)">{{ keyword }}</span>
				<img class="keyword-image" src="@/assets/svg/app/Close-1.svg" @click.stop="removeRecent(keyword)" />
			</li>
		</ul>
	</div>
</template>
<script>
export default {
	name: 'InventoryRecentKeywords',
	computed: {
		recentKey() {
			return `${this.$route.name}_${this.$root.user?.id}`
		},
	},
	data() {
		return {
			recentList: [],
		}
	},
	mounted() {
		this.recentList = JSON.parse(this.$cookies.get(this.recentKey) ?? '[]')
	},
	methods: {
		addRecent(keyword) {
			if (keyword.length >= 2) {
				if (this.recentList.includes(keyword)) this.recentList.remove(keyword)
				this.recentList.unshift(keyword)
				this.recentList = this.recentList.slice(0, 20)
				this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
			}
		},
		removeRecent(keyword) {
			this.recentList.remove(keyword)
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
		removeAllRecent(event) {
			this.recentList = []
			this.$cookies.set(this.recentKey, JSON.stringify(this.recentList))
		},
	},
}
</script>

<style lang="scss" scoped></style>
