var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"table-header"},[_c('th',{staticClass:"header-checkbox"},[_c('span',[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.isAllChecked},on:{"change":_vm.allCheck}})])]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("로고 이미지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("브랜드명")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("우선순위")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("상품 수")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("등록일시")])])
}]

export { render, staticRenderFns }