import chemicalApi from '@/services/api/chemicalApi'
import { sendMessage } from '@/services/api/slack/index'

export default {
	namespaced: true,
	state: {
		list: [],
		count: 0,
		scrollTop: 0,
		loadParams: {
			keyword: null,
			status: null,
			offset: 0,
			length: 50,
			order: 'create_datetime',
			orderDirection: 'desc',
		},
		item: null,
	},
	getters: {
		selectedStatus: state => {
			if (state.loadParams.status == null) return 'ALL'
			else return state.loadParams.status[0]
		},
		keyword: state => state.loadParams.keyword,
		offset: state => state.loadParams.offset,
		length: state => state.loadParams.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setItem: (state, data) => {
			state.item = data
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await chemicalApi.getRequestList(state.loadParams)
			commit('setList', response.data)
		},
		getDetail: async ({ state, commit }, id) => {
			const response = await chemicalApi.getRequestDetail({ id })
			commit('setItem', response.data.data)
		},
		getProductDetail: async (_, id) => {
			const response = await chemicalApi.getProductDetail({ id })
			return response.data.data
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		setInitParams: async ({ dispatch }) => {
			await dispatch('setLoadParams', {
				status: null,
				offset: 0,
				length: 50,
				order: 'create_datetime',
				orderDirection: 'desc',
			})
		},
		matchingRequest: async (_, body) => {
			const response = await chemicalApi.matchingRequest(body)
			return response.data
		},
		sendSlack: async ({ state, commit }, body) => {
			const response = await sendMessage(body, '생기원 물품 등록 요청')
			return response
		},
	},
}
