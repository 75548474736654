<template>
	<tr class="table-item">
		<td class="check-box">
			<div class="checkbox">
				<input type="checkbox" :checked="isChecked" @change="check" />
			</div>
		</td>
		<td @click="goToDetail">
			<div style="min-width: 50px; width: 50px">
				<span>
					{{ item.id }}
				</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div style="min-width: 150px">
				<div class="logo-image-wrapper">
					<img v-if="item.image" class="logo-image" :src="item.image" />
					<span class="logo-title" v-else>{{ item.name }}</span>
				</div>
			</div>
		</td>
		<td @click="goToDetail">
			<div style="min-width: 150px; max-width: 750px">
				<span style="font-weight: 700">
					{{ item.name }}
				</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>
					<span :class="['status', item.isRecommend ? 'recommend' : '']"> {{ statusText }} </span>
				</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span style="font-weight: 700"> {{ commaNum(item.productInfoCount) }}개 </span>
			</div>
		</td>
		<td @click="goToDetail">
			<div style="min-width: 150px">
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'
import { mapMutations, mapState } from 'vuex'

export default {
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		...mapState('lms/brand', ['checkedList']),
		statusText() {
			return this.item.isRecommend ? '추천 브랜드' : '일반'
		},
		isChecked() {
			return this.checkedList?.some(check => {
				return check.id === this.item.id
			})
		},
	},
	methods: {
		...mapMutations('lms/brand', ['setChecked', 'removeChecked']),
		goToDetail() {
			this.$router.push({
				name: 'BrandDetail',
				params: {
					id: this.item.id,
				},
			})
		},
		check(event) {
			if (event.target.checked) this.setChecked(this.item)
			else this.removeChecked(this.item)
		},
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.logo-image-wrapper {
	width: 120px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #eeeeee;
	display: flex;
	align-items: center;
	justify-content: center;
	.logo-image {
		object-fit: contain;
		width: 100%;
		height: 100%;
		max-height: 40px;
		max-width: 120px;
	}
	.logo-title {
		font-weight: 700;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
