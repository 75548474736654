<template>
	<div class="lm-sb-wrapper" ref="search-box" v-click-outside="focusOut">
		<div class="lm-sb" :class="{ readonly: readonly }">
			<input
				ref="input"
				:disabled="disabled"
				:readonly="readonly"
				class="transparent"
				v-model="text"
				@keypress.enter="onDataChanged"
				@focus="isFocus = true"
				:placeholder="placeholder"
			/>
			<template v-if="text != null && !readonly">
				<img v-show="hasQR && text.length == 0" class="qr" src="./assets/ic-qr.svg" @click="clickQR" />
				<img v-show="text.length > 0" class="qr" src="./assets/ic-delete.svg" @click="deleteValue" />
			</template>
			<img class="btn-search" src="./assets/ic-search.svg" @click="onDataChanged" />
		</div>
		<div class="keyword-wrapper" v-show="isKeywordWrapperOpen">
			<slot></slot>
			<recent ref="recent" @clickRecent="clickRecent" />
		</div>
	</div>
</template>

<script>
import Recent from './recent'
export default {
	components: {
		Recent,
	},
	props: {
		hasQR: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: '검색',
		},
		value: {
			type: [String, Number],
			default: '',
		},
		noRecent: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			text: '',
			isFocus: false,
		}
	},
	computed: {
		isKeywordWrapperOpen() {
			return this.isFocus && this.text?.length <= 0 && !this.noRecent
		},
	},
	mounted() {
		// if (!this.readonly) {
		// 	this.focus()
		// }
		if (this.value != this.text) {
			this.text = this.value
		}
	},
	methods: {
		onDataChanged() {
			this.$emit('input', this.text)
			this.$emit('change', this.text)
			if (!this.noRecent) this.$refs['recent'].addRecent(this.text)
			this.isFocus = false
		},
		clickRecent(keyword) {
			this.text = keyword
			this.onDataChanged()
		},
		focus() {
			this.$refs.input.focus()
		},
		deleteValue() {
			this.text = ''
			this.onDataChanged()
		},
		clickQR() {
			this.$emit('clickQR')
		},
		focusOut() {
			this.isFocus = false
		},
	},
}
</script>

<style lang="scss" scoped>
.lm-sb-wrapper {
	position: relative;
	.lm-sb {
		position: relative;
		min-width: 200px;
		height: 30px;
		background-color: white;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding-right: 26px;
		border: 1px solid #e4e5ea;
		&.readonly {
			border: none;
		}
		input {
			background-color: transparent;
			width: 100%;
			height: 100%;
			padding-left: 10px;
			font-size: 14px;
			font-weight: 400;
			color: #111;
			border: none;
		}
		img {
			padding: 5px;
			cursor: pointer;
		}
		.qr {
			margin-left: auto;
		}
		.btn-search {
			cursor: pointer;
			width: 30px;
			height: 100%;
			position: absolute;
			right: 0px;
			background-color: #f2f3f4;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}
</style>
