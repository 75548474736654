import axiosInstance from '@/services/axios'

const endPoint = {
	lookupPopupList: () => `admin/popup/list`,
	lookupPopup: id => `admin/popup?id=${id}`,
	createPopup: () => `admin/popup`,
}

const api = {
	lookupPopupList: params => axiosInstance.get(endPoint.lookupPopupList(), { params }),
	lookupPopup: id => axiosInstance.get(endPoint.lookupPopup(id)),
	deletePopup: params => axiosInstance.delete(endPoint.createPopup(), { params }),
	createPopup: formData =>
		axiosInstance.post(endPoint.createPopup(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	editPopup: formData =>
		axiosInstance.patch(endPoint.createPopup(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}

export default api
