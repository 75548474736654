import axiosInstance from '@/services/axios'

const endPoint = {
	lookupPaymentList: () => `admin/payment/list`,
	lookupPaymentSummaryList: () => `admin/payment/summary`,
	lookupPaymentFilter: () => `admin/payment/list/filter`,
	updatePaid: () => `admin/payment/paid`,
	createManual: () => `admin/payment/manual`,
}

const api = {
	lookupPaymentList: params => axiosInstance.get(endPoint.lookupPaymentList(), { params }),
	lookupPaymentSummaryList: params => axiosInstance.get(endPoint.lookupPaymentSummaryList(), { params }),
	lookupPaymentFilter: params => axiosInstance.get(endPoint.lookupPaymentFilter(), { params }),
	updatePaid: payload => axiosInstance.patch(endPoint.updatePaid(), payload),
	createManual: payload => axiosInstance.post(endPoint.createManual(), payload),
}

export default api
