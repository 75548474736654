<template>
	<div class="product-info-form-item">
		<input-text
			v-if="field.formType === 'inputText'"
			:type="field.inputType"
			:title="field.name"
			:inputValue="field.value"
			:placeholder="placeholder"
			:unit="field.unit"
			:isRequired="field.required"
			:inputWidthSize="400"
			@changeValue="changeValue"
		/>
		<input-select
			v-if="field.formType === 'inputSelect'"
			ref="input-select"
			:title="field.name"
			:inputValue="field.value"
			:placeholder="placeholder"
			:unitList="field.unitList"
			:isRequired="field.required"
			:inputWidthSize="400"
			@changeValue="changeValue"
			@changeOption="changeOption"
		/>
		<input-range
			v-if="field.formType === 'inputRange'"
			ref="input-range"
			:title="field.name"
			:inputValue="field.value"
			:placeholder="placeholder"
			:unitList="field.unitList"
			:isRequired="field.required"
			:inputWidthSize="195"
			@changeValue="changeValue"
		/>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputSelect from '@/views/common/components/formItem/InputSelect.vue'
import InputRange from '@/views/common/components/formItem/InputRange.vue'

export default {
	name: 'ProductInfoFormItem',
	components: {
		InputText,
		InputSelect,
		InputRange,
	},
	props: {
		field: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		placeholder() {
			return `${this.field.name} 입력${this.field.required ? '*' : ''}`
		},
	},
	methods: {
		changeValue(value) {
			this.$emit('changeValue', value, this.field)
		},
		changeOption(value) {
			this.$emit('changeOption', value, this.field)
		},
		setProductInfo(item) {
			const keys = {
				이름: item.name,
				브랜드: item.brand?.name ? item.brand.name : item.brand,
				제품번호: item.code ?? '',
				서브네임: item.subname,
				'CAS No': item.casno,
				'보호구 유형': item.kind,
				'기계 유형': item.kind,
				'기구 유형': item.kind,
				'농도(밀도)': item.density,
				분자량: item.molecularWeight,
				MOL: item.molarMass,
				순도: item.assay,
				보관온도: item.storageTemperature,
				용해도: item.solubility,
				분자식: item.molecularFormula,
				발화점: item.flashPoint,
				끓는점: item.boilingPoint,
				녹는점: item.meltingPoint,
				규격: item.spec,
				형태: item.shape,
				소스: item.origin,
				'DNA 정보': item.dna,
				'USP/Phase': item.usp,
				'내경(ID)': item.innerDiameter,
				'Particle Size': item.particleSize,
				길이: item['length'],
				'Pore Size': item.poreSize,
				'Carbon Load(%)': item.carbonLoad,
				Endcapped: item.endcapped,
			}
			if (this.field.formType === 'inputSelect' && this.field.unitList.length > 1) {
				const unitKeys = {
					'농도(밀도)': item.densityUnit,
					'내경(ID)': item.innerDiameterUnit,
					'Particle Size': item.particleSizeUnit,
					길이: item.lengthUnit,
				}
				this.field.unitList.forEach((unit, index) => {
					if (unit.value === unitKeys[this.field.name]) this.$refs['input-select'].changeOption(index)
				})
			}
			this.field.value = typeof keys[this.field.name] === 'number' ? keys[this.field.name].toString() : keys[this.field.name]
			if (this.field.name === 'pH 범위') {
				this.$refs['input-range'].to = item.phRangeTo
				this.$refs['input-range'].from = item.phRangeFrom
			}

			this.$forceUpdate()
		},
	},
}
</script>

<style lang="scss" scoped>
.product-info-form-item {
	width: 100%;
}
</style>
