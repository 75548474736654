<template>
	<div class="input-radio-wrap">
		<base-item :title="title" :required="required" :subContentText="subText" :style="subText !== '' ? 'padding-bottom: 40px' : ''">
			<div class="input-radio-content">
				<span v-for="(item, index) in list" :key="`radio_${index}`">
					<input
						type="radio"
						class="common"
						:id="`${name}_radio_${index + 1}`"
						:name="name"
						:value="item.id"
						v-model="selectedTab"
						:disabled="item.disabled"
						@change="changeItem(item)"
					/>
					<label :for="`${name}_radio_${index + 1}`">
						{{ item.title }}
						<img v-if="item.tooltip" v-b-tooltip.hover="item.tooltip" src="@/assets/svg/common/question.svg" />
					</label>
				</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputRadio',
	components: {
		BaseItem,
	},
	props: {
		name: {
			type: String,
			default: 'radioList',
		},
		title: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => [],
		},
		tab: {
			type: [Number, String],
			default: 0,
		},
		subText: {
			type: String,
			default: '',
		},
	},
	watch: {
		tab() {
			this.selectedTab = this.tab
		},
	},
	data() {
		return {
			selectedTab: this.tab,
		}
	},
	methods: {
		changeItem(value) {
			this.selectedTab = value
			this.$emit('changeValue', value)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-radio-content {
	height: 100%;
	display: flex;
	align-items: center;
	input[type='radio'] {
		margin-right: 10px;
	}
	label {
		margin-right: 30px;
		> img {
			margin-bottom: 2px;
		}
	}
}
// .radio-wrap {
// 	display: flex;
// 	.radio-content {
// 		margin-left: 50px;
// 		input[type='radio'] {
// 			margin-right: 10px;
// 		}
// 		label {
// 			margin-right: 30px;
// 		}
// 	}
// }
</style>
