<template>
	<div class="product-info-item">
		<div class="image">
			<img v-if="!isEmptyImages" :src="product.images[0] ?? product.image" />
			<img v-else-if="!isEmptyBrandImage" :src="product.brand?.image" />
			<img v-else src="@/assets/images/no-image.png" alt="empty" />
		</div>
		<div class="content">
			<p class="seller-name" v-html="highlightKeyword(`[${sellerName}]`)"></p>
			<h6 class="name" v-html="highlightKeyword(product.name)"></h6>
			<p class="subname" v-if="product.subname" v-html="highlightKeyword(product.subname)"></p>
			<div class="data">
				<p v-for="(option, index) in setItemInfo(product)" :key="`item-option_${index}`">
					<span>{{ option.title }}:</span>
					<span v-html="highlightKeyword(isData(option.value))"></span>
				</p>
			</div>
			<b class="price">{{ productPrice }}원</b>
		</div>
		<div class="remove" v-if="isRemove">
			<img src="@/assets/svg/common/delete.svg" alt="" @click="onDelete" />
		</div>
	</div>
</template>

<script>
import mixin from '@/mixins'
import info from '@/mixins/info'

export default {
	name: 'ProductInfoItem',
	props: {
		product: {
			type: Object,
			default: () => ({}),
		},
		index: {
			type: Number,
			default: 0,
		},
		isRemove: {
			type: Boolean,
			default: false,
		},
		searchKeyword: {
			type: String,
			default: '',
		},
	},
	mixins: [mixin, info],
	computed: {
		isEmptyImages() {
			return this.$_.isEmpty(this.product.images) || !this.product.images[0]
		},
		isEmptyBrandImage() {
			return this.$_.isEmpty(this.product.brand?.image)
		},
		sellerName() {
			if (this.product.productInfos) {
				return this.product.productInfos[0]?.seller.name
			} else {
				return this.product.seller?.name
			}
		},
		productPrice() {
			let price = 0
			price = this.product.unitPrice || this.product.minUnitPrice || this.product.price

			return this.commaNum(price)
		},
	},
	methods: {
		onDelete() {
			this.$emit('delete', this.index)
		},

		highlightKeyword(value) {
			if (this.searchKeyword) {
				const name = value
				const startIndex = value.indexOf(this.searchKeyword)
				const endIndex = startIndex + this.searchKeyword.length
				if (startIndex >= 0) {
					return [
						name.slice(0, startIndex),
						`<b style="background: rgba(255, 87, 70, 0.5)">${this.searchKeyword}</b>`,
						name.slice(endIndex),
					].join('')
				}
			}
			return value
		},
	},
}
</script>

<style lang="scss" scoped>
.product-info-item {
	display: flex;
	padding: 20px 0;
	.image {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		img {
			border: 1px solid #eee;
			border-radius: 4px;
			width: 80px;
			height: 80px;
		}
	}
	.content {
		margin-left: 16px;
		font-size: 0.75rem;
		line-height: 18px;
		color: $COLOR_666;
		width: calc(100% - 100px);
		text-align: left;
		.seller-name {
			color: $COLOR_111;
			font-weight: 500;
		}
		.name {
			font-size: 1rem;
			font-weight: 700;
			color: $COLOR_111;
			margin-bottom: 3px;
		}
		.data,
		.options {
			font-size: 0.75rem;
			color: $COLOR_666;
			display: flex;
			align-items: center;
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
			flex-wrap: wrap;
			p {
				&:first-child::before {
					margin: 0;
					border-left: none;
				}
				&::before {
					vertical-align: middle;
					margin-left: 6px;
					margin-right: 6px;
					display: inline-block;
					height: 12px;
					content: '';
					border-left: 1px solid #ddd;
				}
			}
		}
		.price {
			margin-top: 10px;
			font-size: 0.875rem;
			font-weight: 400;
			color: #111;
			display: block;
		}
		.info {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_111;
			margin-top: 5px;
			text-overflow: ellipsis;
			width: 100%;
			white-space: nowrap;
			span {
				display: inline-flex;
				align-items: center;
				img {
					margin-right: 5px;
				}
			}
			span + span::before {
				vertical-align: middle;
				margin-left: 6px;
				margin-right: 6px;
				display: inline-block;
				height: 12px;
				content: '';
				border-left: 1px solid #ddd;
			}
		}
	}
	.remove {
		margin-right: 20px;
		img {
			cursor: pointer;
		}
	}
}
</style>
