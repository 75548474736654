<template>
	<b-modal ref="modal" size="md" title="제조사 등록" scrolled centered>
		<b-overlay :show="isUploading" spinner-variant="yellow" rounded="sm">
			<div class="content">
				<input-text
					ref="institute"
					v-model="inputData.name"
					title="제조사 이름"
					placeholder="제조사 이름 입력"
					inValidText="제조사 이름을 입력해주세요"
					isRequired
				/>
			</div>
		</b-overlay>
		<template #modal-footer>
			<button class="cancel" @click="hide">취소</button>
			<button class="confirm" @click="confirm">등록</button>
		</template>
	</b-modal>
</template>
<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import { mapActions } from 'vuex'
export default {
	components: {
		InputText,
	},
	data() {
		return {
			isUploading: false,
			inputData: {
				id: null,
				name: null,
			},
		}
	},
	methods: {
		...mapActions('chemicalApi/search', ['addMaker']),
		async show(name) {
			this.inputData = {
				id: null,
				name: name,
			}
			this.$refs.modal.show()
		},
		hide() {
			this.$refs.modal.hide()
		},
		async confirm() {
			this.isUploading = true
			try {
				this.inputData.id = this.inputData.name
				const result = await this.addMaker(this.inputData)
				this.$root.toast('등록 성공', '제조사 등록에 성공하였습니다.', 'success')
				this.$emit('confirm', result.data)
				this.hide()
			} catch (e) {
				this.$root.toast('등록 실패', e ?? '등록에 실패했습니다. 개발팀에 문의해주세요', 'error')
			}
			this.isUploading = false
		},
	},
}
</script>
<style lang="scss" scoped>
button {
	width: 100px;
	height: 36px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	&.cancel {
		border: 1px solid #ccc;
		&:hover {
			background-color: #eee;
		}
	}
	&.confirm {
		background-color: #fec741;
	}
	&:hover {
		opacity: 0.8;
	}
}
.content {
	border: 1px solid #eee;
	padding: 20px;
}
</style>
