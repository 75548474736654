<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div>
				<span>{{ isData(item.name) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isData(Validation.autoHypenTel(item.phone)) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isData(item.email) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isVerified }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isNotiSms }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isNotiEmail }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ isNotiPush }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ createDatetime }}</span>
			</div>
		</td>
	</tr>
</template>

<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import Validation from '@/utils/Validation'

export default {
	mixins: [mixin, MixinItem],
	props: {
		item: {
			type: Object,
		},
	},
	data() {
		return {
			Validation,
		}
	},
	computed: {
		isVerified() {
			return this.item.isVerified ? '완료' : '미완료'
		},
		isNotiSms() {
			return this.item.isNotiSms ? '동의' : '미동의'
		},
		isNotiEmail() {
			return this.item.isNotiEmail ? '동의' : '미동의'
		},
		isNotiPush() {
			return this.item.isNotiPush ? '동의' : '미동의'
		},
		createDatetime() {
			return this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd') : '-'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Buyer.BuyerDetail,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
