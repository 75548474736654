<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalChangePhone Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">연락처 변경</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="!isValidPhone ? 'warning' : ''">
						<p>연락처<span class="required">*</span></p>
						<input type="text" placeholder="연락처를 입력해주세요" v-model="phone" maxlength="13" @input="isValidName = true" />
						<span>연락처를 입력해주세요</span>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">취소</button>
						<button class="Btn__complete" @click="submit">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import Validation from '@/utils/Validation'
import { mapState } from 'vuex'

export default {
	name: 'ModalChangePhone',
	mixins: [MixinModal],
	props: {},
	data() {
		return {
			phone: null,
			userInfo: {},
			isValidPhone: true,
		}
	},
	watch: {
		phone: function () {
			this.phone = Validation.autoHypenTel(this.phone)
		},
	},
	computed: {
		// ...mapState('user', ['user']),
	},
	methods: {
		show() {
			this.isShow = true
			this.phone = this.user.phone
			this.isValidPhone = true
		},
		close() {
			this.hide()
		},
		validPhone() {
			if (this.phone.length == 0 || this.phone == null) {
				this.isValidPhone = false
			}
		},
		async submit() {
			await this.validPhone()

			if (this.isValidPhone) {
				this.$emit('submit', this.phone)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_common.scss';

.Popup__input-field {
	> span {
		display: none;
	}
	&.warning {
		> span {
			display: block;
			width: 100%;
			padding-left: 16px;
			background-image: url(~@/assets/svg/app/side-warning.svg);
			background-repeat: no-repeat;
			background-size: 12px;
			font-size: 0.75rem;
			background-position: center left 0;
			margin-top: 2px;
			color: $PRIMARY_RED;
			margin-bottom: -14px;
		}
	}
}
</style>
