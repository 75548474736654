import labAPI from '@/services/api/lab'

export default {
	namespaced: true,
	state: {
		item: {},
	},
	getters: {},
	mutations: {
		setItem: (state, payload) => {
			state.item = payload
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await labAPI.lookupLab(id)
			commit('setItem', response.data)
		},
		buyLabBlock: async (_, body) => {
			return await labAPI.buyLabBlock(body)
		},
		updateCredit: async (_, body) => {
			return await labAPI.updateCredit(body)
		},
		updatePaymentDueMonth: async (_, body) => {
			return await labAPI.updatePaymentDueMonth(body)
		},
		setBrandTargetPromotion: async (_, body) => {
			return await labAPI.setBrandTargetPromotion(body)
		},
		deleteBrandTargetPromotion: async (_, params) => {
			return await labAPI.deleteBrandTargetPromotion(params)
		},
	},
}
