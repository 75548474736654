<template>
	<tr class="table-header">
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>유형</span>
		</th>
		<th>
			<span>접수내용</span>
		</th>
		<th>
			<span>주문번호</span>
		</th>
		<th>
			<span>주문 아이템 번호</span>
		</th>
		<th>
			<span>상품 정보</span>
		</th>
		<th>
			<span>접수자</span>
		</th>
		<th>
			<span>접수자 연락처</span>
		</th>
		<th>
			<span>접수일시</span>
		</th>
		<th>
			<span>처리일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapGetters('cancelRefund/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('cancelRefund/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
