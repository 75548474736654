<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalChangeNameAndEmail Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm" v-if="authentication == false">
				<div class="Popup__top">
					<h5 class="Popup__title">이메일 아이디 변경</h5>
					<button type="button" class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field">
						<p>이메일</p>
						<input type="text" placeholder="이메일 주소를 입력해주세요" v-model="email" />
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button type="button" class="Btn__close" @click="close">취소</button>
						<button type="button" class="Btn__complete" @click="submit">완료</button>
					</div>
				</div>
			</div>
			<div class="Popup__container Popup__size-sm" v-else>
				<div class="Popup__top">
					<h5 class="Popup__title">인증번호 입력</h5>
					<button type="button" class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" style="margin-bottom: 20px">{{ email }}(으)로<br />전송된 인증번호를 입력해주세요</p>
					<div class="Popup__input-field">
						<p>인증번호</p>
						<input type="text" placeholder="인증번호를 입력해주세요" v-model="code" />
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button type="button" class="Btn__close" @click="close">취소</button>
						<button type="button" class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalChangeNameAndEmail',
	mixins: [MixinModal],
	props: {
		authentication: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			email: null,
			code: null,
			prev: null,
		}
	},
	methods: {
		show(email) {
			this.isShow = true
			this.prev = email
			this.email = this.prev
			this.code = null
		},
		close() {
			this.hide()
		},
		submit() {
			if (this.prev == this.email) {
				this.hide()
			} else {
				this.$emit('submit', this.email)
			}
		},
		complete() {
			this.$emit('complete', this.email, this.code)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_common.scss';
</style>
