export default {
	namespaced: true,
	state: {
		info: {
			seller: [
				{ title: '판매자 이름', value: '스마트잭' },
				{ title: '담당자', value: '홍길동' },
				{ title: '연락처', value: '010-1234-1234' },
				{ title: '사업자등록번호', value: '123123123' },
				{ title: '주소', value: '(우: 04779) 서울시 성동구 성수일로 19 (UHAN TOWER(유한타워)) 5층', isSingleRow: true },
				{ title: '로고 이미지', value: '', isSingleRow: true },
			],
		},
		sellingListSummary: {
			ALL: 13,
			APPROVE: 3,
			CANCEL: 1,
			SHIPPING_COMPLETE: 1,
			SHIPPING_ING: 2,
			WAIT: 6,
		},
		sellerTabs: [
			{ name: '판매자 정보', value: 'info' },
			{ name: '계정', value: 'account' },
			{ name: '판매내역', value: 'history' },
			{ name: '판매중인 상품', value: 'selling' },
		],
		asccountList: {
			list: [
				{
					account: '계정1',
					password: '******',
					createDatetime: '2022-09-22T03:15:29.000Z',
				},
				{
					account: '계정2',
					password: '******',
					createDatetime: '2022-09-22T03:15:29.000Z',
				},
			],
			count: 1,
		},
	},
	getters: {
		getSellingListSummary: state => state.sellingListSummary,
	},
	mutations: {},
	actions: {},
}
