<template>
	<div class="detail-wrapper" ref="wrapper" v-if="item && item.id == id">
		<div class="common-header-wrapper">
			<div>
				<h2>1:1 문의 관리</h2>
				<h6>
					<span :class="['status', status]"> {{ item.status }} </span>
					<p>문의일시: {{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}</p>
				</h6>
			</div>
			<div class="button-groups" v-if="item.status === '답변대기' && !showReply">
				<base-button color="primary" size="md" @click="onClickReply">답변</base-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="문의 정보" :list="contentInfo" style="margin-bottom: 20px" />
			<info-list title="작성자 정보" :list="userInfo" style="margin-bottom: 20px" />
			<info-list v-if="item.status === '답변완료'" title="답변 정보" :list="replyInfo" style="margin-bottom: 20px" />
			<div v-if="item.orderItem">
				<h5>연결된 주문 아이템</h5>
				<table class="order-table sm-table">
					<order-header />
					<order-item :item="item.orderItem" />
				</table>
			</div>
			<div v-if="item.cs">
				<h5>연결된 취소/교환/반품 내역</h5>
				<table class="order-table sm-table">
					<cancel-refund-header />
					<cancel-refund-item :item="item.cs" />
				</table>
			</div>
			<div v-if="showReply">
				<div class="reply">
					<h6>답변<span> * 해당 답변은 문의자에게 노출되는 정보입니다.</span></h6>
					<textarea placeholder="답변 내용을 입력해주세요" v-model="reply" />
				</div>
				<div class="btn-wrapper">
					<button class="cancel" @click="cancelReply">취소</button>
					<button class="complete" @click="confirmReply">답변 완료</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { mapActions, mapState } from 'vuex'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import OrderHeader from '../../components/order/header.vue'
import OrderItem from '../../components/order/item.vue'
import CancelRefundHeader from '@/views/cs/cancelRefund/components/TableHeader.vue'
import CancelRefundItem from '@/views/cs/cancelRefund/components/TableItem.vue'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
export default {
	components: {
		BaseButton,
		InfoList,
		OrderHeader,
		OrderItem,
		TextareaEditForm,
		CancelRefundHeader,
		CancelRefundItem,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	data() {
		return {
			showReply: false,
			reply: '',
		}
	},
	computed: {
		...mapState('inquiry/detail', ['item']),
		status() {
			return this.item.status === '답변완료' ? 'COMPLETE' : ''
		},
		contentInfo() {
			return [
				{ title: '문의 일시', value: this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy.MM.dd T') },
				{ title: '문의 유형', value: this.item.type.name, type: 'bold' },
				{ title: '문의 내용', value: this.item.content, isSingleRow: true },
				{ title: '첨부파일', value: this.item.files, type: 'file', isSingleRow: true },
			]
		},
		userInfo() {
			return [
				{ title: '작성자 소속', value: this.item.institute.name },
				{ title: '소속(연구실) ID', value: this.item.institute.id },
				{ title: '작성자', value: this.item.user.name },
				{ title: '작성자 연락처', value: this.item.user.phone },
				{ title: '작성자 이메일', value: this.item.user.email, isSingleRow: true },
			]
		},
		replyInfo() {
			return [
				{ title: '답변 일시', value: this.$DateTime.fromISO(this.item.comment.createDatetime).toFormat('yyyy.MM.dd T') },
				{ title: '답변자', value: this.item.comment.commenter.name },
				{ title: '답변 내용', value: this.item.comment.content, isSingleRow: true },
			]
		},
	},
	async created() {
		await this.getDetail(this.id)
	},
	methods: {
		...mapActions('inquiry/detail', ['getDetail', 'setReply']),
		onClickReply() {
			this.showReply = true
			this.$nextTick(() => {
				this.$refs['wrapper'].scrollTop = 10000
			})
		},
		cancelReply() {
			this.reply = ''
			this.showReply = false
		},
		async confirmReply() {
			try {
				await this.setReply({ id: this.id, content: this.reply })
				await this.getDetail(this.id)
				this.showReply = false
				this.$root.toast('답변 완료', '해당 문의의 답변이 완료되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('답변 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.sm-table {
	min-height: 0 !important;
}
.reply {
	h6 {
		font-weight: 700;
		font-size: 20px;
		span {
			font-weight: 500;
			font-size: 14px;
			color: #666666;
			padding-left: 10px;
		}
	}
	textarea {
		width: 100%;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		min-height: 80px;
		padding: 8px 10px;
		font-size: 0.875rem;
	}
}
.btn-wrapper {
	display: flex;
	justify-content: center;
	gap: 20px;
	button {
		width: 200px;
		height: 48px;
		font-size: 16px;
		font-weight: 700;
		justify-content: center;
	}
	.cancel {
		background: #f2f3f4;
		border-radius: 5px;
	}
	.complete {
		background: #fec741;
		border-radius: 5px;
	}
}
</style>
