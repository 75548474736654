var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"table-header"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_c('th',{class:_vm.orderClass('CREATE_DATETIME'),on:{"click":function($event){$event.stopPropagation();return _vm.changeOrder('CREATE_DATETIME')}}},[_c('span',[_vm._v("등록일시")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("로고 이미지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("판매자 이름")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("상태")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("담당자")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("연락처")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("사업자등록번호")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("주소지")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("판매상품 수")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('span',[_vm._v("누적 판매금액")])])
}]

export { render, staticRenderFns }