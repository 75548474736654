<template>
	<b-modal ref="modal" size="lg" title="물품 등록" scrolled centered>
		<b-overlay :show="isUploading" spinner-variant="yellow" rounded="sm">
			<div class="content">
				<input-select-option
					ref="input-select"
					v-model="inputData.status"
					title="상태 변경"
					placeholder="상태 변경 선택"
					:list="statusList"
					isRequired
				/>
				<input-text
					ref="institute"
					v-model="inputData.itemId"
					title="물품 ID"
					placeholder="물품 ID 입력"
					inValidText="ID를 입력해주세요"
					isRequired
					isSearch
					@onClickSearch="keyword => $refs['ModalSearchProduct'].show(keyword)"
				/>
				<input-select-option
					ref="input-select"
					v-model="inputData.comment"
					title="거절 사유"
					placeholder="거절 사유 선택"
					:list="commentList"
				/>
			</div>
		</b-overlay>
		<template #modal-footer>
			<button class="cancel" @click="hide">취소</button>
			<button class="confirm" @click="confirm">등록</button>
		</template>
		<ModalSearchProduct ref="ModalSearchProduct" @confirm="id => (inputData.itemId = id)" />
	</b-modal>
</template>
<script>
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import ModalSearchProduct from './ModalSearchProduct.vue'
import { mapActions } from 'vuex'
export default {
	components: {
		InputText,
		InputSelectOption,
		ModalSearchProduct,
	},
	data() {
		return {
			requestItem: null,
			isUploading: false,
			inputData: {
				status: null,
				itemId: null,
				comment: null,
			},
		}
	},
	computed: {
		statusList() {
			return [
				{ value: 'WAIT', name: '등록대기' },
				{ value: 'COMPLETE', name: '등록완료' },
				{ value: 'REJECT', name: '등록거절' },
			]
		},
		commentList() {
			return [
				{ value: 'A', name: 'A: [중복요청] 조회가능한 제품을 등록 요청' },
				{ value: 'B', name: 'B: [정보불일치] 신규 화학제품 용량 부정확' },
				{ value: 'C', name: 'C: [정보불일치] 신규 화학제품 정보 부정확(카탈로그번호, 제조사, 제품명)' },
				{ value: 'Z', name: 'Z: [확인필요] 그 외 사유' },
			]
		},
	},
	methods: {
		...mapActions('chemicalApi/request', ['matchingRequest']),
		async show(item) {
			this.inputData = {
				status: null,
				itemId: null,
				comment: null,
				id: item.id,
			}
			this.requestItem = item
			this.$refs.modal.show()
		},
		hide() {
			this.$refs.modal.hide()
		},
		async confirm() {
			this.isUploading = true
			try {
				await this.matchingRequest(this.inputData)
				this.$root.toast('등록 성공', '요청 물품 등록에 성공하였습니다.', 'success')
				this.$emit('confirm')
				this.hide()
			} catch (e) {
				this.$root.toast('등록 실패', e ?? '등록에 실패했습니다. 개발팀에 문의해주세요', 'error')
			}
			this.isUploading = false
		},
	},
}
</script>
<style lang="scss" scoped>
button {
	width: 100px;
	height: 36px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 500;
	&.cancel {
		border: 1px solid #ccc;
		&:hover {
			background-color: #eee;
		}
	}
	&.confirm {
		background-color: #fec741;
	}
	&:hover {
		opacity: 0.8;
	}
}
.content {
	border: 1px solid #eee;
	padding: 20px;
}
</style>
