export const addressFinderOpen = (window, lanaguageType) => {
	return new Promise(resolve => {
		window.daum.postcode.load(function () {
			/* eslint-disable */
			new daum.Postcode({
				oncomplete: function (data) {
					resolve(data);
				},
			}).open({
				popupName: 'KakaoPostCode',
				userLanguageType: lanaguageType
			});
		});
	});
};
