const estimateTableAllColumns = [
	'상태',
	'견적 만료일',
	'작성자',
	'견적번호',
	'상품정보',
	'견적금액',
	'견적서 담당자',
	'담당자 연락처',
	'담당자 이메일',
	'수신자',
	'견적 생성일',
	'견적일',
]

const estimateTableRequireColumns = ['상태', '견적 만료일', '작성자']

export { estimateTableAllColumns, estimateTableRequireColumns }
