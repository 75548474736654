<template>
	<base-input
		:value="value"
		:placeholder="placeholder"
		:maxlength="maxlength"
		:disabled="disabled"
		:readonly="readonly"
		:autofocus="autofocus"
		:error="!isValid || error"
		:errorMessage="errorMessage"
		:inputStyle="inputStyle"
		@input="$emit('input', $event)"
		@keypress="$emit('keypress', $event)"
		@keydown="$emit('keydown', $event)"
		@keyup="$emit('keyup', $event)"
		@click="$emit('click', $event)"
	/>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'

export default {
	name: 'TextInput',
	components: {
		BaseInput,
	},
	extends: BaseInput,
}
</script>

<style lang="scss" scoped></style>
