<template>
	<div class="header-wrapper">
		<h2 class="header-title">상품 목록</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box
					class="search-box"
					name="request"
					placeholder="브랜드, 상품번호, 판매자로 검색"
					@search="changeKeyword"
					:useTagKeywords="false"
					:useSuggestionKeywords="false"
				></search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/common/filter-active.svg')"
					:image="require('@/assets/svg/common/filter.svg')"
					@click="filterBtnClick"
				></image-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button
					text="보기옵션"
					:image="require('@/assets/svg/common/view-option.svg')"
					@click="isShowModalVisibleOption = true"
				></image-button>
				<image-button text="목록 다운로드" :image="require('@/assets/svg/common/excel-download.svg')" @click="excelBtnClick"></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-tab
				v-for="status in statusList"
				:isActive="status.id === selectedStatus[0]"
				:text="status.name"
				:key="status.id"
				@click="changeActiveStatus(status.id)"
			></list-filter-tab>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
		<modal-visible-option
			:isShow="isShowModalVisibleOption"
			:allColumns="allColumns"
			:requiredColumns="requiredColumns"
			:visibleColumns="visibleColumns"
			@close="isShowModalVisibleOption = false"
			@save="setVisibleOption"
		></modal-visible-option>
	</div>
</template>
<script>
import SearchBox from '@/views/common/components/SearchBox.vue'
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import ListFilterTab from '@/views/common/components/button/ListFilterButton.vue'
import ModalFilter from '../modals/ModalFilter.vue'
import ModalVisibleOption from '@/views/common/modals/ModalVisibleOption.vue'
import { productListTableAllColumns, productListTableRequireColumns } from '@/utils/define/table/productListTable'
import { mapState, mapGetters, mapMutations } from 'vuex'
export default {
	components: {
		SearchBox,
		ImageButton,
		ListFilterTab,
		ModalFilter,
		ModalVisibleOption,
	},
	data() {
		return {
			isShowModalVisibleOption: false,
			allColumns: productListTableAllColumns,
			requiredColumns: productListTableRequireColumns,
		}
	},
	computed: {
		...mapState('product/list', ['loadParams', 'visibleColumns']),
		...mapGetters('product/list', ['selectedStatus', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return `필터(${this.filteredCount})`
			return '필터'
		},
		statusList() {
			return [
				{
					id: 'ALL',
					name: '전체',
				},
				{
					id: 'ING',
					name: '판매중',
				},
				{
					id: 'STOP',
					name: '판매중지',
				},
			]
		},
	},
	methods: {
		...mapMutations('product/list', ['setVisibleColumn']),
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		excelBtnClick() {},
		setVisibleOption(visibleNameList) {
			this.setVisibleColumn(visibleNameList)
		},
		changeActiveStatus(status) {
			let result = []
			switch (status) {
				case 'ING':
					result.push(false)
					break
				case 'STOP':
					result.push(true)
					break
			}
			this.changeLoadParams({ isStops: result })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword: keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
	},
}
</script>
<style lang="scss" scoped>
.header-wrapper {
	position: sticky;
	background-color: white;
	top: 0;
	display: flex;
	flex-direction: column;
	// width: calc(100% - 250px);
	// height: 158px;
	z-index: 100;
	border-bottom: 1px solid #eee; // $COLOR_EEE
	.header-title {
		text-align: left;
		font-weight: 700;
		font-size: 24px;
		padding: 10px 30px 5px 30px;
		height: 50px;
	}
	.filter-button-wrapper {
		display: flex;
		width: 100%;
		min-width: 1200px;
		height: 54px;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		.filter-button-wrapper-left {
			display: inherit;
			gap: 10px;
			.search-box {
				width: 300px;
			}
		}
		.filter-button-wrapper-right {
			display: inherit;
			gap: 10px;
		}
	}
	.status-btn-wrapper {
		display: flex;
		width: 100%;
		height: 54px;
		align-items: center;
		padding: 12px 30px;
		border-top: 1px solid #eee; // $COLOR_EEE
		gap: 8px;
	}
}
</style>
