import catalogAPI from '@/services/api/catalog'

export default {
	namespaced: true,
	state: {
		item: {},
		loadParams: {
			offset: 0,
			length: 50,
			order: ['FAVORITE'],
			orderDirection: ['DESC'],
			catalogId: null,
			hasStock: false,
		},
		products: [],
		count: 0,
	},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
		setProductsParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setProducts: (state, data) => {
			state.products = data.list
			state.count = data.count
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await catalogAPI.lookupCatalog(id)
			commit('setItem', response.data)
		},
		setProductsParams: async ({ commit, dispatch }, loadParams) => {
			commit('setProductsParams', loadParams)
			dispatch('getProducts')
		},
		getProducts: async ({ state, commit }) => {
			const response = await catalogAPI.lookupProductList(state.loadParams)
			commit('setProducts', response.data)
		},
	},
}
