<template>
	<div class="release-wrap">
		<div class="release-wrap-header">
			<div>
				<p>{{ 'Sigma-Aldrich 제공 예상 출고일' }}</p>
				{{ '실시간으로 국내 재고를 파악하여 아래와 같이 예상 출고일을 구매자에게 제공합니다.' }}
			</div>
			<img src="@/assets/svg/registration/sigma-brand-logo.svg" />
		</div>
		<div class="release-wrap-content" v-if="isShowContent">
			<div>
				<p>{{ '국내재고 있는 경우' }}</p>
				<li>{{ '오후 2시 이전 주문 시 당일출고' }}</li>
				<li>{{ '오후 2시 이후 주문 시 익일출고' }}</li>
				<p>{{ '국내재고 없는 경우' }}</p>
				<li>{{ '예상 출고일 판매자 문의필요' }}</li>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ExpectReleaseDate',
	props: {
		isShowContent: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.release-wrap {
	display: flex;
	flex-direction: column;
	border: 1px solid $JW_HOVER;
	border-radius: 5px;
	.release-wrap-header {
		display: flex;
		padding: 15px;
		font-size: 0.875rem;
		p {
			font-weight: 700;
			color: #d52b34;
		}
		img {
			padding-left: 200px;
		}
	}
	.release-wrap-content {
		background: $LIST_HOVER;
		border-top: 1px solid $JW_HOVER;
		div {
			font-weight: 500;
			margin: 15px;
			li {
				font-weight: 400;
				font-size: 1rem;
				list-style-type: disc;
				font-size: 0.875rem;
			}
		}
		// &:before {
		// 	border-top: 1px solid $JW_HOVER;
		// }
	}
}
</style>
