<template>
	<portal to="lm-modal">
		<div class="modal-institute-select-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">유저 소속 선택</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="content">
						<div class="name">소속 선택</div>
						<div class="value">
							<select-option
								:list="options"
								:isError="!isValid"
								errorMessage="견적서를 작성할 유저의 소속을 선택해주세요"
								placeholder="유저의 소속을 선택해주세요"
								@select="onSelect"
							/>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import SelectOption from '@/views/common/components/formItem/SelectOption'
import PageName from '@/utils/define/PageName'

export default {
	name: 'ModalInstituteSelect',
	components: {
		SelectOption,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {
		isShow: {
			handler(val) {
				if (val === true) {
					this.selectedItem = {}
					this.isValid = true
				}
			},
		},
	},
	data() {
		return {
			selectedItem: {},
			isValid: true,
		}
	},
	computed: {
		options() {
			if (this.$_.isEmpty(this.item)) return []
			return this.item.institutes.map(institute => {
				return {
					...institute,
					value: institute.id,
				}
			})
		},
	},
	methods: {
		hide() {
			this.$emit('hide')
		},
		onSelect(selectedItem) {
			this.selectedItem = selectedItem
		},
		complete() {
			console.log(this.selectedItem)
			if (this.$_.isEmpty(this.selectedItem)) {
				this.isValid = false
				return
			}
			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'add',
				},
				query: {
					instituteId: this.selectedItem.value,
					manager: JSON.stringify({
						id: this.item.id,
						name: this.item.name,
						phone: this.item.phone,
						email: this.item.email,
						instituteName: this.selectedItem.name,
					}),
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-institute-select-wrapper {
	z-index: 2888;
	.Popup__container {
		overflow: visible;
		.Popup__content {
			width: 500px;
			overflow: visible;
			.content {
				.name {
					font-size: 0.75rem;
					font-weight: 400;
				}
				.value {
					margin-top: 3px;
				}
			}
		}
	}
}
</style>
