<template>
	<div class="app-sidebar">
		<div class="sidebar-institution">
			<div class="logo">
				<img src="@/assets/svg/app/smartjack-logo.svg" />
			</div>
			<h5>스마트잭</h5>
		</div>
		<ul class="sidebar-menu">
			<li class="menu-title" @click="toggleLMS">
				<img src="@/assets/images/lms-logo.png" />
				<img :class="{ open: isLMSOpen }" class="arrow" src="@/assets/svg/app/sidebar-arrow.svg" />
			</li>
			<template v-if="isLMSOpen">
				<app-side-menu-item
					v-for="(item, index) in lmsMenuList"
					:key="`sidebar-menu-${item.classification}`"
					:item="item"
					:index="index"
					:opened="lmsOpenList.includes(item.classification)"
					@toggleMenu="toggleLMSMenu(item.classification)"
				></app-side-menu-item>
			</template>
			<li class="menu-title lmp" @click="toggleLMP">
				<img src="@/assets/images/lmp-logo.png" />
				<img :class="{ open: isLMPOpen }" class="arrow" src="@/assets/svg/app/sidebar-arrow.svg" />
			</li>
			<template v-if="isLMPOpen">
				<app-side-menu-item
					v-for="(item, index) in lmpMenuList"
					:key="`sidebar-menu-${item.classification}`"
					:item="item"
					:index="index"
					:opened="lmpOpenList.includes(item.classification)"
					@toggleMenu="toggleLMPMenu(item.classification)"
				></app-side-menu-item>
			</template>
		</ul>
	</div>
</template>

<script>
import AppSideMenuItem from '@/views/app/components/AppSideMenu/components/AppSideMenuItem.vue'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

export default {
	name: 'AppSideMenu',
	computed: {
		...mapGetters('menu', ['lmsMenuList', 'lmsOpenList', 'lmpMenuList', 'lmpOpenList', 'isLMSOpen', 'isLMPOpen']),
	},
	components: {
		AppSideMenuItem,
	},
	watch: {},
	methods: {
		...mapMutations('menu', ['toggleLMSMenu', 'toggleLMS', 'toggleLMPMenu', 'toggleLMP']),
	},
}
</script>

<style lang="scss" scoped>
.app-sidebar {
	width: 250px;
	height: 100vh;
	background-color: $SIDEBAR_DEFAULT;
	.sidebar-institution {
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0 16px;
		height: 60px;
		box-sizing: border-box;
		border-bottom: 1px solid $SIDEBAR_BORDER;
		.logo {
			width: 36px;
			height: 36px;
			border-radius: 8px;
			overflow: hidden;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		h5 {
			font-size: 0.875rem;
			color: $COLOR_111;
			margin-left: 10px;
			font-weight: 700;
		}
	}
	.menu-title {
		cursor: pointer;
		display: flex;
		align-items: center;
		width: 100%;
		font-size: 20px;
		padding-right: 16px;
		padding-left: 16px;
		text-align: left;
		height: 60px;
		border-bottom: 1px solid $LINE_DIVIDER;
		&.lmp {
			padding-left: 20px;
		}
		img {
			height: 30px;
			&.arrow {
				width: 24px;
				height: 24px;
				margin-left: auto;
				&.open {
					transform: rotate(180deg);
				}
			}
		}
		&:hover {
			background-color: $SIDEBAR_SUB_HOVER;
		}
	}
	.sidebar-menu {
		height: calc(100% - 60px);
		overflow-y: auto;
	}
}
</style>
