import businessRegistrationAPI from '@/services/api/businessregistration'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			status: JSON.parse(cookies.get('filter_businessregistration_status')) ?? [],
			offset: 0,
			length: 20,
			order: ['STATUS_CHANGE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: null,
		filterCookieName: {},
		list: [],
		count: 0,
		info: {},
		detail: {},
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (state.loadParams.status.length == 1) return state.loadParams.status[0]
			return 'ALL'
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => state.loadParams.status.length,
		detail: state => state.detail,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * state.loadParams.length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			state.filter = data
		},
		setDetail: (state, data) => {
			state.detail = data
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: null,
				status: JSON.parse(cookies.get('filter_businessregistration_status')) ?? [],
				offset: 0,
				length: 20,
				order: ['STATUS_CHANGE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await businessRegistrationAPI.list(state.loadParams)
			commit('setList', response.data)
		},
		getDetail: async ({ state, commit }, params) => {
			const response = await businessRegistrationAPI.detail(params)
			commit('setDetail', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setOffset', 1)
			commit('setLoadParams', loadParams)
			await dispatch('getList')
		},
		rejectBusinessRegistration: async ({ commit }, payload) => {
			const { body } = payload
			return await businessRegistrationAPI.rejectBusinessRegistration(body)
		},
		approveBusinessRegistration: async ({ commit }, body) => {
			return await businessRegistrationAPI.approveBusinessRegistration(body)
		},
		updateBusinessRegistration: async ({ commit }, body) => {
			return await businessRegistrationAPI.updateBusinessRegistration(body)
		},
	},
}
