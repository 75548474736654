import axiosInstance from '@/services/axios'
import axios from 'axios'
import { logout } from '@/services/api/common'

const axiosInstanceLogin = axios.create({
	baseURL: process.env.VUE_APP_BASE_ACCOUNT_URL,
	timeout: 100000,
})

const endPoint = {
	login: () => '/v1/signin',
	logout: () => '/v1/signout',
	lookupUserInfo: () => '/mall/user',
	lookupEmailValidation: () => '/partner/seller/email-validation',
	applyEnterSeller: () => '/partner/seller/apply-enter',
	lookupUserList: () => '/admin/user/search',
}

const userAPI = {
	login: body => {
		return axiosInstanceLogin.post(endPoint.login(), body)
	},
	logout: () => {
		logout()
		return axiosInstanceLogin.post(endPoint.logout)
	},
	lookupUserInfo: params => {
		return axiosInstance.get(endPoint.lookupUserInfo(), { params })
	},
	lookupEmailValidation: params => {
		return axiosInstance.get(endPoint.lookupEmailValidation(), { params })
	},
	applyEnterSeller: payload => {
		return axiosInstance.post(endPoint.applyEnterSeller(), payload)
	},
	lookupUserList: params => {
		return axiosInstance.get(endPoint.lookupUserList(), { params })
	},
}

export default userAPI
