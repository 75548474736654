<template>
	<b-modal ref="modal" size="lg" title="물품 상세" centered hide-footer>
		<info-list :list="contentInfo" style="margin-bottom: 20px" />
	</b-modal>
</template>
<script>
import { mapActions } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
export default {
	components: {
		InfoList,
	},
	data() {
		return {
			item: null,
		}
	},
	computed: {
		contentInfo() {
			return [
				{ title: '물품 ID', value: this.item?.id, row: true },
				{ title: '물품 이름', value: this.item?.name, row: true },
				{ title: '제조사', value: this.item?.maker?.name },
				{ title: '제조사 ID', value: this.item?.maker?.id },
				{ title: 'Catalog No', value: this.item?.catalogNo },
				{ title: 'CAS No', value: this.item?.casNo },
				{ title: '용량', value: this.item?.volume?.value + this.item?.volume?.unit },
				{ title: 'MSDS', type: 'link', value: this.item?.msdsUrl || '-', row: true, clickFn: this.msdsPopup },
			]
		},
	},

	methods: {
		...mapActions('chemicalApi/request', ['getProductDetail']),
		async show(id) {
			this.item = await this.getProductDetail(id)
			this.$refs.modal.show()
		},
		hide() {
			this.$refs.modal.hide()
		},
		msdsPopup() {
			if (this.item.msdsUrl) {
				window.open(this.item.msdsViewerUrl, '_blank', 'width=800, height=1000, left=300, top=50')
				return false
			}
		},
	},
}
</script>
<style lang="scss" scoped></style>
