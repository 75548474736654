<template>
	<div class="SellerList">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<seller-table class="table" />
		</div>
		<pagination :total="sellers.count" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import SellerTable from '../components/Table.vue'

export default {
	name: 'SellerList',
	components: {
		Pagination,
		SellerTable,
		ListHeader,
	},
	async created() {
		this.initParams()
		await this.getList()
	},
	computed: {
		...mapState('seller/list', ['sellers']),
	},
	methods: {
		...mapMutations('seller/list', ['initParams']),
		...mapActions('seller/list', ['getList', 'setLoadParams', 'setPagination']),
		pagination(page) {
			this.setPagination(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-wrapper {
	height: calc(100% - 171px);
}
</style>
