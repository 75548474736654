import approvalAPI from '@/services/api/product/approval'

export default {
	namespaced: true,
	state: {
		item: {},
	},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await approvalAPI.lookupApprovalProduct(id)
			commit('setItem', response.data)
		},
	},
}
