<template>
	<div class="seller-terms-agree-wrapper">
		<div class="terms-agree-title">
			<p>이용약관 동의</p>
		</div>
		<div class="terms-agree">
			<ul class="terms">
				<li>
					<input id="allCheck" type="checkbox" v-model="isAllChecked" @change="changeAllTerms" />
					<label for="allCheck">전체동의</label>
				</li>
				<li :class="{ open: openedTermsNumber === '1' }">
					<div class="flex">
						<input id="terms1" type="checkbox" v-model="terms" @change="changeTerms" value="terms1" />
						<label for="terms1">[필수] 개인정보 이용약관</label>
						<input type="radio" v-model="openedTermsNumber" value="1" @click="closeTerms('1')" />
					</div>
					<div class="box">
						<h6>LAB MANAGER 개인정보 처리방침</h6>
						<p>
							주식회사 스마트 잭은 개인정보 보호법 제30조에 따라 랩매니저 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고
							원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립․공개합니다. <br /><br />
							제1조(개인정보의 처리목적)
							<br /><br />
							주식회사 스마트 잭은 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
							이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할
							예정입니다.
							<br /><br />
							1. 랩매니저 회원 가입 및 관리
							<br /><br />
							이용자의 회원 가입의사 확인, 랩매니저 서비스 제공에 따른 본인 식별․인증, 회원자격 유지․관리, 본인확인제 시행에 따른
							본인확인, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인, 각종 고지․통지, 고충처리
							등을 목적으로 개인정보를 처리합니다.
							<br /><br />
							2. 재화 또는 서비스 제공
							<br /><br />
							상품 주문 확인 및 이용자 식별 서비스 이용 및 상담, 서비스 내 주문 및 구매내역 관리, 물품배송, 배송내역 관리, 서비스 제공,
							세금계산서․거래명세서 등의 서비스 이용 관련 서류 발송, 콘텐츠 제공, 맞춤서비스 제공, 본인인증, 연령인증, 요금결제․정산 및
							내역 관리, 채권추심 등을 목적으로 개인정보를 처리합니다.
							<br /><br />
							3. 고충처리
							<br /><br />
							이용자의 신원 확인, 고충사항 확인, 사실조사를 위한 연락․통지, 처리결과 통보, 분쟁처리에 관한 기록 등의 목적으로 개인정보를
							처리합니다.
							<br /><br />
							4. 서비스 관련 공지사항 및 마케팅 정보의 제공
							<br /><br />
							제공 서비스 및 상품의 업데이트, 이벤트 등 광고성 공지와 참여자 식별 및 당첨자 선정, 이벤트 당첨에 따른 경품 전달, 당사 및
							제휴사 상품 안내, 회원 대상의 각종 마케팅 활동 등의 목적으로 개인정보를 처리합니다.
							<br /><br />
							제2조(개인정보의 처리 및 보유기간)
							<br /><br />
							① 주식회사 스마트 잭은 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터 개인정보를 수집시에 동의받은 개인정보
							보유․이용기간 내에서 개인정보를 처리․보유합니다.
							<br /><br />
							② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
							<br /><br />
							1. 랩매니저 스토어 회원 가입 및 관리 : 랩매니저 스토어 탈퇴시까지 보유합니다.
							<br /><br />
							다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 보유합니다.
							<br /><br />
							1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당 수사․조사 종료시까지
							<br /><br />
							2) 랩매니저 스토어 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계 정산시까지
							<br /><br />
							2. 재화 또는 서비스 제공 :
							<br /><br />
							1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 주문, 배송 및 결제 등 거래에 관한 기록
							<br /><br />
							- 표시ㆍ광고에 관한 기록: 6개월
							<br /><br />
							- 계약 또는 청약철회 등에 관한 기록: 5년
							<br /><br />
							- 대금결제 및 재화등의 공급에 관한 기록: 5년
							<br /><br />
							- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년
							<br /><br />
							2) 「통신비밀보호법」 시행령 제41조에 따른 통신사실확인자료 보관
							<br /><br />
							- 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
							<br /><br />
							3) 「부가가치세법」 제71조에 따른 장부, 세금계산서, 수입세금계산서, 영수증 등의 자료 : 5년
							<br /><br />
							제3조(개인정보의 제3자 제공)
							<br /><br />
							① 주식회사 스마트 잭은 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의
							동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
							<br /><br />
							② 주식회사 스마트 잭은 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
							<br /><br />
							1. 실험용 물품 판매사 (판매사 목록 링크 제공)
							<br /><br />
							- 제공받는 자의 개인정보 이용목적 : 재고 확인 및 회신, 상품 배송, 반품, 고객상담 등
							<br /><br />
							정보통신서비스제공계약 및 전자상거래(통신판매) 계약의 이행을 위해 필요한 업무 처리
							<br /><br />
							- 제공하는 개인정보 항목 : 구매자 정보(이름, 휴대폰번호, 전자메일주소), 수령인 정보(이름,
							<br /><br />
							휴대폰번호, 주소), 상품 주문/취소/반품/교환정보, 송장정보
							<br /><br />
							- 제공받는 자의 보유․이용기간 : 상품 제공 완료 후 3개월
							<br /><br />
							제4조(개인정보처리의 위탁)
							<br /><br />
							① 주식회사 스마트 잭은 원활한 서비스 및 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
							<br /><br />
							1. 결제 관련 기업/기관
							<br /><br />
							i. 위탁받는 자 (수탁자) : KG이니시스, 팝빌, 다날, 링크허브
							<br /><br />
							ii. 위탁하는 업무의 내용 : 전자 결제 대행, 전자세금계산서 발행, 휴대폰 본인인증,
							<br /><br />
							2. SMS 및 카카오 알림톡 발송
							<br /><br />
							② 주식회사 스마트 잭은 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적
							보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가
							개인정보를 안전하게 처리하는지를 감독하고 있습니다.
							<br /><br />
							③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
							<br /><br />
							제5조(정보주체와 법정대리인의 권리 ․의무 및 행사방법)
							<br /><br />
							① 이용자는 주식회사 스마트 잭에 대해 언제든지 개인정보 열람․정정․삭제․처리정지 요구 등의 권리를 행사할 수 있습니다.
							<br /><br />
							② 제1항에 따른 권리 행사는 주식회사 스마트 잭에 대해 개인정보보호법 시행령 제41조제1항에 따라 서면, 전자우편,
							모사전송(FAX) 등을 통하여 하실 수 있으며, 주식회사 스마트 잭은 이에 대해 지체없이 조치하겠습니다.
							<br /><br />
							③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
							보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
							<br /><br />
							④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수
							있습니다.
							<br /><br />
							⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
							없습니다.
							<br /><br />
							⑥ 주식회사 스마트 잭은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가
							본인이거나 정당한 대리인인지를 확인합니다.
							<br /><br />
							제6조(처리하는 개인정보 항목)
							<br /><br />
							주식회사 스마트 잭은 다음의 개인정보 항목을 처리하고 있습니다.
							<br /><br />
							1. 랩매니저 회원 가입 및 관리
							<br /><br />
							․필수항목 : 성명, 아이디(전자메일 주소), 비밀번호
							<br /><br />
							․선택항목 : 휴대폰번호, 소속 기업/기관, 소속 연구실, 연구실 전화번호
							<br /><br />
							2. 재화 또는 서비스 제공
							<br /><br />
							․필수항목 :
							<br /><br />
							가) 본인인증을 통한 인적 정보: 성명, 성별, 생년월일, 개인식별 고유 키, 사이트 별 개인식별 고유 키, 휴대폰 번호, 통신사,
							외국인 여부
							<br /><br />
							나) 상품 주문 및 결제를 위한 정보: 전자메일 주소/ID, 비밀번호, 배송지 주소, 전화번호, 거래명세서 수신 전자메일주소,
							세금계산서 수신 전자메일주소, 신용카드번호, 사업자등록증, 은행계좌정보 등 결제정보
							<br /><br />
							․선택항목 : 관심 상품, 과거 구매내역
							<br /><br />
							3. 고충처리
							<br /><br />
							․필수항목 : 성명, 아이디(전자메일 주소), 전화번호
							<br /><br />
							4. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
							<br /><br />
							․IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량 이용기록 등
							<br /><br />
							제7조(개인정보의 파기절차 및 파기방법)
							<br /><br />
							① 주식회사 스마트 잭은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당
							개인정보를 파기합니다.
							<br /><br />
							② 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를
							계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
							<br /><br />
							③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
							<br /><br />
							A. 파기절차
							<br /><br />
							주식회사 스마트 잭은 파기 사유가 발생한 개인정보를 선정하고, 주식회사 스마트 잭의 개인정보 보호책임자의 승인을 받아
							개인정보를 파기합니다.
							<br /><br />
							B. 파기방법
							<br /><br />
							주식회사 스마트 잭은 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록․저장된
							개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.
							<br /><br />
							제8조(개인정보의 안전성 확보조치)
							<br /><br />
							주식회사 스마트 잭은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.
							<br /><br />
							1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등
							<br /><br />
							2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치
							<br /><br />
							3. 물리적 조치 : 전산실, 자료보관실 등의 접근통제
							<br /><br />
							제9조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항)
							<br /><br />
							① 주식회사 스마트 잭은 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를
							사용합니다.
							<br /><br />
							② 쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC
							컴퓨터내의 하드디스크에 저장되기도 합니다.
							<br /><br />
							가) 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을
							파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
							<br /><br />
							나) 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
							있습니다.
							<br /><br />
							다) 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.
							<br /><br />
							제10조(개인정보 보호책임자)
							<br /><br />
							① 주식회사 스마트 잭은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및
							피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.
							<br /><br />
							▶ 개인정보 보호책임자
							<br /><br />
							성명 : 정동주
							<br /><br />
							직책 : 개발팀
							<br /><br />
							연락처 : 02-498-3060, lostcode7@smartjackwp.com
							<br /><br />
							▶ 개인정보 보호 담당부서
							<br /><br />
							부서명 : 개발팀
							<br /><br />
							담당자 : 정동주
							<br /><br />
							연락처 : 1533-3061, lostcode7@smartjackwp.com
							<br /><br />
							② 이용자께서는 주식회사 스마트 잭의 랩매니저 스토어 서비스를 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리,
							피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 주식회사 스마트 잭은 정보주체의 문의에
							대해 지체없이 답변 및 처리해드릴 것입니다.
							<br /><br />
							제11조(개인정보 열람청구)
							<br /><br />
							이용자는 개인정보 보호법 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 주식회사 스마트 잭은
							정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.
							<br /><br />
							▶ 개인정보 열람청구 접수․처리 부서
							<br /><br />
							부서명 : 개발팀
							<br /><br />
							담당자 : 정동주
							<br /><br />
							연락처 : 1533-3061, lostcode7@smartjackwp.com
							<br /><br />
							제12조(권익침해 구제방법)
							<br /><br />
							정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다.
							<br /><br />
							&lt;아래의 기관은 주식회사 스마트 잭과는 별개의 기관으로서, 주식회사 스마트 잭의 자체적인 개인정보 불만처리, 피해구제
							결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다&gt;
							<br /><br />
							▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
							<br /><br />
							- 소관업무 : 개인정보 침해사실 신고, 상담 신청
							<br /><br />
							- 홈페이지 : privacy.kisa.or.kr
							<br /><br />
							- 전화 : (국번없이) 118
							<br /><br />
							- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층 개인정보침해신고센터
							<br /><br />
							▶ 개인정보 분쟁조정위원회
							<br /><br />
							- 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
							<br /><br />
							- 홈페이지 : www.kopico.go.kr
							<br /><br />
							- 전화 : (국번없이) 1833-6972
							<br /><br />
							- 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
							<br /><br />
							▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
							<br /><br />
							▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
							<br /><br />
							제13조(개인정보 처리방침 변경)
							<br /><br />
							① 이 개인정보 처리방침은 2020. 9. 1부터 적용됩니다.
							<br /><br />
							② 향후 개인정보 처리방침이 변경될 경우, 이전의 개인정보 처리방침은 별도로 확인하실 수 있도록 링크를 제공드릴 예정입니다.
						</p>
					</div>
				</li>
				<li :class="{ open: openedTermsNumber === '2' }">
					<div class="flex">
						<input id="terms2" type="checkbox" v-model="terms" @change="changeTerms" value="terms2" />
						<label for="terms2">[필수] 개인정보 제3자 제공동의</label>
						<input type="radio" v-model="openedTermsNumber" value="2" @click="closeTerms('2')" />
					</div>
					<div class="box">
						<h6>LAB MANAGER 서비스 이용 약관</h6>
						<p>
							제 1 조 [ 목적 ] <br /><br />
							본 약관은 주식회사 스마트 잭(이하 "회사")이 제공하는 랩매니저 이용과 관련하여 회사와 이용자의 권리·의무 및 책임사항, 기타
							필요한 사항을 규정함을 목적으로 합니다.
							<br /><br />
							제 2 조 [ 용어의 정의 ]
							<br /><br />
							본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
							<br /><br />
							① 랩매니저 : 이용자의 연구실 화학물질 및 실험물품을 관리하고, 이용자가 판매자로부터 화학물질 및 실험물품을 구매할 수
							있도록 중개하기 위하여 회사가 제공하는 모바일 어플리케이션 및 PC버전을 의미합니다.
							<br /><br />
							② 서비스: 회사가 랩매니저를 통하여 이용자의 연구실 화학물질 및 실험물품을 관리하고, 이용자가 판매자로부터 화학물질 및
							실험물품을 구매할 수 있도록 중개하는 등의 서비스 일체를 의미합니다.
							<br /><br />
							③ 이용자: 본 약관에 따라 회사와 서비스 이용계약을 체결하고 회사가 제공하는 서비스를 이용하는 기관·기업·학교의 연구실에
							소속된 개인을 의미합니다.
							<br /><br />
							④ 연구실: 학교·기관·기업 등이 과학기술분야 연구개발활동을 위하여 시설·장비·연구재료 등을 갖추어 설치한
							실험실·실습실·실험준비실로서 랩매니저를 사용하는 최소 단위의 그룹을 의미합니다.
							<br /><br />
							⑤ 책임자(PI: Principle Investigator): 이용자들이 소속된 연구실을 대표하여 구성원의 서비스 이용을 승인하거나, 구성원의
							서비스 이용에 대한 관리 권한과 운영을 책임지는 자를 의미합니다.
							<br /><br />
							⑥ 관리자: PI에 의해 PI의 권한 일부를 승인 받아 PI가 허용하는 범위 내에서 연구실 계정을 관리하고 연구원의 결재를
							승인·반려하는 자를 의미합니다.
							<br /><br />
							⑦ 연구원: 연구실에 소속되어 실험 실무를 담당하는 자를 의미합니다.
							<br /><br />
							⑧ 아이디: 이용자의 식별과 서비스 이용을 위하여 이용자가 정하고 회사가 승인하는 문자와 숫자의 조합을 의미하며, 랩매니저는
							전자우편주소를 아이디로 사용하도록 설계되었습니다.
							<br /><br />
							⑨ 비밀번호: 아이디와 일치된 이용자임을 확인하고, 이용자의 비밀을 보호하기 위해 이용자가 설정한 문자와 숫자의 조합을
							의미합니다.
							<br /><br />
							⑩ 데이터: 이용자가 입력하여 회사의 서버 내에 저장되는 자료(보유목록, 시약 잔량, 물품 상세정보, 구매/결재 기록, 물품 사진,
							물질안전보건자료, 개인 프로필 정보 등) 및 그 자료들의 결합, 변형 또는 재 연산을 통하여 생성되는 부가 자료 일체를
							의미합니다.
							<br /><br />
							⑪ 재화: 회사가 중개하여 판매자가 랩매니저 내 스토어에서 이용자에게 판매하는 화학물질 및 실험물품을 의미합니다.
							<br /><br />
							⑫ 스토어: 판매자가 재화를 이용자에게 판매하는 것을 회사가 중개하기 위하여, 모바일, PC 등 정보통신설비를 이용하여 재화를
							거래할 수 있도록 설정한 랩매니저 내 가상의 영업장을 말합니다.
							<br /><br />
							⑬ 판매자: 스토어에서 이용자에게 재화를 판매하는 사업자를 의미합니다.
							<br /><br />
							⑭ 구매자: 스토어에서 재화를 구매하는 이용자를 의미합니다.
							<br /><br />
							⑮ 회원: 랩매니저에 회원등록을 한 자로서, 계속적으로 회사가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
							<br /><br />
							16 비회원: 회원에 가입하지 않은 자로서, 일시적으로 회사가 제공하는 서비스를 이용하는 자를 말합니다.
							<br /><br />
							제 3 조 [ 약관의 게시와 개정 ]
							<br /><br />
							① 회사는 본 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소, 전화번호, 전자우편주소, 사업자등록번호, 통신판매업
							신고번호, 개인정보관리책임자 등을 이용자가 쉽게 알 수 있도록 랩매니저 내 서비스 이용 메뉴에 게시합니다.
							<br /><br />
							② 회사는 약관 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 전자상거래 등에서의 소비자 보호에 관한
							법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 방문판매 등에 관한 법률, 소비자기본법, 화학물질 관리법 등
							관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
							<br /><br />
							③ 회사는 이용자가 본 약관에 동의하기에 앞서 본 약관에 정하여져 있는 내용 중 청약철회∙배송책임∙환불조건 등과 같은 중요한
							내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
							<br /><br />
							④ 회사가 본 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 본 조 제1항의 방식에 따라 그 개정약관의
							적용일자 7일 전부터 적용일자 전까지 공지합니다. 다만, 이용자의 권리 또는 의무에 관한 중요한 사항의 변경 또는 이용자에게
							불리한 약관의 변경은 최소한 30일 전에 공지하고, 이용자가 알기 쉽도록 개정 전 후 내용을 명확히 비교하여 이용자가 사전에
							등록한 전자우편주소, 전화번호로 메일, 문자 메시지 등의 전자적 수단을 통해 별도로 명확히 통지하도록 합니다.
							<br /><br />
							⑤ 회사가 본 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된
							계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 이용계약을 체결한 이용자가 개정약관 조항의 적용을 받기를
							원하는 뜻을 제2항에 의한 개정약관의 공지기간 내에 회사에 송신하여 회사의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
							<br /><br />
							⑥ 회사가 본 조 제2항에 따라 개정약관을 공지 또는 통지하면서 이용자에게 약관 변경 적용일까지 거부의사를 표시하지 않으면
							동의한 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도 이용자가 명시적으로 거부의 의사표시를 하지 아니한 경우
							이용자가 개정약관에 동의한 것으로 봅니다.
							<br /><br />
							⑦ 이용자는 개정된 약관에 대해 동의하지 않을 권리가 있으며 이용자가 개정약관의 적용에 동의하지 않을 경우 이용자는
							이용계약을 해지할 수 있습니다. 회사는 개정약관 적용에 동의하지 않는 이용자에 대해서는 기존 약관을 적용하되, 기존 약관을
							적용할 수 없는 특별한 사정이 있는 경우에는 이용계약을 해지할 수 있습니다.
							<br /><br />
							⑧ 본 약관은 이용자가 약관의 내용에 동의함으로써 효력이 발생하여 이용계약 종료일까지 적용됩니다. 단, 채권 또는 채무관계가
							있을 경우에는 채권, 채무의 완료일까지로 규정합니다.
							<br /><br />
							제 4 조 [ 약관 외 준칙 ]
							<br /><br />
							본 약관에서 정하지 않은 사항과 본 약관의 해석에 관하여는 약관의 규제에 관한 법률, 정보통신망 이용촉진 및 정보보호 등에
							관한 법률, 전자상거래 등에서의 소비자보호에 관한 법률, 전자문서 및 전자거래기본법, 전자금융거래법, 전자서명법, 방문판매
							등에 관한 법률, 소비자기본법, 화학물질 관리법, 기타 관계법령 및 상관례에 따릅니다.
							<br /><br />
							제 5 조 [ 회원가입 및 이용계약 체결 ]
							<br /><br />
							① 이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로서 회원가입을
							신청합니다.
							<br /><br />
							② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
							<br /><br />
							1. 가입신청자가 부정사용에 의하여 이전에 회원자격을 상실한 적이 있는 경우
							<br /><br />
							2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
							<br /><br />
							3. 기타 회원으로 등록하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우
							<br /><br />
							③ 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 합니다.
							<br /><br />
							④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 회사에 회원정보 수정 등의 방법으로 그 변경사항을
							알려야 합니다.
							<br /><br />
							⑤ 서비스 이용계약은 이용자가 본 약관의 내용에 대하여 동의를 한 다음 회사가 제시하는 양식과 절차에 따라 이용신청을 하고
							회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
							<br /><br />
							⑥ 회사는 이용자의 서비스 이용 신청에 대하여 서비스 이용 승낙을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에
							대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
							<br /><br />
							1. 이용자가 본 약관에 의하여 이전에 서비스 이용자격을 상실한 적이 있는 경우, 단 회사의 이용자 재가입 승낙을 얻을 경우에는
							예외로 함.
							<br /><br />
							2. 실명이 아니거나 타인의 명의를 이용한 경우
							<br /><br />
							3. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우
							<br /><br />
							4. 만 14세 미만의 신청자인 경우
							<br /><br />
							5. 이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에 지장을 줄 것으로 예상되는 경우
							<br /><br />
							6. 이용자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우
							<br /><br />
							7. 기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 인정하는 경우
							<br /><br />
							8. 회사로부터 계약해지를 당한 이후 1년이 경과하지 않은 경우
							<br /><br />
							⑦ 서비스 이용 신청에 있어 회사는 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 요청할 수 있습니다.
							<br /><br />
							⑧ 회사는 이용자의 서비스 이용 신청에 대하여 전문기관을 통한 실명확인 및 본인인증을 요청하거나 증빙자료를 요청할 수
							있습니다.
							<br /><br />
							⑨ 회사는 서비스 관련 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
							<br /><br />
							⑩ 이용자는 서비스 이용을 위해 허위 사실이 없는 진실된 정보를 제공하여야 하며, 회사는 제공하는 서비스에 따라 필요한 경우
							이용자에게 추가 정보를 요청할 수 있습니다.
							<br /><br />
							⑪ 회사와 이용자가 서비스 이용에 관하여 별도 계약을 체결한 경우, 해당 별도 계약이 본 약관에 우선하여 적용됩니다.
							<br /><br />
							제 6 조 [ 구매계약 체결 및 대금 결제 ]
							<br /><br />
							① 재화의 구매계약은 구매자가 스토어 내에서 판매자가 제시한 재화의 판매 조건에 응하여 구매신청을 하고 이에 대하여 판매자가
							승낙함으로써 체결됩니다.
							<br /><br />
							② 구매자는 재화 중 화학물질관리법상 유해화학물질 또는 유해화학물질에 해당하는 시험용·연구용·검사용 시약을 구매하기 위해
							실명·연령 확인 및 본인 인증을 거쳐야 합니다.
							<br /><br />
							③ 구매자의 실명·연령 확인 및 본인 인증은 화학물질관리법 시행규칙 제30조의2에 따라 다음 각호의 어느 하나에 해당하는
							방법으로 합니다.
							<br /><br />
							1. 팩스 또는 우편으로 수신한 사업자등록증 사본 확인
							<br /><br />
							2. 「전자서명법」 제2조제8호에 따른 공인인증서를 통한 확인
							<br /><br />
							3. 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제23조의3에 따라 지정된 본인확인기관을 통한 본인확인
							<br /><br />
							④ 구매자는 연구실이 적법한 사업자임을 인증해야 하며, 사업자등록증 사본, 소속기관, 소속 연구실 정보를 팩스 또는 우편으로
							제출해야 합니다. 회사는 사업자등록증에 대하여 다음 각호의 기준으로 심사합니다.
							<br /><br />1. 사업자등록증 심사기간 : 영업시간 기준 3시간 이내 <br /><br />2. 사업자등록증 승인기준 <br /><br />가.
							서비스 내 연구기관 이름과 정보가 일치해야 함. <br /><br />나. 실험∙연구 목적의 기관/기업이어야 함. 다. 교육기관 및
							출연연의 경우, 책임자(PI) 이름이 기관 홈페이지에 존재해야 함. <br /><br />
							⑤ 판매자는 유해화학물질에 해당하는 시험용·연구용·검사용 시약을 판매하는 경우, 스토어에 게재하는 방법으로 구매자에게 다음
							각 호의 사항을 알려주어야 합니다.
							<br /><br />
							1. 시험용·연구용·검사용 시약은 해당 용도로만 사용하여야 한다는 것
							<br /><br />
							2. 취급 시 유해화학물질 취급기준을 준수하여야 한다는 것
							<br /><br />
							⑥ 판매자는 본 조 제2 내지 4항의 인증을 거친 구매자의 구매 신청에 대하여 승낙을 원칙으로 합니다. 다만, 판매자는 스토어 내
							구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 회사는 판매자가 미성년자와 계약을 체결하는 경우에는
							법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
							<br /><br />
							1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
							<br /><br />
							2. 청소년이 주류, 담배, 마약류, 환각물질 등 청소년보호법에서 금지하는 청소년유해약물등을 구매하는 경우
							<br /><br />
							3. 기타 구매신청에 승낙하는 것이 스토어 기술상 현저히 지장이 있다고 판단하는 경우
							<br /><br />
							⑦ 구매자는 스토어에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, 회사는 구매자가 구매신청을 함에 있어서 다음의
							각 내용을 알기 쉽게 제공하여야 합니다.
							<br /><br />
							1. 재화의 검색 및 선택
							<br /><br />
							2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
							<br /><br />
							3. 약관내용, 청약철회권이 제한되는 서비스, 배송료 등의 비용부담과 관련한 내용에 대한 확인
							<br /><br />
							4. 본 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭)
							<br /><br />
							5. 재화의 구매신청 및 이에 관한 확인 또는 스토어의 확인에 대한 동의
							<br /><br />
							6. 결제방법의 선택
							<br /><br />
							⑧ 회사는 스토어에서 구매자의 구매신청이 있는 경우, 구매자에게 수신확인 통지를 합니다.
							<br /><br />
							⑨ 스토어 내 회사의 수신확인 통지가 구매자에게 도달한 시점에 판매자와 구매자의 구매 계약이 성립한 것으로 봅니다.
							<br /><br />
							⑩ 스토어 내 회사의 수신확인 통지에는 구매자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보
							등을 포함하여야 합니다.
							<br /><br />
							⑪ 수신확인통지를 받은 구매자는 의사표시의 불일치 등이 있는 경우에는 수신확인 통지를 받은 후 즉시 구매신청 변경 및 취소를
							요청할 수 있고 회사는 배송 전에 구매자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을
							지불한 경우에는 청약철회 등에 관한 규정에 따릅니다.
							<br /><br />
							⑫ 회사와 판매자는 구매자의 배송정보 오기입으로 발생되는 문제에 대해 책임을 지지 않으며, 상품의 회수와 안전 문제에 대해
							발생되는 모든 비용을 구매자가 부담합니다.
							<br /><br />
							제 7 조 [ 재화의 공급 ]
							<br /><br />
							① 판매자는 구매자와 재화의 공급시기에 관하여 별도의 약정이 없는 이상, 구매자가 청약을 한 날부터 7일 이내에 재화을 배송할
							수 있도록 재고 확인, 포장 등 기타의 필요한 조치를 취합니다. 이때 판매자는 구매자가 재화의 공급 절차 및 진행 사항을 확인할
							수 있도록 적절한 조치를 합니다. 단, 재고 부족에 의한 배송 지연에 대해서는 구매자에게 상황을 고지하고 공급일정 및 대체품
							공급을 별도 협의합니다.
							<br /><br />
							② 판매자는 구매자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 판매자가
							약정 배송기간을 초과한 경우에는 그로 인한 구매자의 손해를 배상하여야 합니다. 다만 판매자가 고의․과실이 없음을 입증한
							경우에는 그러하지 아니합니다.
							<br /><br />
							③ 판매자는 구매자가 구매신청한 재화가 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 구매자와
							회사에게 통지합니다. 사전에 재화의 대금을 받은 경우에는 회사는 판매자로부터 통지를 받은 날부터 3영업일 이내에 환급하거나
							환급에 필요한 조치를 취합니다.
							<br /><br />
							④ 판매자는 배송·환불 불가 상품에 대해 공급 및 환불을 제한할 수 있습니다.
							<br /><br />
							⑤ 판매자는 재화의 품절 또는 사양의 변경 등의 경우에 장차 체결되는 구매계약에 의해 제공할 재화의 내용을 변경할 수 있습니다.
							이 경우에는 변경된 재화의 내용 및 제공일자를 명시하여 현재 재화의 내용을 게시한 곳에 즉시 공지합니다.
							<br /><br />
							제 8 조 [ 서비스 제공 ]
							<br /><br />
							① 회사는 이용자에게 제공하는 서비스를 홈페이지에 게시합니다.
							<br /><br />
							② 회사는 연구실의 개별 구성원에게 부여할 수 있는 계정의 수량을 특정할 수 있으며, 책임자(PI)는 부여받은 계정의 수량 내에서
							구성원들에게 개별적으로 계정을 부여합니다. 단, 서비스 개시 시점을 포함한 서비스 이용기간은 계정이 할당되지 않은 수량을
							포함한 모든 계정에 동일하게 적용됩니다.
							<br /><br />
							③ 책임자(PI) 및 관리자는 특정 구성원이 계정을 다른 구성원과 공유하지 못하도록 이를 관리, 감독할 책임이 있으며, 기존
							구성원이 퇴사 또는 다른 구성원으로 계정을 변경하고자 하는 경우에는 회사를 통해 당해 계정을 재할당 할 수 있습니다.
							<br /><br />
							④ 회사는 스토어를 통해 재화에 대한 정보 제공 및 구매계약 체결의 중개, 구매계약이 체결된 재화의 배송 위탁, 배송완료 된
							재화에 대한 대금결제를 수행합니다.
							<br /><br />
							⑤ 회사가 제공하기로 이용자와 이용계약을 체결한 서비스의 내용을 재화의 품절 또는 기술적 사양의 변경 등의 사유로 변경할
							경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
							<br /><br />
							⑥ 전항의 경우 회사는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, 회사가 고의 또는 과실이 없음을 입증하는 경우에는
							그러하지 아니합니다.
							<br /><br />
							⑦ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은 회사가 사전에 통지한 바에 따릅니다.
							<br /><br />
							제 9 조 [ 서비스의 변경 및 중단 ]
							<br /><br />
							① 회사는 정책상, 운영상, 컴퓨터 등 정보통신설비의 보수점검∙교체 및 고장, 통신의 두절 등 기술상의 필요에 따라 제공하고 있는
							서비스의 전부 또는 일부를 변경 및 중단할 수 있으며, 이에 대하여 관련법에 특별한 규정 및 회사의 고의 또는 과실이 없는 한
							이용자에게 별도 보상을 하지 않습니다.
							<br /><br />
							② 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사는 이용자에게
							통지하고 당초 회사에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, 회사가 보상기준 등을 고지하지 아니한 경우에는
							이용자들의 마일리지 또는 적립금 등을 회사에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
							<br /><br />
							③ 회사는 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등을
							그 변경 전에 제21조[이용자에 대한 통지]의 방법으로 이용자에게 통지합니다.
							<br /><br />
							제 10 조 [ 정보의 제공 및 광고의 게재 ]
							<br /><br />
							① 회사는 서비스를 운영함에 있어 각종 정보를 서비스 화면에 게재하거나 E-mail, 서신우편, SMS(MMS)등으로 회원에게 제공할 수
							있습니다.
							<br /><br />
							② 회사는 서비스를 운영함에 있어 회사 또는 제휴사의 서비스 관련 각종 광고를 서비스 화면 또는 게시물과 결합하여 게재하거나
							회원의 동의를 얻어 E-mail 및 서신 우편, SMS(MMS) 등의 방법으로 회원에게 제공할 수 있습니다.
							<br /><br />
							③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는
							거래를 하는 것은 전적으로 회원과 광고주간의 문제입니다. 만약, 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가
							직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.
							<br /><br />
							④ 회사는 다음 각호에 해당하는 경우 회원의 동의여부와 상관없이 전자우편으로 발송할 수 있습니다.
							<br /><br />
							1. 이용 신청에서 입력한 전자우편 주소의 소유를 확인하기 위해서 인증메일을 발송하는 경우
							<br /><br />
							2. 회원의 정보가 변경되어 확인하기 위해서 인증메일을 발송하는 경우
							<br /><br />
							3. 기타 서비스를 제공함에 있어 회원이 반드시 알아야 하는 중대한 정보라고 회사가 판단하는 경우
							<br /><br />
							제 11 조 [ 회원 탈퇴 및 자격 상실 등 ]
							<br /><br />
							① 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시 회원탈퇴를 처리합니다.
							<br /><br />
							② 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있으며, 서비스 이용정지 시 서비스 내의
							혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.
							<br /><br />
							1. 가입 신청 시에 허위 내용을 등록한 경우
							<br /><br />
							2. 스토어를 이용하여 구입한 재화의 대금, 기타 회사 서비스 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
							<br /><br />
							3. 다른 사람의 회사 서비스 이용을 방해하거나 그 정보를 도용하는 등 질서를 위협하는 경우
							<br /><br />
							4. 랩매니저를 이용하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
							<br /><br />
							③ 회사가 회원 자격을 제한 및 정지시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우
							회사는 회원자격을 상실시킬 수 있습니다.
							<br /><br />
							제 12 조 [ 서비스 이용의 제한 및 정지 ]
							<br /><br />
							① 회사는 이용자가 본 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우 서비스 이용을 제한하거나 정지할 수
							있습니다.
							<br /><br />
							② 회사는 전항에도 불구하고 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해,
							정보통신망 이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신, 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이
							관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 서비스 내의 혜택 및 권리 등도
							모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.
							<br /><br />
							③ 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우, 회사는 이용자에게
							통지하고 서비스를 종료할 수 있습니다.
							<br /><br />
							④ 스토어를 통해 후불결제(나중에 결제) 서비스를 이용하는 고객이 월별 구매가능금액을 초과한 경우, 후불결제(나중에 결제)
							서비스 이용이 제한됩니다.
							<br /><br />
							⑤ 스토어를 통해 후불결제(나중에 결제) 서비스를 이용한 고객이 지난 달 정산금액을 월 말까지 납부하지 않은 경우, 다음 달 1일
							부터 후불결제(나중에 결제) 서비스 이용이 제한됩니다.
							<br /><br />
							제 13 조 [ 서비스 이용요금 등의 계산 ]
							<br /><br />
							① 회사가 제공하는 서비스 이용과 관련하여 이용자가 납부하여야 할 서비스 이용요금 및 납부방법은 홈페이지에 게재한 바에
							따릅니다.
							<br /><br />
							② 서비스 이용요금은 선불결제를 원칙으로 합니다. 단 회사는 이용자와 합의하여 서비스 이용요금 결제 이전에 미리 서비스를
							제공할 수 있습니다.
							<br /><br />
							③ 이용자가 서비스 이용 도중 연구실 이용자 증원 등 서비스를 추가하는 경우, 추가요금은 사용 기간 잔여일에 따라 일할
							계산됩니다.
							<br /><br />
							④ 서비스 이용 도중 요금과금 기준이 변경되더라도 이미 결제가 완료된 서비스 이용분에 대하여 증감된 서비스 이용요금을 추가로
							청구하거나 환불하지 않습니다.
							<br /><br />
							⑤ 회사는 판매 촉진, 이용자 유치 등의 목적을 위해 회사의 정책에 따라 가격 할인, 이용기간 연장 등 프로모션에 따른 혜택을
							제공할 수 있습니다. 이용자의 서비스 신청 내용이 회사의 프로모션 제공 조건에 부합하지 않는 경우 회사는 프로모션에 따른
							혜택을 제공하지 않을 수 있으며, 회사는 그에 대한 어떠한 책임도 부담하지 않습니다.
							<br /><br />
							⑥ 서비스 이용요금에 대하여 회사와 이용자 간에 별도 약정이 있는 경우, 당해 약정 내용이 본 약관에 우선하여 적용됩니다.
							<br /><br />
							⑦ 회사가 제공하는 인증 방법을 통해 교육기관 인증을 거친 이용자는 서비스 이용요금을 면제받습니다.
							<br /><br />
							제 14 조 [ 서비스 이용요금 등의 지급 ]
							<br /><br />
							① 이용자는 다음 각 호의 방법 중 가능한 방법으로 서비스 이용요금 및 스토어에서 구매한 재화에 대한 대금 지급을 할 수
							있습니다.
							<br /><br />
							1. 세금계산서 발행, 계좌이체
							<br /><br />
							2. 신용카드 결제: 신용카드를 사전 등록하여 주문 시 결제를 하는 바로 결제와
							<br /><br />
							주문 내역을 선택하여 후불로 결제할 수 있는 나중에 결제 기능 제공
							<br /><br />
							② 이용자는 서비스 이용을 위한 서비스 이용요금 결제 시 회사가 정하는 범위 내에서 결제 수단과 결제 방식을 선택할 수
							있습니다. 단, 회사는 직접적으로 전자결제대행 결제방식을 제공하지 아니하므로, 전자결제대행 방식과 관련하여 회사는
							면책됩니다.
							<br /><br />
							③ 스토어에서 구매한 재화는 즉시 결제와 후불 결제가 가능하며, 후불 결제는 당월 주문 내역에 대해 차월말까지 결제 완료를
							원칙으로 합니다. 결제일이 지연될 경우 주문에 제한이 있을 수 있으며, 손실 비용에 대해 이용자에게 청구될 수 있습니다.
							<br /><br />
							④ 이용자는 서비스 이용요금을 결제함에 있어 다음에 기재한 사유가 발생하지 않도록 유의하여야 합니다.
							<br /><br />
							1. 이용자 본인 외에 결제수단 사용을 금지합니다.
							<br /><br />
							2. 이용자는 결제수단의 비밀번호, 인증서 등의 유출로 인한 부정사용 또는 위변조 사고가 발생하지 않도록 주의하여야 합니다.
							특히 이용자는 신용카드의 미서명, 관리소홀, 무단 대여나 양도 또는 담보제공으로 인한 부정사용, 불법 현금대출, 위변조 사고
							등이 발생하지 않도록 주의하여야 합니다.
							<br /><br />
							⑤ 회사는 서비스 이용요금 결제가 정상적으로 이루어지지 않은 경우 이용자의 서비스 이용을 제한할 수 있으며, 서비스 이용요금을
							미납하는 등 이용자의 귀책사유로 회사가 서비스 이용을 제한한 경우 이용자에게 발생한 손해에 대해서 회사는 면책됩니다.
							<br /><br />
							⑥ 회사는 회사의 사정에 따라 결제 방식, 결제 수단을 추가, 삭제, 변경할 수 있으며, 사전에 그 사유와 적용일자를 공지합니다.
							<br /><br />
							제 15 조 [ 서비스 이용요금 등의 이의신청 ]
							<br /><br />
							① 이용자는 청구된 서비스 이용요금 등에 대하여 이의가 있는 경우 청구일로부터 6개월 이내에 이의 신청을 할 수 있습니다. 단,
							회사의 귀책사유로 과오납이 발생한 경우, 이용자는 전단의 기간에 관계없이 청구된 서비스 이용요금 등에 대하여 이의 신청을 할
							수 있습니다.
							<br /><br />
							② 회사는 제1항의 이의 신청 접수 후 2주 이내에 해당 이의신청을 타당성 여부를 조사하여 그 결과를 이용자에게 통지합니다.
							<br /><br />
							③ 부득이한 사유로 인하여 제2항에서 정한 기간 내에 이의신청결과를 통지할 수 없는 경우에는 그 사유와 재 지정된 처리기한을
							명시하여 이용자에게 통지합니다.
							<br /><br />
							제 16 조 [ 서비스 이용요금 등의 반환 및 청약철회 ]
							<br /><br />
							① 회사는 서비스 이용요금 등의 과납 또는 오납이 있을 때에는 그 과납 또는 오납된 서비스 이용요금을 반환하고, 회사의
							귀책사유로 과납 또는 오납이 발생한 경우에는 법정이율로서 적정이자를 함께 반환합니다.
							<br /><br />
							② 회사가 프로모션을 통해 서비스 이용요금을 할인한 경우 회사는 잔여금액에서 이용자가 할인 받은 금액을 공제하고 환불합니다.
							전단의 경우, 잔여금액보다 이용자가 할인 받은 금액이 더 큰 경우 이용자는 초과된 금액(=할인금액 – 잔여금액)을 회사에 즉시
							지급하여야 합니다.
							<br /><br />
							③ 이용자가 서비스 이용 개시일로부터 7일 이내에 청약 철회 의사를 회사에 통보하는 경우에 한하여, 회사는 서비스 이용요금
							전액을 환불합니다.
							<br /><br />
							④ 본 조에 따른 환불 시 회사는 송금비용, 전자결제대행 수수료 등의 사유로 잔여금액의 10% 이내 금액 또는 잔여금액이 10,000원
							이내인 경우에는 1,000원을 공제합니다. 단, 회사의 고의 또는 과실에 의한 환불의 경우에는 전단의 비용을 공제하지 않습니다.
							<br /><br />
							⑤ 회사는 본 조에 따른 환불금액을 중도 해지일을 기준으로 익월 말일까지 이용자 명의 계좌로 입금하거나, 결제대행업체에게 대금
							청구를 정지하거나 결제승인을 취소하도록 요청하는 등 환불에 필요한 조치를 이행합니다. 단, 이용자가 회사에게 부담하는
							금전지급채무가 있다거나 기타 회사에 부담하는 채무 이행을 지연하고 있을 경우, 회사는 중도해지 요금 환불을 보류 또는
							이용자의 채무 변제에 우선 충당할 수 있습니다.
							<br /><br />
							⑥ 이용자가 신용카드 결제 후 결제를 취소하는 경우 환급 금액, 환급 방법과 환급일은 해당 신용카드 정책에 따르며, 회사는 결제
							취소에 필요한 조치를 하는 것 외에 환급에 관하여 책임을 부담하지 않습니다. 단, 이용자의 결제 취소가 회사의 고의 또는 과실에
							의한 경우에는 그러하지 아니합니다.
							<br /><br />
							⑦ 회사와 재화의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른
							계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화의 공급이 늦게 이루어진 경우에는 재화을 공급받거나 재화의 공급이
							시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의
							소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
							<br /><br />
							⑧ 이용자는 스토어를 통해 재화을 배송 받은 경우, 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
							<br /><br />
							1. 이용자에게 책임 있는 사유로 재화이 멸실 또는 훼손된 경우(다만, 재화의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는
							청약철회를 할 수 있습니다)
							<br /><br />
							2. 이용자의 사용 또는 일부 소비에 의하여 재화의 가치가 현저히 감소한 경우
							<br /><br />
							3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화의 가치가 현저히 감소한 경우
							<br /><br />4. 같은 성능을 지닌 재화으로 복제가 가능한 경우 그 원본인 재화의 포장을 훼손한 경우 <br /><br />5. 주문한
							상품이 해외발주가 필요한 경우, 사전에 이 사실을 고지 받고 구매에 동의했을 경우 <br /><br />
							6. 드라이아이스 패키징 상품을 구매할 경우
							<br /><br />
							⑨ 제8항 제2호 또는 제4호의 경우에 회사가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나
							시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
							<br /><br />
							⑩ 이용자는 제7항 및 제8항의 규정에 불구하고 재화의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해
							재화을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
							<br /><br />
							⑪ 회사는 스토어 이용자로부터 재화을 반환 받은 경우 3영업일 이내에 이미 지급받은 재화의 대금을 환급합니다. 이 경우 회사가
							이용자에게 재화의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」
							제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
							<br /><br />
							⑫ 회사는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화의 대금을 지급한 때에는 지체 없이
							당해 결제수단을 제공한 사업자로 하여금 재화의 대금의 청구를 정지 또는 취소하도록 요청합니다.
							<br /><br />
							⑬ 청약철회 등의 경우 공급받은 재화의 반환에 필요한 비용은 이용자가 부담합니다. 회사는 이용자에게 청약철회 등을 이유로
							위약금 또는 손해배상을 청구하지 않습니다. 다만 재화의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회
							등을 하는 경우 재화의 반환에 필요한 비용은 회사가 부담합니다.
							<br /><br />
							⑭ 이용자가 재화을 제공받을 때 발송비를 부담한 경우에 회사는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록
							명확하게 표시합니다.
							<br /><br />
							제 17 조 [ 계약해지 ]
							<br /><br />
							① 이용자는 이용계약을 해지 하고자 할 때 본인이 직접 전자우편, 전화 등의 방법을 통하여 회사에 신청하여야 합니다.
							<br /><br />
							② 회사는 전1항의 규정에 의하여 해지신청이 접수되면 즉시 이용계약을 해지합니다. 단, 별도의 채권·채무관계가 있을 경우에는
							그러하지 아니합니다.
							<br /><br />
							③ 회사는 이용자가 다음 각 호에 해당할 경우에는 이용자의 동의 없이 이용계약을 해지할 수 있으며 그 사실을 이용자에게
							통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 이용자의 귀책사유로 인하여 통지할 수 없는 경우에는
							지체 없이 사후 통지로 대체 할 수 있습니다.
							<br /><br />
							1. 이용자가 제23조 [이용자의 의무]를 포함한 본 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우
							<br /><br />
							2. 회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우
							<br /><br />
							3. 제12조 [서비스 이용의 제한 및 정지] 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우
							<br /><br />
							4. 회사의 이용요금 등의 납입청구에 대하여 이용자가 이용요금을 체납할 경우
							<br /><br />
							제 18 조 [ 개인정보보호 의무 ]
							<br /><br />
							① 회사는 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계 법령이 정하는 바에 따라 이용자의
							개인정보를 보호하기 위해 노력합니다.
							<br /><br />
							② 개인정보의 보호 및 이용에 대해서는 관련법 및 회사의 개인정보 처리방침이 적용됩니다. 다만, 회사의 공식 사이트와
							어플리케이션 이외의 링크된 사이트에서는 회사의 개인정보 처리방침이 적용되지 않습니다.
							<br /><br />
							③ 회사가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2) 개인정보를 제공받는 자의
							개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고
							동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
							<br /><br />
							④ 회사가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2)
							개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도
							같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및
							정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 처리방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도
							됩니다.
							<br /><br />
							⑤ 회사는 적법하고 공정한 수단에 의하여 계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.
							<br /><br />
							⑥ 회사는 개인정보의 수집 시 관련법규에 따라 개인정보 처리방침에 그 수집범위 및 목적을 사전 고지합니다.
							<br /><br />
							⑦ 회사는 서비스 화면에서 회사가 수집한 개인정보의 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취해야
							합니다.
							<br /><br />
							제 19 조 [ 이용자의 아이디 및 비밀번호의 관리에 대한 의무 ]
							<br /><br />
							① 이용자의 아이디와 비밀번호에 관한 관리책임은 이용자에게 있으며 이를 제3자가 이용하도록 하여서는 안 됩니다.
							이용자(관리자, 구성원 포함)의 고의 또는 관리소홀 등의 과실로 인하여 아이디 또는 비밀번호가 유출되어 발생한 제3자의 도용,
							부정사용 등에 대해서 회사는 책임을 지지 않습니다.
							<br /><br />
							② 이용자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에
							따라야 하며, 회사에 제3자 도용, 부정사용 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에
							대해서 회사는 책임을 지지 않습니다.
							<br /><br />
							③ 회사는 이용자의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 또는 회사의 운영자로
							오인할 우려가 있는 경우 해당 아이디의 활용을 제한할 수 있습니다.
							<br /><br />
							제 20 조 [ 이용자의 정보 변경 ]
							<br /><br />
							① 이용자는 계정설정 화면을 통하여 언제든지 비밀번호를 수정할 수 있습니다. 다만, 아이디 및 LAB 정보 등 연구원과 연구실
							계정에 관한 정보(이하 "계정 정보")는 회사의 별도 승낙이 없는 한 수정이 불가능합니다.
							<br /><br />
							② 이용자는 계정 정보를 변경하려는 경우 유선연락 및 전자우편을 통하여 변경사항을 고지하여야 하며, 변경사항 미수정 또는
							변경사항 미통지로 인하여 발생한 이용자의 불이익에 대해서는 회사는 책임을 지지 않습니다.
							<br /><br />
							제 21 조 [ 이용자에 대한 통지 ]
							<br /><br />
							① 회사는 본 약관에 별도 규정이 없는 한 이용자가 등록한 전자우편주소, 휴대전화번호로 이메일, 문자메시지 등 기타 통신수단을
							이용하여 이용자에게 통지할 수 있습니다. 이용자가 전자우편주소, 휴대전화번호 정보를 허위로 제출 또는 등록하거나, 변경된
							정보를 수정 또는 회사에 고지하지 않은 경우 회사는 이용자가 사전에 제출 또는 등록한 정보로 발송한 때에 이용자에게 도달한
							것으로 합니다.
							<br /><br />
							② 회사는 전체에 대한 통지의 경우 7일 이상 서비스 공지사항에 게시함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, 이용자의
							거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
							<br /><br />
							제 22 조 [ 회사의 의무 ]
							<br /><br />
							① 회사는 관련법과 본 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기
							위하여 최선을 다하여 노력합니다.
							<br /><br />
							② 회사는 이용자가 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며
							개인정보 처리방침을 공시하고 준수합니다.
							<br /><br />
							③ 회사는 서비스 제공과 관련하여 알고 있는 이용자의 개인정보를 이용자의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만,
							관계법령에 의한 관계기관으로부터의 요청 등 법률의 규정에 따른 적법한 절차에 의한 경우에는 그러하지 않습니다.
							<br /><br />
							④ 회사는 이용자에게 제공하는 서비스를 연중 1일 24시간 계속적이고 안정적으로 제공하며, 설비에 장애가 생기거나 멸실 되었을
							때 지체 없이 이를 수리 또는 복구합니다. 다만, 회사의 업무상·기술상의 사유 또는 천재지변이나 비상사태 등 부득이한 경우에는
							서비스를 일시 중단하거나 영구 중지할 수 있으며, 이러한 경우, 회사는 홈페이지·어플리케이션 게시, 유선연락, 문자메시지,
							전자우편을 통해 사전 또는 사후적으로 이를 고지합니다.
							<br /><br />
							⑤ 회사는 이용자로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시 처리하여야 합니다. 다만, 즉시 처리가 곤란한
							경우는 이용자에게 그 사유와 처리일정을 전자메일 등으로 통보하여야 합니다.
							<br /><br />
							제 23 조 [ 이용자의 의무 ]
							<br /><br />
							① 회사의 개인정보방침의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
							<br /><br />
							② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
							<br /><br />
							③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가
							있는 경우에는 그에 따라야 합니다.
							<br /><br />
							④ 이용자는 서비스 이용의 대가로 회사가 정한 서비스 이용요금을 지정된 일자를 준수하여 납입하여야 합니다.
							<br /><br />
							⑤ 이용자는 다음 행위를 하여서는 안 됩니다.
							<br /><br />
							1. 서비스 이용 신청 또는 서비스 내용 변경 시 허위내용을 등록하는 행위
							<br /><br />
							2. 타인의 정보나 명의를 도용하거나 부정하게 사용하는 행위
							<br /><br />
							3. 다른 이용자의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
							<br /><br />
							4. 회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위
							<br /><br />
							5. 회사 또는 제3자의 명예를 손상시키거나 업무를 방해하는 행위
							<br /><br />
							6. 회사의 동의 없이 본 약관의 목적 범위를 벗어나 영리를 목적으로 서비스를 이용하는 행위
							<br /><br />
							7. 기타 불법적이거나 부당한 행위
							<br /><br />
							⑥ 이용자는 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여, 담보로 제공하는
							등 기타 이와 유사한 행위를 할 수 없습니다.
							<br /><br />
							⑦ 이용자는 서비스 이용 관련하여 보유목록, 구매·결재 등의 각종 데이터 등에 대해 별도로 상시 저장할 의무가 있으며, 이를
							소홀히 하여 발생한 정보의 유출, 누락 또는 자료의 손실에 대해 회사는 책임을 지지 않습니다.
							<br /><br />
							⑧ 이용자는 관계법, 본 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야
							하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
							<br /><br />
							⑨ 이용자는 다음 행위를 하여서는 안 됩니다.
							<br /><br />
							1. 신청 또는 변경시 허위 내용의 등록
							<br /><br />
							2. 타인의 정보 도용
							<br /><br />
							3. 회사 스토어에 게시된 정보의 변경
							<br /><br />
							4. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
							<br /><br />
							5. 회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해
							<br /><br />
							6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
							<br /><br />
							7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위
							<br /><br />
							제 24 조 [ 불만처리 ]
							<br /><br />
							① 회사는 개인정보와 관련하여 이용자의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련하여야 합니다.
							<br /><br />
							② 회사는 전화 및 전자우편을 통하여 접수된 이용고객의 불만사항을 처리 하여야 합니다.
							<br /><br />
							제 25 조 [ 서비스 등의 보호 ]
							<br /><br />
							① 이용자는 랩매니저를 무단 복제하거나 고의 또는 과실로 랩매니저 및 서비스에 관한 정보를 외부에 유출할 수 없습니다.
							<br /><br />
							② 이용자는 이용계약 도중 또는 종료 후라고 하더라도 랩매니저 또는 랩매니저와 유사한 솔루션의 제작/판매행위를 할 수 없으며,
							제3자를 통한 우회적 제작/판매행위도 이용자 본인의 행위로 간주합니다.
							<br /><br />
							③ 이용자는 전 1항 또는 2항을 위반할 경우, 민·형사상의 모든 법적 책임을 부담합니다.
							<br /><br />
							제 26 조 [ 데이터의 보존 및 파기 ]
							<br /><br />
							① 이용자는 서비스 이용 중 데이터를 별도로 상시 백업∙저장하여야 하며, 회사가 이용자의 데이터를 백업하거나 이용자에게 제공할
							의무는 없습니다. 이용자가 전단의 의무를 소홀히 하여 발생한 데이터의 유출, 누락이나 손실에 대해서 회사는 책임을 부담하지
							않습니다.
							<br /><br />
							② 서비스 이용기간 만료, 서비스 이용계약의 해제 또는 해지 등의 사유로 회사가 서비스 제공을 종료한 경우 회사는 관련법 및
							개인정보처리방침에 따라 보존하는 경우를 제외하고는 관리자, 구성원의 개인정보, 이용자 정보 등 회사가 보관하는 연구실의
							정보를 6개월 간 보존 후 파기합니다. 다만, 이름, 생년월일, 성별, 아이디, 연락처, 주소 등 서비스 이용에 혼란 및 부정사용을
							방지하기 위하여 필요한 확인정보는 이용기간 만료 이후에도 개인정보 처리방침에 따라 일정기간 보관합니다.
							<br /><br />
							③ 이용자의 서비스 이용요금 결제정보는 이력확인을 위해 이용자의 해지 유무와 상관없이 관계법령에 따라 일정기간 동안 별도로
							보관할 수 있습니다.
							<br /><br />
							제 27 조 [ 게시물의 지적재산권 ]
							<br /><br />
							① 이용자는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 게재된 데이터를 상업적으로 이용할 수 없으며 이를 위반하여
							발생하는 제반 문제에 대한 책임은 이용자에게 있습니다.
							<br /><br />
							② 회사가 작성한 저작물에 대한 저작권 기타 일체의 지적재산권은 회사에 귀속합니다.
							<br /><br />
							③ 회사는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
							<br /><br />
							제 28 조 [ 손해배상의 범위 및 청구 ]
							<br /><br />
							① 회사의 귀책사유로 이용자가 서비스를 이용하지 못한 경우에는 다음 각 호의 기준에 따라 배상합니다. 다만, 이용자가 서비스를
							이용하지 못한 때로부터 2시간 이내에 서비스가 정상화 된 경우는 제외합니다.
							<br /><br />
							1. 회사는 회사의 귀책사유로 사전 고지 없이 이용자가 서비스를 이용하지 못한 시간이 연속하여 2시간 이상인 경우 또는 이용자가
							서비스를 이용하지 못하는 사실을 회사에 통보하거나 회사가 알게 된 때로부터 연속하여 2시간 이상인 경우 이용자가 입은 손해를
							배상합니다.
							<br /><br />
							2. 서비스가 정상화 된 이후 1시간 이내에 동일한 장애가 발생한 경우 이는 연속된 장애로 간주합니다.
							<br /><br />
							3. 회사는 이용자가 서비스를 이용하지 못한 일수의 3배의 이용기한을 무료로 연장하여 제공합니다. 단, 연속하여 2시간 이상
							서비스를 이용하지 못한 날을 1일로 계산합니다.
							<br /><br />
							② 회사가 고의 또는 과실로 이용자에게 손해를 끼친 경우, 손해에 대하여 배상할 책임이 있습니다.
							<br /><br />
							③ 이용자가 고의 또는 과실로 회사에 손해를 끼친 경우, 이용자는 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.
							<br /><br />
							④ 회사는 그 손해가 천재지변 등 불가항력이거나 이용자의 고의 또는 과실로 인하여 발생된 때에는 손해배상을 하지 않습니다.
							<br /><br />
							⑤ 손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 전자우편, 전화 등으로 신청하여야 합니다.
							<br /><br />
							⑥ 회사 및 타인에게 피해를 주어 피해자의 고발 또는 소송 제기로 인하여 손해배상이 청구된 이용자는 회사 및 수사기관의 요청에
							적극 협조하여야 합니다.
							<br /><br />
							제 29 조 [ 면책 ]
							<br /><br />
							① 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 이용자 또는 제3자에게 발생한 손해에 대해서는 책임을
							부담하지 않습니다.
							<br /><br />
							1. 천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우
							<br /><br />
							2. 이용자의 시스템 환경이나 통신사 또는 호스팅 업체의 장애 등 회사의 관리 범위 밖의 사정으로 인한 경우
							<br /><br />
							3. 현재의 보안기술 수준으로는 방어가 곤란한 네트워크 해킹 등으로 인한 경우
							<br /><br />
							4. 서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우
							<br /><br />
							5. 이용자의 귀책사유로 서비스 이용에 장애가 있는 경우
							<br /><br />
							6. 회사의 고의 및 과실이 없는 경우
							<br /><br />
							② 회사는 이용자가 서비스를 통해 얻은 정보 또는 자료 등으로 인해 발생한 손해와 서비스를 이용하거나 이용할 것으로부터
							발생하거나 기대하는 손익 등 부가적, 영업적 손해에 대하여 책임을 면합니다.
							<br /><br />
							③ 회사는 이용자가 게시 또는 전송한 데이터의 내용에 대해서는 책임을 면합니다.
							<br /><br />
							④ 회사는 이용자 상호 간 또는 이용자와 제3자 상호 간에 서비스를 매개로 하여 거래 등을 한 경우 이에 대해 개입할 의무가
							없으며, 이로 인한 손해를 배상할 책임도 없습니다.
							<br /><br />
							⑤ 회사는 이용자가 서비스 이용 종료 후 제26조에 따라 파기된 데이터와 이용자가 직접 삭제를 요청한 데이터 기타 서비스 이용
							중인지 여부를 불문하고 백업의무를 소홀히 하여 발생한 데이터의 소실에 대해서는 책임을 지지 않습니다.
							<br /><br />
							⑥ 회사는 서비스를 통하여 이용자에게 제공된 정보, 자료, 사실의 신뢰도, 정확성 등 그 내용에 대해서는 책임을 지지 않습니다.
							<br /><br />
							⑦ 회사는 스토어 내 구매계약과 관련하여 통신판매중개자로서 랩매니저 내 스토어를 기반으로 한 거래시스템만을 제공할 뿐,
							통신판매의 당사자가 아닙니다. 랩매니저 스토어의 거래시스템을 이용한 거래 내용에 관한 모든 분쟁에 대해서는 당해 거래
							당사자가 책임을 져야 합니다.
							<br /><br />
							제 30 조 [ 분쟁조정 ]
							<br /><br />
							① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
							<br /><br />
							② 서비스 이용과 관련하여 회사와 이용자 간에 발생한 분쟁에 대해서는 회사의 주소지를 관할하는 법원을 합의관할로 합니다.
							<br /><br />
							③ 해외에 주소나 거소가 있는 이용자의 경우 회사와 이용자간 발생한 분쟁에 관한 소송은 전항에도 불구하고 대한민국
							서울중앙지방법원을 관할법원으로 합니다.
							<br /><br />
							④ 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
							설치․운영합니다.
							<br /><br />
							⑤ 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는
							이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
							<br /><br />
							⑥ 회사와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는
							시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
							<br /><br />
							* 부 칙 *
							<br /><br />
							① 본 약관은 2020년 09월 01일부터 적용됩니다.
						</p>
					</div>
				</li>
				<li :class="{ open: openedTermsNumber === '3' }">
					<div class="flex">
						<input id="terms3" type="checkbox" v-model="terms" @change="changeTerms" value="terms3" />
						<label for="terms3">[필수] 이벤트 등 혜택 알림 수신 동의</label>
						<input type="radio" v-model="openedTermsNumber" value="3" @click="closeTerms('3')" />
					</div>
					<div class="box">
						<h6>마케팅 정보 수신 동의</h6>
						<p>
							㈜스마트잭은 개인정보보호법 및 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령 에 따라 광고성 정보를 전송하기
							위해 수신자의 사전 수신동의를 받고 있으며, 광고성 정보 수신자의 수신동의여부를 정기적으로 확인합니다. <br /><br />
							<b>전송방법</b>
							고객의 핸드폰 문자메시지(SMS), E-mail 등을 통해 전달될 수 있습니다.
							<br /><br />
							<b>전송내용</b>
							발송되는 마케팅 정보는 수신자에게 LAB Manager와 관련하여 제공하는 정보, 각종 이벤트 정보 등 광고성 정보로 관련 법의 규정을
							준수하여 발송됩니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.
							<br /><br />
							<b>철회안내</b>
							고객님은 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지 않아도 회사가 제공하는 기본적인
							서비스를 이용할 수 있으나, 당사의 마케팅 정보를 수신하지 못할 수 있습니다.
							<br /><br />
							<b>수신동의 변경</b>
							LAB Manager 고객센터 전화(1533-3061) 및 E-mail(smartjack@smartjackwp.com)을 통해 수신동의를 변경(동의/철회)할 수
							있습니다.
						</p>
					</div>
				</li>
			</ul>
		</div>
		<div class="next-button-wrapper">
			<button class="btn Btn__complete" @click="onClickNextButton" :disabled="isNextButtonDisabled">다음</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SellerTermsAgree',
	data() {
		return {
			isAllChecked: false,
			terms: [],
			openedTermsNumber: '0',
		}
	},
	computed: {
		isNextButtonDisabled() {
			if (this.terms.length === 3) return false
			return true
		},
	},
	methods: {
		changeAllTerms() {
			if (this.isAllChecked) {
				this.terms = ['terms1', 'terms2', 'terms3']
			} else {
				this.terms = []
			}
		},
		changeTerms() {
			if (this.terms.length === 3) {
				this.isAllChecked = true
			} else {
				this.isAllChecked = false
			}
		},
		closeTerms(termsNumber) {
			if (this.openedTermsNumber === termsNumber) {
				this.openedTermsNumber = '0'
			}
		},
		onClickNextButton() {
			this.$emit('complete')
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-terms-agree-wrapper {
	.terms-agree-title {
		display: flex;
		justify-content: center;
		p {
			font-size: 1.5rem;
			font-weight: 700;
		}
	}

	.terms-agree {
		display: flex;
		justify-content: flex-start;
		margin-top: 30px;

		ul {
			text-align: left;
			li {
				border-bottom: 1px solid #eee;
				padding: 12px 10px;

				input[type='checkbox'] {
					margin-right: 10px;
					&:hover {
						&:after {
							border: 1px solid #0075c5;
						}
					}
					&::after {
						width: 20px;
						height: 20px;
						border-radius: 20px;
						margin-left: -3px;
						display: block;
						top: -2px;
					}
					&:checked {
						&:before {
							display: none;
						}
					}
					&:checked {
						&::after {
							content: '';
							width: 20px;
							height: 20px;
							background-image: url(~@/assets/svg/app/add-member-plus-checked.svg);
							background-repeat: no-repeat;
							background-position: center;
							background-size: 20px;
							background-color: #0075c5;
							border-radius: 20px;
							margin-left: -2.5px;
							border-color: #0075c5;
						}
					}
				}
				label {
					color: #000;
					font-size: 0.875rem;
					font-weight: 400;
					margin-top: 4px;
					cursor: pointer;
				}
				.flex {
					align-items: center;
					input[type='radio'] {
						margin-left: auto;
						position: relative;
						margin-right: 12px;
						cursor: pointer;
						transition: 0.5s;
						&:before {
							position: absolute;
							top: -2px;
							left: -2px;
							content: '';
							width: 20px;
							height: 20px;
							background-color: #fff;
						}
						&:after {
							background-image: url(~@/assets/svg/app/arrow-down_b.svg);
							background-repeat: no-repeat;
							background-position: center;
							background-size: 20px;
							background-color: #fff;
							z-index: 1;
							position: absolute;
							top: -2px;
							left: -2px;
							content: '';
							width: 20px;
							height: 20px;
							opacity: 0.6;
							transition: 0.5s;
						}
					}
				}

				&.open {
					.box {
						max-height: 200px;
						padding: 0 20px;
						margin: 20px 0 10px;
					}
					input[type='radio'] {
						&:checked {
							&:after {
								transform: rotate(180deg);
							}
						}
					}
				}
				.box {
					width: 100%;
					padding: 20px;
					overflow-y: auto;
					transition: all 0.5s;
					max-height: 0;
					padding: 0;
					margin: 0;
					h6 {
						font-size: 0.75rem;
						font-weight: 700;
					}
					p {
						font-size: 0.75rem;
						font-weight: 400;
					}
				}
			}
		}
	}
	.next-button-wrapper {
		margin-top: 30px;
		button {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: center;
		}
	}
}
</style>
