<template>
	<div class="settlement-wrapper">
		<div class="settlement-header">
			<div class="header-wrapper">
				<h4 class="header-title">정산관리</h4>
			</div>
		</div>
		<div class="settlement-filter-wrapper">
			<status-counter-list :list="statusList" @active="onClickStatus" />
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="정산번호, 업체 정보로 검색"
				:datePlaceholder="datePlaceholder"
				:filters="filters"
				:datePickerList="datePickerList"
				@searchTextList="searchTextList"
				@searchCheckList="searchCheckList"
			/>
		</div>
		<div class="settlement-list-wrapper">
			<div class="title-btn-wrapper">
				<search-title
					:title="`정산 목록(${totalCount})`"
					subTitle="상품을 선택 한후 아래 정산 상태를 클릭하여 정산상태를 변경할 수 있습니다."
				/>
				<div class="btn-wrapper">
					<button class="btn" @click="onClickDownloadSettlementList">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
				</div>
			</div>
			<order-status-changer :selectedList="checkedList" @change="settlementManagementCreated" />
			<div class="table-wrapper">
				<settlement-table />
			</div>
			<div class="pagenation-wrapper">
				<common-pagenation @page="getCurrentPage" :total="totalCount" :defaultOffset="50"></common-pagenation>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import PageName from '@/utils/define/PageName'
import SettlementTable from '../components/Table.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'SettlementManagement',
	components: {
		StatusCounterList,
		DetailSearchBox,
		OrderStatusChanger,
		SearchTitle,
		CommonPagenation,
		SettlementTable,
	},
	data() {
		return {
			selectedItemList: [],
			selectedStatus: 'ALL',
			checkedList: [],
			totalCount: 0,
			listParams: {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				sellers: [],
				orderStartDatetime: undefined,
				orderEndDatetime: undefined,
				estimatedReleaseStartDatetime: undefined,
				estimatedReleaseEndDatetime: undefined,
				releaseStartDatetime: undefined,
				releaseEndDatetime: undefined,
			},
			filters: {
				'판매자 정보': [],
			},
			statusList: [],
			datePickerList: [
				{
					text: '청구일',
				},
				{
					text: '확인일',
				},
				{
					text: '송금일',
				},
			],
		}
	},
	computed: {
		...mapState('settlement/list', ['settlementList', 'settlementListSummary', 'settlementFilter']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
	},
	created() {
		this.settlementManagementCreated()
	},
	methods: {
		...mapActions('settlement/list', ['lookupSettlementList', 'lookupSettlementListSummary', 'lookupSettlementListFilter']),
		settlementManagementCreated() {
			this.loadListData()
			this.loadSummaryData()
			this.setFilters()
		},
		async loadListData() {
			await this.lookupSettlementList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			await this.lookupSettlementListSummary()
			this.setSummaryData()
		},
		setData() {
			this.totalCount = this.settlementList.count
		},
		setSummaryData() {
			this.statusList = Object.keys(this.settlementListSummary.summary).map(summary => {
				return {
					id: summary,
					// title: this.getStatusTitle(summary),
					title: summary,
					count: this.settlementListSummary.summary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		getStatusTitle(key) {
			const result = {
				ALL: '전체',
				APPROVE: '승인 완료',
				CANCEL: '주문취소',
				SHIPPING_COMPLETE: '배송완료',
				SHIPPING_ING: '배송중',
				WAIT: '승인 대기',
			}[key]

			return result
		},
		async setFilters() {
			await this.lookupSettlementListFilter()

			this.filters['판매자 정보'] = []
			this.settlementFilter.filter.sellers.forEach(item => {
				this.filters['판매자 정보'].push({ name: item.name, value: item.id })
			})
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			const periodType = {
				BILLING_DATE: '기간 조회/청구일',
				CONFIRM_DATE: '기간 조회/확인일',
				REMITTANCE_DATE: '기간 조회/송금일',
			}

			this.listParams.orderStartDatetime = filters[periodType.BILLING_DATE]?.length ? filters[periodType.BILLING_DATE][0].value[0] : undefined
			this.listParams.orderEndDatetime = filters[periodType.BILLING_DATE]?.length ? filters[periodType.BILLING_DATE][0].value[1] : undefined
			this.listParams.estimatedReleaseStartDatetime = filters[periodType.CONFIRM_DATE]?.length
				? filters[periodType.CONFIRM_DATE][0].value[0]
				: undefined
			this.listParams.estimatedReleaseEndDatetime = filters[periodType.CONFIRM_DATE]?.length
				? filters[periodType.CONFIRM_DATE][0].value[1]
				: undefined
			this.listParams.releaseStartDatetime = filters[periodType.REMITTANCE_DATE]?.length
				? filters[periodType.REMITTANCE_DATE][0].value[0]
				: undefined
			this.listParams.releaseEndDatetime = filters[periodType.REMITTANCE_DATE]?.length
				? filters[periodType.REMITTANCE_DATE][0].value[1]
				: undefined

			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''

			this.loadListData()
		},
		searchCheckList(filters) {
			let sellers = []

			if (filters['판매자 정보']?.length) {
				filters['판매자 정보'].forEach(brand => {
					sellers.push(brand.value)
				})
			}

			this.listParams.sellers = sellers
			this.loadListData()
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		getCurrentPage(page) {
			const { offset, length } = page
			this.listParams.offset = offset
			this.listParams.length = length
			this.loadListData()
		},
		async onClickDownloadSettlementList() {
			// API 미구현
			// const response = await this.downloadOrderList(this.listParams)
			// window.fileDownload(response, '전체 주문관리 목록')
		},
	},
}
</script>

<style lang="scss" scoped>
.settlement-wrapper {
	width: 100%;
	height: 100%;
	.settlement-header {
		width: 100%;
		height: 80px;
		position: sticky;
		display: flex;
		flex-direction: column;
		z-index: 11;
		.header-wrapper {
			height: 55px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			border-bottom: 1px solid $LINE_DIVIDER;
			.header-title {
				text-align: left;
				font-weight: 700;
			}
			.guide-button {
				width: 140px;
				height: 36px;
				font-size: 0.875rem;
				font-weight: 700;
			}
		}
	}
	.settlement-filter-wrapper {
		padding: 0 30px 20px 30px;
		.search-box {
			margin-top: 33px;
		}
	}
	.settlement-list-wrapper {
		padding: 0 30px;
		.title-btn-wrapper {
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
		}
		.btn-wrapper {
			display: flex;
			gap: 10px;
		}
		.table-wrapper {
			padding-top: 10px;
			height: 300px;
			.pagenation-wrapper {
				position: relative;
				z-index: 100;
			}
		}
	}
}
</style>
