<template>
	<portal to="lm-modal">
		<div class="modal-shipping-info-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ shippingTitle }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div v-if="shippingContent" class="shipping-info-wrapper">
						<h6 v-html="shippingContent"></h6>
					</div>
					<div class="shipping-info-table-wrapper">
						<shipping-info-option-table ref="shipping-info-option-table" :orderItemList="orderItemList"></shipping-info-option-table>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import ShippingInfoOptionTable from '@/views/order/orderItem/components/ShippingInfoOptionTable.vue'
import { ToastType } from '@/utils/define/ToastType'

const EDIT_TYPE = {
	SHIPPING_INFO: 'shippingInfo',
	SHIPPING_START: 'shippingStart',
	SHIPPING_COMPLETE: 'shippingComplete',
}

export default {
	name: 'ModalShippingInfo',
	components: {
		ShippingInfoOptionTable,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		editType: {
			type: String,
			default: EDIT_TYPE.SHIPPING_INFO,
		},
		orderItemList: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		shippingTitle() {
			if (this.editType === EDIT_TYPE.SHIPPING_INFO) return '배송정보'
			else if (this.editType === EDIT_TYPE.SHIPPING_START) return '배송중으로 변경'
			else if (this.editType === EDIT_TYPE.SHIPPING_COMPLETE) return '배송완료로 변경'
		},
		shippingContent() {
			if (this.editType === EDIT_TYPE.SHIPPING_INFO) return ''
			else if (this.editType === EDIT_TYPE.SHIPPING_START)
				return `선택한 상품의 주문상태를 <span style="font-weight: 700">배송중</span>으로 변경하시겠습니까?
				<br /> 배송정보를 확인해주세요.`
			else if (this.editType === EDIT_TYPE.SHIPPING_COMPLETE)
				return `선택한 상품의 주문상태를 <span style="font-weight: 700">배송완료</span>로 변경하시겠습니까? 
				<br /> 배송정보를 확인해주세요.`
		},
	},
	methods: {
		...mapActions('order', ['updateShippingInfo', 'startShipping', 'completeShipping']),
		hide() {
			this.$emit('hide')
		},
		async complete() {
			try {
				if (!this.$refs['shipping-info-option-table'].validation()) {
					this.$root.toast('에러', '필수값을 입력해주세요', ToastType.ERROR)
					return
				}
				let response = null
				let title = ''
				let content = ''

				if (this.editType === EDIT_TYPE.SHIPPING_INFO) {
					response = await this.updateShippingInfo(this.makePayloadShippingInfo())
					title = '배송정보 변경 완료'
					content = '선택한 상품의 배송정보가 변경되었습니다.'
				} else if (this.editType === EDIT_TYPE.SHIPPING_START) {
					response = await this.startShipping(this.makePayloadOrderStatus())
					title = '주문상태 변경 완료'
					content = '배송중으로 변경되었습니다.'
				} else if (this.editType === EDIT_TYPE.SHIPPING_COMPLETE) {
					response = await this.completeShipping(this.makePayloadOrderStatus())
					title = '주문상태 변경 완료'
					content = '배송완료로 변경되었습니다.'
				}

				if (response?.status === 200 || response?.status === 205) {
					this.$root.toast(title, content, ToastType.SUCCESS)
					this.$emit('complete')
					this.hide()
				}
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		makePayloadShippingInfo() {
			const tableData = this.$refs['shipping-info-option-table'].getTableData()
			const { orderItemId, shippingMethod, shippingCompany, invoiceNumber } = tableData[0]
			const data = { orderItemId, shippingMethod, shippingCompany, invoiceNumber }
			const payload = {}
			Object.keys(data).forEach(key => {
				if (data[key] !== null && (data[key].length || data[key] > 0)) payload[key] = data[key]
			})
			return payload
		},
		makePayloadOrderStatus() {
			const tableData = this.$refs['shipping-info-option-table'].getTableData()
			return {
				orderItems: tableData.map(({ orderItemId: id, shippingMethod, shippingCompany, invoiceNumber }) => {
					const data = { id, shippingMethod, shippingCompany, invoiceNumber }
					const payload = {}
					Object.keys(data).forEach(key => {
						if (data[key] !== null && (data[key].length || data[key] > 0)) payload[key] = data[key]
					})
					return payload
				}),
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-shipping-info-wrapper {
	.Popup__container {
		.Popup__content {
			width: 1200px;
			.shipping-info-wrapper {
				margin-bottom: 20px;
			}
			.shipping-info-table-wrapper {
				max-height: 450px;
			}
		}
	}
}
</style>
