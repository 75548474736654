<template>
	<div class="donut-chart" :class="`size-${size}`" :style="`${ratioBackground}`">
		<div class="chart-center">
			<span :class="{ active: countRatio == 100 }">{{ countRatio }}%</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'DonutChart',
	props: {
		allCount: {
			type: Number,
			default: 0,
		},
		itemCount: {
			type: Number,
			default: 0,
		},
		size: {
			type: String,
			default: 'sm',
		},
	},
	computed: {
		countRatio() {
			return Math.round((this.itemCount / this.allCount) * 100)
		},
		ratioBackground() {
			return `background: conic-gradient(#00A9E5 0deg ${this.deg}, $COLOR_EEE ${this.deg} 360deg);`
		},
		deg() {
			return `${(this.countRatio / 100) * 360}deg`
		},
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.donut-chart {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	&.size-md {
		width: 60px;
		height: 60px;
		.chart-center {
			top: 6px;
			left: 6px;
			width: calc(100% - 12px);
			height: calc(100% - 12px);
			span {
				font-size: 1rem;
				font-weight: 700;
			}
		}
	}
	.chart-center {
		background-color: white;
		position: absolute;
		top: 4px;
		left: 4px;
		width: calc(100% - 8px);
		height: calc(100% - 8px);
		border-radius: 50%;
		span {
			text-align: center;
			width: 100%;
			font-size: 0.675rem;
			font-weight: 400;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			&.active {
				font-weight: 700;
				color: $PRIMARY_YELLOW;
			}
		}
	}
}
</style>
