<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ViewOptionPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">주소 {{ title }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="address-name-wrapper">
						<p>주소 이름</p>
						<div>
							<input
								type="text"
								v-model="addressInfo.name"
								:class="{ error: !isValid.name }"
								placeholder="주소 이름을 입력해주세요"
								@input="isValid.name = true"
							/>
							<div v-if="!isValid.name" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.name }}</span>
							</div>
						</div>
					</div>
					<div class="address-wrapper">
						<p>주소</p>
						<div class="address">
							<input
								type="text"
								v-model="addressInfo.address"
								:class="{ error: !isValid.address }"
								placeholder="주소를 검색해 주세요"
								disabled
							/>
							<button class="btn" @click="searchAddress">주소 검색</button>
						</div>
						<div>
							<input
								type="text"
								v-model="addressInfo.addressDetail"
								:placeholder="'상세주소를 입력해 주세요'"
								:class="{ error: !isValid.addressDetail }"
								@input="$emit('update:addressDetail', $event.target.value), (isValid.addressDetail = true)"
							/>
							<div v-if="!isValid.address || !isValid.addressDetail" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.address }}</span>
							</div>
						</div>
						<div v-if="!isDefaultAddress" class="address-default">
							<input id="defaultAddress" type="checkbox" v-model="addressInfo.isSetDefaultAddress" />
							<label for="defaultAddress">기본 주소로 설정</label>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import MixinModal from '@/mixins/modal'
import { addressFinderOpen } from '@/services/api/addressFinder'
import { ToastType } from '@/utils/define/ToastType'
const MODAL_TYPE = {
	REGIST: 'regist',
	EDIT: 'edit',
}

export default {
	name: 'ModalAddressManagement',
	mixins: [MixinModal],
	data() {
		return {
			type: MODAL_TYPE.REGIST,
			addressInfo: {
				id: 0,
				name: '',
				address: '',
				addressDetail: '',
				isSetDefaultAddress: false,
			},
			isValid: {
				name: true,
				address: true,
				addressDetail: true,
			},
			inValidText: {
				name: '',
				address: '',
			},
			isDefault: false,
		}
	},
	computed: {
		isDefaultAddress() {
			return this.type === MODAL_TYPE.EDIT && this.isDefault
		},
		title() {
			switch (this.type) {
				case MODAL_TYPE.REGIST:
					return '등록'
				case MODAL_TYPE.EDIT:
					return '수정'
				default:
					return ''
			}
		},
	},
	methods: {
		...mapActions('seller/list', ['registAddress', 'updateAddress']),
		async show(type = MODAL_TYPE.REGIST, address) {
			Object.assign(this.$data, this.$options.data.call(this))
			this.isShow = true
			this.type = type

			if (this.type === MODAL_TYPE.EDIT) {
				this.addressInfo = this.$_.cloneDeep(address)
				this.isDefault = (this.addressInfo?.isSetDefaultAddress || this.addressInfo.isDefault) ?? false
			}
		},
		async searchAddress() {
			this.isValid.address = true
			const localeMode = 'K'
			const { address, bname } = await addressFinderOpen(window, localeMode)
			this.addressInfo.address = `${address}(${bname})`
		},
		async complete() {
			try {
				if (!this.validate()) return
				if (this.type === MODAL_TYPE.REGIST) {
					const body = {
						name: this.addressInfo.name,
						address: this.addressInfo.address,
						addressDetail: this.addressInfo.addressDetail,
						isDefault: this.addressInfo.isSetDefaultAddress,
					}
					await this.registAddress(body)
				} else if (this.type === MODAL_TYPE.EDIT) {
					const body = {
						id: this.addressInfo.id,
						name: this.addressInfo.name,
						address: this.addressInfo.address,
						addressDetail: this.addressInfo.addressDetail,
						isDefault: this.addressInfo.isSetDefaultAddress,
					}
					await this.updateAddress(body)
				}
				this.hide()
				this.$emit('complete')
				this.$root.toast(`주소 ${this.title} 완료`, `주소를 ${this.title}했습니다.`, ToastType.SUCCESS)
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			let isValid = true
			if (!this.addressInfo.name.length) {
				this.isValid.name = false
				this.inValidText.name = '주소 이름을 입력해주세요.'
				isValid = false
			}
			// 주소
			if (!this.addressInfo.address.length) {
				this.isValid.address = false
				this.inValidText.address = '주소를 입력해주세요.'
				isValid = false
			}
			// 상세주소
			if (!this.addressInfo.addressDetail.length) {
				this.isValid.addressDetail = false
				this.inValidText.address = '주소를 입력해주세요.'
				isValid = false
			}

			return isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__content {
	input[type='text'] {
		width: 100%;
		height: 30px;
		padding: 12px;
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;
		&.error {
			border-color: $PRIMARY_RED;
		}
	}
	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
	.address-name-wrapper {
		p {
			font-size: 0.75rem;
		}
		div {
			margin-top: 8px;
		}
	}

	.address-wrapper {
		margin-top: 20px;
		p {
			font-size: 0.75rem;
		}
		div {
			margin-top: 8px;
		}
		.address {
			display: flex;
			align-items: center;

			button.btn {
				width: 100px;
				margin-left: 8px;
				display: flex;
				justify-content: center;
			}
		}

		.address-default label {
			font-size: 0.875rem;
			margin-left: 10px;
		}
	}
}
</style>
