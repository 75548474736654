<template>
	<b-modal ref="modal" id="modal-product-request-management" :title="title" centered size="lg" ok-only ok-title="닫기" @show="onShow">
		<div class="content-wrap">
			<div class="catalog-detail-wrapper">
				<div class="catalog-info">
					<img :src="item.brand?.image ? item.brand?.image : require('@/assets/svg/common/no-image.svg')" />
					<div>
						<div class="info-title">
							<h6>{{ item.name }}</h6>
							<h5>{{ commaNum(minUnitPrice) }}원~</h5>
						</div>
						<p>{{ item.subname }}</p>
						<div class="info-option">
							<span v-for="(option, index) in setItemInfo(item)" :key="`item-option_${index}`">
								{{ option.title }}: {{ isData(option.value) }}
							</span>
						</div>
					</div>
				</div>
				<div>
					<line-tab
						:list="tabs"
						:value="catalogDetailSelectTab"
						:customStyle="{ borderTop: 'none' }"
						@change="catalogDetailSelectTab = $event"
					/>
					<table class="lm-table">
						<thead>
							<tr class="table-header">
								<th>
									<span>판매자</span>
								</th>
								<th>
									<span>SKU</span>
								</th>
								<th>
									<span>옵션</span>
								</th>
								<th>
									<span>재고</span>
								</th>
								<th>
									<span>가격</span>
								</th>
								<th>
									<span>배송정보</span>
								</th>
								<th>
									<span>상품 추가</span>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="product of productList" :key="product.productId" class="table-item">
								<td>
									<div>
										<img class="seller-image" :src="item.seller?.image ?? require('@/assets/images/no-image.png')" />
									</div>
								</td>
								<td>
									<div>{{ product.sku }}</div>
								</td>
								<td>
									<div style="white-space: pre-wrap">{{ convertDisplayOption(product.options) }}</div>
								</td>
								<td>
									<div>{{ product.stock }}개</div>
								</td>
								<td>
									<div :class="{ lowest: minUnitPrice === product.unitPrice }">{{ commaNum(product.unitPrice) }}원</div>
								</td>
								<td>
									<div class="info-wrap">
										<p v-if="!item.shippingDayPolicy" style="color: #999">판매자 문의필요</p>
										<template v-else-if="item.shippingDayPolicy === 'DEFAULT'">
											<p class="info-title">무료배송</p>
											<p class="info-sub">
												<img v-if="item.shippingDay <= 3" src="@/assets/svg/common/icon_watch.svg" />
												<span :class="{ error: item.shippingDay <= 3 }"> 약 {{ item.shippingDay }}일 이내 출고예정 </span>
											</p>
										</template>
										<template v-else-if="item.shippingDayPolicy === 'SHAK'">
											<p class="info-title">
												<img style="margin-right: 7px" src="@/assets/svg/common/icon-thunder-truck.svg" />
												<img src="@/assets/svg/common/shackshack-text.svg" />
											</p>
											<p class="info-sub">
												{{ item.shippingDay === 0 ? '지금 주문하면 오늘 도착!' : '지금 주문하면 내일 오전 도착!' }}
											</p>
										</template>
										<template v-else-if="item.shippingDayPolicy === 'SIGMA'">
											<p class="info-title">
												<img src="@/assets/svg/common/icon-truck.svg" /><b>당일 출고</b> 예정 (Sigma-Aldrich 실시간 제공)
											</p>
											<p class="info-sub caution">
												<img src="@/assets/svg/common/icon-error.svg" />
												출고정보는 제조사 재고상황에 따라 변경될 수 있습니다.
											</p>
										</template>
									</div>
								</td>
								<td>
									<div>
										<button class="btn" @click="onAdd(product)">추가</button>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import mixin from '@/mixins'
import info from '@/mixins/info'
import LineTab from '@/components/tab/LineTab'
import { mapActions, mapMutations, mapState } from 'vuex'

const TAB = {
	FAVORITE: 'FAVORITE',
	PRICEASC: 'PRICEASC',
	PRICEDESC: 'PRICEDESC',
}

export default {
	name: 'ModalProductRequestManagement',
	components: {
		LineTab,
	},
	mixins: [mixin, info],
	props: {
		title: {
			type: String,
			default: '',
		},
		productInfoId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			TAB,
			catalogDetailSelectTab: TAB.FAVORITE,
			tabs: [
				{ name: '구매 많은 순', value: TAB.FAVORITE },
				{ name: '낮은 가격 순', value: TAB.PRICEASC },
				{ name: '높은 가격 순', value: TAB.PRICEDESC },
			],
			productList: [],
		}
	},
	computed: {
		...mapState('productInfo/detail', ['item']),
		minUnitPrice() {
			return Math.min(...this.productList.map(product => product.unitPrice))
		},
	},
	watch: {
		catalogDetailSelectTab(val) {
			// if (val === TAB.FAVORITE) this.setProductsParams({ order: [TAB.FAVORITE], orderDirection: ['DESC'] })
			// else if (val === TAB.PRICEASC) this.setProductsParams({ order: ['PRICE'], orderDirection: ['ASC'] })
			// else if (val === TAB.PRICEDESC) this.setProductsParams({ order: ['PRICE'], orderDirection: ['DESC'] })
		},
		'item.products': {
			handler(val) {
				this.productList = this.$_.cloneDeep(val)
			},
			deep: true,
		},
	},
	methods: {
		...mapActions('productInfo/detail', ['getDetail', 'setProductsParams']),
		async onShow() {
			this.catalogDetailSelectTab = TAB.FAVORITE
			this.productList = []
			await this.getDetail(this.productInfoId)
		},
		convertDisplayOption(options) {
			const optionMap = new Map()
			options.forEach(option => {
				if (!optionMap.has(option.name)) optionMap.set(option.name, [])
				optionMap.set(option.name, [...optionMap.get(option.name), option.value])
			})

			return [...optionMap].reduce((acc, [key, value], index) => {
				return acc + `${index !== 0 ? ' | ' : ''}${key}: ${value.join(',')}`
			}, '')
		},
		onAdd(product) {
			this.$emit('add', product)
			this.$refs.modal.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
#modal-product-request-management {
	.modal-lg {
		max-width: 1000px;
	}
	.modal-content,
	.modal-body {
		padding: 0 0;
	}
	.modal-footer {
		button {
			border: 1px solid #ccc;
			background-color: transparent;
			width: 100px;
			&:hover {
				background-color: white !important;
				color: black !important;
			}
		}
	}
	.search-wrap {
		width: 100%;
		padding: 20px;
		border-top: 1px solid $LINE_DIVIDER;
		border-bottom: 1px solid $LINE_DIVIDER;
		.search-box-wrap {
			display: flex;
			align-items: flex-start;
			.search-box {
				width: 360px;
				margin-right: 10px;
			}
		}
		.search-sub-wrap {
			width: 360px;
			margin-top: 10px;
		}
	}
	.content-wrap {
		width: 100%;
		.line-tab {
			height: 40px;
			padding: 0 20px;
			align-items: center;
			border-top: 1px solid $LINE_DIVIDER;
			border-bottom: 1px solid $LINE_DIVIDER;
			.tab {
				font-size: 0.75rem;
				font-weight: 400;
				padding-left: 18px;
				padding-bottom: 0;
				margin-right: 2px;
				&.active {
					background-image: url(~@/assets/svg/common/sort-checked.svg);
					background-position: center left 0;
					background-repeat: no-repeat;
					background-size: 14px;
					border-bottom: 2px solid transparent;
					font-weight: 700;
				}
			}
		}
		.product-box {
			height: 480px;
			background-color: $LIST_BACKGROUND;
			overflow-y: auto;
			position: relative;
			.no-data-box {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				h6 {
					font-size: 1.125rem;
					font-weight: 700;
					color: $COLOR_999;
				}
				p {
					margin-top: 10px;
					font-size: 0.875rem;
					font-weight: 400;
				}
				button {
					font-size: 0.875rem;
					font-weight: 700;
					border: 1px solid black;
					margin: 1rem auto;
				}
			}
		}
		.product-row {
			display: flex;
			align-items: flex-start;
			border-bottom: 1px solid $LINE_DIVIDER;
			background-color: white;
			cursor: pointer;
			.catalog-info-item {
				width: calc(100% - 272px);
				border-right: 1px solid $LINE_DIVIDER;
				.image {
					img {
						width: 140px;
						height: 140px;
					}
				}
				.content {
					width: calc(100% - 160px);
				}
			}
			.seller-box {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
				width: 272px;
				font-size: 0.75rem;
				padding: 20px;
				li {
					display: flex;
					width: 100%;
					font-weight: 400;
					&:first-child {
						font-weight: 700;
						p {
							color: $PRIMARY_RED;
						}
					}
					p {
						margin-left: auto;
					}
				}
			}
		}
		.catalog-detail-wrapper {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.btn-area {
				padding: 20px 20px 0 20px;
			}
			.catalog-info {
				display: flex;
				gap: 20px;
				padding: 0 20px;
				> img {
					width: 100px;
					height: 100px;
					border: 1px solid $COLOR_EEE;
					border-radius: 4px;
				}
				> div {
					display: flex;
					flex-direction: column;
					width: 100%;
					> p {
						font-weight: 400;
						font-size: 0.75rem;
						color: $COLOR_666;
					}
					.info-title {
						display: flex;
						justify-content: space-between;
					}
					.info-option {
						margin-top: 20px;
						> span {
							font-weight: 400;
							font-size: 0.75rem;
							color: $COLOR_666;
							&:not(:first-child)::before {
								vertical-align: middle;
								margin-left: 6px;
								margin-right: 6px;
								display: inline-block;
								height: 12px;
								content: '';
								border-left: 1px solid $COLOR_DDD;
							}
						}
					}
				}
			}
			.lm-table {
				@import '@/styles/scss/table/_table-header.scss';
				@import '@/styles/scss/table/_table-item.scss';
				position: relative;
				height: 100%;
				text-align: left;
				display: block;
				overflow: auto;
				white-space: nowrap;
				.table-item {
					.seller-image {
						width: 75px;
						height: 30px;
						border: 1px solid $COLOR_EEE;
						border-radius: 4px;
						padding: 2px;
					}
					.lowest {
						padding-left: 14px;
						background-image: url(~@/assets/svg/common/low-price.svg);
						background-repeat: no-repeat;
						background-position: center left 0;
						background-size: 12px;
						color: $PRIMARY_RED;
					}
					.info-sub {
						.error {
							margin-left: 3px;
							font-weight: 700;
							color: $PRIMARY_RED;
						}
					}
					.caution {
						color: $PRIMARY_RED;
						display: flex;
						img {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}
</style>
