import updateProductPrice from '@/services/api/product/updatePrice'
import cookies from 'vue-cookies'
import _ from 'lodash'
import { updateProductPriceTableAllColumns } from '@/utils/define/table/updateProductPriceTable'

export default {
	namespaced: true,
	state: {
		loadParams: {
			offset: 0,
			length: 50,
		},
		filter: null,
		visibleColumns: JSON.parse(cookies.get('visible_update_product_price_columns')) ?? updateProductPriceTableAllColumns,
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		setVisibleColumn: (state, data) => {
			state.visibleColumns = data
			cookies.set('visible_update_product_price_columns', JSON.stringify(data))
		},
		initLoadParams: state => {
			state.loadParams = {
				offset: 0,
				length: 50,
			}
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const loadParams = _.cloneDeep(state.loadParams)

			const response = await updateProductPrice.lookupProductPriceBulkupdateList(loadParams)
			commit('setList', response.data)
		},
		uploadProductPriceBulkupdate: async ({ dispatch }, formData) => {
			await updateProductPrice.uploadProductPriceBulkupdate(formData)
			await dispatch('getList')
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
