<template>
	<div class="wrapper">
		<div class="table-wrapper">
			<table>
				<colgroup>
					<col v-for="(column, index) of columns" :key="`col_${index}`" :width="column.width ?? 'auto'" />
				</colgroup>
				<thead>
					<tr>
						<th v-for="(column, index) of columns" :key="`th_${index}`">
							<span>
								{{ column.value }}
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(brand, rowIndex) of brandList"
						:key="`tr_${rowIndex}`"
						:style="selectedBrand.id === brand.id ? 'background: #FFF3D6' : ''"
					>
						<td>
							<div>
								<input type="radio" style="cursor: pointer" name="select" :id="brand.id" @change="changeSelect" />
							</div>
						</td>
						<td>
							<div>
								{{ brand.id }}
							</div>
						</td>
						<td>
							<div class="logo-image-wrapper">
								<img v-if="brand.image" class="logo-image" :src="brand.image" />
								<span class="logo-title" v-else>{{ brand.name }}</span>
							</div>
						</td>
						<td>
							<div style="font-weight: 700">
								{{ brand.name }}
							</div>
						</td>
						<td>
							<div>
								<span :class="['status', brand.isRecommend ? 'recommend' : '']">{{
									brand.isRecommend ? '추천 브랜드' : '일반'
								}}</span>
							</div>
						</td>
						<td>
							<div>{{ commaNum(brand.productInfoCount) }}개</div>
						</td>
						<td>
							<div>{{ brand.register?.name ?? '-' }}</div>
						</td>
						<td>
							<div>{{ $DateTime.fromISO(brand.createDatetime).toFormat('yyyy.MM.dd T') }}</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="table-footer">{{ footerText }}</div>
	</div>
</template>

<script>
export default {
	name: 'MergeBrandTable',
	props: {
		brandList: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		footerText() {
			if (!this.$_.isEmpty(this.selectedBrand)) return `${this.selectedBrand.name} (으)로 ${this.brandList.length}가지 브랜드를 통합합니다.`
			else return '통합할 브랜드를 선택해주세요'
		},
	},
	data() {
		return {
			selectedBrand: {},
			columns: [
				{
					key: '',
					value: '선택',
					width: '5%',
				},
				{
					key: '',
					value: '번호',
					width: '5%',
				},
				{
					key: '',
					value: '로고 이미지',
					width: '15%',
				},
				{
					key: '',
					value: '브랜드명',
					width: '25%',
				},
				{
					key: '',
					value: '우선순위',
					width: '10%',
				},
				{
					key: '',
					value: '상품 수',
					width: '10%',
				},
				{
					key: '',
					value: '등록자',
					width: '15%',
				},
				{
					key: '',
					value: '등록일시',
					width: '15%',
				},
			],
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {},
		changeSelect(e) {
			this.selectedBrand = this.brandList.find(brand => {
				return brand.id === parseInt(e.target.id)
			})
			this.$emit('select', this.selectedBrand)
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.table-wrapper {
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px 5px 0 0;
		overflow: auto;
		min-height: 280px;
		max-height: 400px;
		table {
			width: 100%;
			table-layout: fixed;
			thead {
				background: $COLOR_F5;
				border-bottom: 1px solid $LINE_DIVIDER;
				th {
					padding: 14px;
					font-size: 14px;
					text-align: left;
					color: $COLOR_666;
					span {
						white-space: nowrap;
						em {
							color: $PRIMARY_RED;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					td {
						position: relative;
						padding: 14px;
						font-size: 14px;
						.product-name {
							font-weight: 700;
						}
						.product-subname {
							color: #666666;
							font-size: 0.875rem;
						}
					}
				}
			}
		}
	}
	.logo-image-wrapper {
		width: 120px;
		height: 40px;
		border-radius: 5px;
		border: 1px solid #eeeeee;
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		.logo-image {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
		.logo-title {
			font-weight: 700;
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.status {
		padding: 0px 5px;
		font-size: 0.75rem;
		font-weight: 700;
		border-radius: 5px;
		height: 20px;
		color: #666;
		background-color: #eee;
		&.recommend {
			color: #faa400;
			background-color: #fff3d6;
		}
	}
	.table-footer {
		width: 100%;
		height: 30px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 0 0 5px 5px;
		background: $COLOR_F5;
		font-weight: 400;
		font-size: 12px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	input {
		background: white;
		appearance: none;
		width: 18px;
		height: 18px;
		border: 1px solid $PRIMARY_YELLOW;
		border-radius: 50%;
		background-clip: content-box;
		padding: 2px;
	}
	input:checked {
		background-color: $PRIMARY_YELLOW;
	}
}
</style>
