<template>
	<div class="flex">
		<label class="switch" :style="`--sliderColor: ${sliderColor}`">
			<input type="checkbox" v-model="isChecked" />
			<span class="slider round"></span>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		// Slider 초기 값
		defaultValue: {
			type: Boolean,
			default: false,
		},
		// 컬러코드 사용
		sliderColor: {
			type: String,
			default: '#FEC741',
		},
	},
	data() {
		return {
			isChecked: this.defaultValue,
		}
	},
	watch: {
		isChecked() {
			this.$emit('change', this.isChecked)
		},
	},
}
</script>

<style lang="scss" scoped>
.switch {
	margin-bottom: 0;
}

.switch input:checked + .slider {
	background-color: var(--sliderColor);
}
</style>
