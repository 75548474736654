import banner from './banner'
import event from './event'
import notice from './notice'
import faq from './faq'
import brand from './brand'
import instituteAPI from '@/services/api/institute'

export default {
	namespaced: true,
	modules: { banner, event, notice, faq, brand },
	state: {
		targetList: [],
		targetCount: 0,
	},
	mutations: {
		setTargetList: (state, data) => {
			state.targetList = data.list
			state.targetCount = data.count
		},
	},
	actions: {
		getTargetList: async ({ commit }, loadParams) => {
			const response = await instituteAPI.list(loadParams)
			commit('setTargetList', response.data)
		},
	},
}
