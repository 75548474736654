<template>
	<portal to="lm-modal">
		<div class="MemoPopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">입금일 선택</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div style="display: flex">
						<div style="flex-grow: 1">
							<div style="font-size: 0.75rem">입금일 선택<span class="required">*</span></div>
							<div style="margin-top: 5px">
								<date-picker
									v-model="paymentDate"
									:lang="lang"
									type="date"
									valueType="format"
									placeholder="yyyy-MM-dd"
									style="width: 225px"
								/>
							</div>
						</div>
						<div style="flex-grow: 1; margin-left: 10px">
							<div style="font-size: 0.75rem">입금시간 입력<span class="required">*</span></div>
							<div class="Popup__input-field" style="margin-top: 5px">
								<input type="text" v-model="paymentTime" placeholder="Hour:Minute(24시 기준)으로 입력" style="width: 225px; height: 34px" />
							</div>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
	name: 'ModalWaitToPaid',
	components: {
		DatePicker,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		subtext: {
			type: String,
			default: '',
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			lang: {
				formatLocale: {
					// MMMM
					months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// MMM
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// dddd
					weekdays: ['일', '월', '화', '수', '목', '금', '토'],
					// ddd
					weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
					// dd
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			paymentDate: '',
			paymentTime: '',
		}
	},
	watch: {
		isShow() {
			this.paymentDate = this.$DateTime.now().toFormat('yyyy-MM-dd')
			this.paymentTime = ''
		},
	},
	methods: {
		...mapActions('payment/list', ['updatePaid']),
		hide() {
			this.$emit('hide')
		},
		async complete() {
			if (!this.validate()) return

			try {
				await this.updatePaid({
					paymentItemIds: this.selectedList.map(selectedItem => selectedItem.payment.paymentItemId),
					paymentDatetime: `${this.paymentDate} ${this.paymentTime}:00`,
				})

				this.$root.toast('입금완료로 변경', '입금 완료로 변경되었습니다.', ToastType.SUCCESS)
				this.hide()
				this.$emit('complete')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			if (!this.paymentDate) {
				this.$root.toast('에러', '입금일을 선택해주세요.', ToastType.ERROR)
				return false
			}
			if (!this.paymentTime) {
				this.$root.toast('에러', '입금시간을 입력해주세요.', ToastType.ERROR)
				return false
			}
			return true
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~vue2-datepicker/scss/index.scss';
</style>
