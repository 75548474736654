const productListTableAllColumns = [
	'판매상태',
	'상품번호',
	'판매자',
	'정보',
	'옵션',
	'총 재고수량',
	'카테고리',
	'브랜드',
	'제품번호',
	'CAS No',
	'검색 태그',
	'예상 출고일',
	'개별연락',
	'퀵 서비스',
	'출고지',
	'상품등록일시',
	'최종수정일시',
	'판매중지일시',
]

const productListTableRequireColumns = []

export { productListTableAllColumns, productListTableRequireColumns }
