import inquiryAPI from '@/services/api/cs/inquiry'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			typeIds: JSON.parse(cookies.get('filter_inquiry_types')) ?? [],
			isCommented: [],
			offset: 0,
			length: 50,
		},
		filter: null,
		filterCookieName: {
			typeIds: 'filter_inquiry_types',
		},
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (state.loadParams.isCommented.length == 1) return state.loadParams.isCommented[0]
			return 'ALL'
		},
		filteredCount: state => state.loadParams.typeIds.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			state.filter = data
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await inquiryAPI.lookupInquiryList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		getFilter: async ({ commit }) => {
			const response = await inquiryAPI.lookupInquiryFilter()
			commit('setFilter', response.data)
		},
		exportExcel: async ({ state }, type) => {
			// const response = await budgetAPI.listExportExcel(type == 'ALL' ? { status: state.loadParams.status } : state.loadParams)
			// return response
		},
	},
}
