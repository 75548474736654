<template>
	<b-modal
		:id="id"
		centered
		:title="title"
		:ok-title="btnText"
		ok-variant="complete"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-common"
		:size="size"
		button-size="lg"
		:hide-header-close="!isHeaderClose"
		:ok-only="isOkOnly"
		no-close-on-esc
		no-close-on-backdrop
		@ok="$emit('ok')"
	>
		<div class="msg-wrapper">
			<p v-html="content"></p>
			<div class="notice" v-if="notice" v-html="notice"></div>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		id: {
			type: String,
			default: 'modal-submit',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'md',
		},
		notice: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '확인',
		},
		isHeaderClose: {
			type: Boolean,
			default: false,
		},
		isOkOnly: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss">
#modal-submit {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			text-align: center;
			justify-content: center;
			padding-bottom: 0;
			border-bottom: 0;
		}
		.modal-body {
			text-align: center;
			p {
				font-size: 0.875rem;
				font-weight: 400;
				color: #333;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			border-top: 0;
			text-align: center;
			justify-content: center;
			button {
				width: 160px;
				height: 40px;
				font-size: 0.875rem;
			}
		}
	}
}

#modal-common {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			padding: 0 0;
			border-bottom: 0;
		}
		.modal-body {
			padding: 20px 0;
			font-size: 0.875rem;
		}
		.modal-footer {
			flex-wrap: unset;
			justify-content: end;
			border-top: 0;
			padding: 0 0;
			button {
				justify-content: center;
				min-width: 100px;
				width: unset;
				height: 30px;
				font-size: 0.875rem;
				border-radius: 5px;
				border-width: 1px;
			}
		}
	}
}

#image-size-modal,
#date-caution-modal {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			text-align: center;
			justify-content: center;
			padding-bottom: 0;
			border-bottom: 0;
		}
		.modal-body {
			text-align: center;
			p {
				font-size: 0.875rem;
				font-weight: 400;
				color: #333;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			border-top: 0;
			text-align: center;
			justify-content: center;
			button {
				width: 160px;
				height: 40px;
				font-size: 0.875rem;
			}
		}
	}
}
</style>
