export default {
	data() {
		return {
			isShow: false,
			modalType: '',
			keyword: '',
		}
	},
	beforeMount() {
		window.addEventListener('keyup', this.keyupEvent)
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.keyupEvent)
	},
	methods: {
		keyupEvent(e) {
			if (e.keyCode == 27) {
				this.hide()
			}
		},
		show() {
			this.isShow = true
		},
		hide() {
			this.isShow = false
			this.$emit('hide')
		},
		goToMemberManagement() {
			this.$router.push({ path: '/MemberManagement' })
		},
		goToOrganizationTree() {
			if (this.$route.name === 'OrganizationTree') {
				this.hide()
			} else {
				this.$router.replace({ path: '/OrganizationTree' })
			}
		},
		goToLocationTree() {
			if (this.$route.name === 'Location') {
				this.hide()
			} else {
				this.$router.replace({ path: '/institute/location' })
			}
		},
		showGroupNames(groups) {
			return groups
				.map(group => {
					return group.name
				})
				.join(', ')
		},
		highlightName(name) {
			const lowercaseName = name.toLowerCase()
			const characterIndex = lowercaseName.indexOf(this.keyword.toLowerCase())
			if (name.length > 0 && characterIndex > -1) {
				const subStrCharacter = name.substr(characterIndex, this.keyword.length)
				return name.replace(subStrCharacter, `<mark>${subStrCharacter}</mark>`)
			} else {
				return name
			}
		},
		refresh() {
			this.$emit('refresh')
		},
		focusInput() {
			if (this.$refs.scan) {
				this.$refs.scan.focus()
			}
		},
	},
}
