<template>
	<portal to="lm-modal">
		<div class="modal-order-cancel-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">교환/반품 접수</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="explanation">정말로 주문을 교환/반품 접수 하시겠습니까?</div>
					<div class="cancel-reason-detail-wrapper">
						<div class="title-wrapper">
							<div class="title">교환/반품 상세사유 입력</div>
							<div class="delete" @click="clearCancelRefundReasonDetail">모두 지우기</div>
						</div>
						<div class="textarea-wrapper">
							<textarea v-model="cancelRefundReasonDetail" placeholder="교환/반품 상세사유를 입력해주세요"></textarea>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__delete btn-warning" @click="complete">접수완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'

export default {
	name: 'ModalOrderCancelRefund',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		orderItemList: {
			type: Array,
			default: [],
		},
	},
	watch: {
		isShow() {
			Object.assign(this.$data, this.$options.data.call(this))
		},
	},
	data() {
		return {
			cancelRefundReasonDetail: '',
		}
	},
	methods: {
		// ...mapActions('order', ['cancelRefundOrder']),
		hide() {
			this.$emit('hide')
		},
		clearCancelRefundReasonDetail() {
			this.cancelRefundReasonDetail = ''
		},
		async complete() {
			try {
				// await this.cancelRefundOrder({
				// 	orderItemIds: this.orderItemList.map(({ orderItemId }) => orderItemId),
				// 	cancelRefundReasonDetail: this.cancelRefundReasonDetail,
				// })

				this.$root.toast('접수 완료', '교환/반품이 접수되었습니다.', ToastType.SUCCESS)
				this.$emit('complete')
				this.hide()
			} catch (error) {
				this.$root.toast('에러', error.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-cancel-wrapper {
	.Popup__container {
		.Popup__content {
			width: 500px;
			.explanation {
				font-size: 0.875rem;
			}

			.cancel-reason-detail-wrapper {
				margin-top: 20px;
				.title-wrapper {
					font-size: 0.75rem;
					display: flex;
					justify-content: space-between;
					.delete {
						color: $FONT_PLACEHOLDER;
						font-weight: 700;
						cursor: pointer;
					}
				}
				.textarea-wrapper {
					margin-top: 8px;
					textarea {
						width: 100%;
						height: 150px;
						border: 1px solid #cccccc;
						border-radius: 5px;
						padding: 10px;
						font-size: 0.875rem;
					}
				}
			}
		}
	}
}
</style>
