<template>
	<div class="shipping-wrapper">
		<div>
			<h5>출고지 정보</h5>
			<base-item :title="'출고지 선택'">
				<button class="btn address-btn" @click="$refs['modal-address-management'].show()">출고지 등록</button>
				<choice-list
					ref="choice-list"
					title="출고지"
					emptyMessage="출고지가 없습니다. 출고지를 등록해주세요"
					:list="addressList"
					:tab.sync="selectShippingTab"
					type="address"
					@changeValue="onChangeAddressValue"
					@regist="$refs['modal-address-management'].show('regist')"
					@edit="value => $refs['modal-address-management'].show('edit', value)"
				></choice-list>
			</base-item>
		</div>
		<div>
			<h5>배송 정보</h5>
			<input-radio
				ref="input-radio"
				:title="'예상 출고일 제공방식'"
				:list="shippingDayPolicyList"
				:required="true"
				:tab.sync="shippingDayPolicy"
				@changeValue="onChangeDayPolicy"
			></input-radio>
			<base-item v-if="shippingDayPolicy === DAY_POLICY.SIGMA" title="예상 출고일">
				<expect-release-date :isShowContent="true" />
			</base-item>
			<base-item v-if="shippingDayPolicy === DAY_POLICY.DEFAULT" title="기본 예상 출고일">
				<div class="form-text">
					<div class="main">재고 있는 경우 <b>7일</b> 이내 출고 / 재고 없는 경우 <b>30일</b> 이내 출고</div>
					<div class="sub">기본 예상 출고일은 <b>내 정보 관리 > 판매자 정보 관리</b>에서 수정할 수 있습니다.</div>
				</div>
			</base-item>
			<base-item v-if="shippingDayPolicy === DAY_POLICY.SHAK" title="기본 예상 출고일">
				<div class="form-text">
					<div class="main">12시 이전 주문 시 <b>당일출고</b>, 12시 이후 주문 시 <b>익일출고</b></div>
				</div>
			</base-item>
			<input-check-box
				ref="contact-checkbox"
				title="개별연락"
				checkSubTitle="재고/예상 출고일 확인 후 개별연락"
				subContentText="주문 접수 후, 제조사에 재고 및 출고정보를 확인한 뒤 주문자에게 직접 연락하여 전달합니다."
				@changeValue="onChangeContact"
			></input-check-box>
			<input-check-box ref="quick-checkbox" title="퀵 서비스" checkSubTitle="퀵 서비스 가능" @changeValue="onChangeQuick"></input-check-box>
			<input-text-area
				ref="info"
				title="배송 안내"
				:inputWidthSize="810"
				:inputHeightSize="120"
				placeholder="배송 안내 문구 입력"
				@changeValue="onChangeShippingInfoText"
			></input-text-area>
		</div>

		<modal-address-management ref="modal-address-management" @complete="fetchAddressList"></modal-address-management>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ExpectReleaseDate from './ExpectReleaseDate.vue'
import ChoiceList from '@/views/common/components/formItem/ChoiceList.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import InputAddress from '@/views/common/components/formItem/InputAddress.vue'
import InputCheckBox from '@/views/common/components/formItem/InputCheckBox.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputTextArea from '@/views/common/components/formItem/InputTextArea.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import ModalAddressManagement from '@/views/common/modals/ModalAddressManagement.vue'

const DAY_POLICY = {
	SIGMA: 'SIGMA',
	DEFAULT: 'DEFAULT',
	NULL: 'null',
	SHAK: 'SHAK',
}

export default {
	name: 'ShippingInfo',
	components: {
		ExpectReleaseDate,
		ChoiceList,
		InputRadio,
		InputAddress,
		BaseItem,
		InputCheckBox,
		InputText,
		InputTextArea,
		ModalAddressManagement,
	},
	data() {
		return {
			DAY_POLICY,
			addressList: [],
			selectShippingTab: null,
		}
	},
	computed: {
		...mapState('productRegist/regist', ['seller', 'shippingInfo', 'productInfo']),
		...mapGetters('seller/list', ['getAddressList']),
		shippingDayPolicyList() {
			const isProvideShippingDate = false // TODO: 임시 예상출고일 제공 (서버 작업 안돼있음)

			const list = [
				{
					id: DAY_POLICY.NULL,
					title: '제공 안함',
				},
				{
					id: DAY_POLICY.DEFAULT,
					title: '기본 예상 출고일 적용',
				},
				{
					id: DAY_POLICY.SIGMA,
					title: 'Sigma-Aldrich 예상 출고일 자동적용',
				},
				{
					id: DAY_POLICY.SHAK,
					title: '샥샥배송',
				},
			]
			return list.map(item => {
				item.disabled = item.id === DAY_POLICY.DEFAULT && !isProvideShippingDate
				item.tooltip = item.id === DAY_POLICY.DEFAULT ? '기본 예상출고일은 내 정보 관리 > 판매자 정보 관리에서 수정할 수 있습니다.' : null
				return item
			})
		},
		shippingDayPolicy() {
			const isProvideShippingDate = false // TODO: 임시 예상출고일 제공 (서버 작업 안돼있음)
			const isSigmaEldrich = this.productInfo.brand?.constructor === Object ? this.productInfo.brand.isMerck : false

			if (this.shippingInfo?.shippingDayPolicy) {
				return this.$_.cloneDeep(this.shippingInfo.shippingDayPolicy)
			} else if (isProvideShippingDate) {
				return DAY_POLICY.DEFAULT
			} else if (isSigmaEldrich) {
				return DAY_POLICY.SIGMA
			} else {
				return DAY_POLICY.NULL
			}
		},
	},
	created() {
		this.fetchAddressList()
	},
	mounted() {
		if (!this.$_.isEmpty(this.shippingInfo)) this.bindShippingInfo()
		else {
			this.onChangeContact(false)
			this.onChangeQuick(false)
			this.onChangeDayPolicy({ id: this.shippingDayPolicy })
		}
	},
	methods: {
		...mapActions('seller/list', ['lookupAddressList']),
		...mapMutations('productRegist/regist', ['setShippingInfo']),
		async fetchAddressList() {
			await this.lookupAddressList({
				sellerId: this.seller.id
			})
			const response = this.getAddressList
			this.addressList = this.$_.cloneDeep(response.list)
			if (!this.shippingInfo?.shippingAddressId) {
				this.addressList.some(item => {
					if (item.isDefault) {
						this.selectShippingTab = this.$_.cloneDeep(item)
						this.setInfo('shippingAddressId', item.id)
						return true
					} else {
						return false
					}
				})
			} else {
				let address = this.addressList.find(item => {
					return item.id === this.shippingInfo.shippingAddressId
				})
				this.selectShippingTab = address
			}
		},
		bindShippingInfo() {
			this.$refs['contact-checkbox'].isCheck = this.shippingInfo?.shippingDirectContact ?? false
			this.$refs['quick-checkbox'].isCheck = this.shippingInfo?.shippingCanQuick ?? false
			this.shippingInfo?.shippingInfo ? this.$refs['info'].setValue(this.shippingInfo.shippingInfo) : null
		},
		onChangeAddressValue(value) {
			this.selectShippingTab = value
			this.setInfo('shippingAddressId', value.id)
		},
		onChangeDayPolicy(value) {
			this.setInfo('shippingDayPolicy', value.id)
		},
		onChangeContact(value) {
			this.setInfo('shippingDirectContact', value)
		},
		onChangeQuick(value) {
			this.setInfo('shippingCanQuick', value)
		},
		onChangeShippingInfoText(value) {
			this.setInfo('shippingInfo', value)
		},
		setInfo(key, value) {
			const shippingInfo = this.$_.cloneDeep(this.shippingInfo)
			shippingInfo[key] = value
			this.setShippingInfo(shippingInfo)
		},
		valid() {
			return true
		},
	},
}
</script>

<style lang="scss" scoped>
.shipping-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	h5 {
		text-align: left;
		font-weight: 700;
		padding: 25px 0;
	}
	.address-btn {
		width: 100px;
		height: 36px;
		display: flex;
		justify-content: center;
	}
	.address-btn + div {
		margin-top: 15px;
	}
	.form-text {
		display: flex;
		flex-direction: column;
		gap: 8px;
		.main {
			font-size: 0.875rem;
		}
		.sub {
			font-size: 0.75rem;
			color: $COLOR_999;
		}
	}
}
</style>
