<template>
	<tr class="table-item" style="cursor: default">
		<td>
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status', item.csStatus.code]"> {{ item.csStatus.name }} </span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.csType.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span style="text-decoration: underline; cursor: pointer" @click="goToDetail"> 내용보기 </span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; cursor: pointer; text-decoration: underline" @click="routeOrderDetailPage">
				<span>{{ item.orderNo }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; cursor: pointer; text-decoration: underline" @click="routeOrderItemDetailPage">
				<span>{{ item.orderItemNo }}</span>
			</div>
		</td>
		<td>
			<div class="info-wrap">
				<p class="info-title">{{ item.product?.name }}</p>
				<p class="info-sub" v-if="item.product.subname">
					{{ item.product.subname ? item.product.subname : '' }}
				</p>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.cancelRequestUser?.name ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.cancelRequestUser?.phone ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.cancelRequestDatetime ? $DateTime.fromISO(item.cancelRequestDatetime).toFormat('yyyy.MM.dd T') : '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.approveDatetime ? $DateTime.fromISO(item.approveDatetime).toFormat('yyyy.MM.dd T') : '-' }}
				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		statusText() {
			if (this.item.status == 'REJECT') return '거절'
			else if (this.item.status == 'COMPLETE') return '처리 완료'
			return '접수중'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'CancelRefundDetail',
				params: {
					id: this.item.orderItemId,
				},
			})
		},
		routeOrderDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: this.item.orderId,
				},
			})
		},
		routeOrderItemDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: this.item.orderItemId,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
