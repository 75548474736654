<template>
	<div class="seller-login-wrapper">
		<div class="login-wrapper">
			<div class="logo-wrapper">
				<img src="@/assets/images/lms-logo.png" alt="logo" style="width: 200px; display: block" />
				<p><b>CMS</b></p>
			</div>
			<div class="input-wrapper">
				<div>
					<input ref="id" type="text" v-model="user.email" :class="{ error: !isValid.email }" placeholder="아이디(이메일 주소)" />
					<div v-if="!isValid.email" class="error">
						<img src="@/assets/svg/common/icon-error.svg" alt="error" />
						<span>{{ inValidText.email }}</span>
					</div>
				</div>
				<div>
					<input
						ref="password"
						type="password"
						v-model="user.password"
						:class="{ error: !isValid.password }"
						placeholder="비밀번호"
						@keyup.enter="loginUser"
					/>
					<div v-if="!isValid.password" class="error">
						<img src="@/assets/svg/common/icon-error.svg" alt="error" />
						<span>{{ inValidText.password }}</span>
					</div>
				</div>
			</div>
			<div class="function-wrapper">
				<div class="login-status">
					<input id="login-status" type="checkbox" v-model="isRemember" />
					<label for="login-status">로그인 상태 유지</label>
				</div>
				<div class="find-account">
					<p @click="findAccountModal">계정 / 비밀번호 찾기</p>
				</div>
			</div>
			<div class="login-button-wrapper">
				<button class="btn Btn__complete" @click="loginUser">로그인</button>
			</div>
			<div class="footer-wrapper">
				<p>아직 CMS에 입점하지 않았다면? <span @click="routeEntryRegistration">입점 신청하기</span></p>
			</div>
		</div>
		<modal-common
			ref="modal-common"
			:hideCancelBtn="true"
			@cancel="$refs['modal-common'].hide()"
			@complete="$refs['modal-common'].hide()"
		></modal-common>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'
import { isEmail } from '@/utils/modules/Regex'
import { setToken } from '@/services/api/common'
import axiosInstance from '@/services/axios'
import cookies from 'vue-cookies'

export default {
	name: 'SellerLogin',
	components: {
		ModalCommon,
	},
	data() {
		return {
			isRemember: false,
			user: {
				email: '',
				password: '',
			},
			isValid: {
				email: true,
				password: true,
			},
			inValidText: {
				email: '',
				password: '',
			},
		}
	},
	computed: {
		...mapGetters('user', ['getUser']),
	},
	mounted() {
		this.initMounted()
	},
	methods: {
		...mapMutations('user', ['setIsLogin', 'setUser']),
		...mapActions('user', ['login']),
		initMounted() {
			const isRemember = localStorage.getItem('isRemember')
			if (isRemember === 'true') {
				this.user.email = localStorage.getItem('email')
				this.user.password = localStorage.getItem('password')
				this.isRemember = true

				this.loginUser()
			}
		},
		async loginUser() {
			if (!this.validate()) return
			const body = {
				userEmail: this.user.email,
				userPassword: this.user.password,
			}
			const response = await this.login(body)
			if (response.data.code === 0) {
				if (this.isRemember) {
					localStorage.setItem('isRemember', true)
					localStorage.setItem('email', this.user.email)
					localStorage.setItem('password', this.user.password)
				}
				setToken(response.data.data.token)
				axiosInstance.setAuth(response.data.data.token)
				location.href = '/'
			} else {
				this.$root.toast('로그인 실패', `${response.data.msg}`, ToastType.ERROR)
			}
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			// 이메일
			let isValid = true
			if (!this.user.email.length) {
				this.isValid.email = false
				this.inValidText.email = '이메일을 입력해주세요.'
				isValid = false
			} else if (!isEmail(this.user.email)) {
				this.isValid.email = false
				this.inValidText.email = '이메일 형식이 올바르지 않습니다.'
				isValid = false
			}
			// 비밀번호
			if (!this.user.password.length) {
				this.isValid.password = false
				this.inValidText.password = '비밀번호를 입력해주세요.'
				isValid = false
			} else if (this.user.password.length < 6) {
				this.isValid.password = false
				this.inValidText.password = '6자리 이상 입력해주세요.'
				isValid = false
			}

			return isValid
		},
		findAccountModal() {
			this.$refs['modal-common'].show(
				'계정/비밀번호 찾기',
				`계정/비밀번호를 찾기 위해서는 CMS 고객센터로 문의해주세요. <br /> 
				고객센터 연락처: <span style="font-weight: bold;">02-1234-1234</span>`,
				'white',
				'확인'
			)
		},
		routeEntryRegistration() {
			this.$router.push({
				name: PageName.Seller.SellerEntryRegistration,
			})
		},
		routeRequestProduct() {
			this.$router.replace({
				name: PageName.Product.ProductRequestBoard,
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-login-wrapper {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $COMMON_BACKGROUND;

	.login-wrapper {
		width: 500px;
		background: #ffffff;
		padding: 55px 50px;
		border: 1px solid #eeeeee;
		box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
		border-radius: 16px;

		.logo-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;

			p {
				color: $PRIMARY_YELLOW;
				font-weight: 500;
				font-size: 1.25rem;
				margin-left: 4px;
			}
		}

		.input-wrapper {
			display: flex;
			flex-direction: column;
			margin-top: 40px;
			& > div {
				margin-bottom: 10px;
				input[type='text'],
				input[type='password'] {
					width: 100%;
					height: 48px;
					border: 1px solid $FILE_BORDER;
					border-radius: 5px;
					padding: 12px;
					&.error {
						border-color: $PRIMARY_RED;
					}
				}
				.error {
					display: flex;
					span {
						font-size: 0.75rem;
						color: $PRIMARY_RED;
						margin-left: 6px;
					}
				}
			}
		}

		.function-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 5px;
			.login-status {
				display: flex;
				label {
					margin-left: 10px;
					font-size: 0.875rem;
					font-weight: 400;
				}
			}
			.find-account {
				p {
					margin-left: 10px;
					font-size: 0.875rem;
					font-weight: 400;
					cursor: pointer;
				}
			}
		}

		.login-button-wrapper {
			margin-top: 30px;
			button {
				width: 100%;
				height: 48px;
				display: flex;
				justify-content: center;
			}
		}

		.footer-wrapper {
			margin-top: 35px;
			span {
				color: $PRIMARY_BLUE;
				font-weight: 500;
				text-decoration-line: underline;
				cursor: pointer;
			}
		}
	}
}
</style>
