<template>
	<div ref="wrapper" class="image-wrapper">
		<h5>{{ title }}</h5>
		<div class="image-box" :style="{ width: `${calcWidth}px`, height: `${calcHeight}px` }">
			<img :src="image" />
		</div>
		<div v-b-tooltip.hover="link" v-if="link" class="link" :style="{ width: `${calcWidth}px` }" @click="onClickLink">{{ link }}</div>
		<div v-if="link" class="link-sub">유저가 이미지를 클릭하였을 떄 이 링크 주소로 유저를 이동시킵니다.</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
		},
		width: {
			type: Number,
		},
		height: {
			type: Number,
		},
		image: {
			type: String,
		},
		link: {
			type: String,
			default: null,
		},
	},
	computed: {
		calcWidth() {
			if (this.width >= 1600) return 1050
			else if (this.width >= 1000) return 500
			return 375
		},
		calcHeight() {
			if (this.height) {
				return (this.calcWidth / this.width) * this.height
			}
			return 1000
		},
	},
	methods: {
		onClickLink() {
			window.open(this.link, '_blank')
		},
	},
}
</script>

<style lang="scss" scoped>
.image-wrapper {
	display: inline-block;
	h5 {
		font-size: 14px;
		font-weight: 700;
		color: #111;
		margin-bottom: 10px;
	}
	.image-box {
		border: 1px solid #ddd;
		border-radius: 5px;
		background-color: #f5f5f5;
		max-height: 1000px;
		text-align: center;
		img {
			border-radius: 5px;
			max-width: 100%;
			max-height: 100%;
		}
	}
}
.link {
	display: block;
	margin-top: 10px;
	border: 1px solid #ddd;
	border-radius: 5px;
	background-color: #f5f5f5;
	font-size: 14px;
	height: 36px;
	color: #111;
	word-break: break-all;
	font-weight: 400;
	overflow: hidden;
	padding: 8px 12px;
	cursor: pointer;
}
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
</style>
