import estimateAPI from '@/services/api/estimate/estimate'

export default {
	namespaced: true,
	state: {
		item: {},
	},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await estimateAPI.lookupEstimate(id)
			commit('setItem', response.data)
		},
		expiryEstimate: async (_, body) => {
			return await estimateAPI.expiryEstimate(body)
		},
		addEstimate: async (_, body) => {
			return await estimateAPI.addEstimate(body)
		},
		updateEstimate: async (_, body) => {
			return await estimateAPI.updateEstimate(body)
		},
		printEstimate: async (_, body) => {
			const response = await estimateAPI.printEstimate(body)
			return response.data.url
		},
		lookupProductsByOrderItemId: async (_, params) => {
			const response = await estimateAPI.lookupProductsByOrderItemId(params)
			return response.data
		},
		lookupProductsByRequestId: async (_, params) => {
			const response = await estimateAPI.lookupProductsByRequestId(params)
			return response.data
		},
	},
}
