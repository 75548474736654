<template>
	<div class="registration-success-wrapper">
		<div class="registration-success-title">
			<p class="title">입점 신청 완료!</p>
			<p class="email">{{ email }}</p>
			<p class="content">
				가입 시 입력한 이메일로 심사 결과를 알려드립니다. <br />
				심사완료까지 최대 n일까지 소요될 수 있습니다.
			</p>
		</div>
		<div class="image-wrapper">
			<img src="@/assets/svg/seller/success_check.svg" />
		</div>
		<div class="next-button-wrapper"><button class="btn Btn__complete" @click="onClickNextButton">확인</button></div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
	name: 'RegistrationSuccess',
	computed: {
		...mapGetters('user', {
			registUserInfo: 'getRegistUserInfo',
		}),
		email() {
			return this.registUserInfo.email
		},
	},
	methods: {
		onClickNextButton() {
			this.$emit('complete')
		},
	},
}
</script>

<style lang="scss" scoped>
.registration-success-wrapper {
	.registration-success-title {
		display: flex;
		justify-content: center;
		flex-direction: column;
		gap: 10px;
		.title {
			font-size: 2rem;
			font-weight: 700;
		}
		.email,
		.content {
			font-size: 1rem;
			font-weight: 400;
		}
	}
	.image-wrapper {
		width: 100%;
		height: 300px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.next-button-wrapper {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		button {
			width: 200px;
			height: 50px;
			display: flex;
			justify-content: center;
		}
	}
}
</style>
