<template>
	<div class="base-input-wrapper">
		<input
			ref="input"
			class="base-input"
			:class="{ error }"
			:type="type"
			:value="value"
			:placeholder="placeholder"
			:maxlength="maxlength"
			:min="min"
			:max="max"
			:disabled="disabled"
			:readonly="readonly"
			:style="inputStyle"
			@input="$emit('input', $event.target.value)"
			@keypress="$emit('keypress', $event)"
			@keydown="$emit('keydown', $event)"
			@keyup="$emit('keyup', $event)"
			@click="$emit('click', $event)"
		/>
		<div v-if="isError">
			<img src="@/assets/svg/common/icon-error.svg" />
			<span class="error-msg">{{ errorMessage }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseInput',
	props: {
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		maxlength: {
			type: Number,
			default: 9999,
		},
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 999999999,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		autofocus: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		inputStyle: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			isValid: true,
		}
	},
	computed: {
		isError() {
			return this.error && this.errorMessage
		},
	},
	mounted() {
		setTimeout(() => {
			if (this.autofocus && this.$refs.input) this.$refs.input.focus()
		}, 0)
	},
	methods: {
		validate() {
			this.isValid = !!this.value
			return this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.base-input-wrapper {
	display: inline-block;
	.base-input {
		width: 300px;
		height: 40px;
		padding: 10px;
		border: 1px solid $COLOR_EEE;
		border-radius: 4px;
		font-size: 0.875rem;
		font-weight: 400;
		&:disabled {
			background: $LIST_BACKGROUND;
			color: $FONT_PLACEHOLDER;
		}
		&.error {
			border: 1px solid $PRIMARY_RED;
		}
	}
	.error-msg {
		margin-left: 6px;
		font-size: 0.75rem;
		color: $PRIMARY_RED;
	}
}
</style>
