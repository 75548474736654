import bannerAPI from '@/services/api/lms/banner'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			status: [''],
			offset: 0,
			length: 50,
		},
		list: [],
		count: 0,
		item: {},
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => state.loadParams.status[0],
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				if (key === 'status') {
					state.loadParams.status = []
					state.loadParams.status.push(loadParams[key])
				} else state.loadParams[key] = loadParams[key]
			})
		},
		setItem: (state, data) => {
			state.item = data
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await bannerAPI.list(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		getDetail: async ({ commit }, id) => {
			const response = await bannerAPI.detail(id)
			commit('setItem', response.data)
		},
		createEvent: async ({ commit }, body) => {
			return await bannerAPI.create(body)
		},
		updateEvent: async ({ commit }, body) => {
			return await bannerAPI.updateEvent(body)
		},
		deleteEvent: async ({ commit }, params) => {
			return await bannerAPI.deleteEvent(params)
		},
	},
}
