<template>
	<div class="order-wrapper">
		<div class="order-header">
			<div class="header-wrapper">
				<h4 class="header-title">주문 상품별 목록</h4>
				<button class="btn guide-button" @click="onClickShowGuide">
					<img src="@/assets/svg/order/icon-question-mark.svg" />
					주문관리 가이드
				</button>
			</div>
		</div>
		<div class="order-filter-wrapper">
			<status-counter-list :list="statusList" @active="onClickStatus" />
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
				searchPlaceholder="주문자, 주문상세 ID,  상품명, 브랜드로 검색"
				:datePlaceholder="datePlaceholder"
				:filters="filters"
				:datePickerList="datePickerList"
				:useFold="true"
				@searchTextList="searchTextList"
				@searchCheckList="searchCheckList"
			/>
		</div>
		<div class="order-list-wrapper">
			<div class="title-btn-wrapper">
				<search-title
					:title="`주문 목록(${totalCount})`"
					subTitle="상품을 선택 한후 아래 주문 상태를 클릭하여 주문상태를 변경할 수 있습니다."
				/>
				<div class="btn-wrapper">
					<button class="btn" @click="isShowModalVisibleOption = true">
						<img src="@/assets/svg/common/view-option.svg" />
						보기옵션
					</button>
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
					<button class="btn" @click="makeEstimate">
						<img src="@/assets/svg/common/icon-estimate.svg" />
						견적서 작성
					</button>
					<button class="btn" @click="onClickTransactionStatement">
						<img src="@/assets/svg/common/icon-transaction-statement.svg" />
						거래명세서
					</button>
				</div>
			</div>
			<order-status-changer :selectedList="checkedList" @change="orderManagementCreated" />
			<div class="table-wrapper">
				<jw-table
					ref="jw-table"
					class="jwTable"
					:width="table.width"
					:height="table.height"
					:options="table.options"
					:noData="isNoData"
					:noSearchResult="isNoSearchResult"
					:noDataText="noDataText"
					:noDataImg="noDataImg"
					:noDataSubtext="noDataSubtext"
					:noSearchResultText="noSearchResultText"
					:noSearchResultSubtext="noSearchResultSubtext"
					noDataHeight="600px"
					@getSelectedItem="getSelectedItem"
					@change="changeDate"
				></jw-table>
				<div class="pagenation-wrapper">
					<common-pagenation @page="getCurrentPage" :total="totalCount" :defaultOffset="50"></common-pagenation>
				</div>
			</div>
		</div>

		<modal-order-management-guide :isShow="isShowOrderQuideModal" @hide="isShowOrderQuideModal = false" />
		<modal-order-modify
			:isShow="isShowOrderModifyModal"
			:orderItem="selectedItem"
			@hide="isShowOrderModifyModal = false"
			@complete="orderManagementCreated"
		></modal-order-modify>
		<modal-shipping-info
			:isShow="isShowShippingInfoModal"
			:orderItemList="selectedItemList"
			@hide="isShowShippingInfoModal = false"
			@complete="orderManagementCreated"
		></modal-shipping-info>
		<modal-visible-option
			:isShow="isShowModalVisibleOption"
			:allColumns="allColumns"
			:visibleColumns="visibleColumns"
			@close="isShowModalVisibleOption = false"
			@save="setVisibleOption"
		></modal-visible-option>

		<modal-update-price :item="changePriceRow" :isShow="isShowPriceModal" @hide="hidePriceModal" />
		<modal-caution :subtext="modalSubtext" :isShow="isShowCautionModal" @hide="isShowCautionModal = false" />
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import ModalOrderManagementGuide from '@/views/order/orderProduct/modals/ModalOrderManagementGuide.vue'
import ModalOrderModify from '@/views/order/orderProduct/modals/ModalOrderModify.vue'
import ModalShippingInfo from '@/views/order/orderProduct/modals/ModalShippingInfo.vue'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import JwTable from '@/views/common/components/JwTable.vue'
import ModalVisibleOption from '@/views/common/modals/ModalVisibleOption.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import ModalUpdatePrice from '@/views/order/orderProduct/modals/ModalUpdatePrice.vue'
import ModalCaution from '@/views/order/orderProduct/modals/ModalCaution.vue'
import PageName from '@/utils/define/PageName'
import { orderManagementTable, orderManagementTableAllColumns } from '@/utils/define/table/orderManagementTable'
import { TableField } from '@/utils/define/table/JwTable'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'OrderManagement',
	components: {
		ModalOrderManagementGuide,
		ModalOrderModify,
		ModalShippingInfo,
		StatusCounterList,
		DetailSearchBox,
		OrderStatusChanger,
		SearchTitle,
		JwTable,
		CommonPagenation,
		ModalUpdatePrice,
		ModalVisibleOption,
		ModalCaution,
	},
	data() {
		return {
			isShowModalVisibleOption: false,
			isShowOrderQuideModal: false,
			isShowOrderModifyModal: false,
			isShowShippingInfoModal: false,
			isShowPriceModal: false,
			isShowCautionModal: false,
			selectedItem: {},
			selectedItemList: [],
			selectedStatus: 'ALL',
			checkedList: [],
			table: this.$_.cloneDeep(orderManagementTable),
			sourceTable: orderManagementTable,
			allColumns: orderManagementTableAllColumns,
			cookieVisibleColumnsKey: 'OrderManagementTableAllColumns',
			visibleColumns: [],
			totalCount: 0,
			listParams: {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				brandIds: [],
				shippingMethods: [],
				orderStartDatetime: undefined,
				orderEndDatetime: undefined,
				estimatedReleaseStartDatetime: undefined,
				estimatedReleaseEndDatetime: undefined,
				releaseStartDatetime: undefined,
				releaseEndDatetime: undefined,
			},
			filters: {
				배송방식: [],
				브랜드: [],
			},
			statusList: [],
			datePickerList: [
				{
					text: '주문일',
				},
				{
					text: '예상출고일',
				},
				{
					text: '배송시작일',
				},
			],
			changePriceRow: {},
			modalSubtext: '',
		}
	},
	computed: {
		...mapGetters('order', ['getOrderList', 'getOrderListTableData', 'getOrderListSummary', 'getOrderListFilter']),
		...mapState('order', ['scrollTop']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		isNoData() {
			return this.listParams.keyword.length === 0 && this.totalCount === 0
		},
		isNoSearchResult() {
			return this.listParams.keyword.length > 0 && this.totalCount === 0
		},
		noDataText() {
			return '구매자가 주문한 상품이 없습니다.'
		},
		noDataSubtext() {
			return '구매자가 주문을 시작하면, 주문한 상품을 보여드립니다.'
		},
		noSearchResultText() {
			return '검색 결과가 없습니다'
		},
		noSearchResultSubtext() {
			return '상품명, 상품번호, 자체 관리번호, 브랜드, 제품번호, CAS No로 검색할 수 있습니다.'
		},
		noDataImg() {
			return require('@/assets/svg/common/jw-table/img-nodata.svg')
		},
	},
	created() {
		this.orderManagementCreated()
	},
	destroyed() {
		if (this.$route.name !== 'OrderProductManagementDetail' && this.$route.name !== 'OrderItemDetail') this.setScrollTop(0)
	},
	methods: {
		...mapActions('order', ['lookupOrderList', 'lookupOrderListSummary', 'lookupOrderListFilter', 'changeEstimatedDate', 'downloadOrderList']),
		...mapMutations('order', ['setScrollTop']),
		async orderManagementCreated() {
			await this.loadListData()
			await this.loadSummaryData()
			await this.setFilters()
			this.setColumnClickEvent()

			this.$nextTick(() => document.querySelector('.jwTable').scrollTo({ top: this.scrollTop }))
		},
		routeOrderDetailPage(orderId) {
			this.setScrollTop(document.querySelector('.jwTable').scrollTop)
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: orderId,
				},
			})
		},
		routeOrderItemDetailPage(orderItemId) {
			this.setScrollTop(document.querySelector('.jwTable').scrollTop)
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: orderItemId,
				},
			})
		},
		routeLabDetail(row) {
			if (row.isBlock === 1) {
				this.setScrollTop(document.querySelector('.jwTable').scrollTop)
				this.$router.push({
					name: PageName.Buyer.LabDetail,
					params: {
						id: row.institute.id,
					},
				})
			}
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())

			await this.lookupOrderList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			await this.lookupOrderListSummary()
			this.setSummaryData()
		},
		setData() {
			const response = this.getOrderListTableData
			this.totalCount = response.count
			this.table.options.tableData = response.tableData
			if (this.$cookies.get(this.cookieVisibleColumnsKey)) this.setVisibleOption(JSON.parse(this.$cookies.get(this.cookieVisibleColumnsKey)))
		},
		scrollToTop() {
			document.querySelector('.jwTable').scrollTo(0, 0)
		},
		setSummaryData() {
			this.statusList = Object.keys(this.getOrderListSummary.summary).map(summary => {
				return {
					id: summary,
					title: this.getStatusTitle(summary),
					count: this.getOrderListSummary.summary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		getStatusTitle(key) {
			const result = {
				ALL: '전체',
				APPROVE: '승인 완료',
				CANCEL: '주문취소',
				SHIPPING_COMPLETE: '배송완료',
				SHIPPING_ING: '배송중',
				WAIT: '승인 대기',
			}[key]

			return result
		},
		setColumnClickEvent() {
			this.table.options.columns.forEach((data, index) => {
				if (data.key === TableField.ReleaseVolume) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItem = row
						this.isShowOrderModifyModal = true
					}
				}
				if (data.key === TableField.DeliveryMethod) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItemList = [row]
						this.isShowShippingInfoModal = true
					}
				}
				if (data.key === TableField.OrderPrice) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.changePriceRow = row
						this.isShowPriceModal = true
					}
				}
				if (data.key === TableField.OrderNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderDetailPage(data.id)
					}
				}
				if (data.key === TableField.OrderItemNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderItemDetailPage(data.id)
					}
				}
				if (data.key === TableField.Status) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeLabDetail(data.id)
					}
				}
			})
		},
		setVisibleOption(visibleNameList) {
			this.table.options.columns = [
				...this.sourceTable.options.columns.filter(item => {
					return visibleNameList.includes(item.name)
				}),
			]
			this.visibleColumns = visibleNameList
			this.$cookies.set(this.cookieVisibleColumnsKey, JSON.stringify(visibleNameList))
			this.setColumnClickEvent()
		},
		hidePriceModal() {
			this.isShowPriceModal = false
			this.loadListData()
		},
		async setFilters() {
			await this.lookupOrderListFilter()

			this.filters['브랜드'] = []
			this.filters['배송방식'] = []
			this.getOrderListFilter.filter.brands.forEach(item => {
				this.filters['브랜드'].push({ name: item.name, value: item.id })
			})
			this.getOrderListFilter.filter.shippingMethods.forEach(item => {
				this.filters['배송방식'].push({ name: item, value: item })
			})
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			const periodType = {
				ORDER_DATE: '기간 조회/주문일',
				RELEASE_DATE: '기간 조회/예상출고일',
				SHIPPING_DATE: '기간 조회/배송시작일',
			}

			this.listParams.orderStartDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[0] : undefined
			this.listParams.orderEndDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[1] : undefined
			this.listParams.estimatedReleaseStartDatetime = filters[periodType.RELEASE_DATE]?.length
				? filters[periodType.RELEASE_DATE][0].value[0]
				: undefined
			this.listParams.estimatedReleaseEndDatetime = filters[periodType.RELEASE_DATE]?.length
				? filters[periodType.RELEASE_DATE][0].value[1]
				: undefined
			this.listParams.releaseStartDatetime = filters[periodType.SHIPPING_DATE]?.length
				? filters[periodType.SHIPPING_DATE][0].value[0]
				: undefined
			this.listParams.releaseEndDatetime = filters[periodType.SHIPPING_DATE]?.length ? filters[periodType.SHIPPING_DATE][0].value[1] : undefined

			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''

			this.loadListData()
		},
		searchCheckList(filters) {
			let brandIds = []
			let shippingMethods = []

			if (filters['브랜드']?.length) {
				filters['브랜드'].forEach(brand => {
					brandIds.push(brand.value)
				})
			}

			if (filters['배송방식']?.length) {
				filters['배송방식'].forEach(el => {
					shippingMethods.push(el.value)
				})
			}

			this.listParams.brandIds = brandIds
			this.listParams.shippingMethods = shippingMethods
			this.loadListData()
		},
		onClickShowGuide() {
			this.isShowOrderQuideModal = true
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		getCurrentPage(page) {
			const { offset, length } = page
			this.listParams.offset = offset
			this.listParams.length = length
			this.scrollToTop()
			this.loadListData()
		},
		async onClickDownloadOrderList() {
			try {
				const response = await this.downloadOrderList(this.listParams)
				window.fileDownload(response, '전체 주문관리 목록')
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, ToastType.ERROR)
			}
		},
		makeEstimate() {
			if (!this.checkedList.length) {
				this.modalSubtext = '견적서 작성할 상품을 선택해주세요.'
				return (this.isShowCautionModal = true)
			}
			let ids = []
			this.checkedList.forEach(item => {
				ids.push(item.orderItemId)
			})

			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'add',
				},
				query: {
					ids: JSON.stringify(ids),
				},
			})
		},
		onClickTransactionStatement() {
			if (!this.checkedList.length) {
				this.modalSubtext = '거래명세서 출력할 상품을 선택해주세요.'
				return (this.isShowCautionModal = true)
			}

			let ids = []
			this.checkedList.forEach(item => {
				ids.push(item.orderItemId)
			})
			this.$router.push({
				name: PageName.Order.TransactionStatement,
				query: {
					ids: ids,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.order-wrapper {
	width: 100%;
	height: 100%;
	.order-header {
		width: 100%;
		height: 80px;
		position: sticky;
		display: flex;
		flex-direction: column;
		z-index: 11;
		.header-wrapper {
			height: 55px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 25px;
			border-bottom: 1px solid $LINE_DIVIDER;
			.header-title {
				text-align: left;
				font-weight: 700;
			}
			.guide-button {
				width: 140px;
				height: 36px;
				font-size: 0.875rem;
				font-weight: 700;
			}
		}
	}
	.order-filter-wrapper {
		padding: 0 30px 20px 30px;
		.search-box {
			margin-top: 33px;
		}
	}
	.order-list-wrapper {
		padding: 0 30px;
		.title-btn-wrapper {
			display: flex;
			justify-content: space-between;
			padding-top: 20px;
		}
		.btn-wrapper {
			display: flex;
			gap: 10px;
		}
		.table-wrapper {
			padding-top: 10px;
			.pagenation-wrapper {
				position: relative;
				z-index: 100;
			}
		}
	}
}
</style>
