<template>
	<base-item :title="title">
		<template>
			<search-box noRecent class="search-box" placeholder="소속, 학교명/기관명 검색" @change="changeKeyword"></search-box>
			<table class="lm-table">
				<tr class="table-header">
					<th>
						<span><input type="checkbox" :checked="isChecekdAll" @change="checkedAll" /></span>
					</th>
					<th><span>소속</span></th>
				</tr>
				<tr class="table-item" v-for="item in targetList" :key="item.id">
					<td><input type="checkbox" @change="checked(item.id)" :checked="checkedList.includes(item.id)" /></td>
					<td>{{ item.name }}</td>
				</tr>
				<tr class="table-footer">
					<td colspan="2">선택된 타겟: {{ checkedList.length }}</td>
				</tr>
			</table>
			<pagination :total="targetCount" @page="pagination" :defaultOffset="10"></pagination>
		</template>
	</base-item>
</template>
<script>
import Pagination from '@/views/common/components/CommonPagenation.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import SearchBox from '@/views/common/components/searchBox/index.vue'
import { mapActions, mapState } from 'vuex'
export default {
	components: {
		BaseItem,
		SearchBox,
		Pagination,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			loadParams: {
				keyword: null,
				offset: 0,
				length: 10,
			},
			checkedList: [],
		}
	},
	computed: {
		...mapState('lms', ['targetList', 'targetCount']),
		isChecekdAll() {
			return this.targetList.length == this.targetList.filter(it => this.checkedList.includes(it.id)).length
		},
	},
	async created() {
		await this.getTargetList(this.loadParams)
	},
	watch: {
		checkedList() {
			this.$emit('changeValue', this.checkedList)
		},
	},
	methods: {
		...mapActions('lms', ['getTargetList']),
		changeKeyword(value) {
			this.loadParams.keyword = value
			this.getTargetList(this.loadParams)
		},
		pagination(page) {
			this.loadParams.offset = page.offset
			this.loadParams.length = page.length
			this.getTargetList(this.loadParams)
		},
		checked(id) {
			if (this.checkedList.includes(id)) this.checkedList.remove(id)
			else this.checkedList.push(id)
		},
		checkedAll() {
			if (this.isChecekdAll) {
				this.targetList.forEach(it => this.checkedList.remove(it.id))
			} else {
				this.targetList.forEach(it => {
					if (!this.checkedList.includes(it.id)) this.checkedList.push(it.id)
				})
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.search-box {
	width: 400px;
}
.lm-table {
	position: relative;
	max-height: 600px;
	width: 100%;
	text-align: left;
	border-collapse: collapse;
	border-radius: 4px;
	border-style: hidden;
	box-shadow: 0 0 0 1px #eee;
	border-radius: 4px;
	margin-top: 20px;
	.table-header {
		height: 40px;
		th {
			background-color: #f5f5f5;
			height: 40px;
			padding-left: 24px;
			&:first-child {
				width: 100px;
			}
		}
	}
	.table-item {
		height: 60px;
		border-top: 1px solid #eee;
		td {
			padding-left: 24px;
		}
	}
	.table-footer {
		background-color: #f5f5f5;
		border-top: 1px solid #ddd;
		text-align: center;
		td {
			font-size: 12px;
			height: 30px;
		}
	}
}
</style>
