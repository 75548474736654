<template>
	<div class="upload-wrapper">
		<base-item title="팝업 이미지" class="image-upload-wrapper">
			<base-button size="md" @click="onClickAddImage">이미지 선택</base-button>
			<div class="subtext">
				<b>로고 이미지</b>&nbsp;
				<span>340x120</span>
			</div>
			<div v-show="!hasFile" class="drag-area">
				<input ref="image-file" type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
				<h2>+</h2>
				<h5>이미지 업로드</h5>
				<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
			</div>
			<div class="upload-files-wrapper" v-show="hasFile">
				<div v-if="popupForm.image" class="image-box">
					<img :src="imageSrc" />
					<button class="close-btn" @click="deleteFile">
						<img src="@/assets/svg/icon-trash.svg" />
					</button>
				</div>
			</div>
		</base-item>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import InputBannerImage from '@/views/common/components/formItem/InputBannerImage.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	components: {
		InputText,
		BaseItem,
		InputBannerImage,
		BaseButton,
	},
	data() {
		return {
			popupForm: {
				width: 340,
				height: 120,
				image: null,
				deleteImage: false,
			},
		}
	},
	watch: {
		popupForm: {
			deep: true,
			handler() {
				this.$emit('changeValue', this.popupForm)
			},
		},
	},
	computed: {
		hasFile() {
			return this.popupForm.image != null
		},
		imageSrc() {
			if (this.popupForm.image?.constructor === Object) return this.popupForm.image.url
			else if (this.popupForm.image.constructor === File) return URL.createObjectURL(this.popupForm.image)
			else if (this.popupForm.image.constructor === String) return this.popupForm.image
			return null
		},
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['image-file'].files = event.dataTransfer.files
				this.uploadImage()
			}
		},
		setForm(file) {
			var reader = new FileReader()
			const data = this
			reader.onload = function (e) {
				var img = new Image()
				img.src = e.target.result
				img.onload = function () {
					var w = this.width
					var h = this.height
					if (w == 340 && h == 120) data.popupForm.image = file
					else data.$root.toast('에러', '340x120의 이미지를 업로드해주세요.', ToastType.ERROR)
				}
			}
			reader.readAsDataURL(file)
		},
		uploadImage() {
			this.popupForm.deleteImage = true
			const image = [...Object.values(this.$refs['image-file'].files)]
			if (image.length > 0) {
				image.forEach(it => this.setForm(it))
			}
			this.files = image
		},
		onClickAddImage() {
			this.$refs['image-file'].click()
		},
		deleteFile() {
			this.popupForm.deleteImage = true
			this.popupForm.image = null
			this.$refs['image-file'].value = ''
		},
		setItem(url) {
			this.popupForm.image = url
		},
	},
}
</script>

<style lang="scss" scoped>
.image-upload-wrapper {
	.subtext {
		margin-top: 10px;
		font-size: 14px;
	}
	.drag-area {
		margin-top: 10px;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 340px;
		height: 120px;
		background: #f5f5f5;
		border: 1px solid #ddd;
		border-radius: 5px;
		& > * {
			color: #999;
		}
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
		&.error {
			border-color: $PRIMARY_RED;
		}
	}
	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
	.upload-files-wrapper {
		& > div {
			gap: 25px;
			display: flex;
			align-items: baseline;
		}
		.image-box {
			width: 340px;
			height: 120px;
			position: relative;
			border: 1px solid #ddd;
			border-radius: 5px;
			background-color: #f5f5f5;
			max-height: 1000px;
			text-align: center;
			img {
				border-radius: 5px;
				max-width: 100%;
				max-height: 100%;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
			.close-btn {
				left: 8px;
				top: 8px;
				position: absolute;
				width: 30px;
				height: 30px;
				background-color: #f5f5f5;
				border-radius: 4px;
				border: 1px solid #ddd;
			}
		}
	}
}
</style>
