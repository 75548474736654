import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Product.ProductList,
		name: PageName.Product.ProductList,
		component: () => import('@/views/product/list/pages/ProductList.vue'),
	},
	{
		path: RoutePath.Product.ProductListDetail,
		name: PageName.Product.ProductListDetail,
		component: () => import('@/views/product/list/pages/ProductDetail.vue'),
	},
	{
		path: RoutePath.Product.ProductRegistration.Main,
		name: PageName.Product.ProductRegistration.Main,
		props: true,
		component: () => import('@/views/product/registration/pages/ProductRegistration.vue'),
		children: [
			{
				path: RoutePath.Product.ProductRegistration.ProductInfo,
				name: PageName.Product.ProductRegistration.ProductInfo,
				component: () => import('@/views/product/registration/components/productInfo/ProductInfo.vue'),
			},
			{
				path: RoutePath.Product.ProductRegistration.SellInfo,
				name: PageName.Product.ProductRegistration.SellInfo,
				component: () => import('@/views/product/registration/components/sellInfo/SellInfo.vue'),
			},
			{
				path: RoutePath.Product.ProductRegistration.ShippingInfo,
				name: PageName.Product.ProductRegistration.ShippingInfo,
				component: () => import('@/views/product/registration/components/shippingInfo/ShippingInfo.vue'),
			},
			{
				path: RoutePath.Product.ProductRegistration.ReturnInfo,
				name: PageName.Product.ProductRegistration.ReturnInfo,
				component: () => import('@/views/product/registration/components/returnInfo/ReturnInfo.vue'),
			},
			{
				path: RoutePath.Product.ProductRegistration.ConsultingInfo,
				name: PageName.Product.ProductRegistration.ConsultingInfo,
				component: () => import('@/views/product/registration/components/consultingInfo/ConsultingInfo.vue'),
			},
		],
	},
	{
		path: RoutePath.Product.ApprovalProductList,
		name: PageName.Product.ApprovalProductList,
		component: () => import('@/views/product/approval/pages/ApprovalProductList.vue'),
	},
	{
		path: RoutePath.Product.ApprovalProductDetail,
		name: PageName.Product.ApprovalProductDetail,
		props: true,
		component: () => import('@/views/product/approval/pages/ApprovalProductDetail.vue'),
	},
	{
		path: RoutePath.Product.UpdateProductPriceList,
		name: PageName.Product.UpdateProductPriceList,
		component: () => import('@/views/product/updatePrice/pages/UpdateProductPriceList.vue'),
	},
]
