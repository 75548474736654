<template>
	<div class="RequestProducDetail">
		<div class="content-header">
			<div class="title">
				<h4>{{ requestProduct.no }}</h4>
				<div class="subtitle">
					<span class="status-text" :class="statusClass">{{ statusText }}</span>
					<p>요청 일시: {{ isData(formatDate(requestProduct.createDatetime, dateFormat)) }}</p>
					<p v-if="requestProduct.status !== 'WAIT'">요청 접수일시: {{ isData(formatDate(requestProduct.takeDatetime, dateFormat)) }}</p>
					<p v-if="requestProduct.status === 'COMPLETE' || requestProduct.status === 'FAIL'">
						소싱 처리일시: {{ isData(formatDate(requestProduct.completeDatetime, dateFormat)) }}
					</p>
				</div>
			</div>
			<div class="right-btn-group">
				<button class="btn-confirm" v-if="requestProduct.status === 'WAIT'" @click="updateConfirmReceipt">접수 확인</button>
				<button class="Btn__export" v-if="requestProduct.status === 'ING'" @click="showFailSourcing">소싱 실패</button>
				<button class="btn-confirm" v-if="requestProduct.status === 'ING'" @click="showCompleteSourcing">소싱 완료</button>
				<!-- <button class="Btn__yellow" v-if="requestProduct.status === 'COMPLETE' || requestProduct.status === 'FAIL'" @click="writeEstimate">
					견적서 작성
				</button> -->
			</div>
		</div>
		<div class="content-wrapper">
			<info-list title="사다드림 요청 정보" :list="productRequestInfo" />
			<info-list title="요청상품 정보" :list="requestProductInfo" @onClickImage="onClickImage" />
			<info-list title="요청자 정보" :list="requesterInfo" />
			<textarea-edit-form
				title="숨김 메모"
				content="*해당 메모는 상품요청자에게 노출되지 않는 정보입니다"
				:defaultValue="requestProduct.adminMemo"
				@save="value => saveTextForm(value, 'adminMemo')"
			/>

			<product-info-table
				v-if="requestProduct.status !== 'WAIT'"
				title="상품정보"
				:isShowTotalPrice="false"
				:isRemove="isShowBtn"
				:headers="productInfoTableHeader"
				:isBtnGroup="isShowBtn"
				:btnGroup="productTableBtnGroup"
				:products="requestProduct.products"
				@clickEv="productTableEv"
				@delete="removeProductItem"
			/>

			<!-- <template v-if="!isEmptyEstimate">
				<div class="title"><h5>연결된 견적서</h5></div>
				<table class="lm-table">
					<table-header />
					<table-item v-for="item in requestProduct.estimates" :key="item.id" :item="item" :isShowCopyButton="false" />
					<div class="empty-table" v-if="estimateCount === 0">
						<img class="empty-img" src="@/assets/svg/common/inventory-empty-data.svg" />
						<p class="bold">검색 결과가 없습니다</p>
						<p class="sub">작성하거나, 전달받은 견적이 없습니다.</p>
					</div>
				</table>
			</template> -->

			<div class="btn-wrap bottom">
				<button class="btn-confirm" v-if="requestProduct.status === 'WAIT'" @click="updateConfirmReceipt">접수 확인</button>
				<button class="Btn__export" v-if="requestProduct.status === 'ING'" @click="showFailSourcing">소싱 실패</button>
				<button class="btn-confirm" v-if="requestProduct.status === 'ING'" @click="showCompleteSourcing">소싱 완료</button>
				<!-- <button class="Btn__yellow" v-if="requestProduct.status === 'COMPLETE' || requestProduct.status === 'FAIL'" @click="writeEstimate">
					견적서 작성
				</button> -->
				<button class="Btn__yellow" v-if="requestProduct.status === 'COMPLETE' && requestProduct.type === 'PURCHASE_AGENT'" @click="print">
					견적서 다운로드
				</button>
			</div>
		</div>
		<modal-common ref="modal-common" @isHide="$refs['modal-common'].hide()"></modal-common>
		<modal-submit :title="modalTitle" :content="modalContent" @ok="confirm" />
		<modal-image-preview ref="modal-image-preview"></modal-image-preview>
		<modal-search-product title="상품 검색" @add="onAddProduct" :type="requestProduct.type ?? 'NORMAL'" />
		<modal-product-request-management title="상품요청 관리" :productInfoId="productInfoId" @add="onAddProduct"></modal-product-request-management>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
import ProductInfoTable from '@/components/product/ProductInfoTable'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ModalImagePreview from '@/views/product/registration/modals/ModalImagePreview.vue'
import { RequestProduct } from '@/utils/define/ItemCode'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'
import ModalSearchProduct from '@/views/common/modals/ModalSearchProduct'
import ModalProductRequestManagement from '@/views/common/modals/ModalProductRequestManagement.vue'
import headers from '@/utils/define/table/header'
import TableHeader from '@/views/estimate/estimate/components/TableHeader.vue'
import TableItem from '@/views/estimate/estimate/components/TableItem.vue'
import RoutePath from '@/utils/define/RoutePath'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
export default {
	name: 'RequestProductDetail',
	components: {
		ModalCommon,
		InfoList,
		TextareaEditForm,
		ProductInfoTable,
		ModalSubmit,
		ModalImagePreview,
		ModalSearchProduct,
		ModalProductRequestManagement,
		TableHeader,
		TableItem,
	},
	data() {
		return {
			headers,
			dateFormat: 'yyyy-MM-dd T',
			mode: '',
			productInfoTableHeader: [
				{ name: '상품 정보', key: 'info', width: '60%' },
				{ name: '매입가', key: 'salesUnitPrice', width: '7%' },
				{ name: '수량', key: 'quantity', width: '70px' },
				{ name: '소비자가', key: 'price', width: '10%' },
				{ name: '판매가', key: 'sellingPrice', width: '15%' },
			],
			productTableBtnGroup: [
				{
					key: 'add',
					image: require('@/assets/svg/common/icon-add-product.svg'),
					text: '새 상품등록',
				},
				{
					key: 'search',
					image: require('@/assets/svg/common/group-search.svg'),
					text: '상품 검색하기',
				},
			],
			productInfoId: 10,
			type: 'NORMAL',
		}
	},
	computed: {
		...mapState('request/detail', ['requestProduct']),
		...mapState('productInfo/detail', ['item']),
		isEmpty() {
			return this.$_.isEmpty(this.requestProduct)
		},
		isEmptyEstimate() {
			if (this.isEmpty) return false
			return this.$_.isEmpty(this.requestProduct.estimates)
		},
		isEstimateText() {
			const { status, estimates } = this.requestProduct
			if (['COMPLETE', 'FAIL'].includes(status)) {
				if (estimates?.length > 0) return '작성 완료'
			}

			return '-'
		},
		isShowBtn() {
			if (this.isEmpty) return false
			const { status } = this.requestProduct
			if (['COMPLETE', 'FAIL'].includes(status)) {
				return false
			}
			return true
		},
		estimateCount() {
			if (this.isEmpty) return 0
			const { estimates } = this.requestProduct
			return estimates.length
		},
		isShowBtnGroup() {
			if (this.isEmpty) return false
		},
		productRequestInfo() {
			// 상품요청 정보
			if (this.isEmpty) return
			const { no, status, createDatetime, takeDatetime } = this.requestProduct
			let list = []
			list.push({ title: '상품 요청번호', value: this.isData(no) })
			list.push({ title: '상품 요청상태', value: RequestProduct.statusToString(status), type: 'bold' })
			list.push({ title: '상품 요청일시', value: this.isData(this.formatDate(createDatetime, this.dateFormat)) })
			list.push({ title: '요청 접수일시', value: this.isData(this.formatDate(takeDatetime, this.dateFormat)) })
			list.push({
				title: '소싱 처리일시',
				value: this.isData(this.formatDate(this.requestProduct.completeDatetime, this.dateFormat)),
			})
			list.push({ title: '견적 작성여부', value: this.isEstimateText })
			return list
		},
		requestProductInfo() {
			// 요청상품 정보
			if (this.isEmpty) return
			const { name, brandName, productCode, casno, volume, quantity, comment, attachments } = this.requestProduct
			let list = []
			list.push({ title: '상품 명', value: this.isData(name), row: true })
			list.push({ title: '브랜드', value: this.isData(brandName) })
			list.push({ title: '제품번호', value: this.isData(productCode) })
			list.push({ title: 'CAS No', value: this.isData(casno) })
			list.push({ title: '사이즈(용량)', value: this.isData(volume) })
			list.push({ title: '수량', value: this.isData(quantity) })
			list.push({
				title: '상품 유형',
				value: this.type,
				type: 'selection',
				options: [
					{
						value: 'NORMAL',
						text: '일반 상품',
					},
					{
						value: 'PURCHASE_AGENT',
						text: '구매 대행',
					},
				],
				onChange: value => {
					this.updateType(value)
				},
			})
			list.push({ title: '추가 요청 사항', value: this.isData(comment), row: true })
			if (!this.$_.isEmpty(attachments)) {
				list.push({ title: '이미지', value: attachments, row: true, type: 'image' })
			}
			return list
		},
		requesterInfo() {
			// 요청자 정보
			if (this.isEmpty) return
			const { user, institute } = this.requestProduct
			const { name: instituteName } = institute ?? { name: '' }
			const { name, phone, email } = user
			let list = []
			list.push({ title: '상품요청자 소속', value: this.isData(instituteName) })
			list.push({ title: '상품요청자', value: this.isData(name) })
			list.push({ title: '상품요청자 연락처', value: this.isData(phone) })
			list.push({ title: '상품요청자 이메일', value: this.isData(email) })
			return list
		},
		statusClass() {
			const { status } = this.requestProduct
			return RequestProduct.statusToClass(status)
		},
		statusText() {
			const { status } = this.requestProduct
			return RequestProduct.statusToString(status)
		},
		modalTitle() {
			if (this.mode === 'fail') {
				return '소싱 실패 처리'
			} else if (this.mode === 'complete') {
				return '소싱 완료 처리'
			}
		},
		modalContent() {
			if (this.mode === 'fail') {
				return '상품요청의 소싱을 실패 처리 하시겠습니까?<br>소싱 실패 처리는 되돌릴 수 없습니다.'
			} else if (this.mode === 'complete') {
				return '상품요청의 소싱을 완료처리 하시겠습니까?<br>소싱완료 처리는 되돌릴 수 없습니다.'
			}
		},
	},
	created() {
		this.fetchRequestProduct()
	},
	methods: {
		...mapActions('request/detail', [
			'lookupRequestProduct',
			'updateRequestProductMemo',
			'failSourcing',
			'completeSourcing',
			'confirmReceipt',
			'addProduct',
			'deleteProduct',
			'setRequestType',
			'printEstimate',
		]),
		...mapActions('productInfo/detail', ['getDetail']),
		async fetchRequestProduct() {
			try {
				const id = this.$route.params.id
				await this.lookupRequestProduct(id)
				this.type = this.requestProduct.type
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		async saveTextForm(value, field) {
			try {
				await this.updateRequestProductMemo({
					requestId: this.requestProduct.id,
					[field]: value,
				})
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		showFailSourcing() {
			this.mode = 'fail'
			this.$bvModal.show('modal-submit')
		},
		showCompleteSourcing() {
			this.mode = 'complete'
			this.$bvModal.show('modal-submit')
		},
		confirm() {
			if (this.mode === 'fail') {
				this.updateFailSourcing()
			} else if (this.mode === 'complete') {
				this.updateCompleteSourcing()
			}
		},
		async updateConfirmReceipt() {
			try {
				await this.confirmReceipt({ requestId: this.requestProduct.id })
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async updateFailSourcing() {
			try {
				await this.failSourcing({ requestId: this.requestProduct.id })
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async updateCompleteSourcing() {
			try {
				await this.completeSourcing({ requestId: this.requestProduct.id })
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async updateType(type) {
			this.type = type
			const doing = async () => {
				try {
					await this.setRequestType({ requestId: this.requestProduct.id, type })
					this.fetchRequestProduct()
					this.$root.toast('유형 변경', '유형을 변경하였습니다', ToastType.SUCCESS)
				} catch (e) {
					this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
				}
			}
			if (this.requestProduct.products.length > 0) {
				this.$refs['modal-common'].show(
					'상품 유형 변경',
					'상품 유형 변경 시 이미 등록한 물품정보가 사라집니다.',
					'complete',
					'진행하기',
					() => {
						doing()
					},
					() => {
						this.type = this.requestProduct.type
					}
				)
			} else {
				await doing()
			}
		},
		writeEstimate() {
			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'add',
				},
				query: {
					requestId: this.requestProduct.id,
				},
			})
		},
		onClickImage(image) {
			this.$refs['modal-image-preview'].show(image)
		},
		productTableEv(key) {
			if (key === 'add') {
				this.addPostMSGEvent()
				window.open(`${RoutePath.Product.ProductRegistration.Main}?requestId=${this.requestProduct.id}`)
			} else if (key === 'search') {
				this.$bvModal.show('modal-search-product')
			}
		},
		async addPostMSGEvent() {
			const self = this
			window.onmessage = function (e) {
				const { type, data } = e.data
				if (type === 'productRegistration') {
					const { productInfoId } = data
					self.fetchProductInfoDetail(productInfoId)
				}
			}
		},
		async fetchProductInfoDetail(productInfoId) {
			try {
				await this.getDetail(productInfoId)
				if (this.item.products.length > 1) this.$bvModal.show('modal-product-request-management')
				else this.onAddProduct({ productId: this.item.products[0].productId })
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async onAddProduct({ productId }) {
			try {
				await this.addProduct({ requestId: this.requestProduct.id, productId })
				this.$root.toast('상품 추가 완료', '선택한 상품이 상품정보에 추가되었습니다', ToastType.SUCCESS)
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async removeProductItem(index) {
			const { products } = this.requestProduct
			const product = products[index]
			try {
				await this.deleteProduct({ requestId: this.requestProduct.id, requestProductId: product.requestProductId })
				this.fetchRequestProduct()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async print() {
			try {
				const { data } = await this.printEstimate(this.requestProduct.id)
				window.open(data.url, '_blank')
			} catch (e) {
				console.log(e)
				this.$root.toast('견적서 다운로드', '견적서를 다운로드 할 수 없습니다. 고객센터를 통해 문의해주세요.', 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.RequestProducDetail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
					display: inline-block;
					&.error {
						color: $PRIMARY_RED;
					}
					&:after {
						width: 1px;
						height: 12px;
						content: '';
						position: absolute;
						top: calc(50% - 6px);
						left: 10px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px 40px;
		.title {
			margin-top: 40px;
			margin-bottom: 1rem;
			display: flex;
			justify-content: flex-start;
			h5 {
				font-size: 1.25rem;
				font-weight: 700;
			}
		}
		.text-edit-form {
			margin-bottom: 40px;
		}
		.product-info-table {
			margin: 40px 0;
		}
		.lm-table {
			position: relative;
			height: 100%;
			text-align: left;
			display: block;
			overflow: auto;
			white-space: nowrap;
			border: 1px solid $LINE_DIVIDER;
		}
		.empty-table {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: calc(100% - 40px);
			padding-bottom: 80px;
			.empty-img {
				margin-bottom: 24px;
			}
			.bold {
				color: #666;
				font-weight: 700;
				font-size: 16px;
				margin-bottom: 6px;
			}
			.sub {
				font-weight: 400;
				font-size: 14px;
				color: #999;
			}
		}
		.bottom {
			margin: 120px 0 60px;
			button {
				width: 200px;
				height: 48px;
				border-radius: 5px;
				font-weight: 700;
			}
		}
	}
}
</style>
