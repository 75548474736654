<template>
	<div
		class="jw-table-wrapper"
		:style="{
			...wrapperStyle,
		}"
	>
		<table class="jw-table" :style="tableStyle" cellspacing="0" cellpadding="0">
			<!-- 헤더 -->
			<tr
				class="jw-table-header"
				:style="{
					minWidth: columnWidthSum + 'px',
					...headerStyle,
				}"
			>
				<!-- 체크박스 -->
				<th class="header-th checkbox" v-if="isCheckbox">
					<input :class="{ selectSome: isSelectSome }" type="checkbox" id="checkAll" @click.stop="selectAll" :checked="isSelectedAll" />
					<label for="checkAll"></label>
				</th>
				<th
					v-for="column in options.columns"
					class="header-th"
					:class="{ 'use-sort': column.useSort, active: isSortActiveColumnKey(column.key) }"
					:style="{
						minWidth: `${column.style.width}`,
					}"
					:key="`column-${column.key}`"
					@click.stop="column.useSort ? toggleSort(column) : () => {}"
				>
					<slot name="header" :column="column"> </slot>
				</th>
			</tr>
			<!-- Data -->
			<tbody
				class="jw-table-body-area"
				:style="{
					minWidth: columnWidthSum + 'px',
				}"
			>
				<tr
					v-for="(notUse, i) in dataLength"
					:key="`column-${i}`"
					class="jw-table-row"
					:class="{
						new: selectColumnKey && tableData[selectColumnKey][i].data.isNew,
					}"
					:style="{
						...tableRow.style,
						minWidth: columnWidthSum + 'px',
					}"
					@click.stop="options.row.click ? rowClickEvent(tableData[options.row.click.rowDataName][i].data) : null"
				>
					<!-- 체크박스 -->
					<td class="jw-table-data checkbox" v-if="isCheckbox">
						<input
							type="checkbox"
							:id="`check-${i}`"
							:value="selectColumnKey && tableData[selectColumnKey][i].data.id"
							:disabled="selectColumnKey && tableData[selectColumnKey][i].data.disabledCheckbox"
							v-model="selectList"
							@click.stop
						/>
						<label :for="`check-${i}`"></label>
					</td>
					<td
						v-for="column in options.columns"
						class="jw-table-data"
						:class="[...column.classes]"
						:key="`column-${column.key}-${i}`"
						:style="`
							minWidth:${column.style.width};
							maxWidth:${column.style.width};
							width:${column.style.width};
							height: ${tableRow.style.height};
						`"
						@mouseover.stop="hoverMouse = true"
						@mouseleave.stop="hoverMouse = false"
					>
						<div :class="{ disable: selectColumnKey && tableData[selectColumnKey][i].data.disabledRow }">
							<slot
								v-if="$scopedSlots[column.type]"
								:name="column.type"
								:data="tableData[column.key][i].data"
								:column="column"
								:keyword="keyword"
							>
							</slot>
							<slot v-else :data="tableData[column.key] && tableData[column.key][i].data" :column="column" :keyword="keyword"></slot>
							<!-- 물품예약하기 컴포넌트 사용 -->
						</div>
						<div class="balloon-wrapper" v-if="column.reservationBalloon && tableData[selectColumnKey][i].data.disabledRow">
							<div class="balloon">
								<p class="balloon-text">선택하신 사용기간에 예약을 할 수 없습니다. [날짜 조정]을 클릭하여 날짜를 변경해주세요.</p>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>

		<!-- 데이터가 없거나 검색 데이터가 없는 경우 : Start-->
		<div class="table-nodata" v-if="noData" :style="{ '--noDataHeight': noDataHeight }">
			<div class="table-nodata-wrapper">
				<div class="table-nodata-img-wrapper">
					<img :src="noDataImg" />
				</div>
				<div class="table-nodata-text" v-html="noDataText"></div>
				<div class="table-nodata-subtext" v-html="noDataSubtext"></div>
				<button class="table-nodata-btn" :class="noDataBtn.color" v-if="noDataBtn.text !== ''" @click="onClickNoDataBtn">
					{{ noDataBtn.text }}
				</button>
			</div>
		</div>
		<!-- 데이터가 없거나 검색 데이터가 없는 경우 : End -->
		<!-- 입고 완료인 경우 : Start-->
		<div class="table-complete" v-if="isComplete">
			<div class="table-complete-wrapper">
				<div class="table-complete-img-wrapper">
					<img :src="completeCheckImg" />
				</div>
				<div class="table-complete-text" v-html="isCompleteText"></div>
			</div>
		</div>
		<!-- 입고 완료인 경우 : End -->
	</div>
</template>

<script>
import ImgCompleteCheck from '@/assets/svg/common/jw-table/complete-check-green.svg'
export default {
	props: {
		// 체크박스 존재 여부
		isCheckbox: {
			type: Boolean,
			default: false,
		},
		wrapperStyle: {
			type: Object,
			default: () => {},
		},
		// 테이블 데이터 옵션
		options: {
			type: Object,
		},
		keyword: {
			type: String,
			default: '',
		},
		noData: {
			type: Boolean,
			default: false,
		},
		noDataText: {
			type: String,
			default: '',
		},
		noDataImg: {
			type: String,
			default: '',
		},
		noDataSubtext: {
			type: String,
			default: '',
		},
		noDataHeight: {
			type: String,
			default: '300px',
		},
		isComplete: {
			type: Boolean,
			default: false,
		},
		isCompleteText: {
			type: String,
			default: '',
		},
		noDataBtn: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		selectList: {
			deep: true,
			handler(newValue) {
				this.$emit('getSelectedItem', newValue)
			},
		},
	},
	data() {
		return {
			selectList: [],
			hoverMouse: false,
			completeCheckImg: ImgCompleteCheck,
		}
	},
	mounted() {},
	computed: {
		columnWidthSum() {
			return this.options.columns
				.map(column => {
					const width = column.style.width
					const value = width.match(/\d+/g)
					return Number(value)
				})
				.reduce((data1, data2) => data1 + data2, 100)
		},
		tableStyle() {
			return this.options.table ? this.options.table.style : null
		},
		headerStyle() {
			return this.options.header ? this.options.header.style : null
		},
		columnKeys() {
			return this.options?.columns.map(item => item.key)
		},
		tableRow() {
			return this.options.row
		},
		tableData() {
			return this.options.tableData
		},
		dataLength() {
			if (this.columnKeys && this.columnKeys.length > 0) {
				return this.options.tableData[this.columnKeys[0]].length
			} else {
				return 0
			}
		},
		selectColumnKey() {
			return this.options.selectColumnKey ?? this.options.columns[0]
		},
		tableDataWithoutDisable() {
			return this.tableData[this.selectColumnKey].filter(item => item.data.id && !item.data.disabledCheckbox).map(item => item.data.id)
		},
		isSelectedAll() {
			if (this.selectColumnKey) {
				const totalData = this.tableDataWithoutDisable
				return this.selectList.length === totalData.length && this.selectList.length !== 0
			} else {
				return false
			}
		},
		isSelectSome() {
			const totalData = this.tableDataWithoutDisable
			return this.selectList.length < totalData.length && this.selectList.length !== 0
		},
	},
	methods: {
		selectAll() {
			if (this.selectColumnKey) {
				const totalData = this.tableDataWithoutDisable

				if (this.isSelectedAll) {
					this.selectList = []
				} else {
					this.selectList = [...totalData]
				}
			}
		},
		rowClickEvent(data) {
			const keyName = this.options.row.click.keyName
			const resultData = keyName ? data[this.options.row.click.keyName] : data
			return this.options.row.click.clickEvent(resultData)
		},
		toggleSort(column) {
			column.sortAsc = !column.sortAsc
			this.$emit('sortColumn', column)
		},
		resetSelectItem() {
			this.selectList = []
		},
		isSortActiveColumnKey(columnKey) {
			return this.options.sortActiveColumnKey === columnKey
		},
		onClickNoDataBtn() {
			this.$emit('onClickNoDataBtn')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_colors.scss';

input[type='checkbox']:disabled:after {
	background-image: url(~@/assets/svg/common/jw-table/checkbox-disabled.svg) !important;
}
.table-nodata {
	display: flex;
	justify-content: center;
	align-items: center;
	height: var(--noDataHeight);
	.table-nodata-wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.table-nodata-img-wrapper {
		}
		.table-nodata-text {
			font-size: 16px;
			font-weight: 700;
			margin: 24px 0 3px 0;
			color: $COLOR_666;
		}
		.table-nodata-subtext {
			font-size: 14px;
			font-weight: 400;
			margin: 3px;
			color: $COLOR_999;
		}
		.table-nodata-btn {
			height: 30px;
			border-radius: 4px;
			font-weight: 700;
			font-size: 14px;
			align-items: center;
			justify-content: center;
			padding: 5px 10px;
			margin-top: 10px;
		}
		.blue {
			color: $PRIMARY_WHITE;
			background: $PRIMARY_YELLOW;
		}
	}
}

.table-complete {
	display: flex;
	width: 100%;
	height: 50px;
	.table-complete-wrapper {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: center;
		.table-complete-text {
			padding-top: 3px;
			color: $PRIMARY_GREEN;
			font-weight: 700;
			font-size: 16px;
		}
	}
}

.jw-table-wrapper {
	min-height: 600px;
	position: relative;
	overflow: auto;
	border-top: 0.5px solid $INPUT_BORDER;
}

.jw-table {
	position: relative;
	display: flex;
	flex-direction: column;
}
.jw-table-header {
	position: sticky;
	top: 0px;
	box-shadow: 0px 2px 2px 0px #0000001a;
	padding: 0px 30px;
	background-color: $TABLE_HEADER;
	z-index: $JW_TABLE_HEADER;
}

.jw-table-header-area {
	border: none;
	display: flex;
	align-items: center;
	height: 40px;
	font-size: 14px;
}

.jw-table-body-area {
	display: flex;
	flex-direction: column;
	padding: 0px 0px;
	z-index: $JW_TABLE_BODY;
}
.jw-table-data {
	width: 100%;
	height: 60px;
	justify-content: center;
	align-items: center;
	font-size: 14px;
	font-weight: 400;
	color: $COLOR_111;
	&.bold {
		font-weight: 700;
	}
	&.checkbox {
		position: relative;
		left: -5px;
		min-width: 30px;
		width: 30px;
		text-align: center;
		input[type='checkbox'] {
			top: -2px;
		}
	}
}
.jw-table-row {
	padding: 0px 30px;
	border-bottom: 0.5px solid $INPUT_BORDER;
	transition: all ease-in-out 0.3s;
	&.new {
		background-color: $JW_100;
		&:hover {
			background-color: $JW_200;
		}
	}

	&.expired {
		&:before {
			content: '';
			width: 100%;
			height: 60px;
			opacity: 0.4;
			position: relative;
			top: 0px;
			left: 0px;
			background-color: white;
		}
		// opacity: 0.4;
	}
	&:hover {
		background-color: $LIST_HOVER;
		.balloon-wrapper {
			position: absolute;
			visibility: visible;
			width: 100%;
			.balloon {
				position: absolute;
				display: block;
				padding: 12px 15px;
				border-radius: 5px;
				top: 10px;
				left: 200px;
				color: white !important;
				background: $COLOR_333;
				font-weight: normal;
				font-size: 0.8125rem;
				z-index: 11;
				border: 1px solid rgba(0, 0, 0, 0.6);
				box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
				max-width: 280px;
				box-sizing: border-box;
				white-space: initial;
				text-align: left;
			}

			.balloon:after {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid $COLOR_333;
				content: '';
				position: absolute;
				top: -7px;
				left: 130px;
				z-index: 1004;
			}
			.balloon:before {
				border-top: 0px solid transparent;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid rgba(0, 0, 0, 0.6);
				content: '';
				position: absolute;
				top: -8px;
				left: 130px;
				z-index: 1003;
			}
		}
		td {
			input[type='checkbox'] {
				&:before {
					background-color: $LIST_HOVER;
				}
			}
		}
	}
	.balloon-wrapper {
		position: absolute;
		visibility: hidden;
		width: 100%;
		.balloon {
			position: absolute;
			padding: 12px 15px;
			border-radius: 5px;
		}
	}
}

.header {
	color: $COLOR_666;
}
.header-gray {
	background-color: $TABLE_HEADER;
}

.header-th {
	position: sticky;
	top: 0px;
	text-align: left;
	.separator {
		display: inline;
		height: 100%;
		border-left: 1px solid $COLOR_DDD !important;
		padding-right: 8px;
	}
	&.active {
		background-color: $LIST_BACKGROUND;
	}

	&.use-sort {
		cursor: pointer;
	}

	&.checkbox {
		position: relative;
		left: -5px;
		min-width: 30px;
		width: 30px;
		text-align: center;
	}
}

.align-left {
	text-align: left;
}

.gray {
	color: $COLOR_666;
}

// .header-th {
// 	resize: vertical;
// }

input[type='checkbox'].selectSome::after {
	width: 18px;
	height: 18px;
	background-color: $PRIMARY_BLACK;
	background-image: url(~@/assets/svg/common/jw-table/checkbox-some.svg);
	background-size: 18px;
	background-position: center !important;
	border: 1px solid $PRIMARY_BLACK;
}
</style>
