<template>
	<portal to="lm-modal">
		<div class="modal-order-management-guide-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">주문관리 가이드</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="image-wrapper">
						<img src="@/assets/svg/order/order-management-guide.svg" alt="" />
					</div>
					<div class="order-status-description-wrapper">
						<h5>주문상태 설명</h5>
						<div class="description-wrapper">
							<div>
								<h6>승인 대기</h6>
								<div>주문 내용을 확인하는 단계입니다. <br />재고 확인이 완료되면 승인 완료으로 상태를 변경할 수 있습니다.</div>
								<div>
									<ul>
										<li>구매자 처리: 주문취소</li>
										<li>판매자 처리: 승인 완료, 배송중, 배송완료, 주문취소</li>
									</ul>
								</div>
							</div>
							<div>
								<h6>승인 완료</h6>
								<div>
									배송시작 전 출고를 준비하는 단계입니다.<br />
									배송이 시작되면 배송 정보를 입력하고 배송중 상태로 변경할 수 있습니다.
								</div>
								<div>
									<ul>
										<li>구매자 처리: 주문취소 요청</li>
										<li>판매자 처리: 배송중, 배송완료, 주문취소</li>
									</ul>
								</div>
							</div>
							<div>
								<h6>승인 완료(취소요청)</h6>
								<div>
									승인 완료 상태에서 주문취소가 필요한 경우에 구매자는 판매자에게 취소요청을 할 수 있습니다.<br />
									판매자는 구매자의 취소요청을 승인 또는 거절할 수 있습니다.
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">닫기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	name: 'ModalOrderManagementGuide',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		hide() {
			this.$emit('hide')
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-management-guide-wrapper {
	.Popup__container {
		.Popup__content {
			width: 1000px;
			height: 1487px;

			.image-wrapper {
				height: 444px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.order-status-description-wrapper {
				margin-top: 50px;
				overflow-y: auto;
				h5 {
					font-weight: 700;
					margin: 15px 0;
				}
				.description-wrapper {
					padding: 20px;
					background-color: $LIST_BACKGROUND;
					border-radius: 5px;
					& > div {
						margin-bottom: 20px;
						font-size: 0.875rem;
						h6 {
							font-weight: 700;
						}
						div {
							margin-top: 10px;
							ul {
								li {
									list-style: inside;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
