<template>
	<b-modal ref="modal" id="modal-search-product" :title="title" centered size="lg" ok-only ok-title="닫기" @show="onShow">
		<div class="search-wrap">
			<div class="search-box-wrap">
				<search-box
					class="search-box"
					name="modal-search-product-search-box"
					placeholder="상품명, CAS No, 제품번호, 브랜드로 검색"
					:useTagKeywords="false"
					@search="changeInput"
				/>
				<div class="btn Btn__select" @click="subSearch = !subSearch">
					<span>결과 내 검색</span>
				</div>
				<button class="btn ml-auto" @click="onClickRequestProduct">상품 요청하기</button>
			</div>
			<sub-search-box v-if="subSearch" @subSearch="onSubSearch"></sub-search-box>
		</div>
		<div class="content-wrap">
			<template v-if="step === 1 || !item">
				<line-tab :list="tabs" :value="catalogSelectTab" :customStyle="{ borderTop: 'none' }" @change="catalogSelectTab = $event" />
				<div ref="table-wrap" class="product-box" @scroll="throttleScroll">
					<div
						v-show="count"
						class="product-row"
						v-for="(catalog, index) in catalogList"
						:key="`product-row${index}`"
						@click="onClickCatalogItem(catalog.catalogId)"
					>
						<catalog-info-item :product="catalog" :index="index" :searchKeyword="keyword" />
						<ul class="seller-box">
							<li v-for="(sub, index) in catalog.productInfos" :key="`seller_${index}`">
								<span>{{ sub.seller.name }}</span>
								<p>{{ commaNum(sub.products[0]?.sellingUnitPrice) }}</p>
							</li>
						</ul>
					</div>
					<div v-show="count == 0" class="no-data-box">
						<h6>{{ noDataTitle }}</h6>
						<p v-html="noDataContent"></p>
						<button v-show="this.keyword !== ''" class="btn" @click="onClickRequestProduct">상품 요청하기</button>
					</div>
				</div>
			</template>
			<template v-else-if="step === 2 && item">
				<div class="catalog-detail-wrapper">
					<div class="btn-area">
						<button class="btn" @click="step = 1">
							<img src="@/assets/svg/common/icon-left-arrow.svg" />
							뒤로가기
						</button>
					</div>
					<div class="catalog-info">
						<img :src="item.brand?.image ? item.brand?.image : require('@/assets/svg/common/no-image.svg')" />
						<div>
							<div class="info-title">
								<h6>{{ item.name }}</h6>
								<h5>{{ commaNum(item.minUnitPrice) }}원~</h5>
							</div>
							<p>{{ item.subname }}</p>
							<div class="info-option">
								<span v-for="(option, index) in setItemInfo(item)" :key="`item-option_${index}`">
									{{ option.title }}: {{ isData(option.value) }}
								</span>
							</div>
						</div>
					</div>
					<detail-search-box :search="false" :filters="filters" @searchCheckList="searchCheckList" />
					<div>
						<line-tab
							:list="tabs"
							:value="catalogDetailSelectTab"
							:customStyle="{ borderTop: 'none' }"
							@change="catalogDetailSelectTab = $event"
						/>
						<table class="lm-table">
							<thead>
								<tr class="table-header">
									<th>
										<span>판매자</span>
									</th>
									<th>
										<span>SKU</span>
									</th>
									<th>
										<span>옵션</span>
									</th>
									<th>
										<span>재고</span>
									</th>
									<th>
										<span>가격</span>
									</th>
									<th>
										<span>배송정보</span>
									</th>
									<th>
										<span>상품 추가</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="product of productList" :key="product.productId" class="table-item">
									<td>
										<div>
											<img class="seller-image" :src="product.seller?.image ?? require('@/assets/images/no-image.png')" />
										</div>
									</td>
									<td>
										<div>{{ product.sku }}</div>
									</td>
									<td>
										<div style="white-space: pre-wrap">{{ convertDisplayOption(product.options) }}</div>
									</td>
									<td>
										<div>{{ product.stock }}개</div>
									</td>
									<td>
										<div :class="{ lowest: minUnitPrice === product.unitPrice }">{{ commaNum(product.unitPrice) }}원</div>
									</td>
									<td>
										<div class="info-wrap">
											<p v-if="!product.shippingDayPolicy" style="color: #999">판매자 문의필요</p>
											<template v-else-if="product.shippingDayPolicy === 'DEFAULT'">
												<p class="info-title">무료배송</p>
												<p class="info-sub">
													<img v-if="product.shippingDay <= 3" src="@/assets/svg/common/icon_watch.svg" />
													<span :class="{ error: product.shippingDay <= 3 }">
														약 {{ product.shippingDay }}일 이내 출고예정
													</span>
												</p>
											</template>
											<template v-else-if="product.shippingDayPolicy === 'SHAK'">
												<p class="info-title">
													<img style="margin-right: 7px" src="@/assets/svg/common/icon-thunder-truck.svg" />
													<img src="@/assets/svg/common/shackshack-text.svg" />
												</p>
												<p class="info-sub">
													{{ product.shippingDay === 0 ? '지금 주문하면 오늘 도착!' : '지금 주문하면 내일 오전 도착!' }}
												</p>
											</template>
											<template v-else-if="product.shippingDayPolicy === 'SIGMA'">
												<p class="info-title">
													<img src="@/assets/svg/common/icon-truck.svg" /><b>당일 출고</b> 예정 (Sigma-Aldrich 실시간 제공)
												</p>
												<p class="info-sub caution">
													<img src="@/assets/svg/common/icon-error.svg" />
													출고정보는 제조사 재고상황에 따라 변경될 수 있습니다.
												</p>
											</template>
										</div>
									</td>
									<td>
										<div>
											<button class="btn" @click="onAdd(product)">추가</button>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</template>
		</div>
	</b-modal>
</template>

<script>
import TableHeader from '@/components/table/TableHeader'
import LineTab from '@/components/tab/LineTab'
import CatalogInfoItem from '@/components/product/CatalogInfoItem'
import SearchBox from '@/views/common/components/SearchBox'
import SubSearchBox from '@/views/common/components/searchBox/SubSearchBox'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import mixin from '@/mixins'
import info from '@/mixins/info'
import { mapActions, mapMutations, mapState } from 'vuex'

const TAB = {
	FAVORITE: 'FAVORITE',
	PRICEASC: 'PRICEASC',
	PRICEDESC: 'PRICEDESC',
}

export default {
	name: 'ModalSearchProduct',
	components: {
		TableHeader,
		LineTab,
		CatalogInfoItem,
		SearchBox,
		SubSearchBox,
		DetailSearchBox,
	},
	mixins: [mixin, info],
	props: {
		title: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'NORMAL',
		},
	},
	data() {
		return {
			TAB,
			catalogSelectTab: TAB.FAVORITE,
			catalogDetailSelectTab: TAB.FAVORITE,
			tabs: [
				{ name: '구매 많은 순', value: TAB.FAVORITE },
				{ name: '낮은 가격 순', value: TAB.PRICEASC },
				{ name: '높은 가격 순', value: TAB.PRICEDESC },
			],
			keyword: '',
			subKeyword: '',
			subSearch: false,
			step: 1,
			catalogList: [],
			productList: [],
			filters: {
				판매자: [],
				'사이즈(용량)': [],
			},
			throttleScroll: () => {},
		}
	},
	computed: {
		...mapState('catalog/list', ['list', 'count', 'loadParams']),
		...mapState('catalog/detail', ['item', 'products']),
		noDataTitle() {
			if (this.keyword == '') {
				return '상품 검색'
			} else {
				return '결과 없음'
			}
		},
		noDataContent() {
			if (this.keyword == '') {
				return '상품명, CAS No, 제품번호, 브랜드로 검색해주세요'
			} else {
				return '검색결과가 없습니다.<br>상품을 요청해보세요.'
			}
		},
		minUnitPrice() {
			return Math.min(...this.productList.map(product => product.unitPrice))
		},
		sellerList() {
			const map = this.productList.reduce((acc, cur) => {
				if (!acc.has(cur.seller.name)) acc.set(cur.seller.name, cur.seller.id)
				return acc
			}, new Map())

			return [...map].map(([key, value]) => {
				return { name: key, value: value }
			})
		},
		sizeList() {
			const set = this.productList.reduce((acc, cur) => {
				cur.options.forEach(option => {
					if (option.name === '사이즈(용량)') acc.add(option.value)
				})
				return acc
			}, new Set())

			return [...set].map(value => {
				return { name: value, value }
			})
		},
	},
	watch: {
		catalogSelectTab(val) {
			if (val === TAB.FAVORITE) this.fetchCatalogList({ order: [TAB.FAVORITE], orderDirection: ['DESC'] })
			else if (val === TAB.PRICEASC) this.fetchCatalogList({ order: ['PRICE'], orderDirection: ['ASC'] })
			else if (val === TAB.PRICEDESC) this.fetchCatalogList({ order: ['PRICE'], orderDirection: ['DESC'] })
		},
		catalogDetailSelectTab(val) {
			if (val === TAB.FAVORITE) this.setProductsParams({ order: [TAB.FAVORITE], orderDirection: ['DESC'] })
			else if (val === TAB.PRICEASC) this.setProductsParams({ order: ['PRICE'], orderDirection: ['ASC'] })
			else if (val === TAB.PRICEDESC) this.setProductsParams({ order: ['PRICE'], orderDirection: ['DESC'] })
		},
		products(val) {
			this.productList = this.$_.cloneDeep(val)
			this.filters['판매자'] = this.sellerList
			this.filters['사이즈(용량)'] = this.sizeList
		},
	},
	mounted() {
		this.throttleScroll = this.$_.throttle(this.scroll, 200)
	},
	methods: {
		...mapMutations('catalog/list', ['setOffset', 'setType']),
		...mapActions('catalog/list', ['getList', 'setLoadParams']),
		...mapActions('catalog/detail', ['getDetail', 'setProductsParams']),
		onShow() {
			this.step = 1
			this.subSearch = false
			this.catalogSelectTab = TAB.FAVORITE
			this.catalogDetailSelectTab = TAB.FAVORITE
			this.catalogList = []
			this.productList = []
			this.setOffset(1)
			this.setType(this.type)
		},
		onClickRequestProduct() {
			console.log('onClickRequestProduct')
		},
		changeInput(value) {
			this.step = 1
			this.keyword = value
			if (this.keyword) this.fetchCatalogList({ keyword: this.keyword })
		},
		onSubSearch(value) {
			this.step = 1
			this.subKeyword = value
			if (this.subKeyword) this.fetchCatalogList({ subKeyword: this.subKeyword })
		},
		async fetchCatalogList(params) {
			await this.setLoadParams(params)
			this.catalogList = this.$_.cloneDeep(this.list)
			this.$refs['table-wrap'].scrollTo(0, 0)
		},
		async onClickCatalogItem(id) {
			this.setProductsParams({ catalogId: id })
			await this.getDetail(id)
			this.step = 2
		},
		searchCheckList(filters) {
			let sellerIds = []
			if (filters['판매자']?.length) {
				filters['판매자'].forEach(seller => {
					sellerIds.push(seller.value)
				})
			}
			let sizeList = []
			if (filters['사이즈(용량)']?.length) {
				filters['사이즈(용량)'].forEach(option => {
					sizeList.push(option.value)
				})
			}

			this.productList = this.$_.cloneDeep(this.products)
				.filter(product => (!sellerIds.length ? true : sellerIds.includes(product.seller.id)))
				.filter(product => (!sizeList.length ? true : product.options.some(option => sizeList.includes(option.value))))
		},
		convertDisplayOption(options) {
			const optionMap = new Map()
			options.forEach(option => {
				if (!optionMap.has(option.name)) optionMap.set(option.name, [])
				optionMap.set(option.name, [...optionMap.get(option.name), option.value])
			})

			return [...optionMap].reduce((acc, [key, value], index) => {
				return acc + `${index !== 0 ? ' | ' : ''}${key}: ${value.join(',')}`
			}, '')
		},
		onAdd(product) {
			this.$emit('add', product)
			this.$refs.modal.hide()
		},
		async scroll() {
			const tableWrap = this.$refs['table-wrap']
			const isBottom = tableWrap.scrollTop + tableWrap.clientHeight + 300 >= tableWrap.scrollHeight
			const offset = this.loadParams.offset + this.loadParams.length
			if (isBottom && offset < this.count) {
				this.setOffset(offset / this.loadParams.length + 1)
				await this.getList()
				this.catalogList = [...this.catalogList, ...this.$_.cloneDeep(this.list)]
			}
		},
	},
}
</script>

<style lang="scss">
#modal-search-product {
	.modal-lg {
		max-width: 1000px;
	}
	.modal-content,
	.modal-body {
		padding: 0 0;
	}
	.modal-footer {
		button {
			border: 1px solid #ccc;
			background-color: transparent;
			width: 100px;
			&:hover {
				background-color: white !important;
				color: black !important;
			}
		}
	}
	.search-wrap {
		width: 100%;
		padding: 20px;
		border-top: 1px solid $LINE_DIVIDER;
		border-bottom: 1px solid $LINE_DIVIDER;
		.search-box-wrap {
			display: flex;
			align-items: flex-start;
			.search-box {
				width: 360px;
				margin-right: 10px;
			}
		}
		.search-sub-wrap {
			width: 360px;
			margin-top: 10px;
		}
	}
	.content-wrap {
		width: 100%;
		.line-tab {
			height: 40px;
			padding: 0 20px;
			align-items: center;
			border-top: 1px solid $LINE_DIVIDER;
			border-bottom: 1px solid $LINE_DIVIDER;
			.tab {
				font-size: 0.75rem;
				font-weight: 400;
				padding-left: 18px;
				padding-bottom: 0;
				margin-right: 2px;
				&.active {
					background-image: url(~@/assets/svg/common/sort-checked.svg);
					background-position: center left 0;
					background-repeat: no-repeat;
					background-size: 14px;
					border-bottom: 2px solid transparent;
					font-weight: 700;
				}
			}
		}
		.product-box {
			height: 480px;
			background-color: $LIST_BACKGROUND;
			overflow-y: auto;
			position: relative;
			.no-data-box {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				h6 {
					font-size: 1.125rem;
					font-weight: 700;
					color: $COLOR_999;
				}
				p {
					margin-top: 10px;
					font-size: 0.875rem;
					font-weight: 400;
				}
				button {
					font-size: 0.875rem;
					font-weight: 700;
					border: 1px solid black;
					margin: 1rem auto;
				}
			}
		}
		.product-row {
			display: flex;
			align-items: flex-start;
			border-bottom: 1px solid $LINE_DIVIDER;
			background-color: white;
			cursor: pointer;
			.catalog-info-item {
				width: calc(100% - 272px);
				border-right: 1px solid $LINE_DIVIDER;
				.image {
					img {
						object-fit: contain;
						width: 140px;
						height: 140px;
					}
				}
				.content {
					width: calc(100% - 160px);
				}
			}
			.seller-box {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
				width: 272px;
				font-size: 0.75rem;
				padding: 20px;
				li {
					display: flex;
					width: 100%;
					font-weight: 400;
					&:first-child {
						font-weight: 700;
						p {
							color: $PRIMARY_RED;
						}
					}
					p {
						margin-left: auto;
					}
				}
			}
		}
		.catalog-detail-wrapper {
			display: flex;
			flex-direction: column;
			gap: 20px;
			.btn-area {
				padding: 20px 20px 0 20px;
			}
			.catalog-info {
				display: flex;
				gap: 20px;
				padding: 0 20px;
				> img {
					object-fit: contain;
					width: 100px;
					height: 100px;
					border: 1px solid $COLOR_EEE;
					border-radius: 4px;
				}
				> div {
					display: flex;
					flex-direction: column;
					width: 100%;
					> p {
						font-weight: 400;
						font-size: 0.75rem;
						color: $COLOR_666;
					}
					.info-title {
						display: flex;
						justify-content: space-between;
					}
					.info-option {
						margin-top: 20px;
						> span {
							font-weight: 400;
							font-size: 0.75rem;
							color: $COLOR_666;
							&:not(:first-child)::before {
								vertical-align: middle;
								margin-left: 6px;
								margin-right: 6px;
								display: inline-block;
								height: 12px;
								content: '';
								border-left: 1px solid $COLOR_DDD;
							}
						}
					}
				}
			}
			.detail-search-box {
				padding: 0 20px;
			}
			.lm-table {
				@import '@/styles/scss/table/_table-header.scss';
				@import '@/styles/scss/table/_table-item.scss';
				position: relative;
				height: 100%;
				text-align: left;
				display: block;
				overflow: auto;
				white-space: nowrap;
				.table-item {
					.seller-image {
						width: 75px;
						height: 30px;
						border: 1px solid $COLOR_EEE;
						border-radius: 4px;
						padding: 2px;
					}
					.lowest {
						padding-left: 14px;
						background-image: url(~@/assets/svg/common/low-price.svg);
						background-repeat: no-repeat;
						background-position: center left 0;
						background-size: 12px;
						color: $PRIMARY_RED;
					}
					.info-sub {
						.error {
							margin-left: 3px;
							font-weight: 700;
							color: $PRIMARY_RED;
						}
					}
					.caution {
						color: $PRIMARY_RED;
						display: flex;
						img {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}
</style>
