<template>
	<portal to="lm-modal">
		<div class="Popup__depth3" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">상세정보</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<info-list title="물품 상세정보" :list="infoList"></info-list>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__white" @click="hide">닫기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'
import InfoList from '@/views/common/components/InfoList.vue'
import { MiddleCategory } from '@/utils/define/ItemCode'

export default {
	name: 'ModalSearchProductExternalDetail',
	mixins: [MixinModal],
	components: {
		InfoList,
	},
	data() {
		return {
			infoList: [],
		}
	},
	methods: {
		showDetailModal(item) {
			this.isShow = true
			this.infoList = this.getInfoListByCategory(item.item)
		},
		getInfoListByCategory(item) {
			switch (item.category.code) {
				case MiddleCategory.REAGENT:
					return [
						{ title: '농도(밀도)', value: item.density ? item.density + item.densityUnit : '' },
						{ title: '분자량', value: item.molecularWeight ? item.molecularWeight + item.molecularWeightUnit : '' },
						{ title: 'MOL', value: item.molarMass ? item.molarMass + item.molarMassUnit : '' },
						{ title: '순도', value: item.assay ? item.assay + item.assayUnit : '' },
						{
							title: '보관온도',
							value: item.storageTemperature ? item.storageTemperature + item.storageTemperatureUnit : '',
						},
						{ title: '용해도', value: item.solubility ? item.solubility : '' },
						{ title: '분자식', value: item.molecularFormula ? item.molecularFormula : '' },
						{ title: '발화점', value: item.flashPoint ? item.flashPoint + item.flashPointUnit : '' },
						{ title: '끓는점', value: item.boilingPoint ? item.boilingPoint + item.boilingPointUnit : '' },
						{ title: '녹는점', value: item.meltingPoint ? item.meltingPoint + item.meltingPointUnit : '' },
					]
				case MiddleCategory.GAS:
					return [
						{ title: '분자량', value: item.molecularWeight ? item.molecularWeight + item.molecularWeightUnit : '' },
						{ title: '순도', value: item.assay ? item.assay + item.assayUnit : '' },
						{ title: '분자식', value: item.molecularFormula ? item.molecularFormula : '' },
					]
				case MiddleCategory.BIO_REAGENT:
					return [
						{ title: '규격', value: item.spec ?? '' },
						{ title: '형태', value: item.shape ?? '' },
						{ title: '농도(밀도)', value: item.density ? item.density + item.densityUnit : '' },
						{ title: '순도', value: item.assay ? item.assay + item.assayUnit : '' },
						{ title: '보관온도', value: item.storageTemperature ? item.storageTemperature + item.storageTemperatureUnit : '' },
					]
				case MiddleCategory.BIO_ANTIBODY:
					return [
						{ title: '규격', value: item.spec ?? '' },
						{ title: '소스', value: item.origin ?? '' },
						{ title: '형태', value: item.shape ?? '' },
						{ title: '밀도', value: item.density ? item.density + item.densityUnit : '' },
						{ title: '분자량', value: item.molecularWeight ? item.molecularWeight + item.molecularWeightUnit : '' },
						{ title: 'MOL', value: item.molarMass ? item.molarMass + item.molarMassUnit : '' },
						{ title: '보관온도', value: item.storageTemperature ? item.storageTemperature + item.storageTemperatureUnit : '' },
					]
				case MiddleCategory.BIO_CELL:
					return [
						{ title: '규격', value: item.spec ?? '' },
						{ title: '소스', value: item.origin ?? '' },
						{ title: '형태', value: item.shape ?? '' },
						{ title: 'DNA 정보', value: item.dna ?? '' },
						{ title: '보관온도', value: item.storageTemperature ? item.storageTemperature + item.storageTemperatureUnit : '' },
					]
				case MiddleCategory.CHROMATOGRAPHY:
					return [
						{ title: 'USP/Phase', value: item.usp ?? '' },
						{ title: '내경(ID)', value: item.innerDiameter ? item.innerDiameter + item.innerDiameterUnit : '' },
						{ title: 'Particle Size', value: item.particleSize ? item.particleSize + item.particleSizeUnit : '' },
						{ title: '길이', value: item['length'] ? item['length'] + item.lengthUnit : '' },
						{ title: 'Pore Size', value: item.poreSize ? item.poreSize + item.poreSizeUnit : '' },
						{ title: 'pH 범위', value: item.phRangeFrom && item.phRangeTo ? item.phRangeFrom + ' ~ ' + item.phRangeTo : '-' },
						{ title: 'Carbon Load(%)', value: item.carbonLoad ?? '-' },
						{ title: 'Endcapped', value: item.endcapped ?? '-' },
					]
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__container {
	width: 1000px;
	max-height: 510px;
	.Popup__content {
		img {
			width: 100%;
			height: 100%;
			border: 1px solid $LINE_DIVIDER;
		}
	}
}
</style>
