const Classification = {
	CHEMICAL: 'CAT_CHEMICAL', //화학물질
	BIO: 'CAT_BIO', //바이오
	CHROMATOGRAPHY: 'CAT_CHROMATOGRAPHY', //분석용 칼럼
	LABWARE: 'CAT_LABWARE', //연구장비
	TOOL: 'CAT_TOOL', // 실험 장비
	PROTECTION: 'CAT_PROTECTION', //보호구
	ETC: 'CAT_ETC', //소모품
	ALL: 'CAT_ALL',
	typeToString: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return '화학물질'
			case Classification.BIO:
				return '바이오'
			case Classification.CHROMATOGRAPHY:
				return '분석용 칼럼'
			case Classification.LABWARE:
				return '연구장비'
			case Classification.TOOL:
				return '실험장비'
			case Classification.PROTECTION:
				return '보호구'
			case Classification.ETC:
				return '소모품'
			case Classification.ALL:
				return '물품 목록'
		}
	},
	typeToClassification: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return 'chemical'
			case Classification.BIO:
				return 'bio'
			case Classification.CHROMATOGRAPHY:
				return 'chromatography'
			case Classification.TOOL:
				return 'tool'
			case Classification.LABWARE:
				return 'labware'
			case Classification.PROTECTION:
				return 'protection'
			case Classification.ETC:
				return 'etc'
		}
	},
	typeToColor: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return '#FEC741'
			case Classification.BIO:
				return '#0B6CFF'
			case Classification.CHROMATOGRAPHY:
				return '#DD6FB2'
			case Classification.TOOL:
				return '#0b6cff'
			case Classification.LABWARE:
				return '#0dc577'
			case Classification.PROTECTION:
				return '#8968f7'
			case Classification.ETC:
				return '#85B5FF'
		}
	},
	typeToClass: type => {
		switch (type) {
			case Classification.CHEMICAL:
				return 'yellow'
			case Classification.BIO:
				return 'blue'
			case Classification.CHROMATOGRAPHY:
				return 'purple'
			case Classification.LABWARE:
				return 'green'
			case Classification.PROTECTION:
				return 'violet'
			case Classification.ETC:
				return 'skyblue'
		}
	},
	typeToCategory: type => {
		switch (type) {
			case 'chemical':
				return '화학물질'
			case 'bio':
				return '바이오'
			case 'chromatography':
				return '분석용 칼럼'
			case 'tool':
				return '실험용품'
			case 'labware':
				return '연구장비'
			case 'protection':
				return '보호구'
			case 'etc':
				return '소모품'
		}
	},
}

const Category = {
	REAGENT: `${Classification.CHEMICAL}_REAGENT`, // 시약
	MADE_REAGENT: `${Classification.CHEMICAL}_MADE_REAGENT`, // 제조 시약
	RAW_MATERIAL: `${Classification.CHEMICAL}_RAWMATERIAL`, // 원료
	MADE_RAW_MATERIAL: `${Classification.CHEMICAL}_MADE_RAWMATERIAL`, // 제조 원료
	STANDARD: `${Classification.CHEMICAL}_STANDARD`, // 표준품
	GAS: `${Classification.CHEMICAL}_GAS`, // 가스
	EQUIPMENT: `${Classification.LABWARE}_EQUIPMENT`, // 기계
	TOOL: `${Classification.LABWARE}_TOOL`, // 기구
	PROTECTION: `${Classification.PROTECTION}`, // 보호구
	ETC: `${Classification.ETC}`, // 소모품
	CHROMATOGRAPHY: `${Classification.CHROMATOGRAPHY}`,
	typeToString: type => {
		switch (type) {
			case Category.REAGENT:
				return '시약'
			case Category.MADE_REAGENT:
				return '제조 시약'
			case Category.RAW_MATERIAL:
				return '원료'
			case Category.MADE_RAW_MATERIAL:
				return '제조 원료'
			case Category.STANDARD:
				return '표준품'
			case Category.GAS:
				return '가스'
			case Category.CHROMATOGRAPHY:
				return '분석용칼럼'
			case Category.EQUIPMENT:
				return '연구 장비'
			case Category.TOOL:
				return '실험 용품'
			case Category.PROTECTION:
				return '보호구'
			case Category.ETC:
				return '소모품'
		}
	},
}

const RegistType = {
	AUTO: {
		IMAGE: 'AUTO_IMAGE',
		DOC: 'AUTO_DOC',
		LMS: 'AUTO_LMS',
	},
	MANUAL: {
		FILE: 'MANUAL_FILE',
		DIRECT: 'MANUAL_DIRECT',
		SUBDIVIDE: 'MANUAL_SUBDIVIDE',
	},
	isTypeAuto: type => {
		return RegistType.AUTO.IMAGE === type || RegistType.AUTO.DOC === type || RegistType.AUTO.LMS === type
	},
	typeToString: type => {
		switch (type) {
			case RegistType.AUTO.IMAGE:
				return '자동등록: 사진 분석'
			case RegistType.AUTO.DOC:
				return '자동등록: MSDS / COA 분석'
			case RegistType.AUTO.LMS:
				return '자동등록: 스토어'
			case RegistType.MANUAL.FILE:
				return '수기등록: 엑셀 업로드'
			case RegistType.MANUAL.DIRECT:
				return '수기등록: DB검색 / 직접입력'
			case RegistType.MANUAL.SUBDIVIDE:
				return '수기등록: 소분'
		}
	},
}

const LargeCategory = {
	CHEMICAL: 'CAT_CHEMICAL', // 화학 물질
	CHROMATOGRAPHY: 'CAT_CHROMATOGRAPHY', // 분석용 칼럼
	LABWARE: 'CAT_LABWARE', // 연구장비
	PROTECTION: 'CAT_PROTECTION', // 보호구
	ETC: 'CAT_ETC', // 소모품
	OFFICE: 'CAT_OFFICE', // 사무 용품
	LAB_ETC: 'CAT_LABETC', // 실험 용품
}

const MiddleCategory = {
	REAGENT: `${LargeCategory.CHEMICAL}_REAGENT`, // 시약
	CHROMATOGRAPHY: `${LargeCategory.CHROMATOGRAPHY}`, // 분석용 칼럼
	EQUIPMENT: `${LargeCategory.LABWARE}_EQUIPMENT`, // 연구 장비
	TOOL: `${LargeCategory.LABWARE}_TOOL`, // 기구
	PROTECTION: `${LargeCategory.PROTECTION}`, // 보호구
	ETC: `${LargeCategory.ETC}`, // 소모품
	OFFICE: `${LargeCategory.OFFICE}`, // 사무 용품
	LAB_ETC: `${LargeCategory.LAB_ETC}`, // 실험 용품
}

const CategoryToString = type => {
	switch (type) {
		case MiddleCategory.REAGENT:
			return '화학물질/시약'
		case MiddleCategory.CHROMATOGRAPHY:
			return '분석용칼럼/분석용칼럼'
		case MiddleCategory.EQUIPMENT:
			return '연구장비/기계'
		case MiddleCategory.TOOL:
			return '연구장비/기구'
		case MiddleCategory.PROTECTION:
			return '보호구'
		case MiddleCategory.ETC:
			return '소모품'
		case MiddleCategory.OFFICE:
			return '사무 용품'
		case MiddleCategory.LAB_ETC:
			return '실험 용품'
		default:
			return '-'
	}
}

const largeCategoryToString = type => {
	switch (type) {
		case LargeCategory.CHEMICAL:
			return '화학 물질'
		case LargeCategory.CHROMATOGRAPHY:
			return '분석용 칼럼'
		case LargeCategory.BIO:
			return '바이오 물질'
		case LargeCategory.LABWARE:
			return '연구 장비'
		case LargeCategory.TOOL:
			return '실험 용품'
		case LargeCategory.PROTECTION:
			return '보호구'
		case LargeCategory.ETC:
			return '소모품'
		case LargeCategory.ALL:
			return '물품 목록'
		default:
			return '-'
	}
}
const middleCategoryToString = type => {
	switch (type) {
		case MiddleCategory.REAGENT:
			return '시약'
		case MiddleCategory.MADE_REAGENT:
			return '제조 시약'
		case MiddleCategory.RAW_MATERIAL:
			return '원료'
		case MiddleCategory.MADE_RAW_MATERIAL:
			return '제조 원료'
		case MiddleCategory.STANDARD:
			return '표준품'
		case MiddleCategory.GAS:
			return '가스'
		case MiddleCategory.BIO_REAGENT:
			return '바이오 시약'
		case MiddleCategory.BIO_ANTIBODY:
			return '항체 / 세럼'
		case MiddleCategory.BIO_CELL:
			return '세포주'
		case MiddleCategory.EQUIPMENT:
			return '연구 장비'
		case MiddleCategory.TOOL:
			return '실험 용품'
		case MiddleCategory.PROTECTION:
			return '보호구'
		case MiddleCategory.ETC:
			return '소모품'
		case MiddleCategory.CHROMATOGRAPHY:
			return '분석용 칼럼'
		default:
			return '-'
	}
}

const LargeClassification = Object.values(LargeCategory).map((item, i) => {
	return {
		name: largeCategoryToString(item),
		type: item,
		id: i,
	}
})

const MiddleClassification = {
	CAT_CHEMICAL: [MiddleCategory.REAGENT, MiddleCategory.GAS],
	CAT_BIO: [MiddleCategory.BIO_REAGENT, MiddleCategory.BIO_ANTIBODY, MiddleCategory.BIO_CELL],
	CAT_CHROMATOGRAPHY: [MiddleCategory.CHROMATOGRAPHY],
	CAT_LABWARE: [MiddleCategory.EQUIPMENT],
	CAT_TOOL: [MiddleCategory.TOOL],
	CAT_PROTECTION: [MiddleCategory.PROTECTION],
	CAT_ETC: [MiddleCategory.ETC],
}

const findLargeClass = middleClass => {
	let result = null
	Object.keys(MiddleClassification).forEach((key, idx) => {
		if (MiddleClassification[key].includes(middleClass)) {
			result = {
				type: key,
				id: idx,
			}
		}
	})
	return result
}

const ShippingMethod = {
	VENDOR_DIRECT: '제조사 직접배송',
	MANUFACTURER_DIRECT: '판매자 직접배송',
	PARCEL: '택배 배송',
	QUICK: '퀵 서비스',
}

const shippingMethodToString = code => {
	switch (code) {
		case ShippingMethod.VENDOR_DIRECT:
			return '제조사 직접배송'
		case ShippingMethod.MANUFACTURER_DIRECT:
			return '판매자 직접배송'
		case ShippingMethod.PARCEL:
			return '택배 배송'
		case ShippingMethod.QUICK:
			return '퀵 서비스'
	}
}

const ShippingMethodSelectList = Object.values(ShippingMethod).map((item, index) => {
	return {
		index,
		name: shippingMethodToString(item),
		value: item,
	}
})

const Estimate = {
	UNREAD: 'UNREAD',
	READ: 'READ',
	ORDERED: 'ORDERED',
	SAMPLE: 'SAMPLE',
	statusToString: status => {
		switch (status) {
			case Estimate.UNREAD:
				return '미확인'
			case Estimate.READ:
				return '확인 완료'
			case Estimate.ORDERED:
				return '주문완료'
			case Estimate.SAMPLE:
				return '샘플'
		}
	},
	statusToClass: status => {
		switch (status) {
			case Estimate.ORDERED:
				return 'complete'
			default:
				return 'common'
		}
	},
}

const RequestProduct = {
	WAIT: 'WAIT',
	ING: 'ING',
	COMPLETE: 'COMPLETE',
	FAIL: 'FAIL',
	type: {
		NOT: 'NOT',
		COMPLETE: 'COMPLETE',
	},
	statusToString: status => {
		switch (status) {
			case RequestProduct.WAIT:
				return '접수중'
			case RequestProduct.ING:
				return '상품 탐색중'
			case RequestProduct.COMPLETE:
				return '소싱 완료'
			case RequestProduct.FAIL:
				return '소싱 실패'
		}
	},
	statusToClass: status => {
		switch (status) {
			case RequestProduct.COMPLETE:
				return 'complete'
			case RequestProduct.FAIL:
				return 'fail'
			default:
				return 'common'
		}
	},
	typeToString: type => {
		switch (type) {
			case RequestProduct.type.NOT:
				return '미작성'
			case RequestProduct.type.COMPLETE:
				return '작성완료'
		}
	},
}

const BusinessRegistration = {
	WAIT: 'WAIT',
	APPROVE: 'APPROVE',
	REJECT: 'REJECT',
	statusToString: status => {
		switch (status) {
			case BusinessRegistration.WAIT:
				return '인증 대기'
			case BusinessRegistration.REJECT:
				return '거절'
			case BusinessRegistration.APPROVE:
				return '인증 완료'
		}
	},
	statusToClass: status => {
		switch (status) {
			case BusinessRegistration.APPROVE:
				return 'complete'
			case BusinessRegistration.REJECT:
				return 'fail'
			default:
				return 'common'
		}
	},
}

const ApprovalProduct = {
	WAIT: 'WAIT',
	APPROVE: 'APPROVE',
	REJECT: 'REJECT',
	statusToString: status => {
		switch (status) {
			case ApprovalProduct.WAIT:
				return '심사 대기'
			case ApprovalProduct.REJECT:
				return '반려'
			case ApprovalProduct.APPROVE:
				return '승인'
		}
	},
	statusToClass: status => {
		switch (status) {
			case ApprovalProduct.APPROVE:
				return 'complete'
			case ApprovalProduct.REJECT:
				return 'fail'
			case ApprovalProduct.WAIT:
				return 'wait'
			default:
				return 'common'
		}
	},
}

const UpdateProductPrice = {
	WAIT: '대기중',
	ING: '처리중',
	DONE: '완료',
	ERROR: '에러',
	statusToString: status => {
		switch (status) {
			case "WAIT":
				return UpdateProductPrice.WAIT;
			case "ING":
				return UpdateProductPrice.ING;
			case "DONE":
				return UpdateProductPrice.DONE;
			case "ERROR":
				return UpdateProductPrice.ERROR;
		}
	},
	statusToClass: status => {
		switch (status) {
			case "WAIT":
				return 'wait';
			case "ING":
				return 'ING';
			case "DONE":
				return 'complete';
			case "ERROR":
				return 'fail';
			default:
				return 'common'
		}
	},
}

const PaymentStatus = {
	WAIT: 'WAIT',
	NOPAID: 'NOPAID',
	PAID: 'PAID',
	CANCEL: 'CANCEL',
	statusToString: status => {
		switch (status) {
			case PaymentStatus.WAIT:
				return '입금대기'
			case PaymentStatus.NOPAID:
				return '미결제'
			case PaymentStatus.PAID:
				return '결제완료'
			case PaymentStatus.CANCEL:
				return '결제취소'
		}
	},
	statusToClass: status => {
		switch (status) {
			case PaymentStatus.WAIT:
				return 'fail'
			case PaymentStatus.NOPAID:
				return 'fail'
			case PaymentStatus.PAID:
				return 'complete'
			case PaymentStatus.CANCEL:
				return 'fail'
		}
	},
}

const PaymentMethod = {
	CARD: 'CARD',
	DIRECT_CARD: 'DIRECT_CARD',
	LATER: 'LATER',
	EBILL: 'EBILL',
	statusToString: method => {
		switch (method) {
			case PaymentMethod.CARD:
				return '카드 간편결제'
			case PaymentMethod.DIRECT_CARD:
				return '바로결제(카드)'
			case PaymentMethod.LATER:
				return '나중에 결제'
			case PaymentMethod.EBILL:
				return '전자세금계산서'
		}
	},
}

const OrderStatusText = ({ status, cancelStatus, returnStatus }) => {
	const result = {
		text: '',
		subtext: '',
	}

	if (returnStatus == 'DONE') {
		result.text = '교환/반품 완료'
	} else if (cancelStatus == 'DONE') {
		result.text = '주문취소 완료'
	} else {
		if (status == 'WAIT') {
			result.text = '승인 대기'
		} else if (status == 'APPROVE') {
			result.text = '승인 완료'
		} else if (status == 'SHIPPING_ING') {
			result.text = '배송중'
		} else if (status == 'SHIPPING_COMPLETE') {
			result.text = '배송완료'
		} else if (status == 'CONFIRM') {
			result.text = '구매확정'
		}

		if (returnStatus == 'ING') {
			result.subtext = '교환/반품 접수'
		} else if (cancelStatus == 'ING') {
			result.subtext = '주문취소 접수'
		}
	}

	return result
}

const PopupStatus = {
	ALL: 'ALL',
	WAIT: 'WAIT',
	OPEN: 'OPEN',
	HIDE: 'HIDE',
	CLOSED: 'CLOSED',
	statusToString: status => {
		switch (status) {
			case PopupStatus.ALL:
				return '전체'
			case PopupStatus.WAIT:
				return '대기'
			case PopupStatus.OPEN:
				return '노출'
			case PopupStatus.HIDE:
				return '숨김'
			case PopupStatus.CLOSED:
				return '종료'
		}
	},
	statusToClass: status => {
		switch (status) {
			case PopupStatus.ALL:
				return 'common'
			case PopupStatus.WAIT:
				return 'common'
			case PopupStatus.OPEN:
				return 'complete'
			case PopupStatus.HIDE:
				return 'common'
			case PopupStatus.CLOSED:
				return 'common'
		}
	},
}

const PopupTargetType = {
	ALL: 'ALL',
	INSTITUTE_TYPE: 'INSTITUTE_TYPE',
	INSTITUTES: 'INSTITUTES',
	typeToString: type => {
		switch (type) {
			case PopupTargetType.ALL:
				return '전체'
			case PopupTargetType.INSTITUTE_TYPE:
				return ''
			case PopupTargetType.INSTITUTES:
				return '특정 소속'
		}
	},
}

const InstituteType = {
	UNIVERSITY: 'UNIVERSITY',
	ENTERPRISE: 'ENTERPRISE',
	SELLER: 'SELLER',
	PERSON: 'PERSON',
	typeToString: type => {
		switch (type) {
			case InstituteType.UNIVERSITY:
				return '학교 소속 연구실'
			case InstituteType.ENTERPRISE:
				return '기업 소속 연구실'
			case InstituteType.SELLER:
				return '재판매업체(법인)'
			case InstituteType.PERSON:
				return '개인'
		}
	},
}

export {
	Category,
	Classification,
	RegistType,
	LargeClassification,
	CategoryToString,
	MiddleClassification,
	LargeCategory,
	largeCategoryToString,
	middleCategoryToString,
	MiddleCategory,
	findLargeClass,
	ShippingMethod,
	shippingMethodToString,
	ShippingMethodSelectList,
	Estimate,
	RequestProduct,
	BusinessRegistration,
	ApprovalProduct,
	UpdateProductPrice,
	PaymentStatus,
	PaymentMethod,
	OrderStatusText,
	PopupStatus,
	PopupTargetType,
	InstituteType,
}
