<template>
	<tr class="table-header">
		<th>
			<span>번호</span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>제목</span>
		</th>
		<th>
			<span>노출 시작일시</span>
		</th>
		<th>
			<span>노출 종료일시</span>
		</th>
		<th>
			<span>작성자</span>
		</th>
		<th>
			<span>작성일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	computed: {
		...mapGetters('lms/notice', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	methods: {
		...mapActions('lms/notice', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
