<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>팝업 이벤트 {{ this.title }}</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>이벤트 정보</h5>
				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					v-model="formData.title"
					@changeValue="data => (formData.title = data)"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<div v-if="id">
					<base-item :title="'노출여부'">
						<div class="toggle-wrapper">
							<div class="toggle" :class="{ active: !isHide }" @click="isHide = !isHide"></div>
						</div>
					</base-item>
				</div>
				<upload-images
					:isValidImage="isValid.content"
					inValidImageText="이미지를 업로드해주세요"
					:isValidLink="isValid.url"
					:inValidLinkText="inValidLinkText"
					:inputValueContent="formData.content.image"
					:inputValueUrl="formData.url"
					@changeValue="data => (formData.content = data)"
					@changeLinkValue="data => (formData.url = data)"
				/>
			</div>
			<div>
				<h5>이벤트 설정</h5>
				<!-- <input-radio
					title="타겟 지정"
					@changeValue="data => (formData.targetType = data.id)"
					:list="targetStatus"
					:tab="formData.targetType"
				/> -->
				<!-- <target-select
					title="타겟 직접 선택"
					v-if="formData.targetType == 'INSTITUTES'"
					@changeValue="data => (formData.targetInstituteIds = data)"
				/> -->
				<input-date-picker title="노출 시작일" :inputValue="formData.startDatetime" @changeValue="data => (formData.startDatetime = data)" />
				<input-date-picker title="노출 종료일" :inputValue="formData.endDatetime" @changeValue="data => (formData.endDatetime = data)" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="onClickOk">{{ okText }}</base-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import UploadImages from '../components/UploadImages.vue'
// import TargetSelect from '../../components/target/TargetSelect.vue'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'

export default {
	props: {
		id: {
			type: [Number, String],
		},
	},
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputRadio,
		UploadImages,
		// TargetSelect,
	},
	data() {
		return {
			formData: {
				title: '',
				content: {},
				url: '',
				startDatetime: this.$DateTime.now().toJSDate(),
				endDatetime: this.$DateTime.now().plus({ day: 7 }).toJSDate(),
			},
			isHide: false,
			isValid: {
				title: true,
				content: true,
				url: true,
			},
			inValidLinkText: '링크를 입력해주세요',
		}
	},
	async mounted() {
		if (this.id) {
			await this.getDetail(this.id)
			this.setData()
		}
	},
	computed: {
		...mapState('lmp/event', ['item']),
		okText() {
			return this.id ? '수정' : '등록'
		},
		title() {
			return this.id ? '수정' : '만들기'
		},
		targetStatus() {
			return [
				{
					id: 'ALL',
					title: '전체',
				},
				// {
				// 	id: 'UNIVERSITY',
				// 	title: '대학교',
				// },
				// {
				// 	id: 'COMPANY',
				// 	title: '기업',
				// },
				// {
				// 	id: 'GOV',
				// 	title: '정출연',
				// },
				// {
				// 	id: 'INSTITUTES',
				// 	title: '특정 소속만',
				// },
				// {
				// 	id: 'TARGET_SAGMENT',
				// 	title: '특정 세그먼트',
				// },
			]
		},
	},
	methods: {
		...mapActions('lmp/event', ['createPopup', 'getDetail', 'editPopup']),
		onClickOk() {
			if (this.id) this.onEdit()
			else this.onAdd()
		},
		async onEdit() {
			if (!this.validate()) return
			try {
				const formData = new FormData()
				formData.append('popupId', this.id)
				formData.append('hide', this.isHide)
				Object.keys(this.formData).forEach(key => {
					if (key === 'content') formData.append(key, this.formData[key]['image'])
					else if (key === 'startDatetime' || key === 'endDatetime')
						formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
					else if (this.formData[key]) formData.append(key, this.formData[key])
				})
				await this.editPopup(formData)
				this.$root.toast('팝업 이벤트 수정 완료', '팝업 이벤트 수정을 완료하였습니다.', ToastType.SUCCESS)
				this.$router.replace({
					name: PageName.Lmp.LmpEventDetail,
					params: {
						id: this.id,
					},
				})
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async onAdd() {
			if (!this.validate()) return
			try {
				const formData = new FormData()
				Object.keys(this.formData).forEach(key => {
					if (key === 'content') formData.append(key, this.formData[key]['image'])
					else if (key === 'startDatetime' || key === 'endDatetime')
						formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
					else if (this.formData[key]) formData.append(key, this.formData[key])
				})
				await this.createPopup(formData)
				this.$root.toast('팝업 이벤트 등록 완료', '팝업 이벤트 등록을 완료하였습니다.', ToastType.SUCCESS)
				this.$router.replace({
					name: PageName.Lmp.LmpEvent,
				})
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			let isValid = true
			this.isValid.title = true
			this.isValid.content = true
			this.isValid.url = true

			if (!this.formData.title) {
				isValid = false
				this.isValid.title = false
			}
			if (!this.formData.content || !this.formData.content.image) {
				isValid = false
				this.isValid.image = false
			}
			if (this.formData.url && !this.validateUrl(this.formData.url)) {
				isValid = false
				this.inValidLinkText = '프로토콜을 확인해 주세요'
				this.isValid.url = false
			}

			return isValid
		},
		validateUrl(string) {
			try {
				const newUrl = new URL(string)
				return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
			} catch (e) {
				return false
			}
		},
		setData() {
			console.log(this.item)
			this.isHide = this.item.isHide
			Object.keys(this.item).forEach(key => {
				switch (key) {
					case 'title':
					case 'url':
						this.formData[key] = this.item[key]
						break
					case 'startDatetime':
					case 'endDatetime':
						this.formData[key] = new Date(this.item[key])
						break
					case 'image':
						this.formData.content[key] = this.item[key]
						break
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
.toggle-wrapper {
	display: inline-block;
	height: 100%;
	position: relative;
	.toggle {
		width: 68px;
		height: 100%;
		border-radius: 20px;
		cursor: pointer;
		background-color: #808080;
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 10px;
			color: #fff;
			transition: 1s;
			transform: translate(95%, -50%);
		}
		&:before {
			content: '';
			background-color: #fff;
			width: 28px;
			height: 28px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 1px;
			transition: 0.4s;
			transform: translate(5%, -50%);
		}
		&.active {
			background-color: #0dc577;
			&:after {
				transform: translate(0%, -50%);
				// content: 'ON';
			}
			&:before {
				transform: translate(130%, -50%);
			}
		}
	}
}
</style>
