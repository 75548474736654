import brandAPI from '@/services/api/brand'
import { refineAddressListTableData, refineTemplateListTableData } from '@/store/modules/dataRefiner'

const brand = {
	namespaced: true,
	state: {
		brands: {
			count: 0,
			list: [],
		},
	},
	getters: {
		brandList: state => state.brands.list,
	},
	mutations: {
		setBrandList: (state, payload) => {
			state.brands.count = payload.count
			state.brands.list = payload.list
		},
	},
	actions: {
		lookupBrandList: async ({ commit }, params) => {
			const response = await brandAPI.lookupBrandList(params)
			commit('setBrandList', response.data)
		},
	},
}

export default brand
