<template>
	<div class="status-changer-wrapper">
		<div class="selected-order-count-text">{{ selectedOrderCountText }}</div>
		<hr class="divide" />
		<div class="selected-product-text">선택한 상품을</div>
		<div class="select-wrapper" @click="toggleOrderStatusSelect">
			<div class="text-box">
				<p v-if="selectedOrderStatus.id">{{ selectedOrderStatus.value }}</p>
				<p v-else class="placeholder">주문상태 선택</p>
			</div>
			<div class="image-box">
				<img src="@/assets/svg/app/select-triangle.svg" alt="" />
			</div>
			<ul v-show="isShowOrderStatusSelect">
				<li v-for="(status, index) in statusList" :key="`status_${index}`" :value="status.value" @click="selectOrderStatus(index)">
					{{ status.value }}
				</li>
				<li class="no-status" v-if="!statusList.length">변경 가능한 상태 없음</li>
			</ul>
		</div>
		<div class="button-wrapper">
			<button class="btn" @click="change">변경</button>
		</div>
		<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="completeCommonModal"></modal-common>
		<modal-shipping-info
			ref="modal-shipping-info"
			:isShow="isShowModalShippingInfo"
			:editType="shippingInfoModalEditType"
			:orderItemList="selectedList"
			@hide="isShowModalShippingInfo = false"
			@complete="emitChange"
		></modal-shipping-info>
		<modal-order-cancel
			ref="modal-order-cancel"
			:orderItemList="selectedList"
			:isShow="isShowModalOrderCancel"
			@hide="isShowModalOrderCancel = false"
			@complete="emitChange"
		></modal-order-cancel>
		<modal-order-cancel-request-reject
			ref="modal-order-cancel-request-reject"
			:orderItemList="selectedList"
			:isShow="isShowModalOrderCancelRequestReject"
			@hide="isShowModalOrderCancelRequestReject = false"
			@complete="emitChange"
		></modal-order-cancel-request-reject>
		<modal-order-approve
			:orderItemList="selectedList"
			:isShow="isShowModalOrderApprove"
			@hide="isShowModalOrderApprove = false"
			@complete="emitChange"
		></modal-order-approve>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import ModalShippingInfo from '@/views/order/orderProduct/modals/ModalShippingInfo.vue'
import ModalOrderCancel from '@/views/order/orderProduct/modals/ModalOrderCancel.vue'
import ModalOrderCancelRequestReject from '@/views/order/orderProduct/modals/ModalOrderCancelRequestReject.vue'
import ModalOrderApprove from '@/views/order/orderProduct/modals/ModalOrderApprove.vue'

const STATUS = {
	WAIT: ['APPROVE', 'SHIPPING_ING', 'SHIPPING_COMPLETE', 'CANCEL'], // 재고 확인 중
	APPROVE: ['SHIPPING_ING', 'SHIPPING_COMPLETE', 'CANCEL'], // 승인 완료
	SHIPPING_ING: ['SHIPPING_COMPLETE', 'CANCEL'], // 배송중
	SHIPPING_COMPLETE: ['SHIPPING_ING', 'CANCEL'], // 배송완료
	WAIT_CANCEL: ['WAIT_CANCEL_REQUEST_APPROVE', 'WAIT_CANCEL_REQUEST_REJECT'], // 취소 대기중(취소 요청)
	CANCEL: [],
	CONFIRM: ['CANCEL']
}

const STATUS_NAME = {
	WAIT: '승인 대기',
	APPROVE: '승인 완료',
	WAIT_CANCEL_REQUEST_APPROVE: '주문취소 요청승인',
	WAIT_CANCEL_REQUEST_REJECT: '주문취소 요청거절',
	SHIPPING_ING: '배송중',
	SHIPPING_COMPLETE: '배송완료',
	CANCEL: '주문취소',
}

const EDIT_TYPE = {
	SHIPPING_INFO: 'shippingInfo',
	SHIPPING_START: 'shippingStart',
	SHIPPING_COMPLETE: 'shippingComplete',
}

export default {
	name: 'OrderStatusChanger',
	components: {
		ModalCommon,
		ModalShippingInfo,
		ModalOrderCancel,
		ModalOrderCancelRequestReject,
		ModalOrderApprove,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedOrderStatus: { id: '', name: '' },
			isShowOrderStatusSelect: false,
			isShowModalShippingInfo: false,
			isShowModalOrderCancel: false,
			isShowModalOrderCancelRequestReject: false,
			isShowModalOrderApprove: false,
			modalCommonType: '',
			shippingInfoModalEditType: EDIT_TYPE.SHIPPING_START,
		}
	},
	computed: {
		selectedOrderCountText() {
			let text = '상품을 선택해주세요'
			if (this.selectedList.length) text = `${this.selectedList.length}개 선택됨`
			return text
		},
		statusList() {
			Object.assign(this.$data, this.$options.data.call(this))
			const statusCount = {
				APPROVE: 0,
				WAIT: 0,
				WAIT_CANCEL_REQUEST_APPROVE: 0,
				WAIT_CANCEL_REQUEST_REJECT: 0,
				SHIPPING_ING: 0,
				SHIPPING_COMPLETE: 0,
				CANCEL: 0,
			}

			this.selectedStatusList.forEach(item => {
				if (STATUS[item]) {
					STATUS[item].forEach(el => {
						statusCount[el]++
					})
				}
			})

			const result = []
			for (let key in statusCount) {
				if (statusCount[key] === this.selectedStatusList.size && this.selectedStatusList.size !== 0)
					result.push({ id: key, value: STATUS_NAME[key] })
			}
			return result
		},
		selectedStatusList() {
			return new Set(
				this.selectedList.map(({ cancelStatus, status }) => {
					if (cancelStatus === 'ING') return 'WAIT_CANCEL'
					else if (cancelStatus === 'DONE') return 'CANCEL'
					else return status
				})
			)
		},
	},
	methods: {
		...mapActions('order', ['approveOrder', 'completeShipping', 'cancelOrder', 'cancelOrderRequestApprove']),
		toggleOrderStatusSelect() {
			this.isShowOrderStatusSelect = !this.isShowOrderStatusSelect
		},
		selectOrderStatus(index) {
			this.selectedOrderStatus = this.statusList[index]
		},
		change() {
			switch (this.selectedOrderStatus.value) {
				case STATUS_NAME.APPROVE: // 승인 완료
					this.changeApprove()
					break
				case STATUS_NAME.WAIT_CANCEL_REQUEST_APPROVE: // 주문취소 요청승인
					this.changeOrderCancelRequestApprove()
					break
				case STATUS_NAME.WAIT_CANCEL_REQUEST_REJECT: // 주문취소 요청거절
					this.changeOrderCancelRequestReject()
					break
				case STATUS_NAME.SHIPPING_ING: // 배송 중
					this.changeShippingIng()
					break
				case STATUS_NAME.SHIPPING_COMPLETE: // 배송 완료
					this.changeShippingComplete()
					break
				case STATUS_NAME.CANCEL: // 주문 취소
					this.changeCancel()
					break
			}
		},
		changeApprove() {
			const isEmptyEstimatedReleaseDatetime = this.selectedList.some(selectedItem => !selectedItem.estimatedReleaseDatetime)
			if (isEmptyEstimatedReleaseDatetime) {
				this.isShowModalOrderApprove = true
				return
			}

			this.modalCommonType = STATUS_NAME.APPROVE
			this.$refs['modal-common'].show(
				'승인 완료으로 변경',
				'선택한 상품의 주문상태를 <span style="font-weight: 700;">승인 완료</span>으로 변경하시겠습니까?',
				'complete',
				'변경'
			)
		},
		async updateApprove() {
			try {
				await this.approveOrder({
					orderItems: this.selectedList.map(({ orderItemId, estimatedReleaseDatetime }) => {
						return {
							id: orderItemId,
							estimatedReleaseDatetime: this.formatDate(estimatedReleaseDatetime),
						}
					}),
				})

				this.$refs['modal-common'].hide()
				this.$root.toast('주문상태 변경 완료', '승인 완료으로 변경되었습니다.', ToastType.SUCCESS)
				this.emitChange()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		changeShippingIng() {
			this.isShowModalShippingInfo = true
			this.shippingInfoModalEditType = EDIT_TYPE.SHIPPING_START
		},
		changeShippingComplete() {
			const emptyShippingMethod = this.selectedList.some(({ shippingMethod, shippingCompany, invoiceNumber }) => {
				if (!shippingMethod) return true
				else if (shippingMethod === '택배 배송' && (!shippingCompany || !invoiceNumber)) return true
			})
			if (emptyShippingMethod) {
				this.isShowModalShippingInfo = true
				this.shippingInfoModalEditType = EDIT_TYPE.SHIPPING_COMPLETE
			} else {
				this.modalCommonType = STATUS_NAME.SHIPPING_COMPLETE
				this.$refs['modal-common'].show(
					'배송완료로 변경',
					'선택한 상품의 주문상태를 <span style="font-weight: 700;">배송완료</span>로 변경하시겠습니까?',
					'complete',
					'변경'
				)
			}
		},
		async updateShippingComplete() {
			try {
				const response = await this.completeShipping({
					orderItems: this.selectedList.map(({ orderItemId: id }) => {
						return {
							id,
						}
					}),
				})
				if (response?.status === 200) {
					this.$refs['modal-common'].hide()
					this.$root.toast('주문상태 변경 완료', '배송완료로 변경되었습니다.', ToastType.SUCCESS)
					this.emitChange()
				}
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		changeCancel() {
			this.isShowModalOrderCancel = true
		},
		changeOrderCancelRequestReject() {
			this.isShowModalOrderCancelRequestReject = true
		},
		changeOrderCancelRequestApprove() {
			this.modalCommonType = STATUS_NAME.WAIT_CANCEL_REQUEST_APPROVE
			this.$refs['modal-common'].show(
				'주문취소 요청 승인',
				'주문취소 요청을 승인하면 주문이 취소되며, 되돌릴 수 없습니다.<br /> 선택한 상품의 취소를 승인하시겠습니까?',
				'complete',
				'승인'
			)
		},
		async updateOrderCancelRequestApprove() {
			try {
				const response = await this.cancelOrderRequestApprove({
					orderItemIds: this.selectedList.map(({ orderItemId }) => orderItemId),
				})
				if (response?.status === 205) {
					this.$refs['modal-common'].hide()
					this.$root.toast('주문취소 요청 승인 완료', '주문취소로 변경되었습니다.', ToastType.SUCCESS)
					this.emitChange()
				}
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		completeCommonModal() {
			if (this.modalCommonType === STATUS_NAME.APPROVE) this.updateApprove()
			else if (this.modalCommonType === STATUS_NAME.SHIPPING_COMPLETE) this.updateShippingComplete()
			else if (this.modalCommonType === STATUS_NAME.WAIT_CANCEL_REQUEST_APPROVE) this.updateOrderCancelRequestApprove()
		},
		emitChange() {
			this.$emit('change')
		},
	},
}
</script>

<style lang="scss" scoped>
.status-changer-wrapper {
	width: 100%;
	height: 76px;
	background-color: #fff3d6;
	margin-top: 10px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	.selected-order-count-text {
		font-size: 1rem;
		font-weight: 700;
		color: $FONT_YELLOW;
	}
	.selected-product-text {
		font-size: 1rem;
	}
	.divide {
		border-left: 1px solid $COLOR_DDD;
		height: 20px;
		width: 0px;
		margin: 0 10px;
		padding-right: 5px;
		border-top: 0;
	}
	.select-wrapper {
		width: 180px;
		height: 44px;
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;
		position: relative;
		display: inline-flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
		padding: 0 10px;
		margin: 0 10px;
		background-color: $PRIMARY_WHITE;
		.text-box {
			display: flex;
			align-items: center;
			font-weight: 700;
			.placeholder {
				color: $FONT_PLACEHOLDER;
			}
		}
		.image-box {
			padding-left: 10px;
			display: flex;
			align-items: center;
		}
		ul {
			width: 180px;
			max-height: 200px;
			position: absolute;
			top: 44px;
			right: 0;
			background-color: $PRIMARY_WHITE;
			border: 1px solid $INPUT_BORDER;
			border-radius: 4px;
			z-index: 11;
			overflow-y: auto;
			li {
				font-size: 0.875rem;
				height: 40px;
				display: flex;
				align-items: center;
				padding: 10px 15px;
				&.no-status {
					color: $FONT_PLACEHOLDER;
				}
				&:hover {
					background-color: $HOVER_BACKGROUND;
				}
			}
		}
	}
	.button-wrapper {
		button.btn {
			width: 80px;
			height: 44px;
			display: flex;
			justify-content: center;
			background: $FONT_YELLOW;
			border-radius: 5px;
			color: $PRIMARY_WHITE;
			font-size: 1rem;
			font-weight: 700;
		}
	}
}
</style>
