import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Cs.CancelRefund,
		name: PageName.CS.CancelRefund,
		props: true,
		component: () => import('@/views/cs/cancelRefund/pages/list.vue'),
	},
	{
		path: RoutePath.Cs.CancelRefundDetail,
		name: PageName.CS.CancelRefundDetail,
		props: true,
		component: () => import('@/views/cs/cancelRefund/pages/detail.vue'),
	},
	{
		path: RoutePath.Cs.Inquiry,
		name: PageName.CS.Inquiry,
		props: true,
		component: () => import('@/views/cs/inquiry/pages/list.vue'),
	},
	{
		path: RoutePath.Cs.InquiryDetail,
		name: PageName.CS.InquiryDetail,
		props: true,
		component: () => import('@/views/cs/inquiry/pages/detail.vue'),
	},
]
