<template>
	<div class="header-wrapper">
		<h2 class="header-title">1:1문의</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box class="search-box" placeholder="작성자, 문의번호, 연구실로 검색" @change="changeKeyword"></search-box>
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/common/filter-active.svg')"
					:image="require('@/assets/svg/common/filter.svg')"
					@click="filterBtnClick"
				></image-button>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button text="목록 다운로드" :image="require('@/assets/svg/common/excel-download.svg')" @click="excelBtnClick"></image-button>
			</div>
		</div>
		<div class="status-btn-wrapper">
			<list-filter-tab
				v-for="status in statusList"
				:isActive="status.id === selectedStatus"
				:text="status.name"
				:key="status.id"
				@click="changeActiveStatus(status.id)"
			></list-filter-tab>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="changeLoadParams" />
	</div>
</template>
<script>
import SearchBox from '@/views/common/components/searchBox/index.vue'
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import ListFilterTab from '@/views/common/components/button/ListFilterButton.vue'
import ModalFilter from '../modal/ModalFilter.vue'
import { mapGetters } from 'vuex'
export default {
	components: {
		SearchBox,
		ImageButton,
		ListFilterTab,
		ModalFilter,
	},
	computed: {
		...mapGetters('inquiry/list', ['selectedStatus', 'filteredCount']),
		filterText() {
			if (this.filteredCount > 0) return `필터(${this.filteredCount})`
			return '필터'
		},
		statusList() {
			return [
				{
					id: 'ALL',
					name: '전체',
				},
				{
					id: 'WAIT',
					name: '답변 대기',
				},
				{
					id: 'COMPLETE',
					name: '답변 완료',
				},
			]
		},
	},
	methods: {
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
		excelBtnClick() {},
		changeActiveStatus(status) {
			this.changeLoadParams({ isCommented: status == 'ALL' ? [] : [status] })
		},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
	},
}
</script>
<style lang="scss" scoped>
.header-wrapper {
	position: sticky;
	background-color: white;
	top: 0;
	display: flex;
	flex-direction: column;
	// width: calc(100% - 250px);
	// height: 158px;
	z-index: 100;
	border-bottom: 1px solid #eee; // $COLOR_EEE
	.header-title {
		text-align: left;
		font-weight: 700;
		font-size: 24px;
		padding: 10px 30px 5px 30px;
		height: 50px;
	}
	.filter-button-wrapper {
		display: flex;
		width: 100%;
		min-width: 1200px;
		height: 54px;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		.filter-button-wrapper-left {
			display: inherit;
			gap: 10px;
			.search-box {
				width: 300px;
			}
		}
		.filter-button-wrapper-right {
			display: inherit;
			gap: 10px;
		}
	}
	.status-btn-wrapper {
		display: flex;
		width: 100%;
		height: 54px;
		align-items: center;
		padding: 12px 30px;
		border-top: 1px solid #eee; // $COLOR_EEE
		gap: 8px;
	}
}
</style>
