<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div class="flex" style="min-width: 180px; max-width: 250px">
				<span style="text-decoration: underline">
					{{ isData(item.orderNo) }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700">
					{{ isData(item.productName) }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ isData(item.instituteName) }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.instituteId) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.orderer.name) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(phone) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(address) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.deliveryRequest) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ commaNum(item.totalOrderItemPrice) }}원 </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import Validation from '@/utils/Validation'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		address() {
			const { postcode, address, addressDetail } = this.item
			return `[${postcode}] ${address} ${addressDetail}`
		},
		phone() {
			return Validation.autoHypenTel(this.item.orderer.phone)
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: this.item.orderId,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
