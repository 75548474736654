<template>
	<b-modal :id="id" centered :title="title" ref="modal" :size="size" hide-footer @show="onShow">
		<div class="flex">
			<search-box class="search-box" placeholder="검색" @change="onChangeKeyword" />
		</div>
		<div class="table-wrap">
			<table>
				<table-header :headers="headers" />
				<tbody v-if="sellerList.length > 0">
					<tr v-for="(item, index) in sellerList" :key="`seller_${index}`">
						<td class="logo-image-container"><img class="logo-image" :src="item.image" /></td>
						<td>{{ item.name }}</td>
						<td>{{ item.contactName }}</td>
						<td>{{ item.contactPhone }}</td>
						<td>
							<div class="box">
								<p>{{ item.memo }}</p>
								<button class="btn btn-complete" @click="onClickSelect(item)">선택</button>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<div class="empty-area">
						<span>이름으로 검색해 주세요</span>
					</div>
				</tbody>
			</table>
		</div>
	</b-modal>
</template>

<script>
import SearchBox from '@/views/common/components/searchBox/index.vue'
import TableHeader from '@/components/table/TableHeader'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
	name: 'ModalSearchSeller',
	components: {
		SearchBox,
		TableHeader,
	},
	props: {
		id: {
			type: String,
			default: 'modal-search-seller',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'xl',
		},
	},
	data() {
		return {
			searchKeyword: '',
			headers: [
				{ name: '로고 이미지', key: 'image' },
				{ name: '판매자 이름', key: 'name' },
				{ name: '담당자', key: 'contactName' },
				{ name: '연락처', key: 'tel' },
				{ name: '메모', key: 'memo' },
			],
		}
	},
	computed: {
		...mapGetters('seller/list', ['sellerList']),
	},
	mounted() {
		this.initParams()
	},
	methods: {
		...mapMutations('seller/list', ['initParams']),
		...mapActions('seller/list', ['getList', 'setLoadParams']),
		async fetchSellerList() {
			await this.getList({
				keyword: this.searchKeyword,
			})
		},
		onChangeKeyword(keyword) {
			this.setLoadParams({ keyword })
			this.fetchSellerList()
		},
		onClickSelect(item) {
			this.$emit('select', item)
			this.$refs['modal'].hide()
		},
		onShow() {
			this.fetchSellerList()
		},
	},
}
</script>

<style lang="scss">
#modal-search-seller {
	.modal-body {
		padding: 0 0;
		.flex {
			height: 60px;
			border-top: 1px solid $COLOR_DDD;
			padding: 0 20px;
			.search-box {
				width: 360px;
				.keyword-wrapper {
					width: 100%;
				}
			}
		}
	}
	.table-wrap {
		width: 100%;
		border: 1px solid $COLOR_DDD;
		min-height: 400px;
		max-height: 480px;
		overflow-y: auto;
		table {
			width: 100%;
			thead {
				th {
					height: 40px;
					font-size: 0.875rem;
					color: $COLOR_666;
					border-bottom: 1px solid $COLOR_DDD;
					padding: 0 8px;
					position: relative;
					&:first-child {
						padding-left: 8px;
						left: 20px;
					}
					&:before {
						position: absolute;
						width: 1px;
						height: 20px;
						background-color: $COLOR_DDD;
						content: '';
						top: calc(50% - 10px);
						left: 0;
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $COLOR_DDD;
					td {
						font-size: 0.875rem;
						padding: 0 8px;
						height: 60px;
						&:first-child {
							padding-left: 28px;
						}
						> div {
							display: flex;
							align-items: center;
							button {
								width: 58px;
								margin-left: auto;
								justify-content: center;
								margin-right: 8px;
							}
						}
						&.logo-image-container {
							text-align: center;
							.logo-image {
								max-width: 150px;
							}
						}
					}
				}
			}
		}
		.empty-area {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;
			width: 100%;
			height: 357px;
			> span {
				font-weight: 400;
				font-size: 0.875rem;
				color: $COLOR_999;
			}
		}
	}
}
</style>
