import axiosInstance from '@/services/axios'

const dummyList = [
	{
		id: 1,
		name: '고려대학교 테스트연구실1',
	},
	{
		id: 2,
		name: '고려대학교 테스트연구실2',
	},
	{
		id: 3,
		name: '고려대학교 테스트연구실3',
	},
	{
		id: 4,
		name: '고려대학교 테스트연구실4',
	},
]

const endPoint = {

}

const api = {
	list: loadParams => {
		return {
			data: {
				count: 4,
				list: dummyList
			}
		}
	},
}
export default api