<template>
	<base-item :title="title">
		<board-editor
			class="text-editor"
			:value="value"
			:placeholder="placeholder"
			:style="editorStyle"
			@input="$emit('input', $event)"
		></board-editor>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'
import BoardEditor from '@/views/common/components/BoardEditor.vue'

export default {
	name: 'InputTextEditor',
	components: {
		BaseItem,
		BoardEditor,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		editorStyle: {
			type: Object,
			default: () => ({}),
		},
	},
}
</script>

<style lang="scss" scoped>
.text-editor {
	width: 810px;
	height: 154px;
	overflow: auto;
}
</style>
