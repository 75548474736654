import axios from 'axios'
import cookies from 'vue-cookies'
import qs from 'qs'
import { logout, setToken } from './api/common'

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_LMP_URL,
	timeout: 100000,
})
axiosInstance.defaults.paramsSerializer = params => {
	return qs.stringify(params, { encode: false, arrayFormat: 'brackets' })
}

axiosInstance.interceptors.request.use(
	function (config) {
		return config
	},
	function (error) {
		return Promise.reject(error)
	}
)

axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		try {
			switch (error.response.status) {
				case 401:
				case 403:
					alert('권한이 없습니다. 개발팀에 문의해주세요.')
					logout()
					axiosInstance.setAuth()
					location.href = '/'
					break
				case 404:
					console.warn(error.response.data.msg)
					break
				default:
					break
			}

			return Promise.reject(error)
		} catch (error) {
			return Promise.reject(error)
		}
	}
)

axiosInstance.setAuth = function (token) {
	axiosInstance.defaults.headers.common['Authorization'] = token
}

axiosInstance.defaults.instituteId = cookies.get('instituteId')

export default axiosInstance
