import axiosInstance from '@/services/axios'

const endPoint = {
	lookupSellerInfo: () => `/partner/seller`,
	editSellerInfo: () => `/partner/seller`,

	getList: () => `/admin/seller/list`,

	lookupAddressList: () => `/admin/seller/address/list`,
	registAddress: () => `/admin/seller/address`,
	deleteAddress: () => `/admin/seller/address`,
	updateAddress: () => `/admin/seller/address`,

	lookupTemplateList: () => `/admin/seller/cs/list`,
	registTemplate: () => `/admin/seller/cs`,
	deleteTemplate: () => `/admin/seller/cs`,
	updateTemplate: () => `/admin/seller/cs`,
}

const productAPI = {
	// 판매자 정보
	lookupSellerInfo: params => axiosInstance.get(endPoint.lookupSellerInfo(), { params }),
	editSellerInfo: body => axiosInstance.patch(endPoint.editSellerInfo(), body),

	// 판매자 목록
	getList: params => axiosInstance.get(endPoint.getList(), { params }),

	// 주소록 관리
	lookupAddressList: params => axiosInstance.get(endPoint.lookupAddressList(), { params }),
	registAddress: body => axiosInstance.post(endPoint.registAddress(), body),
	deleteAddress: params => axiosInstance.delete(endPoint.deleteAddress(), { params }),
	updateAddress: body => axiosInstance.patch(endPoint.updateAddress(), body),

	// 템플릿 관리
	lookupTemplateList: params => axiosInstance.get(endPoint.lookupTemplateList(), { params }),
	registTemplate: body => axiosInstance.post(endPoint.registTemplate(), body),
	deleteTemplate: params => axiosInstance.delete(endPoint.deleteTemplate(), { params }),
	updateTemplate: body => axiosInstance.patch(endPoint.updateTemplate(), body),
}

export default productAPI
