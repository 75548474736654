<template>
	<div class="page-wrapper">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<RequestTable class="table" :list="list" />
		</div>
		<pagination ref="pagination" :total="count" @page="pagination"></pagination>
	</div>
</template>
<script>
import Pagination from '@/views/common/components/CommonPagenation.vue'
import ListHeader from '../components/ListHeader.vue'
import RequestTable from '../components/Table.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	components: {
		ListHeader,
		Pagination,
		RequestTable,
	},
	computed: {
		...mapState('chemicalApi/request', ['list', 'count']),
		...mapGetters('chemicalApi/request', ['offset', 'length']),
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.previousPage = from
		})
	},
	data() {
		return {
			previousPage: null,
		}
	},
	mounted() {
		this.$nextTick().then(() => {
			this.$refs.pagination.changePage(this.offset, this.length)
		})
		if (this.previousPage.name !== 'ChemicalAPIRequestDetail') this.setInitParams()
		else this.getList()
	},
	methods: {
		...mapActions('chemicalApi/request', ['getList', 'setLoadParams', 'setInitParams']),
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 215px);
	}
}
</style>
