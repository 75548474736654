<template>
	<div class="product-info-form">
		<div v-for="(field, index) in fields" :key="`field_${index}`">
			<product-info-form-item
				ref="info-form-item"
				:field="field"
				@changeValue="changeValue"
				@changeOption="changeOption"
			></product-info-form-item>
		</div>
	</div>
</template>

<script>
import ProductInfoFormItem from '@/views/product/registration/components/productInfo/ProductInfoFormItem.vue'

export default {
	name: 'ProductInfoForm',
	components: {
		ProductInfoFormItem,
	},
	props: {
		fields: {
			type: Array,
			default: () => [],
		},
		selectedProduct: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		changeValue(value, field) {
			this.$emit('changeValue', value, field)
		},
		changeOption(value, field) {
			this.$emit('changeOption', value, field)
		},
		setProductInfo(item) {
			this.$refs['info-form-item'].forEach(component => {
				component.setProductInfo(item)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.product-info-form {
	height: 100%;
}
</style>
