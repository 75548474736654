import axiosInstance from '@/services/axios'

const endPoint = {
	lookupLabList: () => `admin/institute/list`,
	lookupLab: id => `admin/institute?id=${id}`,
	buyLabBlock: () => `admin/institute/block`,
	updateCredit: () => `admin/institute/credit`,
	updatePaymentDueMonth: () => `admin/institute/paymentDueMonth`,
	brandTargetPromotion: () => `admin/institute/brandTargetPromotion`,
}

const api = {
	lookupLabList: params => axiosInstance.get(endPoint.lookupLabList(), { params }),
	lookupLab: id => axiosInstance.get(endPoint.lookupLab(id)),
	buyLabBlock: body => axiosInstance.patch(endPoint.buyLabBlock(), body),
	updateCredit: body => axiosInstance.patch(endPoint.updateCredit(), body),
	updatePaymentDueMonth: body => axiosInstance.patch(endPoint.updatePaymentDueMonth(), body),
	setBrandTargetPromotion: body => axiosInstance.post(endPoint.brandTargetPromotion(), body),
	deleteBrandTargetPromotion: params => axiosInstance.delete(endPoint.brandTargetPromotion(), { params }),
}

export default api
