<template>
	<tr class="table-header">
		<th>
			<span>판매상태</span>
		</th>
		<th>
			<span>상품번호</span>
		</th>
		<th>
			<span>판매자</span>
		</th>
		<th>
			<span>정보</span>
		</th>
		<th>
			<span>옵션</span>
		</th>
		<th>
			<span>총 재고수량</span>
		</th>
		<th>
			<span>카테고리</span>
		</th>
		<th>
			<span>브랜드</span>
		</th>
		<th>
			<span>제품번호</span>
		</th>
		<th>
			<span>CAS No</span>
		</th>
		<th>
			<span>검색 태그</span>
		</th>
		<th>
			<span>예상 출고일</span>
		</th>
		<th>
			<span>개별연락</span>
		</th>
		<th>
			<span>퀵서비스</span>
		</th>
		<th>
			<span>출고지</span>
		</th>
		<th>
			<span>상품등록일시</span>
		</th>
		<th>
			<span>최종수정일시</span>
		</th>
		<th>
			<span>판매중지일시</span>
		</th>
	</tr>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	computed: {
		...mapState('lms/brand', ['list', 'checkedList']),
		isAllChecked() {
			if (this.list.length === 0) return false
			return this.list.length === this.checkedList.length
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('lms/brand', ['setAllCheck', 'initCheckedList']),
		allCheck(event) {
			if (event.target.checked) this.setAllCheck()
			else this.initCheckedList()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
