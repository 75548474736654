import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	//상품 요청 목록
	{
		path: RoutePath.Estimate.RequestProductList,
		name: PageName.Estimate.RequestProductList,
		props: true,
		component: () => import('@/views/estimate/request/pages/RequestProductList.vue'),
	},
	//상품 요청 상세
	{
		path: RoutePath.Estimate.RequestProductDetail,
		name: PageName.Estimate.RequestProductDetail,
		props: true,
		component: () => import('@/views/estimate/request/pages/RequestProductDetail.vue'),
	},
	//견적함
	{
		path: RoutePath.Estimate.EstimateList,
		name: PageName.Estimate.EstimateList,
		props: true,
		component: () => import('@/views/estimate/estimate/pages/EstimateList.vue'),
	},
	//견적함 상세
	{
		path: RoutePath.Estimate.EstimateDetail,
		name: PageName.Estimate.EstimateDetail,
		props: true,
		component: () => import('@/views/estimate/estimate/pages/EstimateDetail.vue'),
	},
	//견적함 작성/복제
	{
		path: RoutePath.Estimate.EstimateForm,
		name: PageName.Estimate.EstimateForm,
		props: true,
		component: () => import('@/views/estimate/estimate/pages/EstimateForm.vue'),
	},
	{
		path: RoutePath.Estimate.EstimateExport,
		name: PageName.Estimate.EstimateExport,
		props: true,
		component: () => import('@/views/estimate/estimate/pages/EstimateExport.vue'),
	},
]
