import { TableField } from '@/utils/define/table/JwTable'
import { formatDatetime, _2H2MFormat } from '@/utils/modules/DateUtils'
import IconDelete from '@/assets/svg/common/icon-trash.svg'
import IconEdit from '@/assets/svg/common/icon-edit.svg'

const customerConsultingTemplateTable = {
	width: '100%',
	height: '100%',
	options: {
		selectColumnKey: TableField.TemplateName,
		isCheckbox: true,
		table: {
			style: {},
		},
		header: {
			style: {
				height: '60px;',
				color: '#666666',
				fontSize: '14px',
				backgroundColor: '#FFFFFF',
			},
		},
		row: {
			style: {
				height: '60px',
			},
		},
		columns: [
			{
				key: TableField.TemplateName,
				balloon: true,
				bold: true,
				name: '템플릿 이름',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: TableField.CustomerConsultingContact,
				name: '고객상담 연락처',
				style: {
					width: '150px',
				},
				classes: [],
			},
			{
				key: TableField.ConsultingInfo,
				name: '상담 안내',
				balloon: true,
				style: {
					width: '500px',
				},
				classes: [],
			},
			{
				key: TableField.TemplateCreateDatetime,
				name: '템플릿 생성일',
				style: {
					width: '200px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
			{
				key: TableField.TemplateEdidDatetime,
				name: '템플릿 수정일',
				style: {
					width: '200px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
			{
				key: TableField.Management,
				name: '관리',
				type: 'doubleImgTextButton',
				style: {
					width: '150px',
				},
				classes: [],
				firstImgHandler: () => {
					return IconEdit
				},
				secondImgHandler: () => {
					return IconDelete
				},
				firstClickFn: () => {},
				secondClickFn: () => {},
			},
		],
		tableData: {
			TemplateName: [],
			CustomerConsultingContact: [],
			ConsultingInfo: [],
			TemplateCreateDatetime: [],
			TemplateEdidDatetime: [],
			Management: [],
		},
	},
}

export { customerConsultingTemplateTable }
