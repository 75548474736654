<template>
	<div class="header-wrapper">
		<h2 class="header-title">공지사항 관리</h2>
		<div class="filter-button-wrapper">
			<div class="filter-button-wrapper-left">
				<search-box class="search-box" placeholder="검색" @change="changeKeyword"></search-box>
			</div>
			<div class="filter-button-wrapper-right">
				<image-button text="공지사항 등록" :image="require('@/assets/svg/sellInfo/add-button-icon.svg')" @click="createNoticeClick">
				</image-button>
			</div>
		</div>
	</div>
</template>

<script>
import SearchBox from '@/views/common/components/searchBox/index.vue'
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import ListFilterTab from '@/views/common/components/button/ListFilterButton.vue'

export default {
	components: {
		SearchBox,
		ImageButton,
		ListFilterTab,
	},
	computed: {},
	methods: {
		excelBtnClick() {},
		changeKeyword(keyword) {
			this.changeLoadParams({ keyword: keyword })
		},
		changeLoadParams(params) {
			this.$emit('changeLoadParams', params)
		},
		createNoticeClick() {
			this.$router.push({ name: 'NoticeCreate' })
		},
	},
}
</script>
<style lang="scss" scoped>
.header-wrapper {
	position: sticky;
	background-color: white;
	top: 0;
	display: flex;
	flex-direction: column;
	// width: calc(100% - 250px);
	// height: 158px;
	z-index: 100;
	border-bottom: 1px solid #eee; // $COLOR_EEE
	.header-title {
		text-align: left;
		font-weight: 700;
		font-size: 24px;
		padding: 10px 30px 5px 30px;
		height: 50px;
	}
	.filter-button-wrapper {
		display: flex;
		width: 100%;
		min-width: 1200px;
		height: 54px;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		.filter-button-wrapper-left {
			display: inherit;
			gap: 10px;
			.search-box {
				width: 300px;
			}
		}
		.filter-button-wrapper-right {
			display: inherit;
			gap: 10px;
		}
	}
}
</style>
