<template>
	<tr class="table-header">
		<th>
			<span>계정</span>
		</th>
		<th>
			<span>비밀번호</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>계정등록일시</span>
		</th>
		<th>
			<span>관리</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'
export default {
	props: {
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('buyer/list', ['order', 'orderDirection']),
	},
	mixins: [MixinHeader],
	data() {
		return {
			checked: false,
		}
	},
	watch: {},
	methods: {
		...mapActions('buyer/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
