const TableField = {}

// 공통
TableField.Status = 'Status' // 상태
TableField.Info = 'Info' // 정보
TableField.ManagementNo = 'ManagementNo' // 자체 관리번호
TableField.Option = 'Option' // 옵션
TableField.TotalQuantity = 'TotalQuantity' // 총 재고수량
TableField.Category = 'Category' // 카테고리
TableField.Brand = 'Brand' // 브랜드
TableField.ProductNo = 'ProductNo' // 제품번호
TableField.Casno = 'Casno' // CAS No
TableField.ReleaseLocation = 'ReleaseLocation' // 출고지
TableField.ProductRegistDatetime = 'ProductRegistDatetime' // 상품등록일시
TableField.Volume = 'Volume' // 용량

// 상품 등록 관리
TableField.SellProductNo = 'SellProductNo' // 상품번호
TableField.LastEditDatetime = 'LastEditDatetime' // 최종수정일시
TableField.RegistrationProposeDatetime = 'RegistrationProposeDatetime' // 등록 신청일시
TableField.ExaminationCompleteDatetime = 'ExaminationCompleteDatetime' // 심사 완료일시
TableField.RequestNo = 'RequestNo' // 요청번호
TableField.RequestProduct = 'RequestProduct' // 요청상품
TableField.RequestPrice = 'RequestPrice' // 요청가격
TableField.RequestTerm = 'RequestTerm' // 요청사항
TableField.Requester = 'Requester' // 요청자
TableField.RequestDatetime = 'RequestDatetime' // 요청일시

// 판매자 관리
TableField.AddressName = 'AddressName' // 주소이름
TableField.Address = 'Address' // 주소
TableField.AddDatetime = 'AddDatetime' // 추가일시
TableField.EditDatetime = 'EditDatetime' // 수정일시
TableField.Management = 'Management' // 관리
TableField.TemplateName = 'TemplateName' // 템플릿 이름
TableField.CustomerConsultingContact = 'CustomerConsultingContact' // 고객상담 연락처
TableField.ConsultingInfo = 'ConsultingInfo' // 상담 안내
TableField.TemplateCreateDatetime = 'TemplateCreateDatetime' // 템플릿 생성일
TableField.TemplateEdidDatetime = 'TemplateEdidDatetime' // 템플릿 수정일

// 주문 관리
TableField.OrderNo = 'OrderNo' // 주문 번호
TableField.OrderItemNo = 'OrderItemNo' // 주문 아이템 번호
TableField.ReleaseVolume = 'ReleaseVolume' // 출고 수량
TableField.EstimatedReleaseDate = 'EstimatedReleaseDate' // 예상 출고일
TableField.DeliveryMethod = 'DeliveryMethod' // 배송방식
TableField.IndividualContact = 'IndividualContact' // 개별연락
TableField.QuickService = 'QuickService' // 퀵 서비스
TableField.OrderNumber = 'OrderNumber' // 주문번호
TableField.Orderer = 'Orderer' // 주문자
TableField.ProductPrice = 'ProductPrice' // 상품단가
TableField.OrderPrice = 'OrderPrice' // 주문금액
TableField.OrderPriceVat = 'OrderPriceVat' // 주문금액 + vat
TableField.OrderDatatime = 'OrderDatatime' // 주문일
TableField.DeliveryRequest = 'DeliveryRequest' // 배송 요청사항
TableField.HiddenMemo = 'HiddenMemo' // 숨김 메모
TableField.StockCheckDatetime = 'StockCheckDatetime' // 재고확인일
TableField.DeliveryStartDatetime = 'DeliveryStartDatetime' // 배송시작일
TableField.DeliveryCompleteDatetime = 'DeliveryCompleteDatetime' // 배송완료일
TableField.RequestCancelDatetime = 'RequestCancelDatetime' // 취소요청일
TableField.CancelOrderDatetime = 'CancelOrderDatetime' // 주문취소일
TableField.ConfirmOrderDatetime = 'ConfirmOrderDatetime' // 구매확정일
TableField.OrderLab = 'OrderLab' // 주문 연구실
TableField.OrderLabId = 'OrderLabId' // 주문 연구실 ID
TableField.Sku = 'Sku' // Sku
TableField.PurchaseUnitPrice = 'PurchaseUnitPrice' // 매입단가
TableField.PaymentNo = 'PaymentNo' // 결제번호
TableField.PaymentStatus = 'PaymentStatus' // 결제상태
TableField.LabSettlementStatus = 'LabSettlementStatus' // 연구실 정산상태
TableField.SellerSettlementStatus = 'SellerSettlementStatus' // 판매자 정산상태
TableField.SettlementMonth = 'SettlementMonth' // 정산월
TableField.PaymentMethod = 'PaymentMethod' // 결제방법
TableField.ApproveDatetime = 'ApproveDatetime' // 승인일시
TableField.CardApproveNo = 'CardApproveNo' // 카드 승인번호
TableField.NationalTaxApproveNo = 'NationalTaxApproveNo' //국세청 승인번호
TableField.DepositDetails = 'DepositDetails' //실제 입금내역
TableField.PaymentDatetime = 'PaymentDatetime' //결제일시
TableField.DepositDatetime = 'DepositDatetime' //입금일시
TableField.SellerInfo = 'SellerInfo' //판매자 정보
TableField.SellerMemo = 'SellerMemo' //판매자 메모
TableField.Platform = 'Platform' //플랫폼

export { TableField }
