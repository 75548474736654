const updateProductPriceTableAllColumns = [
	'id',
	'상태',
	'요청일시',
	'파일명',
	'갱신일시',
	'전체 상품 수',
	'처리된 상품 수',
	'에러 메세지',
]
const updateProductPriceTableRequireColumns = ['id', '요청일시', '파일명', '상태']

export { updateProductPriceTableAllColumns, updateProductPriceTableRequireColumns }
