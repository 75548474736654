<template>
	<div class="apply-discount-box">
		<div class="error-msg" v-if="error">
			<p>{{ errorMessage }}</p>
		</div>
		<div class="box">
			<b>일괄할인</b>
			<input-number-with-unit v-model="allDiscountPrice" :error="error" @input="$emit('input', $event)" />
			<select-option :list="options" :selectValue="options[0].value" @select="$emit('select', $event)" />
			<b>적용하기</b>
		</div>
	</div>
</template>

<script>
import InputNumberWithUnit from '@/views/common/components/formItem/InputNumberWithUnit'
import SelectOption from '@/views/common/components/formItem/SelectOption'

export default {
	name: 'ApplyDiscountBox',
	props: {
		value: {
			type: [Number, String],
			default: 0,
		},
		options: {
			type: Array,
			default: () => [],
		},
		error: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
	},
	components: {
		InputNumberWithUnit,
		SelectOption,
	},
	mounted() {
		this.allDiscountPrice = this.value
	},
	watch: {
		value(val) {
			this.allDiscountPrice = val
		},
	},
	data() {
		return {
			allDiscountPrice: 0,
			allDiscountPercent: 0,
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.apply-discount-box {
	.error-msg {
		width: 100%;
		height: 40px;
		border-radius: 5px;
		background-color: rgba(255, 87, 70, 0.1);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 1rem;
		p {
			font-size: 0.875rem;
			color: $PRIMARY_RED;
			font-weight: 400;
			background-image: url(~@/assets/svg/app/noti-error.svg);
			background-repeat: no-repeat;
			background-size: 20px;
			background-position: center left 0;
			padding-left: 36px;
		}
	}
	.box {
		width: 100%;
		height: 56px;
		background-color: $COMMON_BACKGROUND;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		b {
			display: inline-block;
			margin: 0 1rem;
			font-size: 0.875rem;
			font-weight: 700;
		}
		.input-number-with-unit {
			width: 240px;
		}
		.select-option {
			width: 122px;
			margin-left: 8px;
		}
	}
}
</style>
