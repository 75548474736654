<template>
	<div class="board-editor">
		<div class="editor-btns" v-if="editor">
			<button @click="$refs['image-file'].click()">
				<img src="@/assets/svg/registration/board-image.svg" />
			</button>
			<button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
				<img src="@/assets/svg/registration/board-bold.svg" />
			</button>
			<button @click="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
				<img src="@/assets/svg/registration/board-italic.svg" />
			</button>
			<button @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
				<img src="@/assets/svg/registration/board-strike.svg" />
			</button>
			<button
				@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				:class="{
					'is-active': editor.isActive('heading', { level: 1 }),
				}"
			>
				<img src="@/assets/svg/registration/board-h1.svg" />
			</button>
			<button
				@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				:class="{
					'is-active': editor.isActive('heading', { level: 2 }),
				}"
			>
				<img src="@/assets/svg/registration/board-h2.svg" />
			</button>
			<button @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
				<img src="@/assets/svg/registration/board-dot.svg" />
			</button>
			<button @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
				<img src="@/assets/svg/registration/board-number.svg" />
			</button>
			<button @click="editor.chain().focus().setHorizontalRule().run()">
				<img src="@/assets/svg/registration/board-line.svg" />
			</button>
			<button @click="editor.chain().focus().setHardBreak().run()">
				<img src="@/assets/svg/registration/board-lident.svg" />
			</button>
			<button @click="editor.chain().focus().undo().run()">
				<img src="@/assets/svg/registration/board-undo.svg" />
			</button>
			<button @click="editor.chain().focus().redo().run()">
				<img src="@/assets/svg/registration/board-redo.svg" />
			</button>
			<button @click="setLink" :class="{ 'is-active': editor.isActive('link') }">
				<img src="@/assets/svg/registration/link.svg" />
			</button>
			<input type="file" ref="image-file" accept="image/*" @change="addImage" />
		</div>
		<editor-content ref="editor-content" :editor="editor" />
		<span class="placeholder" v-show="!text">{{ placeholder }}</span>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import { ToastType } from '@/utils/define/ToastType'

Image.options = {
	allowBase64: true,
}

export default {
	components: {
		EditorContent,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			editor: null,
			url: '',
		}
	},
	computed: {
		text() {
			return this.editor?.getText()
		},
	},
	watch: {
		value(value) {
			// HTML
			const isSame = this.editor.getHTML() === value

			// JSON
			// const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

			if (isSame) {
				return
			}

			this.editor.commands.setContent(value, false)
		},
	},
	mounted() {
		this.editor = new Editor({
			content: this.value,
			extensions: [StarterKit, Image, Link],
			onUpdate: () => {
				// HTML
				this.$emit('input', this.editor.getHTML())
			},
		})
	},
	beforeDestroy() {
		this.editor.destroy()
	},
	methods: {
		...mapActions('productRegist/regist', ['addProductImage']),
		async addImage(event) {
			try {
				const file = event.target.files[0]
				const formData = new FormData()
				formData.append('file', file)
				const result = await this.addProductImage(formData)
				this.editor.chain().focus().setImage({ src: result }).run()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		setLink() {
			const previousUrl = this.editor.getAttributes('link').href
			const url = window.prompt('URL', previousUrl)

			// cancelled
			if (url === null) {
				return
			}

			// empty
			if (url === '') {
				this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

				return
			}

			// update link
			this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
		},
	},
}
</script>

<style lang="scss">
.board-editor {
	width: 100%;
	border-radius: 5px;
	border: 1px solid $INPUT_BORDER;
	padding: 10px 12px;
	height: 100%;
	position: relative;
	.editor-btns {
		display: flex;
		button {
			width: 30px;
			height: 30px;
			overflow: hidden;
			border-radius: 5px;
			transition: all 0.3s;
		}
		input[type='file'] {
			width: 0;
			height: 0;
			visibility: hidden;
			opacity: 0;
		}
	}
	.placeholder {
		position: absolute;
		font-size: 0.875rem;
		font-weight: 400;
		display: inline-block;
		top: 50px;
		left: 20px;
		color: $COLOR_666;
	}
	.ProseMirror {
		margin-top: 10px;
		width: 100%;
		text-align: left;
		height: 600px;
		overflow-y: auto;
		img {
			max-width: 100%;
		}
		span.placeholder {
			font-size: 0.875rem;
		}
	}
}
</style>
