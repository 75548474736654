<template>
	<base-item :title="title" :subContentText="subContentText" :required="isRequired" v-click-outside="hideSelect">
		<div
			class="select-wrapper"
			:class="{ error: !isValid }"
			@click="toggleSelect"
			:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
		>
			<span v-if="!selectedValue && placeholder">{{ placeholder }}</span>
			<b v-if="selectedValue && selectedValue.code" ref="code">{{ selectedValue.code }}</b>
			<p v-if="selectedValue" class="name" ref="name">
				{{ selectedValue.name }}
			</p>
		</div>
		<div class="option-wrapper" v-show="isShowSelect">
			<ul>
				<li v-for="(item, index) in list" :key="`item-${index}`" :value="item.value" @click="changeOption(index)">
					<b v-if="item.code">
						{{ item.code }}
					</b>
					<p>
						{{ item.name }}
					</p>
				</li>
			</ul>
		</div>
		<div v-if="!isValid" class="error">
			<img src="@/assets/svg/common/icon-error.svg" alt="error" />
			<span>{{ inValidText }}</span>
		</div>
		<div>
			<slot></slot>
		</div>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputSelect',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		placeholder: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
		value: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			isShowSelect: false,
			selected: null,
		}
	},
	computed: {
		selectedValue() {
			return this.list.find(item => item.value == this.selected)
		},
	},
	created() {
		if (this.value) this.selected = this.value
	},
	watch: {
		value() {
			this.selected = this.value
		},
	},
	methods: {
		toggleSelect() {
			this.isShowSelect = !this.isShowSelect
		},
		changeOption(index) {
			this.selected = this.list[index].value
			this.isShowSelect = false
			this.$emit('changeValue', this.selected)
			this.$emit('input', this.selected)
		},
		hideSelect() {
			this.isShowSelect = false
		},
		setValue(value) {
			this.selected = value
		},
	},
}
</script>

<style lang="scss" scoped>
.select-wrapper {
	width: 30%;
	height: 36px;
	background-image: url(~@/assets/svg/app/select-triangle.svg);
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: center right 8px;
	cursor: pointer;
	border-radius: 5px;
	display: inline-flex;
	align-items: center;
	padding-left: 10px;
	text-align: left;
	border: 1px solid $FILE_BORDER;
	span {
		color: #999999;
	}
	b {
		margin-right: 10px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	p {
		max-width: 100%;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
	&.error {
		border-color: $PRIMARY_RED;
	}
}

.option-wrapper {
	min-width: 30%;
	z-index: 4;
	border-radius: 5px;
	border: 1px solid $INPUT_BORDER;
	background-color: white;
	position: absolute;
	// top: 5px;
	ul {
		overflow-y: auto;
		max-height: 200px;
		padding: 5px;
		li {
			padding-left: 10px;
			padding-right: 10px;
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			width: 100%;
			height: 36px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: $HOVER_BACKGROUND;
				border-radius: 4px;
			}
			b {
				margin-right: 10px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			p {
				max-width: 100%;
				// width: 90px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			// b {
			// 	font-size: 0.875rem;
			// 	margin-right: 10px;
			// 	width: 90px;
			// 	text-overflow: ellipsis;
			// 	overflow: hidden;
			// 	white-space: nowrap;
			// }
			// p {
			// 	max-width: 100%;
			// 	width: 90px;
			// 	margin-right: 10px;
			// 	text-overflow: ellipsis;
			// 	overflow: hidden;
			// 	white-space: nowrap;
			// 	&.isCode {
			// 		max-width: calc(100% - 90px);
			// 	}
			// 	&.name {
			// 		width: 190px;
			// 	}
			// }
		}
	}
}

.error:not(.select-wrapper) {
	display: flex;
	span {
		font-size: 0.75rem;
		color: $PRIMARY_RED;
		margin-left: 6px;
	}
}
</style>
