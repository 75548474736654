<template>
	<portal to="lm-modal">
		<div class="MemoPopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">결제일 / 입금일 선택</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div style="display: flex">
						<div style="flex-grow: 1">
							<div style="font-size: 0.75rem; margin-bottom: 5px;">결제수단 선택<span class="required">*</span></div>
							<select-input :items="paymentMethodList" v-model="selectedPaymentMethod">
							</select-input>
						</div>
					</div>
					<div style="display: flex">
						<div style="flex-grow: 1">
							<div style="font-size: 0.75rem">결제일 선택<span class="required">*</span></div>
							<div style="margin-top: 5px">
								<date-picker
									v-model="createDate"
									:lang="lang"
									type="date"
									valueType="format"
									placeholder="yyyy-MM-dd"
									style="width: 225px"
								/>
							</div>
						</div>
						<div style="flex-grow: 1; margin-left: 10px">
							<div style="font-size: 0.75rem">결제시간 입력<span class="required">*</span></div>
							<div class="Popup__input-field" style="margin-top: 5px">
								<input type="text" v-model="createTime" placeholder="Hour:Minute(24시 기준)으로 입력" style="width: 225px; height: 34px" />
							</div>
						</div>
					</div>
					<div style="display: flex">
						<div style="flex-grow: 1">
							<div style="font-size: 0.75rem">입금일 선택<span class="required">*</span></div>
							<div style="margin-top: 5px">
								<date-picker
									v-model="paymentDate"
									:lang="lang"
									type="date"
									valueType="format"
									placeholder="yyyy-MM-dd"
									style="width: 225px"
								/>
							</div>
						</div>
						<div style="flex-grow: 1; margin-left: 10px">
							<div style="font-size: 0.75rem">입금시간 입력<span class="required">*</span></div>
							<div class="Popup__input-field" style="margin-top: 5px">
								<input type="text" v-model="paymentTime" placeholder="Hour:Minute(24시 기준)으로 입력" style="width: 225px; height: 34px" />
							</div>
						</div>
					</div>
					<div style="display: flex">
						<div v-if="selectedPaymentMethod=='카드'" style="flex-grow: 1">
							<div style="font-size: 0.75rem; margin-bottom: 5px;">카드사 승인번호<span class="required">*</span></div>
							<div class="Popup__input-field" style="margin-top: 5px">
								<input type="text" v-model="cardPayAuthCode" placeholder="8글자" style="width: 225px; height: 34px" />
							</div>
						</div>
						<div v-else-if="selectedPaymentMethod=='전자세금계산서'" style="flex-grow: 1">
							<div style="font-size: 0.75rem; margin-bottom: 5px;">국세청 승인번호<span class="required">*</span></div>
							<div class="Popup__input-field" style="margin-top: 5px">
								<input type="text" v-model="ebillNtsNum" placeholder="24글자" style="width: 225px; height: 34px" />
							</div>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import SelectInput from '@/components/input/SelectInput'

export default {
	name: 'ModalNopaidToWait',
	components: {
		DatePicker,
		SelectInput,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		subtext: {
			type: String,
			default: '',
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			lang: {
				formatLocale: {
					// MMMM
					months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// MMM
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// dddd
					weekdays: ['일', '월', '화', '수', '목', '금', '토'],
					// ddd
					weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
					// dd
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
			createDate: '',
			createTime: '',
			paymentDate: '',
			paymentTime: '',
			ebillNtsNum: null,
			cardPayAuthCode: null,
			paymentMethodList: ["카드", "전자세금계산서"],
			selectedPaymentMethod: null,		
		}
	},
	watch: {
		isShow() {
			this.createDate = this.$DateTime.now().toFormat('yyyy-MM-dd')
			this.createTime = ''
			this.paymentDate = this.$DateTime.now().toFormat('yyyy-MM-dd')
			this.paymentTime = ''
		},
	},
	methods: {
		...mapActions('payment/list', ['createManual']),
		hide() {
			this.$emit('hide')
		},
		changePaymentMethod(item){
			this.selectedPaymentMethod = item;
		},
		async complete() {
			if (!this.validate()) return

			try {
				const params = {
					orderItemIds: this.selectedList.map(selectedItem => selectedItem.orderItemId),
					paymentStatus: "PAID",
					createDatetime: `${this.createDate} ${this.createTime}:00`,
					paymentDatetime: `${this.createDate} ${this.createTime}:00`,
				};

				if (this.selectedPaymentMethod == "카드") {
					params.paymentMethod = "CARD";
					params.cardPayAuthCode = this.cardPayAuthCode;
				} else if (this.selectedPaymentMethod == "전자세금계산서") {
					params.paymentMethod = "EBILL";
					params.ebillNtsNum = this.ebillNtsNum;
				}
				
				await this.createManual(params);

				this.$root.toast('결제완료로 변경', '결제완료로 변경되었습니다.', ToastType.SUCCESS)
				this.hide()
				this.$emit('complete')
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			if (!this.selectedPaymentMethod) {
				this.$root.toast('에러', '결제방식을 선택해주세요.', ToastType.ERROR)
				return false
			}
			if (!this.createDate) {
				this.$root.toast('에러', '결제일을 선택해주세요.', ToastType.ERROR)
				return false
			}
			if (!this.createTime) {
				this.$root.toast('에러', '결제시간을 입력해주세요.', ToastType.ERROR)
				return false
			}
			if (!this.paymentDate) {
				this.$root.toast('에러', '입금일을 선택해주세요.', ToastType.ERROR)
				return false
			}
			if (!this.paymentTime) {
				this.$root.toast('에러', '입금시간을 입력해주세요.', ToastType.ERROR)
				return false
			}
			
			if (this.selectedPaymentMethod == '카드' && !this.cardPayAuthCode) {
				this.$root.toast('에러', '카드사 승인번호를 입력해주세요.', ToastType.ERROR)
				return false
			}
			if (this.selectedPaymentMethod == '전자세금계산서' && !this.ebillNtsNum) {
				this.$root.toast('에러', '국세청 승인번호를 입력해주세요.', ToastType.ERROR)
				return false
			}
			return true
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~vue2-datepicker/scss/index.scss';
.Popup__content {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
	gap: 20px;
}
</style>
