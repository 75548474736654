import eventAPI from '@/services/api/lms/event'

export default {
	namespaced: true,
	state: {
		loadParams: {
			status: 'ALL',
			keyword: '',
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			offset: 0,
			length: 50,
		},
		list: [],
		count: 0,
		item: {},
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => state.loadParams.status,
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		setItem: (state, data) => {
			state.item = data
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await eventAPI.lookupPopupList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		getDetail: async ({ commit }, id) => {
			const response = await eventAPI.lookupPopup(id)
			commit('setItem', response.data)
		},
		createPopup: async ({ getters }, formData) => {
			const response = await eventAPI.createPopup(formData)
			return response.data
		},
		editPopup: async ({ getters }, formData) => {
			const response = await eventAPI.editPopup(formData)
			return response.data
		},
		deletePopup: async ({ commit }, ids) => {
			return await eventAPI.deletePopup({ ids: ids })
		},
	},
}
