<template>
	<b-modal
		id="modal-edit-price"
		centered
		:title="title"
		:ok-title="btnText"
		ok-variant="warning"
		cancel-title="취소"
		cancel-variant="default"
		size="xl"
		no-close-on-backdrop
		@ok="submit"
	>
		<apply-discount-box
			v-model="totalDiscount"
			:options="discountOptions"
			:error="error"
			:errorMessage="errorMessage"
			@input="changeAllDiscount"
			@select="changeOption"
		/>
		<div class="table-wrapper">
			<div class="table-wrap">
				<table>
					<table-header :headers="headers" />
					<tbody>
						<tr v-for="(product, index) in productList" :key="`product-row${index}`">
							<td>
								<div class="info">
									<b>{{ product.name }}</b>
									<p v-if="product.subname">{{ product.subname }}</p>
								</div>
							</td>
							<td>
								<input-number-with-unit v-model.number="product.price" unit="원" readonly />
							</td>
							<td>
								<input-number-with-unit
									v-model.number="product.quantity"
									:index="index"
									:error="product.error"
									@input="changeCount"
								/>
							</td>
							<td>
								<input-number-with-unit
									v-model.number="product.discountPercent"
									:index="index"
									@input="changeDiscountPrice"
									unit="%"
								/>
							</td>
							<td>
								<input-number-with-unit
									v-model.number="product.discountPrice"
									:index="index"
									@input="changeDiscountPercent"
									unit="원"
								/>
							</td>
							<td>
								<input-number-with-unit v-model.number="product.sellingPrice" unit="원" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="total-price">
				<b>합계</b>
				<div>
					<b>{{ commaNum(totalPrice) }}</b>
					<span>원</span>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import ApplyDiscountBox from '@/views/estimate/estimate/components/ApplyDiscountBox'
import TableHeader from '@/components/table/TableHeader'
import InputNumberWithUnit from '@/views/common/components/formItem/InputNumberWithUnit'
import mixin from '@/mixins'

export default {
	name: 'ModalEditPrice',
	props: {
		title: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '확인',
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
	components: {
		ApplyDiscountBox,
		TableHeader,
		InputNumberWithUnit,
	},
	mixins: [mixin],
	data() {
		return {
			headers: [
				{ name: '상품 정보', key: 'info', width: '25%' },
				{ name: '소비자가(단가)', key: 'price', width: '15%' },
				{ name: '수량', key: 'quantity', width: '15%' },
				{ name: '할인율', key: 'discountPercent', width: '15%' },
				{ name: '할인금액', key: 'discountPrice', width: '15%' },
				{ name: '판매가', key: 'sellingPrice', width: '15%' },
			],
			productList: [],
			totalDiscount: 0,
			discountOptions: [
				{ name: '할인율 (%)', value: 'percent' },
				{ name: '할인금액 (원)', value: 'price' },
			],
			selectOption: {},
			error: false,
			errorMessage: '',
		}
	},
	computed: {
		totalPrice() {
			let result = 0

			this.productList.forEach(item => {
				result = result + item.sellingPrice
			})
			return result
		},
	},
	watch: {
		selectOption: {
			deep: true,
			handler(newVal, oldVal) {
				if (oldVal.value && newVal != oldVal) {
					this.changeAllDiscount(this.totalDiscount)
				}
			},
		},
		list(val) {
			this.productList = this.$_.cloneDeep(val)
		},
	},
	mounted() {
		this.init()
		this.initError()
	},
	methods: {
		init() {
			this.totalDiscount = 0
		},
		initError() {
			this.error = false
			this.errorMessage = ''
		},
		//일괄할인 적용
		changeAllDiscount(val) {
			this.initError()

			if (this.selectOption.value == 'percent' && val > 100) {
				this.error = true
				this.errorMessage = '할인율은 100보다 클 수 없습니다'
				return false
			}

			this.productList.forEach((item, index) => {
				if (this.selectOption.value == 'percent') {
					item.discountPercent = val
					this.changeDiscountPrice(val, index)
				} else if (this.selectOption.value == 'price') {
					item.discountPrice = val
					this.changeDiscountPercent(val, index)
				}
			})
		},
		//할인율 -> 할인금액 변경
		changeDiscountPrice(discountPercent, index) {
			this.productList[index].discountPrice = this.productList[index].quantity * ((this.productList[index].price / 100) * discountPercent)
			this.productList[index].sellingPrice = this.productList[index].quantity * this.productList[index].price - this.productList[index].discountPrice
		},
		//할인금액 -> 할인율 변경
		changeDiscountPercent(discountPrice, index) {
			this.productList[index].discountPercent = Math.round((discountPrice / this.productList[index].price) * 100)
			this.productList[index].sellingPrice = this.productList[index].quantity * this.productList[index].price - this.productList[index].discountPrice
		},
		//수량 -> 판매가 변경
		changeCount(val, index) {
			this.initError()
			this.productList[index].error = false

			if (val == 0) {
				this.error = true
				this.productList[index].error = true
				this.errorMessage = '수량은 0으로 설정할 수 없습니다'
			}

			if (this.selectOption.value == 'percent') {
				this.productList[index].discountPrice = this.productList[index].quantity * ((this.productList[index].price / 100) * this.productList[index].discountPercent)
			}
			this.productList[index].sellingPrice = val * this.productList[index].price - this.productList[index].discountPrice
		},
		changeOption(option) {
			console.log(option)
			this.selectOption = option
		},
		submit(bvModalEvent) {
			if (this.error) {
				this.$root.toast('적용 불가', this.errorMessage, 'error')
				bvModalEvent.preventDefault()
				return
			}
			this.$emit('ok', this.productList)
		},
	},
}
</script>

<style lang="scss">
#modal-edit-price {
	.modal-body {
		padding-top: 0;
	}
	.table-wrapper {
		width: 100%;
		border-radius: 5px;
		border: 1px solid $LINE_DIVIDER;
		overflow: hidden;
		margin-top: 18px;
		.table-wrap {
			max-height: 480px;
			overflow-y: auto;
		}
		table {
			width: 100%;
		}
		.table-header {
			position: sticky;
			top: 0;
			left: 0;
			background-color: white;
			z-index: 1;
			th {
				border-bottom: 1px solid $LINE_DIVIDER;
				font-size: 0.875rem;
				font-weight: 700;
				text-align: center;
				color: $COLOR_666;
				height: 38px;
				padding: 0 8px;
				&:first-child {
					text-align: left;
					padding-left: 1rem;
				}
			}
		}
		tbody {
			tr {
				border-bottom: 1px solid $LINE_DIVIDER;
				&:last-child {
					border-bottom: 0;
				}
				td {
					height: 82px;
					padding: 0 8px;
					.info {
						padding-left: 8px;
						b {
							font-size: 0.875rem;
							font-weight: 700;
						}
						p {
							font-size: 0.75rem;
							font-weight: 400;
							margin-top: 2px;
						}
					}
				}
			}
		}
		.total-price {
			border-top: 1px solid $LINE_DIVIDER;
			display: flex;
			align-items: center;
			width: 100%;
			height: 70px;
			padding: 0 1rem;
			b {
				font-size: 0.875rem;
				font-weight: 700;
			}
			> div {
				margin-left: auto;
				display: inline-flex;
				align-items: center;
				span {
					font-size: 0.875rem;
					font-weight: 400;
					color: $COLOR_999;
					display: inline-block;
					margin-left: 20px;
				}
			}
		}
	}
	.modal-footer {
		margin-bottom: 20px;
		button {
			width: 100px;
		}
	}
}
</style>
