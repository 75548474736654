<template>
	<div class="buyer-list">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<buyer-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination" :defaultOffset="50" />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import BuyerTable from '../components/Table.vue'

export default {
	name: 'BuyerList',
	components: {
		ListHeader,
		BuyerTable,
		Pagination,
	},
	computed: {
		...mapState('buyer/list', ['list', 'count']),
	},
	created() {
		this.initLoadParams()
	},
	mounted() {
		this.getList()
	},
	methods: {
		...mapMutations('buyer/list', ['initLoadParams']),
		...mapActions('buyer/list', ['getList', 'setLoadParams']),
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>

<style lang="scss" scoped>
.buyer-list {
	.table-wrapper {
		height: calc(100% - 161px);
	}
}
</style>
