<template>
	<input-option-table
		ref="optionTable"
		:title="title"
		:columns="columns"
		:totalCountObj="totalCountObj"
		@changeTableData="changeTableData"
	></input-option-table>
</template>

<script>
import InputOptionTable from '@/views/common/components/formItem/InputOptionTable.vue'

export default {
	name: 'SellInfoOptionTable',
	components: {
		InputOptionTable,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			baseColumns: [
				{
					type: 'text',
					key: 'sku',
					value: '옵션(SKU)',
					width: '250',
					required: true,
				},
				{
					type: 'number',
					key: 'stock',
					value: '재고 수량',
					width: '150',
				},
				{
					type: 'currency',
					key: 'salesUnitPrice',
					value: '매입단가',
					width: '200',
					required: true,
				},
				{
					type: 'currency',
					key: 'unitPrice',
					value: '소비자단가',
					width: '200',
					required: true,
				},
				{
					type: 'deleteButton',
					key: 'management',
					value: '삭제',
					width: '85',
				},
			],
			totalCountObj: {
				key: 'stock',
				title: '총 재고 합계',
			},
			columns: [],
			columnInsertIndex: 1,
		}
	},
	methods: {
		setColumns(list) {
			this.columns = this.$_.cloneDeep(this.baseColumns)
			list.forEach(({ value, disabled }) => {
				this.columns.splice(this.columnInsertIndex, 0, {
					type: 'text',
					key: value,
					value: value,
					width: '150',
					required: disabled,
				})
			})
		},
		getTableData() {
			return this.$refs.optionTable.getTableData()
		},
		changeTableData(tableData) {
			this.$emit('changeTableData', tableData)
		},
		setTableData(tableData) {
			this.$refs['optionTable'].setTableData(tableData)
		},
		valid() {
			return this.$refs['optionTable'].valid()
		},
	},
}
</script>

<style lang="scss" scoped></style>
