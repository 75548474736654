<template>
	<b-overlay :show="isUpdating" spinner-variant="yellow" rounded="sm">
		<div class="create-wrapper">
			<div class="create-header">
				<h2>물품 등록</h2>
			</div>
			<div class="create-content" v-if="item && item.id == id">
				<info-list title="요청 정보" :list="contentInfo" style="margin-bottom: 20px" />

				<div>
					<h5>응답 정보</h5>
					<input-select-option
						ref="input-select"
						v-model="inputData.status"
						title="상태 변경"
						placeholder="상태 변경 선택"
						:list="statusList"
						isRequired
					/>
					<input-select-option
						v-if="inputData.status == 'REJECT'"
						ref="input-select"
						v-model="inputData.comment"
						title="거절 사유"
						placeholder="거절 사유 선택"
						:list="commentList"
					/>
				</div>
				<div v-if="inputData.status == 'COMPLETE'">
					<h5>물품 정보 (Sigma-Aldrich 상품이 존재하지 않는 경우 반드시 슬랙으로 Data 전송 해주세요)</h5>
					<input-radio title="유형 선택" name="addType" @changeValue="data => (type = data.id)" :list="addType" :tab="type" />
					<input-text
						:disabled="type == 'new'"
						ref="itemId"
						isReadOnly
						v-model="inputData.itemId"
						title="물품 ID"
						placeholder="물품 ID 입력"
						inValidText="ID를 입력해주세요"
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchProduct'].show(keyword, 'detail')"
					/>
					<input-text
						ref="productName"
						v-model="inputData.productName"
						title="물품 이름"
						placeholder="물품 이름 입력"
						inValidText="이름을 입력해주세요"
						isRequired
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchProduct'].show(keyword, 'name')"
					/>
					<input-radio
						title="제품 번호 유형 선택"
						name="codeType"
						@changeValue="data => (inputData.codeTypeItem = data.id)"
						:list="codeType"
						:tab="inputData.codeTypeItem"
					/>
					<input-text
						:disabled="inputData.codeTypeItem == 'generate'"
						ref="productCode"
						v-model="inputData.productCode"
						title="제품 번호"
						placeholder="제품 번호 입력"
						inValidText="제품 번호를 입력해주세요"
						isRequired
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchProduct'].show(keyword, 'catalog')"
					/>
					<input-text
						ref="productCasno"
						v-model="inputData.productCasno"
						title="CAS No"
						placeholder="CAS No 입력"
						inValidText="CAS No 입력해주세요"
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchProduct'].show(keyword, 'casno')"
					/>
					<input-text
						ref="productMaker"
						isReadOnly
						v-model="inputData.productMakerName"
						title="제조사"
						:isValid="inputData.productMakerId != null"
						placeholder="제조사 검색"
						inValidText="제조사를 검색 후 선택해주세요"
						isRequired
						isSearch
						@onClickSearch="keyword => $refs['ModalSearchMaker'].show(keyword, inputData.productMakerId)"
					/>
					<input-text
						ref="productVolume"
						v-model="inputData.productVolume"
						title="용량"
						isRequired
						placeholder="용량 입력"
						inValidText="용량을 입력해주세요"
					/>
					<input-msds
						v-if="item.file == null"
						ref="productMsds"
						title="MSDS Link"
						placeholder="MSDS Link 입력"
						inValidText="MSDS Link를 입력해주세요"
					/>
				</div>
			</div>
			<div class="create-footer">
				<base-button size="lg" color="primary" @click="onAdd">등록</base-button>
				<!-- <base-button :disabled="editDisabled" size="lg" color="accept" @click="onEdit">수정</base-button> -->
				<base-button size="lg" color="accept" @click="onSendSlack">슬랙으로 Data전송</base-button>
			</div>
		</div>
		<ModalSearchProduct ref="ModalSearchProduct" @confirm="selectProductItem" />
		<ModalSearchMaker
			ref="ModalSearchMaker"
			@confirm="maker => ((inputData.productMakerId = maker.makerId), (inputData.productMakerName = maker.makerName))"
		/>
	</b-overlay>
</template>
<script>
import { ToastType } from '@/utils/define/ToastType'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputMsds from '@/views/common/components/formItem/InputMsds.vue'
import InfoList from '@/views/common/components/InfoList.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import { mapActions, mapState } from 'vuex'
import ModalSearchProduct from '../modal/ModalSearchProduct.vue'
import ModalSearchMaker from '../modal/ModalSearchMaker.vue'
export default {
	components: {
		BaseButton,
		InfoList,
		InputText,
		InputSelectOption,
		ModalSearchProduct,
		ModalSearchMaker,
		InputRadio,
		InputMsds,
	},
	props: {
		id: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			isUpdating: false,
			type: 'old',
			inputData: {
				itemId: null,
				productName: null,
				productCode: null,
				productCasno: null,
				productVolume: null,
				productMsds: null,
				productMakerId: null,
				productMakerName: null,
				codeTypeItem: 'except',
			},
		}
	},
	computed: {
		...mapState('chemicalApi/request', ['item']),
		statusList() {
			return [
				{ value: 'WAIT', name: '등록대기' },
				{ value: 'COMPLETE', name: '등록완료' },
				{ value: 'REJECT', name: '등록거절' },
			]
		},
		commentList() {
			return [
				{ value: 'A', name: 'A: [중복요청] 조회가능한 제품을 등록 요청' },
				{ value: 'B', name: 'B: [정보불일치] 신규 화학제품 용량 부정확' },
				{ value: 'C', name: 'C: [정보불일치] 신규 화학제품 정보 부정확(카탈로그번호, 제조사, 제품명)' },
				{ value: 'Z', name: 'Z: [확인필요] 그 외 사유' },
			]
		},
		contentInfo() {
			return [
				{ title: '물품 이름', row: true, value: this.item.productName },
				{ title: '제조사', row: true, value: this.item.productMaker },
				{ title: '제품 번호', row: true, value: this.item.productCode },
				{ title: 'CAS No.', row: true, value: this.item.productCasno },
				{ title: '용량', row: true, value: this.item.productVolume },
				{
					title: '요청 일시',
					row: true,
					value: this.$DateTime.fromISO(this.item.create_datetime, { setZone: true }).toFormat('yyyy.MM.dd T'),
				},
				{ title: 'MSDS', type: 'link', value: this.item.file, clickFn: this.msdsPopup, row: true },
			]
		},
		addType() {
			return [
				{
					id: 'old',
					title: '기존',
				},
				{
					id: 'new',
					title: '신규',
				},
			]
		},
		codeType() {
			return [
				{
					id: 'generate',
					title: '자동생성',
				},
				{
					id: 'except',
					title: '존재',
				},
			]
		},
		inititalData() {
			return {
				itemId: null,
				productName: null,
				productCode: null,
				productCasno: null,
				productVolume: null,
				productMsds: null,
				productMakerId: null,
				productMakerName: null,
				status: null,
				comment: null,
				codeTypeItem: 'except',
			}
		},
	},
	async created() {
		this.type = 'old'
		this.inputData = { ...this.inititalData }
		if (this.item?.id != this.id) {
			await this.getDetail(this.id)
		}
		this.inputData.id = this.id
		this.inputData.productName = this.item.productName
		this.inputData.productCode = this.item.productCode
		this.inputData.productCasno = this.item.productCasno?.replaceAll(' ', '')
		this.inputData.productMakerName = this.item.productMaker
		this.inputData.productVolume = this.item.productVolume?.replaceAll(' ', '')
		this.inputData.productMsds = this.item.file
		if (this.item.productCode) this.codeTypeItem = 'except'
		else this.codeTypeItem = 'generate'
	},
	methods: {
		...mapActions('chemicalApi/request', ['getDetail', 'sendSlack', 'matchingRequest']),
		async onSendSlack() {
			this.isUpdating = true
			try {
				Object.values(this.$refs).forEach(ref => {
					if (ref.isRequired && !ref.value) {
						throw '필수 정보를 모두 입력해주세요'
					}
				})
				const response = await this.sendSlack(this.inputData)
				this.$root.toast('슬랙 채널', '슬랙 채널에 전송하였습니다.', ToastType.SUCCESS)
				this.$router.go(-1)
			} catch (e) {
				console.log(e)
				this.$root.toast('슬랙 채널', e ?? '슬랙 채널에 전송에 실패하였습니다.', ToastType.ERROR)
				// alert(JSON.stringify(this.inputData))
			}
			this.isUpdating = false
		},
		async onAdd() {
			this.isUpdating = true
			try {
				Object.values(this.$refs).forEach(ref => {
					if (ref.isRequired && !ref.value) {
						throw '필수 정보를 모두 입력해주세요'
					}
				})
				if (this.inputData.status == 'COMPLETE') {
					if (this.type == 'new') {
						this.inputData.itemId = null
					} else if (this.type == 'old' && !this.inputData.itemId) {
						throw '기존 유형은 물품 ID가 필수 입니다'
					}
					if (!this.inputData.productMakerId) {
						throw '제조사를 검색 후 선택해주세요'
					}
				}
				const response = await this.matchingRequest(this.inputData)
				if (response.code == 0) {
					this.$root.toast('등록 성공', '요청 물품 등록에 성공하였습니다.', 'success')
					this.$router.go(-1)
				} else {
					throw response.msg.message
				}
			} catch (e) {
				console.log(e)
				this.$root.toast('등록 오류', e ?? '슬랙 채널에 전송에 실패하였습니다.', ToastType.ERROR)
				// alert(JSON.stringify(this.inputData))
			}

			this.isUpdating = false
		},
		selectProductItem(id, item) {
			this.inputData.itemId = id
			if (item) {
				this.inputData.productName = item.name
				this.inputData.productCode = item.catalogNo
				this.inputData.productCasno = item.casNo
				this.inputData.productMakerName = item.maker?.name
				this.inputData.productMakerId = item.maker?.id
				this.inputData.productVolume = `${item.volume?.value}${item.volume?.unit}`
				this.inputData.productMsds = item.msdsUrl
			}
		},
		msdsPopup() {
			window.open(
				'https://msds.lab-manager.com/v/u?url=' + encodeURIComponent(this.item.file),
				'_blank',
				'width=800, height=1000, left=300, top=50'
			)
			return false
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
