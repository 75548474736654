<template>
	<div class="input-text-wrap">
		<base-item :title="title" :subContentText="subContentText" :required="isRequired">
			<div class="input-wrap">
				<input
					:type="type"
					:value="inputValue"
					:placeholder="placeholder"
					:disabled="isReadOnly"
					:class="{ error: !isValid }"
					:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
					@input="changeValue"
				/>
				<div v-if="!isListItemOne" class="unit-select" @click="toggleSelect">
					<div class="select-box">
						<p>
							{{ selectItem.name }}
						</p>
					</div>
					<div class="image-box">
						<img src="@/assets/svg/app/select-triangle.svg" alt="" />
					</div>
					<ul v-show="isShowSelect">
						<li v-for="(unit, index) in unitList" :key="`unit_${index}`" :value="unit.value" @click="changeOption(index)">
							{{ unit.name }}
						</li>
					</ul>
				</div>
				<div class="single-unit" v-else>{{ unitList[0].name }}</div>
			</div>
			<div v-if="!isValid" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidText }}</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputSelect',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		inputValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		unitList: {
			type: Array,
			default: () => [],
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isShowSelect: false,
			selectItem: {},
		}
	},
	computed: {
		isListItemOne() {
			return this.unitList.length === 1
		},
	},
	created() {
		if (this.unitList.length) this.changeOption(0)
	},
	methods: {
		toggleSelect() {
			this.isShowSelect = !this.isShowSelect
		},
		changeOption(index) {
			this.selectItem = this.unitList[index]
			this.$emit('changeOption', this.selectItem.value)
		},
		changeValue(event) {
			event.target.value = event.target.value.trimLeft()
			this.$emit('changeValue', event.target.value.trim())
		},
	},
}
</script>

<style lang="scss" scoped>
.input-text-wrap {
	.input-wrap {
		height: 100%;
		input[type='number'],
		input[type='text'],
		input[type='password'] {
			padding: 12px;
			border: 1px solid $FILE_BORDER;
			border-radius: 5px;

			&:disabled {
				background-color: $LIST_HOVER;
			}
			&.error {
				border-color: $PRIMARY_RED;
			}
		}

		.unit-select {
			position: relative;
			right: 120px;
			width: 120px;
			display: inline-flex;
			justify-content: flex-end;
			align-items: center;
			cursor: pointer;
			padding-right: 10px;

			.select-box {
				display: flex;
				align-items: center;
				p {
					font-weight: 700;
				}
			}
			.image-box {
				padding-left: 10px;
				display: flex;
				align-items: center;
			}
			ul {
				z-index: 1;
				width: 120px;
				max-height: 200px;
				position: absolute;
				top: 30px;
				right: 0;
				background-color: $PRIMARY_WHITE;
				border: 1px solid $INPUT_BORDER;
				border-radius: 4px;
				overflow-y: auto;
				li {
					height: 35px;
					display: flex;
					align-items: center;
					padding: 0 15px;
					transition: all 0.3s;
					&:hover {
						background-color: $HOVER_BACKGROUND;
					}
				}
			}
		}
		.single-unit {
			position: relative;
			right: 120px;
			width: 120px;
			display: inline-flex;
			justify-content: flex-end;
			align-items: center;
			padding-right: 10px;
			font-weight: 700;
		}
	}

	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
}
</style>
