const PageName = {
	// 주문 관리
	Order: {
		OrderProductManagement: 'OrderProductManagement', // 전체 주문관리
		OrderProductManagementDetail: 'OrderProductManagementDetail', // 주문관리 상세
		OrderItemList: 'OrderItemList', // 주문 건별 목록
		OrderItemDetail: 'OrderItemDetail', // 주문 아이템 상세
		TransactionStatement: 'TransactionStatement', // 거래명세서
	},
	// 상품 관리
	Product: {
		ProductList: 'ProductList',
		ProductListDetail: 'ProductListDetail',
		ProductRegistration: {
			Main: 'ProductRegistration',
			ProductInfo: 'ProductInfo',
			SellInfo: 'SellInfo',
			ShippingInfo: 'ShippingInfo',
			ReturnInfo: 'ReturnInfo',
			ConsultingInfo: 'ConsultingInfo',
		},
		ApprovalProductList: 'ApprovalProductList',
		ApprovalProductDetail: 'ApprovalProductDetail',
		UpdateProductPriceList: 'UpdateProductPriceList',
	},
	// 판매자 관리
	Seller: {
		SellerList: 'SellerList',
		SellerDetail: 'SellerDetail',
		SettlementManagement: 'SettlementManagement',
		SettlementDetail: 'SettlementDetail',
		// SellerInfo: 'SellerInfo', // 판매자 정보
		// SellerInfoEdit: 'SellerInfoEdit', // 판매자 정보 수정
		// AddressManagement: 'AddressManagement', // 주소록 관리
		// CustomerConsultingTemplateManagement: 'CustomerConsultingTemplateManagement', // 고객상담 템플릿 관리
		SellerLogin: 'SellerLogin', // 판매자 로그인
		SellerEntryRegistration: 'SellerEntryRegistration', // 판매자 입점 신청
	},
	//견적 관리
	Estimate: {
		RequestProductList: 'RequestProductList', // 상품 요청 목록
		RequestProductDetail: 'RequestProductDetail', //상품 요청 상세
		EstimateList: 'EstimateList', //견적함
		EstimateDetail: 'EstimateDetail', //견적함 상테
		EstimateForm: 'EstimateForm', //견적함 작성 / 복제
		EstimateExport: 'EstimateExport',
	},
	//LMS 관리
	Lms: {
		Banner: 'Banner', //마케팅 배너 관리
		BannerCreate: 'BannerCreate', //마케팅 배너 관리
		BannerDetail: 'BannerDetail', //마케팅 배너 관리
		BannerUpdate: 'BannerUpdate', //마케팅 배너 관리
		Event: 'Event', // 팝업 이벤트 관리
		EventCreate: 'EventCreate', //마케팅 배너 관리
		EventDetail: 'EventDetail', // 팝업 이벤트 관리
		EventEdit: 'EventEdit', // 팝업 이벤트 수정
		Notice: 'Notice', // 공지사항 관리
		NoticeCreate: 'NoticeCreate', // 공지사항 관리
		NoticeEdit: 'NoticeEdit', // 공지사항 관리
		FAQ: 'FAQ', // 자주묻는질문 관리
		FAQCreate: 'FAQCreate', // 자주묻는질문 관리
		Brand: 'Brand', //브랜드 관리
		BrandDetail: 'BrandDetail', //브랜드 관리
		BrandCreate: 'BrandCreate', //브랜드 관리
		BrandEdit: 'BrandEdit', //브랜드 관리
	},
	CS: {
		CancelRefund: 'CancelRefund',
		CancelRefundDetail: 'CancelRefundDetail',
		Inquiry: 'Inquiry',
		InquiryDetail: 'InquiryDetail',
	},
	//구매자 관리
	Buyer: {
		BuyerList: 'BuyerList',
		BuyerDetail: 'BuyerDetail',
		LabList: 'LabList',
		LabDetail: 'LabDetail',
		PaymentManagement: 'PaymentManagement',
		BusinessRegistrationManagement: 'BusinessRegistrationManagement', // 사업자등록증(결제처) 관리
		BusinessRegistrationDetail: 'BusinessRegistrationDetail', // 사업자등록증 관리 상세
	},
	//LMP 관리
	Lmp: {
		LmpEvent: 'LmpEvent', // 팝업 이벤트 관리
		LmpEventCreate: 'LmpEventCreate', // 팝업 이벤트 생성
		LmpEventDetail: 'LmpEventDetail', // 팝업 이벤트 관리
		LmpEventEdit: 'LmpEventEdit', // 팝업 이벤트 수정
	},

	Institute: {
		LicenseCreate: 'LicenseCreate',
	},
	ChemicalAPI: {
		Request: 'ChemicalAPIRequest',
		RequestDetail: 'ChemicalAPIRequestDetail',
		ProductCreate: 'ChemicalAPIRequestProductCreate',
	},
}

export default PageName
