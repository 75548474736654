<template>
	<tr class="table-header">
		<th>
			<span>id</span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>요청일시</span>
		</th>
		<th>
			<span>파일명</span>
		</th>
		<th>
			<span>갱신일시</span>
		</th>
		<th>
			<span>전체 상품 수</span>
		</th>
		<th>
			<span>처리된 상품 수</span>
		</th>
		<th>
			<span class="lg">에러메세지</span>
		</th>
	</tr>
</template>

<script>
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
