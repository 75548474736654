import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	//구매자 목록
	{
		path: RoutePath.Buyer.BuyerList,
		name: PageName.Buyer.BuyerList,
		props: true,
		component: () => import('@/views/buyer/buyer/pages/BuyerList.vue'),
	},
	{
		path: RoutePath.Buyer.BuyerDetail,
		name: PageName.Buyer.BuyerDetail,
		props: true,
		component: () => import('@/views/buyer/buyer/pages/BuyerDetail.vue'),
	},
	//연구실 목록
	{
		path: RoutePath.Buyer.LabList,
		name: PageName.Buyer.LabList,
		props: true,
		component: () => import('@/views/buyer/lab/pages/LabList.vue'),
	},
	{
		path: RoutePath.Buyer.LabDetail,
		name: PageName.Buyer.LabDetail,
		props: true,
		component: () => import('@/views/buyer/lab/pages/LabDetail.vue'),
	},
	//결제 관리
	{
		path: RoutePath.Buyer.PaymentManagement,
		name: PageName.Buyer.PaymentManagement,
		props: true,
		component: () => import('@/views/buyer/payment/pages/PaymentManagement.vue')
	},
	//사업자등록증 관리
	{
		path: RoutePath.Buyer.BusinessRegistrationManagement,
		name: PageName.Buyer.BusinessRegistrationManagement,
		props: true,
		component: () => import('@/views/buyer/businessregistration/pages/BusinessRegistrationManagement.vue'),
	},
	{
		path: RoutePath.Buyer.BusinessRegistrationDetail,
		name: PageName.Buyer.BusinessRegistrationDetail,
		props: true,
		component: () => import('@/views/buyer/businessregistration/pages/BusinessRegistrationDetail.vue'),
	},
]
