<template>
	<div class="detail-table">
		<div class="title" v-if="title">
			<h6>{{ title }}</h6>
		</div>
		<div class="table-wrap" ref="table" @scroll="throttleScroll">
			<table>
				<table-header v-if="headers" :headers="headers" :allChecked="allChecked" :isCheck="isCheck" @toggle="$emit('toggle')" />
				<slot></slot>
			</table>
		</div>
	</div>
</template>

<script>
import TableHeader from '@/components/table/TableHeader'
import { mapState, mapMutations } from 'vuex'

export default {
	name: 'DetailTable',
	components: {
		TableHeader,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		headers: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
		isCheck: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			throttleScroll: () => {},
		}
	},
	computed: {
		...mapState('payment/list', ['count', 'scrollTop']),
	},
	watch: {
		count: {
			handler() {
				if (this.scrollTop > 0) this.$nextTick(() => (this.$refs['table'].scrollTop = this.scrollTop))
			},
			immediate: true,
		},
	},
	mounted() {
		this.throttleScroll = this.$_.throttle(this.scroll, 200)
	},
	destroyed() {
		if (this.$route.name !== 'OrderProductManagementDetail' && this.$route.name !== 'OrderItemDetail') this.initScrollTop()
	},
	methods: {
		...mapMutations('payment/list', ['setScrollTop', 'initScrollTop']),
		scroll(e) {
			this.setScrollTop(this.$refs['table'].scrollTop)
		},
	},
}
</script>

<style lang="scss">
.detail-table {
	width: 100%;
	.title {
		display: flex;
		align-items: center;
		h6 {
			font-size: 1.25rem;
			font-weight: 700;
			margin-bottom: 1rem;
		}
	}
	.table-wrap {
		width: 100%;
		max-height: 400px;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		overflow: auto;
		table {
			min-width: 100%;
			border-collapse: collapse;
			thead {
				tr {
					z-index: 1;
					position: sticky;
					top: 0;
					border-bottom: 1px solid $LINE_DIVIDER;
					background-color: white;
					th {
						white-space: nowrap;
						min-width: 120px;
						height: 40px;
						font-size: 0.875rem;
						color: $COLOR_666;
						padding: 0 8px;
						text-align: left;
						&:first-child {
							text-align: left;
							padding-left: 1rem;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					height: 60px;
					td {
						font-size: 0.875rem;
						text-align: left;
						padding: 0 8px;
						white-space: nowrap;
						&:first-child {
							padding-left: 1rem;
						}
						.info {
							p {
								font-size: 0.75rem;
								white-space: nowrap;
							}
						}
						&.link {
							cursor: pointer;
							text-decoration: underline;
						}
						&.complete {
							color: $PRIMARY_GREEN;
						}
						&.fail {
							color: $PRIMARY_RED;
						}
					}
					&.disabled {
						td {
							color: #999999;
							button {
								color: #999999 !important;
								background-color: #f9f9f9 !important;
							}
							b {
								color: #999999;
							}
						}
					}
				}
			}
		}
	}
}
</style>
