<template>
	<div class="wrapper">
		<div class="table-wrapper">
			<table>
				<colgroup>
					<col v-for="(column, index) of columns" :key="`col${index}`" :width="column.width ?? 'auto'" />
				</colgroup>
				<thead>
					<tr>
						<th v-for="(column, index) of columns" :key="`th${index}`">
							<span>
								{{ column.value }}
								<em v-if="column.required">*</em>
							</span>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(data, rowIndex) of tableData" :key="`tr${rowIndex}`">
						<td v-for="([key, value], colIndex) of Object.entries(data)" :key="`tr${rowIndex}${key}`" style="position: relative">
							<template v-if="getType(key) === TYPE.NUMBER">
								<input type="number" :value="value" :placeholder="getAuto(key) ? 'Auto' : null" />
								<img
									:src="require(`@/assets/svg/sellInfo/${getAuto(key) ? 'input-minus-disabled' : 'input-minus'}.svg`)"
									class="number-input-minus"
									@click="onClickMinusBtn(tableData[rowIndex], colIndex)"
								/>
								<img
									:src="require(`@/assets/svg/sellInfo/${getAuto(key) ? 'input-plus-disabled' : 'input-plus'}.svg`)"
									class="number-input-plus"
									@click="onClickPlusBtn(tableData[rowIndex], colIndex)"
								/>
							</template>
							<template v-else-if="getType(key) === TYPE.CURRENCY">
								<input type="text" :value="value" @keyup="onKeyupCurrency($event, tableData[rowIndex], colIndex)" maxlength="15" />
								<span class="currency-type">원</span>
							</template>
							<template v-else-if="getType(key) === TYPE.DELETE_BUTTON">
								<img src="@/assets/svg/icon-trash.svg" @click="onClickDeleteBtn(rowIndex)" style="cursor: pointer" />
							</template>
							<template v-else>
								<input
									type="text"
									:placeholder="getPlaceholder(key, colIndex)"
									:value="value"
									@keyup="onKeyupText($event, tableData[rowIndex], colIndex)"
								/>
							</template>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="add-option-container" v-if="isShowAddButton">
			<span @click="onClickAddBtn">{{ addButtonTitle }}</span>
		</div>
		<div v-if="totalCountObj" class="count-container">{{ totalCountObj.title }}: {{ getAuto(totalCountObj.key) ? 'Auto' : totalCount }}</div>

		<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="onDeleteItem"></modal-common>
	</div>
</template>

<script>
import ModalCommon from '@/views/common/modals/ModalCommon.vue'

const TYPE = {
	TEXT: 'text',
	NUMBER: 'number',
	CALENDAR: 'calendar',
	SELECT: 'select',
	CURRENCY: 'currency',
	DELETE_BUTTON: 'deleteButton',
}

export default {
	name: 'OptionTable',
	components: {
		ModalCommon,
	},
	props: {
		columns: {
			type: Array,
			default: () => [],
		},
		totalCountObj: {
			type: Object,
			default: () => {},
		},
		addButtonTitle: {
			type: String,
			default: '+ 옵션 추가하기',
		},
		isShowAddButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			TYPE,
			tableData: [],
			typeByKey: {},
			autoByKey: {},
			requiredKeyList: [],
			defaultItem: {},
			totalCount: 0,
			deleteItemIndex: 0,
			productTableData: [],
		}
	},
	watch: {
		columns: {
			handler() {
				this.tableData = []
				this.requiredKeyList = []
				this.defaultItem = {}
				this.typeByKey = {}
				this.autoByKey = {}
				this.columns.forEach(column => {
					if (column.required === true) this.requiredKeyList.push(column.key)
					this.typeByKey[column.key] = column.type
					this.defaultItem[column.key] = column.type === TYPE.NUMBER ? 0 : ''
					if (column.auto) {
						this.autoByKey[column.key] = true
						this.defaultItem[column.key] = ''
					}
				})

				this.onClickAddBtn()
			},
			immediate: true,
		},
		tableData: {
			handler() {
				this.$emit(
					'changeTableData',
					this.tableData.map((m, i) => {
						let value = { ...m }
						if (this.productTableData[i]?.productId) value.productId = this.productTableData[i].productId
						return value
					})
				)
				if (!this.totalCountObj) return

				this.totalCount = this.tableData.reduce((acc, cur) => {
					Object.entries(cur).forEach(([key, value]) => {
						if (key === this.totalCountObj.key) acc += value
					})
					return acc
				}, 0)
			},
			deep: true,
		},
	},
	computed: {
		isDisableDeleteButton() {
			return this.tableData.length === 1
		},
	},
	methods: {
		getTableData() {
			return this.tableData
		},
		getType(key) {
			return this.typeByKey[key]
		},
		getAuto(key) {
			return this.autoByKey[key]
		},
		getKey(data, index) {
			return Object.entries(data)[index][0]
		},
		getPlaceholder(key, index) {
			let suffix = ''
			switch (this.getType(key)) {
				case TYPE.TEXT:
					suffix = '입력'
					break
				case TYPE.SELECT:
					suffix = '선택'
					break
			}
			return `${this.columns[index]?.value} ${suffix}`
		},
		onClickPlusBtn(data, index) {
			const key = this.getKey(data, index)
			if (!this.getAuto(key)) {
				data[key]++
				this.$forceUpdate()
			}
		},
		onClickMinusBtn(data, index) {
			const key = this.getKey(data, index)
			if (!this.getAuto(key) && data[key] > 0) {
				data[key]--
				this.$forceUpdate()
			}
		},
		onKeyupCurrency(e, data, index) {
			const key = this.getKey(data, index)
			if (!e.target.value) {
				return (data[key] = '')
			} else if (isNaN(parseInt(e.target.value))) {
				return (data[key] = 0)
			}
			data[key] = this.convertNumberToCurrency(e.target.value)
			this.$forceUpdate()
		},
		onKeyupText(e, data, index) {
			const key = this.getKey(data, index)
			data[key] = e.target.value
		},
		onClickAddBtn() {
			this.tableData.push(this.$_.cloneDeep(this.defaultItem))
		},
		onClickDeleteBtn(index) {
			if (this.isDisableDeleteButton) return

			this.deleteItemIndex = index

			if (this.$_.isEqual(this.tableData[index], this.defaultItem)) {
				this.tableData.splice(this.deleteItemIndex, 1)
			} else {
				this.$refs['modal-common'].show('삭제', '정말로 삭제하시겠습니까?', 'delete', '삭제')
			}
		},
		onDeleteItem() {
			this.tableData.splice(this.deleteItemIndex, 1)
			this.$refs['modal-common'].hide()
		},
		convertNumberToCurrency(value) {
			return this.convertCurrencyToNumber(value)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		},
		convertCurrencyToNumber(value) {
			if (!value) return '0'
			if (value == 0) return value
			return parseInt(value.toString().replace(/,/g, ''))
		},
		valid() {
			let valid = true
			this.tableData.forEach(row => {
				Object.keys(row).forEach(key => {
					if (this.requiredKeyList.includes(key)) {
						if (row[key] === '' || row[key] === 0) valid = false
					}
				})
			})

			return valid
		},
		setTableData(tableData) {
			const interval = setInterval(() => {
				this.productTableData = this.$_.cloneDeep(tableData)
				this.productTableData.forEach((productData, index) => {
					if (index !== 0) this.onClickAddBtn()
					Object.keys(this.tableData[0]).forEach(key => {
						this.tableData[index][key] = productData[key]
					})
				})

				if (!this.$_.isEmpty(this.tableData[0])) clearInterval(interval)
			}, 500)
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.table-wrapper {
		border: 1px solid $LINE_DIVIDER;
		border-radius: 4px;
		overflow: auto;
		max-height: 400px;
		table {
			width: 100%;
			table-layout: fixed;
			thead {
				background: $COLOR_F5;
				border-bottom: 1px solid $LINE_DIVIDER;
				th {
					padding: 14px;
					font-size: 14px;
					text-align: left;
					color: $COLOR_666;
					span {
						white-space: nowrap;
						em {
							color: $PRIMARY_RED;
						}
					}
				}
			}
			tbody {
				tr {
					&:not(:last-child) {
						border-bottom: 1px solid $LINE_DIVIDER;
					}
					td {
						position: relative;
						padding: 14px;
						font-size: 14px;
						input {
							width: 100%;
							height: 36px;
							border: 1px solid $FILE_BORDER;
							border-radius: 5px;
						}
						.number-input-minus {
							position: absolute;
							padding: 7px;
							right: 45px;
							cursor: pointer;
						}
						.number-input-plus {
							position: absolute;
							padding: 7px;
							right: 20px;
							cursor: pointer;
						}
						.currency-type {
							position: absolute;
							padding: 7px;
							right: 20px;
							font-weight: 700;
						}
					}
				}
			}
		}
	}
	.add-option-container {
		border-left: 1px solid $LINE_DIVIDER;
		border-right: 1px solid $LINE_DIVIDER;
		padding: 10px 15px;
		text-align: left;
		display: flex;
		align-items: center;
		span {
			color: $FONT_YELLOW;
			font-size: 14px;
			font-weight: bold;
			cursor: pointer;
		}
	}
	.count-container {
		border: 1px solid $LINE_DIVIDER;
		background: $COLOR_F5;
		padding: 6px;
		text-align: center;
		font-size: 12px;
		color: $PRIMARY_BLACK;
	}
}
</style>
