<template>
	<div class="info-list">
		<div class="info-list-title">
			<h5 v-if="title">{{ title }}</h5>
			<button v-if="list.length > 0 && isFiles" class="btn" @click="allDownload">
				<img src="@/assets/svg/common/table.svg" />
				<span>모두 다운로드</span>
			</button>
			<button v-if="titleBtn && titleBtn.text" class="btn" :style="{ padding: titleBtn.image ? '' : '0 20px' }" @click="$emit('clickEv')">
				<img v-if="titleBtn.image" :src="titleBtn.image" />
				{{ titleBtn.text }}
			</button>
		</div>
		<ul class="list">
			<li
				v-for="(item, index) in infoList"
				class="item"
				:style="item.isSingleRow ? 'width: 100%' : 'width: 50%'"
				:key="`row_${index}`"
				:class="{ listRow: item.row }"
			>
				<div class="header" v-html=item.title></div>
				<div class="content">
					<div class="array-item" v-if="item.type === 'file'">
						<a v-for="(subitem, idx) in item.value" :key="`sub_${idx}`" :href="subitem.url" download target="_blank">
							<img src="@/assets/svg/common/docs.svg" />
							<span>{{ subitem.name }}</span>
						</a>
						<p v-if="item.value && !item.value.length">-</p>
					</div>
					<div v-else-if="item.type === 'tag'">
						<span class="tag" v-for="(tag, index) in item.value" :key="index">{{ tag.value ?? tag }}</span>
					</div>
					<div v-else-if="item.type === 'image'" style="display: flex">
						<div v-for="(image, index) in item.value" :key="`image${index}`" style="padding-right: 5px">
							<img v-if="image.url" class="img" :style="item.style" :src="image.url" @click="clickImage(image)" />
							<span v-else>-</span>
						</div>
					</div>
					<div v-else-if="item.type === 'button'">
						<button class="btn-normal" @click="onClickButton(item.value)">
							<span>{{ item.text }}</span>
						</button>
					</div>
					<div v-else-if="item.type === 'link'" class="link">
						<p style="text-decoration: underline" @click="item.clickFn()">{{ item.value }}</p>
					</div>
					<div v-else-if="item.type === 'text-button'" class="text-button">
						<p>{{ item.value }}</p>
						<button class="btn-small" @click="item.clickFn()">
							<span>{{ item.buttonText }}</span>
						</button>
					</div>
					<div v-else-if="item.type === 'date'" class="date">
						<label style="display: flex; justify-content: space-between; align-items: center; cursor: pointer">
							<p style="text-decoration: underline">{{ item.value }}</p>
							<!-- <p :style="{ textDecoration: item.isEmpty ? 'null' : 'underline' }">{{ item.value }}</p> -->
							<jw-date-picker
								class="date-picker"
								ref="datePicker"
								:index="0"
								:initDate="item.isEmpty ? null : item.value"
								:isRange="false"
								:datePickerStyle="{
									position: 'absolute',
									left: '0',
									width: '100%',
									height: 0,
									border: 'none',
								}"
								@changeDate="$event => item.clickFn($event)"
							/>
						</label>
					</div>
					<div v-else-if="item.type === 'option'">
						<p style="font-weight: bold; padding-bottom: 10px">{{ item.value.title }}</p>
						<p>{{ item.value.size }}</p>
						<p>{{ item.value.subtitle }}</p>
						<button class="btn-normal lnline" @click="onClickUpdatePriceButton(item.productId, item.unitPrice, item.salesUnitPrice)">가격 수정</button>
						<button v-if="item.isDel" class="btn-normal lnline" @click="onClickUpdateDeleteStatusButton(item.productId, item.isDel)">삭제 복구</button>
						<button v-else class="btn-normal lnline" @click="onClickUpdateDeleteStatusButton(item.productId, item.isDel)">삭제</button>
						<button v-if="item.isB2bNonTarget" class="btn-normal lnline" @click="onClieckDeleteB2bTargetButton(item.productId, item.isB2bNonTarget)">B2B판매 재개</button>
					</div>
					<div v-else-if="item.type === 'address'">
						<span style="font-weight: bold">{{ item.value.name }}</span>
						<span>&nbsp;{{ item.value.detail }} </span>
					</div>
					<div v-else-if="item.type === 'list'">
						<p v-for="(value, index) in item.value" :key="`list${index}`">{{ `${value ? '• ' + value : '-'}` }}</p>
					</div>
					<div class="object-item" v-else-if="item.value && isObject(item.value)">
						<img
							:class="{ noImg: item.value && !item.value.image }"
							:src="item.value && item.value.image ? item.value.image : require('@/assets/svg/common/member.svg')"
						/>
						<b>{{ item.value ? item.value.name : '' }}</b>
					</div>
					<p class="approval" :class="item.status === 'ACCEPT' ? 'isApproval' : 'notApproval'" v-else-if="item.category == 'endDatetime'">
						{{ item.value }}
						{{ approvalStatus(item) }}
					</p>
					<p v-else-if="item.type === 'status'" :style="setColor(item.value)">{{ isStatus(item.value) }}</p>
					<p v-else-if="item.type === 'status-border'" class="status-border" :class="item.value === '추천 브랜드' ? 'recommend' : ''">
						{{ item.value }}
					</p>
					<p v-else-if="item.type === 'bold'" style="font-weight: bold">{{ isData(item.value) }}</p>
					<div v-else-if="item.type === 'selection'">
						<b-form-select :value="item.value" :options="item.options" @change="item.onChange" />
					</div>
					<p v-else v-html="isData(item.value)" />
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import JwDatePicker from '@/views/common/components/search/JwDatePicker.vue'

export default {
	components: {
		JwDatePicker,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		titleBtn: {
			type: Object,
			default: () => {},
		},
		list: {
			type: Array,
			default: () => [],
		},
		isFiles: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		infoList() {
			let list = []
			if (this.list.length === 0 && this.isFiles) {
				list.push({
					title: '첨부 파일',
					value: '',
					row: 3,
				})
				return list
			}
			list = this.list

			return list
		},
	},
	methods: {
		//object 여부 확인.
		isObject(value) {
			if (typeof value == 'object') {
				return !this.isArray(value)
			} else {
				return false
			}
		},
		//배열 여부 확인
		isArray(item) {
			return Array.isArray(item)
		},
		approvalStatus(item) {
			if (item.status === 'ACCEPT') {
				return '(승인)'
			}
		},
		isData(value) {
			if (value) {
				return value
			} else {
				return '-'
			}
		},
		isStatus(value) {
			const result = {
				WAIT: '심사 대기',
				APPROVE: '승인',
				REJECT: '반려',
				SELLING: '판매중',
				STOP_SELLING: '판매중지',
				EMPTY: '판매중인 SKU 없음',
				COMPLETE: '승인',
			}[value]

			return result
		},
		setColor(value) {
			const result = {
				APPROVE: 'font-weight: bold; color: #0DC577',
				REJECT: 'font-weight: bold; color: #FF5746',
				SELLING: 'font-weight: bold; color: #0DC577',
				STOP_SELLING: 'font-weight: bold; color: #FF5746',
				EMPTY: 'font-weight: bold; color: #FF5746',
				COMPLETE: 'font-weight: bold; color: #0DC577',
				WAIT: 'font-weight: bold; color: #FF5746',
			}[value]

			return result
		},
		allDownload() {
			this.list.forEach(() => {
				let link = document.querySelector('.array-item a')
				link.click()
			})
		},
		clickImage(value) {
			this.$emit('onClickImage', value)
		},
		onClickButton(value) {
			this.$emit('onClickButton', value)
		},
		onClickUpdatePriceButton(productId, unitPrice, salesUnitPrice) {
			this.$emit('onClickUpdatePriceButton', productId, unitPrice, salesUnitPrice)
		},
		onClickUpdateDeleteStatusButton(productId, isDel) {
			this.$emit('onClickUpdateDeleteStatusButton', productId, !isDel)
		},
		onClieckDeleteB2bTargetButton(productId) {
			this.$emit('onClieckDeleteB2bTargetButton', productId)
		},
	},
}
</script>

<style lang="scss" scoped>
.info-list {
	padding-bottom: 30px;
	text-align: left;
	.info-list-title {
		display: flex;
		align-items: center;
		height: auto;
		margin-bottom: 1rem;
		h5 {
			font-size: 20px;
			font-weight: 700;
		}
		button {
			margin-left: auto;
		}
	}
	// h5 {
	// 	font-size: 1.25rem;
	// 	font-weight: bold;
	// 	margin-bottom: 15px;
	// }
	ul {
		width: 100%;
		border-top: 1px solid $COLOR_DDD;
		overflow: hidden;
		display: flex;
		flex-wrap: wrap;
		li {
			width: 50%;
			font-size: 0.875rem;
			font-weight: 400;
			background-color: $LIST_BACKGROUND;
			border-bottom: 1px solid $COLOR_EEE;
			min-height: 40px;
			display: inline-flex;
			align-items: center;
			&.listRow {
				width: 100% !important;
				align-items: flex-start;
				.header {
					padding-top: 10px;
				}
				.content {
					display: inline-block;
					padding-top: 10px;
					p {
						white-space: normal;
						overflow: initial;
					}
				}
			}
			.header {
				width: 200px;
				padding-left: 12px;
			}
			.content {
				width: calc(100% - 200px);
				background-color: white;
				display: flex;
				align-items: center;
				min-height: inherit;
				padding: 5px 12px;
				.link {
					cursor: pointer;
				}
				.text-button {
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.date {
					position: relative;
					width: 100%;
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
				.object-item {
					img {
						width: 24px;
						height: 24px;
						object-fit: cover;
						border: 1px solid $LINE_DIVIDER;
						border-radius: 12px;
						margin-bottom: 2px;
						&.noImg {
							border: 0;
							border-radius: 0;
							border: 0;
						}
					}
				}
				> p {
					font-weight: 400;
					max-width: 100%;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					&.approval {
						color: $COLOR_666;
						&.isApproval {
							color: $PRIMARY_GREEN;
						}
						// &.notApproval {
						// 	color: $PRIMARY_RED;
						// }
					}
				}
				> img {
					width: 100px;
					height: 150px;
					margin-right: 10px;
					overflow: hidden;
					border-radius: 5px;
					object-fit: cover;
					cursor: pointer;
					border-radius: 8px;
				}
				.tag {
					font-size: 12px;
					color: $PRIMARY_BLACK;
					padding: 3px 6px;
					background: #fff;
					border-radius: 5px;
					display: inline-block;
					margin-right: 5px;
					border: $COLOR_DDD solid 1px;
				}
				.btn-small {
					height: 30px;
					border-radius: 5px;
					width: 70px;
					display: flex;
					justify-content: center;
					font-size: 0.875rem;
					font-weight: 700;
					background-color: $COLOR_F5 !important;
					color: black !important;
					margin-right: 5px;
				}
				.btn-normal {
					height: 36px;
					border-radius: 5px;
					width: 100px;
					display: flex;
					justify-content: center;
					font-size: 0.875rem;
					font-weight: 700;
					background-color: $COLOR_F5 !important;
					color: black !important;
					margin-right: 5px;

					&.lnline {
						display: inline-block;
						margin-right: 10px;
						margin-top: 5px;
					}
				}
				a {
					display: flex;
					align-items: center;
					width: 100%;
					font-weight: 400;
					img {
						margin-right: 10px;
					}
				}
				b {
					display: inline-block;
					margin-left: 5px;
				}
				.status-border {
					padding: 0px 5px;
					font-size: 0.75rem;
					font-weight: 700;
					border-radius: 5px;
					height: 20px;
					color: #666;
					background-color: #eee;
					&.recommend {
						color: #faa400;
						background-color: #fff3d6;
					}
				}
			}
		}
	}
	.img {
		width: 150px;
		height: 150px;
		border: 1px solid #eeeeee;
		border-radius: 7px;
	}
}
::v-deep {
	select {
		padding: 0px 10px;
		width: 140px;
		height: 30px;
		font-size: 14px;
		&:focus {
			border-color: #111;
			box-shadow: none;
		}
	}
				
	div .REJECT {
		color: red;
		font-weight: bold;
	}
}
</style>
