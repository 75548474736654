<template>
	<b-modal
		id="modal-update-selling"
		centered
		title="판매중으로 변경"
		:ok-title="btnText"
		ok-variant="complete"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-common"
		:size="size"
		button-size="lg"
		:hide-header-close="!isHeaderClose"
		@ok="$emit('ok')"
	>
		<div class="msg-wrapper">
			<p>
				판매중으로 상태를 변경하면 랩매니저 스토어에서 상품이 노출됩니다.<br />
				판매중으로 변경하시겠습니까?
			</p>
		</div>
	</b-modal>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: '',
		},
		notice: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '확인',
		},
		isHeaderClose: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss">
#modal-update-selling {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			text-align: center;
			justify-content: center;
			padding-bottom: 0;
			border-bottom: 0;
		}
		.modal-body {
			text-align: center;
			p {
				font-size: 0.875rem;
				font-weight: 400;
				color: #333;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			border-top: 0;
			text-align: center;
			justify-content: center;
			button {
				width: 160px;
				height: 40px;
				font-size: 0.875rem;
			}
		}
	}
}

#modal-common {
	.modal-content {
		padding: 20px;
		.modal-header {
			font-size: 1.125rem;
			padding: 0 0;
			border-bottom: 0;
		}
		.modal-body {
			padding: 20px 0;
			font-size: 0.875rem;
		}
		.modal-footer {
			flex-wrap: unset;
			justify-content: end;
			border-top: 0;
			padding: 0 0;
			button {
				justify-content: center;
				min-width: 100px;
				width: unset;
				height: 30px;
				font-size: 0.875rem;
				border-radius: 5px;
				border-width: 1px;
			}
		}
	}
}
</style>
