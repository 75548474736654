<template>
	<div class="select-category">
		<div class="category-table">
			<div class="header">
				<div>분류</div>
				<div>상세 분류</div>
			</div>
			<div class="content">
				<div class="large">
					<ul>
						<li
							v-for="(option, index) in large"
							:key="`large_${index}`"
							:class="{ active: largeSelect.code === option.code, disabledSelect: fixLargeCategory }"
							@click="selectCategory(option, 'large')"
						>
							{{ option.korName }}
						</li>
					</ul>
				</div>
				<div class="middle">
					<ul>
						<li
							v-for="(option, index) in middleList"
							:key="`middle_${index}`"
							:class="{
								active: middleSelect.code === option.code,
								disabledSelect: fixMiddleCategory,
							}"
							@click="selectCategory(option, 'middle')"
						>
							{{ option.korName }}
						</li>
					</ul>
					<div class="no-data" v-if="!largeSelect && largeSelect !== 0">
						<p>분류를 선택해주세요</p>
					</div>
				</div>
			</div>
		</div>
		<div class="error-text" v-if="showErrorMessage && !isSelectCategory">카테고리를 선택해주세요</div>
		<div class="selected-category-text" v-if="showSelectedCategoryMessage && isSelectCategory">
			선택한 카테고리:
			<span v-if="largeSelect">{{ largeSelect.korName }}</span>
			<span v-if="middleSelect">{{ middleSelect.korName }}</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SelectCategory',
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		large: {
			type: Array,
			default: () => [],
		},
		middle: {
			type: Array,
			default: () => [],
		},
		showErrorMessage: {
			type: Boolean,
			default: false,
		},
		showSelectedCategoryMessage: {
			type: Boolean,
			default: false,
		},
		fixLargeCategory: {
			type: Boolean,
			default: false,
		},
		fixMiddleCategory: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			middleList: [],
			largeSelect: '',
			middleSelect: '',
		}
	},
	computed: {
		isSelectCategory() {
			let isSelectCategory = false
			if (this.middleSelect) isSelectCategory = true
			return isSelectCategory
		},
	},
	watch: {
		middle(val) {
			this.middleList = val
		},
	},
	methods: {
		selectCategory(option, classification, forced) {
			if (!forced && this.fixLargeCategory && classification === 'large') {
				return
			}
			if (!forced && this.fixMiddleCategory && classification === 'middle') {
				return
			}
			if (classification === 'large') {
				this.largeSelect = option
				this.middleList = option.children
				this.middleSelect = ''
			} else if (classification === 'middle') {
				this.middleSelect = option
			}

			this.$emit('selectCategory', {
				option: option,
				classification: classification,
			})
		},
		setData(data, option) {
			this.largeSelect = option
			this.middleList = option.children
			this.middleSelect = data.category.option
		},
	},
}
</script>

<style lang="scss" scoped>
.select-category {
	.category-table {
		width: 100%;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		overflow: hidden;
		.header {
			width: 100%;
			border-bottom: 1px solid $COLOR_DDD;
			background-color: $TITLE_BACKGROUND;
			height: 30px;
			box-sizing: border-box;
			div {
				width: 50%;
				height: inherit;
				text-align: left;
				padding-left: 15px;
				font-size: 0.75rem;
				font-weight: 500;
				color: $COLOR_666;
				display: inline-flex;
				align-items: center;
				border-right: 1px solid $COLOR_DDD;
				box-sizing: border-box;
				&:last-child {
					border-right: 0;
				}
			}
		}
		.content {
			display: flex;
			> div {
				width: 50%;
				box-sizing: border-box;
				border-right: 1px solid $COLOR_DDD;
				display: inline-block;
				height: 280px;
				position: relative;

				ul {
					width: 100%;
					li {
						width: 100%;
						height: 40px;
						border-bottom: 1px solid $LINE_DIVIDER;
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-weight: 400;
						padding: 0 15px;
						background-image: url(~@/assets/svg/registration/right-arrow.svg);
						background-size: 8px;
						background-position: center right 8px;
						background-repeat: no-repeat;
						box-sizing: border-box;
						cursor: pointer;
						&:nth-child(7) {
							border-bottom: 0;
						}
						&.active {
							background-color: $PRIMARY_YELLOW_100;
							background-image: url(~@/assets/svg/registration/right-arrow_b.svg);
							color: $FONT_YELLOW;
							font-weight: 500;
						}

						&.disabledSelect {
							cursor: default;
						}
					}
				}
				&:last-child {
					border-right: 0;
					ul {
						li {
							background-image: unset;
							// &.active {
							// 	background-image: url(~@/assets/svg/registration/sort-checked_b.svg);
							// 	background-size: 24px;
							// }
						}
					}
				}
				.no-data {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					text-align: center;
					word-break: keep-all;
					width: 100%;
				}
			}
		}
	}

	.error-text {
		margin-top: 10px;
		color: $PRIMARY_RED;
		font-size: 0.875rem;
		font-weight: 400;
	}
	.selected-category-text {
		margin-top: 10px;
		font-size: 0.875rem;
		font-weight: 400;
		span {
			font-weight: 700;
			position: relative;
		}
		span + span::before {
			margin-left: 5px;
			content: '';
			width: 10px;
			height: 10px;
			display: inline-block;
			justify-content: center;
			align-items: center;
			background-image: url(~@/assets/svg/registration/arrow-right-small.svg);
			background-repeat: no-repeat;
		}
	}
}
</style>
