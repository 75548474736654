<template>
	<tr class="table-item">
		<td>
			<div>
				<span class="underline" @click="goToDetail">{{ item.settleNo }}</span>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700" :style="textColor">{{ getStatusText(item.status) }}</span>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700" :style="textColor">{{ commaNum(item.settlePriceTotal) }}원</span>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700" :style="{ color: item.status === 'approve' ? '#FF5746' : '' }">
					{{ commaNum(item.billingPriceTotal) }}원
				</span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ commaNum(item.benefit) }}원 </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.benefitRate) }}% </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.companyInfo) }} </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.billingDatetime ? $DateTime.fromISO(item.billingDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.confirmDatetime ? $DateTime.fromISO(item.confirmDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.remittanceDatetime ? $DateTime.fromISO(item.remittanceDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		textColor() {
			switch (this.item.status) {
				case 'cancel':
					return 'color: #999999'
				case 'approve':
					return 'color: #FF5746'
				case 'wait':
					return 'color: #0B6CFF'
				case 'complete':
					return 'color: #0DC577'
			}
		},
	},
	methods: {
		goToDetail() {
			// this.$router.push({
			// 	name: PageName.Buyer.BuyerDetail,
			// 	params: {
			// 		id: 1,
			// 	},
			// })
		},
		getStatusText(status) {
			const result = {
				cancel: '청구취소',
				approve: '정산요청',
				wait: '송금대기',
				complete: '정산완료',
			}[status]

			return result
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.table-item {
	cursor: default;
}
.underline {
	cursor: pointer;
	text-decoration: underline;
}
</style>
