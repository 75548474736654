import productAPI from '@/services/api/product'

export default {
	namespaced: true,
	state: {
		detail: {},
	},
	getters: {},
	mutations: {
		setDetail: (state, payload) => {
			state.detail = payload
		},
	},
	actions: {
		getDetail: async ({ commit }, params) => {
			const response = await productAPI.getDetail(params)
			commit('setDetail', response.data)
		},
		updateSellingStatus: async ({ state }, body) => {
			return await productAPI.updateSellingStatus(body)
		},
		updateProduct: async ({ state }, body) => {
			return await productAPI.updateProduct(body)
		},
	},
}
