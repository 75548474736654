<template>
	<table class="lm-table">
		<table-header />
		<table-item v-for="item in asccountList.list" :key="item.id" :item="item" />
		<div class="empty-table" v-if="asccountList.count === 0">
			<img class="empty-img" src="@/assets/svg/common/inventory-empty-data.svg" />
			<p class="bold">계정 목록이 없습니다.</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './TableHeader.vue'
import TableItem from './TableItem.vue'
import { mapState } from 'vuex'
export default {
	components: {
		TableHeader,
		TableItem,
	},
	computed: {
		...mapState('seller/detail', ['asccountList']),
	},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	min-height: 680px;
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	padding-top: 200px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: #666;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: #999;
	}
}
</style>
