<template>
	<div class="page-wrapper">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<notice-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import Pagination from '@/views/common/components/CommonPagenation.vue'
import ListHeader from '../components/ListHeader.vue'
import NoticeTable from '../components/Table.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	components: {
		ListHeader,
		Pagination,
		NoticeTable,
	},
	computed: {
		...mapState('lms/notice', ['list', 'count']),
	},
	created() {
		this.initParams()
		this.getList()
	},
	methods: {
		...mapMutations('lms/notice', ['initParams']),
		...mapActions('lms/notice', ['getList', 'setLoadParams', 'setPagination']),
		pagination(page) {
			this.setPagination(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 161px);
	}
}
</style>
