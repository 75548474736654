const Constant = {}
Constant.maxMultiCreateCount = 999
Constant.ebillDepositDueTime = '23:30'

const APP_MODE_PRODUCTION = 'PRODUCTION'
const APP_MODE_DEVELOP = 'DEVELOP'
const APP_MODE_EXPERIENCE = 'EXPERIENCE'
const APP_MODE_DEV_EXPERIENCE = 'DEV_EXPERIENCE'

const MENU_ITEM = {}
MENU_ITEM.ACCOUNT_SETTING = 'ACCOUNT_SETTING'
MENU_ITEM.LOGOUT = 'LOGOUT'

const SUBMENU_ITEM = {}
SUBMENU_ITEM.NOTICE = 'NOTICE'
SUBMENU_ITEM.REQUEST = 'REQUEST'
SUBMENU_ITEM.HOMEPAGE = 'HOMEPAGE'
SUBMENU_ITEM.PROVISIONS = 'PROVISIONS'

export { Constant, APP_MODE_PRODUCTION, APP_MODE_EXPERIENCE, APP_MODE_DEVELOP, APP_MODE_DEV_EXPERIENCE, MENU_ITEM, SUBMENU_ITEM }
