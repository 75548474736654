<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div style="min-width: 50px; width: 50px">
				<span>
					{{ item.id }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					<span :class="['status', item.status !== 'DONE' ? item.status : '']"> {{ statusText }} </span>
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 550px; max-width: 750px">
				<span>
					{{ item.title }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span>
					{{ $DateTime.fromISO(item.startDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span>
					{{ $DateTime.fromISO(item.endDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item?.user?.name }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		statusText() {
			if (this.item.status == 'EXPOSE') return '노출'
			else if (this.item.status == 'WAIT') return '대기'
			return '종료'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'NoticeCreate',
				query: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
