<template>
	<div class="order-item-detail-header">
		<div class="title">
			<h4>{{ item.orderItemNo }}</h4>
			<div class="subtitle">
				<span class="status-text">{{ isData(statusText) }}</span>
				<span v-if="cancelStatusText" class="status-text fail">{{ isData(cancelStatusText) }}</span>
				<p>주문 일시: {{ createDatetime }}</p>
			</div>
		</div>
		<div class="right-btn-group">
			<button v-if="isShowTradeStatement" class="btn" @click="onPrintTradeStatement" style="width: 140px">거래명세서 다운로드</button>
			<button class="btn" @click="estimate">견적서 작성</button>
			<!-- todo : 취소 교환 반품 상세로 이동 -->
			<!-- <button v-if="isShowReceptionContents" class="btn" @click="goCancelRefundDetail">접수내용 확인</button> -->
			<div class="division" v-if="isShowDivision">|</div>
			<button v-if="isShowCancelOrder" class="btn-warning" style="margin-left: 0" @click="onCancelOrder">주문취소</button>
			<!-- <button v-if="isShowCancelRefund" class="btn-warning" @click="onCancelRefund">교환/반품</button> -->
			<button v-if="isShowApproveOrder" class="btn-confirm" @click="onApproveOrder">주문승인</button>
			<button v-if="isShowStartShipping" class="btn-confirm" @click="onStartShipping">배송시작</button>
			<button v-if="isShowCompleteShipping" class="btn-confirm" @click="onCompleteShipping">배송완료</button>
		</div>

		<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="completeCommonModal"></modal-common>
		<modal-shipping-info
			:isShow="isShowModalShippingInfo"
			:editType="EDIT_TYPE.SHIPPING_START"
			:orderItemList="[item]"
			@hide="isShowModalShippingInfo = false"
			@complete="$emit('load')"
		></modal-shipping-info>
		<modal-order-cancel
			:isShow="isShowModalOrderCancel"
			:orderItemList="[item]"
			@hide="isShowModalOrderCancel = false"
			@complete="$emit('load')"
		></modal-order-cancel>
		<modal-order-cancel-refund
			:isShow="isShowModalOrderCancelRefund"
			:orderItemList="[item]"
			@hide="isShowModalOrderCancelRefund = false"
			@complete="$emit('load')"
		></modal-order-cancel-refund>
		<modal-order-approve
			:orderItemList="[item]"
			:isShow="isShowModalOrderApprove"
			@hide="isShowModalOrderApprove = false"
			@complete="$emit('load')"
		></modal-order-approve>
	</div>
</template>

<script>
import ModalCommon from '@/views/common/modals/ModalCommon.vue'
import ModalShippingInfo from '@/views/order/orderItem/modals/ModalShippingInfo.vue'
import ModalOrderCancel from '@/views/order/orderItem/modals/ModalOrderCancel.vue'
import ModalOrderCancelRefund from '@/views/order/orderItem/modals/ModalOrderCancelRefund.vue'
import ModalOrderApprove from '@/views/order/orderProduct/modals/ModalOrderApprove.vue'
import { mapState, mapActions } from 'vuex'
import { OrderStatusText } from '@/utils/define/ItemCode'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'

const MODAL_COMMON_TYPE = {
	APPROVE_ORDER: 'APPROVE_ORDER',
	COMPLETE_SHIPPING: 'COMPLETE_SHIPPING',
}

const EDIT_TYPE = {
	SHIPPING_START: 'shippingStart',
}

export default {
	name: 'OrderItemDetailHeader',
	components: {
		ModalCommon,
		ModalShippingInfo,
		ModalOrderCancel,
		ModalOrderCancelRefund,
		ModalOrderApprove,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			EDIT_TYPE,
			modalCommonType: '',
			isShowModalShippingInfo: false,
			isShowModalOrderCancel: false,
			isShowModalOrderCancelRefund: false,
			isShowModalOrderApprove: false,
		}
	},
	computed: {
		createDatetime() {
			const { createDatetime } = this.item
			const createDatetimeFormat = createDatetime ? this.formatDatetime(createDatetime, 'yyyy-MM-dd T') : this.isData(createDatetime)
			return createDatetimeFormat
		},
		statusText() {
			return OrderStatusText(this.item).text
		},
		cancelStatusText() {
			return OrderStatusText(this.item).subtext
		},
		isCancelOrRefund() {
			return this.item.refundStatus || this.item.cancelStatus
		},
		isShowTradeStatement() {
			return !this.isCancelOrRefund
		},
		isShowReceptionContents() {
			return this.isCancelOrRefund
		},
		isShowCancelOrder() {
			const { status } = this.item
			if (!this.isCancelOrRefund && ['WAIT', 'APPROVE'].includes(status)) return true
			return false
		},
		isShowCancelRefund() {
			const { status } = this.item
			if (!this.isCancelOrRefund && ['SHIPPING_COMPLETE', 'CONFIRM'].includes(status)) return true
			return false
		},
		isShowApproveOrder() {
			const { status } = this.item
			if (!this.isCancelOrRefund && status === 'WAIT') return true
			return false
		},
		isShowStartShipping() {
			const { status, cancelStatus } = this.item
			if (!this.isCancelOrRefund && status === 'APPROVE') return true
			else if (this.isCancelOrRefund && status === 'APPROVE' && !['ING', 'DONE'].includes(cancelStatus)) return true
			return false
		},
		isShowCompleteShipping() {
			const { status } = this.item

			if (!this.isCancelOrRefund && status === 'SHIPPING_ING') return true
			return false
		},
		isShowDivision() {
			return this.isShowCancelOrder || this.isShowApproveOrder || this.isShowStartShipping || this.isShowCompleteShipping
		},
	},
	methods: {
		...mapActions('order', ['approveOrder', 'completeShipping', 'printTradeStatement']),
		completeCommonModal() {
			if (this.modalCommonType === MODAL_COMMON_TYPE.APPROVE_ORDER) this.updateApproveOrder()
			else if (this.modalCommonType === MODAL_COMMON_TYPE.COMPLETE_SHIPPING) this.updateCompleteShipping()
		},
		async onPrintTradeStatement() {
			try {
				const { orderItemId, institute, orderer, createDatetime } = this.item
				const { name: instituteName } = institute
				const { name, phone, email } = orderer
				const createDatetimeFormat = this.formatDate(createDatetime)
				const response = await this.printTradeStatement({
					recipientInstituteName: instituteName,
					recipientName: name,
					recipientPhone: phone,
					recipientEmail: email,
					tradeDatetime: createDatetimeFormat,
					orderItemIds: [orderItemId],
				})

				window.fileDownload(response, `거래명세서_${instituteName}_${createDatetimeFormat}.pdf`)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		estimate() {
			const { orderItemId } = this.item
			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'add',
				},
				query: {
					ids: JSON.stringify([orderItemId]),
				},
			})
		},
		onApproveOrder() {
			if (!this.item.estimatedReleaseDatetime) {
				this.isShowModalOrderApprove = true
				return
			}

			this.modalCommonType = MODAL_COMMON_TYPE.APPROVE_ORDER
			this.$refs['modal-common'].show(
				'주문 승인',
				'선택한 상품의 주문을 승인하고 <br /> 주문상태를 <span style="font-weight: 700;">승인 완료</span>으로 변경하시겠습니까?',
				'complete',
				'변경'
			)
		},
		async updateApproveOrder() {
			try {
				await this.approveOrder({
					orderItems: [
						{
							id: this.item.orderItemId,
							estimatedReleaseDatetime: this.formatDate(this.item.estimatedReleaseDatetime),
						},
					],
				})

				this.$refs['modal-common'].hide()
				this.$root.toast('주문상태 변경 완료', '승인 완료으로 변경되었습니다.', ToastType.SUCCESS)
				this.$emit('load')
			} catch (error) {
				this.$root.toast('에러', error.response.data.msg, ToastType.ERROR)
			}
		},
		onStartShipping() {
			this.isShowModalShippingInfo = true
		},
		onCompleteShipping() {
			this.modalCommonType = MODAL_COMMON_TYPE.COMPLETE_SHIPPING
			this.$refs['modal-common'].show(
				'배송완료',
				'선택한 상품의 주문상태를 <span style="font-weight: 700;">배송완료</span>로 변경하시겠습니까?',
				'complete',
				'확인'
			)
		},
		async updateCompleteShipping() {
			try {
				await this.completeShipping({
					orderItems: [{ id: this.item.orderItemId }],
				})

				this.$refs['modal-common'].hide()
				this.$root.toast('주문상태 변경 완료', '배송완료로 변경되었습니다.', ToastType.SUCCESS)
				this.$emit('load')
			} catch (error) {
				this.$root.toast('에러', error.response.data.msg, ToastType.ERROR)
			}
		},
		onCancelOrder() {
			this.isShowModalOrderCancel = true
		},
		onCancelRefund() {
			// API 나오면 작업
			this.isShowModalOrderCancelRefund = true
		},
		goCancelRefundDetail() {
			console.log('교환/반품 상세로 가기')
			// 교환/반품 상세페이지 작업 되면 주석 제거
			// this.$router.push({
			// 	name: PageName.CS.CancelRefundDetail,
			// 	params: {
			// 		id: this.item.orderItemId,
			// 	},
			// })
		},
	},
}
</script>

<style lang="scss" scoped>
.order-item-detail-header {
	width: 100%;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $LINE_DIVIDER;
	height: 90px;
	padding: 0 30px;
	margin-bottom: 30px;
	justify-content: flex-start;
	.title {
		text-align: left;
		h4 {
			font-size: 1.5rem;
			font-weight: 700;
		}
		.subtitle {
			display: inline-flex;
			align-items: center;
			margin-top: 6px;
			p {
				font-size: 0.75rem;
				font-weight: 400;
				color: $COLOR_666;
				padding-left: 20px;
				position: relative;
				display: inline-block;
				&.error {
					color: $PRIMARY_RED;
				}
				&:after {
					width: 1px;
					height: 12px;
					content: '';
					position: absolute;
					top: calc(50% - 6px);
					left: 10px;
					background-color: $LINE_DIVIDER;
				}
			}
			span + span {
				margin-left: 5px;
			}
		}
	}
	.right-btn-group {
		button {
			border-radius: 5px;
			width: 100px;
			height: 36px;
			justify-content: center;
			font-weight: 700;
			font-size: 0.875rem;
		}
		.division {
			width: 30px;
			color: $LINE_DIVIDER;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
