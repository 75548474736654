import cancelRefundAPI from '@/services/api/cs/cancelRefund'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			status: ['ALL'],
			type: JSON.parse(cookies.get('filter_cancelRefund_type')) ?? [],
			offset: 0,
			length: 50,
		},
		filter: null,
		filterCookieName: {
			type: 'filter_cancelRefund_type',
		},
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (state.loadParams.status.length == 1) return state.loadParams.status[0]
			else if (state.loadParams.status.length % 3 == 0) return 'ALL'
			return null
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => state.loadParams.type.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setFilter: (state, data) => {
			let result = {}
			for (let key in data) {
				let filters = []
				for (let id in data[key]) {
					filters.push({ id: id, name: data[key][id] })
				}
				result[key] = filters
			}
			state.filter = result
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: null,
				status: ['ALL'],
				type: JSON.parse(cookies.get('filter_cancelRefund_type')) ?? [],
				offset: 0,
				length: 50,
			}
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await cancelRefundAPI.lookupCancelRefundList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		getFilter: async ({ commit }) => {
			const response = await cancelRefundAPI.lookupCancelRefundFilter()
			commit('setFilter', response.data)
		},
		exportExcel: async ({ state }, type) => {
			// const response = await budgetAPI.listExportExcel(type == 'ALL' ? { status: state.loadParams.status } : state.loadParams)
			// return response
		},
	},
}
