<template>
	<div class="seller-info-wrapper">
		<div class="seller-info-title">
			<p>판매자 정보</p>
		</div>
		<div class="input-wrapper">
			<div>
				<input type="text" v-model="user.name" :class="{ error: !isValid.name }" placeholder="판매자 이름(업체명)*" />
				<div v-if="!isValid.name" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.name }}</span>
				</div>
			</div>
			<div>
				<input type="text" v-model="user.contactName" :class="{ error: !isValid.contactName }" placeholder="담당자*" />
				<div v-if="!isValid.contactName" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.contactName }}</span>
				</div>
			</div>
			<div>
				<input
					type="text"
					v-model="user.contactPhone"
					@input="autoPhoneNumber"
					:class="{ error: !isValid.contactPhone }"
					placeholder="연락처*"
				/>
				<div v-if="!isValid.contactPhone" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.contactPhone }}</span>
				</div>
			</div>
			<div class="address">
				<input type="text" v-model="user.address" :class="{ error: !isValid.address }" placeholder="주소*" disabled />
				<button class="btn" @click="searchAddress">주소 검색</button>
			</div>
			<div>
				<input type="text" v-model="user.addressDetail" :class="{ error: !isValid.addressDetail }" placeholder="상세주소*" />
				<div v-if="!isValid.address || !isValid.addressDetail" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.address }}</span>
				</div>
			</div>
			<div>
				<input
					type="text"
					v-model="user.corpNo"
					:class="{ error: !isValid.corpNo }"
					placeholder="사업자등록번호*"
					@input="autoTraderNumber"
				/>
				<div v-if="!isValid.corpNo" class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidText.corpNo }}</span>
				</div>
			</div>
		</div>
		<div class="type-file">
			<div class="upload-box-title">
				<p>계좌 사본 업로드</p>
				<button class="btn" @click="uploadFile">파일 선택</button>
			</div>
			<div ref="upload-box" class="upload-box" :class="{ error: !isValid.bankAccount }">
				<input ref="dropFile" type="file" class="dropFile" @change="onChange" @drop="dropFile" accept=".pdf" />
				<div class="file-box" v-if="!user.bankAccount.length">
					<p class="file-box-text">첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요</p>
					<div class="btn-wrap">
						<button class="btn" ref="select-btn" @click="uploadFile">파일 선택</button>
					</div>
				</div>
				<ul class="file-list" v-else>
					<li v-for="(file, index) in user.bankAccount" :key="`file_${index}`">
						<img src="@/assets/svg/common/docs.svg" />
						<p>{{ file.name }}</p>
						<button class="close-btn" @click="deleteFile(index)">
							<img src="@/assets/svg/app/Close-1.svg" />
						</button>
					</li>
				</ul>
			</div>
			<div v-if="!isValid.bankAccount" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidText.bankAccount }}</span>
			</div>
		</div>
		<div class="next-button-wrapper"><button class="btn Btn__complete" @click="onClickNextButton">가입신청</button></div>
		<div class="footer-wrapper">
			<p>이미 회원이신가요? <span @click="routeSellerLogin">로그인하기</span></p>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PageName from '@/utils/define/PageName'
import { ToastType } from '@/utils/define/ToastType'
import { addressFinderOpen } from '@/services/api/addressFinder'
import Validation from '@/utils/Validation'

export default {
	name: 'SellerInfo',
	data() {
		return {
			user: {
				name: '',
				contactName: '',
				contactPhone: '',
				address: '',
				addressDetail: '',
				corpNo: '',
				bankAccount: [],
			},
			isValid: {
				name: true,
				contactName: true,
				contactPhone: true,
				address: true,
				addressDetail: true,
				corpNo: true,
				bankAccount: true,
			},
			inValidText: {
				name: '',
				contactName: '',
				contactPhone: '',
				address: '',
				addressDetail: '',
				corpNo: '',
				bankAccount: '',
			},
		}
	},
	computed: {
		...mapGetters('user', {
			registUserInfo: 'getRegistUserInfo',
		}),
	},
	methods: {
		...mapMutations('user', ['setRegistUserInfo']),
		...mapActions('user', ['applyEnterSeller']),
		onClickNextButton() {
			if (!this.validate()) return

			const registUserInfo = this.$_.cloneDeep(this.registUserInfo)
			registUserInfo.name = this.user.name
			registUserInfo.contactName = this.user.contactName
			registUserInfo.contactPhone = this.user.contactPhone.replaceAll('-', '')
			registUserInfo.address = this.user.address
			registUserInfo.addressDetail = this.user.addressDetail
			registUserInfo.corpNo = this.user.corpNo.replaceAll('-', '')
			registUserInfo.bankAccount = this.user.bankAccount
			this.setRegistUserInfo(registUserInfo)

			this.registSeller()
		},
		async registSeller() {
			try {
				const formData = new FormData()
				Object.keys(this.registUserInfo).forEach(key => {
					if (key === 'bankAccount') {
						formData.append(key, this.registUserInfo[key][0])
					} else {
						formData.append(key, this.registUserInfo[key])
					}
				})
				const response = await this.applyEnterSeller(formData)
				if (response?.status === 201) {
					this.$emit('complete')
				}
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		routeSellerLogin() {
			this.$router.push({
				name: PageName.Seller.SellerLogin,
			})
		},
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['dropFile'].files = event.dataTransfer.files
				this.onChange()
			}
		},
		uploadFile() {
			this.$refs['dropFile'].click()
		},
		onChange() {
			const files = Object.values(this.$refs['dropFile'].files)
			const file = files[0]
			let isPdf = new RegExp('application/[a-z]*(pdf)').test(file.type)
			if (!isPdf) {
				this.user.bankAccount = this.user.bankAccount.length > 0 ? this.user.bankAccount : []
				const title = '계좌 사본 업로드 실패'
				const content = 'PDF 형식의 파일만 업로드 가능합니다.'
				this.printToast(title, content, ToastType.ERROR)
			} else {
				this.user.bankAccount = [file]
			}

			this.$refs['dropFile'].value = ''
		},
		deleteFile(index) {
			this.user.bankAccount.splice(index, 1)
			this.$refs['dropFile'].value = ''
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			// 판매자 이름(업체명)
			let isValid = true
			if (!this.user.name.length) {
				this.isValid.name = false
				this.inValidText.name = '필수값입니다.'
				isValid = false
			}
			// 담당자
			if (!this.user.contactName.length) {
				this.isValid.contactName = false
				this.inValidText.contactName = '필수값입니다.'
				isValid = false
			}
			// 연락처
			if (!this.user.contactPhone.length) {
				this.isValid.contactPhone = false
				this.inValidText.contactPhone = '필수값입니다.'
				isValid = false
			}
			// 주소
			if (!this.user.address.length) {
				this.isValid.address = false
				this.inValidText.address = '필수값입니다.'
				isValid = false
			}
			// 상세주소
			if (!this.user.addressDetail.length) {
				this.isValid.addressDetail = false
				this.inValidText.address = '필수값입니다.'
				isValid = false
			}
			// 사업자등록번호
			if (!this.user.corpNo.length) {
				this.isValid.corpNo = false
				this.inValidText.corpNo = '필수값입니다.'
				isValid = false
			}
			// 계좌 사본 파일
			if (!this.user.bankAccount.length) {
				this.isValid.bankAccount = false
				this.inValidText.bankAccount = '계좌 사본을 업로드해주세요.'
				isValid = false
			}

			return isValid
		},
		async searchAddress() {
			const localeMode = 'K'
			const result = await addressFinderOpen(window, localeMode)
			const { address, bname } = result
			this.user.address = `${address}(${bname})`
		},
		autoTraderNumber(event) {
			const traderNumber = Validation.autoTraderNumber(event.target.value)
			this.user.corpNo = traderNumber
		},
		autoPhoneNumber(event) {
			const phoneNumber = Validation.autoHypenTel(event.target.value)
			this.user.contactPhone = phoneNumber
		},
	},
}
</script>

<style lang="scss" scoped>
.seller-info-wrapper {
	.seller-info-title {
		display: flex;
		justify-content: center;
		p {
			font-size: 1.5rem;
			font-weight: 700;
		}
	}

	.input-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 30px;

		& > div {
			margin-bottom: 10px;
			input[type='text'] {
				width: 100%;
				height: 48px;
				border: 1px solid $FILE_BORDER;
				border-radius: 5px;
				padding: 12px;
				&.error {
					border-color: $PRIMARY_RED;
				}
			}
			.error {
				display: flex;
				span {
					font-size: 0.75rem;
					color: $PRIMARY_RED;
					margin-left: 6px;
				}
			}
		}

		// div {
		// 	display: flex;
		// 	position: relative;

		// 	input[type='text'] {
		// 		width: 100%;
		// 		height: 48px;
		// 		border: 1px solid $FILE_BORDER;
		// 		border-radius: 5px;
		// 		padding: 12px;

		// 		&:not(:placeholder-shown) + span {
		// 			display: none;
		// 			visibility: hidden;
		// 		}
		// 	}
		// 	span {
		// 		position: absolute;
		// 		top: 10px;
		// 		color: #ff5746;
		// 	}
		// }

		.address {
			display: flex;
			input[type='text'] {
				width: 270px;
			}

			button.btn {
				display: flex;
				justify-content: center;
				width: 120px;
				height: 48px;
				margin-left: 10px;
			}
		}
	}

	.type-file {
		max-width: 810px;

		.error {
			display: flex;
			span {
				font-size: 0.75rem;
				color: $PRIMARY_RED;
				margin-left: 6px;
			}
		}

		button.btn {
			display: flex;
			justify-content: center;
			width: 72px;
			height: 30px;
			margin-top: 15px;
		}

		.upload-box-title {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			p {
				font-size: 1rem;
				font-weight: 700;
			}
		}

		.upload-box {
			width: 100%;
			height: 120px;
			border: 2px dashed $FILE_BORDER;
			border-radius: 5px;
			margin: 15px 0px;
			position: relative;

			overflow-y: auto;
			cursor: pointer;
			margin-bottom: 10px;

			&.error {
				border: 2px dashed $PRIMARY_RED;
			}
			input {
				width: 100%;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				padding: 0 0;
				cursor: pointer;
				&.isFile {
					visibility: hidden;
				}
			}
			.file-box {
				text-align: center;
				width: 100%;
				z-index: 2;
				// position: relative;
				padding-top: 26px;
				.file-box-text {
					font-size: 14px;
					font-weight: 400;
					color: $COLOR_111;
				}
				.btn-wrap {
					width: 100%;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					button {
						margin-top: 10px;
						cursor: pointer;
					}
				}
			}
			.file-list {
				width: 100%;
				padding: 14px 10px;
				height: 100%;
				// position: relative;
				z-index: 2;
				li {
					width: 100%;
					height: 30px;
					display: flex;
					align-items: center;
					font-size: 0.875rem;
					font-weight: 400;
					img {
						margin-right: 2px;
					}
					button {
						position: relative;
					}
				}
			}
		}
	}

	.next-button-wrapper {
		margin-top: 30px;
		button {
			width: 100%;
			height: 50px;
			display: flex;
			justify-content: center;
		}
	}

	.footer-wrapper {
		margin-top: 35px;
		span {
			color: $PRIMARY_BLUE;
			font-weight: 500;
			text-decoration-line: underline;
			cursor: pointer;
		}
	}
}
</style>
