<template>
	<div class="AppHeader__menu-list">
		<div v-for="(item, index) in itemList" :key="index" @click="getItemEvent(item)">
			<item :text="item.itemName"></item>
		</div>
		<modal-account-setting ref="modal-account-setting"></modal-account-setting>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import { MENU_ITEM } from '@/utils/define/Constant'
import AppHeaderMenuItemVue from '@/views/app/components/AppHeaderMenu/components/AppHeaderMenuItem.vue'
import ModalAccountSetting from '@/views/app/modals/ModalAccountSetting.vue'
import { logout } from '@/services/api/common'
import axiosInstance from '@/services/axios'

export default {
	name: 'AppHeaderMenuList',
	components: {
		item: AppHeaderMenuItemVue,
		ModalAccountSetting,
	},
	data() {
		return {
			itemList: [
				{
					id: MENU_ITEM.LOGOUT,
					itemName: '로그아웃',
					clickEvent: () => {
						logout()
						axiosInstance.setAuth()
						location.href = '/'
					},
				},
			],
		}
	},
	computed: {},
	methods: {
		...mapMutations('modal', ['setAccountSetting']),
		getItemEvent(itemList) {
			return itemList.clickEvent()
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__menu-list {
	width: 198px;
	position: absolute;
	top: 55px;
	right: 30px;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px $COLOR_CCC;
	background-color: $PRIMARY_WHITE;
	border-radius: 5px;
	padding: 5px 0px;
	cursor: default;
	color: $PRIMARY_BLACK;

	.AppHeader__menu-divider {
		position: relative;
		width: 200px;
		border: 0;
		height: 1px;
		left: 0px;
		margin-top: 5px;
		margin-bottom: 5px;
		background: #efeff4;
	}
	.sub-menu-wrap {
		position: relative;
		button {
			padding: 0 5px;
			box-sizing: border-box;
			width: calc(100% - 10px);
			margin: 0 5px;
			height: 32px;
			background-image: url(~@/assets/svg/app/arrow-right-edge.svg);
			background-repeat: no-repeat;
			background-position: center right 10px;
			font-size: 0.875rem;
			color: $PRIMARY_BLACK;
			font-weight: 400;
			transition: 0.3s;
			border-radius: 5px;
			&:hover {
				background-color: $COLOR_F2;
			}
		}
		ul {
			position: absolute;
			width: 200px;
			border: 1px solid $COLOR_CCC;
			border-radius: 5px;
			padding: 5px;
			right: 196px;
			top: -6px;
			text-align: left;
			background-color: #fff;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
			li {
				font-size: 0.875rem;
				font-weight: 400;
				color: $PRIMARY_BLACK;
				height: 30px;
				border-radius: 5px;
				transition: 0.3s;
				padding: 5px 10px;
				display: flex;
				align-items: center;
				cursor: pointer;
				box-sizing: border-box;
				&:hover {
					background-color: $COLOR_F2;
				}
			}
		}
	}
}
</style>
