<template>
	<b-dropdown variant="transparent" menu-class="option-menu" no-caret>
		<template v-slot:button-content>
			<div class="select-box">
				<span>{{ value }}</span>
				<img src="@/assets/svg/common/dropdown.svg" />
			</div>
		</template>
		<b-dropdown-item v-for="(item, index) of items" :key="index" :disabled="disabledFunc(item)" @click="onClickItem(item)">
			{{ item }}
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
export default {
	name: 'SelectInput',
	props: {
		value: {
			type: String,
			default: '',
		},
		items: {
			type: Array,
			default: () => [],
		},
		disabledFunc: {
			type: Function,
			default: () => {},
		},
	},
	methods: {
		onClickItem(value) {
			this.$emit('input', value)
			this.$emit('change', value)
		},
	},
}
</script>

<style lang="scss" scoped>
.select-box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 300px;
	height: 34px;
	padding: 10px;
	border: 1px solid $COLOR_EEE;
	border-radius: 4px;
	font-size: 0.875rem;
	font-weight: 500;
}
::v-deep {
	.dropdown {
		margin-top: 5px;
	}
	.btn {
		background: transparent;
		height: unset;
		border: none;
		padding: 0px;
	}
	img {
		width: 12px;
	}
	ul {
		width: 100%;
		overflow-y: auto;
		max-height: 200px;
		padding: 5px;
		li {
			padding-left: 10px;
			font-size: 0.875rem;
			display: flex;
			align-items: center;
			width: 100%;
			height: 36px;
			cursor: pointer;
			transition: all 0.3s;
			&:hover {
				background-color: #ccc;
				border-radius: 4px;
			}
			a {
				display: flex;
				padding: 0px;
				width: 100%;
				height: 100%;
				align-items: center;
				&:hover {
					background-color: transparent;
				}
				&:active {
					color: #111;
				}
				b {
					margin-right: 10px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					max-width: 90px;
				}
				p {
					max-width: 100%;
					min-width: 90px;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}
		}
	}
}
</style>
