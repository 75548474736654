<template>
	<tr :class="{ disabled: rowDisabled }">
		<td class="check-box">
			<div class="checkbox">
				<input type="checkbox" :checked="checked" @change="$emit('check',item)" :disabled="checkboxDisabled" />
			</div>
		</td>
		<!-- 주문월 -->
		<td>{{ formatDatetime(item.createDatetime, 'yyyy-MM') }}</td>
		<!-- 결제 번호 -->
		<td>{{ isData(item.payment.no) }}</td>
		<!-- 주문번호 -->
		<td class="link" @click="goToOrderDetailPage">{{ item.orderNo }}</td>
		<!-- 주문 아이템 번호 -->
		<td class="link" @click="goToOrderItemDetailPage">{{ item.orderItemNo }}</td>
		<!-- 연구실 결제상태 -->
		<td :class="PaymentStatus.statusToClass(item.payment.status)">
			<b>{{ item.payment.status ? PaymentStatus.statusToString(item.payment.status) : '-' }}</b>
		</td>
		<td :class="PaymentStatus.statusToClass(item.payment.status)">
			<b>{{ item.payment.status === 'PAID' && item.priceWithVat ? `${commaNum(item.priceWithVat)}원` : '0원' }}</b>
		</td>
		<!-- 주문 금액(VAT 포함) -->
		<td>
			<b>{{ item.priceWithVat ? `${commaNum(item.priceWithVat)}원` : '-' }}</b>
		</td>
		<!-- 판매자 정산상태 -->
		<td>{{ isData(item.sellerSettleStatus) }}</td>
		<!-- 주문상태 -->
		<td>
			<div class="info" style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
				<b>{{ OrderStatusText(item).text }}</b>
				<p style="color: red">{{ OrderStatusText(item).subtext }}</p>
			</div>
		</td>
		<!-- 상품 정보 -->
		<td style="max-width: 220px">
			<div class="info" style="width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">
				<b>{{ item.product.name }}</b>
				<p>{{ item.product.subname }}</p>
			</div>
		</td>
		<!-- 출고수량 -->
		<td>{{ isData(item.quantity) }}</td>
		<!-- 매입가 -->
		<td>{{ item.salesUnitPrice && item.quantity ? `${commaNum(item.salesUnitPrice * item.quantity)}원` : '-' }}</td>
		<!-- 소비자가 -->
		<td>{{ item.product.unitPrice && item.quantity ? `${commaNum(item.product.unitPrice * item.quantity)}원` : '-' }}</td>
		<!-- 주문 금액 -->
		<td style="min-width: 180px">
			<div style="display: flex; justify-content: space-between; align-items: center">
				<div>
					<b>
						{{ item.price ? `${commaNum(item.price)}원` : '-' }}
					</b>
				</div>
				<div>
					<button
						style="
							width: 46px;
							height: 30px;
							background-color: #fff3d6;
							border-radius: 4px;
							color: #faa400;
							font-size: 0.875rem;
							font-weight: 700;
						"
						@click="isShowPriceModal = true"
						:disabled="rowDisabled"
					>
						변경
					</button>
				</div>
			</div>
		</td>
		<!-- VAT 금액 -->
		<td>{{ item.vat ? `${commaNum(item.vat)}원` : '-' }}</td>
		<!-- 이익 -->
		<td>{{ item.profit ? `${commaNum(item.profit)}원` : '-' }}</td>
		<!-- 이익률 -->
		<td>{{ isData(item.profitRatio) }}</td>
		<!-- 결제방법 -->
		<td>{{ item.paymentMethod ? PaymentMethod.statusToString(item.paymentMethod) : '-' }}</td>
		<!-- 결제일시 -->
		<td>{{ formatDatetime(item.payment.createDatetime) }}</td>
		<!-- 입금일시 -->
		<td>{{ formatDatetime(item.payment.paymentDatetime) }}</td>
		<!-- 카드 승인번호 -->
		<td>{{ isData(item.payment.payAuthCode) }}</td>
		<!-- 국세청 승인번호 -->
		<td>{{ isData(item.payment.ntsNum) }}</td>
		<!-- 업체정보 -->
		<td>{{ isData(item.product.seller.name) }}</td>
		<!-- 유통사 -->
		<td>{{ isData(item.product.distributor.name) }}</td>
		<!-- 브랜드 -->
		<td>{{ isData(item.product.brand.name) }}</td>
		<!-- 제품번호 -->
		<td>{{ isData(item.product.sku) }}</td>
		<!-- 상품옵션 -->
		<td>{{ isData(item.product.options[0].value) }}</td>
		<!-- 주문 연구실 -->
		<td>{{ isData(item.institute.name) }}</td>
		<!-- 주문 연구실 ID -->
		<td>{{ isData(item.institute.id) }}</td>
		<!-- 주문자 -->
		<td>{{ isData(item.orderer.name) }}</td>
		<!-- 주문자 연락처 -->
		<td>{{ isData(Validation.autoHypenTel(item.orderer.phone)) }}</td>
		<!-- 주소 -->
		<td>{{ item.address && item.addressDetail ? `${item.address} ${item.addressDetail}` : '-' }}</td>
		<!-- 재고 확인일 -->
		<td>{{ formatDatetime(item.approveDatetime) }}</td>
		<!-- 배송시작일 -->
		<td>{{ formatDatetime(item.releaseDatetime) }}</td>
		<!-- 배송완료일 -->
		<td>{{ formatDatetime(item.shippingDatetime) }}</td>
		<!-- 취소요청일 -->
		<td>{{ formatDatetime(item.cancelRequestDatetime) }}</td>
		<!-- 주문취소일 -->
		<td>{{ formatDatetime(item.cancelDatetime) }}</td>
		<!-- 주문 확정일 -->
		<td>{{ formatDatetime(item.confirmDatetime) }}</td>
		<!-- 주문일시 -->
		<td>{{ formatDatetime(item.createDatetime) }}</td>

		<modal-update-price :item="item" mode="payment" :isShow="isShowPriceModal" @hide="isShowPriceModal = false" @complete="$emit('refresh')" />
	</tr>
</template>

<script>
import mixin from '@/mixins'
import PageName from '@/utils/define/PageName'
import Validation from '@/utils/Validation.js'
import { PaymentStatus, PaymentMethod, OrderStatusText } from '@/utils/define/ItemCode'
import ModalUpdatePrice from '@/views/order/orderProduct/modals/ModalUpdatePrice.vue'

export default {
	name: 'PaymentManagementTableItem',
	mixins: [mixin],
	components: {
		ModalUpdatePrice,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
		checked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			Validation,
			PaymentStatus,
			PaymentMethod,
			OrderStatusText,
			isShowPriceModal: false,
		}
	},
	computed: {
		checkboxDisabled() {
			if (this.item.returnStatus === 'DONE' || this.item.cancelStatus === 'DONE') return true
			if (this.item.payment.status === 'PAID') return true
			return false
		},
		rowDisabled() {
			return this.item.returnStatus === 'DONE' || this.item.cancelStatus === 'DONE'
		},
	},
	methods: {
		goToOrderDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: this.item.orderId,
				},
			})
		},
		goToOrderItemDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: this.item.orderItemId,
				},
			})
		},
		formatDatetime(isoDatetime, format = 'yyyy-MM-dd HH:mm') {
			if (!isoDatetime) return '-'
			return this.$DateTime.fromISO(isoDatetime).toFormat(format)
		},
	},
}
</script>

<style lang="scss" scoped></style>
