<template>
	<div class="input-textarea-wrap">
		<base-item :title="title">
			<textarea
				:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
				v-model="inputText"
				:placeholder="placeholder"
				:disabled="isReadOnly"
				@input="changeValue"
			></textarea>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputTextArea',
	components: {
		BaseItem,
	},
	props: {
		value: {
			type: [String, Number, Object, Date],
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		inputWidthSize: {
			type: Number,
			default: 500,
		},
		inputHeightSize: {
			type: Number,
			default: 60,
		},
		inputValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputText: '',
		}
	},
	watch: {
		value() {
			this.inputText = this.value
		},
	},
	mounted() {
		this.inputText = this.value
	},
	computed: {
		resultValue() {
			if (this.inputText !== '') return this.inputText
			else return this.inputValue
		},
	},
	methods: {
		changeValue(event) {
			event.target.value = event.target.value.trimLeft()
			this.$emit('changeValue', event.target.value.trim())
			this.$emit('input', event.target.value.trim())
		},
		setValue(value) {
			this.inputText = value
		},
	},
}
</script>

<style lang="scss" scoped>
.input-textarea-wrap {
	textarea {
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;
		padding: 10px;
		&:disabled {
			background-color: $LIST_HOVER;
		}
	}
}
</style>
