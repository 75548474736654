<template>
	<div class="Toast__container" :class="[type]">
		<div class="Toast__inner-container">
			<div class="Toast__indicator-wrapper">
				<img v-if="isTypeSuccess" class="Toast__indicator" src="@/assets/svg/common/IconSuccess.svg" />
				<img v-else-if="isTypeWarning" class="Toast__indicator" src="@/assets/svg/common/IconWarning.svg" />
				<img v-else-if="isTypeError" class="Toast__indicator" src="@/assets/svg/common/IconError.svg" />
				<img v-else class="Toast__indicator" src="@/assets/svg/common/IconInfo.svg" />
			</div>
			<div class="Toast__text-container">
				<div class="Toast__title">{{ title }}</div>
				<div class="Toast__content" v-html="content"></div>
			</div>
			<div class="Toast__close-container" @click="clickCloseToast">
				<img class="icon-close" src="@/assets/svg/common/IconClose.svg" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	props: {
		type: {
			type: String,
		},
		title: {
			type: String,
		},
		content: {
			type: String,
		},
		toastId: {
			type: String,
		},
	},
	computed: {
		isTypeSuccess() {
			return this.type === ToastType.SUCCESS
		},
		isTypeError() {
			return this.type === ToastType.ERROR
		},
		isTypeWarning() {
			return this.type === ToastType.WARNING
		},
		isTypeInfo() {
			return this.type === ToastType.INFO
		},
	},
	methods: {
		...mapMutations('toast', ['closeToast']),
		clickCloseToast() {
			this.closeToast(this.toastId)
		},
	},
}
</script>

<style lang="scss" scoped>
$initPosition: -360px;
$showPosition: $initPosition + 390px;

.Toast__container {
	display: flex;
	position: relative;
	right: $initPosition;
	border-radius: 5px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
	padding: 7px;
	margin-bottom: 10px;
	width: 320px;
	animation-name: slide;
	animation-duration: 4s;
	z-index: 99999;

	&.success {
		background: linear-gradient(0deg, rgba(13, 197, 119, 0.08), rgba(13, 197, 119, 0.08)), $PRIMARY_WHITE;
	}

	&.error {
		background: linear-gradient(0deg, rgba(255, 87, 70, 0.1), rgba(255, 87, 70, 0.1)), $PRIMARY_WHITE;
	}

	&.warning {
		background: linear-gradient(0deg, rgba(255, 176, 0, 0.1), rgba(255, 176, 0, 0.1)), $PRIMARY_WHITE;
	}

	&.info {
		background: linear-gradient(0deg, rgba(11, 108, 255, 0.1), rgba(11, 108, 255, 0.1)), $PRIMARY_WHITE;
	}

	@keyframes slide {
		from {
			right: $initPosition;
			opacity: 0;
		}
		10% {
			right: $showPosition;
			opacity: 1;
		}
		90% {
			right: $showPosition;
			opacity: 1;
		}
		to {
			right: $initPosition;
			opacity: 0;
		}
	}
}

.Toast__indicator-wrapper {
	display: flex;
	width: 24px;
	height: 46px;
	margin: 0px 12px 0px 7px;
	align-items: center;
	img {
		width: inherit;
		height: 24px;
		display: flex;
		align-items: center;
		object-fit: contain;
	}
}

.Toast__inner-container {
	display: flex;
	width: 100%;
	height: 100%;
}

.Toast__text-container {
	display: flex;
	flex-direction: column;
	width: 240px;
	height: 100%;
	margin-top: 5px;
}

.Toast__close-container {
	display: flex;
	align-items: flex-start;
	margin-top: 2px;
	.icon-close {
		cursor: pointer;
	}
}

.Toast__title {
	display: inline-block;
	width: 240px;
	height: 13px;
	text-align: left;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 7px;
}

.Toast__content {
	font-weight: normal;
	text-align: left;
	font-size: 12px;
	color: rgba(51, 51, 51, 1);
	font-weight: 400;
}
</style>
