<template>
	<portal to="lm-modal" v-if="isShow">
		<div>
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-lg">
				<div class="Popup__top">
					<h5 class="Popup__title">상세설명</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="content-wrapper">
						<p v-html="text" />
						<div class="image-wrapper">
							상품 상세 이미지
							<div v-for="(image, index) in images" :key="`image_${index}`">
								<img :src="image" />
							</div>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="hide">닫기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalDetailDescription',
	mixins: [MixinModal],
	data() {
		return {
			images: [],
			text: '',
		}
	},
	methods: {
		async show(text, images) {
			this.isShow = true
			this.text = text

			this.images = []
			images.forEach(image => {
				this.images.push(image.url)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__container {
	height: 800px;
	.Popup__top {
		height: 62px;
	}
	.Popup__content {
		width: 100%;
		height: 650px;
		.content-wrapper {
			height: 100%;
			border: 1px solid $COLOR_DDD;
			border-radius: 5px;
			overflow-y: scroll;
			padding: 10px;
		}
		.image-wrapper {
			padding-top: 15px;
			font-weight: 700;
			font-size: 18px;
			border: none;
			text-align: center;
			div {
				padding-top: 10px;
			}
			img {
				width: 100%;
			}
		}
	}
}
</style>
