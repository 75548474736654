<template>
	<div class="input-form-group">
		<div class="title" v-if="title">
			<h4>{{ title }}</h4>
			<p v-if="content">{{ content }}</p>
		</div>
		<div class="btn-wrap" v-if="btn">
			<button class="btn" @click="$emit('clickEv')">
				<img v-if="btn.image" :src="btn.image" />
				{{ btn.text }}
			</button>
		</div>
		<ul class="input-forms">
			<li v-for="(item, index) in list" :key="`input_key${index}`">
				<b>{{ item.name }}</b>
				<input
					type="text"
					class="common"
					v-model="info[item.key]"
					:placeholder="setPlaceholder(item.name, formCategory)"
					:disabled="disabled"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'InputFormGroup',
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		btn: {
			type: Object,
			default: () => {},
		},
		list: {
			type: Array,
			default: () => [],
		},
		info: {
			type: Object,
			default: () => {},
		},
		formCategory: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [mixin],
	methods: {
		setPlaceholder(title) {
			let text = title
			if (this.formCategory == 'assignee') {
				text = '담당자 ' + text
			} else if (this.formCategory == 'recipient') {
				text = '수신자 ' + text
			}
			return `${text}${this.checkEnding(text)} 입력해주세요`
		},
	},
}
</script>

<style lang="scss" scoped>
.input-form-group {
	width: calc(100% - 60px);
	margin: 0 auto;
	border-bottom: 1px solid $LINE_DIVIDER;
	.title {
		display: flex;
		align-items: center;
		h4 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		p {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_666;
			margin-left: 1rem;
		}
	}
	.btn-wrap {
		margin-top: 1rem;
	}
	.input-forms {
		width: 100%;
		display: block;
		text-align: left;
		padding: 8px 0;
		li {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 8px 0;
			b {
				font-size: 0.875rem;
				font-weight: 700;
				width: 200px;
			}
			input[type='text'] {
				width: 400px;
				height: 40px;
				border: 1px solid $LINE_DIVIDER;
				border-radius: 4px;
				padding: 0 10px;
				font-weight: 400;
				font-size: 0.875rem;
			}
		}
	}
}
</style>
