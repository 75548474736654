<template>
	<tr class="table-header">
		<th>
			<span>번호</span>
		</th>
		<th>
			<span>로고 이미지</span>
		</th>
		<th>
			<span>판매자 이름</span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>담당자</span>
		</th>
		<th>
			<span>연락처</span>
		</th>
		<th>
			<span>사업자등록번호</span>
		</th>
		<th>
			<span>주소지</span>
		</th>
		<th>
			<span>판매상품 수</span>
		</th>
		<th>
			<span>누적 판매금액</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>등록일시</span>
		</th>
	</tr>
</template>

<script>
import MixinHeader from '@/mixins/table/header'

export default {
	computed: {},
	mixins: [MixinHeader],
	data() {
		return {
			checked: false,
		}
	},
	watch: {},
	methods: {},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
