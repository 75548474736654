<template>
	<div class="AccountList">
		<div class="title-wrapper">
			<h5>계정</h5>
		</div>
		<div class="table-wrapper">
			<account-table />
		</div>
		<pagination :total="asccountList.count" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import AccountTable from './Table.vue'
export default {
	name: 'AccountList',
	components: {
		Pagination,
		AccountTable,
	},
	created() {
		// this.getList()
	},
	computed: {
		...mapState('seller/detail', ['asccountList']),
	},
	methods: {
		pagination(page) {
			this.setLoadParams(page)
		},
	},
}
</script>

<style lang="scss" scoped>
.AccountList {
	// height: calc(100% - 161px);
	.title-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 8px;
		h5 {
			font-weight: 700;
			font-size: 20px;
		}
	}
	.table-wrapper {
		border: solid 1px $LINE_DIVIDER;
	}
}
</style>
