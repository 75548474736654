import orderAPI from '@/services/api/order'
import { refineOrderListTableData } from '@/store/modules/dataRefiner'

const orderManagement = {
	namespaced: true,
	state: {
		orderList: [],
		orderBulkList: [],
		orderListSummary: {},
		orderListFilter: {},
		orderDetail: {},
		orderItemDetail: {},
		orderItemGroup: {},

		loadParams: {
			keyword: null,
			status: 'ALL',
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		list: [],
		count: 0,
		orderItem: {},
		scrollTop: 0,
	},
	getters: {
		getOrderList: state => state.orderList,
		getOrderListTableData: state => refineOrderListTableData(state, 'orderList'),

		getOrderListSummary: state => state.orderListSummary,
		getOrderListFilter: state => state.orderListFilter,
		getOrderDetail: state => state.orderDetail,
		getOrderItemDetail: state => state.orderItemDetail,
		getOrderItemGroup: state => state.orderItemGroup,
		getOrderItem: state => state.orderItem,
	},
	mutations: {
		setOrderList: (state, payload) => {
			state.orderList = payload
		},
		setOrderBulkList: (state, payload) => {
			state.orderList = payload
		},
		setOrderListSummary: (state, payload) => {
			state.orderListSummary = payload
		},
		setOrderListFilter: (state, payload) => {
			state.orderListFilter = payload
		},
		setOrderDetail: (state, payload) => {
			state.orderDetail = payload
		},
		setOrderItem: (state, payload) => {
			state.orderItem = payload
		},
		setOrderItemGroup: (state, payload) => {
			state.orderItemGroup = payload
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		lookupOrderList: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderList(params)
			commit('setOrderList', response.data)
		},
		lookupOrderBulkList: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderBulkList(params)
			commit('setOrderBulkList', response.data)
		},
		lookupOrderListSummary: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderListSummary(params)
			commit('setOrderListSummary', response.data)
		},
		lookupOrderListFilter: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderListFilter(params)
			commit('setOrderListFilter', response.data)
		},
		lookupOrderDetail: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderDetail(params)
			commit('setOrderDetail', response.data)
		},
		lookupOrderItem: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderItem(params)
			commit('setOrderItem', response.data)
		},
		lookupOrderItemGroup: async ({ commit }, params) => {
			const response = await orderAPI.lookupOrderItemGroup(params)
			commit('setOrderItemGroup', response.data)
		},
		downloadOrderList: async ({ commit }, params) => {
			return await orderAPI.downloadOrderList(params)
		},
		setOrderItemMemo: async ({ commit }, payload) => {
			const { body } = payload
			return await orderAPI.setOrderItemMemo(body)
		},
		setAdminMemo: async ({ commit }, payload) => {
			const { body } = payload
			return await orderAPI.setAdminMemo(body)
		},
		changeEstimatedDate: async ({ commit }, payload) => {
			const { body } = payload
			return await orderAPI.changeEstimatedDate(body)
		},
		changeEstimatedDateBulk: async ({ commit }, payload) => {
			const { body } = payload
			return await orderAPI.changeEstimatedDateBulk(body)
		},
		approveOrder: async ({ commit }, payload) => {
			return await orderAPI.approveOrder(payload)
		},
		separationOrder: async ({ commit }, payload) => {
			const { body } = payload
			return await orderAPI.separationOrder(body)
		},
		startShipping: async ({ commit }, payload) => {
			return await orderAPI.startShipping(payload)
		},
		completeShipping: async ({ commit }, payload) => {
			return await orderAPI.completeShipping(payload)
		},
		updateShippingInfo: async ({ commit }, payload) => {
			return await orderAPI.updateShippingInfo(payload)
		},
		cancelOrder: async ({ commit }, payload) => {
			return await orderAPI.cancelOrder(payload)
		},
		cancelOrderRequestReject: async ({ commit }, payload) => {
			return await orderAPI.cancelOrderRequestReject(payload)
		},
		cancelOrderRequestApprove: async ({ commit }, payload) => {
			return await orderAPI.cancelOrderRequestApprove(payload)
		},
		printTradeStatement: async ({ commit }, params) => {
			return await orderAPI.printTradeStatement(params)
		},
		updateOrderItemAdminMemo: async ({ commit }, payload) => {
			return await orderAPI.updateOrderItemAdminMemo(payload)
		},
		updateOrderItemPrice: async ({ commit }, payload) => {
			return await orderAPI.updateOrderItemPrice(payload)
		},
		updateSalesPrice: async ({ commit }, payload) => {
			return await orderAPI.updateSalesPrice(payload)
		},
	},
}

export default orderManagement
