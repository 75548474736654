import axiosInstance from '@/services/axios'

const endPoint = {
	lookupBuyerList: () => 'admin/user/list',
	lookupBuyer: id => `admin/user?id=${id}`,
	exportExcel: body => 'admin/user/list/export/excel',
}

const api = {
	lookupBuyerList: params => axiosInstance.get(endPoint.lookupBuyerList(), { params }),
	lookupBuyer: id => axiosInstance.get(endPoint.lookupBuyer(id)),
	exportExcel: body =>
		axiosInstance.post(endPoint.exportExcel(), body, {
			responseType: 'blob',
		}),
}

export default api
