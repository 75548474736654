<template>
	<div class="consulting-wrapper">
		<div>
			<h5>고객상담 정보</h5>
			<base-item :title="'템플릿 선택'">
				<button class="btn template-btn" @click="$refs['modal-template-management'].show('등록')">템플릿 등록</button>
				<choice-list
					ref="choice-list"
					title="템플릿"
					emptyMessage="템플릿이 없습니다. 고객상담 템플릿을 등록해주세요"
					buttonText="고객상담 템플릿"
					:list="templateList"
					:tab.sync="selectConsultingTab"
					type="template"
					@changeValue="onChangeValue"
					@regist="$refs['modal-template-management'].show('등록')"
					@edit="value => $refs['modal-template-management'].show('수정', value)"
				></choice-list>
			</base-item>
		</div>

		<modal-template-management ref="modal-template-management" @refresh="fetchTemplateList"></modal-template-management>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ChoiceList from '@/views/common/components/formItem/ChoiceList.vue'
import InputAddress from '@/views/common/components/formItem/InputAddress.vue'
import InputCheckBox from '@/views/common/components/formItem/InputCheckBox.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputTextArea from '@/views/common/components/formItem/InputTextArea.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import ModalTemplateManagement from '@/views/seller/template/modals/ModalTemplateManagement.vue'

export default {
	name: 'ConsultingInfo',
	components: {
		ChoiceList,
		InputAddress,
		BaseItem,
		InputCheckBox,
		InputText,
		InputTextArea,
		ModalTemplateManagement,
	},
	data() {
		return {
			templateList: [],
			selectConsultingTab: null,
		}
	},
	computed: {
		...mapState('productRegist/regist', ['seller', 'consultingInfo']),
		...mapGetters('seller/list', ['getTemplateList']),
	},
	created() {
		this.fetchTemplateList()
	},
	methods: {
		...mapActions('seller/list', ['lookupTemplateList']),
		...mapMutations('productRegist/regist', ['setConsultingInfo']),
		async fetchTemplateList() {
			await this.lookupTemplateList({
				sellerId: this.seller.id
			})
			const response = this.$_.cloneDeep(this.getTemplateList)
			this.templateList = response.list
			if (!this.consultingInfo?.csId) {
				this.templateList.some(item => {
					if (item.isDefault) {
						this.selectConsultingTab = item
						this.setInfo('csId', item.id)
						return true
					} else {
						return false
					}
				})
			} else {
				let address = this.templateList.find(item => {
					return item.id === this.consultingInfo.csId
				})
				this.selectConsultingTab = address
			}
		},
		onChangeValue(value) {
			this.selectConsultingTab = value
			this.setInfo('csId', value.id)
		},
		setInfo(key, value) {
			const consultingInfo = this.$_.cloneDeep(this.consultingInfo)
			consultingInfo[key] = value
			this.setConsultingInfo(consultingInfo)
		},
		valid() {
			return true
		},
	},
}
</script>

<style lang="scss" scoped>
.consulting-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	h5 {
		text-align: left;
		font-weight: 700;
		padding: 25px 0;
	}

	.template-btn {
		width: 100px;
		height: 36px;
		display: flex;
		justify-content: center;
	}

	.template-btn + div {
		margin-top: 15px;
	}
}
</style>
