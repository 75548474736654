<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalChangePw Popup__depth2">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">비밀번호 변경</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="Popup__input-field" :class="pwError != null ? 'warning' : ''">
						<p>현재 비밀번호<span class="required">*</span></p>
						<input type="password" placeholde="현재 비밀번호를 입력해주세요" v-model="currentPw" @input="pwError = null" />
						<span v-if="pwError" v-text="pwError"></span>
					</div>
					<div class="Popup__input-field mt-20" :class="newError != null ? 'warning' : ''">
						<p>새로운 비밀번호<span class="required">*</span></p>
						<input type="password" placehold="새로운 비밀번호를 입력해주세요" v-model="newPw" @input="newError = null" />
						<span v-if="newError" v-text="newError"></span>
					</div>
					<div class="Popup__input-field mt-20" :class="confirmError != null ? 'warning' : ''">
						<p>새로운 비밀번호 확인<span class="required">*</span></p>
						<input
							type="password"
							placeholder="새로운 비밀번호를 한번 더 입력해 주세요"
							v-model="newPwConfirm"
							@input="confirmError = null"
						/>
						<span v-if="confirmError" v-text="confirmError"></span>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="close">
							취소
						</button>
						<button class="Btn__complete" @click="submit">
							완료
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalChangePw',
	mixins: [MixinModal],
	props: {},
	data() {
		return {
			currentPw: null,
			pwError: null,
			newPw: null,
			newError: null,
			newPwConfirm: null,
			confirmError: null,
		}
	},
	watch: {
		currentPw(val) {
			if (val.length == 0) {
				this.pwError = null
			}
		},
		newPw(val) {
			if (val.length == 0) {
				this.newError = null
			}
		},
		newPwConfirm(val) {
			if (val.length == 0) {
				this.confirmError = null
			}
		},
	},
	methods: {
		show() {
			this.isShow = true
			this.currentPw = null
			this.newPw = null
			this.pwError = null
			this.newError = null
			this.confirmError = null
		},
		close() {
			this.hide()
		},
		submit() {
			this.validationPwError()
			this.validationNewError()
			this.validationConfirmError()

			if (this.pwError == null && this.newError == null && this.confirmError == null) {
				this.$emit('submit', this.currentPw, this.newPw)
			}
		},
		validationPwError() {
			if (this.currentPw == null) {
				this.pwError = '현재 비밀번호를 입력해주세요'
			} else if (this.currentPw.length < 6) {
				this.pwError = '6자리 이상 입력해주세요'
			} else {
				this.pwError = null
			}
		},
		validationNewError() {
			if (this.newPw == null) {
				this.newError = '새로운 비밀번호를 입력해주세요'
			} else if (this.newPw.length < 6) {
				this.newError = '6자리 이상 입력해주세요'
			} else if (this.currentPw == this.newPw) {
				this.newError = '현재 비밀번호와 새로운 비밀번호가 같습니다'
			} else {
				this.newError = null
			}
		},
		validationConfirmError() {
			if (this.newPwConfirm == null) {
				this.confirmError = '새로운 비밀번호를 한 번 더 입력해주세요'
			} else if (this.newPwConfirm.length < 6) {
				this.confirmError = '6자리 이상 입력해주세요. '
			} else if (this.newPw !== this.newPwConfirm) {
				this.confirmError = '비밀번호가 일치하지 않습니다'
			} else {
				this.confirmError = null
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__input-field {
	> span {
		display: block;
		width: 100%;
		padding-left: 16px;
		background-image: url(~@/assets/svg/app/side-warning.svg);
		background-repeat: no-repeat;
		background-size: 12px;
		font-size: 0.75rem;
		background-position: center left 0;
		margin-top: 2px;
		color: $PRIMARY_RED;
		margin-bottom: -14px;
	}
}
</style>
