<template>
	<portal to="lm-modal">
		<div class="Popup__depth3" v-if="isShow">
			<div class="Popup__background" @click="routeProductList"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">상품 등록 완료!</h5>
					<p>
						랩매니저 스토어에서 상품을 판매할 수 있으며, <br />
						등록이 완료된 상품은 상품관리에서 확인할 수 있습니다.
					</p>
				</div>
				<div class="Popup__content">
					<img src="@/assets/images/registration/check.png" />
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button v-if="isRequest" class="Btn__complete" @click="addRequestProduct">요청상품 추가하기</button>
						<button v-else class="Btn__complete" @click="routeProductList">상품 관리</button>
						<button class="Btn__grey" @click="routeProductRegistration">상품 더 등록하기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import PageName from '@/utils/define/PageName'
export default {
	name: 'ModalSuccessPropose',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		registeredProductId: {
			type: Number,
			default: 0,
		},
		isRequest: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		hide() {
			this.$emit('hide')
		},
		routeProductDetail() {
			this.hide()
			// TODO: 상품 목록 상세 - 현재 화면 미개발
			// this.$router.replace({
			// 	name: PageName.Product.ProductManagementDetail,
			// 	params: {
			// 		id: this.registeredProductId,
			// 	},
			// })
		},
		routeProductList() {
			this.hide()
			this.$router.replace({
				name: PageName.Product.ProductList,
				params: {
					id: this.registeredProductId,
				},
			})
		},
		routeProductRegistration() {
			this.hide()
			window.location.reload()
		},
		addRequestProduct() {
			opener.postMessage(eval(`({"type":"productRegistration", "data": {"productInfoId": "${this.registeredProductId}"} })`))
			window.close()
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__container {
	width: 500px;
	height: 603px;
	border-radius: 20px;
	.Popup__top {
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		margin-bottom: 15px;
		padding: 50px 20px;
		.Popup__title {
			font-size: 32px;
		}
		p {
			font-size: 16px;
		}
	}
	.Popup__content {
		margin-bottom: 52px;
		overflow: hidden;
	}
	.Popup__bottom {
		justify-content: center;
		position: relative;
	}
	.Popup__btn-group {
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.Btn__complete,
		.Btn__grey {
			width: 200px;
			height: 47px;
		}
		.Btn__grey {
			background-color: $COLOR_F5;
		}
	}
}
</style>
