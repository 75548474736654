<template>
	<div class="input-number-with-unit" :class="[{ readonly: readonly }, { error: error }]">
		<input type="number" v-model="formValue" @input="$emit('input', formValue, index)" :readonly="readonly" />
		<b v-if="unit">{{ unit }}</b>
	</div>
</template>

<script>
import mixin from '@/mixins'

export default {
	name: 'InputNumberWithUnit',
	props: {
		value: {
			type: [Number, String],
			default: 0,
		},
		unit: {
			type: String,
			default: '',
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		index: {
			type: Number,
			default: 0,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [mixin],
	mounted() {
		this.formValue = this.value
	},
	watch: {
		value(val) {
			this.formValue = val
		},
	},
	data() {
		return {
			formValue: 0,
		}
	},
	methods: {},
}
</script>

<style lang="scss" scoped>
.input-number-with-unit {
	border-radius: 5px;
	border: 1px solid $COLOR_DDD;
	height: 36px;
	width: 140px;
	font-size: 0.875rem;
	font-weight: 700;
	display: inline-flex;
	align-items: center;
	background-color: white;
	position: relative;
	color: black;
	overflow: hidden;
	&.readonly {
		border-color: $HOVER_BACKGROUND;
		background-color: $HOVER_BACKGROUND;
		color: $FONT_DISABLED;
	}
	&.error {
		border-color: $PRIMARY_RED;
	}
	input[type='number'] {
		border: 0;
		width: 100%;
		text-align: center;
		font-weight: 700;
		background-color: inherit;
		color: inherit;
		height: 100%;
	}
	b {
		font-size: 0.875rem;
		display: inline-block;
		font-weight: 500;
		color: $COLOR_999;
		position: absolute;
		top: calc(50% - 10px);
		right: 8px;
	}
}
</style>
