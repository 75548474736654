<template>
	<tr class="table-item" style="cursor: default" @click="goToDetail">
		<td>
			<div>
				<span>
					{{ item.id }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status', item.status]"> {{ item.status }} </span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; max-width: 250px" v-b-tooltip.hover="item.productName">
				<span>
					{{ item.productName }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.productMaker ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.productCode ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.productCasno ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.productVolume ?? '-' }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ $DateTime.fromISO(item.create_datetime, { setZone: true }).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					<a href="#" v-if="item.file" @click.stop="msdsPopup"> MSDS </a>
					<p v-else>-</p>
				</span>
			</div>
		</td>
		<td>
			<div v-b-tooltip.hover="comment">
				<span>
					{{ item.comment ?? '- ' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.itemId ?? '-' }}
				</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	computed: {
		comment() {
			switch (this.item.comment) {
				case 'A':
					return '[중복요청] 조회가능한 제품을 등록 요청'
				case 'B':
					return '[정보불일치] 신규 화학제품 용량 부정확'
				case 'C':
					return '[정보불일치] 신규 화학제품 정보 부정확(카탈로그번호, 제조사, 제품명)'
				case 'Z':
					return '[확인필요] 그 외 사유'
				default:
					return this.item.comment ?? '-'
			}
		},
	},
	methods: {
		msdsPopup() {
			window.open(
				'https://msds.lab-manager.com/v/u?url=' + encodeURIComponent(this.item.file),
				'_blank',
				'width=800, height=1000, left=300, top=50'
			)
			return false
		},
		goToDetail() {
			this.$router.push({
				name: PageName.ChemicalAPI.RequestDetail,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
