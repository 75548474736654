<template>
	<div class="header-wrapper">
		<h2 class="header-title">상품 가격 일괄 업데이트</h2>
		<div class="upload-button-wrapper">
			<div class="upload-button-wrapper-left"></div>
			<div class="upload-button-wrapper-right">
				<image-button text="파일 업로드" :image="require('@/assets/svg/common/add-product.svg')" @click="openFileUploadModal" />
			</div>
		</div>
		<modal-upload-file ref="modal-upload-file" />
	</div>
</template>

<script>
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import ModalUploadFile from '../modals/ModalUplaodFile.vue'
import { mapState, mapMutations } from 'vuex'
import { updateProductPriceTableAllColumns, updateProductPriceTableRequireColumns } from '@/utils/define/table/updateProductPriceTable'

export default {
	components: {
		ImageButton,
		ModalUploadFile,
	},
	data() {
		return {
			allColumns: updateProductPriceTableAllColumns,
			requiredColumns: updateProductPriceTableRequireColumns,
		}
	},
	methods: {
		...mapMutations('updateProductPrice/list', ['setVisibleColumn']),
		openFileUploadModal() {
			this.$refs['modal-upload-file'].show()
		},
	},
}
</script>
<style lang="scss" scoped>
.header-wrapper {
	position: sticky;
	background-color: white;
	top: 0;
	display: flex;
	flex-direction: column;
	// width: calc(100% - 250px);
	// height: 158px;
	z-index: 100;
	border-bottom: 1px solid #eee; // $COLOR_EEE
	.header-title {
		text-align: left;
		font-weight: 700;
		font-size: 24px;
		padding: 10px 30px 5px 30px;
		height: 50px;
	}
	.upload-button-wrapper {
		display: flex;
		width: 100%;
		min-width: 1200px;
		height: 54px;
		align-items: center;
		justify-content: space-between;
		padding: 12px 30px;
		.upload-button-wrapper-left {
			display: inherit;
			gap: 10px;
			.search-box {
				width: 300px;
			}
		}
		.upload-button-wrapper-right {
			display: inherit;
			gap: 10px;
		}
	}
	.status-btn-wrapper {
		display: flex;
		width: 100%;
		height: 54px;
		align-items: center;
		padding: 12px 30px;
		border-top: 1px solid #eee; // $COLOR_EEE
		gap: 8px;
	}
}
</style>
