<template>
	<div class="search-title">
		<div class="title-wrapper">
			<span class="title">{{ title }}</span>
			<span class="sub-title">{{ subTitle }}</span>
		</div>
		<div v-if="useFold" class="button-wrapper">
			<span v-if="!isFold" @click="toggleFold">
				<img src="@/assets/svg/order/arrow-up.svg" />
			</span>
			<span v-if="isFold" @click="toggleFold">
				<img src="@/assets/svg/order/arrow-down.svg" />
			</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchTitle',
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
		useFold: {
			type: Boolean,
			default: false,
		},
		isFold: {
			type: Boolean,
			default: true,
		},
	},
	methods: {
		toggleFold() {
			this.$emit('toggleFold')
		},
	},
}
</script>

<style lang="scss" scoped>
.search-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	.title-wrapper {
		.title {
			font-weight: bold;
			font-size: 1rem;
		}
		.sub-title {
			font-size: 0.875rem;
			color: $COLOR_333;
			margin-left: 10px;
		}
	}
	.button-wrapper {
		span {
			cursor: pointer;
		}
	}
}
</style>
