import { CategoryToString, LargeCategory, MiddleCategory } from '@/utils/define/ItemCode'

export default {
	methods: {
		setOptions(info) {
			console.log(info.category.code)
		},
		keyToString(key) {
			switch (key) {
				case 'code':
					return '카테고리'
				case 'name':
					return '브랜드'
				case 'productCode':
					return '제품번호'
				case 'casno':
					return 'CAS NO'
			}
		},
		setItemInfo(item) {
			let list = []
			list.push({ title: '카테고리', value: CategoryToString(item.category?.code) })
			list.push({ title: '브랜드', value: item.brand?.name })
			list.push({ title: '제품번호', value: item.code })

			if (item.category?.code == MiddleCategory.REAGENT) {
				// 화학물질 / 시약 일 경우
				list.push({ title: 'CAS No', value: item.casno })
			} else if (item.category?.code == MiddleCategory.CHROMATOGRAPHY) {
				//분석용칼럼/분석용 칼럼 일 경우
				list.push({ title: '내경', value: item.innerDiameter ? `${item.innerDiameter}${item.innerDiameterUnit}` : '' })
				list.push({ title: 'Particle Size', value: item.particleSize ? `${item.particleSize}${item.particleSizeUnit}` : '' })
				list.push({ title: '길이', value: item.length ? `${item.length}${item.lengthUnit}` : '' })
				list.push({ title: 'Pore Size', value: item.poreSize ? `${item.poreSize}${item.poreSizeUnit}` : '' })
			}
			//연구장비의 경우 사이즈(용량) or 패키지 수량이 필요없기 때문에 제외
			if (item.category?.code != MiddleCategory.LABWARE) {
				if (item.categoryDisplayOptions) {
					list.push({ title: item.categoryDisplayOptions[0]?.name, value: item.categoryDisplayOptions[0]?.values[0] })
				} else if (item.options) {
					list.push({ title: item.options[0].name, value: item.options[0].value })
				} else if (item.option1) {
					list.push({ title: item.option1?.name, value: item.option1.value })
				}
			}
			//화학물질,분석용 칼럼 제외한 나머지 카테고리 [규격]
			if (item.category?.code != MiddleCategory.REAGENT && item.category?.code != MiddleCategory.CHROMATOGRAPHY) {
				list.push({ title: '규격', value: item.spec })
			}

			return list
		},
	},
}
