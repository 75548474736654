<template>
	<div class="upload-wrapper">
		<base-item title="팝업 이미지" class="image-upload-wrapper">
			<base-button size="md" @click="onClickAddImage">이미지 추가</base-button>
			<div class="subtext">
				<b>팝업 이미지</b>&nbsp;
				<span>480x680</span>
			</div>
			<div v-show="!hasFile" class="drag-area" :class="{ error: !isValidImage }">
				<input ref="image-file" multiple type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
				<h2>+</h2>
				<h5>이미지 업로드</h5>
				<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
			</div>
			<div class="upload-files-wrapper" v-show="hasFile">
				<div v-if="popupForm.image" class="image-box">
					<img :src="imageSrc" />
					<button class="close-btn" @click="deleteFile">
						<img src="@/assets/svg/icon-trash.svg" />
					</button>
				</div>
			</div>
			<div v-if="!isValidImage" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidImageText }}</span>
			</div>
			<div class="input-area">
				<input type="text" placeholder="링크 입력" v-model="link" :class="{ error: !isValidLink }" />
				<div v-if="isValidLink" class="link-sub">유저가 이미지를 클릭하였을 떄 이 링크 주소로 유저를 이동시킵니다.</div>
				<div v-else class="error">
					<img src="@/assets/svg/common/icon-error.svg" alt="error" />
					<span>{{ inValidLinkText }}</span>
				</div>
			</div>
		</base-item>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import InputBannerImage from '@/views/common/components/formItem/InputBannerImage.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	components: {
		InputText,
		BaseItem,
		InputBannerImage,
		BaseButton,
	},
	props: {
		isValidImage: {
			type: Boolean,
			default: true,
		},
		inValidImageText: {
			type: String,
			default: '',
		},
		isValidLink: {
			type: Boolean,
			default: true,
		},
		inValidLinkText: {
			type: String,
			default: '',
		},
		inputValueContent: {
			type: [String, File],
			default: '',
		},
		inputValueUrl: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			link: '',
			popupForm: {
				width: 480,
				height: 680,
				image: null,
			},
		}
	},
	watch: {
		popupForm: {
			deep: true,
			handler() {
				this.$emit('changeValue', this.popupForm)
			},
		},
		inputValueContent() {
			if (this.inputValueContent) this.popupForm.image = this.inputValueContent
		},
		inputValueUrl() {
			if (this.inputValueUrl) this.link = this.inputValueUrl
		},
		link: {
			handler() {
				this.$emit('changeLinkValue', this.link)
			},
		},
	},
	mounted() {
		if (this.inputValueContent) this.popupForm.image = this.inputValueContent
		if (this.inputValueUrl) this.link = this.inputValueUrl
	},
	computed: {
		hasFile() {
			return this.popupForm.image != null
		},
		imageSrc() {
			if (this.popupForm.image?.constructor === Object) return this.popupForm.image.url
			if (typeof this.popupForm.image == 'string') return this.popupForm.image
			if (this.popupForm.image) return URL.createObjectURL(this.popupForm.image)
			return null
		},
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['image-file'].files = event.dataTransfer.files
				this.uploadImage()
			}
		},
		setForm(file) {
			var reader = new FileReader()
			const data = this
			reader.onload = function (e) {
				var img = new Image()
				img.src = e.target.result
				img.onload = function () {
					var w = this.width
					var h = this.height
					if (w == 480 && h == 680) data.popupForm.image = file
					else data.$root.toast('에러', '480x680의 이미지를 업로드해주세요.', ToastType.ERROR)
				}
			}
			reader.readAsDataURL(file)
		},
		uploadImage() {
			const image = [...Object.values(this.$refs['image-file'].files)]
			if (image.length > 0) {
				image.forEach(it => this.setForm(it))
			}
			this.files = image
		},
		onClickAddImage() {
			this.$refs['image-file'].click()
		},
		deleteFile() {
			this.popupForm.image = null
			this.$refs['image-file'].value = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.image-upload-wrapper {
	.subtext {
		margin-top: 10px;
		font-size: 14px;
	}
	.drag-area {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		width: 480px;
		height: 680px;
		background: #f5f5f5;
		border: 1px solid #ddd;
		border-radius: 5px;
		& > * {
			color: #999;
		}
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
		&.error {
			border-color: $PRIMARY_RED;
		}
	}
	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
	.input-area {
		margin-top: 20px;
		input[type='text'] {
			width: 480px;
			height: 36px;
			padding: 8px 12px;
			border: 1px solid $FILE_BORDER;
			border-radius: 5px;

			&.error {
				border-color: $PRIMARY_RED;
			}
		}
		.link-sub {
			font-size: 12px;
			color: #999;
			margin-top: 10px;
		}
	}
	.size-guide-table {
		width: calc(50% - 5px);
		max-width: 500px;
		height: 240px;
		border: 1px solid #ddd;
		border-radius: 5px;
		table {
			width: 100%;
			height: 100%;
			th,
			td {
				width: 50%;
				height: calc(100% / 7);
				padding-left: 16px;
				font-size: 14px;
				&:first-child {
					border-right: 1px solid #ddd;
				}
			}
			tr {
				border-bottom: 1px solid #ddd;
				&:last-child {
					border-bottom: none;
				}
				&:first-child {
					background-color: #f5f5f5;
				}
			}
		}
	}
	.upload-files-wrapper {
		& > div {
			gap: 25px;
			display: flex;
			align-items: baseline;
		}
		.image-box {
			width: 480px;
			height: 680px;
			position: relative;
			border: 1px solid #ddd;
			border-radius: 5px;
			background-color: #f5f5f5;
			max-height: 1000px;
			text-align: center;
			img {
				border-radius: 5px;
				max-width: 100%;
				max-height: 100%;
			}
			.close-btn {
				left: 8px;
				top: 8px;
				position: absolute;
				width: 30px;
				height: 30px;
				background-color: #f5f5f5;
				border-radius: 4px;
				border: 1px solid #ddd;
			}
		}
	}
}
</style>
