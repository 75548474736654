<template>
	<portal to="lm-modal">
		<div class="MemoPopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-xs">
				<div class="Popup__top">
					<h6 class="Popup__title" style="font-size: 16px">수정할 금액<span style="font-weight: 400">을 입력해주세요.</span></h6>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<span class="subtitle">매입단가</span>
					<input type="number" v-model="salesUnitPrice" class="price" />
					<span class="won">원</span>
				</div>
				<div class="Popup__content">
					<span class="subtitle">소비자단가</span>
					<input type="number" v-model="unitPrice" class="price" />
					<span class="won">원</span>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__complete complete-btn" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>

export default {
	name: 'ModalUpdatePrice',
	watch: {
	},
	data() {
		return {
			isShow: false,
			productId: null,
			unitPrice: 0,
			salesUnitPrice: 0,
		}
	},
	methods: {
		show(productId, unitPrice, salesUnitPrice) {
			this.productId = productId
			this.unitPrice = unitPrice
			this.salesUnitPrice = salesUnitPrice
			this.isShow = true
		},
		hide() {
			this.isShow = false
			this.$emit('hide')
		},
		complete() {
			console.log("A")
			this.$emit('complete', this.productId, this.unitPrice, this.salesUnitPrice)
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
.MemoPopup {
	.subtitle {
		width: 30%;
		height: 36px;
		// border-radius: 5px;
		// border: solid 1px #ddd;
		font-size: 14px;
		// padding: 10px;
		display: inline-block;
	}
	.price {
		width: 70%;
		height: 36px;
		border-radius: 5px;
		border: solid 1px #ddd;
		font-size: 14px;
		padding: 10px;
	}
	.won {
		position: absolute;
		right: 40px;
		// top: 68px;
		padding-top: 7px;
		font-size: 14px;
		color: #000;
	}
	.Popup__bottom {
		padding: 0 10px 13px 10px;
		.Popup__complete-btn-group {
			margin: 0;
		}
		.Popup__btn-group {
			margin: 0;
		}
		.complete-btn {
			width: 315px;
			height: 40px;
		}
	}
}
</style>
