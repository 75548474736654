<template>
	<div class="date-picker jw-datepicker">
		<p v-if="name">{{ name }}:</p>
		<date-picker
			v-model="date"
			valueType="format"
			:range="isRange"
			:lang="lang"
			:placeholder="datePlaceholder"
			:clearable="false"
			:ref="`date-picker_${index}`"
			:editable="false"
			:style="datePickerStyle"
			@pick="changeDate"
		></date-picker>
	</div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { formatDate } from '@/utils/modules/DateUtils'

export default {
	name: 'jwDatePicker',
	components: {
		DatePicker,
	},
	props: {
		name: {
			type: String,
			default: '',
		},
		isRange: {
			type: Boolean,
			default: true,
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		filtered: {
			type: Array,
			default: () => [],
		},
		index: {
			type: Number,
			default: 0,
		},
		//필터 내 삭제된 기간 아이템
		deleteDateItem: {
			type: Array,
			default: () => [],
		},
		datePickerStyle: {
			type: Object,
			default: () => ({}),
		},
		initDate: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			date: [],
			lang: {
				formatLocale: {
					// MMMM
					months: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// MMM
					monthsShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', ' 12월'],
					// dddd
					weekdays: ['일', '월', '화', '수', '목', '금', '토'],
					// ddd
					weekdaysShort: ['일', '월', '화', '수', '목', '금', '토'],
					// dd
					weekdaysMin: ['일', '월', '화', '수', '목', '금', '토'],
				},
				yearFormat: 'YYYY년',
				monthBeforeYear: false,
			},
		}
	},
	watch: {
		deleteDateItem(val) {
			//필터 내 기간 필터 리스트가 삭제될 떄, inputValue 도 reset
			if (val == this.date) {
				this.date = []
			}
		},
		filtered(val) {
			if (val.length == 0) {
				this.date = []
			}
		},
	},
	mounted() {
		if (!this.date.length && this.initDate) this.date = formatDate(this.initDate)
	},
	methods: {
		resetDate() {
			this.filtered.forEach(item => {
				if (item.name != this.name) {
					this.$refs[`date-picker_${this.index}`].clear()
				}
			})
		},
		reset() {
			this.date = ''
		},
		changeDate(value) {
			if (value) {
				this.$emit('changeDate', this.date, this.name)
			}
		},
	},
}
</script>

<style lang="scss"></style>
