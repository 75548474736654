<template>
	<div>
		<span class="keyword-header"> 태그 찾기 </span>
		<ul class="tag-keyword-list" v-if="tagKeywords.length != 0">
			<li class="tag-keyword" v-for="(tag, index) in tagKeywords" :key="`tag_${index}`" @click.stop="clickTag(tag.id)">
				<span>
					{{ tag.name }}
				</span>
			</li>
		</ul>
		<div v-else>
			<span> 일치하는 태그가 없습니다. </span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TagKeywords',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		tagSearchList: [],
	},
	data() {
		return {
			tagKeywords: [],
			tagsId: [],
		}
	},
	created() {
		this.tagKeywords = this.tagSearchList.slice(0, 10)
	},
	watch: {
		tagSearchList() {
			this.tagKeywords = [...this.tagSearchList]
			this.tagKeywords = this.tagSearchList.slice(0, 10)
		},
	},
	methods: {
		clickTag(id) {
			this.tagsId.push(id)
			this.$emit('clickTag', this.tagsId)
		},
	},
}
</script>

<style lang="scss" scoped></style>
