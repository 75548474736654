<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<span><input type="checkbox" :checked="allChecked" @change="$emit('toggle')" /></span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>소속 유형</span>
		</th>
		<th>
			<span>연구실 이름</span>
		</th>
		<th>
			<span>주문 연구실 ID</span>
		</th>
		<th>
			<span>멤버</span>
		</th>
		<th>
			<span>책임자</span>
		</th>
		<th>
			<span>연락처</span>
		</th>
		<th>
			<span>사업자등록증</span>
		</th>
		<th>
			<span>LM 연동</span>
		</th>
		<th>
			<span>브랜드 할인</span>
		</th>
		<th>
			<span>연구실 누적 주문금액</span>
		</th>
		<th>
			<span>미결제 금액 합계</span>
		</th>
		</th>
		<th>
			<span>결제 한도일</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>연구실 생성일</span>
		</th>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
	props: {
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters('lab/list', ['order', 'orderDirection']),
	},
	methods: {
		...mapActions('lab/list', ['setLoadParams']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
