<template>
	<div class="wrapper">
		<div class="add-item" @click="onClickAddBtn" :style="{ background, border }">
			<img src="@/assets/svg/sellInfo/add-button-icon.svg" alt="add" />
			<span>{{ title }}</span>
		</div>
		<additional-text
			ref="texts"
			v-for="(item, index) of items"
			:key="`items${index}`"
			:index="index"
			:text="item.value"
			:complete="item.complete"
			:disabled="item.disabled"
			@delete="onDelete"
			@save="onSave"
		></additional-text>
	</div>
</template>

<script>
import AdditionalText from '@/views/common/components/AdditionalText.vue'

export default {
	name: 'AdditionalTextList',
	components: {
		AdditionalText,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		defaultTextList: {
			type: Array,
			default: () => [],
		},
		maxCount: {
			type: Number,
			default: 999,
		},
		background: {
			type: String,
			default: 'white',
		},
		border: {
			type: String,
			default: '1px solid #333',
		},
	},
	data() {
		return {
			items: [],
			deleteItemIds: [],
		}
	},
	computed: {
		isWriting() {
			return this.items.some(item => this.isEmptyObject(item))
		},
	},
	mounted() {
		this.initItems()
	},
	methods: {
		initItems() {
			this.items = this.$_.cloneDeep(
				this.defaultTextList.map(text => {
					return {
						id: text?.id ? text.id : null,
						value: text?.value ? text.value : text,
						complete: text?.complete ? text.complete : false,
						disabled: text?.disabled ? text.disabled : false,
					}
				})
			)

			this.$emit('change', this.items)
		},
		onClickAddBtn() {
			if (this.maxCount <= this.items.length) return this.$emit('overCount')
			if (!this.isWriting) this.items.unshift({})
		},
		onDelete(index) {
			if (this.items[index]?.id) {
				this.deleteItemIds.push(this.items[index].id)
				this.$emit('changeDeleteTagIds', this.deleteItemIds)
			}
			this.items.splice(index, 1)
			this.$emit('change', this.items)
		},
		onSave(value, index) {
			this.items.splice(index, 1, {
				value,
				complete: true,
			})
			this.$emit('change', this.items)
		},
		isEmptyObject(obj) {
			return Object.keys(obj).length === 0 && obj.constructor === Object
		},
		async setItems(items) {
			await this.$nextTick()
			this.items = this.$_.cloneDeep(items)
			this.$emit('change', this.items)
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-flow: wrap;
	gap: 8px;
	.add-item {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2px;
		height: 38px;
		padding: 6px 8px;
		border-radius: 5px;
		font-size: 14px;
		cursor: pointer;
	}
}
</style>
