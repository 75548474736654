<template>
	<div class="OrderItemList">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<order-item-table class="table" :list="list" />
		</div>
		<pagination :total="count" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import OrderItemTable from '../components/Table.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'OrderItemList',
	components: {
		Pagination,
		OrderItemTable,
		ListHeader,
	},
	computed: {
		...mapState('orderItem/list', ['list', 'count']),
	},
	created() {
		this.fetchOrderBulkList()
	},
	methods: {
		...mapActions('orderItem/list', ['getList', 'setLoadParams']),
		fetchOrderBulkList() {
			try {
				this.getList()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-wrapper {
	height: calc(100% - 160px);
}
</style>
