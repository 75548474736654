import { lmsMenuList, lmpMenuList } from '@/utils/define/menu'

const state = {
	lmsOpenList: [],
	lmpOpenList: [],
	lmsOpen: true,
	lmpOpen: true,
}

const getters = {
	lmsMenuList: state => lmsMenuList,
	lmsOpenList: state => state.lmsOpenList,
	lmpMenuList: state => lmpMenuList,
	lmpOpenList: state => state.lmpOpenList,
	isLMSOpen: state => state.lmsOpen,
	isLMPOpen: state => state.lmpOpen,
}

const mutations = {
	toggleLMSMenu: (state, classification) => {
		if (state.lmsOpenList.includes(classification)) {
			state.lmsOpenList.remove(classification)
		} else {
			state.lmsOpenList.push(classification)
		}
	},
	toggleLMPMenu: (state, classification) => {
		if (state.lmpOpenList.includes(classification)) {
			state.lmpOpenList.remove(classification)
		} else {
			state.lmpOpenList.push(classification)
		}
	},
	toggleLMS: state => {
		state.lmsOpen = !state.lmsOpen
	},
	toggleLMP: state => {
		state.lmpOpen = !state.lmpOpen
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
