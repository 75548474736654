export default {
	requestHeaders: [
		{ name: '상태', key: 'status', type: 'status' },
		{ name: '견적 작성여부', key: '' },
		{ name: '상품 요청번호', key: '', type: 'line' },
		{ name: '요청 상품', key: '', type: 'name' },
		{ name: '제조사', key: '' },
		{ name: '제품번호', key: '' },
		{ name: 'CAS No.', key: '' },
		{ name: '용량', key: '' },
		{ name: '수량', key: '' },
		{ name: '추가 요청사항', key: '' },
		{ name: '요청자 소속', key: '' },
		{ name: '요청자', key: '' },
		{ name: '요청자 연락처', key: '' },
		{ name: '요청자 이메일', key: '' },
		{ name: '요청일시', key: '' },
		{ name: '접수일시', key: '' },
		{ name: '소싱 처리일시', key: '' },
		{ name: '견적 생성일시', key: '' },
	],
	estimateHeaders: [
		{ name: '상태', key: 'status', type: 'status' },
		{ name: '견적 만료일', key: '' },
		{ name: '작성자', key: '' },
		{ name: '견적번호', key: '', type: 'line' },
		{ name: '상품정보', key: '' },
		{ name: '견적금액', key: '' },
		{ name: '담당자.', key: '', type: 'info' },
		{ name: '담당자 연락처', key: '' },
		{ name: '담당자 이메일', key: '' },
		{ name: '수신자', key: '' },
		{ name: '견적 생성일', key: '' },
		{ name: '견적일', key: '' },
	],
	orderHeaders: [
		{ name: '주문번호', key: '', type: 'line' },
		{ name: '주문일시', key: '' },
		{ name: '주문상품', key: '', type: 'name' },
		{ name: '주문자 소속', key: '' },
		{ name: '주문자', key: '' },
		{ name: '주문자 연락처', key: '' },
		{ name: '주문자 이메일', key: '' },
		{ name: '수령인 소속', key: '' },
		{ name: '수령인', key: '' },
		{ name: '수령인 연락처', key: '' },
		{ name: '수령인 이메일', key: '' },
	],
	ordererEstimateHeaders: [
		{ name: '상태', key: 'status' },
		{ name: '견적 만료일', key: '' },
		{ name: '작성자', key: '' },
		{ name: '견적번호', key: '' },
		{ name: '상품정보', key: '' },
		{ name: '견적금액', key: '' },
		{ name: '담당자 연락처', key: '' },
		{ name: '담당자 이메일', key: '' },
		{ name: '수신자', key: '' },
		{ name: '견적 생성일', key: '' },
		{ name: '견적일', key: '' },
	],
	buyerHeaders: [
		{ name: '소속 유형', key: '' },
		{ name: '연구실 이름', key: '' },
		{ name: '주문 연구실 ID', key: '' },
		{ name: '멤버', key: '' },
		{ name: '책임자', key: '' },
		{ name: '연락처', key: '' },
		{ name: '연구실 생성일', key: '' },
	],
	labMemberHeaders: [
		{ name: '이름', key: '' },
		{ name: '연락처', key: '' },
		{ name: '계정', key: '' },
		{ name: '본인인증', key: '' },
		{ name: '멤버별 누적 주문금액', key: '' },
		{ name: '마케팅수신동의(SMS)', key: '' },
		{ name: '마케팅수신동의(이메일)', key: '' },
		{ name: '마케팅수신동의(PUSH)', key: '' },
		{ name: '가입일', key: '' },
	],
	businessRegistrationHeaders: [
		{ name: '인증 상태', key: 'status' },
		{ name: '사업자등록증 별칭', key: '' },
		{ name: '법인명', key: '' },
		{ name: '등록일시', key: '' },
		{ name: '인증 처리일시', key: '' },
	],
}
