const BreadCrumb = {
	namespaced: true,
	state: () => ({
		institutionName: '',
		pageName: '',
		locationName: undefined,
		storageDetailPaths: [],
	}),
	mutations: {
		setPageName(state, payload) {
			state.pageName = payload
		},
		setStorageDetailPaths(state, payload) {
			state.storageDetailPaths = payload
		},
	},
	actions: {},
}
export default BreadCrumb
