import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCancelRefundList: () => `/admin/cs/list`,
	lookupCancelRefundFilter: () => `/admin/cs/list/filter`,
	lookupCancelRefundDetail: id => `/admin/cs?id=${id}`,
	setReturn: () => `/admin/cs/return/done`,
	setCancel: () => `/admin/cs/cancel/done`,
	setReturnReject: () => `/admin/cs/return/reject`,
	setCancelReject: () => `/admin/cs/cancel/reject`,
	setMemo: () => `/admin/cs`,
}

const api = {
	lookupCancelRefundList: params => axiosInstance.get(endPoint.lookupCancelRefundList(), { params }),
	lookupCancelRefundFilter: params => axiosInstance.get(endPoint.lookupCancelRefundFilter(), { params }),
	lookupCancelRefundDetail: id => axiosInstance.get(endPoint.lookupCancelRefundDetail(id)),
	setReturn: body => axiosInstance.patch(endPoint.setReturn(), body),
	setCancel: body => axiosInstance.patch(endPoint.setCancel(), body),
	setReturnReject: body => axiosInstance.patch(endPoint.setReturnReject(), body),
	setCancelReject: body => axiosInstance.patch(endPoint.setCancelReject(), body),
	setMemo: body => axiosInstance.patch(endPoint.setMemo(), body),
}

export default api
