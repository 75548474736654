<template>
	<div class="lab-detail">
		<div class="content-header">
			<div class="title">
				<h4>{{ item.name }}</h4>
				<div class="subtitle">
					<span class="status-text" :class="statusClass">{{ statusText }}</span>
					<p>연구실 생성일: {{ createDatetime }}</p>
				</div>
			</div>
			<div class="right-btn-group">
				<!-- <button class="Btn__common">연구실 대행</button> -->
				<button :class="isPossible ? 'Btn__export' : 'Btn__green'" @click="$bvModal.show('modal-submit')">{{ modalContent.title }}</button>
			</div>
		</div>
		<div class="content-wrapper">
			<line-tab :list="labTabs" :value="selectedTab" @change="changeTab" />
			<div class="content info" v-if="selectedTab === 'info'">
				<info-list title="연구실 정보" :list="labInfo" />
				<detail-table title="사업자등록증(결제처) 목록" :headers="headers.businessRegistrationHeaders">
					<template>
						<tbody>
							<tr v-for="(item, index) in item.businessRegistrations" :key="`businessRegistration_${index}`">
								<td>
									<span class="status-text" :class="BusinessRegistration.statusToClass(item.status)">{{
										BusinessRegistration.statusToString(item.status)
									}}</span>
								</td>
								<td class="link" @click="goToBusinessRegistrationDetail(item.id)">{{ isData(item.name) }}</td>
								<td>{{ isData(item.corpName) }}</td>
								<td>
									{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd HH:mm') : '-' }}
								</td>
								<td>
									{{ item.statusChangeDatetime ? $DateTime.fromISO(item.statusChangeDatetime).toFormat('yyyy-MM-dd HH:mm') : '-' }}
								</td>
							</tr>
						</tbody>
					</template>
				</detail-table>
				<detail-table :title="`연구실 멤버 목록(${members.length})`" :headers="headers.labMemberHeaders" style="margin-top: 40px">
					<template>
						<tbody>
							<tr v-for="(item, index) in members" :key="`member_${index}`">
								<td>
									<b>{{ isData(item.name) }}</b>
								</td>
								<td>{{ item.phone ? Validation.autoHypenTel(item.phone) : '-' }}</td>
								<td>{{ isData(item.email) }}</td>
								<td>{{ item.isVerified ? '완료' : '미완료' }}</td>
								<td>
									{{ item.orderCount && item.totalOrderPrice ? `${item.orderCount}회 ${commaNum(item.totalOrderPrice)}원` : '-' }}
								</td>
								<td>{{ item.isNotiSms ? '동의' : '미동의' }}</td>
								<td>{{ item.isNotiEmail ? '동의' : '미동의' }}</td>
								<td>{{ item.isNotiPush ? '동의' : '미동의' }}</td>
								<td>
									{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd') : '-' }}
								</td>
							</tr>
						</tbody>
					</template>
				</detail-table>
				<detail-table v-if="item.isBrandTargetPromotion" title="할인 적용 대상 브랜드" style="margin-top: 40px">
					<template>
						<thead class="table-header">
							<tr>
								<th>브랜드 ID</th>
								<th>브랜드명</th>
								<th>할인율</th>
								<th>기간</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in item.brandTargetPromotionList" :key="`brand_${index}`">
								<template v-if="item.brand">
									<td>
										{{ item.brand.id }}
									</td>
									<td>
										{{ item.brand.name }}
									</td>
								</template>
								<td v-else colspan="2">전체</td>
								<td>{{ item.discountPercent }}%</td>
								<td>
									{{ item.startDatetime ? $DateTime.fromISO(item.startDatetime).toISODate() : '' }} ~
									{{ item.endDatetime ? $DateTime.fromISO(item.endDatetime).toISODate() : '' }}
								</td>
							</tr>
						</tbody>
					</template>
				</detail-table>
			</div>
			<div class="content order" v-else-if="selectedTab === 'order'">
				<div class="order-wrapper">
					<div class="order-filter-wrapper">
						<status-counter-list :list="statusList" @active="onClickStatus" />
						<detail-search-box
							class="search-box"
							title="상세검색"
							subTitle="조회할 항목을 검색하거나 선택할 수 있습니다"
							searchPlaceholder="주문자, 주문상세 ID,  상품명, 브랜드로 검색"
							:datePlaceholder="datePlaceholder"
							:datePickerList="datePickerList"
							:useFold="true"
							@searchTextList="searchTextList"
						/>
					</div>
					<div class="order-list-wrapper">
						<div class="title-btn-wrapper">
							<search-title
								:title="`주문 목록(${totalCount})`"
								subTitle="상품을 선택 한후 아래 주문 상태를 클릭하여 주문상태를 변경할 수 있습니다."
							/>
							<div class="btn-wrapper">
								<button class="btn" @click="isShowModalVisibleOption = true">
									<img src="@/assets/svg/common/view-option.svg" />
									보기옵션
								</button>
								<button class="btn" @click="onClickDownloadOrderList">
									<img src="@/assets/svg/common/excel-download.svg" />
									목록 다운로드
								</button>
							</div>
						</div>
						<order-status-changer :selectedList="checkedList" @change="orderManagementCreated" />
						<div class="table-wrapper">
							<jw-table
								ref="jw-table"
								class="jwTable"
								:width="table.width"
								:height="table.height"
								:options="table.options"
								@getSelectedItem="getSelectedItem"
								@change="changeDate"
								:noData="isNoData"
								:noSearchResult="isNoSearchResult"
								:noDataText="noDataText"
								:noDataImg="noDataImg"
								:noDataSubtext="noDataSubtext"
								:noSearchResultText="noSearchResultText"
								:noSearchResultSubtext="noSearchResultSubtext"
								noDataHeight="600px"
							></jw-table>
							<div class="pagenation-wrapper">
								<common-pagenation @page="getCurrentPage" :total="totalCount" :defaultOffset="50"></common-pagenation>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<modal-update-limit-credit-price
			:isShow="isShowModalUpdateLimitCredit"
			:item="item"
			@hide="isShowModalUpdateLimitCredit = false"
			@complete="getDetail(id)"
		/>
		<modal-update-payment-due-month
			:isShow="isShowModalUpdatePaymentDueMonth"
			:item="item"
			@hide="isShowModalUpdatePaymentDueMonth = false"
			@complete="getDetail(id)"
		/>
		<modal-submit :title="modalContent.title" :content="modalContent.content" @ok="changeStatus" />
		<modal-order-modify
			:isShow="isShowOrderModifyModal"
			:orderItem="selectedItem"
			@hide="isShowOrderModifyModal = false"
			@complete="orderManagementCreated"
		/>
		<modal-shipping-info
			:isShow="isShowShippingInfoModal"
			:orderItemList="selectedItemList"
			@hide="isShowShippingInfoModal = false"
			@complete="orderManagementCreated"
		/>
		<modal-visible-option
			:isShow="isShowModalVisibleOption"
			:allColumns="allColumns"
			:visibleColumns="visibleColumns"
			@close="isShowModalVisibleOption = false"
			@save="setVisibleOption"
		/>
		<modal-update-price :isShow="isShowPriceModal" :item="changePriceRow" @hide="hidePriceModal" />
		<modal-confirm
			ref="modal-brand-promotion"
			variant="accept"
			title="브랜드 할인 적용"
			content="브랜드 할인 적용 상태를 변경하시겠습니까?"
			confirmText="변경"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import mixin from '@/mixins'
import LineTab from '@/components/tab/LineTab'
import InfoList from '@/views/common/components/InfoList.vue'
import headers from '@/utils/define/table/header'
import ModalUpdateLimitCreditPrice from '@/views/buyer/lab/modals/ModalUpdateLimitCreditPrice.vue'
import ModalUpdatePaymentDueMonth from '@/views/buyer/lab/modals/ModalUpdatePaymentDueMonth.vue'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import DetailTable from '@/components/table/DetailTable'
import JwTable from '@/views/common/components/JwTable.vue'
import OrderStatusChanger from '@/views/order/orderProduct/components/OrderStatusChanger.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import { orderManagementTable, orderManagementTableAllColumns } from '@/utils/define/table/orderManagementTable'
import { TableField } from '@/utils/define/table/JwTable'
import { ToastType } from '@/utils/define/ToastType'
import { BusinessRegistration } from '@/utils/define/ItemCode'
import Validation from '@/utils/Validation'
import PageName from '@/utils/define/PageName'
import ModalOrderModify from '@/views/order/orderProduct/modals/ModalOrderModify.vue'
import ModalShippingInfo from '@/views/order/orderProduct/modals/ModalShippingInfo.vue'
import ModalVisibleOption from '@/views/common/modals/ModalVisibleOption.vue'
import ModalUpdatePrice from '@/views/order/orderProduct/modals/ModalUpdatePrice.vue'

export default {
	name: 'LabDetail',
	mixins: [mixin],
	components: {
		DetailTable,
		InfoList,
		LineTab,
		OrderStatusChanger,
		SearchTitle,
		CommonPagenation,
		StatusCounterList,
		DetailSearchBox,
		JwTable,
		ModalSubmit,
		ModalUpdateLimitCreditPrice,
		ModalUpdatePaymentDueMonth,
		ModalOrderModify,
		ModalShippingInfo,
		ModalVisibleOption,
		ModalUpdatePrice,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	data() {
		return {
			Validation,
			BusinessRegistration,
			labTabs: [
				{ name: '연구실 정보', value: 'info' },
				{ name: '연구실 주문내역', value: 'order' },
			],
			selectedTab: 'info',
			headers: headers,
			isShowModalUpdateLimitCredit: false,
			isShowModalUpdatePaymentDueMonth: false,
			isShowInstituteSelectModal: false,
			isShowModalVisibleOption: false,
			isShowOrderModifyModal: false,
			isShowShippingInfoModal: false,
			isShowPriceModal: false,
			selectedItem: {},
			selectedItemList: [],
			selectedStatus: 'ALL',
			checkedList: [],
			table: this.$_.cloneDeep(orderManagementTable),
			sourceTable: orderManagementTable,
			allColumns: orderManagementTableAllColumns,
			cookieVisibleColumnsKey: 'LabDetailTableAllColumns',
			visibleColumns: [],
			totalCount: 0,
			listParams: {
				keyword: '',
				status: 'ALL',
				offset: 0,
				length: 50,
				brandIds: [],
				shippingMethods: [],
				orderStartDatetime: undefined,
				orderEndDatetime: undefined,
				estimatedReleaseStartDatetime: undefined,
				estimatedReleaseEndDatetime: undefined,
				releaseStartDatetime: undefined,
				releaseEndDatetime: undefined,
				instituteId: this.id,
			},
			statusList: [],
			datePickerList: [{ text: '주문일' }],
			changePriceRow: {},
		}
	},
	computed: {
		...mapState('lab/detail', ['item']),
		...mapGetters('order', ['getOrderList', 'getOrderListTableData', 'getOrderListSummary', 'getOrderListFilter']),
		createDatetime() {
			return this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd') : '-'
		},
		statusText() {
			return !this.item.isBlock ? '구매 가능' : '구매 불가'
		},
		statusClass() {
			return !this.item.isBlock ? 'complete' : 'fail'
		},
		labInfo() {
			// 연구실 정보
			return [
				{ title: '책임자', value: this.item.managerName },
				{ title: '소속 유형', value: this.item.type?.name },
				{ title: '연구실 이름', value: this.item.name, isSingleRow: true },
				{ title: '연구실 ID', value: this.item.id },
				{
					title: '연구실 생성일',
					value: this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd') : '-',
				},
				{
					title: '연구실 누적 주문금액',
					value:
						this.item.orderCount && this.item.totalOrderPrice
							? `${this.item.orderCount}회 ${this.commaNum(this.item.totalOrderPrice)}원`
							: '-',
				},
				{ title: '미결제 금액 합계', value: this.item.noPaidPrice ? `${this.commaNum(this.item.noPaidPrice)}원` : '-' },
				{
					title: '연구실 주문 한도금액',
					value: `${this.commaNum(this.item.limitCredit)}원`,
					type: 'text-button',
					clickFn: () => (this.isShowModalUpdateLimitCredit = true),
					buttonText: '수정',
				},
				{
					title: '연구실 결제 한도일',
					value: `M+${this.commaNum(this.item.paymentDueMonth)} (${this.paymentDueDay})`,
					type: 'text-button',
					clickFn: () => (this.isShowModalUpdatePaymentDueMonth = true),
					buttonText: '수정',
				},
				{
					title: '브랜드 할인 적용',
					value: this.item.isBrandTargetPromotion ? '적용' : '미적용',
					type: 'text-button',
					clickFn: () => this.$refs['modal-brand-promotion'].show(this.toggleBrandPromotion),
					buttonText: this.item.isBrandTargetPromotion ? '해제' : '적용',
				},
			]
		},
		isPossible() {
			return !this.item.isBlock ? true : false
		},
		modalContent() {
			if (this.isPossible) {
				return {
					title: '구매 금지',
					content: '연구실을 구매 금지 하시겠습니까?<br>해당 연구실은 구매 금지한 시점부터 더 이상 구매를 할 수 없습니다.',
				}
			} else {
				return {
					title: '구매 재개',
					content: '연구실을 구매 재개 하시겠습니까?<br>해당 연구실은 구매 재개한 시점부터 구매가 가능해집니다.',
				}
			}
		},
		members() {
			return this.item?.members ?? []
		},
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		isNoData() {
			return this.listParams.keyword.length === 0 && this.totalCount === 0
		},
		isNoSearchResult() {
			return this.listParams.keyword.length > 0 && this.totalCount === 0
		},
		noDataText() {
			return '구매자가 주문한 상품이 없습니다.'
		},
		noDataSubtext() {
			return '구매자가 주문을 시작하면, 주문한 상품을 보여드립니다.'
		},
		noSearchResultText() {
			return '검색 결과가 없습니다'
		},
		noSearchResultSubtext() {
			return '상품명, 상품번호, 자체 관리번호, 브랜드, 제품번호, CAS No로 검색할 수 있습니다.'
		},
		noDataImg() {
			return require('@/assets/svg/common/jw-table/img-nodata.svg')
		},
		paymentDueDay() {
			let dueDay = new Date()
			dueDay.setDate(1)
			dueDay.setHours(0, 0, 0, 0)
			dueDay.setMonth(dueDay.getMonth() - this.item.paymentDueMonth)
			return this.formatDate(dueDay.toISOString(), 'yyyy-MM-dd')
		},
	},
	created() {
		this.changeTab(this.labTabs[0].value)
	},
	methods: {
		...mapActions('lab/detail', ['getDetail', 'buyLabBlock', 'setBrandTargetPromotion', 'deleteBrandTargetPromotion']),
		...mapActions('order', ['lookupOrderList', 'lookupOrderListSummary', 'changeEstimatedDate', 'downloadOrderList']),
		async changeTab(val) {
			this.selectedTab = val
			if (this.selectedTab === 'info') {
				await this.getDetail(this.id)
			} else if (this.selectedTab === 'order') {
				this.orderManagementCreated()
			}
		},
		async changeStatus() {
			try {
				await this.buyLabBlock({ ids: [this.item.id], isBlock: !this.item.isBlock })

				if (this.isPossible) {
					this.$root.toast('구매 금지 완료', '연구실을 구매 금지 처리했습니다.', ToastType.SUCCESS)
				} else {
					this.$root.toast('구매 재개 완료', '연구실을 구매 재개 처리했습니다.', ToastType.SUCCESS)
				}

				if (this.selectedTab === 'info') {
					await this.getDetail(this.id)
				} else if (this.selectedTab === 'order') {
					this.orderManagementCreated()
				}
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		goToBusinessRegistrationDetail(id) {
			this.$router.push({
				name: PageName.Buyer.BusinessRegistrationDetail,
				params: {
					id: id,
				},
			})
		},
		orderManagementCreated() {
			this.loadListData()
			this.loadSummaryData()
			this.setColumnClickEvent()
		},
		routeOrderDetailPage(orderId) {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: orderId,
				},
			})
		},
		routeOrderItemDetailPage(orderItemId) {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: orderItemId,
				},
			})
		},
		routeLabDetail(row) {
			if (row.isBlock === 1) {
				this.$router.push({
					name: PageName.Buyer.LabDetail,
					params: {
						id: row.institute.id,
					},
				})
			}
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())
			await this.lookupOrderList(this.listParams)
			this.setData()
		},
		async loadSummaryData() {
			await this.lookupOrderListSummary({ instituteId: this.id })
			this.setSummaryData()
		},
		setData() {
			const response = this.getOrderListTableData
			this.totalCount = response.count
			this.table.options.tableData = response.tableData
			if (this.$cookies.get(this.cookieVisibleColumnsKey)) this.setVisibleOption(JSON.parse(this.$cookies.get(this.cookieVisibleColumnsKey)))
		},
		scrollTop() {
			document.querySelector('.jwTable').scrollTo(0, 0)
		},
		setSummaryData() {
			this.statusList = Object.keys(this.getOrderListSummary.summary).map(summary => {
				return {
					id: summary,
					title: this.getStatusTitle(summary),
					count: this.getOrderListSummary.summary[summary],
					active: summary === 'ALL' ? true : false,
				}
			})
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		getStatusTitle(key) {
			const result = {
				ALL: '전체',
				APPROVE: '승인 완료',
				CANCEL: '주문취소',
				SHIPPING_COMPLETE: '배송완료',
				SHIPPING_ING: '배송중',
				WAIT: '승인 대기',
			}[key]

			return result
		},
		setColumnClickEvent() {
			this.table.options.columns.forEach((data, index) => {
				if (data.key === TableField.ReleaseVolume) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItem = row
						this.isShowOrderModifyModal = true
					}
				}
				if (data.key === TableField.DeliveryMethod) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.selectedItemList = [row]
						this.isShowShippingInfoModal = true
					}
				}
				if (data.key === TableField.OrderPrice) {
					this.table.options.columns[index].clickEvent = ({ row }) => {
						this.changePriceRow = row
						this.isShowPriceModal = true
					}
				}
				if (data.key === TableField.OrderNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderDetailPage(data.id)
					}
				}
				if (data.key === TableField.OrderItemNo) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeOrderItemDetailPage(data.id)
					}
				}
				if (data.key === TableField.Status) {
					this.table.options.columns[index].clickEvent = data => {
						this.routeLabDetail(data.id)
					}
				}
			})
		},
		setVisibleOption(visibleNameList) {
			this.table.options.columns = [
				...this.sourceTable.options.columns.filter(item => {
					return visibleNameList.includes(item.name)
				}),
			]
			this.visibleColumns = visibleNameList
			this.$cookies.set(this.cookieVisibleColumnsKey, JSON.stringify(visibleNameList))
			this.setColumnClickEvent()
		},
		hidePriceModal() {
			this.isShowPriceModal = false
			this.loadListData()
		},
		async onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.listParams.status = this.selectedStatus
			this.loadListData()
		},
		async searchTextList(filters) {
			const periodType = {
				ORDER_DATE: '기간 조회/주문일',
			}
			this.listParams.orderStartDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[0] : undefined
			this.listParams.orderEndDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[1] : undefined
			this.listParams.keyword = filters['검색']?.length ? filters['검색'][0].value : ''
			this.loadListData()
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		getCurrentPage(page) {
			const { offset, length } = page
			this.listParams.offset = offset
			this.listParams.length = length
			this.scrollTop()
			this.loadListData()
		},
		async onClickDownloadOrderList() {
			try {
				const response = await this.downloadOrderList(this.listParams)
				window.fileDownload(response, '전체 주문관리 목록')
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, ToastType.ERROR)
			}
		},
		async toggleBrandPromotion() {
			//api
			if (this.item.isBrandTargetPromotion) {
				await this.deleteBrandTargetPromotion({ id: this.id })
				this.$root.toast('브랜드 할인 적용', `해제되었습니다.`, 'success')
			} else {
				await this.setBrandTargetPromotion({ id: this.id })
				this.$root.toast('브랜드 할인 적용', `적용되었습니다.`, 'success')
			}
			await this.getDetail(this.id)
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-detail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
			}
		}
	}
	.content-wrapper {
		padding: 0 30px 30px 30px;
		width: 100%;
		.order-filter-wrapper {
			padding: 0 0 20px 0;
			.search-box {
				margin-top: 33px;
			}
		}
		.order-list-wrapper {
			.title-btn-wrapper {
				display: flex;
				justify-content: space-between;
				padding-top: 20px;
			}
			.btn-wrapper {
				display: flex;
				gap: 10px;
			}
			.table-wrapper {
				padding-top: 10px;
				.pagenation-wrapper {
					position: relative;
					z-index: 100;
				}
			}
		}
	}
}
</style>

<style lang="scss">
.lab-detail {
	.line-tab {
		padding: 0 0;
		height: 50px;
		margin-bottom: 40px;
		.tab {
			font-weight: 400;
			&.active {
				border-color: $PRIMARY_YELLOW;
				font-weight: 700;
			}
		}
	}
	.search-wrap {
		.search-box {
			width: 300px;
		}
	}
}
</style>
