<template>
	<thead class="table-header">
		<tr>
			<th class="check-box" v-if="isCheck">
				<input type="checkbox" :checked="allChecked" @change="$emit('toggle')" />
			</th>
			<th v-for="(header, index) in headers" :key="`product-header${index}`" :width="header.width">{{ header.name }}</th>
		</tr>
	</thead>
</template>

<script>
export default {
	name: 'TableHeader',
	props: {
		headers: {
			type: Array,
			default: () => [],
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
		isCheck: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style lang="scss" scoped>
.table-header {
	th {
		font-size: 0.875rem;
		font-weight: 700;
		&.check-box {
			width: 60px;
			min-width: 60px;
		}
	}
}
</style>
