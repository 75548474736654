<template>
	<b-modal :id="id" centered :title="title" ref="modal" :size="size" hide-footer @show="init">
		<div class="flex">
			<search-box
				class="search-box"
				:useRecentKeywords="true"
				:useTagKeywords="false"
				:useSuggestionKeywords="false"
				name="user"
				placeholder="검색"
				:loadList="onChangeKeyword"
				:initInputValue="keyword"
			/>
		</div>
		<div class="table-wrap">
			<table>
				<table-header :headers="headers" />
				<tbody v-if="userList.length > 0">
					<tr v-for="(item, index) in userList" :key="`user_${index}`">
						<td>{{ item.institute?.type?.name }}</td>
						<td>{{ item.institute?.name }}</td>
						<td>{{ item.name }}</td>
						<td>{{ isData(Validation.autoHypenTel(item.phone)) }}</td>
						<td>
							<div class="box">
								<p>{{ item.email }}</p>
								<button class="btn btn-complete" @click="onClickSelect(item)">선택</button>
							</div>
						</td>
					</tr>
				</tbody>
				<tbody v-else>
					<div class="empty-area">
						<img src="@/assets/svg/common/empty-user.svg" />
						<span>유저명, 소속, 연락처, 이메일로 검색해 주세요</span>
					</div>
				</tbody>
			</table>
		</div>
	</b-modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import SearchBox from '@/views/common/components/SearchBox.vue'
import TableHeader from '@/components/table/TableHeader'
import Validation from '@/utils/Validation'

export default {
	name: 'ModalSearchUser',
	components: {
		SearchBox,
		TableHeader,
	},
	props: {
		id: {
			type: String,
			default: 'modal-search-user',
		},
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		size: {
			type: String,
			default: 'xl',
		},
	},
	data() {
		return {
			Validation,
			searchKeyword: '',
			headers: [
				{ name: '소속유형', key: 'type' },
				{ name: '소속', key: 'instituteName' },
				{ name: '이름', key: 'name' },
				{ name: '연락처', key: 'phone' },
				{ name: '이메일', key: 'email' },
			],
		}
	},
	computed: {
		...mapState('user', ['userList']),
		keyword() {
			return this.searchKeyword
		},
	},
	methods: {
		...mapMutations('user', ['setUserList']),
		...mapActions('user', ['lookupUserList']),
		init() {
			this.searchKeyword = ''
			this.fetchUserList()
		},
		async fetchUserList() {
			await this.lookupUserList({
				keyword: this.searchKeyword,
			})
		},
		onChangeKeyword(keyword) {
			this.searchKeyword = keyword
			this.fetchUserList()
		},
		onClickSelect(item) {
			this.$emit('select', item)
			this.$refs['modal'].hide()
		},
	},
}
</script>

<style lang="scss">
#modal-search-user {
	.modal-body {
		padding: 0 0;
		.flex {
			height: 60px;
			border-top: 1px solid $COLOR_DDD;
			padding: 0 20px;
			.search-box {
				width: 360px;
				.keyword-wrapper {
					width: 100%;
				}
			}
		}
	}
	.table-wrap {
		width: 100%;
		border: 1px solid $COLOR_DDD;
		min-height: 400px;
		max-height: 480px;
		overflow-y: auto;
		table {
			width: 100%;
			thead {
				th {
					height: 40px;
					font-size: 0.875rem;
					color: $COLOR_666;
					border-bottom: 1px solid $COLOR_DDD;
					padding: 0 8px;
					position: relative;
					&:first-child {
						padding-left: 8px;
						left: 20px;
					}
					&:before {
						position: absolute;
						width: 1px;
						height: 20px;
						background-color: $COLOR_DDD;
						content: '';
						top: calc(50% - 10px);
						left: 0;
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $COLOR_DDD;
					td {
						font-size: 0.875rem;
						padding: 0 8px;
						height: 60px;
						&:first-child {
							padding-left: 28px;
						}
						> div {
							display: flex;
							align-items: center;
							button {
								width: 58px;
								margin-left: auto;
								justify-content: center;
								margin-right: 8px;
							}
						}
					}
				}
			}
		}
		.empty-area {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 16px;
			width: 100%;
			height: 357px;
			> span {
				font-weight: 400;
				font-size: 0.875rem;
				color: $COLOR_999;
			}
		}
	}
}
</style>
