import axiosInstance from '@/services/axios'

const endPoint = {
	lookupOrderList: () => `/admin/order/list`,
	lookupOrderBulkList: () => `/admin/order/list/bulk`,
	lookupOrderListSummary: () => `/admin/order/list/summary`,
	lookupOrderListFilter: () => `/admin/order/list/filter`,
	lookupOrderDetail: () => `/admin/order`,
	lookupOrderItemDetail: () => `/admin/order/item`,
	lookupOrderItemGroup: () => `/admin/order/item/group`,
	lookupOrderItem: () => `/admin/order/item`,
	downloadOrderList: () => `/admin/order/list/export/excel`,
	setOrderItemMemo: () => `/admin/order/memo`,
	setAdminMemo: () => `/admin/order/memo`,
	changeEstimatedDate: () => `admin/order/item/estimated-release`,
	changeEstimatedDateBulk: () => `admin/order/item/bulk/estimated-release`,
	approveOrder: () => `/admin/order/item/approve`,
	separationOrder: () => `/admin/order/item/separation`,
	startShipping: () => `/admin/order/item/shipping-ing`,
	completeShipping: () => `/admin/order/item/shipping-complete`,
	updateShippingInfo: () => `/admin/order/item/shipping-info`,
	updateOrderItemAdminMemo: () => `/admin/order/item/memo`,
	updateOrderItemPrice: () => `/admin/order/item/price`,
	updateSalesPrice: () => `/admin/order/item/sales-unit-price`,
	cancelOrder: () => `/admin/order/item/cancel`,
	cancelOrderRequestReject: () => `/admin/order/item/reject/cancel-request`,
	cancelOrderRequestApprove: () => `/admin/order/item/approve/cancel-request`,
	printTradeStatement: () => `/admin/order/tradeStatement`,
}

const orderAPI = {
	lookupOrderList: params => axiosInstance.get(endPoint.lookupOrderList(), { params }),
	lookupOrderBulkList: params => axiosInstance.get(endPoint.lookupOrderBulkList(), { params }),
	lookupOrderListSummary: params => axiosInstance.get(endPoint.lookupOrderListSummary(), { params }),
	lookupOrderListFilter: params => axiosInstance.get(endPoint.lookupOrderListFilter(), { params }),
	lookupOrderDetail: params => axiosInstance.get(endPoint.lookupOrderDetail(), { params }),
	lookupOrderItemDetail: params => axiosInstance.get(endPoint.lookupOrderItemDetail(), { params }),
	lookupOrderItemGroup: params => axiosInstance.get(endPoint.lookupOrderItemGroup(), { params }),
	lookupOrderItem: params => axiosInstance.get(endPoint.lookupOrderItem(), { params }),
	downloadOrderList: params => axiosInstance.post(endPoint.downloadOrderList(), params, { responseType: 'blob' }),
	setOrderItemMemo: body => axiosInstance.patch(endPoint.setOrderItemMemo(), body),
	setAdminMemo: body => axiosInstance.patch(endPoint.setAdminMemo(), body),
	changeEstimatedDate: body => axiosInstance.patch(endPoint.changeEstimatedDate(), body),
	changeEstimatedDateBulk: body => axiosInstance.patch(endPoint.changeEstimatedDateBulk(), body),
	separationOrder: body => axiosInstance.patch(endPoint.separationOrder(), body),
	approveOrder: payload => axiosInstance.patch(endPoint.approveOrder(), payload),
	startShipping: payload => axiosInstance.patch(endPoint.startShipping(), payload),
	completeShipping: payload => axiosInstance.patch(endPoint.completeShipping(), payload),
	updateShippingInfo: payload => axiosInstance.patch(endPoint.updateShippingInfo(), payload),
	updateOrderItemAdminMemo: payload => axiosInstance.patch(endPoint.updateOrderItemAdminMemo(), payload),
	updateOrderItemPrice: payload => axiosInstance.patch(endPoint.updateOrderItemPrice(), payload),
	updateSalesPrice: payload => axiosInstance.patch(endPoint.updateSalesPrice(), payload),
	cancelOrder: payload => axiosInstance.patch(endPoint.cancelOrder(), payload),
	cancelOrderRequestReject: payload => axiosInstance.patch(endPoint.cancelOrderRequestReject(), payload),
	cancelOrderRequestApprove: payload => axiosInstance.patch(endPoint.cancelOrderRequestApprove(), payload),
	printTradeStatement: params => axiosInstance.post(endPoint.printTradeStatement(), params, { responseType: 'blob' }),
}

export default orderAPI
