import axiosInstance from '@/services/axios'

const endPoint = {
	lookupRequestProductList: () => `/partner/request/list`,
	lookupRequestProduct: () => `/partner/request`,
	downloadRequestProductList: () => ``,
	lookupCategoryList: () => `/partner/category/list/child`,
	lookupItemToRegistered: () => `/partner/catalog/search`,
	registProduct: () => `/partner/productInfo`,
	lookupProductManagementList: () => `/partner/productInfo/list`,
	lookupProductManagementFilter: () => `/partner/productInfo/list/filter`,
	lookupProductDetail: () => `/partner/productInfo`,
	deleteProducts: () => `/partner/productInfo`,
	changeSellingStatus: () => `/partner/productInfo/stop`,
	downloadProductManagementList: instituteId => ``,

	// gen2
	list: () => `admin/productInfo/list`,
	getDetail: () => `admin/productInfo`,
	updateSellingStatus: () => `admin/productInfo/stop`,
	updateProduct: () => `admin/product`,
}

const productAPI = {
	// 상품 요청 게시판
	lookupRequestProductList: params => axiosInstance.get(endPoint.lookupRequestProductList(), { params }),
	lookupRequestProduct: params => axiosInstance.get(endPoint.lookupRequestProduct(), { params }),
	downloadRequestProductList: params => axiosInstance.post(endPoint.downloadRequestProductList(), params, { responseType: 'blob' }),

	// 상품 등록하기
	lookupCategoryList: params => axiosInstance.get(endPoint.lookupCategoryList(), { params }),
	lookupItemToRegistered: params => axiosInstance.get(endPoint.lookupItemToRegistered(), { params }),
	registProduct: formData =>
		axiosInstance.post(endPoint.registProduct(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	// 상품 조회/수정
	lookupProductManagementList: params => axiosInstance.get(endPoint.lookupProductManagementList(), { params }),
	lookupProductManagementFilter: () => axiosInstance.get(endPoint.lookupProductManagementFilter()),
	lookupProductDetail: params => axiosInstance.get(endPoint.lookupProductDetail(), { params }),
	deleteProducts: params => axiosInstance.delete(endPoint.deleteProducts(), { params }),
	changeSellingStatus: payload => axiosInstance.patch(endPoint.changeSellingStatus(), payload),
	downloadProductManagementList: (instituteId, payload) =>
		axiosInstance.post(endPoint.downloadProductManagementList(instituteId), payload, {
			responseType: 'blob',
		}),
	// gen2
	list: params => axiosInstance.get(endPoint.list(), { params }),
	getDetail: params => axiosInstance.get(endPoint.getDetail(), { params }),
	updateSellingStatus: body => axiosInstance.patch(endPoint.updateSellingStatus(), body),
	updateProduct: body => axiosInstance.patch(endPoint.updateProduct(), body),
}

export default productAPI
