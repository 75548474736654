<template>
	<div>
		<base-item :title="title">
			<div>
				<button class="btn" @click="onClickAddImage">이미지 추가</button>
				<div class="item-sub-text">
					권장 사이즈: 500px * 500px / 최대 용량 nMB / jpg, jpeg, png<br />
					대표이미지는 상품 목록에서 보이는 이미지이며, 이미지를 클릭하면 대표 이미지로 설정할 수 있습니다.
				</div>
				<div v-if="images.length" class="input-image-list">
					<image-item
						v-for="(image, index) of images"
						:key="`images_${index}`"
						:image="image"
						:index="index"
						:imageThumbnailIndex="imageThumbnailIndex"
						@clickImage="onClickImage"
						@deleteImage="onClickDeleteImage"
						@changeThumbnailImage="onClickThumbnailImage"
					/>
				</div>
			</div>
			<input ref="image-file" type="file" accept=".jpg, .jpeg, .png" @change="uploadImage" hidden />
		</base-item>
		<modal-image-preview ref="modal-image-preview"></modal-image-preview>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'
import ImageItem from '@/views/common/components/ImageItem.vue'
import ModalImagePreview from '@/views/product/registration/modals/ModalImagePreview.vue'

export default {
	name: 'InputImage',
	components: {
		BaseItem,
		ImageItem,
		ModalImagePreview,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		images: {
			type: Array,
			default: () => [],
		},
		imageThumbnailIndex: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			deleteItemIds: [],
		}
	},
	created() {
		this.onClickThumbnailImage(this.imageThumbnailIndex)
	},
	methods: {
		onClickAddImage() {
			this.$refs['image-file'].click()
		},
		onClickImage(index) {
			this.$refs['modal-image-preview'].show(this.images[index])
		},
		onClickDeleteImage(index) {
			if (this.images[index]?.id) {
				this.deleteItemIds.push(this.images[index].id)
				this.$emit('changeDeleteImageIds', this.deleteItemIds)
			}

			this.images.splice(index, 1)
			if (this.imageThumbnailIndex === index) {
				if (this.images[index]) this.onClickThumbnailImage(index)
				else this.onClickThumbnailImage(0)
			}

			this.$emit('changeImages', this.images)
		},
		onClickThumbnailImage(index) {
			this.$emit('changeThumbnailImage', index)
		},
		uploadImage(e) {
			const image = e.target.files[0]
			this.images.push(image)
			this.$refs['image-file'].value = ''
			this.$emit('changeImages', this.images)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-image-list {
	max-width: 820px;
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	padding-top: 35px;
}
</style>
