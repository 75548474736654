<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalViewMSDS" v-if="isShow" :class="mode == 'MSDS' ? 'msds' : 'ghs'">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-lg" v-if="mainModalShow">
				<div class="Popup__top">
					<h5 class="Popup__title">
						<span>MSDS</span>
					</h5>
					<button class="Popup__close-btn" @click="close">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="AppContainer__tabs">
						<!-- <div v-for="(item, index) in tabs" :key="index" v-if="keyArr.indexOf(item.value) > -1">
							<input v-model="tab" :id="`tab` + index" type="radio" :value="index" name="managementCategory" /><label
								:for="`tab` + index"
								>{{ item.title }}</label
							>
						</div> -->
					</div>
					<div class="boxwrap">
						<div class="pdf-view">
							<iframe v-if="pdfUrl != null" :src="pdfUrl" frameborder="0"></iframe>
						</div>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
export default {
	name: 'ModalViewMsds',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		pdfUrl: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			pdfFileUrl: '',
			name: '',
			casno: '',
			mainModalShow: true,
			activeHCodeList: [],
			mode: 'MSDS',
			tab: 0,
			tabs: [
				{ id: 0, title: '제조사 MSDS', value: 'master' },
				{
					id: 1,
					title: '안전보건공단 MSDS',
					value: 'kosha',
				},
				{
					id: 2,
					title: '업로드된 MSDS',
					value: 'institute',
				},
			],
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalViewMSDS {
	.Popup__container {
		padding: 0;
		.Popup__top {
			padding: 20px;
			.guide-btn {
				position: absolute;
				right: 60px;
			}
		}
		.Popup__content {
			padding: 0;
			.AppContainer__tabs {
				margin-top: 0;
				padding-bottom: 19px;
				padding-left: 6px;
				border-bottom: 1px solid #eee;
				> div {
					display: inline-block;
					position: relative;
					label {
						font-size: 0.875rem;
						padding-bottom: 19px;
					}
					input[type='radio']:checked + label {
						img {
							filter: none;
							opacity: 1;
						}
					}
				}
			}
			.top {
				padding: 20px;
				background-color: #fff;
				button {
					font-weight: bold;
					margin-right: 10px;
					img {
						padding-right: 0;
					}
				}
				.Btn__hover-balloon-w {
					.balloon {
						margin-top: 10px;
						max-width: 334px;
						width: 334px;
					}
					.balloon:after {
						top: -7px;
					}
					&:hover {
						.balloon {
							visibility: visible;
							transition: 0.3s all ease;
						}
					}
				}
			}
			.pdf-view {
				height: 770px;
				max-height: 70vh;
				background-color: #f1f1f1;
				width: 100%;
				.upload-box {
					width: 100%;
					height: inherit;
					max-height: inherit;
					vertical-align: middle;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					> div {
					}
					input {
						width: inherit;
						height: inherit;
						max-height: inherit;
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						cursor: pointer;
					}
					> img {
						width: 67px;
					}
					h5 {
						font-size: 1.125rem;
						color: #999;
						font-weight: 700;
						padding: 20px 0 30px;
					}
					.btn-wrap {
						display: flex;
						align-items: center;
						justify-content: center;
						button {
							height: 30px;
							cursor: pointer;
						}
					}
				}
				iframe {
					width: inherit;
					height: inherit;
					max-height: inherit;
				}
			}
		}
	}
	&.ghs {
		.Popup__content {
			> .boxwrap {
				display: flex;
				height: inherit;
				max-height: inherit;
				.pdf-view {
					width: 60%;
					display: inline-block;
					iframe {
						width: 100%;
					}
				}

				.pdf-description {
					width: 40%;
					background-color: #fff;
					padding: 20px;
					box-sizing: border-box;
					height: 70vh;
					overflow-y: auto;
					display: inline-block;
					> div {
						padding-bottom: 70px;
						h5 {
							font-size: 1rem;
							font-weight: 700;
							color: #000;
						}
						h6 {
							font-size: 0.75rem;
							font-weight: 700;
							color: #000;
							padding-bottom: 10px;
							padding-top: 22px;
						}
						.tag {
							font-size: 0.75rem;
							color: #000;
							font-weight: 400;
							height: 28px;
							display: inline-flex;
							align-items: center;
							border: 1px solid #eee;
							background-color: #fff;
							padding: 0 8px;
							border-radius: 7px;
							vertical-align: middle;
							margin-right: 8px;
							margin-bottom: 8px;
							cursor: pointer;
							&.selected {
								border: 2px solid $PRIMARY_YELLOW;
								background-color: rgba(254, 199, 65, 0.1);
								font-weight: bold;
								color: $PRIMARY_YELLOW;
								margin-top: -1px;
								margin-bottom: 7px;
							}
						}
						.check-box {
							margin-bottom: 23px;
							p {
								font-size: 0.75rem;
								color: rgba(0, 0, 0, 0.6);
								font-weight: 400;
							}
							label {
								display: flex;
								width: 100%;
								margin-top: 10px;
								font-size: 0.875rem;
								color: #000;
								font-weight: 400;
								cursor: pointer;
								b {
									display: inline-block;
									font-weight: bold;
									margin: 0 10px;
								}
								span {
									max-width: 200px;
									text-overflow: ellipsis;
									overflow: hidden;
									white-space: nowrap;
								}
								button {
									display: inline-block;
									padding: 0;
									width: 20px;
									height: 20px;
									margin-left: 6px;
									img {
										width: 100%;
										margin-bottom: -5px;
									}
									.balloon {
										left: unset;
										right: -20px;
										width: 280px;
										h6 {
											padding-top: 0;
											font-size: 0.875rem;
											color: #000;
											font-weight: 700;
											padding-bottom: 3px;
										}
										&:before {
											left: unset;
											right: 20px;
										}
										&:after {
											left: unset;
											right: 21px;
										}
									}
								}
							}
						}
						.msds-error-message-wrap {
							background: #fff5f5;
							padding: 8px;
							font-size: 12px;
							color: #ff0000;
							display: flex;
							position: relative;
							justify-content: center;
						}
						.msds-error-message {
							position: absolute;
							top: 12px;
							left: 8px;
						}
					}
					.Popup__bottom {
						width: 40%;
						padding: 0 20px;
						background-color: #fff;
						height: 70px;
						position: absolute;
						bottom: 0;
						right: 0;
						border-top: 1px solid #eee;
					}
				}
			}
		}
	}
}
.Btn__border-b {
	background: unset;
	border: 0;
	box-shadow: none;
	display: flex;
	height: auto;
	align-items: center;
	cursor: pointer;
	white-space: pre;
	transition: 0.5s all ease;
	border: 1px solid #000;
	border-radius: 5px;
	background-color: #f2f3f4;
	color: #000 !important;
	font-weight: 700;
	font-size: 0.875rem;
	// input {
	// 	width: 0;
	// 	height: 0;
	// 	visibility: hidden;
	// 	margin-left: -10px;
	// }
}
</style>
