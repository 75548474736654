<template>
	<portal to="lm-modal">
		<div class="Popup__depth3" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ title }}</h5>
					<button class="Popup__close-btn" @click="cancel">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<p class="Popup__plain-text" v-html="desc"></p>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button v-if="!hideCancelBtn" @click="cancel" class="Btn__close">취소</button>
						<button @click="complete" :class="`Btn__${button}`">
							{{ button_text }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalCommon',
	mixins: [MixinModal],
	props: {
		hideCancelBtn: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			title: '',
			desc: '',
			button: '',
			button_text: '',
			completeFn: null,
			cancelFn: null,
		}
	},
	methods: {
		show(title, desc, button, button_text, completeFn, cancelFn) {
			this.isShow = true
			this.title = title
			this.desc = desc
			this.button = button
			this.button_text = button_text
			this.completeFn = completeFn
			this.cancelFn = cancelFn
		},
		cancel() {
			this.$emit('isHide', { button: this.button_text, check: false })
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
		},
		complete() {
			this.$emit('isHide', { button: this.button_text, check: true })
			this.$emit('complete', this.button_text)
			if (this.completeFn) this.completeFn()
		},
	},
}
</script>

<style lang="scss">
.Popup__container {
	.Popup__bottom {
		.Popup__btn-group {
			button.Btn__init {
				background: $PRIMARY_LAB_BLACK;
				color: white;
				text-decoration: none;
			}
		}
	}
}
</style>
