<template>
	<tr class="table-header">
		<th class="header-checkbox">
			<span><input type="checkbox" :checked="isAllChecked" @change="allCheck" /></span>
		</th>
		<th>
			<span>번호</span>
		</th>
		<th>
			<span>로고 이미지</span>
		</th>
		<th>
			<span>브랜드명</span>
		</th>
		<th>
			<span>우선순위</span>
		</th>
		<th>
			<span>상품 수</span>
		</th>
		<th>
			<span>등록일시</span>
		</th>
	</tr>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	computed: {
		...mapState('lms/brand', ['list', 'checkedList']),
		isAllChecked() {
			if (this.list.length === 0) return false
			return this.list.length === this.checkedList.length
		},
	},
	mixins: [MixinHeader],
	methods: {
		...mapMutations('lms/brand', ['setAllCheck', 'initCheckedList']),
		allCheck(event) {
			if (event.target.checked) this.setAllCheck()
			else this.initCheckedList()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
