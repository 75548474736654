<template>
	<div class="search-box" :class="{ focus: isFocused }" @click.stop>
		<input
			class="search-input"
			:placeholder="placeholder"
			@focus="isFocused = true"
			@blur="isFocused = false"
			:value="keyword"
			@input="change"
			@keyup.enter="search"
		/>
		<div class="delete-btn" v-if="keyword.length > 0" @click.stop.prevent="keyword = ''">
			<img :src="deleteImg" />
		</div>
		<div class="qr-btn" v-if="useQr" @click.stop.prevent="showCode">
			<img src="@/assets/svg/common/qr-scan.svg" />
		</div>
		<div class="search-img-wrapper" @click.stop="search">
			<img :src="searchImg" />
		</div>
	</div>
</template>

<script>
import IconSearch from '@/assets/svg/app/Search.svg'
import IconDelete from '@/assets/svg/app/Close-1.svg'

export default {
	name: 'SmallSearchBox',
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		defaultKeyword: {
			type: String,
			default: '',
		},
		useQr: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			searchImg: IconSearch,
			deleteImg: IconDelete,
			isFocused: false,
			keyword: '',
		}
	},
	watch: {
		defaultKeyword(value) {
			this.keyword = value
		},
		isFocused(value) {
			return value ? this.$emit('focus') : this.$emit('blur')
		},
		keyword(value) {
			this.$emit('change', value)
		},
	},
	methods: {
		change(event) {
			event.target.value = event.target.value.trimLeft()
			this.keyword = event.target.value
		},
		search() {
			this.$emit('search', this.keyword)
		},
		showCode() {
			this.$emit('showCode')
		},
	},
}
</script>

<style lang="scss" scoped>
.search-box {
	&.focus {
		border: 1px solid $PRIMARY_LAB_BLACK !important;
	}
}

.delete-btn {
	width: 24px;
	margin-right: 5px;
	cursor: pointer;
}
.qr-btn {
	width: 24px;
	margin-right: 5px;
	cursor: pointer;
}

.search-img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 28px;
	background: $COMMON_BACKGROUND;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	cursor: pointer;
}

.search-input {
	width: 100%;
	height: 100%;
	border: 0;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: 400;
	&::placeholder {
		color: $COLOR_999;
		font-size: 14px;
		font-weight: 400;
	}
}
</style>
