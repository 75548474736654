<template>
	<div class="input-text-wrap">
		<base-item :title="title" :subContentText="subContentText" :required="isRequired">
			<div class="input-wrap">
				<input
					:type="type"
					v-model="inputText"
					:placeholder="placeholder"
					:disabled="isReadOnly || disabled"
					:class="{ error: !isValid, hasSearch: isSearch }"
					:style="`width:${inputWidthSize}px; height:${inputHeightSize}px;`"
					@input="changeValue"
				/>
				<b class="unit" v-if="unit">{{ unit }}</b>
				<img v-if="isSearch" class="btn-search" src="@/assets/svg/common/ic-search.svg" @click="onClickSearch" />
			</div>
			<div v-if="!isValid" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidText }}</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputText',
	components: {
		BaseItem,
	},
	props: {
		value: {
			type: [String, Number, Object, Date],
			default: null,
		},
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		inputValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
		isSearch: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			inputText: '',
		}
	},
	watch: {
		value() {
			this.inputText = this.value
		},
	},
	mounted() {
		this.inputText = this.value
	},
	methods: {
		changeValue(event) {
			let text = ''
			if (this.type == 'number') text = Number(event.target.value.trimLeft())
			else text = event.target.value?.trimLeft()?.trim()
			this.$emit('changeValue', text)
			this.$emit('input', text)
		},
		setValue(value) {
			this.inputText = value
			this.$emit('changeValue', this.inputText)
		},
		onClickSearch() {
			this.$emit('onClickSearch', this.inputText)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-text-wrap {
	.input-wrap {
		position: relative;
		width: fit-content;
		input {
			padding: 12px;
			border: 1px solid $FILE_BORDER;
			border-radius: 5px;
			&:disabled {
				background-color: $LIST_HOVER;
			}
			&.error {
				border-color: $PRIMARY_RED;
			}
			&.hasSearch {
				padding-right: 40px;
				// border-top-right-radius: 0px;
				// border-bottom-right-radius: 0px;
				// border-right: none;
			}
		}
		b.unit,
		.btn-search {
			position: absolute;
			right: 1px;
			top: 1px;
		}

		.btn-search {
			cursor: pointer;
			border-right: 1px solid $FILE_BORDER;
			height: 34px;
			padding: 7px;
			background-color: $COLOR_EEE;
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}
	}

	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
}
</style>
