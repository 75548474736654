<template>
	<div class="product-info-table">
		<div class="title" v-if="title">
			<h4>{{ title }}</h4>
			<div class="btn-wrap" v-if="isBtnGroup">
				<button class="btn" v-for="(btn, index) in btnGroup" :key="`product_btn${index}`" @click="$emit('clickEv', btn.key)">
					<img v-if="btn.image" :src="btn.image" />
					{{ btn.text }}
				</button>
			</div>
		</div>
		<div v-if="!orders.length" class="table-wrap">
			<table>
				<table-header :headers="headers" />
				<tbody>
					<template v-if="isEmpty">
						<tr>
							<td :colspan="headers.length">
								<div class="no-product-list">
									<img src="@/assets/svg/app/no-data_item.svg" />
									<p>상품이 없습니다</p>
									<div v-if="isBtnGroup" class="btn-wrap">
										<button
											class="btn"
											v-for="(btn, index) in btnGroup"
											:key="`product_table_btn${index}`"
											@click="$emit('clickEv', btn.key)"
										>
											<img v-if="btn.image" :src="btn.image" />
											{{ btn.text }}
										</button>
									</div>
								</div>
							</td>
						</tr>
					</template>
					<template v-else>
						<tr v-for="(product, index) in products" :key="`product-row${index}`">
							<td v-for="(header, idx) in headers" :key="`product-column${idx}${index}`">
								<product-info-item
									v-if="header.key == 'info'"
									:product="product"
									:index="index"
									:isRemove="isRemove"
									@delete="$emit('delete', $event)"
								/>
								<div v-else-if="isPriceKey(header.key)" class="price">
									<b>{{ commaNum(product[header.key]) }}원</b>
									<span v-if="header.key == 'sellingPrice' && product.discountPrice">{{
										`(-${product.discountPercent}% / ${commaNum(product.discountPrice)}원)`
									}}</span>
								</div>
								<div v-else>{{ product[header.key] }}</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>
			<product-total-price
				title="견적 금액"
				v-if="products.length > 0 && isShowTotalPrice"
				:sellingPrice="totalSellingPrice"
				:vatPrice="totalVat"
			/>
		</div>
		<div v-else class="divide-table-wrapper">
			<div v-for="(order, orderIndex) in orders" :key="`order_${orderIndex}}`">
				<div style="display: flex; margin: 15px 0">
					<p class="order-info">
						주문일자 <span>{{ getOrderDate(order.createDatetime) }}</span>
					</p>
					<span>&nbsp;&nbsp; | &nbsp;&nbsp;</span>
					<p class="order-info">
						주문번호 <span>{{ order.orderNo }}</span>
					</p>
				</div>
				<table class="divide-table">
					<table-header :headers="headers" />
					<tbody>
						<template>
							<tr v-for="(item, index) in order.items" :key="`product-row${index}`">
								<td v-for="(header, idx) in headers" :key="`product-column${idx}${index}`">
									<product-info-item v-if="header.key == 'info'" :product="item.product" :index="index" />
									<div v-else-if="isPriceKey(header.key)" class="price">
										<b>{{ commaNum(item.orderItemPrice) }}원</b>
										<span v-if="header.key == 'sellingPrice' && item.discountPrice">{{
											`(-${item.discountPercent} / ${commaNum(item.discountPrice)}원)`
										}}</span>
									</div>
									<div v-else-if="header.key === 'orderer'">{{ order.orderer.name }}</div>
									<div v-else>{{ item[header.key] }}</div>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
			<product-total-price
				v-if="isShowTotalPrice"
				style="margin-top: 15px"
				title="견적 금액"
				:sellingPrice="sellingPrice"
				:vatPrice="vatPrice"
			/>
			<p v-if="orders.length > 1" class="caution-text">
				<img src="@/assets/svg/order/icon-caution.svg" />
				다른 주문이 섞여있습니다. 거래명세서를 출력하는 목적에 맞게 선택되었는지 확인해주세요.
			</p>
		</div>
	</div>
</template>

<script>
import ProductTotalPrice from '@/components/product/ProductTotalPrice'
import ProductInfoItem from '@/components/product/ProductInfoItem'
import mixin from '@/mixins'
import TableHeader from '@/components/table/TableHeader'
import { formatDate } from '@/utils/modules/DateUtils'

export default {
	name: 'ProductInfoTable',
	components: {
		ProductTotalPrice,
		ProductInfoItem,
		TableHeader,
	},
	mixins: [mixin],
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		btnGroup: {
			type: Array,
			default: () => [],
		},
		headers: {
			type: Array,
			default: () => [],
		},
		products: {
			type: Array,
			default: () => [],
		},
		orders: {
			type: Array,
			default: () => [],
		},
		isBtnGroup: {
			type: Boolean,
			default: true,
		},
		isRemove: {
			type: Boolean,
			default: false,
		},
		totalPrice: {
			type: Number,
			default: 0,
		},
		vatPrice: {
			type: Number,
			default: 0,
		},
		sellingPrice: {
			type: Number,
			default: 0,
		},
		isShowTotalPrice: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		isEmpty() {
			return this.$_.isEmpty(this.products)
		},
		totalSellingPrice() {
			return this.products.reduce((acc, cur) => acc + cur.sellingPrice, 0)
		},
		totalVat() {
			return this.totalSellingPrice / 10
		},
	},
	methods: {
		isPriceKey(key) {
			return key.indexOf('Price') >= 0 || key.indexOf('price') >= 0 || key.indexOf('salesUnitPrice') >= 0 || key.indexOf('unitPrice') >= 0
		},
		getOrderDate(date) {
			return formatDate(date)
		},
	},
}
</script>

<style lang="scss">
.product-info-table {
	width: 100%;
	margin: 0 auto;
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		h4 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		p {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_666;
			margin-left: 1rem;
		}
		.btn-wrap {
			margin-left: auto;
			display: inline-flex;
			button {
				min-width: 80px;
				height: 30px;
				border-radius: 4px;
				display: inline-flex;
				justify-content: center;
				margin-left: 8px;
			}
		}
	}
	.table-wrap {
		width: 100%;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		overflow: hidden;
		table {
			width: 100%;
			thead {
				border-bottom: 1px solid $COLOR_DDD;
				th {
					height: 40px;
					font-size: 0.875rem;
					font-weight: 700;
					color: $COLOR_666;
					background-color: $HOVER_BACKGROUND;
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					td {
						border-right: 1px solid $LINE_DIVIDER;
						font-size: 0.875rem;
						font-weight: 400;
						&:last-child {
							border-right: 0;
						}
						.price {
							font-weight: 700;
							font-size: 1rem;
							span {
								display: block;
								font-size: 0.75rem;
								font-weight: 700;
								color: $PRIMARY_RED;
							}
						}
					}
					&:last-child {
						border-bottom: 0;
					}
				}
			}
			.no-product-list {
				width: 100%;
				text-align: center;
				> img {
					width: 60px;
					height: 60px;
					margin-top: 60px;
				}
				p {
					font-size: 1rem;
					font-weight: 400;
					color: $COLOR_999;
					display: block;
					margin: 24px 0 1rem;
				}
				.btn-wrap {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-bottom: 40px;
					button {
						min-width: 80px;
						height: 30px;
						border-radius: 4px;
						display: inline-flex;
						justify-content: center;
						margin-left: 8px;
					}
				}
			}
		}
	}
	.divide-table-wrapper {
		width: 100%;
		div {
			.order-info {
				font-weight: 500;
				font-size: 16px;
				span {
					font-weight: 700;
				}
			}
			.divide-table {
				width: 100%;
				border: solid 1px $LINE_DIVIDER;
				thead {
					border-bottom: 1px solid $COLOR_DDD;
					th {
						height: 40px;
						font-size: 0.875rem;
						font-weight: 700;
						color: $COLOR_666;
						background-color: $HOVER_BACKGROUND;
					}
				}
				tbody {
					tr {
						border-bottom: 1px solid $LINE_DIVIDER;
						td {
							border-right: 1px solid $LINE_DIVIDER;
							font-size: 0.875rem;
							font-weight: 400;
							&:last-child {
								border-right: 0;
							}
							.price {
								font-weight: 700;
								font-size: 1rem;
								span {
									display: block;
									font-size: 0.75rem;
									font-weight: 700;
									color: $PRIMARY_RED;
								}
							}
						}
						&:last-child {
							border-bottom: 0;
						}
					}
				}
				.no-product-list {
					width: 100%;
					text-align: center;
					> img {
						width: 60px;
						height: 60px;
						margin-top: 60px;
					}
					p {
						font-size: 1rem;
						font-weight: 400;
						color: $COLOR_999;
						display: block;
						margin: 24px 0 1rem;
					}
					.btn-wrap {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-bottom: 40px;
					}
				}
			}
		}
		.caution-text {
			color: #ff8248;
			font-weight: 500;
			font-size: 14px;
			padding: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
