<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>팝업 이벤트 {{ this.title }}</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>이벤트 정보</h5>
				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					v-model="formData.title"
					@changeValue="data => (formData.title = data)"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<div v-if="id">
					<base-item :title="'노출여부'">
						<switch-slider :sliderColor="'#0dc577'" :defaultValue="!isHide" @change="onClickHide"></switch-slider>
					</base-item>
				</div>
				<upload-images
					:isValidImage="isValid.image"
					inValidImageText="이미지를 업로드해주세요"
					:isValidLink="isValid.link"
					:inValidLinkText="inValidLinkText"
					:inputValueContent="formData.image.image"
					:inputValueUrl="formData.link"
					@changeValue="data => (formData.image = data)"
					@changeLinkValue="data => (formData.link = data)"
				/>
			</div>
			<div>
				<h5>이벤트 설정</h5>
				<input-radio
					title="타겟 지정"
					@changeValue="data => (formData.targetType = data.id)"
					:list="targetStatus"
					:tab="formData.targetType"
				/>
				<!-- <target-select
					title="타겟 직접 선택"
					v-if="formData.targetType == 'INSTITUTES'"
					@changeValue="data => (formData.targetInstituteIds = data)"
				/> -->
				<input-date-picker title="노출 시작일" :inputValue="formData.startDatetime" @changeValue="data => (formData.startDatetime = data)" />
				<input-date-picker title="노출 종료일" :inputValue="formData.endDatetime" @changeValue="data => (formData.endDatetime = data)" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="onClickOk">등록</base-button>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputDatePicker from '@/views/common/components/formItem/InputDatePicker.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import UploadImages from '../components/UploadImages.vue'
import TargetSelect from '../../components/target/TargetSelect.vue'
import { ToastType } from '@/utils/define/ToastType'
import PageName from '@/utils/define/PageName'
import SwitchSlider from '@/views/common/components/button/SwitchSlider.vue'

export default {
	props: {
		id: {
			type: [Number, String],
		},
	},
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputDatePicker,
		InputRadio,
		UploadImages,
		TargetSelect,
		SwitchSlider,
	},
	data() {
		return {
			formData: {
				title: '',
				image: {},
				link: '',
				targetType: 'ALL',
				// targetInstituteIds: [],
				startDatetime: this.$DateTime.now().toJSDate(),
				endDatetime: this.$DateTime.now().plus({ day: 7 }).toJSDate(),
			},
			isHide: false,
			isValid: {
				title: true,
				image: true,
				link: true,
			},
			inValidLinkText: '링크를 입력해주세요',
		}
	},
	async created() {
		if (this.id) {
			await this.getDetail(this.id)
			this.setData()
		}
	},
	computed: {
		...mapState('lms/event', ['item']),
		okText() {
			return this.id ? '수정' : '등록'
		},
		title() {
			return this.id ? '수정' : '만들기'
		},
		targetStatus() {
			return [
				{
					id: 'ALL',
					title: '전체',
				},
				// {
				// 	id: 'UNIVERSITY',
				// 	title: '대학교',
				// },
				// {
				// 	id: 'COMPANY',
				// 	title: '기업',
				// },
				// {
				// 	id: 'GOV',
				// 	title: '정출연',
				// },
				// {
				// 	id: 'INSTITUTES',
				// 	title: '특정 소속만',
				// },
				// {
				// 	id: 'TARGET_SAGMENT',
				// 	title: '특정 세그먼트',
				// },
			]
		},
	},
	methods: {
		...mapActions('lms/event', ['createPopup', 'getDetail', 'editPopup']),
		onClickHide(value) {
			this.isHide = value
		},
		onClickOk() {
			if (this.id) this.onEdit()
			else this.onAdd()
		},
		async onEdit() {
			if (!this.validate()) return
			try {
				const formData = new FormData()
				formData.append('id', this.id)
				formData.append('isHide', this.isHide)
				Object.keys(this.formData).forEach(key => {
					if (key === 'image') formData.append(key, this.formData[key]['image'])
					else if (key === 'startDatetime' || key === 'endDatetime')
						formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
					else formData.append(key, this.formData[key])
				})
				await this.editPopup(formData)
				this.$root.toast('팝업 이벤트 수정 완료', '팝업 이벤트 수정을 완료하였습니다.', ToastType.SUCCESS)
				this.$router.replace({
					name: PageName.Lms.EventDetail,
					params: {
						id: this.id,
					},
				})
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		async onAdd() {
			if (!this.validate()) return
			try {
				const formData = new FormData()
				formData.append('isHide', false)
				Object.keys(this.formData).forEach(key => {
					if (key === 'image') formData.append(key, this.formData[key][key])
					else if (key === 'startDatetime' || key === 'endDatetime')
						formData.append(key, this.$DateTime.fromJSDate(this.formData[key]).toFormat('yyyy-MM-dd HH:mm'))
					else formData.append(key, this.formData[key])
				})
				await this.createPopup(formData)
				this.$root.toast('팝업 이벤트 등록 완료', '팝업 이벤트 등록을 완료하였습니다.', ToastType.SUCCESS)
				this.$router.replace({
					name: PageName.Lms.Event,
				})
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		validate() {
			let isValid = true
			this.isValid.title = true
			this.isValid.image = true
			this.isValid.link = true

			if (!this.formData.title) {
				isValid = false
				this.isValid.title = false
			}
			if (!this.formData.image || !this.formData.image.image) {
				isValid = false
				this.isValid.image = false
			}
			if (!this.formData.link) {
				isValid = false
				this.inValidLinkText = '링크를 입력해주세요'
				this.isValid.link = false
			} else if (!this.validateUrl(this.formData.link)) {
				isValid = false
				this.inValidLinkText = '프로토콜을 확인해 주세요'
				this.isValid.link = false
			}

			return isValid
		},
		validateUrl(string) {
			try {
				const newUrl = new URL(string)
				return newUrl.protocol === 'http:' || newUrl.protocol === 'https:'
			} catch (e) {
				return false
			}
		},
		setData() {
			this.isHide = this.item.isHide
			Object.keys(this.item).forEach(key => {
				switch (key) {
					case 'title':
					case 'link':
						this.formData[key] = this.item[key]
						break
					case 'startDatetime':
					case 'endDatetime':
						this.formData[key] = new Date(this.item[key])
						break
					case 'image':
						this.formData.image[key] = this.item[key]
						break
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
::v-deep .switch {
	width: 68px;
	height: 31px;
	max-width: 100%;
	max-height: 100%;
}
::v-deep .slider::before {
	width: 28px;
	height: 28px;
}
::v-deep .switch input:checked + .slider:before {
	-webkit-transform: translateX(37px);
	-ms-transform: translateX(37px);
	transform: translateX(37px);
}
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
</style>
