<template>
	<div class="brand-detail">
		<div class="content-header">
			<div class="title">
				<h4>{{ detail.name }}</h4>
				<div class="subtitle">
					<p>번호: {{ detail.id }}</p>
					<p>등록일: {{ createDatetime }}</p>
				</div>
			</div>
			<div class="right-btn-group">
				<button class="btn-default" @click="$router.push({ name: PageName.Lms.BrandEdit, params: $route.params.id })">수정</button>
			</div>
		</div>
		<div class="content-wrapper">
			<line-tab :list="tabs" :value="selectedTab" @change="changeTab" />
			<div class="content info" v-if="selectedTab === 'info'">
				<info-list title="기본 정보" :list="baseInfo" />
			</div>
			<div class="content info" v-else-if="selectedTab === 'productInfo'">
				<info-list title="상품 요약" :list="productSummaryInfo" />
				<div class="product-list-wrapper">
					<p>상품목록 {{ `(${commaNum(productList.count)})` }}</p>
					<div class="table-wrapper">
						<brand-table :list="productList.list" />
					</div>
					<pagination :total="productList.count" :defaultOffset="10" @page="pagination"></pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixin from '@/mixins'
import LineTab from '@/components/tab/LineTab'
import InfoList from '@/views/common/components/InfoList.vue'
import BrandTable from '../components/DetailTable.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'

export default {
	name: 'detail',
	mixins: [mixin],
	components: {
		InfoList,
		LineTab,
		BrandTable,
		Pagination,
	},
	data() {
		return {
			tabs: [
				{ name: '브랜드 정보', value: 'info' },
				{ name: '상품목록', value: 'productInfo' },
			],
			selectedTab: 'info',
			length: 10,
			offset: 0,
		}
	},
	computed: {
		...mapState('lms/brand', ['detail', 'productSummary', 'productList']),
		createDatetime() {
			return this.detail.createDatetime ? this.$DateTime.fromISO(this.detail.createDatetime).toFormat('yyyy-MM-dd') : '-'
		},
		baseInfo() {
			return [
				{ title: '브랜드명', value: this.detail.name },
				{ title: '우선순위', value: this.detail.isRecommend ? '추천 브랜드' : '일반', type: 'status-border' },
				{
					title: '로고 이미지',
					value: [{ url: this.detail.image }],
					type: 'image',
					style: 'width: 340px; height: 120px; object-fit: contain;',
					isSingleRow: true,
				},
				{ title: '검색 태그', value: this.detail.tags, type: 'tag' },
			]
		},
		productSummaryInfo() {
			return [
				{
					title: '판매중인 상품',
					value: this.productSummary.productInfoCount > 0 ? `${this.commaNum(this.productSummary.productInfoCount)}개` : '-',
				},
				{
					title: '판매 중지한 상품',
					value: this.productSummary.productInfoBlockCount > 0 ? `${this.commaNum(this.productSummary.productInfoBlockCount)}개` : '-',
				},
			]
		},
	},
	async created() {
		await this.getDetail({ brandId: this.$route.params.id })
		await this.getProductList({ brandId: this.$route.params.id, length: this.length, offset: this.offset })
		await this.getProductSummary({ brandId: this.$route.params.id })
	},
	methods: {
		...mapActions('lms/brand', ['getDetail', 'getProductList', 'getProductSummary']),
		...mapActions('order', ['lookupOrderList', 'lookupOrderListSummary', 'changeEstimatedDate', 'downloadOrderList']),
		changeTab(val) {
			this.selectedTab = val
		},
		async pagination(page) {
			this.length = page.length
			this.offset = page.offset
			await this.getProductList({ brandId: this.$route.params.id, length: this.length, offset: this.offset })
		},
	},
}
</script>

<style lang="scss" scoped>
.brand-detail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					position: relative;
					&:last-child {
						padding-left: 20px;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
			}
		}
	}
	.content-wrapper {
		padding: 0 30px 30px 30px;
		width: 100%;
		.product-list-wrapper {
			p {
				font-weight: 700;
				font-size: 20px;
				text-align: start;
				padding-bottom: 10px;
			}
			.table-wrapper {
				border-top: solid 1px $LINE_DIVIDER;
			}
		}
	}
}
</style>

<style lang="scss">
.brand-detail {
	.line-tab {
		padding: 0 0;
		height: 50px;
		margin-bottom: 40px;
		.tab {
			font-weight: 400;
			&.active {
				border-color: $PRIMARY_YELLOW;
				font-weight: 700;
			}
		}
	}
	.search-wrap {
		.search-box {
			width: 300px;
		}
	}
}
</style>
