<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset" class="modal-wrapper">
		<template class="modal-content" #modal-content v-if="filter">
			<filter-list ref="filter-buyer-verified" title="본인 인증" :filterCookieName="filterCookieName.verified" :list="filter.verified" />
			<filter-list ref="filter-buyer-sms" title="마케팅 수신동의 (SMS)" :filterCookieName="filterCookieName.sms" :list="filter.sms" />
			<filter-list ref="filter-buyer-email" title="마케팅 수신동의 (이메일)" :filterCookieName="filterCookieName.email" :list="filter.email" />
			<filter-list ref="filter-buyer-push" title="마케팅 수신동의 (PUSH)" :filterCookieName="filterCookieName.push" :list="filter.push" />
		</template>
	</modal-filter>
</template>

<script>
import ModalFilter from '@/views/common/modals/ModalFilterBase.vue'
import FilterList from '@/views/common/components/filter/FilterList.vue'
import { mapState, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	components: {
		ModalFilter,
		FilterList,
	},
	computed: {
		...mapState('buyer/list', ['filter', 'filterCookieName']),
	},
	methods: {
		...mapMutations('buyer/list', ['setFilter']),
		async show() {
			this.setFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			const params = {
				isVerified: this.$refs['filter-buyer-verified'].checkedList ?? [],
				isNotiSMS: this.$refs['filter-buyer-sms'].checkedList ?? [],
				isNotiEmail: this.$refs['filter-buyer-email'].checkedList ?? [],
				isNotiPush: this.$refs['filter-buyer-push'].checkedList ?? [],
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', ToastType.SUCCESS)
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
	},
}
</script>

<style lang="scss" scoped></style>
