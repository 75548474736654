<template>
	<tr class="table-item">
		<td v-if="visibleColumns.includes('상태')" @click="goToDetail">
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status', statusClass]"> {{ item.status.name }} </span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적 만료일')" @click="goToDetail">
			<div class="datetime">
				<span :class="{ expired: diffDate <= 0 }">
					{{ item.expireDatetime ? $DateTime.fromISO(item.expireDatetime).toFormat('yyyy-MM-dd T') : '-' }}
				</span>
				<p v-if="item.expireDatetime" :class="{ expired: diffDate <= 0, warning: diffDate <= 7 && diffDate > 0 }">
					<template v-if="diffDate <= 0"> 만료됨 </template>
					<template v-else-if="diffDate <= 7"> {{ diffDate }}일 남음 </template>
				</p>
			</div>
		</td>
		<td v-if="visibleColumns.includes('작성자')" @click="goToDetail">
			<div>
				<span>{{ item.writer.name }}</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적번호')">
			<div class="flex" style="min-width: 180px; max-width: 250px" @click="copy">
				<span style="text-decoration: underline">
					{{ item.no }}
				</span>
				<button v-if="isShowCopyButton" class="btn" @click="copy">복제</button>
			</div>
		</td>
		<td v-if="visibleColumns.includes('상품정보')" @click="goToDetail">
			<div>
				<span>{{ item.productName }}</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적금액')" @click="goToDetail">
			<div>
				<span>{{ commaNum(item.totalSellingPrice) }}원</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적서 담당자')" @click="goToDetail">
			<div class="info-wrap">
				<p class="info-title">{{ item.manager.name }}</p>
				<p class="info-sub">{{ item.manager.instituteName }}</p>
			</div>
		</td>
		<td v-if="visibleColumns.includes('담당자 연락처')" @click="goToDetail">
			<div>
				<span>{{ isData(item.manager.phone) }}</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('담당자 이메일')" @click="goToDetail">
			<div>
				<span>{{ isData(item.manager.email) }}</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('수신자')" @click="goToDetail">
			<div class="info-wrap">
				<p class="info-title">{{ item.recipient.name }}</p>
				<p class="info-sub">{{ item.recipient.instituteName }}</p>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적 생성일')" @click="goToDetail">
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd') : '-' }}</span>
			</div>
		</td>
		<td v-if="visibleColumns.includes('견적일')" @click="goToDetail">
			<div class="datetime">
				<span>{{ item.estimateDatetime ? $DateTime.fromISO(item.estimateDatetime).toFormat('yyyy-MM-dd') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import { Estimate } from '@/utils/define/ItemCode'
import { dateDiff } from '@/utils/modules/DateUtils'
import { mapState } from 'vuex'

export default {
	props: {
		item: {
			type: Object,
		},
		isShowCopyButton: {
			type: Boolean,
			default: true,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		...mapState('estimate/list', ['visibleColumns']),
		statusClass() {
			return Estimate.statusToClass(this.item.status.code)
		},
		diffDate() {
			return dateDiff(new Date(), this.item.expireDatetime)
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Estimate.EstimateDetail,
				params: {
					id: this.item.id,
				},
			})
		},
		copy() {
			this.$router
				.push({
					name: PageName.Estimate.EstimateForm,
					params: {
						mode: 'copy',
					},
					query: {
						id: this.item.id,
					},
				})
				.catch(() => {})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';

.datetime {
	.expired {
		color: $COLOR_999;
	}
	.warning {
		color: $PRIMARY_RED;
	}
}
</style>
