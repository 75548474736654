import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCategoryList: () => 'admin/category/list',
	addProduct: () => 'admin/productInfo',
	updateProduct: () => 'admin/productInfo',
	addProductImage: () => 'admin/productInfo/image',
}

const api = {
	lookupCategoryList: () => axiosInstance.get(endPoint.lookupCategoryList()),
	addProduct: formData =>
		axiosInstance.post(endPoint.addProduct(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	updateProduct: formData =>
		axiosInstance.patch(endPoint.updateProduct(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
	addProductImage: formData =>
		axiosInstance.post(endPoint.addProductImage(), formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),
}

export default api
