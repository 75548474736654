<template>
	<tr class="table-header">
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>문의 유형</span>
		</th>
		<th>
			<span>문의번호</span>
		</th>
		<th>
			<span>주문 아이템 번호</span>
		</th>
		<th>
			<span>문의 내용</span>
		</th>
		<th>
			<span>작성자</span>
		</th>
		<th>
			<span>작성자 연락처</span>
		</th>
		<th>
			<span>작성자 연구실</span>
		</th>
		<th>
			<span>문의일시</span>
		</th>
		<th>
			<span>답변일시</span>
		</th>
	</tr>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
	methods: {
		...mapActions('inquiry/list', ['setLoadParams']),
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
