<template>
	<tr class="table-item">
		<td>
			<div>
				<span>{{ item.id }}</span>
			</div>
		</td>
		<td>
			<div>
				<span :class="['status-text', statusClass]"> {{ statusText }} </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ createDatetime }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.fileName }}개</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ updateDatetime }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.fullRows ? `${item.fullRows}개` : '...' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.currentRows ? `${item.currentRows}개` : '...' }}</span>
			</div>
		</td>
		<td>
			<div class="lg">
				{{ isData(item.errorMessage) }}
			</div>
		</td>
	</tr>
</template>
<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import { UpdateProductPrice } from '@/utils/define/ItemCode'

export default {
	mixins: [mixin, MixinItem],
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		statusText() {
			return UpdateProductPrice.statusToString(this.item.status)
		},
		statusClass() {
			return UpdateProductPrice.statusToClass(this.item.status)
		},
		createDatetime() {
			return this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd T') : '-'
		},
		updateDatetime() {
			return this.item.updateDatetime ? this.$DateTime.fromISO(this.item.updateDatetime).toFormat('yyyy-MM-dd T') : '-'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-item.scss';
</style>
