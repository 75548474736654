<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div>
				<span>
					{{ item.category.name }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 750px; max-width: 850px; font-weight: 700">
				<span>
					{{ item.title }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.user.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'
export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'FAQCreate',
				query: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
