import { TableField } from '@/utils/define/table/JwTable'
import { formatDate } from '@/utils/modules/DateUtils'

const orderManagementTable = {
	width: '100%',
	height: '800px',
	options: {
		selectColumnKey: TableField.Status,
		isCheckbox: true,
		table: {
			style: {},
		},
		header: {
			style: {
				height: '60px;',
				color: '#666666',
				fontSize: '14px',
				backgroundColor: '#FFFFFF',
			},
		},
		row: {
			style: {
				height: '60px',
			},
			click: {
				rowDataName: TableField.Status,
				keyName: 'id',
				clickEvent: () => {},
			},
		},
		columns: [
			{
				key: TableField.Status,
				type: 'statusWithDetail',
				name: '주문상태',
				style: {
					width: '100px',
				},
				classes: [],
				clickEvent: () => {},
			},
			{
				key: TableField.OrderNo,
				name: '주문번호',
				type: 'textWithClickEvent',
				style: {
					width: '180px',
				},
				classes: [],
				clickEvent: () => {},
			},
			{
				key: TableField.OrderItemNo,
				name: '주문 아이템 번호',
				type: 'textWithClickEvent',
				style: {
					width: '180px',
				},
				classes: [],
				clickEvent: () => {},
			},
			{
				key: TableField.Info,
				type: 'item',
				name: '상품 정보',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: TableField.ReleaseVolume,
				name: '출고수량',
				type: 'textButtonWithText',
				style: {
					width: '130px',
				},
				classes: [],
				clickEvent: () => {},
			},
			{
				key: TableField.EstimatedReleaseDate,
				name: '예상 출고일',
				type: 'textWithDatePicker',
				style: {
					width: '200px',
				},
				classes: [],
				clickEvent: () => {},
				textHandler: value => {
					return value === null || value === '' ? '예상 출고일 선택' : value
				},
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.DeliveryMethod,
				name: '배송방식',
				type: 'textWithClickEvent',
				style: {
					width: '250px',
				},
				classes: [],
				clickEvent: () => {},
				textHandler: value => {
					return value === null || value === '' ? '배송방식 선택' : value
				},
			},
			{
				key: TableField.IndividualContact,
				name: '개별연락',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.QuickService,
				name: '퀵 서비스',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.OrderLab,
				name: '주문 연구실',
				style: {
					width: '130px',
				},
				classes: [],
			},
			{
				key: TableField.OrderLabId,
				name: '주문 연구실 ID',
				style: {
					width: '140px',
				},
				classes: [],
			},
			{
				key: TableField.Orderer,
				name: '주문자',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Address,
				name: '주소',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: TableField.DeliveryRequest,
				name: '배송 요청사항',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.HiddenMemo,
				name: '숨김 메모',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.OrderDatatime,
				name: '주문일',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.StockCheckDatetime,
				name: '재고확인일',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.DeliveryStartDatetime,
				name: '배송시작일',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.DeliveryCompleteDatetime,
				name: '배송완료일',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.ConfirmOrderDatetime,
				name: '구매확정일',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.Brand,
				name: '브랜드',
				style: {
					width: '150px',
				},
				classes: [],
			},
			{
				key: TableField.ProductNo,
				name: '제품번호',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.Sku,
				name: 'SKU',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.Option,
				name: '옵션',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.PurchaseUnitPrice,
				name: '매입단가',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.ProductPrice,
				name: '소비자가(단가)',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.OrderPrice,
				name: '주문금액',
				type: 'textButtonWithText',
				style: {
					width: '180px',
				},
				classes: [],
				clickEvent: () => {},
			},
			{
				key: TableField.OrderPriceVat,
				name: '주문 금액(VAT 포함)',
				style: {
					width: '140px',
				},
				classes: [],
			},
			{
				key: TableField.PaymentNo,
				name: '결제번호',
				style: {
					width: '140px',
				},
				classes: [],
			},
			{
				key: TableField.PaymentStatus,
				name: '결제상태',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.LabSettlementStatus,
				name: '연구실 정산상태',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.SellerSettlementStatus,
				name: '판매자 정산상태',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.SettlementMonth,
				name: '정산월',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.PaymentMethod,
				name: '결제방법',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.ApproveDatetime,
				name: '승인일시',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.CardApproveNo,
				name: '카드 승인번호',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.NationalTaxApproveNo,
				name: '국세청 승인번호',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.DepositDetails,
				name: '실제 입금내역',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.PaymentDatetime,
				name: '결제일시',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.DepositDatetime,
				name: '입금일시',
				style: {
					width: '120px',
				},
				classes: [],
				dataHandler: value => {
					return formatDate(value)
				},
			},
			{
				key: TableField.SellerInfo,
				name: '판매자 정보',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.SellerMemo,
				name: '판매자 메모',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: TableField.Platform,
				name: '플랫폼',
				style: {
					width: '120px',
				},
				classes: [],
			},
		],
		tableData: {
			Status: [],
			OrderNo: [],
			OrderItemNo: [],
			Info: [],
			ReleaseVolume: [],
			EstimatedReleaseDate: [],
			DeliveryMethod: [],
			IndividualContact: [],
			QuickService: [],
			OrderLab: [],
			OrderLabId: [],
			Orderer: [],
			Address: [],
			DeliveryRequest: [],
			HiddenMemo: [],
			OrderDatatime: [],
			StockCheckDatetime: [],
			DeliveryStartDatetime: [],
			DeliveryCompleteDatetime: [],
			ConfirmOrderDatetime: [],
			Brand: [],
			ProductNo: [],
			Sku: [],
			Option: [],
			PurchaseUnitPrice: [],
			ProductPrice: [],
			OrderPrice: [],
			OrderPriceVat: [],
			PaymentStatus: [],
			LabSettlementStatus: [],
			SellerSettlementStatus: [],
			SettlementMonth: [],
			PaymentMethod: [],
			ApproveDatetime: [],
			CardApproveNo: [],
			NationalTaxApproveNo: [],
			DepositDetails: [],
			PaymentDatetime: [],
			DepositDatetime: [],
			SellerInfo: [],
			SellerMemo: [],
			Platform: [],
		},
	},
}

const orderManagementTableAllColumns = [
	'주문상태',
	'주문번호',
	'주문 아이템 번호',
	'상품 정보',
	'출고수량',
	'예상 출고일',
	'배송방식',
	'개별연락',
	'퀵 서비스',
	'주문 연구실',
	'주문 연구실 ID',
	'주문자',
	'주소',
	'배송 요청사항',
	'숨김 메모',
	'주문일',
	'재고확인일',
	'배송시작일',
	'배송완료일',
	'구매확정일',
	'브랜드',
	'제품번호',
	'SKU',
	'옵션',
	'매입단가',
	'소비자가(단가)',
	'주문금액',
	'주문 금액(VAT 포함)',
	'결제번호',
	'결제상태',
	'연구실 정산상태',
	'판매자 정산상태',
	'정산월',
	'결제방법',
	'승인일시',
	'카드 승인번호',
	'국세청 승인번호',
	'실제 입금내역',
	'결제일시',
	'입금일시',
	'판매자 정보',
	'판매자 메모',
	'플랫폼',
]

export { orderManagementTable, orderManagementTableAllColumns }
