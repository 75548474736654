<template>
	<b-container class="checkbox-list" :class="{ all: isOpen }" :style="containerStyle">
		<ul>
			<li>
				<input class="checkbox" type="checkbox" :id="`all_${type}`" v-model="allCheck" />
				<label :for="`all_${type}`">전체</label>
			</li>
			<li v-for="(item, index) in list" :key="`item_${type}_${index}`">
				<input
					class="checkbox"
					type="checkbox"
					:id="`item_${type}_${index}`"
					:value="item.value"
					v-model="checkedList"
					@change="checked(item.value)"
				/>
				<label :for="`item_${type}_${index}`" v-b-tooltip.hover :title="item.name.length > 6 ? item.name : ''">
					{{ item.name }}
				</label>
			</li>
		</ul>
	</b-container>
</template>

<script>
export default {
	name: 'CheckboxList',
	props: {
		list: {
			type: Array,
			default: [],
		},
		isOpen: {
			type: Boolean,
			default: false,
		},
		filtered: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: '',
		},
		containerStyle: {
			type: Object,
			default: () => ({}),
		},
	},
	components: {},
	data() {
		return {
			checkedList: [],
			allCheck: false,
		}
	},
	watch: {
		filterList(val) {
			let values = []
			val.forEach(item => {
				values.push(item.value)
			})
			this.checkedList = values

			if (val.length == 0) {
				this.checkedList = []
			}
		},
		allCheck(val) {
			if (val == true) {
				let checkItems = []

				this.list.forEach(item => {
					checkItems.push({
						name: item.name,
						value: item.value,
						type: this.type,
					})
				})

				this.$emit('checkedList', checkItems)
			} else {
				this.checkedList = []
				this.$emit('checkedList', [])
			}
		},
		checkedList(val) {
			if (val.length == 0) {
				this.allCheck = false
			}
		},
	},
	computed: {
		filterList() {
			return this.filtered
		},
	},
	methods: {
		checked(value) {
			let checkItems = []

			this.list.forEach(item => {
				if (this.checkedList.includes(item.value)) {
					checkItems.push({
						name: item.name,
						value: item.value,
						type: this.type,
					})
				}
			})

			this.$emit('checkedList', checkItems)
		},
	},
}
</script>

<style lang="scss" scoped>
.checkbox-list {
	max-height: 72px;
	// overflow-y: auto;
	// overflow-x: hidden;
	transition: 0.5s;
	padding: 0 10px 0 18px;
	max-width: unset;
	&.all {
		max-height: 200px;
		height: 100%;
	}
	ul {
		width: 100%;
		text-align: left;
		padding-top: 12px;
		li {
			display: inline-flex;
			width: 138px;
			margin-right: 10px;
			margin-bottom: 10px;
			position: relative;
			align-items: center;
			height: 20px;
			label {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 400;
				margin-left: 10px;
				max-width: calc(100% - 30px);
				cursor: pointer;
				position: relative;
			}
			label:hover ~ .tooltip {
				display: block;
				visibility: visible;
				opacity: 1;
			}
		}
	}
}
</style>
