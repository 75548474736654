import PageName from '@/utils/define/PageName.js'
import RoutePath from '@/utils/define/RoutePath'

const lmsMenuList = [
	{
		name: '주문 관리',
		icon: 'sidebar-icon3.svg',
		classification: 'lms_order',
		list: [
			{
				name: '주문 건별 목록',
				routeName: PageName.Order.OrderItemList,
				path: RoutePath.Order.OrderItemList,
			},
			{
				name: '주문 상품별 목록',
				routeName: PageName.Order.OrderProductManagement,
				path: RoutePath.Order.OrderProductManagement,
			},
		],
	},
	{
		name: '견적 관리',
		icon: 'sidebar-icon3.svg',
		classification: 'lms_estimate',
		list: [
			{
				name: '구매대행&상품요청 목록',
				routeName: PageName.Estimate.RequestProductList,
				path: RoutePath.Estimate.RequestProductList,
			},
			{
				name: '견적함',
				routeName: PageName.Estimate.EstimateList,
				path: RoutePath.Estimate.EstimateList,
			},
		],
	},
	{
		name: 'CS관리',
		icon: 'sidebar-icon4.svg',
		classification: 'lms_cs',
		list: [
			{
				name: '취소/교환/반품 목록',
				routeName: PageName.CS.CancelRefund,
				path: RoutePath.Cs.CancelRefund,
			},
			{
				name: '1:1 문의 관리',
				routeName: PageName.CS.Inquiry,
				path: RoutePath.Cs.Inquiry,
			},
		],
	},
	{
		name: '상품 관리',
		icon: 'sidebar-icon2.svg',
		classification: 'lms_product',
		list: [
			{
				name: '상품 목록',
				routeName: PageName.Product.ProductList,
				path: RoutePath.Product.ProductList,
			},
			{
				name: '상품 등록하기',
				routeName: PageName.Product.ProductRegistration.Main,
				path: RoutePath.Product.ProductRegistration.Main,
			},
			{
				name: '상품 등록 승인하기',
				routeName: PageName.Product.ApprovalProductList,
				path: RoutePath.Product.ApprovalProductList,
			},
			{
				name: '상품 가격 일괄 업데이트',
				routeName: PageName.Product.UpdateProductPriceList,
				path: RoutePath.Product.UpdateProductPriceList,
			},
		],
	},
	{
		name: '구매자 관리',
		icon: 'sidebar-icon3.svg',
		classification: 'lms_buyer',
		list: [
			{
				name: '유저 목록',
				routeName: PageName.Buyer.BuyerList,
				path: RoutePath.Buyer.BuyerList,
			},
			{
				name: '연구실 목록',
				routeName: PageName.Buyer.LabList,
				path: RoutePath.Buyer.LabList,
			},
			{
				name: '결제 관리',
				routeName: PageName.Buyer.PaymentManagement,
				path: RoutePath.Buyer.PaymentManagement,
			},
			{
				name: '사업자등록증 관리',
				routeName: PageName.Buyer.BusinessRegistrationManagement,
				path: RoutePath.Buyer.BusinessRegistrationManagement,
			},
		],
	},
	{
		name: '판매자 관리',
		icon: 'sidebar-icon2.svg',
		classification: 'lms_seller',
		list: [
			{
				name: '판매자 목록',
				routeName: PageName.Seller.SellerList,
				path: RoutePath.Seller.SellerList,
			},
			// {
			// 	name: '(미구현) 정산 관리',
			// 	routeName: PageName.Seller.SettlementManagement,
			// 	path: RoutePath.Seller.SettlementManagement,
			// },
		],
	},
	{
		name: 'LMS 관리',
		icon: 'sidebar-icon3.svg',
		classification: 'lms_lms',
		list: [
			{
				name: '이벤트 페이지 관리',
				routeName: PageName.Lms.Banner,
				path: RoutePath.Lms.Banner,
			},
			{
				name: '팝업 이벤트 관리',
				routeName: PageName.Lms.Event,
				path: RoutePath.Lms.Event,
			},
			{
				name: '공지사항 관리',
				routeName: PageName.Lms.Notice,
				path: RoutePath.Lms.Notice,
			},
			{
				name: '자주묻는 질문 관리',
				routeName: PageName.Lms.FAQ,
				path: RoutePath.Lms.Faq,
			},
			{
				name: '브랜드 관리',
				routeName: PageName.Lms.Brand,
				path: RoutePath.Lms.Brand,
			},
		],
	},
]

const lmpMenuList = [
	{
		name: '기관 관리',
		icon: 'sidebar-icon9.svg',
		classification: 'lmp_institute',
		list: [
			{
				name: '라이선스 부여',
				routeName: PageName.Institute.LicenseCreate,
				path: RoutePath.Institute.LicenseCreate,
			},
		],
	},
	{
		name: 'LMP 관리',
		icon: 'sidebar-icon1.svg',
		classification: 'lmp_lmp',
		list: [
			{
				name: '메인 팝업 관리',
				routeName: PageName.Lmp.LmpEvent,
				path: RoutePath.Lmp.LmpEvent,
			},
		],
	},
	{
		name: 'API 관리(생기원)',
		icon: 'sidebar-icon4.svg',
		classification: 'chemical_api',
		list: [
			{
				name: '물품 요청 목록',
				routeName: PageName.ChemicalAPI.Request,
				path: RoutePath.ChemicalAPI.Request,
			},
		],
	},
]

const menuList = lmsMenuList.concat(lmpMenuList)

export { menuList, lmsMenuList, lmpMenuList }
