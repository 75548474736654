<template>
	<tr class="table-header">
		<th>
			<span>요청 ID</span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>물품 이름</span>
		</th>
		<th>
			<span>제조사</span>
		</th>
		<th>
			<span>제품번호</span>
		</th>
		<th>
			<span>CAS No.</span>
		</th>
		<th>
			<span>용량</span>
		</th>
		<th>
			<span>요청일</span>
		</th>
		<th>
			<span>MSDS Link</span>
		</th>
		<th>
			<span>메모/거절사유</span>
		</th>
		<th>
			<span>매칭된 물품 ID</span>
		</th>
	</tr>
</template>
<script>
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
