<template>
	<div class="status-counter-list">
		<template v-for="(item, index) of list" class="list-wrapper">
			<status-counter-item :id="item.id" :title="item.title" :count="item.count" :active="item.active" @active="$emit('active', $event)" />
			<template v-if="index < list.length - 1">
				<img v-if="index === 0 || index === list.length - 2" src="@/assets/svg/order/line_separator.svg" />
				<img v-else src="@/assets/svg/order/arrow_up.svg" />
			</template>
		</template>
	</div>
</template>

<script>
import StatusCounterItem from '@/views/order/orderProduct/components/StatusCounterItem.vue'

export default {
	name: 'StatusCounterList',
	components: {
		StatusCounterItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.status-counter-list {
	width: 100%;
	min-width: 1100px;
	display: flex;
	gap: 40px;
	justify-content: space-between;
	.list-wrapper {
		display: inline-flex;
		align-items: center;
		width: 100%;
		> img {
			width: 84px;
			height: 20px;
			object-fit: contain;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
	}
}
</style>
