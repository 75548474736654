import axios from 'axios'

// const BOT_TOKEN = 'xoxb-274978451233-5784121517139-7Z1qo5IjSyqcGuVn0fhT5lkL'

const sendMessage = async (body, title) => {
	delete body.title
	const result = await axios({
		method: 'post',
		url: 'https://7t4ght3xkax7zjglgrqds5g3xi0gphkh.lambda-url.ap-northeast-2.on.aws/?' + encodeURIComponent('https://hooks.slack.com/services/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'),
		data: {
			text: `[CMS] ${title}\n` + JSON.stringify(body, null, 2),
		},
		headers: {
			'Content-type': 'application/json',
			// Authorization: `Bearer ${BOT_TOKEN}`,
		},
	})
	console.log(result)
	return result
}

export { sendMessage }
