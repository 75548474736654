<template>
	<search-row class="search-row" :title="title" :contentStyle="{ padding: 0 }">
		<checkbox-list
			:type="title"
			:list="list"
			:filtered="filterList"
			:containerStyle="{ maxHeight: 'none' }"
			@checkedList="$emit('change', $event)"
		/>
	</search-row>
</template>

<script>
import SearchRow from '@/views/common/components/search/SearchRow.vue'
import CheckboxList from '@/views/common/components/search/CheckboxList.vue'

export default {
	name: 'SearchCheckList',
	components: {
		SearchRow,
		CheckboxList,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		filterList: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped></style>
