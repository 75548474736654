<template>
	<div class="detail-wrapper">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ detail.name }}</h2>
				<h6>
					<span :class="['status', statusClass]"> {{ statusText }} </span>
				</h6>
			</div>
			<div class="button-groups">
				<base-button size="md" @click="viewProduct" :disabled="detail.isStop === 1">상품 보기</base-button>
				<!-- <base-button size="md" @click="updateProduct">수정</base-button> -->
				<base-button v-if="detail.isStop === 0" color="warning" size="md" @click="onClickStopSelling">판매중지</base-button>
				<base-button v-else color="accept" size="md" @click="onClickStartSelling">판매재개</base-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="판매상태 정보" :list="sellingStatusInfo" />
			<info-list title="판매자 정보" :list="sellerInfo" />
			<info-list title="물품 정보" :list="productInfo" />
			<info-list title="물품 상세정보" v-if="productDetailInfo?.length" :list="productDetailInfo" />
			<info-list title="MSDS" v-if="detail?.category?.code === 'CAT_CHEMICAL_REAGENT'" :list="msdsInfo" />
			<info-list title="판매 상품 정보" :list="sellingProductInfo" @onClickButton="showDetailDescription"/>
			<info-list title="가격 및 재고" :list="priceStockInfo" @onClickUpdatePriceButton="openUpdatePriceModal" @onClickUpdateDeleteStatusButton="updateDeleteStatus" @onClieckDeleteB2bTargetButton="deleteB2bNonTarget" />
			<info-list title="배송 정보" :list="shippingInfo" />
			<info-list title="반품/환불 정보" :list="returnInfo" />
			<info-list title="고객상담 정보" :list="csInfo" />
		</div>

		<modal-update-stop @ok="stopSelling" />
		<modal-update-selling @ok="startSelling" />
		<modal-detail-description ref="modal-detail-description" />
		<modal-update-price ref="modal-update-price" @complete="completeUpdatePrice" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import ModalUpdateStop from '../modals/ModalUpdateStop.vue'
import ModalUpdateSelling from '../modals/ModalUpdateSelling.vue'
import ModalDetailDescription from '@/views/product/management/modal/ModalDetailDescription.vue'
import ModalUpdatePrice from '@/views/product/management/modal/ModalUpdatePrice.vue'
import { ToastType } from '@/utils/define/ToastType'
import { getMallURL } from '@/services/api/common'

export default {
	name: 'ProductDetail',
	components: {
		InfoList,
		BaseButton,
		ModalUpdateStop,
		ModalUpdateSelling,
		ModalDetailDescription,
		ModalUpdatePrice,
	},
	data() {
		return {
			mode: '',
			productInfoTableHeader: [
				{ name: '상품 정보', key: 'info', width: '70%' },
				{ name: '수량', key: 'quantity', width: '72px' },
				{ name: '매입가', key: 'sellingPrice', width: '10%' },
				{ name: '소비자가', key: 'sellingPrice', width: '10%' },
			],
		}
	},
	computed: {
		...mapState('product/detail', ['detail']),
		statusClass() {
			if (this.detail.isStop) {
				return "STOP"
			} else if (this.detail.sellingProductCount == 0) {
				return "EMPTY"
			} else {
				return "ING"
			}
		},
		statusCode() {
			if (this.detail.isStop) {
				return "STOP_SELLING"
			} else if (this.detail.sellingProductCount == 0) {
				return "EMPTY"
			} else {
				return "SELLING"
			}
		},
		statusText() {
			if (this.detail.isStop) {
				return "판매중지"
			} else if (this.detail.sellingProductCount == 0) {
				return "판매중인 SKU 없음"
			} else {
				return "판매중"
			}
		},
		isEmpty() {
			return this.$_.isEmpty(this.detail)
		},
		isEstimate() {
			const { status, estimates } = this.requestProduct
			if (['COMPLETE', 'FAIL'].includes(status)) {
				if (estimates?.length > 0) return '작성 완료'
			}

			return '-'
		},
		sellingStatusInfo() {
			if (this.isEmpty) return

			let list = []
			list.push({ title: '판매상태', type: 'status', value: this.statusCode })
			if (this.detail?.isStop === 0) {
				list.push({
					title: '상품등록일시',
					value: this.isData(this.formatDate(this.detail?.createDatetime, 'yyyy-MM-dd T')),
				})
			} else {
				list.push({
					title: '판매중지일시',
					value: this.isData(this.formatDate(this.detail?.stopDatetime, 'yyyy-MM-dd T')),
				})
				list.push({ title: '판매중지 사유', value: this.detail?.stopReason, row: true })
				list.push({ title: '판매중지 상세사유', value: this.detail?.stopReasonDetail, row: true })
			}
			return list
		},
		sellerInfo() {
			if (this.isEmpty) return

			let list = []
			list.push({ title: '판매자 이름', value: this.isData(this.detail?.seller?.name) })
			list.push({ title: '담당자', value: this.isData(this.detail?.seller?.contactName) })
			list.push({ title: '연락처', value: this.isData(this.detail?.seller?.contactPhone) })
			list.push({ title: '이메일', value: this.isData(this.detail?.seller?.email) })
			return list
		},
		productInfo() {
			if (this.isEmpty) return

			let list = []
			list.push({ title: '카테고리', value: this.isData(this.detail.category?.korName) })
			list.push({ title: '물품 이름', value: this.isData(this.detail.name) })
			list.push({ title: '서브네임', value: this.isData(this.detail.subname) })
			list.push({ title: '브랜드', value: this.isData(this.detail.brand.name) })
			list.push({ title: '제품번호', value: this.isData(this.detail.code) })
			list.push({ title: 'CAS No', value: this.isData(this.detail.casno) })
			return list
		},
		productDetailInfo() {
			if (this.isEmpty) return

			switch (this.detail?.category?.code) {
				case 'CAT_CHEMICAL_REAGENT':
					return [
						{
							title: '농도(밀도)',
							value: this.getValue(this.detail.density, this.detail.densityUnit),
						},
						{ title: '분자량', value: this.getValue(this.detail.molecularWeight, this.detail.molecularWeightUnit) },
						{ title: 'MOL', value: this.getValue(this.detail.molarMass, this.detail.molarMassUnit) },
						{ title: '순도', value: this.getValue(this.detail.assay, this.detail.assayUnit) },
						{
							title: '보관온도',
							value: this.getValue(this.detail.storageTemperature, this.detail.storageTemperatureUnit),
						},
						{ title: '용해도', value: this.detail.solubility ? this.detail.solubility : '' },
						{ title: '분자식', value: this.detail.molecularFormula ? this.detail.molecularFormula : '' },
						{ title: '발화점', value: this.getValue(this.detail.flashPoint, this.detail.flashPointUnit) },
						{ title: '끓는점', value: this.getValue(this.detail.boilingPoint, this.detail.boilingPointUnit) },
						{ title: '녹는점', value: this.getValue(this.detail.meltingPoint, this.detail.meltingPointUnit) },
					]
				case 'CAT_CHROMATOGRAPHY':
					return [
						{ title: 'USP/Phase', value: this.detail.usp ? this.detail.usp : '' },
						{ title: '내경(ID)', value: this.getValue(this.detail.innerDiameter, this.detail.innerDiameterUnit) },
						{ title: 'Particle Size', value: this.getValue(this.detail.particleSize, this.detail.particleSizeUnit) },
						{ title: '길이', value: this.detail['length'] ? this.detail['length'] + this.detail.lengthUnit : '' },
						{ title: 'Pore Size', value: this.detail.poreSize ? this.detail.poreSize + this.detail.poreSizeUnit : '' },
						{
							title: 'pH 범위',
							value: this.detail.phRangeFrom && this.detail.phRangeTo ? `${this.detail.phRangeFrom} ~ ${this.detail.phRangeTo}` : '',
						},
						{ title: 'Carbon Load(%)', value: this.detail.carbonLoad ? this.detail.carbonLoad : '' },
						{ title: 'Endcapped', value: this.detail.endcapped ? this.detail.endcapped : '' },
					]
				case 'CAT_LABWARE_EQUIPMENT':
					return [{ title: '모델명', value: this.detail.spec ? this.detail.spec : '' }]
				case 'CAT_LABWARE_TOOL':
					return [{ title: '규격', value: this.detail.spec ? this.detail.spec : '' }]
				case 'CAT_PROTECTION':
				case 'CAT_OFFICE':
				case 'CAT_LABETC':
				case 'CAT_ETC':
					return []
			}
		},
		msdsInfo() {
			let list = []
			if (this.detail?.msds?.seller?.url) list.push({ ...this.detail.msds.seller, name: '판매자 MSDS' })
			if (this.detail?.msds?.brand?.url) list.push({ ...this.detail.msds.brand, name: '브랜드 MSDS' })
			return [{ title: 'MSDS', value: list, type: 'file' }]
		},
		priceStockInfo() {
			let list = []
			this.detail?.products?.forEach((product, index) => {
				let options = ''
				product.options.forEach((option, innerIndex) => {
					options += `${option.name}: ${option.value}`
					if (innerIndex + 1 !== product.options.length) options += ', '
				})
				list.push({
					title: `<div>옵션${index + 1}</div><div class="status REJECT">${product.deleteDatetime ? "삭제된 상태" : ""}</div><div class="status REJECT">${product.b2bNonTarget ? "B2B 판매불가 상태" : ""}</div>`,
					value: {
						title: `SKU : ${product.sku}`,
						size: options,
						subtitle: `재고수량: ${product.stock} | 매입단가: ${this.commaNum(product.salesUnitPrice)}원 | 소비자가(단가): ${this.commaNum(
							product.unitPrice
						)}원`,
					},
					isDel: !!product.deleteDatetime,
					isB2bNonTarget: !!product.b2bNonTarget,
					productId: product.productId,
					unitPrice: product.unitPrice,
					salesUnitPrice: product.salesUnitPrice,
					row: true,
					type: 'option',
				})
			})

			return list
		},
		sellingProductInfo() {
			return [
				{ title: '이미지', value: this.detail.images, type: 'image', row: true },
				{ title: '상세설명', value: '', text: '상세설명 보기', type: 'button', row: true },
				{ title: '검색 태그', value: this.detail.tags, type: 'tag', row: true },
				{ title: '상품 번호', value: this.detail.productInfoId, row: true },
				{ title: '자체 관리번호', value: this.detail.mngNo, row: true },
			]
		},
		shippingInfo() {
			let address = this.detail?.shippingAddress?.address
			if (this.detail?.shippingAddress?.addressDetail) address += `, ${this.detail?.shippingAddress?.addressDetail}`

			return [
				{
					title: '출고지',
					value: { detail: address },
					type: 'address',
					isSingleRow: true,
				},
				{ title: '예상 출고일', value: this.detail?.shippingDay ?? '판매자 문의 필요', isSingleRow: true },
				{ title: '개별연락', value: this.detail?.shippingDirectContact === 1 ? '재고/예상 출고일 확인 후 개별연락' : '-', isSingleRow: true },
				{ title: '퀵 서비스', value: this.detail?.shippingCanQuick === 1 ? '퀵 서비스 가능' : '-', isSingleRow: true },
				{ title: '배송 안내', value: [this.detail?.shippingInfo], type: 'list', isSingleRow: true },
			]
		},
		returnInfo() {
			let address = this.detail?.returnAddress?.address
			if (this.detail?.returnAddress?.addressDetail) address += `, ${this.detail?.returnAddress?.addressDetail}`

			return [
				{
					title: '반품지',
					value: { detail: address },
					type: 'address',
					isSingleRow: true,
				},
				{ title: '반품/환불 정보', value: [this.detail?.returnInfo], type: 'list', isSingleRow: true },
			]
		},
		csInfo() {
			return [
				{ title: '고객상담 연락처', value: this.detail?.cs?.tel, isSingleRow: true },
				{ title: '상담 안내', value: this.detail?.cs?.content, isSingleRow: true },
			]
		},
		categoryKorName() {
			let name = ''
			if (this.detail.category.parents.length > 0) {
				name += `${this.detail.category.parents[0].korName}/`
			}
			if (this.detail.category.korName) {
				name += `${this.detail.category.korName}`
			}

			return name
		},
	},
	created() {
		this.fetchProductDetail()
	},
	methods: {
		...mapActions('product/detail', ['getDetail', 'updateSellingStatus', 'updateProduct']),
		async fetchProductDetail() {
			try {
				await this.getDetail({ productInfoId: this.$route.params.id })
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		getValue(value, unit) {
			if (value && unit) return value + unit
			else if (value && !unit) return value
		},
		viewProduct() {
			window.open(`${getMallURL()}/product/list/detail/${this.detail.productInfoId}`, '_blank')
		},
		onClickStopSelling() {
			this.$bvModal.show('modal-update-stop')
		},
		onClickStartSelling() {
			this.$bvModal.show('modal-update-selling')
		},
		async stopSelling(value) {
			const body = {
				productInfoIds: [this.detail.productInfoId],
				isStop: true,
				stopReason: value.stopReason,
				stopReasonDetail: value.stopReasonDetail,
			}

			try {
				await this.updateSellingStatus(body)
				this.fetchProductDetail()
				this.$root.toast('판매상태 변경 완료', '상품 상태가 판매 중지로 변경되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('판매상태 변경 실패', `${e.response.data.msg}`, 'error')
			}
		},
		async startSelling() {
			const body = {
				productInfoIds: [this.detail.productInfoId],
				isStop: false,
			}
			try {
				await this.updateSellingStatus(body)
				this.fetchProductDetail()
				this.$root.toast('판매상태 변경 완료', '상품 상태가 판매중으로 변경되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('판매상태 변경 실패', `${e.response.data.msg}`, 'error')
			}
		},
		showDetailDescription() {
			this.$refs['modal-detail-description'].show(this.detail.detailHtml, this.detail.images)
		},
		openUpdatePriceModal(productId, unitPrice, salesUnitPrice ) {
			this.$refs['modal-update-price'].show(productId, unitPrice, salesUnitPrice)
		},
		async completeUpdatePrice(productId, unitPrice, salesUnitPrice) {
			const body = {
				productId,
				unitPrice,
				salesUnitPrice
			}
			try {
				await this.updateProduct(body)
				this.fetchProductDetail()
				this.$root.toast('가격 변경 완료', 'SKU의 가격이 변경되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('가격 변경 실패', `${e.response.data.msg}`, 'error')
			}
		},
		async updateDeleteStatus(productId, isDel) {
			const body = {
				productId: productId,
				isDel,
			}
			try {
				await this.updateProduct(body)
				this.fetchProductDetail()
				this.$root.toast('상태 변경 완료', 'SKU의 상태가 변경되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('상태 변경 실패', `${e.response.data.msg}`, 'error')
			}
		},
		async deleteB2bNonTarget(productId) {
			const body = {
				productId: productId,
				deleteB2bNonTarget: true,
			}
			try {
				await this.updateProduct(body)
				this.fetchProductDetail()
				this.$root.toast('상태 변경 완료', 'SKU의 상태가 변경되었습니다.', 'success')
			} catch (e) {
				this.$root.toast('상태 변경 실패', `${e.response.data.msg}`, 'error')
			}
		}
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';

.ProductDetail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: space-between;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
					display: inline-block;
					&.error {
						color: $PRIMARY_RED;
					}
					&:after {
						width: 1px;
						height: 12px;
						content: '';
						position: absolute;
						top: calc(50% - 6px);
						left: 10px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px 40px;
		.text-edit-form {
			margin-bottom: 40px;
		}
		.product-info-table {
			margin: 40px 0;
		}
		.bottom {
			margin: 120px 0 60px;
			button {
				width: 200px;
				height: 48px;
				border-radius: 5px;
				font-weight: 700;
			}
		}
	}
}
</style>
