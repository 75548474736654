import productInfoAPI from '@/services/api/product-info'

export default {
	namespaced: true,
	state: {
		item: {},
	},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await productInfoAPI.lookupProductInfo(id)
			commit('setItem', response.data)
		},
		stopSaleProductInfo: async (_, body) => {
			return await productInfoAPI.stopSaleProductInfo(body)
		},
	},
}
