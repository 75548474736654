<template>
	<tr class="table-item">
		<td class="check-box">
			<div class="checkbox">
				<span v-b-tooltip.hover :title="!!item.isBlock ? '구매불가 상품은 선택할 수 없어요' : ''">
					<input type="checkbox" v-model="checked" :disabled="!!item.isBlock" />
				</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div style="min-width: 100px; max-width: 100px">
				<span :class="['status-text', statusClass]"> {{ statusText }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ isData(item.type.name) }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span style="font-weight: 700">{{ isData(item.name) }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ isData(item.id) }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.memberCount ? `${item.memberCount}명` : '-' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ isData(item.managerName) }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ isData(Validation.autoHypenTel(item.phone)) }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.businessRegistrationCount ? `${item.businessRegistrationCount}개` : '-' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ isLinkLmPro }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.isBrandTargetPromotion ? '적용' : '미적용' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.totalOrderPrice ? commaNum(item.totalOrderPrice) : '-' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.noPaidPrice ? commaNum(item.noPaidPrice) : '-' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div>
				<span>{{ item.paymentDueMonth ? `M+${item.paymentDueMonth}` : '-' }}</span>
			</div>
		</td>
		<td @click="goToDetail">
			<div class="datetime">
				<span>{{ createDatetime }}</span>
			</div>
		</td>
	</tr>
</template>

<script>
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'
import Validation from '@/utils/Validation'

export default {
	mixins: [mixin, MixinItem],
	props: {
		item: {
			type: Object,
		},
		allChecked: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			Validation,
			checked: false,
		}
	},
	watch: {
		allChecked(val) {
			if (!this.item.isBlock) this.checked = val
		},
		checked(val) {
			this.$emit('check', val, this.item.id)
		},
	},
	computed: {
		statusText() {
			return !this.item.isBlock ? '구매 가능' : '구매 불가'
		},
		statusClass() {
			return !this.item.isBlock ? 'complete' : 'fail'
		},
		isLinkLmPro() {
			return this.item.proInstituteId ? '완료' : '미완료'
		},
		createDatetime() {
			return this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd') : '-'
		},
	},
	methods: {
		goToDetail() {
			this.$router.push({
				name: PageName.Buyer.LabDetail,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
