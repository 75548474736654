<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ModalAccountSetting">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-md">
				<div class="Popup__content">
					<div class="accountMenu">
						<h6>계정 설정</h6>
						<ul>
							<li
								v-for="item in accountMenu"
								:key="item.id"
								@click="selectMenu(item)"
								:class="item.id == accountItem.id ? 'active' : ''"
							>
								<img :src="require('@/assets/svg/app/' + item.image)" />
								<p>{{ item.text }}</p>
							</li>
						</ul>
					</div>
					<div class="accountContent">
						<div class="title">
							<p>{{ accountItem.text }}</p>
							<button class="Popup__close-btn" @click="hide">
								<img src="@/assets/svg/app/popup-close.svg" alt="close" />
							</button>
						</div>
						<div class="boxwrap" v-if="accountItem.id == 1">
							<div class="box" style="padding-top: 20px">
								<span>이름</span>
								<p>{{ user.name }}</p>
							</div>
							<div class="box">
								<span>이메일 아이디</span>
								<p>{{ user.email }}</p>
								<!-- <button class="btn" @click="showChangeEmail">
									이메일 변경') }}
								</button> -->
							</div>
							<div class="box">
								<span>연락처</span>
								<p>{{ user.phone }}</p>
							</div>
							<div class="box">
								<span>비밀번호</span>
								<button class="btn" @click="showChangePw">비밀번호 변경</button>
							</div>
						</div>
						<div class="boxwrap" v-else-if="accountItem.id == 2">
							<ul>
								<li>
									<p>PC 푸시 알림</p>
									<label class="switch">
										<input type="checkbox" v-model="info.noti.pc" @change="isNoticePC(info.noti.pc)" />
										<span class="slider round"></span>
									</label>
								</li>
								<li>
									<p>모바일 푸시 알림</p>
									<label class="switch">
										<input type="checkbox" v-model="info.noti.mobile" @change="isNoticeMobile(info.noti.mobile)" />
										<span class="slider round"></span>
									</label>
								</li>
								<li>
									<p>이메일 푸시 알림</p>
									<label class="switch">
										<input type="checkbox" v-model="info.noti.email" @change="isNoticeEmail(info.noti.email)" />
										<span class="slider round"></span>
									</label>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<!--modals-->
			<modal-change-name :mode="mode" ref="modal-change-name" @submit="changeName"></modal-change-name>
			<modal-change-email
				:mode="mode"
				:authentication="authentication"
				ref="modal-change-email"
				@submit="sendAuthentication"
				@complete="changeEmail"
			></modal-change-email>
			<modal-change-phone ref="modal-change-phone" @submit="changePhone"></modal-change-phone>
			<modal-change-pw ref="modal-change-pw" @submit="changePw"></modal-change-pw>
			<modal-withdrawal-member ref="modal-withdrawal-member"></modal-withdrawal-member>
		</div>
	</portal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import userAPI from '@/services/api/institute/user'
import MixinModal from '@/mixins/modal'
import ModalChangeName from '@/views/app/modals/ModalChangeName.vue'
import ModalChangeEmail from '@/views/app/modals/ModalChangeEmail.vue'
import ModalChangePhone from '@/views/app/modals/ModalChangePhone.vue'
import ModalChangePw from '@/views/app/modals/ModalChangePw.vue'
import ModalWithdrawalMember from '@/views/app/modals/ModalWithdrawalMember.vue'

export default {
	name: 'ModalAccountSetting',
	mixins: [MixinModal],
	components: {
		ModalChangeName,
		ModalChangeEmail,
		ModalChangePhone,
		ModalChangePw,
		ModalWithdrawalMember,
	},
	mounted() {},
	data() {
		return {
			accountMenu: [
				{
					id: 1,
					image: 'side-icon1.svg',
					text: '계정 관리',
				},
				{
					id: 2,
					image: 'side-icon2.svg',
					text: '알림 설정',
				},
			],
			accountItem: {},
			info: {
				name: '',
				image: null,
				email: '',
				phone: '',
				password: '',
			},
			imageSrc: null,
			mode: null,
			authentication: false,
		}
	},
	watch: {},
	computed: {
		...mapState('user', ['user']),
	},
	methods: {
		...mapActions('user', ['lookupUserInfo']),
		async show(tab) {
			await this.lookupUserInfo()
			this.imageSrc = null
			this.mode = null
			this.info = this.$_.cloneDeep(this.user)
			if (tab) {
				this.accountItem = this.accountMenu[1]
			} else {
				this.accountItem = this.accountMenu[0]
			}
			this.isShow = true
		},
		selectMenu(item) {
			this.accountItem = item
		},
		uploadImage(e) {
			let image = this.$refs['image-file'].files[0]

			this.imageSrc = URL.createObjectURL(e.target.files[0])

			const formData = new FormData()
			formData.append('image', image)

			// userAPI.updateUserInfo(formData).then(() => {
			// 	this.loadUserInfo()
			// 	const toastTitle = '변경 완료'
			// 	const toastContent = '이미지 업로드가 완료되었습니다'
			// 	this.$root.toast(toastTitle, toastContent, 'success')
			// })
		},
		showChangeName() {
			this.$refs['modal-change-name'].show(this.user.name)
		},
		showChangePhone() {
			this.$refs['modal-change-phone'].show()
		},
		showChangeEmail() {
			this.authentication = false
			this.$refs['modal-change-email'].show(this.user.email)
		},
		showChangePw() {
			this.$refs['modal-change-pw'].show()
		},
		showWithdrawal() {
			this.$refs['modal-withdrawal-member'].show(this.user.name)
		},
		changeName(name) {
			// userAPI
			// 	.updateUserInfo({
			// 		name: name,
			// 	})
			// 	.then(() => {
			// 		this.loadUserInfo()
			// 		const toastTitle = '변경 완료'
			// 		const toastContent = '이름 변경이 완료되었습니다'
			// 		this.$root.toast(toastTitle, toastContent, 'success')
			// 	})
			// this.$refs['modal-change-name'].hide()
		},
		changePhone(phone) {
			// userAPI
			// 	.updateUserInfo({
			// 		phone: phone,
			// 	})
			// 	.then(() => {
			// 		this.loadUserInfo()
			// 		const toastTitle = '변경 완료'
			// 		const toastContent = '연락처 변경이 완료되었습니다'
			// 		this.$root.toast(toastTitle, toastContent, 'success')
			// 	})
			// this.$refs['modal-change-phone'].hide()
		},
		sendAuthentication(email) {
			var emailRule = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
			if (!emailRule.test(email)) {
				const toastTitle = '변경 실패'
				const toastContent = '이메일 형식이 맞지 않습니다. 다시 시도해주세요'
				this.$root.toast(toastTitle, toastContent, 'error')
			}

			// userAPI
			// 	.sendEmailCocde({
			// 		email: email,
			// 	})
			// 	.then(response => {
			// 		this.authentication = true
			// 		const toastTitle = '전송 완료'
			// 		const toastContent = '변경할 이메일 주소로 인증번호가 전송되었습니다'
			// 		this.$root.toast(toastTitle, toastContent, 'success')
			// 	})
		},
		changeEmail(email, code) {
			// userAPI
			// 	.updateUserInfo({
			// 		email: email,
			// 		code: code,
			// 	})
			// 	.then(() => {
			// 		this.loadUserInfo()
			// 		const toastTitle = '변경 완료'
			// 		const toastContent = '이메일(아이디) 변경이 완료되었습니다'
			// 		this.$root.toast(toastTitle, toastContent, 'success')
			// 	})
			// this.$refs['modal-change-email'].hide()
		},
		async changePw(password, newPassword) {
			// await userAPI
			// 	.updateUserInfo({
			// 		password: password,
			// 		newPassword: newPassword,
			// 	})
			// 	.then(() => {
			// 		this.loadUserInfo()
			// 		const toastTitle = '변경 완료'
			// 		const toastContent = '비밀번호 변경이 완료되었습니다'
			// 		this.$root.toast(toastTitle, toastContent, 'success')
			// 		this.$refs['modal-change-pw'].hide()
			// 	})
			// 	.catch(error => {
			// 		this.$root.toast('변경 실패', error.response.data.msg, 'error')
			// 	})
		},
		isNoticePC(val) {
			// userAPI
			// 	.updateUserInfo({
			// 		noti: {
			// 			pc: val,
			// 		},
			// 	})
			// 	.then(() => {
			// 		if (val == true) {
			// 			const title = '알림 켜짐'
			// 			const content = 'PC 알림이 설정되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		} else {
			// 			const title = '알림 꺼짐'
			// 			const content = 'PC 알림이 해제되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		}
			// 	})
		},
		isNoticeMobile(val) {
			// userAPI
			// 	.updateUserInfo({
			// 		noti: {
			// 			mobile: val,
			// 		},
			// 	})
			// 	.then(() => {
			// 		if (val == true) {
			// 			const title = '알림 켜짐'
			// 			const content = '모바일 알림이 설정되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		} else if (val == false) {
			// 			const title = '알림 꺼짐'
			// 			const content = '모바일 알림이 해제되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		}
			// 	})
		},
		isNoticeEmail(val) {
			// userAPI
			// 	.updateUserInfo({
			// 		noti: {
			// 			email: val,
			// 		},
			// 	})
			// 	.then(() => {
			// 		if (val == true) {
			// 			const title = '알림 켜짐'
			// 			const content = '이메일 알림이 설정되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		} else if (val == false) {
			// 			const title = '알림 꺼짐'
			// 			const content = '이메일 알림이 해제되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		}
			// 	})
		},
		isReceiveMarketing(val) {
			// userAPI
			// 	.updateUserInfo({
			// 		noti: {
			// 			marketing: val,
			// 		},
			// 	})
			// 	.then(() => {
			// 		if (val == true) {
			// 			const title = '알림 켜짐'
			// 			const content = '마케팅 정보 수신이 설정되었습니다'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		} else if (val == false) {
			// 			const title = '알림 꺼짐'
			// 			const content = '마케팅 정보 수신이 해제되었습니다.'
			// 			const type = 'success'
			// 			this.$root.toast(title, content, type)
			// 		}
			// 	})
		},
	},
}
</script>

<style lang="scss" scoped>
.ModalAccountSetting {
	.Popup__content {
		display: flex;
		height: 500px;
		overflow: hidden;
		padding: 0;
		.accountMenu {
			width: 220px;
			height: inherit;
			padding: 10px;
			border-right: 1px solid $INPUT_BORDER;
			box-sizing: border-box;
			h6 {
				font-size: 0.875rem;
				color: rgb(0, 0, 0, 0.6);
				font-weight: 400;
			}
			ul {
				margin-top: 12px;
				li {
					width: 100%;
					height: 30px;
					display: flex;
					border-radius: 5px;
					font-size: 0.875rem;
					font-weight: 400;
					align-items: center;
					border-radius: 5px;
					color: $PRIMARY_BLACK;
					cursor: pointer;
					color: $PRIMARY_BLACK;
					&.active {
						background-color: $PRIMARY_YELLOW;
						font-weight: 700;
						color: white;
					}
				}
			}
		}
		.accountContent {
			width: calc(100% - 220px);
			height: inherit;
			.title {
				height: 52px;
				padding: 0 20px 0 30px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid $COLOR_EEE;
				p {
					font-size: 1.125rem;
					color: $PRIMARY_BLACK;
					font-weight: 700;
				}
				button {
					margin-left: auto;
				}
			}
			.boxwrap {
				width: 100%;
				padding: 0 30px 20px;
				height: calc(100% - 52px);
				overflow-y: auto;
				padding-bottom: 50px;
				.box {
					padding-top: 30px;
					input {
						width: 0;
						height: 0;
						visibility: hidden;
						opacity: 0;
					}
					img {
						width: 60px;
						height: 60px;
						overflow: hidden;
						border-radius: 30px;
						display: block;
						border: 1px solid $HOVER_BACKGROUND;
					}
					label {
						cursor: pointer;
						display: inline-flex;
						height: 30px;
						align-items: center;
						margin-top: 10px;
						color: $PRIMARY_BLACK;
					}
					button {
						margin-top: 10px;
					}
					span {
						font-size: 0.75rem;
						color: rgb(0, 0, 0, 0.6);
					}
					p {
						font-size: 0.875rem;
						color: $PRIMARY_BLACK;
						font-weight: 400;
						padding-top: 3px;
					}
				}
				ul {
					width: 100%;
					margin-top: 20px;
					li {
						display: flex;
						align-items: center;
						height: 40px;
						border-bottom: 1px solid $COLOR_EEE;
						p {
							font-size: 0.875rem;
							color: $PRIMARY_BLACK;
							font-weight: 400;
						}
						.switch {
							margin-left: auto;
						}
					}
				}
				input[type='radio'] {
					position: relative;
					margin-right: 4px;
					width: 20px;
					height: 20px;
					&:before {
						position: absolute;
						content: '';
						background-color: #fff;
						width: 20px;
						height: 20px;
					}
				}
				input[type='radio']:checked {
					position: relative;
					width: 20px;
					height: 20px;
					&:after {
						position: absolute;
						width: 20px;
						height: 20px;
						content: '';
						background-image: url(~@/assets/svg/app/icon-check.svg);
						background-repeat: no-repeat;
						background-size: 20px;
						background-position: center;
						top: 0px;
						left: -4px;
					}
				}
				label {
					display: inline-block;
					cursor: pointer;
				}
				input[type='radio']:checked + label {
					font-weight: 700;
				}
			}
		}
	}
}
</style>
