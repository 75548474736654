<template>
	<div class="input-address-wrap">
		<base-item :title="'주소'">
			<div class="input-address-content">
				<button class="Btn__white" @click="searchAddress" v-if="!isReadOnly">
					{{ '주소 검색' }}
				</button>
				<input type="text" :placeholder="'주소를 검색해 주세요'" :value="address" readonly :disabled="isReadOnly" />
				<input
					type="text"
					:disabled="isReadOnly"
					:placeholder="'상세 주소를 입력해 주세요'"
					:value="addressDetail"
					@input="$emit('update:addressDetail', $event.target.value)"
				/>
			</div>
		</base-item>
	</div>
</template>

<script>
import { addressFinderOpen } from '@/services/api/addressFinder'
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputAddress',
	components: {
		BaseItem,
	},
	props: {
		address: {
			type: String,
			default: '',
		},
		addressDetail: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {},
	methods: {
		async searchAddress() {
			const localeMode = 'K'
			const result = await addressFinderOpen(window, localeMode)
			const { address, bname } = result
			this.$emit('update:address', `${address}(${bname})`)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-address-content {
	display: flex;
	flex-direction: column;
	gap: 15px;
	width: 810px;
	button {
		background: $COMMON_BACKGROUND;
		border-radius: 4px;
		width: 10%;
	}
	input[type='text'] {
		padding: 12px;
		height: 36px;
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;
		font-weight: 400;
		font-size: 14px;
		&:disabled {
			background-color: $LIST_HOVER;
		}
	}
}
</style>
