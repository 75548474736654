<template>
	<div>
		<base-item title="제공 MSDS" v-if="hasAutoMsds">
			<div class="form-header">
				<item-msds
					v-if="msdsInfo.brand"
					:imgSrc="msdsInfo.brand.image"
					title="제조사 MSDS"
					:subtext="`출처: ${msdsInfo.brand.name}`"
					:msds="msdsInfo.brand"
					@showMsds="showMsds"
				></item-msds>
				<item-msds
					v-if="msdsInfo.kosha"
					:imgSrc="iconKosha"
					title="안전보건공단 MSDS"
					subtext="출처: 안전보건공단(msds.kosha.or.kr)"
					:msds="msdsInfo.kosha"
				>
				</item-msds>
				<item-msds
					v-if="msdsInfo.institute"
					:imgSrc="iconInstitution"
					title="기관 업로드 MSDS"
					:subtext="`${formatDate(msdsInfo.institute.createDatetime, 'yyyy.MM.dd')}업로드됨`"
					:msds="msdsInfo.institute"
					@showMsds="showMsds"
				>
				</item-msds>
				<div class="subtext">위 MSDS를 자동 제공하고 있습니다.</div>
				<!-- TODO: 판매자 제공 MSDS 필요 -->
				<!-- <item-msds></item-msds> -->
			</div>
		</base-item>
		<base-item title="MSDS 직접 업로드">
			<div class="form-header">
				<!--type [file]-->
				<div class="type-file">
					<div ref="upload-box" class="upload-box">
						<input class="dropFile" type="file" ref="dropFile" @change="onChange" @drop="dropFile" accept=".pdf" />
						<div class="file-box" v-if="files.length == 0">
							<p class="file-box-text">첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요</p>
							<div class="btn-wrap">
								<button class="btn" ref="select-btn" @click="uploadFile">파일 선택</button>
							</div>
						</div>
						<ul class="file-list" v-else>
							<li v-for="(file, index) in files" :key="`key${index}`">
								<img src="@/assets/svg/common/docs.svg" />
								<p>{{ file.name }}</p>
								<button class="close-btn" @click="deleteFile">
									<img src="@/assets/svg/app/Close-1.svg" />
								</button>
							</li>
						</ul>
					</div>
					<button class="btn" @click="uploadFile">파일 선택</button>
					<!--//type [file]-->
				</div>
			</div>
		</base-item>
		<modal-view-msds ref="modal-msds" :isShow="displayViewMSDS" @close="closeMsdsViewer" :pdfUrl="viewerPdfUrl"></modal-view-msds>
	</div>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import BaseItem from '@/views/common/components/BaseItem.vue'
import ModalViewMsds from '@/views/product/registration/modals/ModalViewMsds.vue'
import ItemMsds from '@/views/product/registration/components/productInfo/ItemMsds.vue'

import IconKosha from '@/assets/svg/registration/icon-kosha.svg'
import IconInstitution from '@/assets/svg/registration/icon-institute-msds.svg'

export default {
	name: 'InputMsds',
	components: {
		BaseItem,
		ModalViewMsds,
		ItemMsds,
	},
	props: {
		msdsInfo: {
			type: Object,
			default: () => ({}),
		},
		msds: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			iconKosha: IconKosha,
			iconInstitution: IconInstitution,
			files: [],
			displayViewMSDS: false,
			viewerPdfUrl: null,
		}
	},
	computed: {
		hasAutoMsds() {
			if (!this.msdsInfo.brand || !this.msdsInfo.kosha || !this.msdsInfo.institute) return false
			const msdsKey = Object.keys(this.msdsInfo)
			let hasMsdsInfoAtAnyKey = msdsKey.filter(key => {
				if (this.msdsInfo[key]) {
					return Object.keys(this.msdsInfo[key]).length > 0
				}
			})
			return msdsKey.length > 0 && hasMsdsInfoAtAnyKey.length > 0
		},
	},
	mounted() {
		this.files = this.$_.cloneDeep(this.msds)
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['dropFile'].files = event.dataTransfer.files
				this.onChange()
			}
		},
		uploadFile() {
			this.$refs['dropFile'].click()
		},
		onChange() {
			let fileValues = Object.values(this.$refs['dropFile'].files)
			const file = fileValues[0]

			if (file) {
				let isPdf = new RegExp('application/[a-z]*(pdf)').test(file.type)

				if (!isPdf) {
					this.files = this.files.length > 0 ? this.files : []
					const title = 'MSDS 업로드 실패'
					const content = 'PDF 형식의 파일만 업로드 가능합니다.'
					this.printToast(title, content, ToastType.ERROR)
				} else {
					this.files = [file]
				}
				this.changeItem(this.files)
				this.$refs['dropFile'].value = ''
			}
		},
		deleteFile() {
			this.files = []
			this.changeItem(this.files)
			this.$refs['dropFile'].value = ''
		},
		changeItem(value) {
			this.$emit('changeInfo', value)
		},
		showMsds(msds) {
			this.displayViewMSDS = true
			this.viewerPdfUrl = msds.url
		},
		closeMsdsViewer() {
			this.displayViewMSDS = false
			this.viewerPdfUrl = null
		},
	},
}
</script>

<style lang="scss" scoped>
.form-header {
	display: flex;
	font-size: 16px;
	font-weight: 700;
	color: $COLOR_000;
	flex-direction: column;
	.subtext {
		font-size: 0.75rem;
		font-weight: 400;
		margin-top: 10px;
		color: $FONT_PLACEHOLDER;
	}
}
.type-file {
	max-width: 810px;

	button.btn {
		display: flex;
		justify-content: center;
		width: 100px;
		height: 36px;
		margin-top: 15px;
	}

	.upload-box {
		width: 100%;
		height: 120px;
		border: 1px dashed $FILE_BORDER;
		border-radius: 5px;
		margin: 15px 0px;
		position: relative;

		overflow-y: auto;
		cursor: pointer;
		margin-bottom: 10px;
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
		.file-box {
			text-align: center;
			width: 100%;
			z-index: 2;
			// position: relative;
			padding-top: 26px;
			.file-box-text {
				font-size: 14px;
				font-weight: 400;
				color: $COLOR_111;
			}
			.btn-wrap {
				width: 100%;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				button {
					margin-top: 10px;
					cursor: pointer;
				}
			}
		}
		.file-list {
			width: 100%;
			padding: 14px 10px;
			height: 100%;
			// position: relative;
			z-index: 2;
			li {
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				font-size: 0.875rem;
				font-weight: 400;
				img {
					margin-right: 2px;
				}
				button {
					position: relative;
				}
			}
		}
	}
}
</style>
