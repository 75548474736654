<template>
	<div class="page-wrapper">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<brand-table class="table" :list="list" />
			<floating-menu :count="checkedList.length" :list="btnList" @clickEv="showModal" />
		</div>
		<pagination :total="count" :defaultOffset="50" @page="pagination"></pagination>
		<modal-merge-brand v-if="isShowMergeModal" :brandList="checkedList" @hide="isShowMergeModal = false" @complete="initList" />
		<modal-confirm-delete :isShow="isShowConfirmDelete" @hide="isShowConfirmDelete = false" />
	</div>
</template>

<script>
import Pagination from '@/views/common/components/CommonPagenation.vue'
import ListHeader from '../components/ListHeader.vue'
import BrandTable from '../components/Table.vue'
import FloatingMenu from '@/components/table/FloatingMenu'
import ModalMergeBrand from '../modals/ModalMergeBrand.vue'
import ModalConfirmDelete from '../modals/ModalConfirmDelete.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
	components: {
		ListHeader,
		Pagination,
		BrandTable,
		FloatingMenu,
		ModalMergeBrand,
		ModalConfirmDelete,
	},
	data() {
		return {
			checkList: [],
			isShowMergeModal: false,
			isShowConfirmDelete: false,
		}
	},
	computed: {
		...mapState('lms/brand', ['list', 'count', 'checkedList']),
		btnList() {
			return [
				{
					text: '통합',
					key: 'union',
					image: require('@/assets/svg/lms/float-icon-union.svg'),
					disabled: this.checkedList.length <= 1,
				},
				{
					text: '삭제',
					key: 'delete',
					image: require('@/assets/svg/lms/float-icon-delete.svg'),
					disabled: this.checkedList.length <= 0,
				},
			]
		},
	},
	created() {
		this.initParams()
		this.getList()
		this.initCheckedList()
	},
	methods: {
		...mapMutations('lms/brand', ['initParams', 'initCheckedList']),
		...mapActions('lms/brand', ['getList', 'setLoadParams', 'setPagination']),
		pagination(page) {
			this.setPagination(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
		showModal(key) {
			if (key === 'union') this.isShowMergeModal = true
			else if (key === 'delete') this.isShowConfirmDelete = true
		},
		initList() {
			this.getList()
			this.initCheckedList()
		},
	},
}
</script>
<style lang="scss" scoped>
.page-wrapper {
	overflow: hidden;
	.table-wrapper {
		height: calc(100% - 161px);
	}
}
</style>
