<template>
	<div class="floating-menu">
		<h4 class="count">
			<span>{{ count }}개</span>
			<p>선택</p>
		</h4>
		<div class="btn-group">
			<div v-for="(btn, index) in list" :key="`menu-btn_${index}`" v-b-tooltip.hover :title="btn.disabled ? btn.tooltip : ''">
				<button :disabled="btn.disabled" @click="$emit('clickEv', btn.key)">
					<img :src="btn.image" />
					<p>{{ btn.text }}</p>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FloatingMenu',
	props: {
		count: {
			type: Number,
			default: 0,
		},
		list: {
			type: Array,
			default: () => [],
		},
	},
}
</script>

<style lang="scss" scoped>
.floating-menu {
	display: flex;
	height: auto;
	align-items: center;
	position: absolute;
	bottom: 70px;
	left: 50%;
	transform: translate(-50%, 0);
	padding: 10px 0;
	border-radius: 10px;
	border: 1px solid #ccc;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	background: #fff;
	z-index: 2;
	h4 {
		font-size: 1rem;
		color: #000;
		font-weight: 400;
		width: 112px;
		font-size: 1rem;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		span {
			display: inline-block;
			color: $PRIMARY_YELLOW;
			padding-right: 3px;
			font-weight: bold;
		}
		p {
			display: inline-block;
		}
	}
	.btn-group {
		padding: 0 20px;
		border-left: 1px solid #eee;
		display: flex;
		align-items: center;
		justify-items: center;
		height: 50px;
		button {
			display: block;
			text-align: center;
			min-width: 50px;
			img {
				width: 24px;
				display: block;
				margin: 0 auto;
				padding-right: 0;
			}
			p {
				display: block;
				font-size: 10px;
				font-weight: 400;
				color: #111;
			}
			&:disabled {
				border: 0 !important;
				background-color: transparent !important;
				img {
					opacity: 0.2;
				}
				p {
					color: inherit;
				}
			}
		}
	}
}
</style>
