import axios from 'axios'

const BASE_API_URL = 'https://api.lab-manager.com/v1'
// const BASE_API_URL = 'http://localhost:5001/v1'

// const BOT_TOKEN = 'xoxb-274978451233-5784121517139-7Z1qo5IjSyqcGuVn0fhT5lkL'

const getRequestList = async params => {
	const result = await axios({
		method: 'GET',
		url: `${BASE_API_URL}/data/product/request/list`,
		params,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const getRequestDetail = async params => {
	const result = await axios({
		method: 'GET',
		url: `${BASE_API_URL}/data/product/request/detail`,
		params,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const matchingRequest = async body => {
	const result = await axios({
		method: 'POST',
		url: `${BASE_API_URL}/data/product/request/confirm`,
		data: body,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const getProductDetail = async params => {
	const result = await axios({
		method: 'GET',
		url: `${BASE_API_URL}/data/product/detail`,
		params,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const searchProduct = async params => {
	const result = await axios({
		method: 'GET',
		url: `${BASE_API_URL}/data/product/${params.type}/search`,
		params,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const searchMaker = async params => {
	const result = await axios({
		method: 'GET',
		url: `${BASE_API_URL}/data/product/maker`,
		params,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}

const addMaker = async body => {
	const result = await axios({
		method: 'POST',
		url: `${BASE_API_URL}/data/product/maker`,
		data: body,
		headers: {
			'Content-type': 'application/json',
			'X-API-KEY': 'KITECHCHEMSEARCH',
		},
	})
	return result
}
export default { getRequestList, getRequestDetail, getProductDetail, searchProduct, matchingRequest, searchMaker, addMaker }
