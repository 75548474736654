<template>
	<div class="recent">
		<div class="keyword-header">
			<span> 최근 검색어 </span>
			<button @click.stop="removeAllRecent($event)">모두 삭제</button>
		</div>
		<ul class="recent-keyword-list">
			<li class="recent-keyword" v-for="(keyword, index) in reversedRecentKeywords" :key="`keyword_${index}`">
				<img class="recent" src="@/assets/svg/common/recent-keyword.svg" />
				<span class="keyword-text" @click.stop="clickItem($event, keyword)">{{ keyword }}</span>
				<img class="keyword-image" src="@/assets/svg/common/close-image1.svg" @click.stop="removeRecent($event, keyword)" />
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'RecentKeywords',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		recentKeywords: {
			type: Array,
			default: () => [],
		},
		classification: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			reversedRecentKeywords: [],
		}
	},
	created() {
		this.reversedRecentKeywords = [...this.recentKeywords]
		this.reversedRecentKeywords.reverse()
	},
	watch: {
		isShow(value) {
			if (value) {
				this.reversedRecentKeywords = [...this.recentKeywords]
				this.reversedRecentKeywords.reverse()
			}
		},
		recentKeywords(value) {
			this.reversedRecentKeywords = [...this.recentKeywords]
			this.reversedRecentKeywords.reverse()
		},
	},
	methods: {
		clickItem(event, keyword) {
			this.$emit('clickItem', keyword)
		},
		removeRecent(event, keyword) {
			this.$emit('removeItem', keyword)
		},
		removeAllRecent(event) {
			this.$emit('removeAllItem')
		},
	},
}
</script>

<style lang="scss" scoped></style>
