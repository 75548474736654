const approvalProductTableAllColumns = [
	'심사 상태',
	'정보',
	'판매자',
	'옵션',
	'총 재고수량',
	'카테고리',
	'브랜드',
	'제품번호',
	'CAS No',
	'검색 태그',
	'출고지',
	'판매 신청일시',
	'심사 완료일시',
]
const approvalProductTableRequireColumns = ['심사 상태', '정보', '판매자', '옵션']

export { approvalProductTableAllColumns, approvalProductTableRequireColumns }
