import productRegistAPI from '@/services/api/product-regist'
import _ from 'lodash'

export default {
	namespaced: true,
	state: {
		seller: {
			id: 0,
			name: '',
		},
		brand: {
			id: 0,
			name: '',
		},
		categoryList: [],
		productInfo: {},
		sellInfo: {},
		shippingInfo: {},
		returnInfo: {},
		consultingInfo: {},
	},
	getters: {
		infoFormData: state => {
			const requestBody = {
				...state.productInfo,
				...state.sellInfo,
				...state.shippingInfo,
				...state.returnInfo,
				...state.consultingInfo,
			}
			if (!requestBody.type) requestBody.type = 'NORMAL'
			requestBody.categoryId = requestBody.category.option.id
			if (requestBody.tags && requestBody.tags.length) {
				requestBody.tags.forEach((tag, index) => {
					requestBody[`tags[${index}]`] = tag.value
				})
			}
			if (requestBody.optionNames && requestBody.optionNames.length) {
				requestBody.optionNames.forEach((option, index) => {
					requestBody[`optionNames[${index}]`] = option.value
				})
			}
			if (requestBody.images && requestBody.images.length) {
				requestBody.images = requestBody.images.filter((_, index) => index < 10)
			}
			requestBody.products.forEach((product, index) => {
				Object.entries(product).forEach(([key, value]) => {
					if (key === 'options') {
						value.forEach((option, idx) => (requestBody[`products[${index}][${key}][${idx}]`] = option))
					} else {
						requestBody[`products[${index}][${key}]`] = value
					}
				})
			})
			requestBody.brandId = state.brand.id
			requestBody.brandName = state.brand.name
			requestBody.sellerId = state.seller.id

			delete requestBody.category
			delete requestBody.tags
			delete requestBody.optionNames
			delete requestBody.products

			return Object.entries(requestBody).reduce((acc, [key, value]) => {
				if (value === null || value === 'null') return acc

				if (Array.isArray(value)) {
					for (let _value of value) {
						acc.append(key, _value)
					}
				} else {
					acc.append(key, value)
				}
				return acc
			}, new FormData())
		},
	},
	mutations: {
		setSeller: (state, payload) => {
			state.seller.id = payload.id
			state.seller.name = payload.name
		},
		setBrand: (state, payload) => {
			state.brand.id = payload.id
			state.brand.name = payload.name
		},
		setCategoryList: (state, payload) => {
			state.categoryList = payload
		},
		setProductInfo: (state, payload) => {
			state.productInfo = payload
		},
		setSellInfo: (state, payload) => {
			state.sellInfo = payload
		},
		setShippingInfo: (state, payload) => {
			state.shippingInfo = payload
		},
		setReturnInfo: (state, payload) => {
			state.returnInfo = payload
		},
		setConsultingInfo: (state, payload) => {
			state.consultingInfo = payload
		},
	},
	actions: {
		initInfo: ({ commit }) => {
			commit('setSeller', {})
			commit('setProductInfo', {})
			commit('setSellInfo', {})
			commit('setShippingInfo', {})
			commit('setReturnInfo', {})
			commit('setConsultingInfo', {})
		},
		lookupCategoryList: async ({ commit }) => {
			const response = await productRegistAPI.lookupCategoryList()
			commit('setCategoryList', response.data)
		},
		addProduct: async ({ getters }, requestId) => {
			const formData = getters.infoFormData
			if (requestId) formData.append('requestId', requestId)

			const response = await productRegistAPI.addProduct(formData)
			return response.data
		},
		updateProduct: async ({ getters }, requestId) => {
			const formData = getters.infoFormData
			if (requestId) formData.append('requestId', requestId)

			await productRegistAPI.updateProduct(formData)
		},
		addProductImage: async ({ getters }, formData) => {
			const response = await productRegistAPI.addProductImage(formData)
			return response.data
		},
	},
}
