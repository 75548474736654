<template>
	<tr class="table-header">
		<th>
			<span>주문번호</span>
		</th>
		<th>
			<span>상품 정보</span>
		</th>
		<th>
			<span>주문 연구실</span>
		</th>
		<th>
			<span>주문 연구실 ID</span>
		</th>
		<th>
			<span>주문자</span>
		</th>
		<th>
			<span>주문자 연락처</span>
		</th>
		<th>
			<span>주소</span>
		</th>
		<th>
			<span>배송 요청사항</span>
		</th>
		<th>
			<span>주문금액</span>
		</th>
		<th>
			<span>주문일</span>
		</th>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
	computed: {
		...mapGetters('orderItem/list', ['order', 'orderDirection']),
	},
	methods: {
		...mapActions('orderItem/list', ['setLoadParams']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
