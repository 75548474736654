<template>
	<div class="card-input-wrapper">
		<number-input
			v-model="displayNumbers[0]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="showKeypad"
		/>
		<password-input
			v-model="displayNumbers[1]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="showKeypad"
		/>
		<password-input
			v-model="displayNumbers[2]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="showKeypad"
		/>
		<base-input
			v-model="displayNumbers[3]"
			:placeholder="placeholder"
			:maxlength="4"
			:disabled="disabled"
			:error="!isValid || error"
			:errorMessage="errorMessage"
			:inputStyle="{ width: '100%', textAlign: 'center' }"
			style="flex: 1"
			readonly
			@click="showKeypad"
		/>

		<numeric-keypad ref="keypad" @input="onInput" @delete="onDelete" @reset="onReset" @close="hideKeypad" />
	</div>
</template>

<script>
import BaseInput from '@/components/input/BaseInput.vue'
import NumberInput from '@/components/input/NumberInput.vue'
import PasswordInput from '@/components/input/PasswordInput.vue'
import NumericKeypad from '@/components/input/NumericKeypad.vue'

export default {
	name: 'CardInput',
	components: {
		BaseInput,
		NumberInput,
		PasswordInput,
		NumericKeypad,
	},
	extends: BaseInput,
	data() {
		return {
			cardNumbers: ['', '', '', ''],
			displayNumbers: ['', '', '', ''],
		}
	},
	watch: {
		cardNumbers: {
			handler(val) {
				this.$emit('input', val.join(''))
			},
			deep: true,
		},
	},
	methods: {
		showKeypad() {
			this.$refs.keypad.show()
		},
		hideKeypad() {
			this.$refs.keypad.hide()
		},
		onInput(value) {
			if (this.cardNumbers.join('').length >= 16) return this.hideKeypad()

			let index = 0
			let temp = ''
			for (let number of this.cardNumbers) {
				if (number.length >= 4) {
					index++
					continue
				}

				temp = number + value
				break
			}
			this.cardNumbers[index] = temp
			this.cardNumbers = this.$_.cloneDeep(this.cardNumbers)

			if (index === 3 && temp.length === 4) {
				const dummy = temp.slice(0, 3)
				temp = dummy.padEnd(temp.length, '*')
			}
			this.displayNumbers[index] = temp
			this.displayNumbers = this.$_.cloneDeep(this.displayNumbers)

			if (this.cardNumbers.join('').length === 16) this.hideKeypad()
		},
		onDelete() {
			if (this.cardNumbers.join('').length <= 0) return

			let index = this.cardNumbers.length - 1
			let temp = ''
			for (let i = index; i >= 0; i--) {
				if (this.cardNumbers[i].length <= 0) {
					index--
					continue
				}

				temp = this.cardNumbers[i].substr(0, this.cardNumbers[i].length - 1)
				break
			}
			this.cardNumbers[index] = temp
			this.displayNumbers[index] = temp
			this.cardNumbers = this.$_.cloneDeep(this.cardNumbers)
			this.displayNumbers = this.$_.cloneDeep(this.displayNumbers)
		},
		onReset() {
			this.cardNumbers = ['', '', '', '']
			this.displayNumbers = ['', '', '', '']
		},
	},
}
</script>

<style lang="scss" scoped>
.card-input-wrapper {
	display: flex;
	gap: 8px;
	width: 100%;
}
</style>
