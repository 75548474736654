import paymentAPI from '@/services/api/payment'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: '',
			paymentStatus: 'ALL',
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		list: [],
		count: 0,
		statusList: [],
		filter: {},
		userList: [],
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setStatusList: (state, data) => {
			state.statusList = data
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			state.filter = data
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: '',
				paymentStatus: 'ALL',
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getStatusList: async ({ state, commit }) => {
			const response = await paymentAPI.lookupPaymentSummaryList()
			commit('setStatusList', response.data)
		},
		getList: async ({ state, commit }) => {
			const response = await paymentAPI.lookupPaymentList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			await dispatch('getList')
		},
		getFilter: async ({ state, commit }) => {
			const response = await paymentAPI.lookupPaymentFilter()
			commit('setFilter', response.data)
		},
		updatePaid: async ({ state, commit }, body) => {
			return await paymentAPI.updatePaid(body)
		},
		createManual: async ({ state, commit }, body) => {
			return await paymentAPI.createManual(body)
		},
		exportExcel: async ({ state }, type) => {
			//
		},
	},
}
