<template>
	<portal to="lm-modal">
		<div class="modal-merge-brand-wrapper">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">브랜드 통합하기</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="merge-brand-table-wrapper">
						<merge-brand-table ref="merge-brand-table" :brandList="brandList" @select="selectBrand"></merge-brand-table>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">합치기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import MergeBrandTable from '@/views/lms/brand/components/MergeBrandTable.vue'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalMergeBrand',
	components: {
		MergeBrandTable,
	},
	props: {
		brandList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			baseBrandId: null,
		}
	},
	methods: {
		...mapActions('lms/brand', ['mergeBrands']),
		hide() {
			this.$emit('hide')
		},
		selectBrand(brand) {
			this.baseBrandId = brand.id
		},
		async complete() {
			if (!this.baseBrandId) return this.$root.toast('에러', '통합할 브랜드를 선택해주세요.', ToastType.ERROR)
			try {
				let brandIds = []
				this.brandList.forEach(brand => {
					brandIds.push(brand.id)
				})

				const body = {
					baseBrandId: this.baseBrandId,
					brandIds: brandIds,
				}
				await this.mergeBrands(body)

				this.$root.toast('브랜드 통합 완료', '브랜드 통합을 완료하였습니다.', ToastType.SUCCESS)
				this.$emit('complete')
				this.hide()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-merge-brand-wrapper {
	.Popup__container {
		.Popup__content {
			width: 1200px;
			.merge-brand-wrapper {
				margin-bottom: 20px;
			}
			.merge-brand-table-wrapper {
				max-height: 450px;
			}
		}
	}
}
</style>
