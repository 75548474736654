<template>
	<div class="textarea-edit-form">
		<div class="title" v-if="title">
			<h4>{{ title }}</h4>
			<p v-if="content">{{ content }}</p>
			<div v-if="!hideBtn" class="btn-wrap">
				<button class="btn" v-if="isEdit" @click="cancel">취소</button>
				<button class="btn btn-complete" v-if="isSave" @click="save">저장</button>
				<button class="btn" v-if="defaultValue && !isEdit" @click="edit">수정</button>
			</div>
		</div>
		<textarea
			ref="textarea"
			v-model="formValue"
			:class="{ error: isOverMaxLength }"
			:placeholder="setPlaceholder(title)"
			:readonly="readonly"
			:maxlength="maxlength"
		/>
		<div v-if="isOverMaxLength" class="error-msg">
			<img src="@/assets/svg/common/icon-error.svg" />
			<span>{{ maxlength }}자 이내로 작성해주세요</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TextareaEditForm',
	props: {
		title: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isDetail: {
			type: Boolean,
			default: false,
		},
		defaultValue: {
			type: String,
			default: '',
		},
		hideBtn: {
			type: Boolean,
			default: false,
		},
		maxlength: {
			type: Number,
			default: 999999,
		},
	},
	data() {
		return {
			isEdit: false,
			formValue: '',
		}
	},
	computed: {
		readonly() {
			if (!this.isSave && !this.isEdit) {
				return true
			} else {
				return false
			}
		},
		isSave() {
			if (this.isEdit) {
				return true
			} else if (this.defaultValue) {
				return false
			} else if (!this.isDetail) {
				return true
			}
		},
		isOverMaxLength() {
			return this.formValue?.length >= this.maxlength
		},
	},
	watch: {
		defaultValue(val) {
			this.formValue = val
		},
	},
	created() {
		this.formValue = this.defaultValue
	},
	mounted() {
		this.addAutoResize(this.$refs.textarea)
	},
	methods: {
		addAutoResize(el) {
			el.style.boxSizing = 'border-box'
			const offset = el.offsetHeight - el.clientHeight
			el.addEventListener('input', e => {
				e.target.style.height = 'auto'
				e.target.style.height = e.target.scrollHeight + offset + 'px'
			})
		},
		setPlaceholder(title) {
			return `${title}${this.checkEnding(title)} 입력해주세요`
		},
		edit() {
			this.isEdit = true
		},
		cancel() {
			this.formValue = this.defaultValue
			this.isEdit = false
		},
		save() {
			this.$emit('save', this.formValue, this.formKey)
			this.isEdit = false
		},
	},
}
</script>

<style lang="scss" scoped>
.textarea-edit-form {
	// width: calc(100% - 60px);
	margin: 0 auto;
	.title {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		h4 {
			font-size: 1.25rem;
			font-weight: 700;
		}
		p {
			font-size: 0.875rem;
			font-weight: 500;
			color: $COLOR_666;
			margin-left: 1rem;
		}
		.btn-wrap {
			margin-left: auto;
			display: inline-flex;
			button {
				width: 80px;
				height: 30px;
				border-radius: 4px;
				display: inline-flex;
				justify-content: center;
				margin-left: 8px;
			}
		}
	}
	textarea {
		width: 100%;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		min-height: 80px;
		padding: 8px 10px;
		font-size: 0.875rem;
		&::read-only {
			background-color: $HOVER_BACKGROUND;
			cursor: default;
		}
		&.error {
			border-color: $PRIMARY_RED !important;
		}
	}
	.error-msg {
		display: flex;
		gap: 6px;
		align-items: center;
		font-size: 0.75rem;
		color: $PRIMARY_RED;
	}
}
</style>
