import { TableField } from '@/utils/define/table/JwTable'
import { formatDatetime, _2H2MFormat } from '@/utils/modules/DateUtils'

const productManagementTable = {
	width: '100%',
	height: '100%',
	options: {
		selectColumnKey: TableField.Status,
		sortActiveColumnKey: TableField.Status,
		isCheckbox: true,
		table: {
			style: {},
		},
		header: {
			style: {
				height: '60px;',
				color: '#666666',
				fontSize: '14px',
				backgroundColor: '#FFFFFF',
			},
		},
		row: {
			style: {
				height: '60px',
			},
			click: {
				rowDataName: TableField.Status,
				keyName: 'id',
				clickEvent: () => {},
			},
		},
		columns: [
			{
				key: TableField.Status,
				name: '판매상태',
				type: 'statusIndicatorWithMark',
				balloon: true,
				style: {
					width: '80px',
				},
				statusHandler: data => {
					return {
						판매중: {
							class: ['green'],
						},
						판매중지: {
							class: ['red'],
						},
					}[data.text]
				},
				classes: [],
			},
			{
				key: TableField.SellProductNo,
				name: '상품번호',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.ManagementNo,
				name: '자체 관리번호',
				style: {
					width: '120px',
				},
				classes: [],
			},
			{
				key: TableField.Info,
				balloon: true,
				name: '정보',
				type: 'item',
				style: {
					width: '250px',
				},
				classes: [],
			},
			{
				key: TableField.Option,
				name: '옵션',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.TotalQuantity,
				name: '총 재고수량',
				style: {
					width: '150px',
				},
				classes: [],
			},
			{
				key: TableField.Category,
				name: '카테고리',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Brand,
				name: '브랜드',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.ProductNo,
				name: '제품번호',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Casno,
				name: 'CAS No',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.ReleaseLocation,
				name: '출고지',
				style: {
					width: '250px',
				},
				classes: [],
			},
			{
				key: TableField.ProductRegistDatetime,
				name: '상품등록일시',
				style: {
					width: '150px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
			{
				key: TableField.LastEditDatetime,
				name: '최종수정일시',
				style: {
					width: '150px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
		],
		tableData: {
			Status: [],
			SellProductNo: [],
			ManagementNo: [],
			Info: [],
			Option: [],
			TotalQuantity: [],
			Category: [],
			Brand: [],
			ProductNo: [],
			Casno: [],
			ReleaseLocation: [],
			ProductRegistDat: [],
			etime: [],
			LastEditDatetime: [],
		},
	},
}

const productManagementTableAllColumns = [
	'판매상태',
	'상품번호',
	'자체 관리번호',
	'정보',
	'옵션',
	'총 재고수량',
	'카테고리',
	'브랜드',
	'제품번호',
	'CAS No',
	'출고지',
	'상품등록일시',
	'최종수정일시',
]

export { productManagementTable, productManagementTableAllColumns }
