<template>
	<div class="input-text-wrap">
		<base-item :title="title" :subContentText="subContentText" :required="isRequired">
			<span class="input-wrap">
				<date-picker
					:value="inputText"
					@input="changeValue"
					type="datetime"
					format="YYYY-MM-DD a hh:mm"
					:time-picker-options="{
						start: '08:30',
						step: '00:30',
						end: '24:00',
					}"
					placeholder="YYYY-MM-DD a hh:mm"
				/>
			</span>
			<div v-if="!isValid" class="error">
				<img src="@/assets/svg/common/icon-error.svg" alt="error" />
				<span>{{ inValidText }}</span>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
	name: 'InputText',
	components: {
		BaseItem,
		DatePicker,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		inputWidthSize: {
			type: Number,
			default: 300,
		},
		inputHeightSize: {
			type: Number,
			default: 36,
		},
		inputValue: {
			type: Date,
			default: () => new Date(),
		},
		placeholder: {
			type: String,
			default: '',
		},
		unit: {
			type: String,
			default: '',
		},
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		isRequired: {
			type: Boolean,
			default: false,
		},
		isValid: {
			type: Boolean,
			default: true,
		},
		inValidText: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputText: this.inputValue,
		}
	},
	watch: {
		inputValue() {
			if (this.inputText != this.inputValue) this.inputText = this.inputValue
		},
	},
	methods: {
		changeValue(value) {
			this.$emit('changeValue', value)
		},
		setValue(value) {
			this.inputText = value
			this.$emit('changeValue', this.inputText)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~vue2-datepicker/scss/index.scss';
.input-text-wrap {
	.input-wrap {
		position: relative;
		input[type='number'],
		input[type='text'],
		input[type='password'] {
			padding: 12px;
			border: 1px solid $FILE_BORDER;
			border-radius: 5px;

			&:disabled {
				background-color: $LIST_HOVER;
			}
			&.error {
				border-color: $PRIMARY_RED;
			}
		}
		b.unit {
			position: absolute;
			right: 12px;
			top: 0;
		}
	}

	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
}
</style>
