<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>{{ this.mode === 'create' ? '자주묻는 질문 등록하기' : '자주묻는 질문 수정하기' }}</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>자주묻는 질문 정보</h5>
				<input-select-option
					ref="input-select"
					title="자주묻는 질문 유형"
					placeholder="유형 선택"
					:list="statusList"
					:isValid="isValid.categoryId"
					inValidText="질문 유형을 선택해주세요"
					isRequired
					@changeValue="data => (formData.categoryId = data)"
				/>

				<input-text
					ref="title"
					title="제목"
					placeholder="제목 입력"
					:isValid="isValid.title"
					:inputValue="formData.title"
					@changeValue="data => (formData.title = data)"
					inValidText="제목을 입력해주세요"
					isRequired
				/>
				<input-text-editor :editorStyle="{ height: '668px', width: '800px' }" title="내용" v-model="formData.html" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="regist">{{ this.mode === 'create' ? '등록' : '수정' }}</base-button>
		</div>
	</div>
</template>
<script>
import InputSelectOption from '@/views/common/components/formItem/InputSelectOption.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import InputTextEditor from '@/views/common/components/formItem/InputTextEditor.vue'
import { mapActions, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'

export default {
	components: {
		InputSelectOption,
		InputText,
		BaseItem,
		BaseButton,
		InputTextEditor,
	},
	data() {
		return {
			mode: 'create',
			formData: {
				title: null,
				html: null,
				categoryId: null,
			},
			isValid: {
				title: true,
				categoryId: true,
			},
		}
	},
	computed: {
		...mapState('lms/faq', ['detail']),
		statusList() {
			return [
				{ value: 1, name: '개인/연구실 정보' },
				{ value: 2, name: '주문/결제' },
				{ value: 3, name: '후불 결제' },
				{ value: 4, name: '주문 취소' },
				{ value: 5, name: '교환/환불' },
				{ value: 6, name: '연구비/서류' },
				{ value: 7, name: '배송' },
				{ value: 8, name: '상품 문의' },
			]
		},
	},
	async mounted() {
		if (this.$route.query.id) {
			this.mode = 'edit'
			await this.lookupFaq({ faqId: this.$route.query.id })
			this.formData.categoryId = this.statusList.find(status => {
				return status.value === this.detail.category.id
			})
			this.$refs['input-select'].changeOption(
				this.statusList.findIndex(status => {
					return status.value === this.detail.category.id
				})
			)
			this.formData.title = this.detail.title
			this.formData.html = this.detail.html
		}
	},
	methods: {
		...mapActions('lms/faq', ['createFaq', 'lookupFaq', 'updateFaq']),
		async regist() {
			if (!this.formData.categoryId) return (this.isValid.categoryId = false)
			if (!this.formData.title) return (this.isValid.title = false)
			if (!this.formData.html || this.formData.html === '<p></p>') return this.$root.toast('등록 실패', '내용을 입력해주세요', 'error')

			try {
				if (this.mode === 'create') {
					await this.createFaq(this.formData)
					this.$root.toast('등록 완료', '공지사항이 등록되었습니다.', 'success')
					this.$router.replace({
						name: PageName.Lms.FAQ,
					})
				} else {
					this.formData.faqId = this.$route.query.id
					await this.updateFaq(this.formData)
					this.$root.toast('수정 완료', '공지사항이 수정되었습니다.', 'success')
					this.$router.replace({
						name: PageName.Lms.FAQ,
					})
				}
			} catch (e) {
				this.$root.toast('등록 실패', e.response.data.msg, 'error')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
