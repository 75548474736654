<template>
    <b-modal ref="modal" size="lg" centered>
		<template #modal-title>
			<div class="title">가격 파일 업로드</div>
		</template>
        <div class="form-header">
				<!--type [file]-->
            <div class="type-file">
                <div ref="upload-box" class="upload-box">
                    <input class="dropFile" type="file" ref="dropFile" @change="onChange" @drop="dropFile" />
                    <div class="file-box" v-if="file == null">
                        <p class="file-box-text">첨부할 파일을 마우스로 끌어오거나, 직접 선택해 주세요</p>
                        <div class="btn-wrap">
                            <button class="btn" ref="select-btn" @click="uploadFile">파일 선택</button>
                        </div>
                    </div>
                    <ul class="file-list" v-else>
                        <li>
                            <img src="@/assets/svg/common/docs.svg" />
                            <p>{{ file.name }}</p>
                            <button class="close-btn" @click="deleteFile">
                                <img src="@/assets/svg/app/Close-1.svg" />
                            </button>
                        </li>
                    </ul>
                </div>
                <!--//type [file]-->
            </div>
        </div>
		<template #modal-footer>
			<div class="footer-right">
                <button class="button-gray" @click="hide">취소</button>
				<button class="button-primary" @click="confirm">업로드</button>
			</div>
		</template>
    </b-modal>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    data(){
        return {
            file: null
        }
    },
    methods: {
		...mapActions('updateProductPrice/list', ['uploadProductPriceBulkupdate']),
        show(){
            this.file = null;
            this.$refs['modal'].show()
        },
        hide(){
            this.$refs['modal'].hide()
        },
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['dropFile'].files = event.dataTransfer.files
				this.onChange()
			}
		},
		onChange() {
			let fileValues = Object.values(this.$refs['dropFile'].files)
            if(fileValues.length > 0) {
                this.file = fileValues[0]
            }
			this.$refs['dropFile'].value = ''
		},
		uploadFile() {
			this.$refs['dropFile'].click()
		},
        deleteFile() {
			this.file = null
			this.$refs['dropFile'].value = ''
		},
        async confirm() {
            if (this.file) {
                const formData = new FormData()
                formData.append("file", this.file)

                await this.uploadProductPriceBulkupdate(formData)
            }

            this.hide();
        } 
    }
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
::v-deep {
	.modal-dialog {
		max-width: 700px;
	}
	.modal-content {
		// min-height: 580px;
		.modal-body {
			max-height: 700px;
			overflow-y: auto;
		}
	}
}

	.upload-box {
		width: 100%;
		height: 120px;
		border: 1px dashed $FILE_BORDER;
		border-radius: 5px;
		margin: 15px 0px;
		position: relative;

		overflow-y: auto;
		cursor: pointer;
		margin-bottom: 10px;
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
		.file-box {
			text-align: center;
			width: 100%;
			z-index: 2;
			// position: relative;
			padding-top: 26px;
			.file-box-text {
				font-size: 14px;
				font-weight: 400;
				color: $COLOR_111;
			}
			.btn-wrap {
				width: 100%;
				text-align: center;
				display: flex;
				align-items: center;
				justify-content: center;
				button {
					margin-top: 10px;
					cursor: pointer;
				}
			}
		}
		.file-list {
			width: 100%;
			padding: 14px 10px;
			height: 100%;
			// position: relative;
			z-index: 2;
			li {
				width: 100%;
				height: 30px;
				display: flex;
				align-items: center;
				font-size: 0.875rem;
				font-weight: 400;
				img {
					margin-right: 2px;
				}
				button {
					position: relative;
				}
			}
		}
	}

</style>
