<template>
	<div class="upload-wrapper">
		<base-item title="배너 이미지" class="image-upload-wrapper" required>
			<base-button size="md" @click="onClickAddImage">이미지 업로드</base-button>
			<div class="subtext">
				<b>이미지 업로드 Tip</b>
				<ul style="padding: 10px 0 20px 20px">
					<li v-for="(tip, index) in tipList" :key="`banner_${index}`" style="list-style-type: disc">{{ tip }}</li>
				</ul>
			</div>
			<template v-if="!hasFile && !hasImage">
				<div class="upload-drag-wrapper">
					<div class="drag-area">
						<input ref="image-file" multiple type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
						<h2>+</h2>
						<h5>이미지 업로드</h5>
						<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
					</div>
					<div class="size-guide-table">
						<table>
							<tr>
								<th>종류</th>
								<th>사이즈</th>
							</tr>
							<tr>
								<td>[WEB] 홈 배너 이미지</td>
								<td>3200x800</td>
							</tr>
							<tr>
								<td>[WEB] 이벤트 목록 배너 이미지</td>
								<td>3200x400</td>
							</tr>
							<tr>
								<td>[MOBILE] 홈 배너 이미지</td>
								<td>750x520</td>
							</tr>
							<tr>
								<td>[MOBILE] 이벤트 목록 배너 이미지</td>
								<td>750x200</td>
							</tr>
						</table>
					</div>
				</div>
			</template>
			<div class="upload-files-wrapper" v-else>
				<div>
					<input ref="image-file" multiple type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
					<input-banner-image
						title="[WEB] 홈 배너 이미지 3200x800"
						:defaultForm="webHomeBannerForm"
						:defaultImage="defaultImage.webHomeBanner"
						@changeImage="form => (webHomeBannerForm = form)"
						@updateImage="updateImage"
					/>
				</div>
				<div>
					<input-banner-image
						title="[WEB] 이벤트 목록 배너 이미지 3200x400"
						:defaultForm="webListBannerForm"
						:defaultImage="defaultImage.webListBanner"
						@changeImage="form => (webListBannerForm = form)"
						@updateImage="updateImage"
					/>
				</div>
				<div>
					<input-banner-image
						title="[MOBILE] 홈 배너 이미지 750x520"
						:defaultForm="mobileHomeBannerForm"
						:defaultImage="defaultImage.mobileHomeBanner"
						@changeImage="form => (mobileHomeBannerForm = form)"
						@updateImage="updateImage"
					/>
					<input-banner-image
						title="[MOBILE] 목록 배너 이미지 750x200"
						:defaultForm="mobileListBannerForm"
						:defaultImage="defaultImage.mobileListBanner"
						@changeImage="form => (mobileListBannerForm = form)"
						@updateImage="updateImage"
					/>
				</div>
			</div>
		</base-item>
		<modal-submit
			title="이미지 파일 확인 필요"
			content="이미지 사이즈에 맞지 않은 이미지가 포함되어 있거나,<br>업로드 가능한 이미지 수량보다 많습니다.<br>이미지 사이즈 당 1개의 이미지를 업로드해주세요."
			id="image-size-modal"
			:isOkOnly="true"
			@ok="closeModal"
		/>
	</div>
</template>

<script>
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import InputBannerImage from '@/views/common/components/formItem/InputBannerImage.vue'
import ModalSubmit from '@/views/common/modals/ModalSubmit'

export default {
	components: {
		BaseItem,
		InputBannerImage,
		BaseButton,
		ModalSubmit,
	},
	props: {},
	data() {
		return {
			webHomeBannerForm: {
				type: 'webHomeBannerForm',
				width: 3200,
				height: 800,
				image: null,
			},
			webListBannerForm: {
				type: 'webListBannerForm',
				width: 3200,
				height: 400,
				image: null,
			},
			mobileHomeBannerForm: {
				type: 'mobileHomeBannerForm',
				width: 750,
				height: 520,
				image: null,
			},
			mobileListBannerForm: {
				type: 'mobileListBannerForm',
				width: 750,
				height: 200,
				image: null,
			},
			hasImage: false,
			defaultImage: {
				webHomeBanner: null,
				webListBanner: null,
				mobileHomeBanner: null,
				mobileListBanner: null,
			},
			isUpdate: {
				webHomeBannerForm: false,
				webListBannerForm: false,
				mobileHomeBannerForm: false,
				mobileListBannerForm: false,
			},
		}
	},
	computed: {
		hasFile() {
			return this.images.find(it => it.image != null) != null
		},
		images() {
			return [this.webHomeBannerForm, this.webListBannerForm, this.mobileHomeBannerForm, this.mobileListBannerForm]
		},
		tipList() {
			return [
				'종류별 사이즈에 맞는 총 4장의 배너 이미지를 업로드해주세요.',
				'이벤트 페이지를 등록하려면 4장의 배너 이미지를 모두 업로드 해야 합니다.',
				'배너 이미지는 종류별 1장을 초과할 수 없습니다.',
				'이미지 업로드 버튼을 클릭하거나, 드래그 앤 드랍으로 여러 이미지를 한번에 업로드 할 수 있습니다.',
			]
		},
	},
	watch: {
		images: {
			deep: true,
			handler() {
				this.$emit(
					'changeValue',
					this.images.filter(it => it.image != null)
				)
			},
		},
	},
	created() {
		if (this.files) {
			this.files.forEach(it => this.setForm(it))
		}
	},
	methods: {
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['image-file'].files = event.dataTransfer.files
				this.uploadImage()
			}
		},
		setForm(file) {
			let reader = new FileReader()
			const data = this
			reader.onload = function (e) {
				let img = new Image()
				img.src = e.target.result
				img.onload = function () {
					let w = this.width
					let h = this.height
					if (w == 3200 && h == 800) data.webHomeBannerForm.image = file
					else if (w == 3200 && h == 400) data.webListBannerForm.image = file
					else if (w == 750 && h == 520) data.mobileHomeBannerForm.image = file
					else if (w == 750 && h == 200) data.mobileListBannerForm.image = file
					else return data.$bvModal.show('image-size-modal')
				}
			}
			reader.readAsDataURL(file)
		},
		uploadImage() {
			const image = [...Object.values(this.$refs['image-file'].files)]
			if (image.length > 4) return this.$bvModal.show('image-size-modal')
			if (image.length > 0) {
				image.forEach(it => this.setForm(it))
			}
			this.files = image
		},
		onClickAddImage() {
			this.$refs['image-file'].click()
		},
		closeModal() {
			this.webHomeBannerForm.image = null
			this.webListBannerForm.image = null
			this.mobileHomeBannerForm.image = null
			this.mobileListBannerForm.image = null
		},
		setImages(images) {
			this.hasImage = true
			this.defaultImage = images
		},
		updateImage(form) {
			this.isUpdate[form.type] = true
			this[form.type] = form
		},
		validate() {
			let isValid = true

			Object.keys(this.isUpdate).forEach(key => {
				if (this.isUpdate[key] && !this[key].image) isValid = false
			})

			return isValid
		},
	},
}
</script>
<style lang="scss" scoped>
.image-upload-wrapper {
	.subtext {
		margin-top: 10px;
		font-size: 14px;
	}
	.upload-drag-wrapper {
		display: flex;
		gap: 10px;
		margin-top: 10px;
		.drag-area {
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			width: calc(50% - 5px);
			max-width: 500px;
			height: 240px;
			background: #f5f5f5;
			border: 1px solid #ddd;
			border-radius: 5px;
			& > * {
				color: #999;
			}
			input {
				width: 100%;
				height: 100%;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				padding: 0 0;
				cursor: pointer;
				&.isFile {
					visibility: hidden;
				}
			}
		}
		.size-guide-table {
			width: calc(50% - 5px);
			max-width: 500px;
			height: 240px;
			border: 1px solid #ddd;
			border-radius: 5px;
			table {
				width: 100%;
				height: 100%;
				th,
				td {
					width: 50%;
					height: calc(100% / 7);
					padding-left: 16px;
					font-size: 14px;
					&:first-child {
						border-right: 1px solid #ddd;
					}
				}
				tr {
					border-bottom: 1px solid #ddd;
					&:last-child {
						border-bottom: none;
					}
					&:first-child {
						background-color: #f5f5f5;
					}
				}
			}
		}
	}
	.error-text {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
	.upload-files-wrapper {
		& > div {
			gap: 25px;
			display: flex;
			align-items: baseline;
		}
		input {
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
	}
}
</style>
