<template>
	<tr class="table-item">
		<td>
			<div>
				<span :class="['status', item.isStop ? 'STOP_SELLING' : 'SELLING']">
					{{ item.isStop ? '판매중지' : '판매중' }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.code }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span>
					{{ item.seller.name }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 350px">
				<span style="font-weight: 700">{{ item.name }}</span>
				<p style="color: #666666; font-size: 12px">{{ item.subname }}</p>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.productCount }}개 </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.totalStock }}개 </span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span> {{ `${item.category.parents.length ? item.category.parents[0].korName + ' / ' : ''}${item.category.korName}` }} </span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span> {{ item.brand.name }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.code }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.casno }} </span>
			</div>
		</td>
		<td>
			<div style="min-width: 200px">
				<span> {{ item.tags }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ shippingDay }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingDirectContact === 1 ? '개별연락 가능' : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingCanQuick === 1 ? '퀵서비스 가능' : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.shippingAddress.name }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.updateDatetime ? $DateTime.fromISO(item.updateDatetime).toFormat('yyyy.MM.dd T') : '-' }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ item.stopDatetime ? $DateTime.fromISO(item.stopDatetime).toFormat('yyyy.MM.dd T') : '-' }} </span>
			</div>
		</td>

		<!-- <td>
			<div style="min-width: 150px">
				<div class="logo-image-wrapper">
					<img v-if="item.image" class="logo-image" :src="item.image" />
					<span class="logo-title" v-else>{{ item.name }}</span>
				</div>
			</div>
		</td>
		<td>
			<div style="min-width: 150px; max-width: 750px">
				<span style="font-weight: 700">
					{{ item.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					<span :class="['status', item.isRecommend ? 'recommend' : '']"> {{ statusText }} </span>
				</span>
			</div>
		</td>
		<td>
			<div>
				<span style="font-weight: 700"> {{ commaNum(item.productInfoCount) }}개 </span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px">
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td> -->
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'

export default {
	mixins: [MixinItem],
	props: {
		item: {
			type: Object,
		},
	},
	computed: {
		shippingDay() {
			if (!this.item.shippingDayPolicy) return '판매자 문의필요'
			else if (this.item.shippingDayPolicy === 'DEFAULT') return `${this.item.shippingDay}일`
			else if (this.item.shippingDayPolicy === 'SHAK') return '샥샥배송'
			else if (this.item.shippingDayPolicy === 'SIGMA') return this.item.shippingDay === 0 ? '오늘 도착' : '내일 오전 도착'
		},
	},
	methods: {},
}
</script>

<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
</style>
