<template>
	<b-modal ref="modal" title="기관 검색" centered>
		<!-- <template #modal-header>
			<h1>기관 검색</h1>
		</template> -->
		<div class="search-wrap">
			<search-box
				class="search-box"
				name="modal-search-product-search-box"
				placeholder="기관명, 기관ID, 멤버이름을 입력해주세요"
				@change="getInstituteList"
				v-model="params.keyword"
			/>
		</div>
		<div class="list-wrap" ref="scroll-container" @scroll="onScroll">
			<div class="institute-wrap" :class="{ selected: item.id == selected }" v-for="item in list" :key="item.id" @click="selected = item.id">
				<img class="logo" :src="item.logo ?? require('@/assets/svg/common/institution-logo-100.png')" />
				<div class="content">
					<div class="institute-name">기관명: {{ item.name }}</div>
					<div class="institute-id">기관ID: {{ item.id }}</div>
					<div class="institute-member">멤버 수: {{ item.memberCount.toLocaleString() }}명</div>
					<div class="institute-id">관리자: {{ item.almightyMembers }}</div>
					<div class="institute-id">멤버: {{ item.instMembers }}</div>
				</div>
			</div>
		</div>
		<template #modal-footer>
			<div class="footer-right">
				<button class="button-gray" @click="hide()">취소</button>
				<button class="button-primary" @click="onClickConfirm" :disabled="!selected">선택</button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import MixinScrolling from '@/mixins/scrolling'
import SearchBox from '@/views/common/components/searchBox/index'
import { mapActions } from 'vuex'
export default {
	components: {
		SearchBox,
	},
	mixins: [MixinScrolling],
	data() {
		return {
			selected: null,
			params: {
				keyword: '',
				offset: 0,
				length: 20,
			},
			list: [],
			count: 0,
		}
	},
	watch: {
		async isBottom() {
			if (this.list.length < this.count) await this.getInstituteList()
		},
	},
	methods: {
		...mapActions('institute', ['getList']),

		async getInstituteList() {
			if (this.params.keyword.length > 0) {
				const { data } = await this.getList(this.params)
				this.list = this.list.concat(data.list)
				this.count = data.count
				this.params.offset = this.list.length
			} else {
				this.list = []
				this.params.offset = 0
			}
		},
		show(keyword) {
			this.params = {
				keyword: '',
				offset: 0,
				length: 20,
			}
			this.list = []
			this.params.keyword = keyword
			this.selected = keyword
			this.$refs['modal'].show()
			this.getInstituteList()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		onClickConfirm() {
			this.$emit('confirm', this.selected)
			this.hide()
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-base.scss';
.list-wrap {
	margin-top: 30px;
	border: 1px solid $FILE_BORDER;
	border-radius: 5px;
	height: 500px;
	overflow-y: auto;
	.institute-wrap {
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 16px;
		gap: 16px;
		min-height: 80px;
		border-bottom: 1px solid $FILE_BORDER;
		&:hover {
			background-color: $PRIMARY_YELLOW_100;
		}
		&.selected,
		&:active {
			background-color: $PRIMARY_YELLOW_200;
		}
		.logo {
			border-radius: 5px;
			width: 56px;
			height: 56px;
			object-fit: cover;
		}
		.content {
			display: flex;
			flex-direction: column;
			width: calc(100% - 72px);
			.institute-name {
				font-size: 14px;
				color: #111;
			}
			.institute-member {
				margin-top: 5px;
				font-size: 12px;
				color: #333;
			}
			.institute-id {
				font-size: 12px;
				color: #333;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
}
</style>
