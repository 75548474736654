<template>
	<div class="detail-search-box">
		<search-title v-if="title" :title="title" :subTitle="subTitle" :useFold="useFold" :isFold="isFold" @toggleFold="toggleFold" />
		<div v-show="!useFold || !isFold">
			<div v-if="search" class="search-box">
				<search-keyword ref="keywordSearch" :title="keywordTitle" :keywordPlaceholder="searchPlaceholder" @search="searchKeyword" />
				<search-date ref="dateSearch" :title="dateTitle" :datePlaceholder="datePlaceholder" :dateList="datePickerList" @change="searchDate" />
				<filter-item-list v-if="textFilterList.length > 0" :filterList="textFilterList" @reset="resetTextFilter" @delete="deleteTextFilter" />
			</div>
			<div class="search-box" style="margin-top: 10px">
				<search-check-list
					v-for="([key, value], index) of Object.entries(filters)"
					:key="index"
					:title="key"
					:list="value"
					:filterList="checkedFilterList"
					@change="changeCheckList(key, $event)"
				/>
				<filter-item-list
					v-if="checkedFilterList.length > 0"
					:filterList="checkedFilterList"
					@reset="resetCheckFilter"
					@delete="deleteCheckFilter"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import SearchKeyword from '@/views/common/components/search/SearchKeyword.vue'
import SearchDate from '@/views/common/components/search/SearchDate.vue'
import SearchCheckList from '@/views/common/components/search/SearchCheckList.vue'
import FilterItemList from '@/views/common/components/search/FilterItemList.vue'

export default {
	name: 'DetailSearchBox',
	components: {
		SearchTitle,
		SearchKeyword,
		SearchDate,
		SearchCheckList,
		FilterItemList,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		subTitle: {
			type: String,
			default: '',
		},
		search: {
			type: Boolean,
			default: true,
		},
		searchPlaceholder: {
			type: String,
			default: '',
		},
		datePlaceholder: {
			type: String,
			default: '',
		},
		filters: {
			type: Object,
			default: () => ({}),
		},
		datePickerList: {
			type: Array,
			default: () => [],
		},
		useFold: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			keywordTitle: '검색',
			dateTitle: '기간 조회',
			textFiltered: {},
			textFilterList: [],
			checkFiltered: {},
			checkedFilterList: [],
			isFold: true,
		}
	},
	methods: {
		searchKeyword(keyword) {
			if (!keyword) {
				this.textFiltered[this.keywordTitle] = []
				this.textFilterList =
					(this.textFiltered['기간 조회/주문일'] ||
						this.textFiltered['기간 조회/예상출고일'] ||
						this.textFiltered['기간 조회/배송시작일']) ??
					[]
			} else {
				this.textFiltered[this.keywordTitle] = [{ name: keyword, type: this.keywordTitle, value: keyword }]
				this.textFilterList = Object.values(this.textFiltered).reduce((acc, cur) => acc.concat(cur))
			}
			this.$emit('searchTextList', this.textFiltered)
		},
		searchDate(date, name) {
			this.textFiltered[`${this.dateTitle}/${name}`] = [{ name: name, type: this.dateTitle, value: date }]
			this.textFilterList = Object.values(this.textFiltered).reduce((acc, cur) => acc.concat(cur))
			this.$emit('searchTextList', this.textFiltered)
		},
		deleteTextFilter({ item, index }) {
			if (item.type === '검색') {
				this.textFiltered[item.type] = this.textFiltered[item.type].filter(filter => filter.value !== item.value)
			} else {
				this.textFiltered[`${item.type}/${item.name}`] = this.textFiltered[`${item.type}/${item.name}`].filter(
					filter => filter.value !== item.value
				)
			}
			this.textFilterList.splice(index, 1)
			this.$emit('searchTextList', this.textFiltered)
			if (item.type === this.keywordTitle) this.$refs.keywordSearch.reset()
			if (item.type === this.dateTitle) this.$refs.dateSearch.deleteFilter(item)
		},
		resetTextFilter() {
			this.textFiltered = {}
			this.textFilterList = []
			this.$emit('searchTextList', this.textFiltered)

			this.$refs.keywordSearch.reset()
			this.$refs.dateSearch.reset()
		},
		changeCheckList(key, list) {
			this.checkFiltered[key] = list
			this.checkedFilterList = Object.values(this.checkFiltered).reduce((acc, cur) => acc.concat(cur))
			this.$emit('searchCheckList', this.checkFiltered)
		},
		deleteCheckFilter({ item, index }) {
			this.checkFiltered[item.type] = this.checkFiltered[item.type].filter(filter => filter.value !== item.value)
			this.checkedFilterList.splice(index, 1)
			this.$emit('searchCheckList', this.checkFiltered)
		},
		resetCheckFilter() {
			this.checkFiltered = {}
			this.checkedFilterList = []
			this.$emit('searchCheckList', this.checkFiltered)
		},
		toggleFold() {
			this.isFold = !this.isFold
		},
	},
}
</script>

<style lang="scss" scoped>
.detail-search-box {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.search-row {
		&:first-child {
			border-radius: 5px 5px 0 0;
		}
		&:last-child {
			border-radius: 0 0 5px 5px;
		}
		&:not(:last-child) {
			border-bottom: none;
		}
		span {
			width: 70px;
		}
	}
}
</style>
