<template>
	<div class="select-option">
		<div class="input-wrapper" :class="{ error: isError }">
			<input
				type="text"
				:style="{ color: selected.name === null ? '#999999' : '' }"
				:placeholder="placeholder"
				readonly
				v-model="selected.name"
				@click="showOption = !showOption"
			/>
		</div>
		<div class="error-wrapper" v-if="isError">
			<div class="img">
				<img src="@/assets/svg/order/icon-alert.svg" alt="" />
			</div>
			<div class="message">
				{{ errorMessage }}
			</div>
		</div>
		<ul class="list" v-if="showOption">
			<li v-for="(item, index) in list" :key="`option_${index}`" @click="selectItem(item)">{{ item.name }}</li>
			<li v-if="!list.length && isEmptyOption" class="no-item">변경 가능한 상태 없음</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'selectOption',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		selectValue: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
		isError: {
			type: Boolean,
			default: false,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		isEmptyOption: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.init()
	},
	data() {
		return {
			showOption: false,
			selected: {
				value: '',
				name: '',
			},
			options: [],
		}
	},
	computed: {},
	watch: {
		selected: {
			deep: true,
			handler(val) {
				this.$emit('select', val)
			},
		},
	},
	methods: {
		init() {
			if (this.selectValue) {
				this.list.forEach(item => {
					if (this.selectValue == item.value) {
						this.selected = item
					}
				})
			} else {
				this.selected = {}
				this.$emit('select', this.selected)
			}
		},
		selectItem(item) {
			this.selected = item
			this.showOption = false
		},
	},
}
</script>

<style lang="scss" scoped>
.select-option {
	width: 100%;
	position: relative;

	.input-wrapper {
		height: 36px;
		border: 1px solid $COLOR_DDD;
		border-radius: 5px;
		background-color: white;
		padding: 0 24px 0 1rem;
		&.error {
			border: 1px solid $PRIMARY_RED;
		}
		&:after {
			width: 20px;
			height: 24px;
			position: absolute;
			top: 2px;
			right: 5px;
			content: url(~@/assets/svg/app/select-arrow.svg);
			opacity: 0.6;
		}
	}
	.error-wrapper {
		display: flex;
		align-items: center;
		.img {
			display: flex;
			align-items: center;
		}
		.message {
			color: $PRIMARY_RED;
			font-size: 0.75rem;
			margin-left: 5px;
		}
	}
	input[type='text'] {
		border: 0;
		font-size: 0.875rem;
		font-weight: 500;
		height: 100%;
		padding: 0 0;
		width: 100%;
		cursor: pointer;
		&::placeholder {
			color: $COLOR_999;
		}
	}
	.list {
		width: 100%;
		max-height: 240px;
		overflow-y: auto;
		border-radius: 5px;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
		background-color: white;
		position: absolute;
		top: 36px;
		left: 0;
		z-index: 2;
		li {
			height: 36px;
			display: flex;
			align-items: center;
			padding-left: 1rem;
			font-size: 0.875rem;
			transition: all 0.3s;
			cursor: pointer;
			&:hover {
				background-color: $HOVER_BACKGROUND;
			}

			&.no-item {
				color: #999999;
			}
		}
	}
}
</style>
