import buyerAPI from '@/services/api/buyer/index.js'

export default {
	namespaced: true,
	state: {
		item: {},
	},
	getters: {},
	mutations: {
		setItem: (state, payload) => {
			state.item = payload
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await buyerAPI.lookupBuyer(id)
			commit('setItem', response.data)
		},
	},
}
