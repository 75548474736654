<template>
	<modal-filter ref="modal" @confirm="confirm" @reset="reset">
		<template #modal-content v-if="filter">
			<filter-list ref="filter-lab-status" title="상태" :filterCookieName="filterCookieName.isBlock" :list="filter.isBlock" />
			<filter-list ref="filter-lab-type" title="유형" :filterCookieName="filterCookieName.instituteType" :list="filter.instituteType" />
			<filter-list ref="filter-lab-sync-with-pro" title="LM 연동" :filterCookieName="filterCookieName.syncWithPro" :list="filter.syncWithPro" />
		</template>
	</modal-filter>
</template>

<script>
import ModalFilter from '@/views/common/modals/ModalFilterBase.vue'
import FilterList from '@/views/common/components/filter/FilterList.vue'
import { mapState, mapMutations } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'

export default {
	components: {
		ModalFilter,
		FilterList,
	},
	computed: {
		...mapState('lab/list', ['filter', 'filterCookieName']),
	},
	methods: {
		...mapMutations('lab/list', ['setFilter']),
		async show() {
			this.setFilter()
			this.$refs['modal'].show()
		},
		confirm() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.setCookies !== 'undefined') it.setCookies()
			})
			const params = {
				isBlock: this.$refs['filter-lab-status'].checkedList ?? [],
				instituteType: this.$refs['filter-lab-type'].checkedList ?? [],
				syncWithPro: this.$refs['filter-lab-sync-with-pro'].checkedList ?? [],
			}
			this.$emit('changeLoadParams', params)
			this.$refs['modal'].hide()
			this.$root.toast('필터', '필터가 적용되었습니다.', ToastType.SUCCESS)
		},
		reset() {
			Object.values(this.$refs).forEach(it => {
				if (typeof it.reset !== 'undefined') it.reset()
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-body {
	overflow-y: auto;
	max-height: 70vh;
}
</style>
