<template>
	<div class="create-wrapper">
		<div class="create-header">
			<h2>브랜드 등록하기</h2>
		</div>
		<div class="create-content">
			<div>
				<h5>브랜드 정보</h5>
				<input-text
					ref="title"
					title="브랜드명"
					placeholder="브랜드명 입력"
					:isValid="isValid.name"
					:inputValue="formData.name"
					@changeValue="data => (formData.name = data)"
					inValidText="브랜드명을 입력해주세요"
					isRequired
				/>
				<input-logo-image title="로고 이미지" @changeValue="data => (formData.logoImage = data.image)" />
				<input-radio
					title="우선순위 지정"
					:list="radioList"
					:subText="subText"
					:tab="'common'"
					@changeValue="data => (formData.isRecommend = data.id === 'common' ? 'N' : 1)"
				/>
				<input-custom-text-list title="검색 태그" :subContentText="subContentText" addButtonTitle="검색어 추가" @change="setTags" />
			</div>
		</div>
		<div class="create-footer">
			<base-button size="lg" color="primary" @click="regist">등록</base-button>
		</div>
	</div>
</template>

<script>
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputLogoImage from '@/views/lms/brand/components/InputLogoImage.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import InputCustomTextList from '@/views/common/components/formItem/InputCustomTextList.vue'
import BaseItem from '@/views/common/components/BaseItem.vue'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import PageName from '@/utils/define/PageName'
import { mapActions, mapState } from 'vuex'

export default {
	components: {
		InputText,
		BaseItem,
		BaseButton,
		InputLogoImage,
		InputRadio,
		InputCustomTextList,
	},
	data() {
		return {
			formData: {
				name: null,
				logoImage: null,
				isRecommend: 'N',
				tags: null,
			},
			isValid: {
				name: true,
			},
		}
	},
	computed: {
		radioList() {
			return [
				{ id: 'common', title: '일반' },
				{ id: 'recommend', title: '추천 브랜드' },
			]
		},
		subText() {
			return '추천 브랜드로 설정할 경우, 스토어 홈에서 노출되며 브랜드 관에서 상위에 노출됩니다.'
		},
		subContentText() {
			return '물품정보 외에 검색어를 추가하여, 검색결과에 상품을 노출시킬 수 있습니다.'
		},
	},
	methods: {
		...mapActions('lms/brand', ['createBrand']),
		async regist() {
			if (!this.formData.name) return (this.isValid.name = false)

			const formData = new FormData()
			Object.keys(this.formData).forEach(key => {
				if (key === 'tags') this.formData.tags.forEach(tag => formData.append('tags[]', tag))
				else formData.append(key, this.formData[key])
			})
			try {
				await this.createBrand(formData)
				this.$root.toast('등록 완료', '브랜드가 등록되었습니다.', 'success')
				this.$router.replace({
					name: PageName.Lms.Brand,
				})
			} catch (e) {
				this.$root.toast('등록 실패', e.response.data.msg, 'error')
			}
		},
		setTags(data) {
			let tags = []
			data.forEach(tag => {
				tags.push(tag.value)
			})

			this.formData.tags = tags
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_create.scss';
</style>
