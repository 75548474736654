<template>
	<tr class="table-header">
		<th>
			<span>이름</span>
		</th>
		<th>
			<span>연락처</span>
		</th>
		<th>
			<span>계정</span>
		</th>
		<th>
			<span>본인인증</span>
		</th>
		<th>
			<span>마케팅수신동의(SMS)</span>
		</th>
		<th>
			<span>마케팅수신동의(이메일)</span>
		</th>
		<th>
			<span>마케팅수신동의(PUSH)</span>
		</th>
		<th :class="orderClass('CREATE_DATETIME')" @click.stop="changeOrder('CREATE_DATETIME')">
			<span>가입일</span>
		</th>
	</tr>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
	computed: {
		...mapGetters('buyer/list', ['order', 'orderDirection']),
	},
	methods: {
		...mapActions('buyer/list', ['setLoadParams']),
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
