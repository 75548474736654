<template>
	<div class="input-checkbox-wrap">
		<base-item :title="title" :subContentText="subContentText">
			<div class="input-checkbox-content">
				<label style="display: flex">
					<input style="margin-top: 3px" type="checkbox" v-model="isCheck" @change="changeInput" />
					<p style="padding-left: 10px">{{ checkSubTitle }}</p>
				</label>
			</div>
		</base-item>
	</div>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'

export default {
	name: 'InputCheckBox',
	components: {
		BaseItem,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		checkSubTitle: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		propCheck: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isCheck: false,
		}
	},
	methods: {
		changeInput() {
			this.$emit('changeValue', this.isCheck)
		},
	},
}
</script>

<style lang="scss" scoped>
.input-checkbox-content {
	display: flex;
	gap: 15px;
	padding: 5px 0;
}
</style>
