import productAPI from '@/services/api/product'
import cookies from 'vue-cookies'
import { productListTableAllColumns } from '@/utils/define/table/productListTable'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			keywordType: 'TEXT',
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
			isStops: [],
			// estimateStatus: JSON.parse(cookies.get('filter_request_estimate_status')) ?? '',
			// brandNames: JSON.parse(cookies.get('filter_request_brand_names')) ?? [],
			// instituteIds: JSON.parse(cookies.get('filter_request_institute_ids')) ?? [],
		},
		filter: null,
		// filterCookieName: {
		// 	estimateStatus: 'filter_request_estimate_status',
		// 	brandNames: 'filter_request_brand_names',
		// 	instituteIds: 'filter_request_institute_ids',
		// },
		list: [],
		count: 0,
		visibleColumns: JSON.parse(cookies.get('product_list_visible_column')) ?? productListTableAllColumns,
		isLoading: false,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedStatus: state => {
			if (!state.loadParams.isStops.length) return ['ALL']
			else if (state.loadParams.isStops[0]) return ['STOP']
			else if (!state.loadParams.isStops[0]) return ['ING']
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => [],
		// filteredCount: state => state.loadParams.estimateStatus.length + state.loadParams.brandNames.length + state.loadParams.instituteIds.length,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setFilter: (state, data) => {
			let brandNames = []
			data.brandNames.forEach(name => {
				brandNames.push({ name: name, id: name })
			})
			const filter = {
				estimateStatus: [
					{ id: 'NONE', name: '미작성' },
					{ id: 'COMPLETE', name: '작성완료' },
				],
				brandNames: brandNames,
				instituteIds: data.institutes,
			}
			state.filter = filter
		},
		setVisibleColumn: (state, data) => {
			state.visibleColumns = data
			cookies.set('product_list_visible_column', JSON.stringify(data))
		},
		setLoading: (state, isLoading) => {
			state.isLoading = isLoading
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			commit('setLoading', true)
			const params = {
				...state.loadParams,
			}
			const response = await productAPI.list(params)
			commit('setList', response.data)
			commit('setLoading', false)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		// getFilter: async ({ commit }) => {
		// 	const response = await productAPI.filter()
		// 	commit('setFilter', response.data)
		// },
		// exportExcel: async ({ state }, type) => {
		// },
	},
}
