import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.ChemicalAPI.Request,
		name: PageName.ChemicalAPI.Request,
		props: true,
		component: () => import('@/views/api/pages/Request.vue'),
	},
	{
		path: RoutePath.ChemicalAPI.RequestDetail,
		name: PageName.ChemicalAPI.RequestDetail,
		props: true,
		component: () => import('@/views/api/pages/RequestDetail.vue'),
	},
	{
		path: RoutePath.ChemicalAPI.ProductCreate,
		name: PageName.ChemicalAPI.ProductCreate,
		props: true,
		component: () => import('@/views/api/pages/ProductCreate.vue'),
	},
]
