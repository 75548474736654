<template>
	<div class="image-box">
		<img class="img-list" :src="image" />
		<img class="delete" src="@/assets/svg/common/icon_img_delete.svg" @click="$emit('deleteImage', index)" />
	</div>
</template>

<script>
export default {
	name: 'ImageBox',
	props: {
		image: {
			type: String,
			default: '',
		},
		index: {
			type: Number,
			default: 0,
		},
	},
}
</script>

<style lang="scss" scoped>
.image-box {
	border: 1px solid #dddddd;
	border-radius: 8px;
	position: relative;
	.img-list {
		width: 50px;
		height: 50px;
		border-radius: 8px;
	}
	.delete {
		cursor: pointer;
		position: absolute;
		top: -6px;
		right: -10px;
	}
}
</style>
