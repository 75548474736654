<template>
	<div class="EstimateDetail">
		<div class="content-header">
			<div class="title">
				<h4>{{ info.no }}</h4>
				<div class="subtitle">
					<span class="status-text" :class="statusClass">{{ info.status?.name }}</span>
					<p :class="{ error: isExpired }">{{ expireDatetime }}</p>
				</div>
			</div>
			<div class="right-btn-group">
				<button v-if="!isExpired" class="btn" @click="printPdf">PDF 출력</button>
				<button class="btn" @click="copy">복제</button>
				<button v-if="!isExpired" class="Btn__export" @click="$bvModal.show('modal-submit')">만료 처리</button>
			</div>
		</div>
		<div class="content-wrapper">
			<info-list title="견적서 정보" :list="estimateInfo" />
			<info-list title="담당자 정보" :list="assigneeInfo" />
			<info-list title="수신자 정보" :list="recipientInfo" />
			<textarea-edit-form
				title="숨김 메모"
				content="*해당 메모는 상품요청자에게 노출되지 않는 정보입니다"
				:defaultValue="info.adminMemo"
				:hideBtn="isExpired"
				:maxlength="500"
				@save="saveTextForm($event, 'adminMemo')"
			/>
			<product-info-table
				title="상품정보"
				:headers="productInfoTableHeader"
				:products="info.products"
				:totalSellingPrice="info.totalSellingPrice"
				:totalVat="info.totalVat"
			/>
			<textarea-edit-form
				title="메모"
				content="*해당 메모는 상품요청자에게 노출되는 정보입니다"
				:defaultValue="info.memo"
				:hideBtn="isExpired"
				:maxlength="500"
				@save="saveTextForm($event, 'memo')"
			/>
			<template v-if="info.createType === 'VMS'">
				<info-list title="판매자 정보" :list="sellerInfo" style="margin-top: 40px" />
			</template>
			<template v-else>
				<detail-table v-if="info.draft" title="연결된 상품요청서" :headers="headers.requestHeaders"> </detail-table>
				<detail-table v-if="info.draft" title="연결된 견적서" :headers="headers.estimateHeaders">
					<template>
						<tbody>
							<tr v-for="(product, index) in info.products" :key="`table-row${index}`">
								<td>
									<span class="status-text">미확인</span>
								</td>
								<td>2022-02-01</td>
								<td>강형곤</td>
								<td>YYMMDD-123456</td>
								<td>{{ product.name }}</td>
								<td>{{ commaNum(product.price) }}</td>
								<td>
									<div class="info">
										<b>홍길동</b>
										<p>고려대학교 김이박교수님연구실</p>
									</div>
								</td>
								<td>010-1234-4567</td>
								<td>hong@smartjackwp.com</td>
								<td>수신자</td>
								<td>2022-02-01</td>
								<td>2022-02-01</td>
							</tr>
						</tbody>
					</template>
				</detail-table>
				<detail-table v-if="info.draft" title="연결된 주문내역" :headers="headers.orderHeaders" />
				<detail-table v-if="info.draft" title="연결된 주문자 작성 견적서" :headers="headers.ordererEstimateHeaders" />
			</template>
		</div>

		<!--modals-->
		<modal-submit title="견적서 만료 처리" content="견적서를 만료처리하시겠습니까?<br>만료된 견적서는 주문할 수 없습니다." @ok="processExpire" />
		<modal-search-product title="상품 검색" />
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
import ProductInfoTable from '@/components/product/ProductInfoTable'
import DetailTable from '@/components/table/DetailTable'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ModalSearchProduct from '@/views/common/modals/ModalSearchProduct'
import ModalEditPrice from '@/views/estimate/estimate/modals/ModalEditPrice'

import headers from '@/utils/define/table/header'
import { Estimate } from '@/utils/define/ItemCode'
import PageName from '@/utils/define/PageName'
import RoutePath from '@/utils/define/RoutePath'
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'EstimateDetail',
	components: {
		InfoList,
		TextareaEditForm,
		ProductInfoTable,
		DetailTable,
		ModalSubmit,
		ModalSearchProduct,
		ModalEditPrice,
	},
	mixins: [mixin],
	mounted() {},
	data() {
		return {
			headers,
			productInfoTableHeader: [
				{ name: '상품 정보', key: 'info', width: '68%' },
				{ name: '수량', key: 'quantity', width: '70px' },
				{ name: '소비자가', key: 'price', width: '13%' },
				{ name: '판매가', key: 'sellingPrice', width: '13%' },
			],
			info: {},
		}
	},
	computed: {
		...mapState('estimate/detail', ['item']),
		estimateInfo() {
			let list = []
			list.push({ title: '작성자', value: this.info.writer?.name })
			list.push({ title: '견적상태', value: this.info.status?.name })
			list.push({ title: '견적생성 일시', value: this.$DateTime.fromISO(this.info.createDatetime).toFormat('yyyy-MM-dd T') })
			list.push({ title: '견적일', value: this.$DateTime.fromISO(this.info.estimateDatetime).toFormat('yyyy-MM-dd') })
			list.push({ title: '견적 유효기간', value: this.$DateTime.fromISO(this.info.expireDatetime).toFormat('yyyy-MM-dd') })
			list.push({ title: ' ', value: '' })
			return list
		},
		assigneeInfo() {
			//담당자 정보
			let list = []
			list.push({ title: '이름', value: this.info?.manager?.name })
			list.push({ title: '소속', value: this.info?.manager?.instituteName })
			list.push({ title: '연락처', value: this.info?.manager?.phone })
			list.push({ title: '이메일', value: this.info?.manager?.email })

			return list
		},
		recipientInfo() {
			//수신자 정보
			let list = []
			list.push({ title: '소속', value: this.info?.recipient?.instituteName, key: 'instituteName' })
			list.push({ title: '이름', value: this.info?.recipient?.name, key: 'name' })
			list.push({ title: '연락처', value: this.info?.recipient?.phone, key: 'phone' })
			list.push({ title: '이메일', value: this.info?.recipient?.email, key: 'email' })

			return list
		},
		sellerInfo() {
			//판매자 정보
			let list = []
			list.push({ title: '판매자 이름', value: this.info?.distributor?.ceoName })
			list.push({ title: '담당자', value: this.info?.manager?.name })
			list.push({ title: '연락처', value: this.info?.distributor?.contactPhone })
			list.push({ title: '이메일', value: this.info?.manager?.email })
			list.push({ title: '판매자 메모', value: this.info?.distributor?.memo, row: true })

			return list
		},
		statusClass() {
			return Estimate.statusToClass(this.info.status?.code)
		},
		isExpired() {
			if (new Date(this.info.expireDatetime) < new Date()) {
				return true
			} else {
				return false
			}
		},
		expireDatetime() {
			if (this.isExpired) {
				return `견적만료 일시 : ${this.$DateTime.fromISO(this.info.expireDatetime).toFormat('yyyy-MM-dd T')} (만료됨)`
			} else {
				return `견적 유효기간 : ${this.$DateTime.fromISO(this.info.expireDatetime).toFormat('yyyy-MM-dd')}`
			}
		},
	},
	created() {
		this.fetchDetail()
	},
	methods: {
		...mapActions('estimate/detail', ['getDetail', 'updateEstimate', 'expiryEstimate', 'printEstimate']),
		async fetchDetail() {
			await this.getDetail(this.$route.params.id)
			this.info = this.$_.cloneDeep(this.item)
		},
		async printPdf() {
			const url = await this.printEstimate({
				id: this.$route.params.id,
				includeManager: false, // TODO: 담당자 포함 여부 - 디자인 업데이트 안됨
			})
			window.open(`${RoutePath.Estimate.EstimateExport}?url=${url}`)
		},
		copy() {
			this.$router.push({
				name: PageName.Estimate.EstimateForm,
				params: {
					mode: 'copy',
				},
				query: {
					id: this.info.id,
				},
			})
		},
		async processExpire() {
			try {
				await this.expiryEstimate({
					id: this.item.id,
				})
				this.fetchDetail()
			} catch (e) {
				this.$root.toast('처리 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
		async saveTextForm(value, field) {
			try {
				await this.updateEstimate({
					id: this.item.id,
					[field]: value,
				})
				this.fetchDetail()
			} catch (e) {
				this.$root.toast('수정 실패', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.EstimateDetail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
					&.error {
						color: $PRIMARY_RED;
					}
					&:after {
						width: 1px;
						height: 12px;
						content: '';
						position: absolute;
						top: calc(50% - 6px);
						left: 10px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
				&:last-child {
					position: relative;
					margin-left: 40px;
					&:after {
						width: 1px;
						height: 24px;
						content: '';
						position: absolute;
						top: calc(50% - 10px);
						left: -20px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px 40px;
		.text-edit-form {
			margin-bottom: 40px;
		}
		.product-info-table {
			margin: 40px 0;
		}
		.detail-table {
			margin-top: 40px;
		}
	}
}
</style>
