<template>
	<div class="item" :class="{ complete: complete, disabled: disabled }">
		<span v-if="showCompleteText" class="complete-text">{{ text }}</span>
		<span v-else class="input-sizer">
			<input type="text" size="1" @input="onInput" @keyup.enter="onSave" @blur="onSave" maxlength="40" />
		</span>
		<img v-if="!disabled" src="@/assets/svg/sellInfo/delete-button-icon.svg" alt="delete" @click="onClickDeleteBtn" />
	</div>
</template>

<script>
export default {
	name: 'AdditionalText',
	props: {
		index: {
			type: Number,
			required: true,
		},
		text: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		complete: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		showCompleteText() {
			return this.disabled || this.complete
		},
	},
	methods: {
		onInput(e) {
			const target = e.target
			target.parentNode.dataset.value = target.value
		},
		onSave(e) {
			if (!e.target.value || e.target.value.replace(/^\s+|\s+$/g, '') === '') return
			this.$emit('save', e.target.value, this.index)
		},
		onClickDeleteBtn() {
			this.$emit('delete', this.index)
		},
	},
}
</script>

<style lang="scss" scoped>
.item {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 2px;
	padding: 6px 8px;
	border: 1px solid $COLOR_333;
	border-radius: 5px;
	font-size: 14px;
	height: 38px;
	&.complete {
		border: 1px solid $FILE_BORDER;
	}
	&.disabled {
		background: $LIST_BACKGROUND;
		color: $FONT_PLACEHOLDER;
		border: 1px solid $FILE_BORDER;
	}
	input {
		width: 30px;
		border: none;
	}
	.complete-text {
		padding: 0 0.25em;
	}
	.input-sizer {
		display: inline-grid;
		vertical-align: top;
		align-items: center;
		position: relative;
		&.stacked {
			padding: 0.5em;
			align-items: stretch;

			&::after,
			input {
				grid-area: 2 / 1;
			}
		}
		&::after,
		input {
			width: auto;
			min-width: 1em;
			grid-area: 1 / 2;
			font: inherit;
			padding: 0 0.25em;
			margin: 0;
			resize: none;
			background: none;
			appearance: none;
			border: none;
		}
		&::after {
			content: attr(data-value) ' ';
			visibility: hidden;
			white-space: pre-wrap;
		}
	}
}
</style>
