<template>
	<section class="AppHeader__container" v-if="subPath">
		<div class="AppHeader__left-header">
			<p>{{ headerPath }}</p>
			<span> / </span>
			<p
				:class="{
					active: detailPaths.length == 0 && !$route.params.name,
				}"
				@click="clickMenu"
			>
				{{ subPath }}
			</p>
			<b v-if="detailPaths.length > 0">&nbsp;/ {{ detailPaths[0] }}</b>
			<b v-else-if="$route.params.name">&nbsp;/ {{ $route.params.name }}</b>
		</div>
		<div class="AppHeader__right-header">
			<app-notice></app-notice>
			<app-header-menu></app-header-menu>
		</div>
	</section>
</template>

<script>
import AppHeaderMenu from '@/views/app/components/AppHeaderMenu'
import AppNotice from '@/views/app/components/AppNotice'
import { menuList } from '@/utils/define/menu'
import { mapState } from 'vuex'

export default {
	name: 'AppHeader',
	components: {
		AppHeaderMenu,
		AppNotice,
	},
	data() {
		return {
			detailPaths: [],
			headerPath: '',
			pagePath: null,
			rightMenuOn: false,
		}
	},
	watch: {
		$route: {
			deep: true,
			handler(to, from) {
				if (this.subPath != null) {
					this.detailPaths = []
				}
			},
		},
		storageDetailPaths: {
			deep: true,
			handler(newValue) {
				this.detailPaths = newValue
			},
		},
	},
	computed: {
		...mapState('breadcrumb', ['storageDetailPaths']),
		menuList() {
			return menuList
		},
		subPath() {
			// todo: 3 depth 표시 작업
			let list = this.menuList
			for (let item of list) {
				if (item.list) {
					for (let child of item.list) {
						if (child.list) {
							for (let sub of child.list) {
								if (sub.categoryType && this.$route.params.categoryType == sub.categoryType) {
									this.headerPath = item.name
									this.pagePath = sub.routeName
									return `${child.name} / ${sub.name}`
								} else if (this.$route.path.indexOf('waitingRegistration') >= 0) {
									this.headerPath = item.name
									this.pagePath = sub.routeName
									return `${child.name} / ${sub.name}`
								}
							}
						}

						if (this.$route.path.indexOf(this.slicePath(child.path)) >= 0) {
							this.headerPath = item.name
							this.pagePath = child.routeName
							return `${child.name}`
						}
					}
				} else {
					if (this.$route.path.indexOf(this.slicePath(item.menuPath)) >= 0) {
						this.headerPath = item.name
						this.pagePath = item.routeName
						return ''
					}
				}
			}
		},
	},
	mounted() {
		if (this.storageDetailPaths) {
			this.detailPaths = this.storageDetailPaths
		} else {
			this.detailPaths = []
		}
	},
	methods: {
		slicePath(path) {
			if (path) {
				return path.replace('/:instituteId', '').replace('/:type')
			}
		},
		clickMenu() {
			if (this.pagePath) {
				this.$router.push({
					name: this.pagePath,
				})
			} else {
				window.location.reload()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.AppHeader__container {
	display: flex;
	position: fixed;
	min-width: 600px;
	width: calc(100% - 250px);
	height: 60px;
	left: 250px;
	background-color: white;
	padding: 23px 30px 24px 30px;
	font-size: 13px;
	border-bottom: 1px solid $COLOR_EEE;
	z-index: -1;
	color: $PRIMARY_BLACK;
	&.right-menu-on {
		z-index: 2;
	}
	&.notice-on {
		z-index: 2;
	}
	&.experience {
		top: 80px;
	}
}

.AppHeader__left-header {
	display: inline-flex;
	width: 80%;
	height: 20px;
	background-color: white;
	text-align: left;
	p {
		display: inline-block;
		cursor: pointer;
		white-space: nowrap;
		&.active {
			font-weight: 700;
			user-select: none;
			pointer-events: none;
			text-decoration: none;
		}
	}
	span {
		margin: 0 3px;
	}
	a {
		display: inline-block;
		&.active {
			font-weight: 700;
			user-select: none;
			pointer-events: none;
			text-decoration: none;
		}
	}
	b {
		white-space: nowrap;
	}
}

.AppHeader__right-header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 50%;
	height: 20px;
	text-align: right;
}
</style>
