<template>
	<div class="ProductList">
		<list-header @changeLoadParams="setLoadParams" @refresh="getList" />
		<div class="table-wrapper">
			<div v-if="isLoading" style="height: 100%; display: flex; justifiy-contents: center; align-items: center; justify-content: center">
				<lottie-animation
					style="height: 300px; width: 300px"
					ref="lottie-animation"
					:animationData="require('@/assets/json/lm_loading_lottie.json')"
					:loop="true"
				></lottie-animation>
			</div>
			<product-table v-else class="table" :list="list" />
		</div>
		<pagination :total="count" :defaultOffset="50" @page="pagination"></pagination>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import ProductTable from '../components/Table.vue'
import LottieAnimation from 'lottie-web-vue'

export default {
	components: {
		Pagination,
		ProductTable,
		ListHeader,
		LottieAnimation,
	},
	created() {
		this.getList()
	},
	computed: {
		...mapState('product/list', ['list', 'count', 'isLoading']),
	},
	methods: {
		...mapActions('product/list', ['getList', 'setLoadParams']),
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
	},
}
</script>

<style lang="scss" scoped>
.table-wrapper {
	height: calc(100% - 215px);
}
</style>
