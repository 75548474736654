<template>
	<div class="sell-info-wrapper">
		<div>
			<h5 class="form-header-title">판매 상품 정보</h5>
			<input-radio
				ref="input-radio"
				title="판매여부"
				:required="true"
				:list="saleRadioList"
				:tab="isStop"
				@changeValue="onChangeSale"
			></input-radio>
			<input-image
				ref="input-image"
				title="이미지"
				:images="images"
				:imageThumbnailIndex="imageThumbnailIndex"
				@changeImages="onChangeImages"
				@changeThumbnailImage="onChangeThumbnailImage"
				@changeDeleteImageIds="onChangeDeleteImageIds"
			/>
			<input-text-editor title="상품 상세설명" :value="detailHtml" placeholder="상품 상세설명을 입력해주세요" @input="onInputTextEditor" />
			<input-custom-text-list
				ref="input-tag"
				title="검색 태그"
				subContentText="물품정보 외에 검색어를 추가하여, 검색결과에 상품을 노출시킬 수 있습니다."
				addButtonTitle="검색어 추가"
				:defaultTextList="tags"
				@change="onChangeTags"
				@changeDeleteTagIds="onChangeDeleteTagIds"
			/>
			<input-text
				ref="input-text"
				title="자체 관리번호"
				placeholder="관리번호"
				subContentText="자체적으로 관리하는 번호가 있다면 입력해주세요."
				:inputValue="mngNo"
				@changeValue="onChangeMngNo"
			/>
		</div>
		<div style="margin-top: 25px">
			<h5 class="form-header-title">판매가 및 재고</h5>
			<div class="form-header-sub-title">옵션(SKU)별로 옵션 분류, 재고 수량, 판매가를 설정할 수 있습니다.</div>
			<input-custom-text-list
				ref="option-sku-items"
				title="옵션 분류"
				addButtonTitle="옵션 분류 추가"
				:defaultTextList="optionNames"
				:maxCount="5"
				border="none"
				background="#F2F3F4"
				@change="onChangeOptionList"
				@overCount="onOverCountOption"
			/>
			<sell-info-option-table ref="optionTable" title="옵션" @changeTableData="onChangeTableData" />
		</div>

		<modal-common ref="modal-common" :hideCancelBtn="true" @isHide="$refs['modal-common'].hide()"></modal-common>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import mixin from '@/mixins'
import BaseItem from '@/views/common/components/BaseItem.vue'
import InputImage from '@/views/common/components/formItem/InputImage.vue'
import InputTextEditor from '@/views/common/components/formItem/InputTextEditor.vue'
import InputCustomTextList from '@/views/common/components/formItem/InputCustomTextList.vue'
import InputText from '@/views/common/components/formItem/InputText.vue'
import InputRadio from '@/views/common/components/formItem/InputRadio.vue'
import SellInfoOptionTable from '@/views/product/registration/components/sellInfo/SellInfoOptionTable.vue'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'

export default {
	name: 'SellInfo',
	components: {
		BaseItem,
		InputImage,
		InputTextEditor,
		InputCustomTextList,
		InputText,
		InputRadio,
		SellInfoOptionTable,
		ModalCommon,
	},
	mixins: [mixin],
	data() {
		return {
			saleRadioList: [
				{ id: 0, title: '판매' },
				{ id: 1, title: '판매중지' },
			],
		}
	},
	computed: {
		...mapState('productRegist/regist', ['sellInfo']),
		isStop() {
			return this.sellInfo?.isStop ? (this.sellInfo.isStop ? 1 : 0) : 0
		},
		images() {
			return this.$_.cloneDeep(this.sellInfo?.images)
		},
		imageThumbnailIndex() {
			return this.$_.cloneDeep(this.sellInfo?.imageThumbnailIndex)
		},
		detailHtml() {
			return this.$_.cloneDeep(this.sellInfo?.detailHtml)
		},
		tags() {
			return this.$_.cloneDeep(this.sellInfo?.tags)
		},
		mngNo() {
			return this.$_.cloneDeep(this.sellInfo?.mngNo)
		},
		optionNames() {
			return this.$_.cloneDeep(this.sellInfo?.optionNames)
		},
	},
	mounted() {
		if (!this.$_.isEmpty(this.sellInfo)) this.bindSellInfo()
		this.onChangeSale({ id: this.isStop })
	},
	methods: {
		...mapMutations('productRegist/regist', ['setSellInfo']),
		bindSellInfo() {
			if (this.sellInfo.optionNames?.length) {
				this.$refs['option-sku-items'].setItems(
					this.sellInfo.optionNames.map((option, index) => {
						return { value: option.value, complete: true, disabled: index === this.sellInfo.optionNames.length - 1 }
					})
				)
			}
			if (this.sellInfo.products?.length && this.$refs['optionTable']) {
				this.$refs['optionTable'].setTableData(
					this.sellInfo.products.map(product => {
						let value = {
							unitPrice: this.commaNum(product?.unitPrice),
							salesUnitPrice: this.commaNum(product?.salesUnitPrice),
							sku: product?.sku,
							stock: product?.stock,
							management: '',
							productId: product?.productId,
						}
						if (product.options && product.options.length) {
							product.options.forEach((option, index) => {
								value[`${this.sellInfo.optionNames[index].value}`] = option
							})
						}
						return value
					})
				)
			}
		},
		onChangeSale(tab) {
			this.setInfo('isStop', !!tab.id)
		},
		onChangeImages(images) {
			this.setInfo('images', images)
		},
		onChangeThumbnailImage(index) {
			this.setInfo('imageThumbnailIndex', index)
		},
		onChangeDeleteImageIds(deleteImageIds) {
			this.setInfo('deleteImageIds', deleteImageIds)
		},
		onInputTextEditor(html) {
			this.setInfo('detailHtml', html)
		},
		onChangeTags(tags) {
			this.setInfo('tags', this.$_.cloneDeep(tags))
		},
		onChangeDeleteTagIds(tagIds) {
			this.setInfo('deleteTagIds', tagIds)
		},
		onChangeMngNo(value) {
			this.setInfo('mngNo', value)
		},
		onChangeOptionList(optionList) {
			const list = this.$_.cloneDeep(optionList).reverse()
			this.setInfo('optionNames', list)
			this.$refs['optionTable'].setColumns(list)
		},
		onChangeTableData(tableDataList) {
			const optionNames = this.sellInfo?.optionNames?.map(({ value }) => value)
			this.setInfo(
				'products',
				this.$_.cloneDeep(tableDataList).map(tableData => {
					delete tableData.management
					tableData.options = []
					Object.keys(tableData).forEach(key => {
						if (key.includes('Price') && tableData[key]) {
							tableData[key] = parseInt(tableData[key].toString().replace(/,/g, ''))
						}
						const findIndex = optionNames.indexOf(key)
						if (findIndex !== -1) {
							tableData.options[findIndex] = tableData[key] === '' ? null : tableData[key]
							delete tableData[key]
						}
					})
					if (!tableData.options.length) delete tableData.options
					return tableData
				})
			)
		},
		onOverCountOption() {
			this.$refs['modal-common'].show('옵션 분류 추가 불가', '옵션 분류는 최대 5개까지만 입력 가능합니다.', 'white', '닫기')
		},
		setInfo(key, value) {
			const sellInfo = this.$_.cloneDeep(this.sellInfo)
			sellInfo[key] = value
			this.setSellInfo(sellInfo)
		},
		valid() {
			return this.$refs['optionTable'].valid()
		},
	},
}
</script>

<style lang="scss" scoped>
.sell-info-wrapper {
	width: 100%;
	position: relative;
	padding-bottom: 20px;
	.form-header-title {
		text-align: left;
		font-weight: 700;
		padding-top: 25px;
	}
	.form-header-sub-title {
		text-align: left;
		font-size: 14px;
		padding: 10px 0 25px 0;
	}
}
</style>
