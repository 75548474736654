const index = {
	namespaced: true,
	state: () => ({
		showPaginationOption: false,
	}),
	mutations: {
		togglePaginationOption(state, payload) {
			state.showPaginationOption = typeof payload !== 'undefined' ? payload : !state.showPaginationOption
		},
	},
	actions: {
		togglePaginationOption({ commit }, option) {
			commit('togglePaginationOption', option)
		},
	},
}

export default index
