import throttle from 'lodash/throttle'

export default {
	data() {
		return {
			scrollDom: window,
			top: 0,
			bottom: 0,
			lastScrollTop: 0,
			scrollDirection: null,
			isBottom: false,
		}
	},
	computed: {
		isHeaderSticky() {
			return this.scrollDirection == 'UP' && this.isSearchHide
		},
		isScrollBottom() {
			return this.scrollDirection == 'DOWN' && this.lastScrollTop > 92
		},
	},
	methods: {
		onScroll: throttle(function ({ target: { scrollTop, clientHeight, scrollHeight } }) {
			if (scrollTop > this.lastScrollTop) {
				this.scrollDirection = 'DOWN'
			} else {
				this.scrollDirection = 'UP'
			}
			this.isBottom = scrollHeight - clientHeight - 100 < scrollTop
			this.lastScrollTop = scrollTop
		}, 100),
		moveScrollTop() {
			this.$refs['scroll-container'].scrollTo(0, 0)
		},
		goScroll(scrollTop) {
			this.$refs['scroll-container'].scrollTop = scrollTop - 350
		},
		moveBeforeScroll() {
			this.goScroll(this.lastScrollTop + 350)
		},
	},
}
