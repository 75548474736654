import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `/admin/notice/list`,
	lookupNotice: () => `/admin/notice`,
	createNotice: () => `/admin/notice`,
	updateNotice: () => `/admin/notice`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	lookupNotice: params => axiosInstance.get(endPoint.lookupNotice(), { params }),
	createNotice: body => axiosInstance.post(endPoint.createNotice(), body),
	updateNotice: body => axiosInstance.patch(endPoint.updateNotice(), body),
}

export default api
