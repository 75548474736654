import { TableField } from '@/utils/define/table/JwTable'
import { formatDatetime, _2H2MFormat } from '@/utils/modules/DateUtils'
import IconDelete from '@/assets/svg/common/icon-trash.svg'
import IconEdit from '@/assets/svg/common/icon-edit.svg'

const addressManagementTable = {
	width: '100%',
	height: '100%',
	options: {
		selectColumnKey: TableField.AddressName,
		isCheckbox: true,
		table: {
			style: {},
		},
		header: {
			style: {
				height: '60px;',
				color: '#666666',
				fontSize: '14px',
				backgroundColor: '#FFFFFF',
			},
		},
		row: {
			style: {
				height: '60px',
			},
		},
		columns: [
			{
				key: TableField.AddressName,
				balloon: true,
				bold: true,
				name: '주소 이름',
				style: {
					width: '300px',
				},
				classes: [],
			},
			{
				key: TableField.Address,
				name: '주소',
				style: {
					width: '600px',
				},
				classes: [],
			},
			{
				key: TableField.AddDatetime,
				name: '추가일시',
				style: {
					width: '200px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
			{
				key: TableField.EditDatetime,
				name: '수정일시',
				style: {
					width: '200px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
			{
				key: TableField.Management,
				name: '관리',
				type: 'doubleImgTextButton',
				style: {
					width: '150px',
				},
				classes: [],
				firstImgHandler: () => {
					return IconEdit
				},
				secondImgHandler: () => {
					return IconDelete
				},
				firstClickFn: () => {},
				secondClickFn: () => {},
			},
		],
		tableData: {
			AddressName: [],
			Address: [],
			AddDatetime: [],
			EditDatetime: [],
			Management: [],
		},
	},
}

export { addressManagementTable }
