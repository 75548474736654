<template>
	<div class="detail-wrapper" v-if="item && item.id == id">
		<div class="common-header-wrapper">
			<div>
				<h2>{{ item.title }}</h2>
				<h6>
					<span :class="['status', item.status.code === 'ING' ? 'SHOWING' : '']"> {{ item.status.value }} </span>
					<p>
						번호: {{ item.id }}&nbsp;&nbsp; 등록일시: {{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }} &nbsp;&nbsp;
						등록자: {{ item.user?.name }}
					</p>
				</h6>
			</div>
			<div class="button-groups">
				<base-button size="md" @click="$bvModal.show('modal-submit')">삭제</base-button>
				<base-button size="md" @click="onClickUpdate">수정</base-button>
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="배너 설정" :list="bannerInfo" />
			<div class="banner-images">
				<banner-image title="[WEB] 홈 배너 이미지" :width="3200" :height="800" :image="item.banner.webMainUrl" />
			</div>
			<div class="banner-images">
				<banner-image title="[WEB] 이벤트 목록 배너 이미지" :width="3200" :height="400" :image="item.imageUrl" />
			</div>
			<div class="banner-images">
				<banner-image title="[MOBILE] 홈 배너 이미지" :width="750" :height="520" :image="item.banner.mobileMainUrl" />
				<banner-image title="[MOIBLE] 목록 배너 이미지" :width="750" :height="200" :image="item.banner.mobileSearchUrl" />
			</div>
			<div class="banner-images">
				<banner-image
					v-for="(detail, index) in item.details"
					:key="`detail_${index}`"
					style="margin-top: 20px"
					:title="'[Web/MOBILE] 상세 이미지' + (index + 1)"
					:width="3200"
					:height="800"
					:image="detail.imageUrl"
					:link="detail.link"
				/>
			</div>
		</div>
		<modal-submit
			title="이벤트 페이지 삭제"
			content="이벤트 페이지를 삭제하시겠습니까?<br>삭제한 이벤트 페이지는 되돌릴 수 없습니다."
			btnText="삭제"
			@ok="confirmDelete"
		/>
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { mapActions, mapState } from 'vuex'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import MixinBanner from '@/mixins/banner'
import BannerImage from '../../components/BannerImage.vue'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import PageName from '@/utils/define/PageName'

export default {
	components: {
		BaseButton,
		InfoList,
		BannerImage,
		ModalSubmit,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	mixins: [MixinBanner],
	computed: {
		...mapState('lms/banner', ['item']),
		bannerInfo() {
			return [
				{ title: '노출 타겟', value: this.item?.target?.value },
				{ title: '노출 시작일시', value: this.$DateTime.fromISO(this.item.startDatetime).toFormat('yyyy.MM.dd T') },
				{ title: '노출 종료일시', value: this.$DateTime.fromISO(this.item.endDatetime).toFormat('yyyy.MM.dd T'), isSingleRow: true },
			]
		},
	},
	async created() {
		await this.getDetail({ eventId: this.id })
	},
	methods: {
		...mapActions('lms/banner', ['getDetail', 'deleteEvent']),
		async confirmDelete() {
			try {
				await this.deleteEvent({ eventId: this.id })
				this.$root.toast('삭제 완료', '이벤트 페이지가 삭제되었습니다.', 'success')
				this.$router.replace({
					name: PageName.Lms.Banner,
				})
			} catch (e) {
				this.$root.toast('삭제 실패', e.response.data.msg, 'error')
			}
		},
		onClickUpdate() {
			this.$router.push({
				name: PageName.Lms.BannerUpdate,
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.banner-images {
	margin-bottom: 20px;
	& > *:first-child {
		margin-right: 20px;
	}
	& > * {
		vertical-align: text-top;
	}
}
</style>
