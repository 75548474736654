import { TableField } from '@/utils/define/table/JwTable'
import { formatDatetime, _2H2MFormat } from '@/utils/modules/DateUtils'

const requestProductTable = {
	width: '100%',
	height: '100%',
	options: {
		selectColumnKey: TableField.Status,
		isCheckbox: false,
		table: {
			style: {},
		},
		header: {
			style: {
				height: '60px;',
				color: '#666666',
				fontSize: '14px',
				backgroundColor: '#FFFFFF',
			},
		},
		row: {
			style: {
				height: '60px',
			},
			click: {
				rowDataName: TableField.Status,
				keyName: 'id',
				clickEvent: () => {},
			},
		},
		columns: [
			{
				key: TableField.Status,
				name: '상태',
				type: 'statusIndicator',
				style: {
					width: '100px',
				},
				statusHandler: data => {
					return {
						진행중: {
							class: ['gray'],
						},
						완료: {
							class: ['green'],
						},
					}[data.text]
				},
				classes: [],
			},
			{
				key: TableField.RequestNo,
				name: '요청번호',
				style: {
					width: '150px',
				},
				classes: [],
			},
			{
				key: TableField.RequestProduct,
				name: '요청 상품',
				type: 'item',
				style: {
					width: '250px',
				},
				classes: [],
			},
			{
				key: TableField.Category,
				name: '카테고리',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Brand,
				name: '브랜드',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.ProductNo,
				name: '제품번호',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Casno,
				name: 'CAS No',
				style: {
					width: '100px',
				},
				classes: [],
			},
			{
				key: TableField.Volume,
				name: '용량/패키지 수량',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.RequestPrice,
				name: '요청 가격',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.RequestTerm,
				name: '요청사항',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.Requester,
				name: '요청자',
				type: 'profile',
				style: {
					width: '200px',
				},
				classes: [],
			},
			{
				key: TableField.RequestDatetime,
				name: '요청일시',
				style: {
					width: '150px',
				},
				classes: [],
				dataHandler: value => {
					return formatDatetime(value, _2H2MFormat)
				},
			},
		],
		tableData: {
			Status: [],
			RequestNo: [],
			RequestProduct: [],
			Category: [],
			Brand: [],
			ProductNo: [],
			Casno: [],
			Volume: [],
			RequestPrice: [],
			RequestTerm: [],
			Requester: [],
			RequestDatetime: [],
		},
	},
}

export { requestProductTable }
