import chemicalApi from '@/services/api/chemicalApi'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		searchProduct: async ({ state, commit }, params) => {
			const response = await chemicalApi.searchProduct(params)
			return response.data
		},
		searchMaker: async ({ state, commit }, params) => {
			const response = await chemicalApi.searchMaker(params)
			return response.data
		},
		addMaker: async ({ state, commit }, body) => {
			const response = await chemicalApi.addMaker(body)
			return response.data
		},
		getProductDetail: async ({ dispatch }, id) => {
			return await dispatch('chemicalApi/request/getProductDetail', id, { root: true })
		},
	},
}
