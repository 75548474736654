<template>
	<tr class="table-item" style="cursor: default">
		<td>
			<div>
				<span>{{ item.status }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; cursor: pointer; text-decoration: underline" @click="routeOrderDetailPage">
				<span>{{ item.orderNo }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px; cursor: pointer; text-decoration: underline" @click="routeOrderItemDetailPage">
				<span>{{ item.orderItemNo }}</span>
			</div>
		</td>
		<td>
			<div class="info-wrap">
				<p class="info-title">{{ item.productName }}</p>
				<p class="info-sub">{{ item.productSubname }}</p>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.quantity }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.estimatedReleaseDatetime ?? '-' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.shippingMethod ?? '-' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.product?.shippingDirectContact === 0 ? '불가능' : '가능' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.product?.shippingCanQuick === 0 ? '불가능' : '가능' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.instituteName }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.instituteId }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.ordererName }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.ordererPhone }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 300px">
				<span>{{ item.address }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.deliveryRequest ?? '-' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.totalOrderItemPrice }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.adminMemo ?? '-' }}</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.createDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.approveDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.releaseDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.shippingDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.cancelRequestDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.cancelDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div style="min-width: 180px">
				<span>
					{{ item.confirmDatetime }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.distributorName }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.brandName }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.productSKU }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.productOption }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.salesPrice }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.price }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.orderItemPrice }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.orderItemVat }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.orderItemPriceWithVat }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentStatus }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentStatus === 'PAID' ? '정산완료' : '미정산' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.instituteSettleStatus }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.month }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentMethod }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentWay }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.payAuthCode }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.ntsNum }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentPrice }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.paymentDatetime }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.depositDatetime }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.sellerName }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.sellerMemo ?? '-' }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.platform ?? '-' }}</span>
			</div>
		</td>
	</tr>
</template>

<script>
import PageName from '@/utils/define/PageName'

export default {
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		statusText() {
			if (this.item.returnStatus == 'DONE') return '교환/반품 완료'
			if (this.item.status == 'WAIT') {
				if (this.item.cancelStatus == 'DONE') return '주문취소 완료'
				else return '승인 대기'
			} else if (this.item.status == 'APPROVE') return '승인 완료'
			else if (this.item.status == 'SHIPPING_ING') return '배송중'
			else if (this.item.status == 'SHIPPING_COMPLETE') return '배송완료'
			else if (this.item.status == 'CONFIRM') return '구매확정'
		},
		options() {
			let result = ''
			this.item.product?.options.forEach(option => {
				result += option.name + ': '
				result += option.value
			})
			return result
		},
		paymentStatus() {
			return {
				WAIT: '미결제',
				PAID: '결제',
				CANCEL: '취소',
			}[this.item.paymentStatus]
		},
	},
	methods: {
		routeOrderDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderProductManagementDetail,
				params: {
					id: this.item.orderId,
				},
			})
		},
		routeOrderItemDetailPage() {
			this.$router.push({
				name: PageName.Order.OrderItemDetail,
				params: {
					id: this.item.orderItemId,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/table/_table-item.scss';
.table-item {
	td {
		height: 80px;
	}
}
</style>
