<template>
	<div class="product-external-item-wrapper" @click="$emit('clickItem', item)">
		<div class="image-wrapper">
			<img :src="item.src ? item.src : require('@/assets/svg/common/no-image.svg')" alt="" />
		</div>
		<div class="text-wrapper">
			<div class="name">{{ item.name }}</div>
			<div class="sub-name">{{ item.subname }}</div>
			<div class="info">{{ item.info }}</div>
			<div class="detail-info">
				<span @click.stop="showModalDetail">
					상세 정보
					<img src="@/assets/svg/app/arrow-up-s.svg" alt="" />
				</span>
			</div>
		</div>

		<modal-search-product-external-detail ref="modal-search-product-external-detail"></modal-search-product-external-detail>
	</div>
</template>

<script>
import ModalSearchProductExternalDetail from '@/views/product/registration/modals/ModalSearchProductExternalDetail.vue'

export default {
	name: 'ProductExternalItem',
	components: {
		ModalSearchProductExternalDetail,
	},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		showModalDetail() {
			this.$refs['modal-search-product-external-detail'].showDetailModal(this.item)
		},
	},
}
</script>

<style lang="scss" scoped>
.product-external-item-wrapper {
	width: 100%;
	min-height: 125px;
	display: flex;
	border-bottom: 1px solid $LINE_DIVIDER;

	&:hover {
		background-color: $LIST_BACKGROUND;
	}

	.image-wrapper {
		width: 120px;
		height: 100%;
		padding: 20px;

		img {
			width: 80px;
			height: 80px;
			border: 1px solid $LINE_DIVIDER;
		}
	}

	.text-wrapper {
		width: calc(100% - 120px);
		height: 100%;
		padding: 20px 20px 20px 0;

		.name {
			min-height: 23px;
			font-size: 1rem;
			font-weight: 700;
		}
		.sub-name,
		.info {
			min-height: 17px;
			font-size: 0.75rem;
			color: $COLOR_666;
		}
		.detail-info {
			height: 17px;
			font-size: 0.75rem;
			color: $COLOR_666;
			margin-top: 7.5px;
			display: flex;
			align-items: center;

			span:hover {
				cursor: pointer;
			}
			img {
				width: 15px;
				height: 10px;
			}
		}
	}
}
</style>
