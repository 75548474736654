<template>
	<div class="search-wrap">
		<div class="search-box" ref="search-box">
			<input ref="search-input" type="text" :value="inputValue" @input="changeInput" @keyup.enter="search" :placeholder="keywordPlaceholder" />
			<button @click="search" class="search-btn"></button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SearchBox',
	props: {
		keywordPlaceholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			inputValue: '',
		}
	},
	methods: {
		search() {
			this.$emit('search', this.inputValue)
		},
		changeInput(event) {
			this.inputValue = event.target.value
		},
		reset() {
			this.$refs['search-input'].value = ''
		},
	},
}
</script>

<style lang="scss" scoped>
.search-wrap {
	.search-box {
		width: 350px;
		border-radius: 4px;
		border: 1px solid $INPUT_BORDER;
		display: flex;
		align-items: center;
		overflow: hidden;
		&:focus-within {
			border: 1px solid black;
		}
		input {
			border: 0;
			height: 28px;
			width: calc(100% - 30px);
			padding-left: 10px;
		}
		button {
			width: 30px;
			height: 28px;
			background-color: $COMMON_BACKGROUND;
			background-image: url(~@/assets/svg/search/search.svg);
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}
</style>
