<template>
	<div class="lab-list">
		<list-header @changeLoadParams="setLoadParams" />
		<div class="table-wrapper">
			<lab-table :list="list" class="table" :checkList="checkList" @check="changeCheckList" />
			<floating-menu :count="checkList.length" :list="btnList" @clickEv="showModal" />
		</div>
		<pagination :total="count" @page="pagination" :defaultOffset="50" />

		<modal-common ref="modal-common" @cancel="$refs['modal-common'].hide()" @complete="prohibition" />
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import ListHeader from '../components/ListHeader.vue'
import Pagination from '@/views/common/components/CommonPagenation.vue'
import LabTable from '../components/Table.vue'
import FloatingMenu from '@/components/table/FloatingMenu'
import ModalCommon from '@/views/common/modals/ModalCommon.vue'

export default {
	name: 'LabList',
	components: {
		ListHeader,
		LabTable,
		Pagination,
		FloatingMenu,
		ModalCommon,
	},
	data() {
		return {
			checkList: [],
		}
	},
	computed: {
		...mapState('lab/list', ['list', 'count']),
		btnList() {
			return [
				{
					text: '구매 금지',
					key: 'approval',
					image: require('@/assets/svg/common/icon-reject.svg'),
					tooltip: '연구실을 선택해주세요',
					disabled: this.checkList.length <= 0,
				},
			]
		},
	},
	created() {
		this.initLoadParams()
	},
	mounted() {
		this.getList()
	},
	methods: {
		...mapMutations('lab/list', ['initLoadParams']),
		...mapActions('lab/list', ['getList', 'setLoadParams']),
		...mapActions('lab/detail', ['buyLabBlock']),
		changeCheckList(checked, id) {
			if (checked && this.checkList.indexOf(id) < 0) {
				this.checkList.push(id)
			} else if (!checked) {
				this.checkList = this.checkList.filter(item => item != id)
			}
		},
		pagination(page) {
			this.setLoadParams(page)
			document.querySelector('.table').scrollTo(0, 0)
		},
		showModal() {
			this.$refs['modal-common'].show(
				'구매 금지',
				`${this.checkList.length}개 연구실을 구매 금지 하시겠습니까?<br />
				해당 연구실은 구매 금지한 시점부터 더 이상 구매를 할 수 없습니다.`,
				'complete',
				'확인'
			)
		},
		async prohibition() {
			try {
				this.$refs['modal-common'].hide()
				await this.buyLabBlock({ ids: this.checkList, isBlock: true })
				this.checkList = []
				this.getList()
				this.$root.toast('구매 금지 완료', '연구실을 구매 금지 처리했습니다.', ToastType.SUCCESS)
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.lab-list {
	.table-wrapper {
		height: calc(100% - 161px);
	}
}
</style>
