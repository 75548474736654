<template>
	<search-row class="search-row" :title="title">
		<search-box ref="searchBox" :keywordPlaceholder="keywordPlaceholder" @search="$emit('search', $event)" />
	</search-row>
</template>

<script>
import SearchRow from '@/views/common/components/search/SearchRow.vue'
import SearchBox from '@/views/common/components/search/FilterSearchBox.vue'

export default {
	name: 'SearchKeyword',
	components: {
		SearchRow,
		SearchBox,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		keywordPlaceholder: {
			type: String,
			default: '',
		},
	},
	methods: {
		reset() {
			this.$refs.searchBox.reset()
		},
	},
}
</script>

<style lang="scss" scoped></style>
