<template>
	<b-modal ref="modal" centered no-close-on-backdrop no-fade @close="cancel">
		<template #modal-title>
			<div class="title">{{ title }}</div>
		</template>
		<template>
			<div class="content" v-html="content" v-if="noContent"></div>
			<slot></slot>
		</template>
		<template #modal-footer>
			<div class="modal-footer-wrap">
				<div class="footer-left"></div>
				<div class="footer-right">
					<button class="button-white" v-if="noCancel" @click="cancel">
						{{ cancelText }}
					</button>
					<button :class="`button-${variant}`" v-if="noConfirm" @click="confirm">
						{{ confirmText }}
					</button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
export default {
	name: 'ModalConfirm',
	components: {},
	props: {
		title: {
			type: String,
		},
		content: {
			type: String,
		},
		noCancel: {
			type: Boolean,
			default: true,
		},
		noConfirm: {
			type: Boolean,
			default: true,
		},
		noContent: {
			type: Boolean,
			default: true,
		},
		confirmText: {
			type: String,
			default: '확인',
		},
		cancelText: {
			type: String,
			default: '취소',
		},
		variant: {
			type: String,
			default: 'primary',
		},
	},
	data() {
		return {
			confirmFn: null,
			cancelFn: null,
		}
	},
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		show(confirmFn = null, cancelFn = null) {
			if (confirmFn) this.confirmFn = confirmFn
			if (cancelFn) this.cancelFn = cancelFn
			this.$refs['modal'].show()
		},
		hide() {
			this.$refs['modal'].hide()
		},
		cancel() {
			this.$emit('cancel')
			if (this.cancelFn) this.cancelFn()
			this.hide()
		},
		confirm() {
			this.$emit('confirm')
			if (this.confirmFn) this.confirmFn()
			this.hide()
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@/styles/scss/modal/_modal-confirm.scss';
</style>
