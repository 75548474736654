import RoutePath from '@/utils/define/RoutePath'
import PageName from '@/utils/define/PageName'

export default [
	{
		path: RoutePath.Seller.SellerList,
		name: PageName.Seller.SellerList,
		component: () => import('@/views/seller/sellers/pages/SellerList.vue'),
	},
	{
		path: RoutePath.Seller.SellerDetail,
		name: PageName.Seller.SellerDetail,
		component: () => import('@/views/seller/sellers/pages/SellerDetail.vue'),
	},
	{
		path: RoutePath.Seller.SettlementManagement,
		name: PageName.Seller.SettlementManagement,
		component: () => import('@/views/seller/settlement/pages/SettlementManagement.vue'),
	},
	{
		path: RoutePath.Seller.SettlementDetail,
		name: PageName.Seller.SettlementDetail,
		component: () => import('@/views/seller/settlement/pages/SettlementDetail.vue'),
	},
	// {
	// 	path: RoutePath.seller.sellerInfo,
	// 	name: PageName.Seller.SellerInfo,
	// 	component: () => import('@/views/seller/info/pages/SellerInfo.vue'),
	// },
	// {
	// 	path: RoutePath.seller.sellerInfoEdit,
	// 	name: PageName.Seller.SellerInfoEdit,
	// 	component: () => import('@/views/seller/info/pages/SellerInfoEdit.vue'),
	// },
	// {
	// 	path: RoutePath.seller.addressManagement,
	// 	name: PageName.Seller.AddressManagement,
	// 	component: () => import('@/views/seller/address/pages/AddressManagement.vue'),
	// },
	// {
	// 	path: RoutePath.seller.customerConsultingTemplateManagement,
	// 	name: PageName.Seller.CustomerConsultingTemplateManagement,
	// 	component: () => import('@/views/seller/template/pages/CustomerConsultingTemplateManagement.vue'),
	// },
	{
		path: RoutePath.Seller.SellerLogin,
		name: PageName.Seller.SellerLogin,
		component: () => import('@/views/seller/login/pages/SellerLogin.vue'),
	},
	{
		path: RoutePath.Seller.SellerEntryRegistration,
		name: PageName.Seller.SellerEntryRegistration,
		component: () => import('@/views/seller/entry/registration/pages/SellerEntryRegistration.vue'),
	},
]
