import axiosInstance from '@/services/axios'

const endPoint = {
	lookupInquiryList: () => `/admin/inquiry/list`,
	lookupInquiryFilter: () => `/admin/inquiry/list/filter`,
	lookupInquiryDetail: id => `/admin/inquiry?id=${id}`,
	setReply: id => `/admin/inquiry/comment`,
}

const api = {
	lookupInquiryList: params => axiosInstance.get(endPoint.lookupInquiryList(), { params }),
	lookupInquiryFilter: () => axiosInstance.get(endPoint.lookupInquiryFilter()),
	lookupInquiryDetail: id => axiosInstance.get(endPoint.lookupInquiryDetail(id)),
	setReply: body => axiosInstance.post(endPoint.setReply(), body),
}

export default api
