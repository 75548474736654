import { render, staticRenderFns } from "./ItemMsds.vue?vue&type=template&id=68d6d4cc&scoped=true&"
import script from "./ItemMsds.vue?vue&type=script&lang=js&"
export * from "./ItemMsds.vue?vue&type=script&lang=js&"
import style0 from "./ItemMsds.vue?vue&type=style&index=0&id=68d6d4cc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d6d4cc",
  null
  
)

export default component.exports