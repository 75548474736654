import { requestProductTable } from '@/utils/define/table/requestProductTable'
import { orderManagementTable } from '@/utils/define/table/orderManagementTable'
import { addressManagementTable } from '@/utils/define/table/addressManagementTable'
import { customerConsultingTemplateTable } from '@/utils/define/table/customerConsultingTemplateTable'
import { productManagementTable } from '@/utils/define/table/productManagementTable'

import { TableField } from '@/utils/define/table/JwTable'

const refineProductManagementListTableData = (state, type) => {
	const { count, list } = state[type]
	const tableData = {}
	productManagementTable.options.columns.forEach(item => {
		tableData[item.key] = []
	})
	const isEmpty = list.length === 0
	if (isEmpty) {
		return {
			count: 0,
			tableData: tableData,
		}
	}
	for (let i = 0, length = list.length; i < length; i++) {
		const row = list[i]

		let categoryFullPathName = row.category.korName
		if (row.category.parents.length) categoryFullPathName = `${row.category.parents[0].korName}/${categoryFullPathName}`

		tableData[TableField.Status].push({
			data: {
				id: row,
				text: row.isStop ? '판매중지' : '판매중',
				isShowCaution: row.isStop && row.stopSource === 'ADMIN',
			},
		})
		tableData[TableField.SellProductNo].push({
			data: {
				value: row.productInfoId,
			},
		})
		tableData[TableField.ManagementNo].push({
			data: {
				value: row.mngNo,
			},
		})
		tableData[TableField.Info].push({
			data: {
				subtext: row.name,
				text: row.subname,
			},
		})
		tableData[TableField.Option].push({
			data: {
				value: row.productCount,
			},
		})
		tableData[TableField.TotalQuantity].push({
			data: {
				value: row.totalStock,
			},
		})
		tableData[TableField.Category].push({
			data: {
				value: categoryFullPathName,
			},
		})
		tableData[TableField.Brand].push({
			data: {
				value: row.brand.name,
			},
		})
		tableData[TableField.ProductNo].push({
			data: {
				value: row.code,
			},
		})
		tableData[TableField.Casno].push({
			data: {
				value: row.casno,
			},
		})
		tableData[TableField.ReleaseLocation].push({
			data: {
				value: row.shippingAddress.address,
			},
		})
		tableData[TableField.ProductRegistDatetime].push({
			data: {
				value: row.createDatetime,
			},
		})
		tableData[TableField.LastEditDatetime].push({
			data: {
				value: row.updateDatetime,
			},
		})
	}
	return {
		count: count,
		tableData: tableData,
	}
}
const refineRequestProductListTableData = (state, type) => {
	const { count, list } = state[type]
	const tableData = {}
	requestProductTable.options.columns.forEach(item => {
		tableData[item.key] = []
	})
	const isEmpty = list.length === 0
	if (isEmpty)
		return {
			count: 0,
			tableData: tableData,
		}

	for (let i = 0, length = list.length; i < length; i++) {
		const row = list[i]
		tableData[TableField.Status].push({
			data: {
				id: row,
				text: setRequestStatusText(row.status),
			},
		})
		tableData[TableField.RequestNo].push({
			data: {
				value: row.no,
			},
		})
		tableData[TableField.RequestProduct].push({
			data: {
				text: row.name,
				subtext: row.subname,
			},
		})
		tableData[TableField.Category].push({
			data: {
				value: setCategory(row.categoryType),
			},
		})
		tableData[TableField.Brand].push({
			data: {
				value: row.brandName,
			},
		})
		tableData[TableField.ProductNo].push({
			data: {
				value: row.productCode,
			},
		})
		tableData[TableField.Casno].push({
			data: {
				value: row.casno,
			},
		})
		tableData[TableField.Volume].push({
			data: {
				value: row.volume ? row.volume + row.volumeUnit : '',
			},
		})
		tableData[TableField.RequestPrice].push({
			data: {
				value: row.price,
			},
		})
		tableData[TableField.RequestTerm].push({
			data: {
				value: row.comment,
			},
		})
		tableData[TableField.Requester].push({
			data: {
				text: row.user ? row.user.name : '',
				img: row.user ? row.user.image : null,
			},
		})
		tableData[TableField.RequestDatetime].push({
			data: {
				value: row.createDatetime,
			},
		})
	}
	return {
		count: count,
		tableData: tableData,
	}
}

const refineAddressListTableData = (state, type) => {
	const { list } = state[type]
	const tableData = {}
	addressManagementTable.options.columns.forEach(item => {
		tableData[item.key] = []
	})
	const isEmpty = list.length === 0
	if (isEmpty)
		return {
			tableData: tableData,
		}

	for (let i = 0, length = list.length; i < length; i++) {
		const row = list[i]
		tableData[TableField.AddressName].push({
			data: {
				id: row,
				value: `${row.isDefault ? '(기본) ' : ''}${row.name}`,
			},
		})
		tableData[TableField.Address].push({
			data: {
				value: row.address + row.addressDetail,
			},
		})
		tableData[TableField.AddDatetime].push({
			data: {
				value: row.createDatetime,
			},
		})
		tableData[TableField.EditDatetime].push({
			data: {
				value: row.updateDatetime,
			},
		})
		tableData[TableField.Management].push({
			data: {
				row: row,
				firstText: '수정',
				secondText: '삭제',
				isDefault: row.isDefault,
				balloonText: '기본 주소 삭제 불가',
			},
		})
	}
	return {
		tableData: tableData,
	}
}

const refineTemplateListTableData = (state, type) => {
	const { list } = state[type]
	const tableData = {}
	customerConsultingTemplateTable.options.columns.forEach(item => {
		tableData[item.key] = []
	})
	const isEmpty = list.length === 0
	if (isEmpty)
		return {
			tableData: tableData,
		}

	for (let i = 0, length = list.length; i < length; i++) {
		const row = list[i]
		tableData[TableField.TemplateName].push({
			data: {
				id: row,
				value: `${row.isDefault ? '(기본) ' : ''}${row.name}`,
			},
		})
		tableData[TableField.CustomerConsultingContact].push({
			data: {
				value: row.tel,
			},
		})
		tableData[TableField.ConsultingInfo].push({
			data: {
				value: row.content,
			},
		})
		tableData[TableField.TemplateCreateDatetime].push({
			data: {
				value: row.createDatetime,
			},
		})
		tableData[TableField.TemplateEdidDatetime].push({
			data: {
				value: row.updateDatetime,
			},
		})
		tableData[TableField.Management].push({
			data: {
				row: row,
				firstText: '수정',
				secondText: '삭제',
				isDefault: row.isDefault,
				balloonText: '기본 템플릿 삭제 불가',
			},
		})
	}
	return {
		tableData: tableData,
	}
}

const refineOrderListTableData = (state, type) => {
	const { count, list } = state[type]
	const tableData = {}
	orderManagementTable.options.columns.forEach(item => {
		tableData[item.key] = []
	})
	const isEmpty = list.length === 0
	if (isEmpty)
		return {
			count: 0,
			tableData: tableData,
		}
	for (let i = 0, length = list.length; i < length; i++) {
		const row = list[i]
		tableData[TableField.Status].push({
			data: {
				id: row,
				...getStatusText(row),
			},
		})
		tableData[TableField.OrderNo].push({
			data: {
				value: row.orderNo,
				id: row.orderId,
			},
		})
		tableData[TableField.OrderItemNo].push({
			data: {
				value: row.orderItemNo,
				id: row.orderItemId,
			},
		})
		tableData[TableField.Info].push({
			data: {
				text: row.product.name,
				subtext: row.product.subname,
			},
		})
		tableData[TableField.ReleaseVolume].push({
			data: {
				buttonText: '주문수정',
				subtext: row.quantity,
				row: row,
				isButtonDisabled: getButtonStatusVolume(row),
			},
		})
		tableData[TableField.EstimatedReleaseDate].push({
			data: {
				value: row.estimatedReleaseDatetime,
				row: row,
				isDisabled: getButtonStatusVolume(row),
			},
		})
		tableData[TableField.DeliveryMethod].push({
			data: {
				value: row.shippingMethod,
				row: row,
				isDisabled: getStatusDeliveryChange(row),
			},
		})
		tableData[TableField.IndividualContact].push({
			data: {
				value: row.product.shippingDirectContact === 1 ? '개별연락 필요' : '',
			},
		})
		tableData[TableField.QuickService].push({
			data: {
				value: row.product.shippingCanQuick === 1 ? '퀵서비스 가능' : '',
			},
		})
		tableData[TableField.OrderLab].push({
			data: {
				value: row.institute.name,
			},
		})
		tableData[TableField.OrderLabId].push({
			data: {
				value: row.institute.id,
			},
		})
		tableData[TableField.Orderer].push({
			data: {
				value: row.orderer.name,
			},
		})
		tableData[TableField.Address].push({
			data: {
				value: row.address,
			},
		})
		tableData[TableField.DeliveryRequest].push({
			data: {
				value: row.deliveryRequest,
			},
		})
		tableData[TableField.HiddenMemo].push({
			data: {
				value: row.adminMemo,
			},
		})
		tableData[TableField.OrderDatatime].push({
			data: {
				value: row.createDatetime,
			},
		})
		tableData[TableField.StockCheckDatetime].push({
			data: {
				value: row.approveDatetime,
			},
		})
		tableData[TableField.DeliveryStartDatetime].push({
			data: {
				value: row.releaseDatetime,
			},
		})
		tableData[TableField.DeliveryCompleteDatetime].push({
			data: {
				value: row.shippingDatetime,
			},
		})
		tableData[TableField.ConfirmOrderDatetime].push({
			data: {
				value: row.confirmDatetime,
			},
		})
		tableData[TableField.Brand].push({
			data: {
				value: row.product.brand.name,
			},
		})
		tableData[TableField.ProductNo].push({
			data: {
				value: row.product.sku,
			},
		})
		tableData[TableField.Sku].push({
			data: {
				value: row.product.sku,
			},
		})
		tableData[TableField.Option].push({
			data: {
				value: getOptions(row.product.options),
			},
		})
		tableData[TableField.PurchaseUnitPrice].push({
			data: {
				value: convertPrice(row.salesUnitPrice),
			},
		})
		tableData[TableField.ProductPrice].push({
			data: {
				value: convertPrice(row.originUnitPrice),
			},
		})
		tableData[TableField.OrderPrice].push({
			data: {
				buttonText: '변경',
				subtext: convertPrice(row.orderItemPrice),
				row: row,
				isReverse: true,
				isButtonDisabled: getButtonStatus(row),
			},
		})
		tableData[TableField.OrderPriceVat].push({
			data: {
				value: convertPrice(row.orderItemPriceWithVat),
			},
		})
		tableData[TableField.PaymentNo].push({
			data: {
				value: row.paymentNo,
			},
		})
		tableData[TableField.PaymentStatus].push({
			data: {
				value: getPaymentStatusText(row.paymentStatus),
			},
		})
		tableData[TableField.LabSettlementStatus].push({
			data: {
				value: row.instituteSettleStatus,
			},
		})
		tableData[TableField.SellerSettlementStatus].push({
			data: {
				value: row.sellerSettleStatus,
			},
		})
		tableData[TableField.SettlementMonth].push({
			data: {
				value: row.settleMonth,
			},
		})
		tableData[TableField.PaymentMethod].push({
			data: {
				value: row.paymentMethod,
			},
		})
		tableData[TableField.ApproveDatetime].push({
			data: {
				value: row.approveDatetime,
			},
		})
		tableData[TableField.CardApproveNo].push({
			data: {
				value: row.tid,
			},
		})
		tableData[TableField.NationalTaxApproveNo].push({
			data: {
				value: row.ntsNum,
			},
		})
		tableData[TableField.DepositDetails].push({
			data: {
				value: row.depositHistory,
			},
		})
		tableData[TableField.PaymentDatetime].push({
			data: {
				value: row.paymentDatetime,
			},
		})
		tableData[TableField.DepositDatetime].push({
			data: {
				value: row.depositDatetime,
			},
		})
		tableData[TableField.SellerInfo].push({
			data: {
				value: row.product.seller.name,
			},
		})
		tableData[TableField.SellerMemo].push({
			data: {
				value: row.sellerMemo,
				id: row.orderId,
			},
		})
		tableData[TableField.Platform].push({
			data: {
				value: row.platform,
			},
		})
	}
	return {
		count: count,
		tableData: tableData,
	}
}

const getStatusText = row => {
	if (row.isBlock === 1) return { text: '인증 대기', subtext: '', disabledRow: false }
	let result = {
		text: '',
		subtext: '',
		disabledRow: row.cancelStatus == 'DONE' || row.returnStatus == 'DONE',
	}

	if (row.returnStatus == 'DONE') {
		result.text = '교환/반품 완료'
	} else if (row.cancelStatus == "DONE") {
		result.text = '주문취소 완료'
	} else {
		if (row.status == 'WAIT') {
			result.text = '승인 대기'
		} else if (row.status == 'APPROVE') {
			result.text = '승인 완료'
		} else if (row.status == 'SHIPPING_ING') {
			result.text = '배송중'
		} else if (row.status == 'SHIPPING_COMPLETE') {
			result.text = '배송완료'
		} else if (row.status == 'CONFIRM') {
			result.text = '구매확정'
		}

		if (row.returnStatus == "ING") {
			result.subtext = '교환/반품 접수'
		} else if (row.cancelStatus == "ING") {
			result.subtext = '주문취소 접수'
		}
	}

	return result
}

const getOptions = options => {
	let result = ''
	options.forEach(option => {
		result += option.name + ': '
		result += option.value
	})
	return result
}

const getPaymentStatusText = status => {
	const result = {
		WAIT: '입금 대기중',
		PAID: '결제완료',
		CANCEL: '결제취소',
	}
	return result[status]
}

const convertPrice = price => {
	return (price < 1000 ? price : price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')) + (price > 0 ? '원' : null)
}

const getButtonStatusVolume = row => {
	return !(['WAIT', 'APPROVE'].includes(row.status) && row.cancelStatus != 'DONE' && row.returnStatus != 'DONE')
}

const getStatusDeliveryChange = row => {
	return !(['WAIT', 'APPROVE', 'SHIPPING_ING'].includes(row.status) && row.cancelStatus != 'DONE' && row.returnStatus != 'DONE')
}

const getButtonStatus = row => {
	return !(row.cancelStatus != 'DONE' && row.returnStatus != 'DONE' && row.paymentStatus != 'PAID')
}

const setRequestStatusText = text => {
	return { ING: '진행중', COMPLETE: '완료' }[text]
}

const setCategory = category => {
	return {
		CAT_CHEMICAL_REAGENT: '시약',
		CAT_CHEMICAL_GAS: '가스',
		CAT_BIO_REAGENT: '바이오 시약',
		CAT_BIO_ANTIBODY: '항체',
		CAT_BIO_CELL: '세포주',
		CAT_CHROMATOGRAPHY: '분석용 칼럼',
		CAT_LABWARE_EQUIPMENT: '연구 장비',
		CAT_TOOL: '실험용품',
		CAT_PROTECTION: '보호구',
		CAT_ETC: '소모품',
		CAT_CRYOBOX: '크라이오박스',
	}[category]
}

export {
	refineRequestProductListTableData,
	refineOrderListTableData,
	refineAddressListTableData,
	refineTemplateListTableData,
	refineProductManagementListTableData,
}
