<template>
	<div class="item-wrapper" :style="{ flexDirection: direction }">
		<div class="item-title" :style="{ width: direction === 'column' ? 'auto' : null }">
			{{ title }}<span v-if="required" class="required">*</span>
		</div>
		<div v-if="description" class="item-description">{{ description }}</div>
		<div class="item-content">
			<slot></slot>
			<div v-if="subContentText" class="item-sub-text" v-html="subContentText"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseFormItem',
	props: {
		title: {
			type: String,
			default: '',
		},
		description: {
			type: String,
			default: '',
		},
		subContentText: {
			type: String,
			default: '',
		},
		required: {
			type: Boolean,
			default: false,
		},
		direction: {
			type: String,
			default: 'row',
		},
	},
}
</script>

<style lang="scss" scoped>
.item-wrapper {
	display: flex;
	width: 100%;
	height: auto;
	min-height: 50px;
	padding: 15px 0;
	.item-title {
		width: 140px;
		padding-bottom: 8px;
		text-align: left;
		font-size: 0.75rem;
		font-weight: 400;
		.required {
			color: $PRIMARY_RED;
		}
	}
	.item-description {
		font-size: 0.875rem;
		font-weight: 500;
		color: $COLOR_666;
		margin-bottom: 8px;
	}
	.item-content {
		position: relative;
		display: flex;
		width: 100%;
		text-align: left;
		font-size: 0.875rem;
		.item-sub-text {
			padding-top: 8px;
			font-size: 0.75rem;
			color: $COLOR_999;
		}
	}
}
</style>
