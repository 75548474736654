import requestAPI from '@/services/api/estimate/request'

export default {
	namespaced: true,
	state: {
		requestProduct: {},
	},
	getters: {},
	mutations: {
		setRequestProduct: (state, payload) => {
			state.requestProduct = payload
		},
	},
	actions: {
		lookupRequestProduct: async ({ commit }, id) => {
			const response = await requestAPI.lookupRequestProduct(id)
			commit('setRequestProduct', response.data)
		},
		updateRequestProductMemo: async ({ commit }, body) => {
			return await requestAPI.updateRequestProductMemo(body)
		},
		failSourcing: async ({ commit }, body) => {
			return await requestAPI.failSourcing(body)
		},
		completeSourcing: async ({ commit }, body) => {
			return await requestAPI.completeSourcing(body)
		},
		confirmReceipt: async ({ commit }, body) => {
			return await requestAPI.confirmReceipt(body)
		},
		addProduct: async ({ commit }, body) => {
			return await requestAPI.addProduct(body)
		},
		deleteProduct: async ({ commit }, params) => {
			return await requestAPI.deleteProduct(params)
		},
		setRequestType: async (_, body) => {
			return await requestAPI.setRequestType(body)
		},
		printEstimate: async (_, id) => {
			return await requestAPI.printEstimate({ id })
		},
	},
}
