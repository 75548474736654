<template>
	<table class="lm-table" ref="table" @scroll="throttleScroll">
		<table-header />
		<table-item v-for="item in list" :key="item.id" :item="item" />
		<div class="empty-table" v-if="count === 0">
			<img class="empty-img" src="@/assets/svg/common/inventory-empty-data.svg" />
			<p class="bold">검색 결과가 없습니다</p>
			<p class="sub">요청번호, 요청자, 상품으로 검색할 수 있습니다.</p>
		</div>
	</table>
</template>
<script>
import TableHeader from './TableHeader.vue'
import TableItem from './TableItem.vue'
import { mapState, mapMutations } from 'vuex'

export default {
	components: {
		TableHeader,
		TableItem,
	},
	props: {
		list: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		...mapState('cancelRefund/list', ['count', 'scrollTop']),
	},
	watch: {
		count: {
			handler() {
				if (this.scrollTop > 0) this.$nextTick(() => (this.$refs['table'].scrollTop = this.scrollTop))
			},
			immediate: true,
		},
	},
	data() {
		return {
			throttleScroll: () => {},
		}
	},
	mounted() {
		this.throttleScroll = this.$_.throttle(this.scroll, 200)
	},
	destroyed() {
		if (
			this.$route.name !== 'CancelRefundDetail' &&
			this.$route.name !== 'OrderProductManagementDetail' &&
			this.$route.name !== 'OrderItemDetail'
		)
			this.initLoadParams()
	},
	methods: {
		...mapMutations('cancelRefund/list', ['setScrollTop', 'initLoadParams']),
		scroll(e) {
			this.setScrollTop(this.$refs['table'].scrollTop)
		},
	},
}
</script>
<style lang="scss" scoped>
.lm-table {
	position: relative;
	height: 100%;
	text-align: left;
	display: block;
	overflow: auto;
	white-space: nowrap;
}
.empty-table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: calc(100% - 40px);
	padding-bottom: 80px;
	.empty-img {
		margin-bottom: 24px;
	}
	.bold {
		color: #666;
		font-weight: 700;
		font-size: 16px;
		margin-bottom: 6px;
	}
	.sub {
		font-weight: 400;
		font-size: 14px;
		color: #999;
	}
}
</style>
