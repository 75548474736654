<template>
	<div class="Toast__list" :ref="`toast-wrapper`">
		<toast
			v-for="data in getMessages"
			:key="`toast-${data.toastId}`"
			:title="data.title"
			:content="data.content"
			:toastId="String(data.toastId)"
			:type="data.type"
		></toast>
	</div>
</template>

<script>
import Toast from '@/views/common/components/Toast/Toast.vue'
import { mapGetters } from 'vuex'

export default {
	components: {
		Toast,
	},
	data() {
		return {
			dataList: null,
		}
	},
	computed: {
		...mapGetters('toast', ['getMessages']),
	},
}
</script>

<style lang="scss" scoped>
.Toast__list {
	position: absolute;
	top: 50px;
	right: 10px;
	z-index: 999999 !important;
}
</style>
