<template>
	<div class="content">
		<info-list title="상품 요약" :list="sellSumInfo" />
		<p>판매중인 상품</p>
		<div class="list-wrapper">
			<div class="title-btn-wrapper">
				<search-box placeholder="브랜드, 상품번호, 판매자로 검색" :useQr="true" />
				<image-button
					:text="filterText"
					:isActive="filteredCount > 0"
					:activeImage="require('@/assets/svg/common/filter-active.svg')"
					:image="require('@/assets/svg/common/filter.svg')"
					@click="filterBtnClick"
				></image-button>
				<div class="right-btn-group">
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						보기옵션
					</button>
					<button class="btn" @click="onClickDownloadOrderList">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
				</div>
			</div>
			<div class="status-changer">
				<p class="count">{{ checkedLength }}</p>
				<hr class="divide" />
				<p>선택한 상품을</p>
				<button @click="changeStatus">판매상태 변경</button>
				<p>or</p>
				<button @click="deleteProduct">삭제</button>
			</div>
			<div class="table-wrapper">
				<jw-table
					ref="jw-table"
					:width="table.width"
					:options="table.options"
					@getSelectedItem="getSelectedItem"
					@change="changeDate"
					:noData="isNoData"
					:noSearchResult="isNoSearchResult"
					:noDataText="noDataText"
					:noDataImg="noDataImg"
					:noDataSubtext="noDataSubtext"
					:noSearchResultText="noSearchResultText"
					:noSearchResultSubtext="noSearchResultSubtext"
					noDataHeight="500px"
				></jw-table>
				<common-pagenation @page="getCurrentPage" :total="totalCount"></common-pagenation>
			</div>
		</div>
		<modal-filter ref="modal-filter" @changeLoadParams="sellingProductsMounted" />
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoList from '@/views/common/components/InfoList.vue'
import JwTable from '@/views/common/components/JwTable.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import SearchBox from '@/views/common/components/SearchBox.vue'
import ImageButton from '@/views/common/components/button/ImageButton.vue'
import ModalFilter from '@/views/buyer/lab/modals/ModalFilter.vue'
import mixin from '@/mixins'
import { ToastType } from '@/utils/define/ToastType'
import { orderManagementTable } from '@/utils/define/table/orderManagementTable'

export default {
	name: 'SellingProducts',
	components: {
		InfoList,
		SearchTitle,
		CommonPagenation,
		JwTable,
		SearchBox,
		ImageButton,
		ModalFilter,
	},
	mixins: [mixin],
	data() {
		return {
			checkedList: [],
			totalCount: 0,
			filteredCount: 0,
			listParams: {
				offset: 0,
				length: 0,
				keyword: '',
				status: 'ALL',
			},
			table: this.$_.cloneDeep(orderManagementTable),
		}
	},
	computed: {
		...mapGetters('seller/detail', ['getSellingListSummary']),
		sellSumInfo() {
			return [
				{ title: '판매중인 상품', value: '999개' },
				{ title: '판매 중지한 상품', value: '10개' },
			]
		},
		filterText() {
			if (this.filteredCount > 0) return `필터(${this.filteredCount})`
			return '필터'
		},
		isNoData() {
			return this.listParams.keyword.length === 0 && this.totalCount === 0
		},
		isNoSearchResult() {
			return this.listParams.keyword.length > 0 && this.totalCount === 0
		},
		noDataText() {
			return '판매중인 상품이 없습니다.'
		},
		noDataSubtext() {
			return '상품을 등록하면, 판매중인 상품을 보여드립니다.'
		},
		noSearchResultText() {
			return '검색 결과가 없습니다'
		},
		noSearchResultSubtext() {
			return '상품명, 상품번호, 자체 관리번호, 브랜드, 제품번호, CAS No로 검색할 수 있습니다.'
		},
		noDataImg() {
			return require('@/assets/svg/common/jw-table/img-nodata.svg')
		},
		checkedLength() {
			return this.checkedList.length ? this.checkedList.length + '개 선택됨' : '상품을 선택해주세요'
		},
	},
	mounted() {
		this.sellingProductsMounted()
	},
	methods: {
		...mapActions('seller/detail', ['']),
		sellingProductsMounted() {
			this.loadListData()
		},
		async loadListData() {
			this.$nextTick(() => this.$refs['jw-table'].resetSelectedItem())

			// await this.lookupOrderList(this.listParams)
			this.setData()
		},
		setData() {},
		getCurrentPage(page) {
			this.listParams.offset = page.offset
			this.listParams.length = page.length
		},
		getSelectedItem(list) {
			this.checkedList = list
		},
		async changeDate(value, row) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: row.orderItemId,
						estimatedReleaseDatetime: value,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', 'success')
				this.loadListData()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		onClickDownloadOrderList() {},
		changeStatus() {},
		deleteProduct() {},
		filterBtnClick() {
			this.$refs['modal-filter'].show()
		},
	},
}
</script>

<style lang="scss" scoped>
.content {
	p {
		display: flex;
		font-weight: 700;
		font-size: 20px;
	}
}
.search-box {
	padding-top: 30px;
}
.list-wrapper {
	margin-top: 20px;
	.title-btn-wrapper {
		gap: 10px;
		display: flex;
		justify-content: space-between;
	}
	.status-changer {
		height: 76px;
		background: #fff3d6;
		border-radius: 4px;
		margin: 10px 0;
		display: flex;
		justify-content: center;
		align-items: center;
		p {
			font-weight: 400;
			font-size: 16px;
			color: #333;
		}
		button {
			background: $FONT_YELLOW;
			border-radius: 5px;
			height: 44px;
			color: white;
			font-weight: 700;
			font-size: 16px;
			padding: 10px;
			margin: 10px;
		}
		.count {
			font-weight: 700;
			color: $FONT_YELLOW;
		}
		.divide {
			border-left: 1px solid $COLOR_DDD;
			height: 20px;
			width: 0px;
			margin: 0 10px;
			padding-right: 5px;
			border-top: 0;
		}
	}
}
</style>
