<template>
	<tr class="table-header">
		<th>
			<span>번호</span>
		</th>
		<th>
			<span>상태</span>
		</th>
		<th>
			<span>제목</span>
		</th>
		<th>
			<span>노출타겟</span>
		</th>
		<th>
			<span>노출 시작일시</span>
		</th>
		<th>
			<span>노출 종료일시</span>
		</th>
		<th>
			<span>등록자</span>
		</th>
		<th>
			<span>등록일시</span>
		</th>
	</tr>
</template>

<script>
import MixinHeader from '@/mixins/table/header'

export default {
	mixins: [MixinHeader],
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/table/_table-header.scss';
</style>
