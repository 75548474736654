<template>
	<div class="choice-wrapper">
		<ul>
			<li v-for="(item, index) in list" :key="`choiceTab_${index}`">
				<div>
					<input
						v-model="tab.id"
						type="radio"
						:id="`tab_${index}`"
						name="choiceTabList"
						:checked="item.isDefault"
						:value="item.id"
						@change="changeItem(item)"
					/>
					<label :for="`tab_${index}`" style="cursor: pointer">
						{{ item.isDefault ? '(기본) ' : '' }}{{ item.name }}
						<p v-if="type === 'address'">{{ item.address }}</p>
						<p v-else>{{ item.tel }}</p>
					</label>
				</div>
				<div>
					<button class="btn" @click="$emit('edit', item)">수정</button>
				</div>
			</li>
			<div v-if="!list.length">
				<p>{{ emptyMessage }}</p>
				<button class="btn" @click="$emit('regist')">{{ `${buttonText ? buttonText : title} 등록` }}</button>
			</div>
		</ul>
		<span v-if="list.length">
			{{ `선택된 ${title}: ${selectedName}` }}
		</span>
		<span v-else>
			{{ `선택된 ${title} 없습니다.` }}
		</span>
	</div>
</template>

<script>
export default {
	name: 'ChoiceList',
	props: {
		title: {
			type: String,
			default: '',
		},
		emptyMessage: {
			type: String,
			default: '',
		},
		buttonText: {
			type: String,
			default: '',
		},
		list: {
			type: Array,
			default: () => [],
		},
		tab: {
			type: Object,
			default: () => {},
		},
		type: {
			type: String,
			default: '',
		},
	},
	computed: {
		selectedName() {
			return this.selectedTab.name ?? this.tab.name
		},
	},
	data() {
		return {
			selectedTab: {},
		}
	},
	methods: {
		changeItem(value) {
			this.selectedTab = value
			this.$emit('changeValue', value)
		},
	},
}
</script>

<style lang="scss" scoped>
.choice-wrapper {
	display: flex;
	flex-direction: column;
	gap: 15px;
	ul {
		width: 810px;
		height: 230px;
		box-sizing: border-box;
		border: 1px solid $COLOR_DDD;
		border-radius: 4px;
		overflow-x: auto;
		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 15px;
			width: 100%;
			height: 80px;
			border-bottom: 1px solid $COLOR_DDD;
			font-size: 0.875rem;

			div {
				display: flex;
				align-items: center;

				input {
					appearance: none;
					width: 20px;
					height: 20px;
					border: 1px solid $PRIMARY_YELLOW;
					border-radius: 50%;
					background-clip: content-box;
					padding: 2px;
				}

				input:checked {
					background-color: $PRIMARY_YELLOW;
				}

				label {
					padding-left: 15px;
					font-weight: 700;

					p {
						font-weight: 400;
					}
				}
			}
		}
		& > div {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;

			button.btn {
				margin-top: 10px;
			}
		}
	}
	span {
		font-size: 0.875rem;
		color: $COLOR_333;
	}
}
</style>
