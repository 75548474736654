<template>
	<div class="order-product-item-detail">
		<order-item-detail-header :item="orderItem" @load="fetchOrderItem"></order-item-detail-header>
		<div class="content-wrapper">
			<info-list title="주문 정보" :list="orderInfo" />
			<info-list title="주문자 정보" :list="ordererInfo" />
			<info-list title="수령인 정보" :list="recipientInfo" />
			<info-list title="상품 정보" :list="productInfo" />
			<info-list title="주문 금액 정보" :list="orderPriceInfo" />
			<info-list title="상품 배송 정보" :list="shippingInfo" />
			<textarea-edit-form
				title="숨김 메모"
				content="*해당 메모는 판매자와 주문자에게 노출되지 않는 정보입니다"
				:defaultValue="adminMemo"
				@save="value => saveTextForm(value, 'adminMemo')"
			/>
			<info-list title="구매자 결제 정보" :list="payerPaymentInfo" />
			<info-list title="판매자 정보" :list="sellerInfo" />
			<!-- 추후에 작업 -->
			<!-- <info-list title="판매자 정산 정보" :list="sellerCalculateInfo" /> -->
		</div>

		<modal-update-price
			:isShow="isShowModalUpdatePrice"
			:item="orderItem"
			@hide="isShowModalUpdatePrice = false"
			@complete="fetchOrderItem"
		></modal-update-price>
		<modal-update-sales-price
			:isShow="isshowModalUpdateSalesPrice"
			:item="orderItem"
			@hide="isshowModalUpdateSalesPrice = false"
			@complete="fetchOrderItem"
		></modal-update-sales-price>
		<modal-shipping-info
			:isShow="isShowModalShippingInfo"
			:orderItemList="[orderItem]"
			@hide="isShowModalShippingInfo = false"
			@complete="fetchOrderItem"
		></modal-shipping-info>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import OrderItemDetailHeader from '@/views/order/orderItem/components/OrderItemDetailHeader.vue'
import InfoList from '@/views/common/components/InfoList.vue'
import TextareaEditForm from '@/views/common/components/formItem/TextareaEditForm.vue'
import ModalUpdatePrice from '@/views/order/orderItem/modals/ModalUpdatePrice.vue'
import ModalUpdateSalesPrice from '@/views/order/orderItem/modals/ModalUpdateSalesPrice.vue'
import ModalShippingInfo from '@/views/order/orderItem/modals/ModalShippingInfo.vue'
import Validation from '@/utils/Validation.js'
import { PaymentStatus, OrderStatusText, shippingMethodToString } from '@/utils/define/ItemCode'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'OrderItemDetail',
	components: {
		OrderItemDetailHeader,
		InfoList,
		TextareaEditForm,
		ModalUpdatePrice,
		ModalShippingInfo,
		ModalUpdateSalesPrice,
	},
	data() {
		return {
			isShowModalUpdatePrice: false,
			isshowModalUpdateSalesPrice: false,
			isShowModalShippingInfo: false,
		}
	},
	computed: {
		...mapState('order', ['orderItem']),
		orderInfo() {
			// 주문 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const dateFormat = 'yyyy-MM-dd T'
			const { orderNo, orderItemNo, totalOrderItemPrice, createDatetime, approveDatetime, releaseDatetime, shippingDatetime } = this.orderItem
			const createDatetimeFormat = createDatetime ? this.formatDatetime(createDatetime, dateFormat) : this.isData(createDatetime)
			const approveDatetimeFormat = approveDatetime ? this.formatDatetime(approveDatetime, dateFormat) : this.isData(approveDatetime)
			const releaseDatetimeFormat = releaseDatetime ? this.formatDatetime(releaseDatetime, dateFormat) : this.isData(releaseDatetime)
			const shippingDatetimeFormat = shippingDatetime ? this.formatDatetime(shippingDatetime, dateFormat) : this.isData(shippingDatetime)
			const statusText = this.cancelStatusText ? this.cancelStatusText : this.statusText

			const list = []
			list.push({ title: '주문번호', value: this.isData(orderNo) })
			list.push({ title: '주문 아이템 번호', value: this.isData(orderItemNo) })
			list.push({ title: '주문상태', value: this.isData(statusText) })
			list.push({ title: '주문 금액 합계', value: `${this.commaNum(totalOrderItemPrice)}원` })
			list.push({ title: '주문일시', value: createDatetimeFormat })
			list.push({ title: '주문 처리일시', value: approveDatetimeFormat })
			list.push({ title: '배송 시작일시', value: releaseDatetimeFormat })
			list.push({ title: '배송 완료일시', value: shippingDatetimeFormat })
			return list
		},
		ordererInfo() {
			// 주문자 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { institute, orderer } = this.orderItem
			const { name: instituteName, id: instituteId } = institute
			const { name, phone, email } = orderer

			const list = []
			list.push({ title: '주문자 소속', value: this.isData(instituteName) })
			list.push({ title: '주문 연구실 ID', value: this.isData(instituteId) })
			list.push({ title: '주문자', value: this.isData(name) })
			list.push({ title: '주문자 연락처', value: this.isData(Validation.autoHypenTel(phone)) })
			list.push({ title: '주문자 이메일', value: this.isData(email), row: true })
			return list
		},
		recipientInfo() {
			// 수령인 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { recipient, deliveryRequest } = this.orderItem
			const { name, phone } = recipient

			const list = []
			list.push({ title: '수령인', value: this.isData(name) })
			list.push({ title: '수령인 연락처', value: this.isData(Validation.autoHypenTel(phone)) })
			list.push({ title: '주소', value: this.isData(this.address), row: true })
			list.push({ title: '배송 요청사항', value: this.isData(deliveryRequest), row: true })

			return list
		},
		productInfo() {
			// 상품 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { productName, product } = this.orderItem
			const { productInfoId, category, mngNo, subname, brand, code, casno, sku, options, stock, salesUnitPrice, unitPrice } = product
			const { name: brandName } = brand
			const { korName, parents } = category
			let categoryFullName = ''
			if (parents.length > 0) categoryFullName += parents[0].korName
			categoryFullName += `/${korName}`
			let optionText = ''
			if (options?.length > 0) {
				options.forEach((option, index) => {
					optionText += `${index !== 0 ? '|' : ''} ${option.name}: ${option.value}`
				})
			}

			const list = []
			list.push({ title: '상품번호', value: this.isData(productInfoId), type: 'link', clickFn: this.goProductInfoDetail })
			list.push({ title: '자체 관리번호', value: this.isData(mngNo) })
			list.push({ title: '카테고리', value: this.isData(categoryFullName) })
			list.push({ title: '물품 이름', value: this.isData(productName) })
			list.push({ title: '서브네임', value: this.isData(subname) })
			list.push({ title: '브랜드', value: this.isData(brandName) })
			list.push({ title: '제품번호', value: this.isData(code) })
			list.push({ title: 'CAS No', value: this.isData(casno) })
			list.push({
				title: '옵션',
				value: {
					title: this.isData(sku),
					size: optionText,
					subtitle: `재고 수량: ${this.isData(stock)} | 매입단가: ${this.isData(
						this.commaNum(salesUnitPrice)
					)}원 | 소비자가(단가): ${this.isData(this.commaNum(unitPrice))}원`,
				},
				row: true,
				type: 'option',
			})

			return list
		},
		orderPriceInfo() {
			// 주문 금액 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { paymentStatus, quantity, orderItemPrice, orderItemVat, orderItemPriceWithVat } = this.orderItem

			const list = []
			list.push({ title: '주문수량', value: `${this.isData(quantity)}개` })
			list.push({
				title: '주문 금액',
				value: `${this.commaNum(orderItemPrice)}원`,
				type: paymentStatus !== PaymentStatus.PAID ? 'text-button' : null,
				clickFn: this.showModalUpdatePrice,
				buttonText: '수정',
			})
			list.push({ title: 'VAT(10%)', value: `${this.commaNum(orderItemVat)}원` })
			list.push({ title: '주문 금액(VAT포함)', value: `${this.commaNum(orderItemPriceWithVat)}원`, type: 'bold' })
			list.push({
				title: '매입 단가',
				value: `${this.commaNum(this.orderItem.salesUnitPrice)}원`,
				type: 'text-button',
				clickFn: this.showModalUpdateSalesPrice,
				buttonText: '수정',
			})

			return list
		},
		shippingInfo() {
			// 상품 배송 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const dateFormat = 'yyyy-MM-dd'

			const { estimatedReleaseDatetime, shippingMethod, product, status } = this.orderItem
			const { shippingDirectContact, shippingCanQuick } = product
			const shippingDirectContactFormat = shippingDirectContact ? '개별연락 필요' : '-'
			const shippingCanQuickFormat = shippingCanQuick ? '퀵 서비스 가능' : '-'
			const estimatedReleaseDatetimeFormat = estimatedReleaseDatetime
				? this.formatDatetime(estimatedReleaseDatetime, dateFormat)
				: this.isData(estimatedReleaseDatetime)
			const isEmptyEstimatedReleaseDatetime = !estimatedReleaseDatetime ? true : false

			const list = []
			list.push({
				title: '예상 출고일',
				value: `${estimatedReleaseDatetimeFormat}`,
				type: !this.isCancelOrRefund && ['WAIT', 'APPROVE'].includes(status) ? 'date' : null,
				buttonText: '선택',
				isEmpty: isEmptyEstimatedReleaseDatetime,
				clickFn: this.updateEstimatedReleaseDatetime,
			})
			list.push({
				title: '배송방식',
				value: `${this.isData(shippingMethodToString(shippingMethod))}`,
				type: !this.isCancelOrRefund && !['CONFIRM'].includes(status) ? 'text-button' : null,
				clickFn: this.showModalShippingInfo,
				buttonText: shippingMethod ? '수정' : '선택',
			})
			list.push({ title: '개별연락', value: shippingDirectContactFormat })
			list.push({ title: '퀵 서비스', value: shippingCanQuickFormat })
			list.push({ title: '출고지', value: `${this.isData(this.shipmentPlace)}`, row: true })

			return list
		},
		payerPaymentInfo() {
			// 구매자 결제 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { depositPrice } = this.orderItem

			const list = []
			list.push({ title: '입금금액', value: `${this.isData(this.commaNum(depositPrice))}` })
			list.push({ title: '연구실 결제상태', value: `${this.isData(this.paymentStatusText)}` })

			return list
		},
		sellerInfo() {
			// 판매자 정보
			if (this.$_.isEmpty(this.orderItem)) return

			const { product, sellerMemo } = this.orderItem
			const { seller } = product
			const { name, contactName, contactPhone, email } = seller

			const list = []
			list.push({ title: '판매자 이름', value: `${this.isData(name)}` })
			list.push({ title: '담당자', value: `${this.isData(contactName)}` })
			list.push({ title: '연락처', value: `${this.isData(Validation.autoHypenTel(contactPhone))}` })
			list.push({ title: '이메일', value: `${this.isData(email)}` })
			list.push({ title: '판매자 메모', value: `${this.isData(sellerMemo)}`, row: true })

			return list
		},
		sellerCalculateInfo() {
			// 추후에 작업
			// 판매자 정산 정보
			if (this.$_.isEmpty(this.orderItem)) return

			// const {} = this.orderItem

			const list = []
			// list.push({ title: '정산번호', value: `${this.isData()}` })
			// list.push({ title: '정산상태', value: `${this.isData()}` })

			return list
		},
		createDatetime() {
			const { createDatetime } = this.orderItem
			const createDatetimeFormat = createDatetime ? this.formatDatetime(createDatetime, 'yyyy-MM-dd T') : this.isData(createDatetime)
			return createDatetimeFormat
		},
		statusText() {
			return OrderStatusText(this.orderItem).text
		},
		cancelStatusText() {
			return OrderStatusText(this.orderItem).subtext
		},
		paymentStatusText() {
			return PaymentStatus.statusToString(this.orderItem.paymentStatus)
		},
		address() {
			const { postcode, address } = this.orderItem
			return `[${this.isData(postcode)}] ${this.isData(address)}`
		},
		shipmentPlace() {
			const { shipmentPlace } = this.orderItem
			const { postcode, address } = shipmentPlace
			return `[${this.isData(postcode)}] ${this.isData(address)}`
		},
		adminMemo() {
			return this.orderItem.adminMemo
		},
		isCancelOrRefund() {
			return this.orderItem.refundStatus || this.orderItem.cancelStatus
		},
	},
	created() {
		this.fetchOrderItem()
	},
	methods: {
		...mapActions('order', ['lookupOrderItem', 'updateOrderItemAdminMemo', 'changeEstimatedDate']),
		fetchOrderItem() {
			try {
				this.lookupOrderItem({ orderItemId: this.$route.params.id })
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		async saveTextForm(value, field) {
			try {
				await this.updateOrderItemAdminMemo({
					orderItemId: this.orderItem.orderItemId,
					[field]: value,
				})

				this.$root.toast('숨김 메모 입력 완료', '숨김 메모 입력이 완료되었습니다.', ToastType.SUCCESS)
				this.fetchOrderItem()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
		showModalShippingInfo() {
			this.isShowModalShippingInfo = true
		},
		showModalUpdatePrice() {
			this.isShowModalUpdatePrice = true
		},
		showModalUpdateSalesPrice() {
			this.isshowModalUpdateSalesPrice = true
		},
		async updateEstimatedReleaseDatetime(date) {
			try {
				await this.changeEstimatedDate({
					body: {
						orderItemId: this.orderItem.orderItemId,
						estimatedReleaseDatetime: date,
					},
				})
				this.$root.toast('예상 출고일 변경 완료', '예상 출고일이 변경되었습니다.', ToastType.SUCCESS)
				this.fetchOrderItem()
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		goProductInfoDetail() {
			console.log('상품 상세로 이동')
			// 추후 상품 상세로 이동
			// this.$router.push({
			// 	name: 'ProductInfoDetail',
			// })
		},
	},
}
</script>

<style lang="scss" scoped>
.order-product-item-detail {
	.content-wrapper {
		width: 100%;
		padding: 0 30px 40px;

		.textarea-edit-form {
			padding-bottom: 30px;
		}
	}
}
</style>
