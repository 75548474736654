<template>
	<portal to="lm-modal" v-if="isShow">
		<div class="ViewOptionPopup">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">사업자등록증 입력 정보</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="input-wrapper">
						<p>연구실 전화번호</p>
						<div>
							<input type="text" v-model="info.tel" placeholder="연구실 전화번호를 입력해주세요" />
						</div>
						<p>사업자등록증 번호<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.corpNo"
								:class="{ error: !isValid.corpNo }"
								placeholder="사업자등록증 번호를 입력해주세요"
								@input="isValid.corpNo = true"
							/>
							<div v-if="!isValid.corpNo" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.corpNo }}</span>
							</div>
						</div>
						<p>법인명 (단체명)<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.corpName"
								:class="{ error: !isValid.corpName }"
								placeholder="법인명 (단체명)을 입력해주세요"
								@input="isValid.corpName = true"
							/>
							<div v-if="!isValid.corpName" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.corpName }}</span>
							</div>
						</div>
						<p>대표자<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.ceoName"
								:class="{ error: !isValid.ceoName }"
								placeholder="대표자를 입력해주세요"
								@input="isValid.ceoName = true"
							/>
							<div v-if="!isValid.ceoName" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.ceoName }}</span>
							</div>
						</div>
						<p>사업장 소재지<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.address"
								:class="{ error: !isValid.address }"
								placeholder="사업장 소재지 입력해주세요"
								@input="isValid.address = true"
							/>
							<div v-if="!isValid.address" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.address }}</span>
							</div>
						</div>
						<p>사업의 종류 : 업태 (ex. 서비스)<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.bizType"
								:class="{ error: !isValid.bizType }"
								placeholder="사업 업태를 입력해주세요"
								@input="isValid.bizType = true"
							/>
							<div v-if="!isValid.bizType" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.bizType }}</span>
							</div>
						</div>
						<p>사업의 종류 : 종목 (ex. 산학협력업무)<span>*</span></p>
						<div>
							<input
								type="text"
								v-model="info.bizClass"
								:class="{ error: !isValid.bizClass }"
								placeholder="사업 종목을 입력해주세요"
								@input="isValid.bizClass = true"
							/>
							<div v-if="!isValid.bizClass" class="error">
								<img src="@/assets/svg/common/icon-error.svg" alt="error" />
								<span>{{ inValidText.bizClass }}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">{{mode == "approve" ? "인증 완료" : "수정 완료"}}</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import MixinModal from '@/mixins/modal'
import { ToastType } from '@/utils/define/ToastType'

export default {
	name: 'ModalApproveBusinessRegistration',
	props: {
		id: {
			type: [Number, String],
			default: 0,
		},
	},
	mixins: [MixinModal],
	data() {
		return {
			mode: 'approve', // approve, update
			info: {
				tel: '',
				corpNo: '',
				corpName: '',
				ceoName: '',
				address: '',
				bizClass: '',
				bizType: '',
			},
			isValid: {
				corpNo: true,
				corpName: true,
				ceoName: true,
				address: true,
				bizClass: true,
				bizType: true,
			},
			inValidText: {
				corpNo: '',
				corpName: '',
				ceoName: '',
				address: '',
				bizClass: '',
				bizType: '',
			},
		}
	},
	computed: {},
	methods: {
		...mapActions('businessregistration/list', ['approveBusinessRegistration', 'updateBusinessRegistration']),
		async show(data) {
			if (data) {
				this.mode = 'update';
				if (data.tel) this.info.tel = data.tel
				if (data.corpNo) this.info.corpNo = data.corpNo
				if (data.corpName) this.info.corpName = data.corpName
				if (data.ceoName) this.info.ceoName = data.ceoName
				if (data.address) this.info.address = data.address
				if (data.bizType) this.info.bizType = data.bizType
				if (data.bizClass) this.info.bizClass = data.bizClass
			}
			
			this.isShow = true
		},
		async complete() {
			try {
				if (!this.validate()) return
				const body = {
					...this.info,
					id: this.id,
				}
				if (this.mode == 'approve') {
					await this.approveBusinessRegistration(body)
					this.$root.toast('인증 완료', '사업자등록증 인증을 완료했습니다.', ToastType.SUCCESS)
				} else if (this.mode == 'update') {
					await this.updateBusinessRegistration(body)
					this.$root.toast('수정 완료', '사업자등록증 수정을 완료했습니다.', ToastType.SUCCESS)
				}
				this.hide()
				this.$emit('complete')
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		validate() {
			Object.keys(this.isValid).forEach(key => {
				this.isValid[key] = true
			})

			let isValid = true
			if (!this.info.corpNo.length) {
				this.isValid.corpNo = false
				this.inValidText.corpNo = '사업자등록증 번호를 입력해주세요.'
				isValid = false
			}
			if (!this.info.corpName.length) {
				this.isValid.corpName = false
				this.inValidText.corpName = '대표자를 입력해주세요.'
				isValid = false
			}
			if (!this.info.ceoName.length) {
				this.isValid.ceoName = false
				this.inValidText.ceoName = '법인명 (단체명)을 입력해주세요.'
				isValid = false
			}
			if (!this.info.address.length) {
				this.isValid.address = false
				this.inValidText.address = '사업장 소재지 입력해주세요.'
				isValid = false
			}
			if (!this.info.bizClass.length) {
				this.isValid.bizClass = false
				this.inValidText.bizClass = '사업 종목을 입력해주세요.'
				isValid = false
			}
			if (!this.info.bizType.length) {
				this.isValid.bizType = false
				this.inValidText.bizType = '사업 업태를 입력해주세요.'
				isValid = false
			}

			return isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__content {
	input[type='text'] {
		width: 100%;
		height: 30px;
		padding: 12px;
		border: 1px solid $FILE_BORDER;
		border-radius: 5px;
		&.error {
			border-color: $PRIMARY_RED;
		}
	}
	.error {
		display: flex;
		span {
			font-size: 0.75rem;
			color: $PRIMARY_RED;
			margin-left: 6px;
		}
	}
	.input-wrapper {
		p {
			font-size: 0.75rem;
			span {
				color: $PRIMARY_RED;
			}
		}
		div {
			margin: 5px 0 10px 0;
		}
	}
}
</style>
