<template>
	<tr class="table-item">
		<td>
			<div>
				<span>{{ item.account }}</span>
			</div>
		</td>
		<td>
			<div>
				<span>{{ item.password }}</span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
		<td class="btn-td">
			<button>
				<img src="@/assets/svg/common/icon-edit.svg" />
				<div>수정</div>
			</button>
			<button>
				<img src="@/assets/svg/common/icon-trash.svg" />
				<div>삭제</div>
			</button>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {},
	methods: {},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.table-item {
	&:hover {
		background: none;
		cursor: default;
	}
}
.btn-td {
	display: flex;
	gap: 10px;
	align-items: center;
	button {
		background: #f2f3f4;
		border-radius: 4px;
		width: 70px;
		height: 30px;
		div {
			font-weight: 700;
			font-size: 14px;
		}
		&:hover {
			background: #dadbdc;
		}
	}
}
</style>
