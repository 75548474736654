<template>
	<portal to="lm-modal">
		<div class="PricePopup" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-xs">
				<div class="Popup__top">
					<h6 class="Popup__title" style="font-size: 16px">주문 매입단가<span style="font-weight: 400"> 수정</span></h6>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<input type="number" v-model="price" class="price" />
					<span class="won">원</span>
				</div>
				<div class="check-item-wrapper">
					<label class="check-item"> <input class="checkbox" type="checkbox" v-model="isAll" /> 상품 매입단가 일괄 수정 </label>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__complete complete-btn" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'

export default {
	name: 'ModalUpdateSalesPrice',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		item: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		item: {
			handler(val) {
				this.price = this.$_.cloneDeep(this.item.salesUnitPrice)
			},
			deep: true,
		},
	},
	data() {
		return {
			price: 0,
			isAll: false,
		}
	},
	methods: {
		...mapActions('order', ['updateSalesPrice']),
		hide() {
			this.$emit('hide')
		},
		async complete() {
			try {
				await this.updateSalesPrice({
					orderItemId: this.item.orderItemId,
					salesUnitPrice: this.price,
					applyProduct: this.isAll,
				})

				this.$emit('complete')
				this.$root.toast('금액 수정 완료', '매입 단가가 수정되었습니다.', ToastType.SUCCESS)
				this.hide()
			} catch (e) {
				this.$root.toast('에러', e.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.PricePopup {
	.price {
		width: 100%;
		height: 36px;
		border-radius: 5px;
		border: solid 1px #ddd;
		font-size: 14px;
		padding: 10px;
	}
	.won {
		position: absolute;
		right: 40px;
		top: 68px;
		font-size: 14px;
		color: #000;
	}
	.Popup__bottom {
		padding: 0 10px 13px 10px;
		.Popup__complete-btn-group {
			margin: 0;
		}
		.Popup__btn-group {
			margin: 0;
		}
		.complete-btn {
			width: 315px;
			height: 40px;
		}
	}
	.check-item-wrapper {
		text-align: start;
		.check-item {
			font-weight: 400;
			font-size: 14px;
			padding: 0 0 10px 20px;
			input {
				margin-right: 5px;
			}
		}
	}
}
</style>
