<template>
	<portal to="lm-modal">
		<div class="modal-order-approve" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">주문승인</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<table>
						<thead>
							<tr>
								<th style="width: 200px">주문 아이템 번호</th>
								<th style="width: 100px">수량</th>
								<th style="width: 160px">예상 출고일</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in list" :key="`order_approve_${index}`">
								<td>{{ item.orderItemNo }}</td>
								<td>{{ `${item.quantity}개` }}</td>
								<td style="position: relative">
									<image-date-button
										type="calendar"
										datePlaceholder="예상 출고일"
										:initDate="item.estimatedReleaseDatetime"
										:isRange="false"
										@changeDate="date => changeDate(index, date)"
										:datePickerStyle="{ width: '130px' }"
									></image-date-button>
								</td>
							</tr>
						</tbody>
					</table>
					<div v-if="!isValid" class="error-wrapper">
						<div class="image">
							<img src="@/assets/svg/order/icon-alert.svg" alt="" />
						</div>
						<div class="text">예상 출고일을 입력해주세요</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__complete" @click="complete">완료</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { mapActions } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import ImageDateButton from '@/views/common/components/button/ImageDateButton.vue'

export default {
	name: 'ModalOrderApprove',
	components: {
		ImageDateButton,
	},
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		orderItemList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			list: [],
			isValid: true,
		}
	},
	watch: {
		orderItemList: {
			handler(val) {
				this.list = this.$_.cloneDeep(val)
			},
			deep: true,
		},
	},
	methods: {
		...mapActions('order', ['approveOrder']),
		hide() {
			this.$emit('hide')
		},
		async complete() {
			try {
				if (!this.validate()) return
				await this.approveOrder({
					orderItems: this.list.map(({ orderItemId, estimatedReleaseDatetime }) => {
						return {
							id: orderItemId,
							estimatedReleaseDatetime: this.formatDate(estimatedReleaseDatetime),
						}
					}),
				})
				this.hide()
				this.$root.toast('주문상태 변경 완료', '승인 완료으로 변경되었습니다.', ToastType.SUCCESS)
				this.$emit('complete')
			} catch (error) {
				this.$root.toast('에러', `${error.response.data.msg}`, ToastType.ERROR)
			}
		},
		changeDate(index, date) {
			this.list[index].estimatedReleaseDatetime = date
		},
		validate() {
			this.isValid = true
			this.list.forEach(item => {
				if (!item.estimatedReleaseDatetime) this.isValid = false
			})

			return this.isValid
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-approve {
	.Popup__container {
		.Popup__content {
			width: 500px;
			table {
				border: 1px solid #f5f5f5;
				border-radius: 5px;
				thead {
					height: 40px;
					background-color: #f5f5f5;
					tr {
						border-bottom: 1px solid #f5f5f5;
						th {
							padding: 10px 10px;
							font-size: 0.875rem;
							font-weight: 700;
							color: $COLOR_666;
						}
					}
				}
				tbody {
					tr {
						height: 60px;
						&:not(:last-child) {
							border-bottom: 1px solid #f5f5f5;
						}
						td {
							padding: 0 10px;
							font-size: 0.875rem;
						}
					}
				}
			}
			.error-wrapper {
				margin-top: 5px;
				display: flex;
				align-items: center;
				.image {
					display: flex;
					align-items: center;
				}

				.text {
					margin-left: 5px;
					color: $PRIMARY_RED;
					font-size: 0.75rem;
				}
			}
		}
	}
}
</style>
