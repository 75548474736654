import axiosInstance from '@/services/axios'

const endPoint = {
	lookupCatalogList: () => 'admin/catalog/search',
	lookupCatalog: id => `admin/catalog?catalogId=${id}`,
	lookupProductList: () => `admin/catalog/product/list`,
	lookupFilter: () => 'admin/catalog/list/filter',
}

const api = {
	lookupCatalogList: params => axiosInstance.get(endPoint.lookupCatalogList(), { params }),
	lookupCatalog: id => axiosInstance.get(endPoint.lookupCatalog(id)),
	lookupProductList: params => axiosInstance.get(endPoint.lookupProductList(), { params }),
	lookupFilter: params => axiosInstance.get(endPoint.lookupFilter(), { params }),
}

export default api
