import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `/admin/brand/list`,
	getDetail: () => `/admin/brand`,
	getProductList: () => `/admin/brand/productInfo/list`,
	getProductSummary: () => `/admin/brand/productInfo/summary`,
	createBrand: () => `/admin/brand`,
	editBrand: () => `/admin/brand`,
	mergeBrands: () => `/admin/brand/integration`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	getDetail: params => axiosInstance.get(endPoint.getDetail(), { params }),
	getProductList: params => axiosInstance.get(endPoint.getProductList(), { params }),
	getProductSummary: params => axiosInstance.get(endPoint.getProductSummary(), { params }),
	createBrand: body => axiosInstance.post(endPoint.createBrand(), body),
	editBrand: body => axiosInstance.patch(endPoint.editBrand(), body),
	mergeBrands: body => axiosInstance.post(endPoint.mergeBrands(), body),
}

export default api
