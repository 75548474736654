import inquiryAPI from '@/services/api/cs/inquiry'

export default {
	namespaced: true,
	state: {
		item: null,
	},
	getters: {},
	mutations: {
		setItem: (state, data) => {
			state.item = data
		},
	},
	actions: {
		getDetail: async ({ commit }, id) => {
			const response = await inquiryAPI.lookupInquiryDetail(id)
			commit('setItem', response.data)
		},
		setReply: async ({ commit }, body) => {
			return await inquiryAPI.setReply(body)
		},
	},
}
