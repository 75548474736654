<template>
	<base-item :title="title">
		<option-table ref="optionTable" :columns="columns" :totalCountObj="totalCountObj" @changeTableData="changeTableData"></option-table>
	</base-item>
</template>

<script>
import BaseItem from '@/views/common/components/BaseItem.vue'
import OptionTable from '@/views/product/registration/components/sellInfo/OptionTable.vue'

export default {
	name: 'InputOptionTable',
	components: {
		BaseItem,
		OptionTable,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		columns: {
			type: Array,
			default: () => [],
		},
		totalCountObj: {
			type: Object,
			default: () => {},
		},
	},
	methods: {
		changeTableData(tableData) {
			this.$emit('changeTableData', tableData)
		},
		valid() {
			return this.$refs['optionTable'].valid()
		},
		setTableData(tableData) {
			this.$refs['optionTable'].setTableData(tableData)
		},
	},
}
</script>

<style lang="scss" scoped></style>
