import axiosInstance from '@/services/axios'

const endPoint = {
	list: () => `admin/businessRegistration/list`,
	detail: () => `admin/businessRegistration`,
	rejectBusinessRegistration: () => `admin/businessRegistration/reject`,
	approveBusinessRegistration: () => `admin/businessRegistration/approve`,
	updateBusinessRegistration: () => `admin/businessRegistration`,
}

const api = {
	list: params => axiosInstance.get(endPoint.list(), { params }),
	detail: params => axiosInstance.get(endPoint.detail(), { params }),
	rejectBusinessRegistration: body => axiosInstance.patch(endPoint.rejectBusinessRegistration(), body),
	approveBusinessRegistration: body => axiosInstance.patch(endPoint.approveBusinessRegistration(), body),
	updateBusinessRegistration: body => axiosInstance.patch(endPoint.updateBusinessRegistration(), body),
}

export default api
