import catalogAPI from '@/services/api/catalog'

export default {
	namespaced: true,
	state: {
		loadParams: {
			offset: 0,
			length: 50,
			order: ['FAVORITE'],
			orderDirection: ['DESC'],
			keyword: '',
			keywordType: 'TEXT',
			keywordTarget: 'ALL',
			subKeyword: '',
			subKeywordTarget: 'ALL',
			categoryCode: '',
			sellerIds: [],
			brandIds: [],
			tags: [],
			types: ['NORMAL'],
		},
		filter: null,
		list: [],
		count: 0,
	},
	mutations: {
		setType: (state, type) => {
			state.loadParams.types = [type]
		},
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setOffset: (state, pageNumber) => {
			state.loadParams.offset = (pageNumber - 1) * state.loadParams.length
		},
		setFilter: (state, data) => {
			state.filter = data
		},
	},
	actions: {
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			await dispatch('getList')
		},
		getList: async ({ state, commit }) => {
			const response = await catalogAPI.lookupCatalogList(state.loadParams)
			commit('setList', response.data)
		},
		getFilter: async ({ commit }) => {
			const response = await catalogAPI.lookupFilter()
			commit('setFilter', response.data)
		},
	},
}
