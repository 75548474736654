<template>
	<tr class="table-item" @click="goToDetail">
		<td>
			<div>
				<span>
					{{ item.id }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span :class="['status', item.status.code]"> {{ item.status.value }} </span>
			</div>
		</td>
		<td>
			<div style="min-width: 450px; max-width: 650px">
				<span>
					{{ item.title }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.target.value }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ $DateTime.fromISO(item.startDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ $DateTime.fromISO(item.endDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ item.user?.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span>
					{{ $DateTime.fromISO(item.createDatetime).toFormat('yyyy.MM.dd T') }}
				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import MixinItem from '@/mixins/table/item'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [MixinItem],
	methods: {
		goToDetail() {
			this.$router.push({
				name: 'BannerDetail',
				params: {
					id: this.item.id,
				},
			})
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.status {
	padding: 0px 5px;
	font-size: 0.75rem;
	font-weight: 700;
	border-radius: 5px;
	height: 20px;
	color: #666;
	background-color: #eee;
	&.ING {
		color: #0dc577 !important;
		background-color: rgba(13, 197, 119, 0.15) !important;
	}
	&.DONE {
		color: #ff5746 !important;
		background-color: #ffeeed !important;
	}
}
</style>
