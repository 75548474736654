import brandAPI from '@/services/api/lms/brand'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			offset: 0,
			length: 50,
			withNoImage: null,
		},
		list: [],
		count: 0,
		checkedList: [],
		detail: {},
		productList: {},
		productSummary: {},
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
	},
	mutations: {
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setDetail: (state, data) => {
			state.detail = data
		},
		setProductList: (state, data) => {
			state.productList = data
		},
		setProductSummary: (state, data) => {
			state.productSummary = data
		},
		setPagination: (state, loadParams) => {
			state.loadParams.offset = loadParams.offset
			state.loadParams.length = loadParams.length
		},
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		initParams: state => {
			state.loadParams = {
				keyword: null,
				offset: 0,
				length: 50,
				withNoImage: null,
			}
		},
		setChecked: (state, id) => {
			state.checkedList.push(id)
		},
		removeChecked: (state, id) => {
			state.checkedList.splice(state.checkedList.indexOf(id), 1)
		},
		setAllCheck: state => {
			state.checkedList = []
			state.list.forEach(item => {
				state.checkedList.push(item)
			})
		},
		initCheckedList: state => {
			state.checkedList = []
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await brandAPI.list(state.loadParams)
			commit('setList', response.data)
		},
		getDetail: async ({ state, commit }, params) => {
			const response = await brandAPI.getDetail(params)
			commit('setDetail', response.data)
		},
		getProductList: async ({ state, commit }, params) => {
			const responseProductList = await brandAPI.getProductList(params)
			commit('setProductList', responseProductList.data)
		},
		getProductSummary: async ({ state, commit }, params) => {
			const responseProductSummary = await brandAPI.getProductSummary(params)
			commit('setProductSummary', responseProductSummary.data)
		},
		createBrand: async ({ commit }, body) => {
			return await brandAPI.createBrand(body)
		},
		editBrand: async ({ commit }, body) => {
			return await brandAPI.editBrand(body)
		},
		setPagination: async ({ commit, dispatch }, loadParams) => {
			commit('setPagination', loadParams)
			dispatch('getList')
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		mergeBrands: async ({ commit }, body) => {
			return await brandAPI.mergeBrands(body)
		},
	},
}
