const RoutePath = {
	// 주문 관리
	Order: {
		OrderProductManagement: '/orderProductManagement', // 주문 상품별 목록
		OrderProductManagementDetail: '/orderProductManagement/:id', // 주문번호 상세
		OrderItemList: '/orderItemList', // 주문 건별 목록
		OrderItemDetail: '/orderProductManagement/item/:id', // 주문 아이템 상세
		TransactionStatement: '/orderProductManagement/transactionStatement', // 거래명세서
	},
	// 상품 관리
	Product: {
		ProductList: '/productList',
		ProductListDetail: '/productList/detail/:id',
		ProductRegistration: {
			Main: '/product/registration',
			ProductInfo: 'productInfo',
			SellInfo: 'sellInfo',
			ShippingInfo: 'shippingInfo',
			ReturnInfo: 'returnInfo',
			ConsultingInfo: 'consultingInfo',
		},
		ApprovalProductList: '/approvalProductList',
		ApprovalProductDetail: '/approvalProductList/:id',
		UpdateProductPriceList: '/updateProductPriceList',
	},
	// 판매자 관리
	Seller: {
		SellerList: '/seller',
		SellerDetail: '/seller/:id',
		SettlementManagement: '/settlement',
		SettlementDetail: '/settlement/:id',
		// sellerInfo: `/sellerInfo`, // 판매자 정보
		// sellerInfoEdit: `/sellerInfoEdit`, // 판매자 정보 수정
		// addressManagement: `/addressManagement`, // 주소록 관리
		// customerConsultingTemplateManagement: `/customerConsultingTemplateManagement`, // 고객상담 템플릿 관리
		SellerLogin: '/sellerLogin', // 판매자 로그인
		SellerEntryRegistration: '/sellerEntryRegistration', // 판매자 입점 신청
	},
	Lms: {
		Banner: '/lms/banner',
		BannerCreate: '/lms/banner/create',
		BannerDetail: '/lms/banner/:id',
		BannerUpdate: '/lms/banner/update/:id',
		Event: '/lms/event',
		EventCreate: '/lms/event/create',
		EventDetail: '/lms/event/:id',
		EventEdit: '/lms/event/edit/:id',
		Notice: '/lms/notice',
		NoticeCreate: '/lms/notice/create',
		NoticeEdit: '/lms/notice/:id',
		Faq: '/lms/faq',
		FaqCreate: '/lms/faq/create',
		Brand: '/lms/brand',
		BrandDetail: '/lms/brand/:id',
		BrandCreate: '/lms/brand/create',
		BrandEdit: '/lms/brand/edit/:id',
	},
	Cs: {
		CancelRefund: '/cancelRefund',
		CancelRefundDetail: '/cancelRefund/:id',
		Inquiry: '/inquiry',
		InquiryDetail: '/inquiry/:id',
	},
	// 견적 관리
	Estimate: {
		RequestProductList: '/requestProduct', // 상품 요청 목록
		RequestProductDetail: '/requestProduct/:id', // 상품 요청 상세
		EstimateList: '/estimate', // 견적함
		EstimateDetail: '/estimate/:id', //견적함 상세
		EstimateForm: '/estimate/form/:mode', //견적함 작성 / 복제
		EstimateExport: '/estimate/sheet/export',
	},
	//구매자 관리
	Buyer: {
		BusinessRegistrationManagement: '/businessregistration',
		BusinessRegistrationDetail: '/businessregistration/:id',
		BuyerList: '/user',
		BuyerDetail: '/user/:id',
		LabList: '/lab',
		LabDetail: '/lab/:id',
		PaymentManagement: '/payment',
	},
	Lmp: {
		LmpEvent: '/lmp/event',
		LmpEventCreate: '/lmp/event/create',
		LmpEventDetail: '/lmp/event/:id',
		LmpEventEdit: '/lmp/event/edit/:id',
	},
	Institute: {
		LicenseCreate: '/institute/license/create',
	},
	ChemicalAPI: {
		Request: '/chemicalapi/request',
		RequestDetail: '/chemicalapi/request/:id',
		ProductCreate: '/chemicalapi/request/product/create/:id',
	},
}

export default RoutePath
