<template>
	<div class="wrapper">
		<div class="image-container" @click="$emit('clickImage', index)">
			<img :src="imageSrc" alt="image" />
			<button :class="{ represent: index === imageThumbnailIndex }" @click.stop="$emit('changeThumbnailImage', index)">대표</button>
		</div>
		<div class="image-trash-can">
			<img src="@/assets/svg/common/trash-can-icon.svg" alt="trash" @click="$emit('deleteImage', index)" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageItem',
	props: {
		image: {
			type: [File, Object, String],
		},
		index: {
			type: Number,
			default: 0,
		},
		imageThumbnailIndex: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		imageSrc() {
			if (!this.image) return ''
			if (this.image.constructor === String) return this.image
			if (this.image.constructor === Object) return this.image.url
			if (this.image) return URL.createObjectURL(this.image)
		},
	},
}
</script>

<style lang="scss" scoped>
.wrapper {
	.image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		height: 100px;
		border: 1px solid $INPUT_BORDER;
		border-radius: 8px;
		position: relative;
		z-index: 0;
		&:hover {
			button {
				display: block;
			}
		}
		&:hover::before {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			content: '';
			z-index: 1;
			background-color: rgba(0, 0, 0, 0.2);
		}
		img {
			width: 100px;
			height: 100px;
			border-radius: 8px;
		}
		button {
			display: none;
			width: 31px;
			height: 24px;
			position: absolute;
			top: 8px;
			left: 8px;
			font-size: 0.625rem;
			font-weight: 700;
			background: $COMMON_BACKGROUND;
			border-radius: 5px;
			z-index: 2;
			&:hover {
				color: $PRIMARY_YELLOW;
				background-color: $PRIMARY_YELLOW_200;
			}
			&.represent {
				display: block;
				color: $COLOR_111;
				background-color: $PRIMARY_YELLOW;
			}
		}
	}
	.image-trash-can {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100px;
		padding-top: 10px;
		img {
			cursor: pointer;
		}
	}
}
</style>
