<template>
	<div class="approval-product-detail">
		<div class="content-header">
			<div class="title">
				<h4>{{ item.name }}</h4>
				<div class="subtitle">
					<span class="status-text" :class="statusClass">{{ statusText }}</span>
				</div>
			</div>
			<div class="right-btn-group">
				<!-- <button v-if="item.status === 'WAIT' || item.status === 'REJECT'" class="btn" @click="onPreview">미리보기</button> -->
				<button v-if="item.status === 'WAIT'" class="Btn__export" @click="$bvModal.show('modal-select-reason')">반려</button>
				<button v-if="item.status === 'WAIT'" class="btn-confirm" @click="$bvModal.show('modal-common')">승인</button>
				<button v-if="item.status === 'APPROVE'" class="btn" @click="openLMS">상품 보러가기</button>
			</div>
		</div>
		<div class="content-wrapper">
			<info-list title="심사 정보" :list="auditInfo" />
			<info-list title="판매자 정보" :list="sellerInfo" />
			<info-list title="물품 정보" :list="itemInfo" />
			<info-list title="물품 상세정보" :list="detailInfo" />
			<info-list title="MSDS" :list="msdsInfo" />
			<info-list title="판매 상품 정보" :list="sellingInfo" @onClickButton="showDetailDescription" />
			<info-list title="가격 및 재고" :list="optionInfo" />
			<info-list title="배송 정보" :list="shippingInfo" />
			<info-list title="반품/환불 정보" :list="returnInfo" />
			<info-list title="고객상담 정보" :list="csInfo" />
		</div>

		<!--modals-->
		<modal-submit
			id="modal-common"
			title="상품 등록 승인"
			content="등록이 승인된 시점부터 랩매니저 스토어에서 판매가 시작됩니다.<br>승인된 상품은 상품 관리 > 상품 목록에서 관리할 수 있습니다."
			btnText="승인"
			@ok="onApproveApprovalProduct"
		/>
		<modal-select-reason title="반려 사유 선택" btnText="반려" placeholder="반려 상세사유를 입력해주세요" @ok="onRejectApprovalProduct" />
		<modal-detail-description ref="modal-detail-description" />
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import mixin from '@/mixins'
import Validation from '@/utils/Validation'
import PageName from '@/utils/define/PageName'
import { getMallURL } from '@/services/api/common'
import InfoList from '@/views/common/components/InfoList.vue'
import ModalSubmit from '@/views/common/modals/ModalSubmit'
import ModalSelectReason from '@/views/common/modals/ModalSelectReason'
import { ApprovalProduct } from '@/utils/define/ItemCode'
import ModalDetailDescription from '@/views/product/management/modal/ModalDetailDescription.vue'

export default {
	name: 'ApprovalProductDetail',
	mixins: [mixin],
	components: {
		InfoList,
		ModalSubmit,
		ModalSelectReason,
		ModalDetailDescription,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	computed: {
		...mapState('approval/detail', ['item']),
		statusClass() {
			return ApprovalProduct.statusToClass(this.item.status)
		},
		statusText() {
			return ApprovalProduct.statusToString(this.item.status)
		},
		//심사 정보
		auditInfo() {
			let list = []
			list.push({ title: '심사상태', value: this.isData(this.item.status), type: 'status' })
			list.push({
				title: '등록 신청일시',
				value: this.item.createDatetime ? this.$DateTime.fromISO(this.item.createDatetime).toFormat('yyyy-MM-dd T') : '-',
			})
			list.push({
				title: '심사 완료일시',
				value: this.item.confirmDatetime ? this.$DateTime.fromISO(this.item.confirmDatetime).toFormat('yyyy-MM-dd T') : '-',
				row: true,
			})
			if (this.item.status === 'REJECT') {
				list.push({ title: '반려 상세사유', value: this.item.rejectReasonDetail, row: true, isSingleRow: true })
			}
			return list
		},
		//판매자 정보
		sellerInfo() {
			return [
				{ title: '판매자 이름', value: this.item.seller?.name },
				{ title: '담당자', value: this.item.seller?.contactName },
				{ title: '연락처', value: Validation.autoHypenTel(this.item.seller?.contactPhone) },
				{ title: '이메일', value: this.item.seller?.email },
			]
		},
		//물품 정보
		itemInfo() {
			let categoryName = ''
			if (!this.$_.isEmpty(this.item.category?.parents)) {
				categoryName += `${this.item.category?.parents[0].korName}/`
			}
			if (this.item.category?.korName) {
				categoryName += `${this.item.category.korName}`
			}

			return [
				{ title: '카테고리', value: this.isData(categoryName) },
				{ title: '물품 이름', value: this.isData(this.item.name) },
				{ title: '서브네임', value: this.isData(this.item.subname) },
				{ title: '브랜드', value: this.isData(this.item.brand?.name) },
				{ title: '제품번호', value: this.isData(this.item.code) },
				{ title: 'CAS No', value: this.isData(this.item.casno) },
			]
		},
		//물품 상세 정보
		detailInfo() {
			return [
				{ title: '농도(밀도)', value: this.isData(this.item.density) },
				{ title: '분자량', value: this.item.molecularWeight ? `${this.item.molecularWeight}${this.item.molecularWeightUnit}` : '-' },
				{ title: 'MOL', value: this.item.molarMass ? `${this.item.molarMass}${this.item.molarMassUnit}` : '-' },
				{ title: '순도', value: this.item.assay ? `${this.item.assay}${this.item.assayUnit}` : '-' },
				{
					title: '보관온도',
					value: this.item.storageTemperature ? `${this.item.storageTemperature}${this.item.storageTemperatureUnit}` : '-',
				},
				{ title: '용해도', value: this.isData(this.item.solubility) },
				{ title: '분자식', value: this.item.molecularFormula ? `${this.item.molecularFormula}${this.item.molecularWeightUnit}` : '-' },
				{ title: '발화점', value: this.item.flashPoint ? `${this.item.flashPoint}${this.item.flashPointUnit}` : '-' },
				{ title: '끓는점', value: this.item.boilingPoint ? `${this.item.boilingPoint}${this.item.boilingPointUnit}` : '-' },
				{ title: '녹는점', value: this.item.meltingPoint ? `${this.item.meltingPoint}${this.item.meltingPointUnit}` : '-' },
			]
		},
		//MSDS
		msdsInfo() {
			let sellerMSDS = []
			if (!this.$_.isEmpty(this.item.msds?.seller)) {
				const seller = this.$_.cloneDeep(this.item.msds?.seller)
				const sellerSplitUrls = seller.url.split('/')
				seller.name = sellerSplitUrls[sellerSplitUrls.length - 1]
				sellerMSDS = [seller]
			}

			return [{ title: 'MSDS', value: sellerMSDS, type: 'file', row: true }]
		},
		//판매 상품 정보
		sellingInfo() {
			const tags = this.item.tags?.map(tag => {
				return { value: tag }
			})
			return [
				{ title: '이미지', value: this.item.images, type: 'image', row: true },
				{ title: '상세설명', value: '', text: '상세설명 보기', type: 'button', row: true },
				{
					title: '검색 태그',
					value: tags,
					type: 'tag',
					row: true,
				},
				{ title: '상품번호', value: this.item.productInfoId, row: true },
				{ title: '자체 관리번호', value: this.item.mngNo, row: true },
			]
		},
		//가격 및 재고
		optionInfo() {
			let list = []
			if (this.item.products?.length > 0) {
				this.item.products.forEach((product, index) => {
					let optionText = ''
					if (product.options?.length > 0) {
						product.options.forEach((option, index) => {
							optionText += `${index !== 0 ? '|' : ''} ${option.name}: ${option.value}`
						})
					}

					list.push({
						title: `옵션${index + 1}`,
						value: {
							title: this.isData(product.sku),
							size: optionText,
							subtitle: `재고 수량: ${this.isData(product.stock)} | 공급단가: ${this.isData(
								this.commaNum(product.salesUnitPrice)
							)}원 | 소비자가(단가): ${this.isData(this.commaNum(product.unitPrice))}원`,
						},
						row: true,
						type: 'option',
					})
				})
			}
			return list
		},
		//배송정보
		shippingInfo() {
			return [
				{
					title: '출고지',
					value: `[${this.isData(this.item.shippingAddress?.postcode)}] ${this.isData(this.item.shippingAddress?.address)} ${
						this.item.shippingAddress?.addressDetail
					}`,
					row: true,
				},
				{ title: '예상 출고일', value: this.item.shippingDay ? `${this.item.shippingDay}일 이내` : '-', row: true },
				{ title: '개별연락', value: this.item.shippingDirectContact ? '개별연락 필요' : '-', row: true },
				{ title: '퀵 서비스', value: this.item.shippingCanQuick ? '퀵 서비스 가능' : '-', row: true },
				{ title: '배송 안내', value: [this.item.shippingInfo], type: 'list', isSingleRow: true },
			]
		},
		//반품/환불 정보
		returnInfo() {
			return [
				{
					title: '반품지',
					value: `[${this.isData(this.item.returnAddress?.postcode)}] ${this.isData(this.item.returnAddress?.address)} ${
						this.item.returnAddress?.addressDetail
					}`,
					row: true,
				},
				{ title: '반품/환불 안내', value: [this.item.returnInfo], type: 'list', isSingleRow: true },
			]
		},
		//고객상담 정보
		csInfo() {
			return [
				{ title: '고객상담 연락처', value: this.item.cs?.tel ? Validation.autoHypenTel(this.item.cs?.tel) : '-', row: true },
				{ title: '상담 안내', value: [this.item.cs?.content], type: 'list', row: true },
			]
		},
	},
	created() {
		this.getDetail(this.id)
	},
	methods: {
		...mapActions('approval/list', ['approveApprovalProduct', 'rejectApprovalProduct']),
		...mapActions('approval/detail', ['getDetail']),
		showDetailDescription() {
			this.$refs['modal-detail-description'].show(this.item.detailHtml, this.item.images)
		},
		onPreview() {
			console.log('미리보기')
		},
		routeProductDetail() {
			this.$router.push({
				name: PageName.Product.ProductDetail,
				params: this.item.productInfoId,
			})
		},
		openLMS() {
			window.open(`${getMallURL()}/product/list/detail/${this.item.productInfoId}`, '_blank')
		},
		async onApproveApprovalProduct() {
			try {
				await this.approveApprovalProduct({
					productInfoAddRequestIds: [this.id],
				})
				this.getDetail(this.id)
				this.$root.toast('승인완료', '상품 등록신청을 승인했습니다.', 'success')
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, 'error')
			}
		},
		async onRejectApprovalProduct(rejectReasonDetail) {
			try {
				await this.rejectApprovalProduct({
					productInfoAddRequestIds: [this.id],
					rejectReasonDetail,
				})
				this.getDetail(this.id)
				this.$root.toast('반려완료', '상품 등록신청을 반려했습니다', 'success')
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, 'error')
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.approval-product-detail {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 90px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
			.subtitle {
				display: inline-flex;
				align-items: center;
				margin-top: 6px;
				p {
					font-size: 0.75rem;
					font-weight: 400;
					color: $COLOR_666;
					padding-left: 20px;
					position: relative;
					&.error {
						color: $PRIMARY_RED;
					}
					&:after {
						width: 1px;
						height: 12px;
						content: '';
						position: absolute;
						top: calc(50% - 6px);
						left: 10px;
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
		.right-btn-group {
			button {
				border-radius: 5px;
				width: 100px;
				height: 36px;
				justify-content: center;
				font-weight: 700;
				font-size: 0.875rem;
				&:first-child {
					position: relative;
					margin-right: 30px;
					&:after {
						position: absolute;
						width: 1px;
						height: 20px;
						top: calc(50% - 10px);
						right: -20px;
						content: '';
						background-color: $LINE_DIVIDER;
					}
				}
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px;
	}
}
</style>
