import buyerAPI from '@/services/api/buyer'
import cookies from 'vue-cookies'

export default {
	namespaced: true,
	state: {
		settlementList: {
			list: [
				{
					settleNo: '221007-1231231',
					status: 'cancel',
					settlePriceTotal: 10000,
					billingPriceTotal: 10000,
					benefit: 5000,
					benefitRate: 3,
					companyInfo: 'Sigma-Aldrich',
					billingDatetime: '2022-10-06T09:53:32.959Z"',
					confirmDatetime: '2022-10-06T09:53:32.959Z"',
					remittanceDatetime: '2022-10-06T09:53:32.959Z"',
				},
				{
					settleNo: '221007-1231232',
					status: 'approve',
					settlePriceTotal: 10000,
					billingPriceTotal: 10000,
					benefit: 5000,
					benefitRate: 3,
					companyInfo: 'Sigma-Aldrich',
					billingDatetime: '2022-10-06T09:53:32.959Z"',
					confirmDatetime: '2022-10-06T09:53:32.959Z"',
					remittanceDatetime: '2022-10-06T09:53:32.959Z"',
				},
				{
					settleNo: '221007-1231233',
					status: 'wait',
					settlePriceTotal: 10000,
					billingPriceTotal: 10000,
					benefit: 5000,
					benefitRate: 3,
					companyInfo: 'Sigma-Aldrich',
					billingDatetime: '2022-10-06T09:53:32.959Z"',
					confirmDatetime: '2022-10-06T09:53:32.959Z"',
					remittanceDatetime: '2022-10-06T09:53:32.959Z"',
				},
				{
					settleNo: '221007-1231234',
					status: 'complete',
					settlePriceTotal: 10000,
					billingPriceTotal: 10000,
					benefit: 5000,
					benefitRate: 3,
					companyInfo: 'Sigma-Aldrich',
					billingDatetime: '2022-10-06T09:53:32.959Z"',
					confirmDatetime: '2022-10-06T09:53:32.959Z"',
					remittanceDatetime: '2022-10-06T09:53:32.959Z"',
				},
			],
			count: 4,
		},
		settlementListSummary: {
			summary: {
				ALL: 10,
				B: 2,
				C: 2,
				D: 3,
				E: 3,
			},
		},
		settlementFilter: {
			filter: {
				sellers: [
					{
						name: '스마트잭',
						id: 'smarjack',
					},
				],
			},
		},
	},
	getters: {},
	mutations: {},
	actions: {
		lookupSettlementList: async ({ commit }, params) => {
			// const response = await orderAPI.lookupOrderListFilter(params)
			// commit('setOrderListFilter', response.data)
		},
		lookupSettlementListSummary: async ({ commit }, params) => {
			// const response = await orderAPI.lookupOrderListFilter(params)
			// commit('setOrderListFilter', response.data)
		},
		lookupSettlementListFilter: async ({ commit }, params) => {
			// const response = await orderAPI.lookupOrderListFilter(params)
			// commit('setOrderListFilter', response.data)
		},
	},
}
