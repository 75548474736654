import axiosInstance from '@/services/axios'

const endPoint = {
	lookupApprovalProductList: () => `admin/productInfo/request/list`,
	lookupApprovalProduct: id => `admin/productInfo/request?productInfoAddRequestId=${id}`,
	approveApprovalProduct: () => `admin/productInfo/request/approve`,
	rejectApprovalProduct: () => `admin/productInfo/request/reject`,
	lookupApprovalFilter: () => `admin/productInfo/request/list/filter`,
}

const api = {
	lookupApprovalProductList: params => axiosInstance.get(endPoint.lookupApprovalProductList(), { params }),
	lookupApprovalProduct: id => axiosInstance.get(endPoint.lookupApprovalProduct(id)),
	approveApprovalProduct: body => axiosInstance.patch(endPoint.approveApprovalProduct(), body),
	rejectApprovalProduct: body => axiosInstance.patch(endPoint.rejectApprovalProduct(), body),
	lookupApprovalFilter: params => axiosInstance.get(endPoint.lookupApprovalFilter(), { params }),
}

export default api
