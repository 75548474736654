<template>
	<div class="regist-wrapper">
		<div class="header">
			<h4>{{ title }}</h4>
			<page-status-list :list="pages" :currentPageNumber="currentPageNumber" />
		</div>
		<div class="body">
			<router-view ref="currentView" />
		</div>
		<div class="footer">
			<button class="btn Btn__white" @click="onClickPreview">미리보기</button>
			<div class="center">
				<button class="btn" :disabled="currentPageNumber === 1" @click="onClickPrev">이전</button>
				<button class="btn" :disabled="currentPageNumber === pageTotalCount" @click="onClickNext">다음</button>
			</div>
			<button class="btn Btn__complete" :disabled="currentPageNumber !== pageTotalCount" @click="onClickComplete">등록 완료</button>
		</div>

		<modal-success-propose
			ref="modal-success-propose"
			@hide="isShowSuccessProposeModal = false"
			:isShow="isShowSuccessProposeModal"
			:registeredProductId="registeredProductId"
			:isRequest="isRequest"
		></modal-success-propose>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ToastType } from '@/utils/define/ToastType'
import { MODE } from '@/utils/define/product'
import PageStatusList from '@/views/product/registration/components/PageStatusList.vue'
import ModalSuccessPropose from '@/views/product/registration/modals/ModalSuccessPropose.vue'

export default {
	name: 'ProductRegistration',
	components: {
		PageStatusList,
		ModalSuccessPropose,
	},
	data() {
		return {
			bodyEl: null,
			isShowSuccessProposeModal: false,
			mode: '',
			registeredProductId: 0,
			requestId: 0,
		}
	},
	computed: {
		...mapState('productRegist/page', ['pages', 'currentPageNumber']),
		...mapGetters('productRegist/page', ['pageTotalCount']),
		title() {
			switch (this.mode) {
				case MODE.REGIST:
					return '상품 등록하기'
				case MODE.EDIT:
					return '상품 수정하기'
				default:
					return ''
			}
		},
		isRequest() {
			return this.requestId ? true : false
		},
	},
	watch: {
		$route(to, from) {
			const fromIndex = this.pages.findIndex(page => page.pageName === from.name)
			const toIndex = this.pages.findIndex(page => page.pageName === to.name)
			if (fromIndex < 0 || toIndex < 0) return this.movePage(1)

			this.movePage(toIndex + 1)
		},
		currentPageNumber: {
			handler() {
				this.routePage(this.pages[this.currentPageNumber - 1])
			},
			immediate: true,
		},
	},
	created() {
		this.mode = this.$route.query.id ? MODE.EDIT : MODE.REGIST
		this.requestId = this.$route.query.requestId
		if (this.mode === MODE.EDIT) {
			// TODO: API 조회 this.$route.query.id
			this.pages.forEach(page => this.setPageStatus({ page, status: 'done' }))
		}
	},
	mounted() {
		this.bodyEl = document.querySelector('.regist-wrapper .body')
	},
	beforeDestroy() {
		this.init()
	},
	methods: {
		...mapMutations('productRegist/page', ['setPageStatus']),
		...mapActions('productRegist/page', ['initPage', 'movePage', 'movePrevPage', 'moveNextPage']),
		...mapActions('productRegist/regist', ['initInfo', 'addProduct', 'updateProduct']),
		init() {
			this.initPage()
			this.initInfo()
		},
		routePage(page) {
			this.$router.push({
				name: page.pageName,
			})
			if (this.bodyEl) this.bodyEl.scrollTo(0, 0)
		},
		onClickPreview() {
			console.log('onClickPreview')
		},
		onClickPrev() {
			this.movePrevPage()
		},
		onClickNext() {
			if (!this.$refs.currentView.valid()) return this.$root.toast('정보 입력 필요', '필수정보를 입력해주세요', ToastType.ERROR)

			this.setPageStatus({ page: this.pages[this.currentPageNumber - 1], status: 'done' })
			this.moveNextPage()
		},
		async onClickComplete() {
			try {
				if (this.mode === MODE.REGIST) {
					const response = await this.addProduct(this.$route.params.requestId)
					this.registeredProductId = response
					this.isShowSuccessProposeModal = true
				} else {
					await this.updateProduct(this.$route.params.requestId)
					this.$root.toast('상품 수정 완료', '상품 수정이 완료되었습니다.', ToastType.SUCCESS)
					// TODO: 상품 목록 상세 - 현재 화면 미개발
					// this.$router.push({
					// 	name: PageName.Product.ProductManagementDetail,
					// 	params: {
					// 		id: infoList[0].productInfoId,
					// 	},
					// })
				}
				this.init()
			} catch (e) {
				this.$root.toast('에러', `${e.response.data.msg}`, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.regist-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.header {
		position: sticky;
		display: flex;
		flex-direction: column;
		width: 100%;
		text-align: left;
		> h4 {
			display: flex;
			align-items: center;
			height: 55px;
			padding: 10px 30px;
			border-bottom: 1px solid $COLOR_EEE;
			font-weight: 700;
		}
	}
	.body {
		width: 100%;
		height: 100%;
		padding: 0 30px;
		overflow: auto;
		text-align: left;
	}
	.footer {
		position: sticky;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 16px 30px;
		border-top: 1px solid $COLOR_EEE;
		.btn {
			display: flex;
			justify-content: center;
			width: 160px;
			height: 40px;
		}
		.center {
			display: flex;
			gap: 16px;
			.btn {
				width: 80px;
			}
		}
	}
}
</style>
