import estimateAPI from '@/services/api/estimate/estimate'
import cookies from 'vue-cookies'
import { estimateTableAllColumns } from '@/utils/define/table/estimateTable'

export default {
	namespaced: true,
	state: {
		loadParams: {
			keyword: null,
			createType: JSON.parse(cookies.get('filter_estimate_createType')) ?? 'CMS',
			status: JSON.parse(cookies.get('filter_estimate_status')) ?? [''],
			isExpired: JSON.parse(cookies.get('filter_estimate_isExpired')) ?? false,
			expireStatus: JSON.parse(cookies.get('filter_estimate_expireStatus')) ?? [],
			writerIds: JSON.parse(cookies.get('filter_estimate_writerIds')) ?? [],
			offset: 0,
			length: 50,
			order: ['CREATE_DATETIME'],
			orderDirection: ['DESC'],
		},
		filter: null,
		filterCookieName: {
			expireStatus: 'filter_estimate_expireStatus',
			writer: 'filter_estimate_writer',
		},
		visibleColumns: JSON.parse(cookies.get('estimate_list_visible_column')) ?? estimateTableAllColumns,
		list: [],
		count: 0,
		scrollTop: 0,
	},
	getters: {
		keyword: state => state.loadParams.keyword,
		selectedCreateType: state => {
			return state.loadParams.createType
		},
		selectedStatus: state => {
			return state.loadParams.status[0]
		},
		order: state => state.loadParams.order[0],
		orderDirection: state => state.loadParams.orderDirection[0],
		filteredCount: state => state.loadParams.expireStatus.length + state.loadParams.writerIds.length,
	},
	mutations: {
		setLoadParams: (state, loadParams) => {
			Object.keys(loadParams).forEach(key => {
				state.loadParams[key] = loadParams[key]
			})
		},
		setList: (state, data) => {
			state.list = data.list
			state.count = data.count
		},
		setFilter: (state, data) => {
			data.expireStatus.forEach(status => {
				status.id = status.code
			})
			state.filter = data
		},
		setVisibleColumn: (state, data) => {
			state.visibleColumns = data
			cookies.set('estimate_list_visible_column', JSON.stringify(data))
		},
		setScrollTop: (state, value) => {
			state.scrollTop = value
		},
		initLoadParams: state => {
			state.loadParams = {
				keyword: null,
				createType: JSON.parse(cookies.get('filter_estimate_createType')) ?? 'CMS',
				status: JSON.parse(cookies.get('filter_estimate_status')) ?? [''],
				isExpired: JSON.parse(cookies.get('filter_estimate_isExpired')) ?? false,
				expireStatus: JSON.parse(cookies.get('filter_estimate_expireStatus')) ?? [],
				writerIds: JSON.parse(cookies.get('filter_estimate_writerIds')) ?? [],
				offset: 0,
				length: 50,
				order: ['CREATE_DATETIME'],
				orderDirection: ['DESC'],
			}
		},
		initScrollTop: state => {
			state.scrollTop = 0
		},
	},
	actions: {
		getList: async ({ state, commit }) => {
			const response = await estimateAPI.lookupEstimateList(state.loadParams)
			commit('setList', response.data)
		},
		setLoadParams: async ({ commit, dispatch }, loadParams) => {
			commit('setLoadParams', loadParams)
			dispatch('getList')
		},
		getFilter: async ({ commit }) => {
			const response = await estimateAPI.lookupEstimateFilter()
			commit('setFilter', response.data)
		},
	},
}
