<template>
	<div ref="wrapper" class="image-wrapper">
		<h5>{{ title }}</h5>
		<div v-if="defaultForm.image || defaultImageUrl" class="image-box" :style="{ width: `${calcWidth}px`, height: `${calcHeight}px` }">
			<img :src="imageSrc" />
			<button class="close-btn" @click="deleteFile">
				<img src="@/assets/svg/icon-trash.svg" />
			</button>
		</div>
		<div v-else class="drag-area" :style="{ width: `${calcWidth}px`, height: `${calcHeight}px` }">
			<input ref="image-file" type="file" accept=".jpg, .jpeg, .png" @drop="dropFile" @change="uploadImage" />
			<h2>+</h2>
			<h5>{{ title }}</h5>
			<p>여기에 이미지를 드로그 앤 드랍 하세요</p>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		defaultForm: {
			type: Object,
			default: () => {
				return {
					type: null,
					image: null,
					link: null,
					width: 3200,
					height: 800,
				}
			},
		},
		defaultImage: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			defaultImageUrl: null,
		}
	},
	computed: {
		imageSrc() {
			if (this.defaultForm.image?.constructor === Object) return this.defaultForm.image.url
			else if (this.defaultForm.image) return URL.createObjectURL(this.defaultForm.image)
			else if (this.defaultImageUrl) return this.defaultImageUrl
			return null
		},
		calcWidth() {
			if (this.defaultForm.width >= 1600) return 1050
			else if (this.defaultForm.width >= 1000) return 500
			return 375
		},
		calcHeight() {
			if (this.defaultForm.height) {
				return (this.calcWidth / this.defaultForm.width) * this.defaultForm.height
			}
			return 1000
		},
	},
	mounted() {
		this.defaultImageUrl = this.defaultImage
	},
	methods: {
		uploadImage() {
			const image = Object.values(this.$refs['image-file'].files)[0]
			const form = this.$_.cloneDeep(this.defaultForm)
			form.image = image
			this.$refs['image-file'].value = ''
			if (this.defaultImage) {
				this.defaultImageUrl = null
				this.$emit('updateImage', form)
			} else this.$emit('changeImage', form)
		},
		dropFile(event) {
			event.preventDefault()
			if (event.dataTransfer.files) {
				this.$refs['image-file'].files = event.dataTransfer.files
				this.uploadImage()
			}
		},
		deleteFile() {
			if (!this.defaultImage) {
				const form = this.$_.cloneDeep(this.defaultForm)
				form.image = null
				this.$emit('changeImage', form)
			} else {
				this.defaultImageUrl = null
				this.$emit('updateImage', this.defaultForm)
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.image-wrapper {
	display: inline-block;
	h5 {
		font-size: 14px;
		font-weight: 700;
		color: #111;
		margin-bottom: 10px;
	}
	.image-box {
		position: relative;
		border: 1px solid #ddd;
		border-radius: 5px;
		background-color: #f5f5f5;
		max-height: 1000px;
		text-align: center;
		img {
			border-radius: 5px;
			max-width: 100%;
			max-height: 100%;
		}
		.close-btn {
			left: 8px;
			top: 8px;
			position: absolute;
			width: 30px;
			height: 30px;
			background-color: #f5f5f5;
			border-radius: 4px;
			border: 1px solid #ddd;
		}
	}
	.drag-area {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		background: #f5f5f5;
		border: 1px solid #ddd;
		border-radius: 5px;
		& > * {
			color: #999;
		}
		input {
			width: 100%;
			height: 100%;
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			padding: 0 0;
			cursor: pointer;
			&.isFile {
				visibility: hidden;
			}
		}
	}
	.error {
		border: solid 1px #ff5746;
	}
	margin-bottom: 30px;
}
.link {
	width: 100%;
	display: block;
	margin-top: 20px;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 14px;
	height: 36px;
	color: #111;
	word-break: break-all;
	font-weight: 400;
	overflow: hidden;
	padding: 8px 12px;
}
.link-sub {
	font-size: 12px;
	color: #999;
	margin-top: 10px;
}
</style>
