<template>
	<div class="button-include-header">
		<div class="title-area">
			<h2 class="title">
				{{ title }}
			</h2>
			<div class="subtitle" v-if="status || subtitle">
				<b v-if="status" :class="status">{{ infoStatus }}</b>
				<p v-html="subtitle"></p>
			</div>
		</div>
		<div class="btn-area" :class="page">
			<button class="btn" v-if="prevBtn" :class="prevBtn.category" @click="$emit('prevClickEv')">
				<img v-if="prevBtn.image" :src="prevBtn.image" />
				<span>{{ prevBtn.name }}</span>
			</button>

			<button class="btn" v-if="nextBtn" :class="nextBtn.category" @click="$emit('nextClickEv')">
				<img v-if="nextBtn.image" :src="nextBtn.image" />
				<span>{{ nextBtn.name }}</span>
			</button>

			<button class="btn" v-if="lastBtn" :class="lastBtn.category" @click="$emit('lastClickEv')">
				<img v-if="lastBtn.image" :src="lastBtn.image" />
				<span>{{ lastBtn.name }}</span>
			</button>
		</div>
	</div>
</template>

<script>
// import { DraftCode } from '@/utils/define/jw/JwCode'

export default {
	name: 'ButtonIncludeHeader',
	props: {
		page: {
			type: String,
			default: '',
		},
		title: {
			type: String,
			default: '',
		},
		infoStatus: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		status: {
			type: String,
			default: '',
		},
		prevBtn: {
			type: Object,
			default: () => {},
		},
		nextBtn: {
			type: Object,
			default: () => {},
		},
		lastBtn: {
			type: Object,
			default: () => {},
		},
		// 지출마감 상세
		isSettle: {
			type: Boolean,
			default: false,
		},
	},
	computed: {},
	data() {
		return {}
	},
	created() {},
	methods: {},
}
</script>

<style lang="scss">
.button-include-header {
	width: 100%;
	padding: 8px 30px;
	min-height: 54px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
	border-bottom: 1px solid $LINE_DIVIDER;
	top: 60px;
	background: white;
	z-index: 200;
	.title {
		font-size: 1.5rem;
		font-weight: bold;
		color: $PRIMARY_BLACK;
		text-align: left;
		align-items: center;
		height: auto;
		// display: inline-flex;
	}
	.subtitle {
		display: flex;
		font-size: 0.75rem;
		color: $COLOR_666;
		margin-top: 6px;
		b {
			height: 20px;
			padding: 0 5px;
			border-radius: 5px;
			font-weight: 700;
			text-align: center;
			display: inline-block;
			margin-right: 10px;
			background-color: $JW_200;
			color: $PRIMARY_YELLOW;
			&.ING {
				background-color: $LINE_DIVIDER;
				color: $COLOR_666;
			}
			&.ACCEPT {
				background-color: rgba(13, 197, 119, 0.15);
				color: $PRIMARY_GREEN;
			}
			&.REJECT {
				background-color: $COLOR_BACKGROUND_RED;
				color: $PRIMARY_RED;
			}
		}
		p {
			span {
				font-weight: 400;
				color: $COLOR_666;
				padding-right: 16px;
				position: relative;
				&:nth-last-child(2) {
					&:before {
						width: 1px;
						height: 12px;
						background-color: $LINE_DIVIDER;
						position: absolute;
						top: 4px;
						right: 8px;
						content: '';
					}
				}
			}
		}
		h2 {
			font-weight: 400;
		}
	}
	.btn-area {
		display: inline-flex;
		margin-left: auto;
		.btn {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 36px;
			border-radius: 5px;
		}
		&.disabled {
			pointer-events: none;
			background-color: $COLOR_EEE;
			color: #555;
			opacity: 1;
		}
	}
}
</style>
