<template>
	<b-modal
		id="modal-select-reason"
		centered
		:title="title"
		:ok-title="btnText"
		ok-variant="warning"
		cancel-title="취소"
		cancel-variant="default"
		ref="modal-select-reason"
		size="md"
		@ok="submit"
	>
		<div class="content-box">
			<div class="flex">
				<p class="title">반려 상세사유</p>
				<button @click="content = ''">모두 지우기</button>
			</div>
			<textarea v-model="content" placeholder="반려 상세사유를 입력해주세요"></textarea>
		</div>
	</b-modal>
</template>

<script>
export default {
	name: 'ModalSelectReason',
	props: {
		title: {
			type: String,
			default: '',
		},
		btnText: {
			type: String,
			default: '확인',
		},
	},
	data() {
		return {
			content: '',
		}
	},
	methods: {
		submit() {
			this.$emit('ok', this.content)
		},
	},
}
</script>

<style lang="scss">
#modal-select-reason {
	.modal-content {
		.modal-body {
			padding: 0 20px;
		}
		.title {
			font-size: 0.75rem;
			font-weight: 400;
		}
		.radio-option-box {
			.title {
				margin-bottom: 8px;
			}
			.option {
				margin-bottom: 8px;
			}
			label {
				display: inline-block;
				font-size: 0.875rem;
				font-weight: 700;
				margin-left: 10px;
			}
		}
		.content-box {
			margin-top: 12px;
			.flex {
				width: 100%;
				display: flex;
				align-items: center;
				button {
					margin-left: auto;
					font-size: 0.75rem;
					color: $COLOR_999;
					font-weight: 700;
				}
			}
			textarea {
				width: 100%;
				height: 150px;
				border: 1px solid $COLOR_CCC;
				border-radius: 5px;
				padding: 10px;
				font-size: 0.875rem;
				margin-top: 8px;
			}
		}
		.modal-footer {
			flex-wrap: unset;
			justify-content: end;
			border-top: 0;
			button {
				justify-content: center;
				min-width: 100px;
				width: 100px;
				height: 30px;
				font-size: 0.875rem;
				border-radius: 5px;
				border-width: 1px;
			}
		}
	}
}
</style>
