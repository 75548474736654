<template>
	<portal to="lm-modal">
		<div class="Popup__depth2" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container Popup__size-sm">
				<div class="Popup__top">
					<h5 class="Popup__title">이미지 미리보기</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<img :src="imageSrc" alt="image" />
				</div>
				<div class="Popup__bottom">
					<div class="Popup__btn-group">
						<button class="Btn__white" @click="hide">닫기</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import MixinModal from '@/mixins/modal'

export default {
	name: 'ModalImagePreview',
	mixins: [MixinModal],
	data() {
		return {
			image: {},
		}
	},
	computed: {
		imageSrc() {
			if (this.image.constructor === Object) return this.image.url
			if (this.image) return URL.createObjectURL(this.image)

			return ''
		},
	},
	methods: {
		show(image) {
			this.isShow = true
			this.image = image
		},
	},
}
</script>

<style lang="scss" scoped>
.Popup__container {
	width: 700px;
	max-height: 800px;
	.Popup__content {
		img {
			width: 100%;
			height: 100%;
			border: 1px solid $LINE_DIVIDER;
		}
	}
}
</style>
