<template>
	<div class="list-wrapper" v-if="list.length > 0">
		<div class="title-wrapper">
			<h5>{{ title }}</h5>
			<button @click="reset">{{ '초기화' }}</button>
		</div>
		<div class="check-list">
			<label class="check-item"
				><input class="checkbox" type="checkbox" @change="checkedAll" :checked="checkedList.length == list.length" />{{ '전체선택' }}</label
			>
			<label class="check-item" v-for="item in showingList" :key="item.id" v-b-tooltip.hover="item.name">
				<input class="checkbox" type="checkbox" @change="checked(item.id)" :checked="checkedList.includes(item.id)" /> {{ item.name }}
			</label>
		</div>
		<div class="show-all" :class="{ open: isShowAll }" v-if="list.length >= 9" @click="isShowAll = !isShowAll">
			{{ isShowAll ? '접기' : '모두보기' }}
		</div>
	</div>
</template>
<script>
export default {
	props: {
		title: {
			type: String,
		},
		list: {
			type: Array,
			default: () => [],
		},
		filterCookieName: {
			type: String,
		},
	},
	data() {
		return {
			checkedList: [],
			isShowAll: false,
		}
	},
	computed: {
		showingList() {
			if (this.isShowAll) return this.list
			else return this.list.slice(0, 9)
		},
	},
	created() {
		if (this.filterCookieName) this.checkedList = JSON.parse(this.$cookies.get(this.filterCookieName)) ?? []
	},
	methods: {
		reset() {
			this.checkedList = []
		},
		checkedAll() {
			if (this.checkedList.length == this.list.length) this.checkedList = []
			else this.checkedList = this.list.map(it => it.id)
		},
		checked(id) {
			if (this.checkedList.includes(id)) this.checkedList.remove(id)
			else this.checkedList.push(id)
		},
		setCookies() {
			if (this.filterCookieName == null) return
			this.$cookies.set(this.filterCookieName, JSON.stringify(this.checkedList))
		},
	},
}
</script>
<style scoped lang="scss">
.list-wrapper {
	// padding: 25px 20px;
	border-bottom: 1px solid #eee;
	padding-bottom: 30px;
	margin-bottom: 30px;
	.title-wrapper {
		display: flex;
		align-items: center;
		h5 {
			font-size: 16px;
		}
		button {
			justify-content: center;
			margin-left: auto;
			width: 60px;
			height: 30px;
			border-radius: 4px;
			background-color: #f2f3f4;
			color: #111;
			font-size: 14px;
			font-weight: 700;
		}
	}
	.check-list {
		width: 100%;
		margin-top: 10px;
		display: inline-flex;
		flex-wrap: wrap;
		gap: 5px;
		.check-item {
			line-height: 21px;
			cursor: pointer;
			padding-left: 2px;
			align-items: center;
			display: flex;
			gap: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			font-size: 14px;
			flex-basis: calc((100% - 10px) / 3);
		}
	}
	.show-all {
		width: fit-content;
		position: relative;
		cursor: pointer;
		font-weight: 700;
		color: #0b6cff;
		font-size: 14px;
		margin-top: 10px;
		&::after {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			right: -18px;
			top: 3px;
			background-image: url(~@/assets/svg/app/arrow-b.svg);
			opacity: 0.8;
			scale: 0.8;
		}
		&.open::after {
			transform: rotate(180deg);
		}
	}
}
</style>
