export default {
	computed: {
		statusText() {
			if (this.item.status == 'SHOWING') return '노출'
			else if (this.item.status == 'WAIT') return '대기'
			return '종료'
		},
		targetToText() {
			return this.item.target
				.map(it => {
					if (it == 'UNIVERSITY') return '대학교'
					else if (it == 'GOV') return '정출연'
					else if (it == 'COMPANY') return '기업'
					return '전체'
				})
				.join(', ')
		},
	},
}
