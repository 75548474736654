// import { COOKIES_DOMAIN } from '@/services/api/common'
import userAPI from '@/services/api/user'
// import cookies from 'vue-cookies'

// cookies.config({
// 	domain: COOKIES_DOMAIN,
// })

export default {
	namespaced: true,
	state: () => ({
		isLogin: false,
		user: {},
		registUserInfo: {},
		userList: [],
	}),
	getters: {
		getUser: state => {
			return state.user
		},
		getRegistUserInfo: state => {
			return state.registUserInfo
		},
	},
	mutations: {
		setUser: (state, payload) => {
			state.user = payload
		},
		setIsLogin: (state, isLogin) => {
			state.isLogin = isLogin
		},
		setRegistUserInfo: (state, payload) => {
			state.registUserInfo = payload
		},
		setUserList: (state, payload) => {
			state.userList = payload
		},
	},
	actions: {
		login: async ({ commit }, body) => {
			return await userAPI.login(body)
		},
		logout: async () => {
			return await userAPI.logout()
		},
		lookupUserInfo: async ({ commit }, payload) => {
			const response = await userAPI.lookupUserInfo(payload)
			commit('setUser', response.data)
			return response.data
		},
		lookupEmailValidation: async ({ commit }, params) => {
			return await userAPI.lookupEmailValidation(params)
		},
		applyEnterSeller: async ({ commit }, payload) => {
			return await userAPI.applyEnterSeller(payload)
		},
		lookupUserList: async ({ commit }, payload) => {
			const response = await userAPI.lookupUserList(payload)
			commit('setUserList', response.data.list)
		},
	},
}
