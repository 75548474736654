<template>
	<div :class="{ open: isOpen }">
		<ul class="sub-menu" v-if="list.length > 0">
			<li
				class="sub-item"
				:class="{ active: indexOfPath(item.path) }"
				v-for="(item, index) in list"
				:key="`sub_${index}`"
				@click="onClickMenu(item)"
			>
				<p>{{ item.name }}</p>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import PageName from '@/utils/define/PageName'

export default {
	name: 'AppSideMenuSub',
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		isOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			executeAnotherEventPaths: ['/estimate/form'],
		}
	},
	computed: {
		...mapState('common', ['isCompleteAnotherEvent']),
	},
	methods: {
		...mapMutations('common', ['setIsCompleteAnotherEvent']),
		onClickMenu(item) {
			if (!this.$route.path.includes(this.executeAnotherEventPaths)) {
				item.modal ? this.showSetting() : this.goPage(item)
			} else {
				const interval = setInterval(() => {
					if (this.isCompleteAnotherEvent) {
						item.modal ? this.showSetting() : this.goPage(item)
						this.setIsCompleteAnotherEvent(false)
						clearInterval(interval)
					}
				}, 150)
			}
		},
		goPage(path) {
			if (path.routeName === PageName.Product.ProductRegistration.Main)
				this.$router.push({ name: PageName.Product.ProductRegistration.ProductInfo, params: path.params })
			else this.$router.push({ name: path.routeName, params: path.params })
		},
		indexOfPath(path) {
			if (path) {
				let routePath = path.replace('/:instituteId', '').replace('/:type', '')

				return this.$route.path.indexOf(routePath) >= 0
			}
		},
		showSetting() {
			this.$emit('showSetting')
		},
	},
}
</script>

<style lang="scss" scoped>
div {
	box-sizing: border-box;
	max-height: 0;
	overflow: hidden;
	&.open {
		max-height: 100vh;
		height: 100%;
	}
	.sub-menu {
		width: 100%;
		background-color: $SIDEBAR_DEPTH;
		text-align: left;
		box-sizing: border-box;
		padding: 10px;
		padding-left: 32px;
		background-color: $SIDEBAR_DEPTH;
		li {
			display: flex;
			align-items: center;
			width: 100%;
			height: 26px;
			border-radius: 5px;
			transition: all ease-in-out 0.3s;
			&:hover {
				background-color: $SIDEBAR_SUB_HOVER;
			}
			&.active {
				p {
					color: black;
					font-weight: 700;
					&:before {
						background-color: black;
					}
				}
			}
			p {
				padding-left: 25px;
				position: relative;
				cursor: pointer;
				font-size: 0.875rem;
				width: 100%;
				box-sizing: border-box;
				color: $COLOR_333;
				&:before {
					width: 4px;
					height: 4px;
					border-radius: 4px;
					background-color: $COLOR_999;
					content: '';
					position: absolute;
					top: 9px;
					left: 8px;
				}
			}
			input[type='checkbox'] {
				margin-left: auto;
				position: relative;
				transform: rotate(360deg);
				transition: all 0.3s;
				height: 24px;
				&:before {
					border: 0;
					border-radius: 0;
					background-color: $SIDEBAR_DEPTH;
					height: 24px;
				}
				&:after {
					position: absolute;
					top: 4px;
					left: 0;
					content: '';
					background-image: url(~@/assets/svg/app/sidebar-arrow.svg);
					background-size: 16px;
					background-repeat: no-repeat;
					background-position: center;
					z-index: 1;
					width: 16px;
					height: 16px;
					background-color: $SIDEBAR_DEPTH;
					border: 0;
					border-radius: 0;
				}
				&:checked {
					transform: rotate(180deg);
					&:before {
						background-color: $SIDEBAR_DEPTH;
						margin-top: 0;
						margin-left: -1px;
					}
					&:after {
						background-image: url(~@/assets/svg/app/sidebar-arrow.svg);
						background-color: $SIDEBAR_DEPTH;
						border-color: $SIDEBAR_DEPTH;
					}
				}
			}
		}
	}
}
</style>
