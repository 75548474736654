<template>
	<portal to="lm-modal">
		<div class="modal-order-cancel-wrapper" v-if="isShow">
			<div class="Popup__background"></div>
			<div class="Popup__container">
				<div class="Popup__top">
					<h5 class="Popup__title">{{ info.title }}</h5>
					<button class="Popup__close-btn" @click="hide">
						<img src="@/assets/svg/app/popup-close.svg" alt="close" />
					</button>
				</div>
				<div class="Popup__content">
					<div class="explanation" v-html="info.text"></div>
					<div class="cancel-reason-detail-wrapper">
						<div class="title-wrapper">
							<div class="title">{{ info.textAreaTitle }}</div>
							<div class="delete" @click="clear">모두 지우기</div>
						</div>
						<div class="textarea-wrapper">
							<textarea v-model="reason" :placeholder="info.placeholder"></textarea>
						</div>
					</div>
				</div>
				<div class="Popup__bottom">
					<div class="Popup__complete-btn-group Popup__btn-group">
						<button class="Btn__close" @click="hide">취소</button>
						<button class="Btn__delete" @click="complete">{{ info.btnText }}</button>
					</div>
				</div>
			</div>
		</div>
	</portal>
</template>

<script>
import { ToastType } from '@/utils/define/ToastType'
import { mapActions } from 'vuex'

export default {
	name: 'ModalCancelRefunReject',
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
		info: {
			type: Object,
			default: () => {},
		},
	},
	watch: {
		isShow() {
			Object.assign(this.$data, this.$options.data.call(this))
		},
	},
	data() {
		return {
			reason: '',
		}
	},
	methods: {
		...mapActions('cancelRefund/detail', ['setReturnReject', 'setCancelReject', 'getDetail']),
		hide() {
			this.$emit('hide')
		},
		clear() {
			this.reason = ''
		},
		async complete() {
			if (this.reason === '') return this.$root.toast('에러', '상세사유를 입력해주세요.', ToastType.ERROR)
			try {
				if (this.info.type === 'CANCEL') {
					await this.setCancelReject({
						id: this.info.id,
						cancelResponse: this.reason,
					})
				} else {
					await this.setReturnReject({
						id: this.info.id,
						returnResponse: this.reason,
					})
				}
				this.$root.toast('요청 거절 완료', '상품 취소를 거절 했습니다.', ToastType.SUCCESS)
				await this.getDetail(this.info.id)
				this.hide()
			} catch (error) {
				this.$root.toast('에러', error.response.data.msg, ToastType.ERROR)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.modal-order-cancel-wrapper {
	.Popup__container {
		.Popup__content {
			width: 500px;
			.explanation {
				font-size: 0.875rem;
			}

			.cancel-reason-detail-wrapper {
				margin-top: 20px;
				.title-wrapper {
					font-size: 0.75rem;
					display: flex;
					justify-content: space-between;
					.delete {
						color: $FONT_PLACEHOLDER;
						font-weight: 700;
						cursor: pointer;
					}
				}
				.textarea-wrapper {
					margin-top: 8px;
					textarea {
						width: 100%;
						height: 150px;
						border: 1px solid #cccccc;
						border-radius: 5px;
						padding: 10px;
						font-size: 0.875rem;
					}
				}
			}
		}
	}
}
</style>
