const common = {
	namespaced: true,
	state: {
		listKeyword: '',
		isCompleteAnotherEvent: false,
	},
	getters: {},
	mutations: {
		setKeyword: (state, payload) => {
			state.listKeyword = payload
		},
		setIsCompleteAnotherEvent: (state, payload) => {
			state.isCompleteAnotherEvent = payload
		},
	},
	actions: {},
}

export default common
