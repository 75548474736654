<template>
	<div class="detail-wrapper" ref="wrapper" v-if="item && item.id == id">
		<div class="common-header-wrapper">
			<div>
				<h2>물품 요청 상세</h2>
				<h6>
					<span :class="['status', item.status]"> {{ item.status }} </span>
					<p>요청일시: {{ $DateTime.fromISO(item.create_datetime, { setZone: true }).toFormat('yyyy.MM.dd T') }}</p>
				</h6>
			</div>
			<div class="button-groups">
				<base-button color="primary" size="md" @click="createProduct">물품 등록</base-button>
				<!-- <base-button color="primary" size="md" @click="$refs['ModalProductAdd'].show(item)">기존 물품 등록</base-button> -->
			</div>
		</div>
		<div class="common-detail-wrapper">
			<info-list title="요청 정보" :list="contentInfo" style="margin-bottom: 20px" />
			<info-list v-if="item.status != 'WAIT'" title="응답 정보" :list="replyInfo" style="margin-bottom: 20px" />
		</div>
		<ModalProductDetail ref="ModalProductDetail" />
		<ModalProductAdd ref="ModalProductAdd" @confirm="getDetail(id)" />
	</div>
</template>

<script>
import InfoList from '@/views/common/components/InfoList.vue'
import { mapActions, mapState } from 'vuex'
import BaseButton from '@/views/common/components/button/BaseButton.vue'
import ModalProductDetail from '../modal/ModalProductDetail.vue'
import ModalProductAdd from '../modal/ModalProductAdd.vue'
export default {
	components: {
		BaseButton,
		InfoList,
		ModalProductDetail,
		ModalProductAdd,
	},
	props: {
		id: {
			type: [String, Number],
		},
	},
	computed: {
		...mapState('chemicalApi/request', ['item']),
		comment() {
			switch (this.item.comment) {
				case 'A':
					return 'A: [중복요청] 조회가능한 제품을 등록 요청'
				case 'B':
					return 'B: [정보불일치] 신규 화학제품 용량 부정확'
				case 'C':
					return 'C: [정보불일치] 신규 화학제품 정보 부정확(카탈로그번호, 제조사, 제품명)'
				case 'Z':
					return 'Z: [확인필요] 그 외 사유'
				default:
					return this.item.comment ?? '-'
			}
		},
		contentInfo() {
			return [
				{ title: '물품 이름', row: true, value: this.item.productName },
				{ title: '제조사', row: true, value: this.item.productMaker },
				{ title: '제조번호', row: true, value: this.item.productCode },
				{ title: 'CAS No.', row: true, value: this.item.productCasno },
				{ title: '용량', row: true, value: this.item.productVolume },
				{
					title: '요청 일시',
					row: true,
					value: this.$DateTime.fromISO(this.item.create_datetime, { setZone: true }).toFormat('yyyy.MM.dd T'),
				},
				{ title: 'MSDS', type: 'link', value: this.item.file, clickFn: this.msdsPopup, row: true },
			]
		},
		replyInfo() {
			return [
				{ title: '요청 상태', value: this.item.status },
				{ title: '메모/거절사유', value: this.comment },
				{ title: '매칭된 물품 ID', type: 'link', value: this.item.itemId, clickFn: this.productDetail },
			]
		},
	},
	async created() {
		await this.getDetail(this.id)
	},
	methods: {
		...mapActions('chemicalApi/request', ['getDetail']),
		msdsPopup() {
			window.open(
				'https://msds.lab-manager.com/v/u?url=' + encodeURIComponent(this.item.file),
				'_blank',
				'width=800, height=1000, left=300, top=50'
			)
			return false
		},
		productDetail() {
			this.$refs.ModalProductDetail.show(this.item.itemId)
		},
		createProduct() {
			this.$router.push({
				name: 'ChemicalAPIRequestProductCreate',
				params: {
					id: this.item.id,
				},
			})
			// this.$refs.ModalProductDetail.show(this.item.itemId)
		},
	},
}
</script>
<style lang="scss" scoped>
@import '@/styles/scss/global/_detail.scss';
.sm-table {
	min-height: 0 !important;
}
.reply {
	h6 {
		font-weight: 700;
		font-size: 20px;
		span {
			font-weight: 500;
			font-size: 14px;
			color: #666666;
			padding-left: 10px;
		}
	}
	textarea {
		width: 100%;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		min-height: 80px;
		padding: 8px 10px;
		font-size: 0.875rem;
	}
}
.btn-wrapper {
	display: flex;
	justify-content: center;
	gap: 20px;
	button {
		width: 200px;
		height: 48px;
		font-size: 16px;
		font-weight: 700;
		justify-content: center;
	}
	.cancel {
		background: #f2f3f4;
		border-radius: 5px;
	}
	.complete {
		background: #fec741;
		border-radius: 5px;
	}
}
</style>
