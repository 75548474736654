<template>
	<div class="payment-management">
		<div class="content-header">
			<div class="title">
				<h4>결제 관리</h4>
			</div>
		</div>
		<div class="content-wrapper">
			<status-counter-list :list="statusCounterList" @active="onClickStatus" />
			<detail-search-box
				class="search-box"
				title="상세검색"
				subTitle="조회할 항목을 검색하거나 선택할 수 있습니다. (,로 여러개 검색 가능)"
				searchPlaceholder="주문자, 주문상세 ID,  상품명, 브랜드로 검색"
				:datePlaceholder="datePlaceholder"
				:datePickerList="datePickerList"
				:filters="filterList"
				:useFold="true"
				@searchTextList="searchTextList"
				@searchCheckList="searchCheckList"
			/>
			<div class="order-wrapper">
				<div class="flex">
					<search-title
						:title="`결제 목록(${count})`"
						subTitle="항목을 선택 한 후 아래 결제상태를 클릭하여 결제상태를 변경할 수 있습니다."
					/>
					<button class="btn">
						<img src="@/assets/svg/common/excel-download.svg" />
						목록 다운로드
					</button>
				</div>
				<payment-status-changer :options="paymentStatusOptions" :selectedList="checkedList" placeholder="결제상태 선택" @refresh="loadData" @uncheckAll="uncheckAll" />
				<div class="table-wrap">
					<detail-table :headers="paymentHeaders" isCheck :allChecked="allChecked" @toggle="changeAllChecked">
						<template>
							<tbody>
								<table-item
									v-for="(item, index) in list"
									:key="`payment-row-${index}`"
									:item="item"
									:checked="checkedList.some(it => it.orderItemId == item.orderItemId)"
									@refresh="loadData"
									@check="changeCheckedList"
								/>
							</tbody>
						</template>
					</detail-table>
				</div>
				<common-pagenation :total="count" @page="getCurrentPage" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import StatusCounterList from '@/views/order/orderProduct/components/StatusCounterList.vue'
import DetailSearchBox from '@/views/common/components/search/DetailSearchBox.vue'
import SearchTitle from '@/views/common/components/search/SearchTitle.vue'
import CommonPagenation from '@/views/common/components/CommonPagenation.vue'
import DetailTable from '@/components/table/DetailTable'
import TableItem from '@/views/buyer/payment/components/TableItem'
import PaymentStatusChanger from '@/views/buyer/payment/components/PaymentStatusChanger'

export default {
	name: 'PaymentManagement',
	components: {
		StatusCounterList,
		DetailSearchBox,
		SearchTitle,
		CommonPagenation,
		TableItem,
		DetailTable,
		PaymentStatusChanger,
	},
	data() {
		return {
			selectedStatus: 'ALL',
			datePickerList: [
				{
					text: '주문일',
				},
				{
					text: '예상출고일',
				},
				{
					text: '배송시작일',
				},
			],
			filterList: {
				주문상태: [
					{
						name: '승인 완료',
						value: 'APPROVE',
					},
					{
						name: '배송중',
						value: 'SHIPPING_ING',
					},
					{
						name: '배송완료',
						value: 'SHIPPING_COMPLETE',
					},
					{
						name: '구매확정',
						value: 'CONFIRM',
					},
					{
						name: '주문취소',
						value: 'CANCEL',
					},
					{
						name: '교환/반품 완료',
						value: 'RETURN',
					},
				],
				브랜드: [],
			},
			statusCounterList: [],
			paymentHeaders: [
				{ name: '주문월', key: '', width: '160px' },
				{ name: '결제번호', key: '', width: '160px' },
				{ name: '주문번호', key: '', width: '160px' },
				{ name: '주문 아이템 번호', key: '', width: '160px' },
				{ name: '연구실 결제상태', key: '', width: '160px' },
				{ name: '입금금액', key: '', width: '160px' },
				{ name: '주문 금액(VAT 포함)', key: '', width: '160px' },
				{ name: '판매자 정산상태', key: '', width: '160px' },
				{ name: '주문상태', key: '', width: '160px' },
				{ name: '상품 정보', key: '', width: '300px' },
				{ name: '출고수량', key: '', width: '160px' },
				{ name: '매입가', key: '', width: '160px' },
				{ name: '소비자가', key: '', width: '160px' },
				{ name: '주문 금액', key: '', width: '160px' },
				{ name: 'VAT 금액', key: '', width: '160px' },
				{ name: '이익', key: '', width: '160px' },
				{ name: '이익률', key: '', width: '160px' },
				{ name: '결제방법', key: '', width: '160px' },
				{ name: '결제일시', key: '', width: '160px' },
				{ name: '입금일시', key: '', width: '160px' },
				{ name: '카드 승인번호', key: '', width: '160px' },
				{ name: '국세청 승인번호', key: '', width: '160px' },
				{ name: '업체정보', key: '', width: '160px' },
				{ name: '유통사', key: '', width: '160px' },
				{ name: '브랜드', key: '', width: '160px' },
				{ name: '제품번호', key: '', width: '160px' },
				{ name: '상품 옵션', key: '', width: '160px' },
				{ name: '주문 연구실', key: '', width: '160px' },
				{ name: '주문 연구실 ID', key: '', width: '160px' },
				{ name: '주문자', key: '', width: '160px' },
				{ name: '주문자 연락처', key: '', width: '160px' },
				{ name: '주소', key: '', width: '160px' },
				{ name: '재고 확인일', key: '', width: '160px' },
				{ name: '배송시작일', key: '', width: '160px' },
				{ name: '배송완료일', key: '', width: '160px' },
				{ name: '취소요청일', key: '', width: '160px' },
				{ name: '주문취소일', key: '', width: '160px' },
				{ name: '구매확정일', key: '', width: '160px' },
				{ name: '주문일시', key: '', width: '160px' },
			],
			checkedList: [],
			paymentStatusOptions: [],
		}
	},
	watch: {
		checkedList() {
			this.changePaymentStatus()
		},
	},
	computed: {
		...mapState('payment/list', ['list', 'count', 'statusList', 'filter']),
		datePlaceholder() {
			const date = new Date()
			const today = date.toISOString().substring(0, 10)
			const [year] = today.split('-')
			return `${year}-01-01 ~ ${today}`
		},
		abledList() {
			return this.list.filter(it => !(it.returnStatus === 'DONE' || it.cancelStatus === 'DONE' || it.payment.status == 'PAID'))
		},
		allChecked() {
			return this.checkedList.length > 0 && this.abledList.length == this.abledList.filter(it => this.checkedList.some(it2 => it2.orderItemId == it.orderItemId)).length
		}
	},
	created() {
		this.initLoadParams()
	},
	mounted() {
		this.loadData()
	},
	methods: {
		...mapMutations('payment/list', ['initLoadParams']),
		...mapActions('payment/list', ['setLoadParams', 'getStatusList', 'getList', 'getFilter']),
		loadData() {
			this.fetchStatusSummaryList()
			this.fetchFilters()
		},
		async fetchStatusSummaryList() {
			await this.getStatusList()

			const status = {
				ALL: '전체',
				NOPAID: '미결제',
				WAIT: '입금대기',
				PAID: '결제완료',
			}

			this.statusCounterList = Object.keys(this.statusList).map(id => {
				return {
					id,
					title: status[id],
					count: this.statusList[id],
					active: id === 'ALL' ? true : false,
				}
			})

			this.setLoadParams({ paymentStatus: 'ALL' })
		},
		async fetchFilters() {
			await this.getFilter()

			this.filterList['브랜드'] = []
			this.filter.brands.forEach(item => {
				this.filterList['브랜드'].push({ name: item.name, value: item.id })
			})
		},
		changeAllChecked() {
			if(this.allChecked) {
				this.list.filter(it => {
					const idx = this.checkedList.findIndex(it2 => it2.orderItemId == it.orderItemId)
					if(idx > -1)
						this.checkedList.splice(idx, 1)
				})
			} else {
				this.list.filter(it => {
					if(!(it.returnStatus === 'DONE' || it.cancelStatus === 'DONE' || it.payment.status == 'PAID') && !this.checkedList.some(it2 => it2.orderItemId == it.orderItemId)) {
						this.checkedList.push(it)
					}
				})
			}
		},
		changeCheckedList(item) {
			if (this.checkedList.some(it => it.orderItemId == item.orderItemId)) {
				this.checkedList.remove(item, 'orderItemId')
			} else {
				this.checkedList.push(item)
			}

			this.changePaymentStatus()
		},
		changePaymentStatus() {
			this.paymentStatusOptions = []
			if (this.checkedList.length == 0) return;

			if (this.checkedList.length === this.checkedList.filter(selectedItem => selectedItem.payment.status === 'WAIT').length) {
				this.paymentStatusOptions.push({ name: '결제완료', value: 'WAIT_TO_PAID' })
			} else if (this.checkedList.length === this.checkedList.filter(selectedItem => selectedItem.payment.status === 'NOPAID').length) {
				this.paymentStatusOptions.push({ name: '입금대기 (결제 수기생성)', value: 'NOPAID_TO_WAIT' })
				this.paymentStatusOptions.push({ name: '결제완료 (결제 수기생성)', value: 'NOPAID_TO_PAID' })
			}
		},
		onClickStatus(event) {
			if (this.selectedStatus === event) return
			else {
				this.statusCounterList.forEach(status => {
					if (status.active) status.active = false
					else if (status.id === event) status.active = true
				})
				this.selectedStatus = event
			}

			this.setLoadParams({ paymentStatus: this.selectedStatus })
		},
		getCurrentPage(page) {
			this.setLoadParams(page)
			document.querySelector('.detail-table > div').scrollTo(0, 0)
		},
		searchTextList(filters) {
			const periodType = {
				ORDER_DATE: '기간 조회/주문일',
				RELEASE_DATE: '기간 조회/예상출고일',
				SHIPPING_DATE: '기간 조회/배송시작일',
			}

			const params = {}
			params.orderStartDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[0] : undefined
			params.orderEndDatetime = filters[periodType.ORDER_DATE]?.length ? filters[periodType.ORDER_DATE][0].value[1] : undefined
			params.estimatedReleaseStartDatetime = filters[periodType.RELEASE_DATE]?.length ? filters[periodType.RELEASE_DATE][0].value[0] : undefined
			params.estimatedReleaseEndDatetime = filters[periodType.RELEASE_DATE]?.length ? filters[periodType.RELEASE_DATE][0].value[1] : undefined
			params.releaseStartDatetime = filters[periodType.SHIPPING_DATE]?.length ? filters[periodType.SHIPPING_DATE][0].value[0] : undefined
			params.releaseEndDatetime = filters[periodType.SHIPPING_DATE]?.length ? filters[periodType.SHIPPING_DATE][0].value[1] : undefined
			params.keyword = filters['검색']?.length ? filters['검색'][0].value : ''

			this.setLoadParams(params)
		},
		searchCheckList(filters) {
			let brandIds = []
			let orderStatus = []

			if (filters['브랜드']?.length) {
				filters['브랜드'].forEach(brand => {
					brandIds.push(brand.value)
				})
			}

			if (filters['주문상태']?.length) {
				filters['주문상태'].forEach(el => {
					orderStatus.push(el.value)
				})
			}

			const params = {}
			params.brandIds = brandIds
			params.orderStatus = orderStatus

			this.setLoadParams(params)
		},
		uncheckAll() {
			this.checkedList = [];
		}
	},
}
</script>

<style lang="scss" scoped>
.order-wrapper {
	.flex {
		width: 100%;
		align-items: center;
		button {
			margin-left: auto;
		}
	}
}
</style>

<style lang="scss">
.payment-management {
	.content-header {
		width: 100%;
		display: flex;
		align-items: center;
		border-bottom: 1px solid $LINE_DIVIDER;
		height: 54px;
		padding: 0 30px;
		margin-bottom: 30px;
		justify-content: flex-start;
		.title {
			text-align: left;
			h4 {
				font-size: 1.5rem;
				font-weight: 700;
			}
		}
	}
	.content-wrapper {
		width: 100%;
		padding: 0 30px;
		.detail-search-box {
			margin: 40px 0;
		}
		.payment-status-changer {
			margin: 15px 0 10px;
		}
	}
	.table-wrap {
		width: 100%;
		border: 1px solid $LINE_DIVIDER;
		border-radius: 5px;
		overflow-x: auto;
		table {
			thead {
				tr {
					th {
						white-space: nowrap;
						min-width: 120px;
						height: 40px;
						border-bottom: 1px solid $LINE_DIVIDER;
						font-size: 0.875rem;
						color: $COLOR_666;
						padding: 0 8px;
						text-align: left;
						&:first-child {
							text-align: left;
							padding-left: 1rem;
						}
					}
				}
			}
			tbody {
				tr {
					border-bottom: 1px solid $LINE_DIVIDER;
					height: 60px;
					td {
						font-size: 0.875rem;
						text-align: left;
						padding: 0 8px;
						white-space: nowrap;
						.status-text {
							margin-left: 8px;
						}
						.info {
							p {
								font-size: 0.75rem;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
	}
}
</style>
