<template>
	<tr class="table-item" style="cursor: default" @click="goToDetail">
		<td>
			<div>
				<span>{{ item.id }}</span>
			</div>
		</td>
		<td style="min-width: 50px; width: 50px">
			<div style="min-width: 150px">
				<div class="logo-image-wrapper">
					<img v-if="item.image" class="logo-image" :src="item.image" />
					<span class="logo-title" v-else>{{ item.name }}</span>
				</div>
			</div>
		</td>
		<td>
			<div style="min-width: 150px; max-width: 750px">
				<span style="font-weight: 700">
					{{ item.name }}
				</span>
			</div>
		</td>
		<td>
			<div>
				<span :class="['status', item.isBlock === 0 ? 'SELLING' : 'STOP_SELLING']"> {{ statusText }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.contactName) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.contactPhone) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(item.corpNo) }} </span>
			</div>
		</td>
		<td>
			<div style="min-width: 150px; max-width: 750px">
				<span> {{ isData(item.address) }} </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(commaNum(item.productInfoCount)) }} 개 </span>
			</div>
		</td>
		<td>
			<div>
				<span> {{ isData(commaNum(item.totalPrice)) }} 원 </span>
			</div>
		</td>
		<td>
			<div class="datetime">
				<span>{{ item.createDatetime ? $DateTime.fromISO(item.createDatetime).toFormat('yyyy-MM-dd T') : '-' }}</span>
			</div>
		</td>
	</tr>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins'
import MixinItem from '@/mixins/table/item'
import PageName from '@/utils/define/PageName'

export default {
	props: {
		item: {
			type: Object,
		},
	},
	mixins: [mixin, MixinItem],
	computed: {
		statusText() {
			return this.item.isBlock === 0 ? '활성화' : '판매중지'
		},
	},
	methods: {
		goToDetail() {
			// this.$router.push({
			// 	name: PageName.Seller.SellerDetail,
			// 	params: {
			// 		id: 1,
			// 	},
			// })
		},
	},
}
</script>
<style scoped lang="scss">
@import '@/styles/scss/table/_table-item.scss';
.logo-image-wrapper {
	width: 120px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid #eeeeee;
	display: flex;
	align-items: center;
	justify-content: center;
	.logo-image {
		object-fit: contain;
		width: 100%;
		height: 100%;
		max-height: 40px;
		max-width: 120px;
	}
	.logo-title {
		font-weight: 700;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}
</style>
